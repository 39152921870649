import React, { useContext } from 'react'
import BackgroundRow from '../../components/generic/background_row'
import fox from '../../images/fox.jpg'
import BackToHome from '../../components/navigation/back_to_home'
import { useFrench, useTranslate } from '../../context/lang'
import { useOrderNotifications } from '../../context/order_notifications'
import useSound from 'use-sound'
import notifications from '../../sounds/notifications.mp3'
import { Checkbox } from '../../components/generic/checkbox'
import { trigger_notification } from '../order/order_notification'
import Button from '../../components/generic/button'
import UserContext from '../../context/user'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../../graphql/queries/user'
import UpdateButton from '../../components/generic/update-button'
import { await_mutate } from '../../graphql/mutate_promise'
import BigBox from '../../components/layout/big_box'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { OnlyPremium } from '../../components/subscription/start_free_trial'
import { FiAlertCircle, FiBell, FiSave } from 'react-icons/all'
import { UpdateMetaData, useMetaData, useUpdateMetaData } from '../../components/user/meta_data'
import { CompactItem } from '../../components/generic/item'

const activate_fba_fee_change_alerts_meta_data_key = 'activate_fba_fee_change_alerts'
const new_comments_receive_all_changes_meta_data_key = 'new_comments_receive_all_changes'

export default function Alerts() {
  const user = useContext(UserContext)
  const { order_notifications, set_order_notifications } = useOrderNotifications()
  const translate = useTranslate()
  const activate_fba_fee_change_alerts = useMetaData(activate_fba_fee_change_alerts_meta_data_key)

  return <>
    <div className="container pb-5">
      <BackToHome/>
      <Title color={section_color('alerts')} style={{ color: 'white' }}><WelcomeIcon section={'alerts'}/> {translate({
        fr: `Gestion des alertes`,
        en: `Alerts`,
      })}</Title>

      <OnlyPremium>
        <BigBox title={translate({
          fr: 'Alerte en cas de nouvelle vente',
          en: 'New sale alert',
        })} is_active={order_notifications.activate}>
          <Notifications/>
        </BigBox>

        <BigBox title={translate({
          fr: 'Alerte en cas de nouveaux commentaires',
          en: 'New comment alert',
        })} is_active={user.user.accept_comments_notification}>
          <EmailOnNewComments/>
        </BigBox>

        <BigBox title={translate({
          fr: `Alerte en cas d'évolution des frais FBA`,
          en: 'FBA fee change alert',
        })} is_active={activate_fba_fee_change_alerts}>
          <EmailOnFBAFeeChange/>
        </BigBox>
      </OnlyPremium>
    </div>
  </>
}

const EmailOnNewComments = () => {
  const user = useContext(UserContext)
  const translate = useTranslate()
  const french = useFrench()

  const [accept_comments_notification, set_accept_comments_notification] = React.useState(user.user.accept_comments_notification)
  const [update_user, update_user_mutation] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetMe'],
  })
  const value = useMetaData(new_comments_receive_all_changes_meta_data_key)
  const [new_comments_receive_all_changes, set_new_comments_receive_all_changes] = React.useState(value || false)
  const update_meta_data = useUpdateMetaData({
    meta_data_key: new_comments_receive_all_changes_meta_data_key, value: new_comments_receive_all_changes,
  })
  return <div className="row">
    <Checkbox value={accept_comments_notification} setter={set_accept_comments_notification}>
      {translate({
        fr: `Recevoir une alerte par email lorsqu'un nouveau commentaire est détecté sur mes produits`,
        en: `Receive an email alert each time a new comment is detected on one of my products`,
      })}
    </Checkbox>
    <Checkbox value={new_comments_receive_all_changes} setter={set_new_comments_receive_all_changes} disabled={!accept_comments_notification}>
      <span className="pr-2 small font-italic">{french(`(optionnel)`)}</span>{french(`Recevoir une alerte même si la note du produit ne change pas`)}
    </Checkbox>


    <div className="col-12 mt-2">
      <UpdateButton onClick={async () => {
        await Promise.all([
          await_mutate(update_user, {
            variables: {
              user_id: user.user.user_id,
              accept_comments_notification,
            },
          }),
          update_meta_data(),
        ])

        await user.refresh()

      }} {...update_user_mutation} ><FiSave/> {translate({
        fr: `Enregistrer`,
        en: `Save`,
      })}</UpdateButton>
    </div>
  </div>
}

const EmailOnFBAFeeChange = () => {
  const translate = useTranslate()

  const value = useMetaData(activate_fba_fee_change_alerts_meta_data_key)
  const [activate_fba_fee_change_alerts, set_activate_fba_fee_change_alerts] = React.useState(value || false)

  return <div className="row">
    <Checkbox value={activate_fba_fee_change_alerts} setter={set_activate_fba_fee_change_alerts}>
      {translate({
        fr: `Recevoir une alerte par email lorsque les frais FBA d'un de mes produits évoluent`,
        en: `Receive an email alert if a change in the FBA fees from one of my products is detected`,
      })}
    </Checkbox>
    <div className="col-12 mt-2">
      <UpdateMetaData meta_data_key={activate_fba_fee_change_alerts_meta_data_key}
                      value={activate_fba_fee_change_alerts}>
        <FiSave/> {translate({
        fr: `Enregistrer`,
        en: `Save`,
      })}
      </UpdateMetaData>
    </div>
  </div>
}

function Notifications() {
  const translate = useTranslate()
  const french = useFrench()

  const { order_notifications, set_order_notifications } = useOrderNotifications()

  const { activate, sound, confetti, notification } = order_notifications
  const [play] = useSound(notifications, {
    sprite: {
      cashier: [0, 3050],
      intellifox: [3050, 4100],
      space: [7110, 4500],
    },
  })

  const choose_sound = (id, label) => {
    return <Checkbox value={sound === id} setter={() => {
      const new_sound = sound === id ? false : id
      if (new_sound) {
        trigger_notification({
          order_notifications: {
            activate: true,
            sound: id,
          },
          play,
          text: translate({
            fr: 'Nouvelle vente',
            en: 'New sale',
          }),
          body: translate({
            fr: 'Félicitations !',
            en: 'Congrats!',
          }),
        })
      }
      set_order_notifications({
        ...order_notifications,
        sound: new_sound,
      })
    }}>{label}</Checkbox>
  }

  return (
    <>
      <div className="row">
        <Checkbox value={activate} setter={(v) => {
          set_order_notifications({
            ...order_notifications,
            activate: v,
          })
        }}>
          {translate({
            fr: `Activer les alertes`,
            en: `Activate alerts`,
          })}
        </Checkbox>
        {activate ? <>
          <div className="col-12 col-md-4">
            <div className="font-weight-bold">{translate({
              fr: `Son`,
              en: `Sound`,
            })}</div>
            <div className="row">
              {choose_sound('cashier', translate({
                fr: 'Caisse',
                en: 'Cashier',
              }))}
              {choose_sound('intellifox', 'Intellifox')}
              {choose_sound('space', translate({
                fr: 'Discret',
                en: 'Quiet',
              }))}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="font-weight-bold">{translate({
              fr: `Notification`,
              en: `Notification`,
            })}</div>
            <div className="row">
              <Checkbox value={notification} setter={(v, e) => {
                if (!notification) {
                  trigger_notification({
                    order_notifications: {
                      activate: true,
                      notification: true,
                    },
                    play,
                    text: translate({
                      fr: 'Nouvelle vente',
                      en: 'New sale',
                    }),
                    body: translate({
                      fr: 'Félicitations !',
                      en: 'Congrats!',
                    }),
                  })
                }
                set_order_notifications({
                  ...order_notifications,
                  notification: !notification,
                })
              }}>{translate({
                fr: `Activer`,
                en: `Activate`,
              })}</Checkbox>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="font-weight-bold">Confetti</div>
            <div className="row">
              <Checkbox value={confetti} setter={(v, e) => {
                if (!confetti) {
                  trigger_notification({
                    order_notifications: {
                      activate: true,
                      confetti: true,
                    },
                    play,
                    text: translate({
                      fr: 'Nouvelle vente',
                      en: 'New sale',
                    }),
                    body: translate({
                      fr: 'Félicitations !',
                      en: 'Congrats!',
                    }),
                  })
                }
                set_order_notifications({
                  ...order_notifications,
                  confetti: !confetti,
                })
              }}>{translate({
                fr: `Activer`,
                en: `Activate`,
              })}</Checkbox>
            </div>
          </div>

          <div className="col-12 mt-2">
            <Button onClick={() => trigger_notification({
              order_notifications,
              play,
              text: translate({
                fr: 'Nouvelle vente',
                en: 'New sale',
              }),
              body: translate({
                fr: 'Félicitations !',
                en: 'Congrats!',
              }),
            })}><FiBell/> {translate({
              fr: `Tester la notification`,
              en: `Test notification`,
            })}</Button>
          </div>

        </> : null}
      </div>

      <div className="row">
        <div className="col-12">
          <div className="alert alert-success small mt-3 mb-5">
            {french(`Pour pouvoir recevoir les notifications, il faut laisser l'onglet d'Intellifox ouvert en permanence.`)}
          </div>
        </div>
      </div>
    </>
  )
}
