import React from 'react'
import { Browse_Content_Section } from '../browse__content'
import { FaArrowLeft, FaChess, IoIosImages, IoIosSearch } from 'react-icons/all'
import TextButton from '../../../generic/text_button'
import Button from '../../../generic/button'
import ProductPictureSelector from './product_picture_selector'
import { PicturesSelector } from './picture_selector'
import { LibrarySelector } from './library_selector_pixabay'
import { useTranslate } from '../../../../context/lang'

const Selector = ({ icon, label, on_click, help }) => {
  return <div className="row mb-5">
    <div className="col-12">
      <Button className="btn-block" onClick={on_click}>
        <div className="row text-center">
          <div className="col-12 pb-1" style={{ fontSize: 60, lineHeight: '45px' }}>
            {icon}
          </div>
          <div className="col-12">
            {label}
          </div>
        </div>
      </Button>
    </div>
    <div className="col-12">
      {help}
    </div>
  </div>
}
export const PicturesSelectorV2 = ({ on_click }) => {

  const [choice, set_choice] = React.useState('')
  const translate = useTranslate()

  return (
    <>
      {!choice ? <Browse_Content_Section>
        <Selector icon={<FaChess/>} label={translate({
            fr: 'Photos du produit',
            en: 'Product pictures',
        })}
                  help={translate({
                      fr: `Toutes les photos du produit ou liées uniquement à ce produit.`,
                      en: `Pictures of the product or related only to this product.`,
                  })}
                  on_click={() => set_choice('product')}/>
        <Selector icon={<IoIosImages/>} label={translate({
            fr: 'Photos ou images générales',
            en: 'Global pictures',
        })}
                  help={translate({
                      fr: `Toutes les photos réutilisables dans plusieurs produits : logos de marque, logos Amazon, bio, etc.`,
                      en: `All pictures that are common between products, e.g.: brand logos, Amazon logos, etc.`,
                  })}
                  on_click={() => set_choice('pictures')}/>
        <Selector icon={<IoIosSearch/>} label={translate({
            fr: `Bibliothèque d'images`,
            en: `Pictures library`,
        })}
                  help={translate({
                      fr: `Accéder à des images libre de droit directement intégrable dans les designs.`,
                      en: `Browse thousands of free images ready to be integrated in your designs.`,
                  })}
                  on_click={() => set_choice('library')}/>
      </Browse_Content_Section> : <Browse_Content_Section>
        <TextButton className={'black'} onClick={() => set_choice('')}><FaArrowLeft/> {translate({
            fr: `Retour`,
            en: `Back`
        })}</TextButton>
      </Browse_Content_Section>}

      {choice === 'product' ? <ProductPictureSelector on_click={on_click}/> : null}
      {choice === 'pictures' ? <PicturesSelector on_click={on_click}/> : null}
      {choice === 'library' ? <LibrarySelector on_click={on_click}/> : null}

    </>
  )
}
