import { useDesignerContext } from '../designer_context'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { move_down, move_to_the_bottom, move_to_the_top, move_up } from './order_objects'

import {
  MdArrowDownward,
  MdArrowUpward,
  MdExpandLess,
  MdExpandMore,
  MdVerticalAlignBottom,
  MdVerticalAlignCenter,
  MdVerticalAlignTop,
} from 'react-icons/md'
import { FaExpandArrowsAlt } from 'react-icons/fa'
import { useTranslate } from '../../../context/lang'
import SetDimensionsModal from './set_dimensions_modal'
import { MdEdit } from 'react-icons/all'

export const ChangeOrder = ({ object }) => {
  const {
    current_objects,
    update_objects_save_history,
    update_single_object,
    width,
    height,
    edit_overrides,
  } = useDesignerContext()
  const translate = useTranslate()

  const handleToggle = (isOpen, event, metadata) => {
    if (isOpen || metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  const [show_set_dimensions, set_show_set_dimensions] = React.useState(false)

  const [show, setShow] = React.useState(false)
  return <>
    <Dropdown show={show} onToggle={handleToggle} title={translate({
      fr: 'Position',
      en: 'Position',
    })}>
      <Dropdown.Toggle variant="link">Position</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          if (object.type === 'image' && object.width === width && object.height === height && object.x === 0 && object.y === 0) {
            update_single_object(object.object_id, {
              props: {
                ...object.props,
                image_object_fit: object.props.image_object_fit && object.props.image_object_fit === 'contain' ? 'cover' : 'contain',
              },
            })
          } else {
            update_single_object(object.object_id, {
              width,
              x: 0,
              height,
              y: 0,
            })
          }
        }}>
          <FaExpandArrowsAlt/> {translate({
          fr: `Maximiser`,
          en: `Maximize`,
        })}
        </Dropdown.Item>
        <Dropdown.Divider/>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_up(current_objects.objects, object))
        }} disabled={edit_overrides}><MdExpandLess/> {translate({
          fr: `Remonter`,
          en: `Up`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_down(current_objects.objects, object))
        }} disabled={edit_overrides}><MdExpandMore/> {translate({
          fr: `Descendre`,
          en: `Down`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_to_the_top(current_objects.objects, object))
        }} disabled={edit_overrides}><MdArrowUpward/> {translate({
          fr: `Passer tout devant`,
          en: `To the foreground`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_to_the_bottom(current_objects.objects, object))
        }} disabled={edit_overrides}><MdArrowDownward/> {translate({
          fr: `Passer tout derrière`,
          en: `To the background`,
        })}</Dropdown.Item>

        <Dropdown.Divider/>

        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            x: 0,
          })
        }}><MdVerticalAlignTop style={{ transform: 'rotate(-90deg)' }}/> {translate({
          fr: `Coller à gauche`,
          en: `Stick to the left`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            x: ( width - object.width ) / 2,
          })
        }}><MdVerticalAlignCenter style={{ transform: 'rotate(90deg)' }}/> {translate({
          fr: `Centrer horizontalement`,
          en: `Center horizontally`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            x: width - object.width,
          })
        }}><MdVerticalAlignTop style={{ transform: 'rotate(90deg)' }}/> {translate({
          fr: `Coller à droite`,
          en: `Stick to the right`,
        })}</Dropdown.Item>

        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            y: 0,
          })
        }}><MdVerticalAlignTop/> {translate({
          fr: `Coller en haut`,
          en: `Stick to the top`,
        })} </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            y: ( height - object.height ) / 2,
          })
        }}><MdVerticalAlignCenter/> {translate({
          fr: `Centrer verticalement`,
          en: `Center vertically`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            y: height - object.height,
          })
        }}><MdVerticalAlignBottom/> {translate({
          fr: `Coller au bas`,
          en: `Stick to the bottom`,
        })}</Dropdown.Item>

        <Dropdown.Divider/>


        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            width,
            x: 0,
          })
        }}><MdVerticalAlignBottom/> {translate({
          fr: `Prendre toute la largeur`,
          en: `Expand to the whole width`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_single_object(object.object_id, {
            height,
            y: 0,
          })
        }}><MdVerticalAlignBottom/> {translate({
          fr: `Prendre toute la hauteur`,
          en: `Expand to the whole height`,
        })}</Dropdown.Item>

        <Dropdown.Divider/>


        <Dropdown.Item onClick={() => {
          set_show_set_dimensions(true)
        }}><MdEdit/> {translate({
          fr: `Modifier la position et la taille`,
          en: `Edit position and dimension`,
        })}</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>

    {show_set_dimensions ? <SetDimensionsModal show={true} close={() => set_show_set_dimensions(false)}/> : null}

  </>

}
