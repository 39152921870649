import React from 'react'
import IntellifoxModal from '../generic/modal'

import image from '../../images/etiquette-FR-kit-com.png'
import Button from '../generic/button'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import TranslationsContext from '../../context/translations'
import { get_client } from '../../graphql/simple-client'
import { GET_PLANS } from '../../graphql/queries/plan'
import Field from '../generic/field'
import { DefaultError, DefaultLoading } from '../i18n/translations'
import * as PropTypes from 'prop-types'
import { Checkbox } from '../generic/checkbox'
import UpdateButtonV2 from '../generic/update_button_v2'
import { gql, useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import moment from 'moment'
import ExternalLink from '../generic/external-link'
import useLocalStorage from '../../hooks/useLocalStorage'

const siren_info = `
  query siren_info($company_id: ID!, $siren: String!) {
    siren_info(company_id:$company_id, siren:$siren)
  }
`

const ask_for_check_france_num = gql`
    mutation ask_for_check_france_num(
        $company_id: ID!,
        $email: String,
        $company_name: String,
        $company_address_line_1: String,
        $company_address_line_2: String,
        $company_postal_code: String,
        $company_city: String,
        $siret: String,
    ) {
        ask_for_check_france_num(
            company_id: $company_id,
            email: $email,
            company_name: $company_name,
            company_address_line_1: $company_address_line_1,
            company_address_line_2: $company_address_line_2,
            company_postal_code: $company_postal_code,
            company_city: $company_city,
            siret: $siret,
        )
    }
`


function CompanyForm({ siren, siren_info }) {


  const me = useCachedContext('me')


  const normalize = (...keys) => {
    if (siren_info.siege_social) {
      const siege_social = siren_info.siege_social
      return keys.reduce((normalized_value, key) => {
        const value = siege_social[ key ]

        if (!value) {
          return normalized_value
        }

        return ( normalized_value + ' ' + value ).trim()
      }, '')
    }

    return ''
  }

  const [company_name, set_company_name] = React.useState(normalize('nom_raison_sociale'))
  const [company_address_line_1, set_company_address_line_1] = React.useState(normalize('l2_normalisee', 'l3_normalisee'))
  const [company_address_line_2, set_company_address_line_2] = React.useState(normalize('l4_normalisee', 'l5_normalisee'))
  const [company_postal_code, set_company_postal_code] = React.useState(normalize('code_postal'))
  const [company_city, set_company_city] = React.useState(normalize('libelle_commune'))
  const [siret, set_siret] = React.useState(normalize('siret'))

  const [checked, set_checked] = React.useState(false)

  const [trigger_ask_for_check_france_num] = useMutation(ask_for_check_france_num)


  const do_it = async () => {
    await await_mutate(trigger_ask_for_check_france_num, {
      variables: {
        company_id: me.company.company_id,
        email: me.user.email,
        company_name,
        company_address_line_1,
        company_address_line_2,
        company_postal_code,
        company_city,
        siret,
      },
    })

    me.refresh()
  }


  const date_creation = siren_info && siren_info.siege_social ? siren_info.siege_social.date_creation : null

  const creation_date = moment(date_creation, 'YYYYMMDD')

  const date_limit = moment('20201030', 'YYYYMMDD')

  const is_valid = !siren_info || !siren_info.siege_social || creation_date.isSameOrBefore(date_limit)

  if (!is_valid) {
    return <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">
          <h2>L'entreprise n'est pas éligible</h2>
          D'après les informations récupérées sur le site du gouvernement :<br/>
          L'entreprise qui a pour SIREN : <b>{siren}</b>, été créée le <b>{creation_date.format('DD/MM/YYYY')}</b>.<br/>
          La date limite de création est le <b>{date_limit.format('DD/MM/YYYY')}</b>.
        </div>
      </div>
    </div>
  }


  const disabled = !checked || !siret || !company_postal_code || !company_name || !company_city


  return <div className="row mt-5">
    {siren_info && siren_info.siege_social ? <div className="col-12 font-weight-bold mb-5">
      Les informations suivantes ont été automatiquement récupérées pour le SIREN que tu as indiqué. Vérifie et corrige
      les données si nécessaire :
    </div> : null}
    <Field type='text' value={company_name} setter={set_company_name} label={`Nom de l'entreprise (* obligatoire )`}
           validate={(v) => v.length > 0}/>
    <Field type='text' value={company_address_line_1} setter={set_company_address_line_1} label={`Adresse ligne 1`}/>
    <Field type='text' value={company_address_line_2} setter={set_company_address_line_2} label={`Adresse ligne 2`}/>
    <Field type='text' value={company_postal_code} setter={set_company_postal_code}
           label={`Code postal (* obligatoire )`} validate={(v) => v.length > 0}/>
    <Field type='text' value={company_city} setter={set_company_city} label={`Ville (* obligatoire )`}
           validate={(v) => v.length > 0}/>
    <Field type='text' value={siret} setter={set_siret} label={`SIRET (* obligatoire )`}
           validate={(v) => v.replace(/ /g, '').replace(/\D/g, '').trim().length === 14}/>

    <Checkbox value={checked} setter={set_checked} className="h3 mt-5 mb-5">Je confirme l'exactitude des données
      présentes ci-dessus</Checkbox>

    <div className="col-12">
      <UpdateButtonV2 disabled={disabled} trigger={do_it}>J'envoie ma demande pour obtenir une année gratuite
        d'Intellifox</UpdateButtonV2>
    </div>
  </div>
}

const SelectSiret = ({ siren }) => {
  const siren_info = useCachedContext('siren_info', siren)

  if (!siren) {
    return null
  }

  if (siren_info.loading) {
    return <DefaultLoading/>
  }

  return <>
    <CompanyForm siren={siren} siren_info={siren_info}/>
  </>
}


const SiretFinder = () => {

  const [_siren, __set_siren] = React.useState('')
  const [siren, set_siren] = React.useState('')

  const set_siren_timeout_ref = React.useRef(-1)

  const set_siren_timedout = (siren) => {
    clearTimeout(set_siren_timeout_ref.current)
    __set_siren(siren)
    set_siren_timeout_ref.current = setTimeout(() => {
      set_siren(siren.replace(/ /g, '').replace(/\D/g, '').trim())
    }, 500)
  }

  const is_siren_incorrect = siren && siren.length > 0 && siren.length !== 9

  return <div className="row">

    <Field type="text" value={_siren} setter={set_siren_timedout}
           label={'Indique le SIREN de ton entreprise (9 chiffres)'}
           placeholder={`Entrer le SIREN de l'entreprise`}/>
    {is_siren_incorrect ? <div className="col-12 font-weight-bold">
      Le SIREN doit être composé de 9 chiffres. Exemple : 854 079 365
    </div> : null}

    {!is_siren_incorrect ? <div className="col-12">
      <SelectSiret siren={siren}/>
    </div> : null}
  </div>
}

const AskForCheckFranceNum = () => {
  const company_id = useCompanyId()

  const fetch_siren_info = async (siren) => {
    const client = get_client()
    const response = await client.request(siren_info, {
      company_id, siren,
    })

    let output = {}

    try {
      output = JSON.parse(response.siren_info)
    } catch (e) {

    }

    return output
  }


  return <CachedValues name={'siren_info'} fetch_value={fetch_siren_info}>
    <div className="row">
      <div className="col-12">
        <SiretFinder/>
      </div>
    </div>
  </CachedValues>
}

const OptionPicker = ({ options, setter, value }) => {

  return <div className="row">
    <div className="col-12">
      {options.map((option, i) => <Button key={i} onClick={() => setter(option.value)}
                                          button={value === option.value ? 'primary' : 'secondary'}
                                          className={'mr-2'}>{option.label}</Button>)}
    </div>
  </div>
}


const Pick = ({ children, options, setter, value }) => {
  return <>
    <div className="col-12 font-weight-bold mb-2">
      {children}
    </div>
    <div className="col-12 mb-4">
      <OptionPicker options={options} setter={setter} value={value}/>
    </div>
  </>
}


const ChequeFranceNumTester = () => {

  const [french_company, set_french_company] = React.useState('')
  const [company_type, set_company_type] = React.useState('')
  const [creation_date, set_creation_date] = React.useState('')
  const [income, set_income] = React.useState('')
  const [employees, set_employees] = React.useState('')
  const [cdi_fulltime, set_cdi_fulltime] = React.useState('')

  const sorry = <div className="alert alert-danger">
    <div className="row">
      <div className="col-12 font-weight-bold">
        Désolé :(
      </div>

      <div className="col-12">
        Ton entreprise n'est pas éligible à cette subvention.
      </div>
    </div>
  </div>

  const success = <div className="alert alert-success">
    <div className="row">
      <div className="col-12 text-center pt-5 pb-5">
        <h2>
          Ton entreprise est <b>éligible</b> à cette subvention !
        </h2>
      </div>

      <div className="col-12">
        <AskForCheckFranceNum/>
      </div>
    </div>
  </div>


  const display_sorry =
    ( french_company === 'other' ) ||
    ( cdi_fulltime === 'yes' ) ||
    ( creation_date === 'after' ) ||
    ( company_type === 'ae' && income === 'zero' ) ||
    ( income === 'more_than_2m' ) ||
    ( income === 'zero' ) ||
    ( employees === 'more_than_11' )

  const display_success = (
    creation_date === 'before' && income === 'less_than_2m' &&
    (
      ( company_type === 'ae' && cdi_fulltime === 'no' )
      ||
      ( company_type === 'other' && employees === 'less_than_11' )
    )
  )


  return <div className="row">
    <Pick value={french_company} setter={set_french_company} options={[
      { label: 'En France', value: 'france' },
      { label: 'Ailleurs', value: 'other' },
    ]}>
      Où est basée ton entreprise ?
    </Pick>

    {french_company === 'france' ? <Pick value={company_type} setter={set_company_type} options={[
      { label: 'Auto-Entreprise', value: 'ae' },
      { label: 'Autre SAS, SASU, EURL, SARL', value: 'other' },
    ]}>
      Quel type d'entreprise as-tu ?
    </Pick> : null}


    {company_type ? <Pick options={[
      { label: 'Oui', value: 'yes' },
      { label: 'Non', value: 'no' },
    ]} value={cdi_fulltime} setter={set_cdi_fulltime}>
      Est-ce que tu as un contrat à plein temps en parallèle de ton activité ?
    </Pick> : null}

    {company_type === 'other' ? <Pick options={[
      { label: 'Moins de 11', value: 'less_than_11' },
      { label: '11 et plus', value: 'more_than_11' },
    ]} value={employees} setter={set_employees}>
      Combien as-tu d'employés
    </Pick> : null}


    {( cdi_fulltime === 'no' || employees === 'less_than_11' ) ? <Pick options={[
      { label: 'Avant le 30 octobre 2020', value: 'before' },
      { label: 'Le 1er novembre 2020 ou après', value: 'after' },
    ]} value={creation_date} setter={set_creation_date}>
      Quelle est la date de création de l'entreprise ?
    </Pick> : null}

    {( creation_date === 'before' ) ? <Pick options={[
      { label: '0 €', value: 'zero' },
      { label: 'Entre 1 € et 2 000 000 €', value: 'less_than_2m' },
      { label: '2 000 001 € et plus', value: 'more_than_2m' },
    ]} value={income} setter={set_income}>
      Quel est le chiffre d'affaires de l'entreprise sur les 12 derniers mois ?
    </Pick> : null}


    <div className="col-12">
      {display_sorry ? sorry : null}
      {display_success ? success : null}
    </div>

  </div>
}

const ChequeFranceNumModal = () => {


  return <IntellifoxModal title={'Mon entreprise est-elle éligible ?'}
                          body={<ChequeFranceNumTester/>}

  >
    Vérifier si je suis éligible
  </IntellifoxModal>
}


const FAQ = ({ title, children }) => {

  return <div className="row mb-3">
    <div className="col-12">
      <h2>{title}</h2>
    </div>
    <div className="col-12">
      {children}
    </div>
  </div>
}

const ChequeFranceNumFAQ = ({ className }) => {


  return <IntellifoxModal button_props={{ outline: true, className }} title={'FAQ France Relance'} body={<>
    <FAQ title={`C'est quoi France Relance ?`}>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">

              C'est un ensemble de mesures prises par le gouvernement Français pour relancer l'économie et aider les
              entreprises.
            </div>

            <div className="col-12">
              <b>Plus d’info</b> ici sur l'éligibilité ici : <ExternalLink
              to="https://cheque.francenum.gouv.fr/ecom/">https://cheque.francenum.gouv.fr/ecom/</ExternalLink>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <img src={image} className="rounded w-100 shadow" alt="" style={{ maxWidth: '300px' }}/>
        </div>
      </div>
    </FAQ>
    <FAQ title={`Qu'est-ce que j'ai à y gagner ?`}>
      <ul>
        <li><b>Un AN d'Intellifox gratuit !!!</b></li>
      </ul>
    </FAQ>
    <FAQ title={`De quoi j'ai besoin ?`}>
      <ul>
        <li>Une entreprise <b>éligible</b></li>
        <li><b>500 € de trésorerie</b> qui te seront remboursés <b>dans tous les cas</b></li>
        <li><b>Un peu de patience</b> : 1 mois avant le remboursement</li>
      </ul>
    </FAQ>
    <FAQ title={`Est-ce que j'y ai le droit ?`}>
      <div className="row">
        <div className="col-12">
          Probablement ! C'est le cas, si :
        </div>

        <div className="col-12">
          ✅ ton entreprise est <b>basée en France</b> et a <b>moins de 11 salariés</b>,
        </div>
        <div className="col-12">
          ✅ si elle a été <b>créée avant le 30 octobre 2020</b>
        </div>
        <div className="col-12">
          ✅ si elle a réalisé <b>moins de 2 M€ de CA</b>
        </div>
        <div className="col-12">
          ✅ si le dirigeant de l'entreprise n'est <b>pas en contrat à temps plein</b> avec une autre entreprise
        </div>
        <div className="col-12">
          ✅ et si tu n’as <b>pas déjà utilisé</b> ce mécanisme avec une autre offre !
        </div>
      </div>
    </FAQ>

    <FAQ title={`Est-ce que ça marche si j'ai déjà payé pour Intellifox ?`}>
      <div className="row">
        <div className="col-12 font-weight-bold">
          ✅ OUI
        </div>
        <div className="col-12">
          Tu dois suivre les mêmes étapes, même si tu viens d'être prélevé ! Tu ne vas rien perdre en faisant cela, tu
          vas surtout gagner un an !
        </div>
        <div className="col-12">
          Ton prochain prélèvement sera simplement <b>décalé de 12 mois</b>. Nous repousserons la
          prochaine échéance avec notre prestataire de paiement habituel (Paddle) de 12 mois à partir de la réception
          des fonds, ce qui sera totalement transparent pour toi.
        </div>
      </div>
    </FAQ>


    <FAQ title={`Quel est le prix ?`}>
      À la fin, cela sera <b>100% gratuit pour toi</b>, mais <b>tu dois avancer 500 € de trésorerie</b> (peu importe ton
      tarif actuel), qui
      correspondent à la facture
      que l'on va t'envoyer (500 € TTC pour un an d'Intellifox). C'est cette facture que tu vas transmettre sur le site
      du gouvernement et qui te servira de preuve pour obtenir remboursement.
    </FAQ>

    <FAQ title={`Quels sont les risques ?`}>
      Il n'y a <b>aucun risque pour toi</b>. Il n'y a que deux cas de figure :
      <ol>
        <li>Ton remboursement est <b>accepté</b>, tu es alors remboursé par le gouvernement <b>au bout d'un mois</b>.
        </li>
        <li>Ton remboursement est <b>refusé</b>, ta commande est alors <b>annulée</b> et nous te <b>remboursons le plus
          rapidement
          possible</b>. Nous t'offrons alors <b>un mois gratuit</b>, à condition que ton entreprise respectaient bien
          les <b>conditions indiquées ci-dessus</b>.
        </li>
      </ol>
    </FAQ>
    <FAQ title={`J'ai combien de temps pour agir ?`}>
      <div className="row">
        <div className="col-12">
          Le budget du gouvernement est limité aux "premiers arrivés, premiers servis", jusqu'à 60 000 000 €.
        </div>
        <div className="col-12">
          <b> Cette offre ne va PAS DURER</b>.
        </div>
      </div>
    </FAQ>

    <FAQ title={`J'ai une autre question !`}>
      Tu peux nous la poser directement avec le support intégré en bas à droite de l'application, ou par email à <a
      href="mailto:support@intellifox.com">support@intellifox.com</a>
    </FAQ>
  </>}
  >
    Voir la FAQ
  </IntellifoxModal>
}

export default function ChequeFranceNum() {

  const { ask_for_check_france_num } = useCompany()

  const [hide, set_hide] = useLocalStorage('hide_cheque_france_num', false)

  const me = useCachedContext('me')

  if (hide && ask_for_check_france_num) {
    return <div className="row">
      <div className="col-12">
        <div className="alert alert-success">
          <div className="row">
            <div className="col-12 text-uppercase small">
              France Relance
            </div>
            <div className="col-12">
              Ta demande a été prise en compte ! Tu as dû recevoir un email à <b>{me.user.email}</b> avec la procédure à suivre.<br/>
              <ChequeFranceNumFAQ className={'btn-sm'}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  if (hide && !ask_for_check_france_num) {
    return <div className="row">
      <div className="col-12">
        <div className="alert alert-success">
          <div className="row">
            <div className="col text-uppercase small">
              Offre disponible jusqu'au 31 mai 2021 - France Relance
            </div>
            <div className="col-auto">
              <Button outline={true} className={'btn-sm mr-2'} onClick={() => set_hide(false)}>Ouvrir</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }


  return (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-success p-5">
          <div className="row">
            <div className="col-12 col-lg-4 mb-2 mb-lg-0">
              <img src={image} className="rounded w-100 shadow" alt=""/>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row">
                <div className="col-12 text-uppercase small">
                  Offre disponible jusqu'au 31 mai 2021 - France Relance
                </div>
                <div className="col-12 mb-4">
                  <h1>Intellifox gratuit pendant un an ?</h1>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-auto">
                      {ask_for_check_france_num ?
                        <b>
                          Ta demande a été prise en compte ! Tu as dû recevoir un email à <b>{me.user.email}</b> avec la
                          procédure à suivre.
                        </b> :
                        <>
                          <ChequeFranceNumModal/>
                        </>}
                    </div>
                    <div className="col-auto">
                      <ChequeFranceNumFAQ/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-right">
              <Button outline={true} className={'btn-sm'} onClick={() => set_hide(true)}>Fermer</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
