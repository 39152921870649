import React, { useContext } from 'react'
import BackgroundRow from '../../components/generic/background_row'
import fox from '../../images/fox.jpg'
import BackToHome from '../../components/navigation/back_to_home'
import UserContext from '../../context/user'
import { useTranslate } from '../../context/lang'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../../graphql/queries/user'
import { UPDATE_COMPANY_SETTINGS } from '../../graphql/queries/company'
import { Checkbox } from '../../components/generic/checkbox'
import Button from '../../components/generic/button'
import Field from '../../components/generic/field'
import UpdateButton from '../../components/generic/update-button'
import { await_mutate } from '../../graphql/mutate_promise'
import Item from '../../components/generic/item'
import BigBox from '../../components/layout/big_box'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import OrdersTable from '../order/orders_table'
import { OnlyPremium } from '../../components/subscription/start_free_trial'
import TimezoneSelector from '../setting/timezone_selector'
import { FiSave } from 'react-icons/all'
import { useUserCurrency } from '../subscription/upgrade'

export default function Comments() {
  const translate = useTranslate()
  const user = useContext(UserContext)

  return <>
    <div className="container">
      <BackToHome/>
      <Title color={section_color('comments')} style={{ color: 'white' }}><WelcomeIcon section={'comments'}/> {translate({
        fr: `Demandes de commentaires automatiques`,
        en: `Automatic comment requests`,
      })}</Title>

      <OnlyPremium>
        <BigBox title={translate({
          fr: `Paramètres des commentaires sur Amazon`,
          en: `Comments on Amazon settings`,
        })} is_active={user.company.activate_comment_automatic_request}>
          <CommentsSettings/>
        </BigBox>

        <BigBox title={translate({
          fr: `Historique des demandes de commentaires`,
          en: `Comment request history`,
        })} no_padding={true}>
          <CommentRequestsHistory/>
        </BigBox>
      </OnlyPremium>
    </div>
  </>
}


const normalized_values = [25, 20, 15, 10, 5]

function CommentsSettings() {
  const user = useContext(UserContext)
  const translate = useTranslate()

  // const [accept_comments_notification, set_accept_comments_notification] = React.useState(user.user.accept_comments_notification)

  const [activate_comment_automatic_request, set_activate_comment_automatic_request] = React.useState(user.company.activate_comment_automatic_request || false)
  const [comment_automatic_request_delay, set_comment_automatic_request_delay] = React.useState(user.company.comment_automatic_request_delay || 10)
  const [show_other_delays, set_show_other_delays] = React.useState(normalized_values.indexOf(comment_automatic_request_delay) === -1)

  const [minutes, set_minutes] = React.useState(user.company.comment_automatic_request_minutes % 60)
  const [hours, set_hours] = React.useState(( user.company.comment_automatic_request_minutes - minutes ) / 60)

  const [update_user, update_user_mutation] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetMe'],
  })

  const [update_company, update_company_mutation] = useMutation(UPDATE_COMPANY_SETTINGS, {
    refetchQueries: ['GetMe'],
  })


  return (
    <>
      <div className="row">
        <Checkbox value={activate_comment_automatic_request} setter={set_activate_comment_automatic_request}>
          {translate({
            fr: `Demander un commentaire automatiquement lors de chaque commande`,
            en: `Request a comment each time a new order is received`,
          })}

        </Checkbox>

        {activate_comment_automatic_request ? <div className="col-12">
          <BigBox>
            <div className="row">
              <div className="col-12 mb-2">
                {translate({
                  fr: `Combien de temps après réception de la commande ? (choisir une valeur entre 5 et 30 jours)`,
                  en: `How long after the order has been received? (choose a value between 5 and 30 days)`,
                })}
              </div>
              <div className="col-12">
                {normalized_values.map((days) => {
                  return <Button button={comment_automatic_request_delay === days ? 'primary' : 'secondary'}
                                 className={'mr-2 mb-1'}
                                 onClick={() => {
                                   set_comment_automatic_request_delay(days)
                                   set_show_other_delays(false)
                                 }}>{days} {translate({
                    fr: `jours`,
                    en: `days`,
                  })}</Button>
                })}
                <Button button={show_other_delays ? 'primary' : 'secondary'}
                        onClick={() => set_show_other_delays(true)}>{translate({
                  fr: `Autre`,
                  en: `Other`,
                })}</Button>
                {show_other_delays ? <div className="row">
                  <Field type="number" value={comment_automatic_request_delay}
                         setter={(v) => set_comment_automatic_request_delay(~~v)} col={6}
                         validate={(value) => value >= 5 && value <= 30} step={1} label={translate({
                    fr: 'Jours',
                    en: 'Days',
                  })}/>
                </div> : null}
              </div>
            </div>
          </BigBox>
          <BigBox>
            <div className="row">
              <div className="col-12 font-weight-bold mb-2">
                {translate({
                  fr: `À quelle heure ?`,
                  en: `When?`,
                })}
              </div>
              <Field type={'number'} value={hours} step={1} setter={(h) => set_hours(Math.abs(( h || 0 ) % 24))}
                     placeholder={0} col={'4'} label={translate({
                fr: 'Heure',
                en: 'Hour (24 hour format)',
              })}/>
              <Field type={'number'} value={minutes} step={1} setter={(m) => set_minutes(Math.abs(( m || 0 ) % 60))}
                     placeholder={0} col={'4'} label={translate({
                fr: 'Minute',
                en: 'Minute',
              })}/>
            </div>
            <TimezoneSelector/>
          </BigBox>
        </div> : null}

        <div className="col-12 mt-2">
          <UpdateButton onClick={async () => {
            await Promise.all([
              // await_mutate(update_user, {
              //   variables: {
              //     user_id: user.user.user_id,
              //     accept_comments_notification,
              //   },
              // }),
              await_mutate(update_company, {
                variables: {
                  company_id: user.company.company_id,
                  activate_comment_automatic_request,
                  comment_automatic_request_delay: ~~comment_automatic_request_delay,
                  comment_automatic_request_minutes: ( ~~hours ) * 60 + ( ~~minutes ),
                },
              }),
            ])

            await user.refresh()

          }} {...update_company_mutation} ><FiSave/> {translate({
            fr: `Enregistrer`,
            en: `Save`,
          })}</UpdateButton>
        </div>
      </div>

    </>
  )
}

const CommentRequestsHistory = () => {
  const translate = useTranslate()

  const [limit, set_limit] = React.useState(10)
  const orders = useCachedContext('orders_with_comment_request', limit)

  const default_currency = useUserCurrency()

  const [displayed_orders, set_displayed_orders] = React.useState([])
  React.useEffect(() => {
    if (orders.loading || orders.error) {
      return
    }
    set_displayed_orders(orders)
  }, [orders])

  return <div className="row pb-2">
    <div className="col-12">
      <OrdersTable orders={displayed_orders} override_limit={limit}/>
    </div>
    <div className="col-12">
      <div className="container">
        <div className="row">
          {orders.loading ? <div className="col-12">
            <DefaultLoading/>
          </div> : null}
          {orders.error ? <div className="col-12">
            <DefaultError/>
          </div> : null}
          <div className="col-12">
            {orders.length === limit ? <Button onClick={() => set_limit(limit + 100)}>{translate({
              fr: `Voir plus`,
              en: `See more`,
            })}</Button> : null}
          </div>
        </div>
      </div>
    </div>
  </div>
}
