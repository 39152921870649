import React from 'react'

const referral_key = 'intellifox_referral'
const utm_key = 'intellifox_utm'


export const get_stored_referral = () => {
  const current_referral = window.localStorage.getItem(referral_key)
  const referral = JSON.parse(current_referral)

  if (referral) {
    return referral
  }

  return {
    referral_id: 'LjMb7jKXIjw6-Cf-1G8G',
  }
}
export const get_stored_utm = () => {
  const current_utm = window.localStorage.getItem(utm_key)
  const utm = JSON.parse(current_utm)

  if (utm) {
    return utm
  }

  return {
    utm_source: 'n/a',
  }
}

export const get_referral_override_with_coupon = (coupon) => {
  if (/PHENIX/i.test(coupon) || /ATEAM/i.test(coupon) ) {
    return {
      referral_id: 'Rx6PPB2QPcTCzd3EngYX',
    }
  }

  return false
}



export const get_user_referral_id = () => {
  const user_referral_id = window.localStorage.getItem('ur')

  console.log('user_referral_id=',user_referral_id)

  return user_referral_id
}
