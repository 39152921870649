import React from 'react'
import HelpButton from '../../generic/help_button'
import { YouTube } from '../../../routes/onboarding/onboarding_master'
import Browse_Content, {
  Browse_Content_Section,
  Browse_Content_SubTitle,
  Browse_Content_Title,
} from './browse__content'
import { useTranslate } from '../../../context/lang'
import { onboarding_videos_fr } from '../../../routes/onboarding/onboarding_videos'

export default function BrowseTutorials() {
  const translate = useTranslate()

  return (
    <Browse_Content>
      <Browse_Content_Title>{translate({
        fr: `Tutoriels`,
        en: `Tutorials`,
      })}</Browse_Content_Title>

      <Browse_Content_SubTitle>Édition des images</Browse_Content_SubTitle>

      <Browse_Content_Section>
        <YouTube youtubeId={onboarding_videos_fr.image_editor}/>
      </Browse_Content_Section>

      <Browse_Content_SubTitle>Édition des variations</Browse_Content_SubTitle>

      <Browse_Content_Section>
        <YouTube youtubeId={onboarding_videos_fr.image_editor_variations}/>
      </Browse_Content_Section>
    </Browse_Content>
  )
}
