import * as _ from 'lodash'


export const move_up = (objects, object) => {
  const object_index = _.findIndex(objects, object)
  const background_index = _.findIndex(objects, { object_id: 'background' })

  const new_order = []
  if (background_index !== -1) {
    new_order.push(objects[ background_index ])
  }
  new_order.push(...objects.filter((_, i) => i < object_index && i !== background_index))

  if (objects[ object_index + 1 ] && ( object_index + 1 !== background_index )) {
    new_order.push(objects[ object_index + 1 ])
  }
  if (object_index !== -1 && object_index !== background_index) {
    new_order.push(object)
  }
  new_order.push(...objects.filter((_, i) => i > object_index + 1 && i !== background_index))
  return new_order
}

export const move_to_the_top = (objects, object) => {
  for (let i = 0; i < objects.length; i++) {
    objects = move_up(objects, object)
  }
  return objects
}

export const move_to_the_bottom = (objects, object) => {
  for (let i = 0; i < objects.length; i++) {
    objects = move_down(objects, object)
  }
  return objects
}

export const move_down = (objects, object) => {
  const object_index = _.findIndex(objects, object)
  const background_index = _.findIndex(objects, { object_id: 'background' })

  const new_order = []
  if (background_index !== -1) {
    new_order.push(objects[ background_index ])
  }
  new_order.push(...objects.filter((_, i) => i < object_index - 1 && i !== background_index))

  if (object_index !== -1 && object_index !== background_index) {
    new_order.push(object)
  }
  if (objects[ object_index - 1 ] && object_index - 1 !== background_index) {
    new_order.push(objects[ object_index - 1 ])
  }

  new_order.push(...objects.filter((_, i) => i > object_index && i !== background_index))
  return new_order
}
