import React from 'react'
import Settings from './setting'
import ProductEditV2 from './product_v2/edit'
import { compute_region_id_for_market_place, useSourceMarketPlace } from '../hooks/useMarketPlace'
import Redirect from '../components/generic/redirect'
import SsoMastermind from './sso_mastermind/sso_mastermind'
import EditVisual from './product_v2/edit_visual'
import AmazonConfirmation, { AmazonConnectFromSellerCentral } from './onboarding/amazon_confirmation'
import Order from './order'
import moment from 'moment'
import ProductV2 from './product_v2'
import Comments from './comments'
import Alerts from './alerts'
import Subscription from './subscription'
import Welcome from './welcome'
import Help from './help'
import Cancel from './subscription/cancel'
import Upgrade from './subscription/upgrade'
import { navigate } from '../absolute_link'
import { AddAmazonAdsAccountReturn } from './setting/amazon_ads_accounts'
import Support from './setting/support'
import { useFrench, useLang } from '../context/lang'
import NavigationButton from '../components/generic/navigation-button'
import { ShareResults } from './order/results/share_results'
import { Referral } from './referral'
import { Conversion } from './ppc/ppc_v2'
import { ProductFinder } from './product_v2/product_finder'
import { BackFromSellerSuite } from './back_from_sellersuite'

// const LaunchMergeProducts = ({ product_ids }) => {
//   const company_id = useCompanyId()
//
//   const [mergeProducts, mutation] = useMutation(gql`
//       mutation MergeProducts($company_id: ID!, $product_ids: [ID]!) {
//           mergeProducts(company_id: $company_id, product_ids: $product_ids) {
//               product_id
//           }
//       }
//   `, {
//     variables: {
//       company_id,
//     },
//   })
//
//   return <UpdateButton className={'mr-2 mb-2'}
//                        onClick={() => mergeProducts({ variables: { product_ids } })} {...mutation}>
//     Lancer fusion de {product_ids.length} produits
//   </UpdateButton>
// }


export const routes = [
  {
    path: '/', Component: () => {
      return <Redirect to={`/welcome`}/>
    },
    title: 'Bienvenue',
  },

  {
    path: '/welcome', Component: Welcome,
    title: 'Bienvenue',
  },
  {
    path: '/product',
    Component: ProductV2,
    // Menu: ProductListMenu,
    title: 'Optimisation des produits',
  },
  {
    path: '/comments', Component: Comments,
    title: 'Commentaires',
  },
  {
    path: '/alerts', Component: Alerts,
    title: 'Alertes',
  },
  {
    path: '/subscription', Component: Subscription,
    title: 'Abonnement',
  },
  {
    path: '/subscription/upgrade', Component: Upgrade,
    title: 'Abonnement',
  },
  {
    path: '/subscription/cancel', Component: Cancel,
    title: 'Abonnement',
  },
  {
    path: '/help', Component: Help,
    title: 'Aide',
  },

  {
    path: '/product/edit/:product_id',
    Component: ({ product_id }) => {
      const source_market_place = useSourceMarketPlace()
      return <Redirect to={`/product/edit/${product_id}/${source_market_place}/start`}/>
    },
    title: 'Optimisation des produits',
  },

  {
    path: '/product/edit/:product_id/:market_place/visual/:region_id/:index',
    Component: ({ product_id, market_place, region_id, index }) => {
      return <EditVisual product_id={product_id} market_place={market_place} region_id={region_id} index={index}/>
    },
    title: 'Optimisation des produits',
  },
  {
    path: '/product/edit/:product_id/:market_place/visual/:index',
    Component: ({ product_id, market_place, index }) => {
      const region_id = compute_region_id_for_market_place(market_place)
      return <Redirect to={`/product/edit/${product_id}/${market_place}/visual/${region_id}/${index}`}/>
    },
    title: 'Optimisation des produits',
  },

  {
    path: '/product/edit/:product_id/:market_place',
    Component: ({ product_id, market_place }) => {
      return <Redirect to={`/product/edit/${product_id}/${market_place}/start`}/>
    },
    title: 'Optimisation des produits',
  },

  {
    path: '/product/edit/:product_id/:market_place/*actions',
    Component: ProductEditV2,
    // Menu: ProductMenu,
    title: 'Optimisation des produits',
  },

  {
    path: '/product/redirect/:asin/:market_place',
    Component: ProductFinder,
    title: 'Redirection vers le produit en cours...'
  },

  {
    path: '/settings', Component: Settings,
    title: 'Paramètres',
  },
  {
    path: '/settings/support',
    Component: Support,
    title: 'Paramètres',
  },
  {
    path: '/settings/add_amazon_ads_account', Component: AddAmazonAdsAccountReturn,
    title: 'Paramètres',
  },
  {
    path: '/lwa/add_amazon_ads_account', Component: AddAmazonAdsAccountReturn,
    title: 'Paramètres',
  },
  {
    path: '/sso_mastermind', Component: SsoMastermind,
    title: 'Connexion au mastermind...',
  },
  {
    path: '/amazon/confirmation', Component: AmazonConfirmation,
    title: 'Connexion à Amazon...',
  },
  {
    path: '/amazon/connect', Component: AmazonConnectFromSellerCentral,
    title: 'Connexion à Amazon...',
  },
  {
    path: '/order', Component: () => {
      return <Redirect to={`/order/${moment().format('YYYY')}/${moment().format('MM')}`}/>
    },
  },
  {
    path: '/order/:year/:month', Component: ({ year, month }) => {
      const from = moment(`${year}-${month}-01`).startOf('month')
      const to = moment(from).endOf('month')

      return <Redirect to={`/order/period/${from.format('YYYY-MM-DD')}/${to.format('YYYY-MM-DD')}`}/>
    },
    title: 'Suivi des ventes',
  },
  {
    path: '/order/period/:from/:to', Component: Order,
    title: 'Suivi des ventes',
  },
  {
    path: '/order/share', Component: ShareResults,
    title: 'Partage des résultats',
  },
  {
    path: '/ppc', Component: Conversion,
    title: 'Optimisation des publicités',
  },

  // {
  //   path: '/black-friday',
  //   Component: BlackFriday,
  //   title: 'Offre spéciale Black Friday',
  // },
  {
    path: '/referral',
    Component: Referral,
    title: 'Programme de parrainage',
  },

  {
    path: '/community',
    Component: () => {

      const lang = useLang()

      if (lang === 'fr') {
        window.location = 'https://community.intellifox.com/c/francais/16'
      } else {
        window.location = 'https://community.intellifox.com/c/english/17'
      }
      return null
    },
    title: 'Programme de parrainage',
  },

  {
    path: '/back_from_sellersuite',
    title: 'Loading...',
    Component: BackFromSellerSuite
  },

  // {
  //   path: 'appsumo',
  //   title: `Bienvenue aux sumolings d'AppSumo`,
  //   Component: AppSumo
  // },

  {
    path: '*',
    title: 'Page non trouvée',
    Component: () => {
      const french = useFrench()
      return <div className="container pb-5 pt-5">
        <div className="row">
          <div className="col-12">
            <h1>{french(`Page non trouvée`)}</h1>
          </div>
          <div className="col-12">
            <NavigationButton to={'/'}>{french(`Retour à l'accueil`)}</NavigationButton>
          </div>
        </div>
      </div>
    },
  },
  //
  // {
  //   path: '/debug-tmp',
  //   Component: DebugTmp,
  //   title: 'Debug'
  // }

]


export const navigate_to_product = (product_id, market_place, menu = 'preview') => {
  navigate(get_edit_product_route(product_id, market_place, menu))
}

export const get_edit_product_route = (product_id, market_place, menu = 'preview') => {
  return `/product/edit/${product_id}/${market_place}/start#?menu=${menu}`
}
