import React from 'react'
import BackToHome, { BackTo } from '../../../components/navigation/back_to_home'
import Title from '../../../components/layout/title'
import { section_color, WelcomeIcon } from '../../colors'
import NavigationButton from '../../../components/generic/navigation-button'
import {
  FaCopy,
  FaFacebook,
  FaLinkedin,
  FaMailBulk,
  FaTwitter,
  FiArrowDown,
  FiArrowRight,
  FiCheck,
  FiShare2,
} from 'react-icons/all'
import { useFrench, useTranslate } from '../../../context/lang'
import BigBox from '../../../components/layout/big_box'
import Button from '../../../components/generic/button'
import { CompactItem, ItemTitle } from '../../../components/generic/item'
import copy_to_clipboard from 'copy-to-clipboard'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import moment from 'moment'
import ExternalLinkButton from '../../../components/generic/external-link-button'

export const ShareResults = () => {
  const translate = useTranslate()
  const french = useFrench()


  return <div className={'pb-5 container-fluid'}>
    <div className="container mb-5">
      <BackTo to="/order" label={french('Retour au suivi des ventes')}/>
      <Title color={section_color('order')} style={{ color: 'white' }}><WelcomeIcon
        section={'share'}/> {translate({
        fr: `Partage des résultats`,
      })}</Title>

      <BigBox title={french(`Quels résultats partager ?`)}>
        <ShareableResults/>
      </BigBox>
      <div className="row mt-5">
        <div className="col-12">
          <div className="alert alert-success small font-weight-bold">
            <FiCheck/> {french(`Les partages ne divulguent pas les produits vendus. Tes secrets sont sains et saufs !`)}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export const ShareResultsButton = () => {
  const shareable_results = useCachedContext('shareable_results')
  const french = useFrench()


  if (shareable_results.loading) {
    return null
  }

  if (shareable_results.error) {
    return null
  }

  if (shareable_results.length === 0) {
    return null
  }

  return <NavigationButton to={'/order/share'}><FiShare2/> {french(`Partager les résultats`)} <span className="badge badge-success small">{french(`Nouveau !`)}</span>
  </NavigationButton>
}

const ShareableResults = () => {
  const shareable_results = useCachedContext('shareable_results')
  const french = useFrench()

  const [selected_result, set_selected_result] = React.useState(0)


  if (shareable_results.loading) {
    return <DefaultLoading/>
  }

  if (shareable_results.error) {
    return <DefaultError/>
  }

  if (shareable_results.length === 0) {
    return <div className="row">
      <div className="col-12">
        {french(`Il n'y a encore aucun résultat à partager...`)}
      </div>
    </div>
  }


  const current_shareable_result = shareable_results[ selected_result ]

  return <>

    <div className="row">
      {shareable_results.map(({ shareable_result_id, image_url, start, duration }, i) => {
        const is_monthly = duration === 'month'
        const is_weekly = duration === 'week'

        return <div className="col-auto clickable" key={shareable_result_id}
                    style={{ opacity: i === selected_result ? 1 : 0.3 }} onClick={() => set_selected_result(i)}>
          <div className="text-center small font-weight-bold">
            {is_monthly ? <>{moment(start).format('MMMM YYYY')}</> : null}
            {is_weekly ? <>{moment(start).format('LL')}
              <FiArrowRight/> {moment(start).add(1, duration).add(-1, 'day').format('LL')} </> : null}
          </div>
          <div>
            <img src={image_url} style={{ width: 300, height: 300 }}
                 className={'rounded shadow'}/>
          </div>
          {i === selected_result ? <div className="text-center font-weight-bold">
            <FiArrowDown/>
          </div> : null}
        </div>
      })}
    </div>
    <div className="row mt-3">
      <div className="col-12">
        <CompactItem label={french(`Comment partager ces résultats ?`)}>
          <ShareResult shareable_result={current_shareable_result}/>
        </CompactItem>
      </div>
    </div>
  </>

}


const url_params = (params) => {
  return Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[ key ])}`).join('&')
}

const ShareResult = ({ shareable_result }) => {
  const french = useFrench()

  const { duration, start, share_url } = shareable_result

  const is_monthly = duration === 'month'
  const is_weekly = duration === 'week'

  const sharing_text_localized = french(`Mes résultats sur Amazon`)
  const certified_by_intellifox = french(`Résultats certifiés par Intellifox`)

  let date = ''
  if (is_monthly) {
    date = `${moment(start).format('MMMM YYYY')}`
  }
  if (is_weekly) {
    date = `${moment(start).format('LL')} ➜ ${moment(start).add(1, duration).add(-1, 'day').format('LL')}`
  }

  const sharing_text = `${sharing_text_localized} - ${date}\n\n${certified_by_intellifox} - ${share_url}`

  // https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Flocalhost%3A2024%2Fmy-amazon-results%2Fk41vpJ64W&quote=My results on Amazon - March 2022%0A%0AResults certified by Intellifox - http%3A%2F%2Flocalhost%3A2024%2Fmy-amazon-results%2Fk41vpJ64W&hashtag=intellifox
  return <div className="row">
    <div className="col-12">
      <ExternalLinkButton className={'mr-2'}
                          to={`https://www.facebook.com/sharer/sharer.php?${url_params({
                            u: share_url,
                            quote: sharing_text,
                            hashtag: 'intellifox',
                          })}`}>
        <FaFacebook/> Facebook
      </ExternalLinkButton>
      <ExternalLinkButton className={'mr-2'}
                          to={`https://twitter.com/intent/tweet/?${url_params({
                            url: share_url,
                            text: sharing_text,
                          })}`}>
        <FaTwitter/> Twitter
      </ExternalLinkButton>
      <ExternalLinkButton className={'mr-2'}
                          to={`https://www.linkedin.com/sharing/share-offsite/?${url_params({
                            url: share_url,
                          })}`}>
        <FaLinkedin/> LinkedIn
      </ExternalLinkButton>
      <ExternalLinkButton className={'mr-2'}
                          to={`mailto:?${url_params({
                            subject: `${sharing_text_localized} - ${date}`,
                            body: sharing_text,
                          })}`
                          }>
        <FaMailBulk/> {french(`Email`)}
      </ExternalLinkButton>

      <span className={'mr-2'}>{french(`ou...`)}</span>
      <Button className={'mr-2'} button={'secondary'}
              onClick={() => copy_to_clipboard(share_url)}><FaCopy/> {french(`Copier le lien de partage`)}
      </Button>
    </div>
  </div>
}
