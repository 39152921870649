import React, { useContext } from 'react'
import useHistory from '../../hooks/useHistory'
import useLocalStorage from '../../hooks/useLocalStorage'
import * as _ from 'lodash'
import useHashParam from 'use-hash-param'
import copy_to_clipboard from 'copy-to-clipboard'
import { apply_overrides } from './override/apply_overrides'

export const DesignerContext = React.createContext({
  product: null,

  history: null,
  current_objects: null,


  edit_overrides: null, set_edit_overrides: null,
  current_product_id: null, set_current_product_id: null,

  multiple_selection: null, set_multiple_selection: null,

  current_menu: null,
  set_current_menu: null,
  current_option: null,
  set_current_option: null,

  update_objects_save_history: null,

  save_history: null,
  delete_selected_objects: null,
  duplicate_object: null,
  update_single_object: null,
  update_object_props: null,
  update_object_several_props: null,
  update_multiple_objects: null,
  add_object: null,

  click_on_object: null,
  selected_objects: null,
  reset_selection: null,
  get_selected_object: null,
  get_selected_objects: null,
  select_all_objects: null,

  update_or_create_background: null,

  // actions !
  queue_action: null,

  // groups
  group_selected_objects: null,
  ungroup_selected_objects: null,
  are_all_selected_objects_grouped: null,

  // colors

  compute_color: null,

  // copy/paste,
  copy: null,
  paste: null,
})
export const designer_context_for_product = (product) => {
  return {
    compute_color: (value) => {
      if (!product || !product.color_palette) {
        return value
      }

      // first find if value is a color id in product palette
      const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
      if (palette_color) {
        return palette_color.color
      }

      return value
    },
    product,
    current_product_id: product.product_id
  }
}
export const useDesignerContextInit = ({
                                         product, update_objects, overrides, update_overrides, history_id, width, height,
                                         initial_objects, initial_overrides,
                                       }) => {
  const [current_objects, save_history, update_head, history, reset_history] = useHistory({
    objects: initial_objects,
    overrides: initial_overrides,
  }, ({ objects, overrides }) => {
    update_objects(objects)
    update_overrides(overrides)
  }, history_id)

  const [current_menu, set_current_menu] = useLocalStorage('intellifox_current_menu', null)
  const [current_option, set_current_option] = React.useState(null)
  const [current_product_id, set_current_product_id] = useHashParam('p', product.product_id)
  const [edit_overrides, set_edit_overrides] = React.useState(!(current_product_id === product.product_id))
  const [multiple_selection, set_multiple_selection] = React.useState(false)

  const update_objects_save_history = (new_objects, save = true) => {
    if (save) {
      save_history({ ...current_objects, objects: new_objects })
    } else {
      update_head({ ...current_objects, objects: new_objects })
    }

    update_objects(new_objects)
  }
  const update_overrides_save_history = (product_id, product_overrides, save = true) => {
    // console.log('update overrides ! ', product_id, product_overrides)
    if (save) {
      save_history({
        ...current_objects, overrides: {
          ...overrides,
          [ product_id ]: product_overrides,
        },
      })
    } else {
      update_head({
        ...current_objects, overrides: {
          ...overrides,
          [ product_id ]: product_overrides,
        },
      })
    }

    update_overrides({
      ...overrides,
      [ product_id ]: product_overrides,
    })
  }

  const get_new_id = () => {
    const object_id_max = current_objects.objects.reduce((object_id_max, obj) => {
      return Math.max(object_id_max, ~~obj.object_id || 0)
    }, 0)
    return '' + ( object_id_max + 1 )
  }

  const get_new_ids = (length) => {
    const object_id_max = current_objects.objects.reduce((object_id_max, obj) => {
      return Math.max(object_id_max, ~~obj.object_id || 0)
    }, 0)

    const new_ids = []
    for (let i = 0; i < length; i++) {
      new_ids.push('' + ( object_id_max + i + 1 ))
    }
    return new_ids
  }

  const [_selected_objects, __set_selected_objects] = useHashParam('s', JSON.stringify({}))
  const [selected_objects, _set_selected_objects] = React.useState(JSON.parse(_selected_objects || '{}'))
  React.useEffect(() => {
    __set_selected_objects(JSON.stringify(selected_objects))
  }, [selected_objects])
  const set_selected_objects = (whatevs) => {
    _set_selected_objects(whatevs)
    set_current_option(null)
  }


  const get_selected_object = React.useCallback(() => {
    const selected_ids = Object.keys(selected_objects).filter((object_id) => selected_objects[ object_id ])

    if (selected_ids.length !== 1) {
      return null
    }

    return current_objects.objects.find(({ object_id }) => object_id === selected_ids[ 0 ])
  }, [current_objects, current_objects.objects, selected_objects])

  const get_selected_objects = React.useCallback(() => {
    const selected_ids = Object.keys(selected_objects).filter((object_id) => selected_objects[ object_id ])

    return apply_overrides(current_objects.objects.filter(({ object_id }) => selected_ids.indexOf(object_id) !== -1), overrides[current_product_id])

  }, [selected_objects, current_objects.objects, overrides, current_product_id])


  const delete_selected_objects = React.useCallback(() => {
    if (edit_overrides) {
      return
    }
    const filtered_objects = current_objects.objects.filter(({ object_id }) => !selected_objects[ object_id ])
    update_objects_save_history(filtered_objects)
    set_selected_objects({})

    // // Clean overrides
    setTimeout(() => {
      const object_ids = filtered_objects.map(o => o.object_id)
      const cleaned_overrides = Object.keys(overrides).reduce((cleaned_overrides, product_id) => {
        if (!overrides[ product_id ]) {
          cleaned_overrides[ product_id ] = overrides[ product_id ]
        } else {
          cleaned_overrides[ product_id ] = overrides[ product_id ].filter(({ object_id }) => object_ids.indexOf(object_id) !== -1)
        }

        return cleaned_overrides
      }, {})

      save_history({
        objects: filtered_objects,
        overrides: cleaned_overrides,
      })
      update_overrides(cleaned_overrides)

    }, 0)


  }, [current_objects.objects, selected_objects, update_objects_save_history])

  const reinitialize_selected_objects = React.useCallback(() => {
    if (!edit_overrides) {
      return
    }

    update_overrides_save_history(current_product_id,
      current_objects.overrides[ current_product_id ].filter(({ object_id }) => !selected_objects[ object_id ]))
  }, [current_product_id, current_objects.objects, selected_objects, update_overrides_save_history])


  const duplicate_object = React.useCallback(({ deltaX = 20, deltaY = 20 } = {}, selected_objects = get_selected_objects()) => {
    if (edit_overrides) {
      return
    }

    if (selected_objects.length === 0) {
      return
    }

    const new_ids = get_new_ids(selected_objects.length)

    const new_group_ids = {}
    const get_new_group_id = (group_id) => {
      if (!new_group_ids[ group_id ]) {
        new_group_ids[ group_id ] = generate_group_id()
      }
      return new_group_ids[ group_id ]
    }

    update_objects_save_history(
      [
        ...current_objects.objects,
        ...selected_objects.map((selected_object, i) => {
          const props = selected_object.props && selected_object.props.group_id ? {
            ...selected_object.props,
            group_id: get_new_group_id(selected_object.props.group_id),
          } : selected_object.props
          return {
            ..._.cloneDeep(selected_object),
            object_id: new_ids[ i ],
            x: selected_object.x + deltaX,
            y: selected_object.y + deltaY,
            props,
          }
        }),

      ],
    )

    set_selected_objects(new_ids.reduce((selected_objects, new_id) => {
      return {
        ...selected_objects,
        [ new_id ]: true,
      }
    }, {}))

  }, [current_objects.objects, get_new_id, get_selected_object, selected_objects.x, selected_objects.y, update_objects_save_history])

  const update_single_object = (object_id, updates, save = true, product_id, do_edit_overrides) => {
    // updates is object of updates like : {x: 42}
    update_multiple_objects([{
      object_id, updates,
    }], save, product_id, do_edit_overrides)
  }


  const update_multiple_objects = (objects, save = true, product_id = current_product_id, do_edit_overrides = edit_overrides) => {
    if (do_edit_overrides) {

      // objects: {object_id, updates}
      // overrides[product_id] is empty or an array of: {object_id, ...whatever}
      // need to apply objects to overrides
      const new_overrides = ( objects ).reduce((new_overrides, { object_id, updates }) => {
        const existing_override = new_overrides.find(o => o.object_id === object_id) || {}

        return [
          ...new_overrides.filter(o => o.object_id !== object_id),
          {
            object_id,
            ...existing_override,
            ...updates,
          },
        ]
      }, overrides[ product_id ] || [])

      update_overrides_save_history(product_id, new_overrides, save)
    } else {
      update_objects_save_history(
        current_objects.objects.reduce((update_objects, obj) => {
          const update = objects.find((o) => o.object_id === obj.object_id)
          if (update) {
            update_objects.push({
              ...obj,
              ...update.updates,
            })
          } else {
            update_objects.push(obj)
          }
          return update_objects
        }, []),
        save,
      )
    }
  }

  const update_object_props = ({ object, field, value }) => {
    update_object_several_props({ object, props: [{ field, value }] })
  }

  const update_object_several_props = ({ object, props, product_id = current_product_id, do_edit_overrides = edit_overrides }) => {
    const new_props = props.reduce((new_props, { field, value }) => {
      new_props[ field ] = value
      return new_props
    }, {})

    if (do_edit_overrides) {
      // console.log("edit overrides", {object, props})
      let existing_override
      if (overrides[ product_id ]) {
        existing_override = overrides[ product_id ].find((o) => o.object_id === object.object_id)
      }
      if (!existing_override) {
        existing_override = { props: {} }
      }


      update_single_object(object.object_id, {
        props: {
          ...existing_override.props,
          ...new_props,
        },
      }, true, product_id, do_edit_overrides)
    } else {
      update_single_object(object.object_id, {
        props: {
          ...object.props,
          ...new_props,
        },
      })
    }

  }

  const add_object = (object) => {
    object.object_id = get_new_id()
    update_objects_save_history([...current_objects.objects, object])
  }

  const click_on_object = (e, object_id) => {
    e.stopPropagation()
    const object = current_objects.objects.find(o => o.object_id === object_id)
    const group_id = object.props.group_id

    if (!!group_id) {
      const object_ids = current_objects.objects.filter(o => o.props.group_id === group_id).map(o => o.object_id)
      set_selected_objects((selected_objects) => {
        const should_be_selected = !selected_objects[ object_id ]
        return object_ids.reduce((new_selected_objects, object_id) => {
          return {
            ...new_selected_objects,
            [ object_id ]: should_be_selected,
          }
        }, multiple_selection ? selected_objects : {})
      })
    } else if (multiple_selection) {
      set_selected_objects((selected_objects) => ( {
        ...selected_objects,
        [ object_id ]: !selected_objects[ object_id ],
      } ))
    } else {
      set_selected_objects((selected_objects) => ( {
        [ object_id ]: !selected_objects[ object_id ],
      } ))
    }
  }

  const select_next_object = () => {
    let current_object_i = -1
    for (let i = 0; i < current_objects.objects.length; i++) {
      if (selected_objects[ current_objects.objects[ i ].object_id ]) {
        current_object_i = i
        break
      }
    }

    const new_selected_object = current_objects.objects[ current_object_i + 1 < current_objects.objects.length ? current_object_i + 1 : 0 ]
    if (new_selected_object) {
      set_selected_objects({
        [ new_selected_object.object_id ]: true,
      })
    }
  }

  const select_all_objects = () => {
    const all_selected = current_objects.objects.reduce((all_selected, o) => {
      return {
        ...all_selected,
        [ o.object_id ]: true,
      }
    }, {})

    set_selected_objects(all_selected)
  }

  const reset_selection = () => {
    set_selected_objects({})
  }

  const update_or_create_background = (background) => {
    const base_background = {
      object_id: 'background',
      x: 0,
      y: 0,
      width: 500,
      height: 500,
      angle: 0,
    }

    if (current_objects.objects.find((o) => o.object_id === 'background')) {
      update_single_object('background', {
        ...base_background,
        ...background,
      })
    } else {
      update_objects_save_history([
        {
          ...base_background,
          ...background,
        },
        ...current_objects.objects,
      ])
    }
  }

  const [action, set_action] = React.useState(null)
  const queue_action = (fn) => {
    set_action(() => ({ x, y }) => {
      fn({ x, y })
      set_action(null)
    })
  }


  const generate_group_id = () => {
    return `group_${Math.random().toString(36).substr(2, 16)}` // njsscan-ignore: node_insecure_random_generator
  }
  const group_selected_objects = () => {
    const selected_objects = get_selected_objects()

    const group_id = generate_group_id()

    update_multiple_objects(selected_objects.map((o) => {
      return {
        object_id: o.object_id,
        updates: {
          props: {
            ...o.props,
            group_id,
          },
        },
      }
    }), true)
  }

  const ungroup_selected_objects = () => {
    const selected_objects = get_selected_objects()

    console.log('ungroup selected objects', selected_objects.map(o => o.props.group_id))

    update_multiple_objects(selected_objects.map((o) => {
      return {
        object_id: o.object_id,
        updates: {
          props: {
            ...o.props,
            group_id: null,
          },
        },
      }
    }), true)
  }

  const are_all_selected_objects_grouped = () => {
    const selected_objects = get_selected_objects()
    const group_ids = selected_objects.map((o) => o.props.group_id)
    return _.uniq(group_ids).length === 1 && !!group_ids[ 0 ]
  }

  const compute_color = (value) => {
    if (!product || !product.color_palette) {
      return value
    }

    // first find if value is a color id in product palette
    const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
    if (palette_color) {
      return palette_color.color
    }

    return value
  }

  const [clipboard, set_clipboard] = useLocalStorage('intellifox_visual_editor_clipboard', '')
  const copy = React.useCallback(() => {
    const sss = get_selected_objects()

    console.log('copy', JSON.stringify(sss))

    set_clipboard(JSON.stringify(sss))
    copy_to_clipboard(JSON.stringify(sss))
  }, [selected_objects, get_selected_objects, set_clipboard])

  const paste = React.useCallback(() => {
    try {
      console.log(paste, clipboard)
      const objects = JSON.parse(clipboard)

      duplicate_object({}, objects)
    } catch (e) {
      console.log(e)
    }
  }, [clipboard, duplicate_object])

  return {
    product,
    width,
    height,

    edit_overrides, set_edit_overrides,
    current_product_id, set_current_product_id,
    multiple_selection, set_multiple_selection,

    history,
    current_objects,

    current_menu,
    set_current_menu,
    current_option,
    set_current_option,

    update_objects_save_history,

    save_history,
    delete_selected_objects,
    reinitialize_selected_objects,
    duplicate_object,
    update_single_object,
    update_object_props,
    update_object_several_props,
    add_object,
    update_multiple_objects,

    click_on_object,
    set_selected_objects,
    selected_objects,
    reset_selection,
    get_selected_object,
    get_selected_objects,
    select_next_object,
    select_all_objects,


    update_or_create_background,
    reset_history,

    action,
    queue_action,

    group_selected_objects,
    ungroup_selected_objects,
    are_all_selected_objects_grouped,
    compute_color,

    copy, paste, clipboard,
  }

}

export const useDesignerContext = () => useContext(DesignerContext)
