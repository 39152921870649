import React, { useContext } from 'react'
import { get_client } from '../graphql/simple-client'
import { GET_TRANSLATIONS } from '../graphql/queries/translation'
import useCompanyId from '../hooks/useCompanyId'
import useMarketPlace, { get_marketplace, useMarketPlaceDetails, useSourceMarketPlace } from '../hooks/useMarketPlace'

const TranslationsContext = React.createContext([])

export const fetch_translations = async (company_id, source_lang, target_lang, texts) => {
  if (!texts || texts.length === 0) {
    return []
  }

  const client = get_client()

  const response = await client.request(GET_TRANSLATIONS, {
    company_id,
    source_lang,
    target_lang,
    texts,
  })
  return response.translations
}


const useSourceAndTargetLang = () => {
  const market_place = useMarketPlace()
  const source_market_place = useSourceMarketPlace()

  const market_place_details = useMarketPlaceDetails(market_place)
  const source_market_place_details = useMarketPlaceDetails(source_market_place)

  const source_lang = market_place_details.lang
  const target_lang = source_market_place_details.lang

  return { source_lang, target_lang }
}

export const useAddWordsToTranslate = () => {
  const { source_lang, target_lang } = useSourceAndTargetLang()

  const [_, add_words_to_translate] = useTranslationsStore()

  return (keywords) => {
    add_words_to_translate({
      source_lang,
      target_lang,
      keywords,
    })
  }
}

export const useKeywordTranslate = (keyword) => {
  const { source_lang, target_lang } = useSourceAndTargetLang()

  const [get_translate] = useTranslationsStore()

  return get_translate({
    source_lang,
    target_lang,
    keyword,
  })
}

export const useTranslationsInit = (company_id) => {
  const [translation_store, set_translation_store] = React.useState({})

  const translation_store_ref = React.useRef({})

  const add_words_to_translate = React.useCallback(({
                                                      source_lang,
                                                      target_lang,
                                                      keywords,
                                                    }) => {
    if (source_lang === target_lang) {
      return
    }

    const go = async () => {
      keywords = keywords.filter((keyword) => {
        const translation_id = `${source_lang}_to_${target_lang}_${keyword}`
        return !translation_store_ref.current[ translation_id ]
      })

      if (keywords.length === 0) {
        return
      }

      for (let i = 0; i < keywords.length; i++) {
        const keyword = keywords[ i ]
        const translation_id = `${source_lang}_to_${target_lang}_${keyword}`
        translation_store_ref.current[ translation_id ] = 'loading'
      }

      try {
        const translations = await fetch_translations(company_id, source_lang, target_lang, keywords)

        for (let i = 0; i < translations.length; i++) {
          const keyword = keywords[ i ]
          const translation_id = `${source_lang}_to_${target_lang}_${keyword}`
          translation_store_ref.current[ translation_id ] = translations[ i ]
        }
      } catch (e) {
        console.log('could not get translations', e)

        for (let i = 0; i < keywords.length; i++) {
          const keyword = keywords[ i ]
          const translation_id = `${source_lang}_to_${target_lang}_${keyword}`
          translation_store_ref.current[ translation_id ] = 'error'
        }
      }

      set_translation_store(() => {
        return {
          ...translation_store_ref.current,
        }
      })
    }

    go().catch(e => console.error(e))
  }, [company_id])

  const get_translation = React.useCallback(({
                                               source_lang,
                                               target_lang,
                                               keyword,
                                             }) => {
    if (source_lang === target_lang) {
      return ''
    }

    const translation_id = `${source_lang}_to_${target_lang}_${keyword}`

    return translation_store[ translation_id ]
  }, [translation_store])

  return [get_translation, add_words_to_translate]
}


export const useTranslationsStore = () => useContext(TranslationsContext)

export default TranslationsContext
