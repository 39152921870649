import React from 'react'
import { useTranslate } from '../../context/lang'
import moment from 'moment'

const Warning = ({ label, children }) => {
  const translate = useTranslate()

  return <div className="alert alert-warning mb-3">
    <div className="row">
      <div className="col-12 font-weight-bold">
        {label} {translate({
        fr: `Attention`,
        en: `Warning`,
      })}
      </div>
      <div className="col-12">
        {children}
      </div>
    </div>
  </div>
}
const Error = ({ label, children }) => {
  const translate = useTranslate()

  return <div className="alert alert-danger mb-3">
    <div className="row">
      <div className="col-12 font-weight-bold">
        {label} {translate({
        fr: `Erreur`,
        en: `Error`,
      })}
      </div>
      <div className="col-12">
        {children}
      </div>
    </div>
  </div>
}
export default function ValidatePrices({ product }) {
  const translate = useTranslate()

  if (product.loading) {
    return <div>Chargement en cours...</div>
  }

  if (product.error) {
    return <div>Erreur lors du chargement.</div>
  }

  const is_sale_end_date_before_now = product.product_info.sale_end_date && moment(product.product_info.sale_end_date).isBefore(moment())
  const is_sale_end_date_before_this_week = product.product_info.sale_end_date && moment(product.product_info.sale_end_date).isBetween(moment(), moment().add(6, 'days'))

  const is_reduced_price_more_than_standard_price = product.product_info.standard_price && product.product_info.sale_price && product.product_info.standard_price <= product.product_info.sale_price

  const is_sale_end_date_before_sale_from_date = product.product_info.sale_from_date && product.product_info.sale_end_date && moment(product.product_info.sale_end_date).isBefore(product.product_info.sale_from_date)

  const { msrp, standard_price, sale_price } = product.product_info

  const is_msrp_lower_than_other_price = msrp && ( ( standard_price && standard_price > msrp ) || ( sale_price && sale_price >= msrp ) )

  return (
    <>
      {is_sale_end_date_before_now ? <Warning>
        {
          translate({
            fr: `Le prix réduit n'est plus valide : sa date de fin est dépassée !`,
            en: 'Sale price is not valid anymore: its end date has passed.',
          })
        }
      </Warning> : null}
      {is_sale_end_date_before_this_week ? <Warning>
        {
          translate({
            fr: `Le prix réduit ne sera plus valide dans moins de 7 jours. Pense à modifier sa date de fin si tu veux le conserver.`,
            en: `Sale price will expire in less than 7 days. Don't forget to update it if you wish to keep it.`,
          })
        }
      </Warning> : null}
      {is_reduced_price_more_than_standard_price ? <Error>
        {
          translate({
            fr: 'Le prix standard est moins élevé que le prix réduit',
            en: 'The standard price is less than the sale price',
          })
        }
      </Error> : null}
      {is_sale_end_date_before_sale_from_date ? <Error>
        {
          translate({
            fr: 'La fin de la réduction est avant la date de début de réduction',
            en: 'The end sale date is set before the start',
          })
        }
      </Error> : null}
      {is_msrp_lower_than_other_price ? <Error>
        {
          translate({
            fr: 'Le prix de vente suggéré doit être plus élevé que le prix normal et le prix réduit.',
            en: 'The recommended retail price must be higher than standard price and sale price.',
          })
        }
      </Error> : null}
    </>
  )
}
