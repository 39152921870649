import React, { useState } from 'react'
import Button from './button'
import { useKeywordTranslate } from '../../context/translations'


export const keyword_category_to_button = (category) => {
  let button
  if (category === 'black') {
    button = 'dark'
  } else if (category === 'red') {
    button = 'danger'
  } else if (category === 'yellow') {
    button = 'warning'
  } else {
    button = 'success'
  }

  return button
}

export const KeywordSelector = ({ select, keyword, intensity, selected, category, children }) => {

  const [is_selected, set_is_selected] = React.useState(selected)

  const translation = useKeywordTranslate(keyword)

  React.useEffect(() => {
    set_is_selected(selected)
  }, [selected])

  const update_value = async (selected) => {
    set_is_selected(selected)
    select({ keyword, intensity, selected, category })
  }

  if (is_selected) {
    return null
  }

  return <div className="col-auto mb-2 mr-2">
    <Button button={keyword_category_to_button(category)} className={`highlight`}
            onClick={() => update_value(!selected)}>{keyword} {translation && translation !== keyword ?
      <><br/><span className="small font-weight-bold">{translation}</span></> : null}
    </Button>
    {children ? <div className="row">
      <div className="col-12">
        {children}
      </div>
    </div> : null}
  </div>
}
