import React from 'react'

import { FaChess, FaMeteor, FaRegImages, FaPaperPlane } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { MdTextFields } from 'react-icons/md'

export const product_section = {
  path: '/',
  Icon: FaChess,
  label: 'Produits',
  description: 'Tous les produits',
}
export const single_product_section = ({ product_id, sku }) => ( {
  path: `/product/edit/${product_id}`,
  Icon: FaChess,
  label: sku.trim().toUpperCase(),
  description: 'Éditer les produits',
} )
export const publish_section = {
  path: '/publish',
  Icon: FaPaperPlane,
  label: 'Publier',
  description: 'Publier les modifications',
}
export const snippet_section = {
  path: '/snippet',
  Icon: MdTextFields,
  label: 'Variables',
  description: 'Éditer les variables',
}
export const images_section = { path: '/images', Icon: FaRegImages, label: 'Images', description: 'Gérer les images' }
export const settings_section = {
  path: '/settings', Icon: FiSettings, label: 'Paramètres', description: `Gérer les paramètres`,
  label_translations : {
    fr : 'Paramètres',
    en: 'Settings',
  },
  description_translations : {
    fr : 'Gérer les paramètres',
    en: 'Manage parameters'
  }
}

export const sections = [
  // product_section,
  // publish_section,
  // snippet_section,
  // images_section,
  // settings_section,
]
