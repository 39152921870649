import React, { useState, useRef, useEffect } from 'react'

export const useField = ({ value, refresh_if_this_changes, ...rest }) => {
  const [current_value, _set_current_value] = useState(value)
  const set_current_value = React.useCallback((new_value) => {
    if (rest.type === 'number') {
      _set_current_value(parseFloat(new_value))
    } else {
      _set_current_value(new_value)
    }
  }, [_set_current_value])

  const current_ref = useRef(refresh_if_this_changes)
  useEffect(() => {
    if (current_ref.current !== refresh_if_this_changes) {
      current_ref.current = refresh_if_this_changes

      set_current_value(value)
    }
  }, [refresh_if_this_changes, value])

  return {
    ...rest,
    value: current_value,
    setter: set_current_value,
  }
}
const field_reducer = (updates, { key, value, type }) => {
  if (type === 'number') {
    value = !isNaN(parseFloat(value)) ? parseFloat(value) : 0
  }
  return {
    ...updates,
    [ key ]: value,
  }
}

export const useHandleUpdates = (handle_updates, product_fields, product_info_fields) => {
  useEffect(() => {
    handle_updates(
      product_fields.reduce(field_reducer, {}),

      product_info_fields.reduce(field_reducer, {}),
    )
  }, [...( product_fields.map(({ value }) => value) ), ...( product_info_fields.map(({ value }) => value) )])
}
