import React from 'react'
import { SET_COLOR_PALETTE } from '../../../graphql/queries/color_palette'
import useCompanyId from '../../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import IntellifoxModal from '../../generic/modal'
import { await_mutate } from '../../../graphql/mutate_promise'
import * as _ from 'lodash'
import Button from '../../generic/button'
import { ChromePicker } from 'react-color'
import { useTranslate } from '../../../context/lang'
import { IoMdColorPalette } from 'react-icons/all'

const color_ids = ['A', 'B', 'C', 'D', 'E', 'F', 'black', 'grey', 'white']

const blue = ['1f6d93', '023e8a', 'rgba(68,173,232,1)', '90e0ef', 'ade8f4', 'caf0f8', '000000', 'D0D0D0', 'ffffff']
const purple = ['5e0335', '8a0185', '9400b5', 'cb90f0', 'dbaef5', 'e6c8f7', '000000', 'D0D0D0', 'ffffff']
const green = ['145e03', '538a01', '94b500', 'f0ee90', 'f5f5ae', 'f7f7c8', '000000', 'D0D0D0', 'ffffff']
const red = ['550303', '7b0101', 'a10000', 'ee6b6b', 'f18787', 'f2a2a2', '000000', 'D0D0D0', 'ffffff']
const orange = ['542e03', '7a4201', 'a15600', 'edb06b', 'f2c188', 'f2cda2', '000000', 'D0D0D0', 'ffffff']
const yellow = ['9d8e06', 'beab02', 'e2cb00', 'f0e584', 'f4eb9d', 'f4edb2', '000000', 'D0D0D0', 'ffffff']

const blue_yellow = [
  'rgba(23,81,118,1)',
  'rgba(31,102,152,1)',
  'rgba(35,120,179,1)',
  'rgba(254,179,0,1)',
  'rgba(255,191,44,1)',
  'rgba(255,202,75,1)',
  '000000',
  'D0D0D0',
  'ffffff',
]
export const default_palettes = ( [blue, purple, green, red, orange, yellow, blue_yellow] ).map((colors) => {
  return colors.map((c, i) => ( {
    color_id: color_ids[ i ],
    color: /rgba/.test(c) ? c : `#${c}`, // njsscan-ignore: regex_dos
  } ))
})


const default_palette_A = default_palettes[ 0 ]
console.log(default_palette_A)

const default_palette_B = default_palettes[ 1 ]


const Palette = ({ colors, is_current, set_colors }) => {

  const [selected_index, set_selected_index] = React.useState(-1)

  return <div className="rounded" style={{
    height: 50,
    border: is_current ? '5px solid blue' : '',
  }}>        {colors.map((c, i) => <div className="d-inline-block"
                                        style={{
                                          background: c.color,
                                          width: `${100 / colors.length}%`,
                                          height: '100%',
                                          opacity: selected_index !== -1 && selected_index !== i ? 0.5 : 1,
                                        }} onClick={() => {
    if (set_colors) {
      if (selected_index === i) {
        set_selected_index(-1)

      } else {
        set_selected_index(i)

      }
    }
  }}></div>)}

    {selected_index !== -1 ? <ChromePicker color={colors[ selected_index ].color}
                                           onChange={(c, e) => {
                                             set_colors(colors.map((current_color, i) => {
                                               if (i !== selected_index) {
                                                 return current_color
                                               }
                                               return {
                                                 ...current_color,
                                                 color: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`,
                                               }
                                             }))
                                           }}/> : null}
  </div>
}

const DefaultPaletteSelector = ({ colors, on_click, current_colors }) => {
  return <>
    <div className="row mb-3 clickable">
      <div className="col-12" onClick={() => on_click(colors)}>
        <Palette colors={colors} is_current={_.isEqual(current_colors, colors)}/>
      </div>
    </div>

  </>
}


const EditCustomPalette = ({ initial_colors, update_colors }) => {
  const [colors, set_colors] = React.useState(initial_colors)

  return <div className="row">
    <div className="col-12">
      <Palette colors={colors} is_current={_.isEqual(initial_colors, colors)} set_colors={(colors) => {
        set_colors(colors)
        update_colors(colors)
      }}/>
    </div>
  </div>
}

export default function EditColorPalette({ product }) {
  const company_id = useCompanyId()

  const translate = useTranslate()

  const [set_color_palette, mutation] = useMutation(SET_COLOR_PALETTE)

  const [current_colors, set_current_colors] = React.useState(product.color_palette.colors)

  const are_current_colors_custom = !_.isEqual(default_palette_A, current_colors) && !_.isEqual(default_palette_B, current_colors)

  const [custom_colors, set_custom_colors] = React.useState(are_current_colors_custom)

  const do_it = async () => {
    console.log(current_colors.map(({ color }) => color.replace('#', '')))
    await await_mutate(set_color_palette, {
      variables: {
        company_id,
        product_id: product.product_id,
        colors: current_colors,
      },
    })

    await product.refresh()
  }


  return (
    <IntellifoxModal title={translate({
      fr: 'Palette de couleurs',
      en: 'Color palette',
    })}
                     body={<>
                       <div className="row mb-3">
                         <div className="col-12 font-weight-bold">
                           {translate({
                             fr: `Palettes par défaut`,
                             en: `Default color palettes`,
                           })}
                         </div>
                       </div>
                       {default_palettes.map((colors, i) => {
                         return <DefaultPaletteSelector key={i} current_colors={current_colors} colors={colors}
                                                        on_click={set_current_colors}/>
                       })}
                       <div className="row">
                         <div className="col-12 font-weight-bold">
                           {translate({
                             fr: `Palette de couleurs personnalisées`,
                             en: `Custom color palettes`,
                           })}
                         </div>
                         {!custom_colors ? <div className="col-12">
                           <Button onClick={() => set_custom_colors(true)}>{translate({
                             fr: `Personnaliser la palette de couleurs`,
                             en: `Customize color palette`,
                           })}</Button>
                         </div> : <div className="col-12">
                           <EditCustomPalette initial_colors={current_colors} update_colors={set_current_colors}/>
                         </div>}
                       </div>
                     </>}
                     on_click={do_it}
                     action_label={translate({
                       fr: 'Enregistrer',
                       en: 'Save',
                     })}>
      {translate({
        fr: `Palette de couleurs`,
        en: `Color palette`,
      })} <IoMdColorPalette />
    </IntellifoxModal>
  )
}
