import React from 'react'
import { ColorOptionSelector } from '../options/select_color'
import SingleObjectOptions from '../options/single_object_options'
import { compute_rect_style } from '../controller/rect_controls'
import TextButton from '../../generic/text_button'
import { useDesignerContext } from '../designer_context'

import './book.css'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'


export default function Book({ object_id, onLoad, ...props }) {
  const { image_id, image_object_fit = 'cover', image_object_reverse = false, book_properties } = props && props.props ? props.props : null

  const image = useCachedContext('image', image_id)

  React.useEffect(() => {
    if (image_id === null) {
      onLoad && onLoad()
    }
  }, [])

  React.useEffect(() => {
    if (image.error || image.image_id === null) {
      onLoad && onLoad()
    }
  }, [image])

  if (image.loading) {
    return <div className="position-absolute loading" id={object_id} style={{
      ...compute_rect_style(props),
      background: 'grey',
      opacity: 0.5,
    }}>
    </div>
  }

  if (image.error || image.image_id === null) {
    return <div></div>
  }

  const pagesOffset = 2

  const angle = book_properties ? book_properties.angle_value : 30 ||  30

  const pages = book_properties ? book_properties.pages_value : 100 ||  100


  const thickness = Math.min(props.width, props.height) * pages / 1500
  return (
    <div className="position-absolute" id={object_id} style={{
      ...compute_rect_style(props),
    }}>
      <div className="book-container" style={{
        width: props.width,
        height: props.height,
      }}>


        <div className="book" style={{
          width: props.width,
          height: props.height,

          transform: `rotateY(-${angle}deg)`
        }}>
          <div className="book-before" style={{
            left: 0,
            top: pagesOffset,
            width: thickness - 2,
            height: props.height - 2 * pagesOffset,
            transform: `translateX(${props.width - thickness / 2 - pagesOffset}px) rotateY(90deg)`,
          }}>

          </div>

          <img src={image.url} onLoad={() => onLoad && onLoad()} style={{
            width: props.width,
            height: props.height,
            transform: `translateZ(${thickness / 2}px)`,
            objectFit: 'cover'
          }}/>
          <div className="book-after" style={{
            width: props.width,
            height: props.height,
            transform: `translateZ(-${thickness / 2}px)`
          }}/>
        </div>

    </div>
  </div>
  )
}

export const BookOptions = ({ scale, ...object }) => {
  const { set_current_option } = useDesignerContext()
  const translate = useTranslate()

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 no-gutters row justify-content-between">
      <div className="col-auto">
        <OptionSelector option={'props.book'}>{translate({
            fr: `Propriétés du livre`,
            en: `Book properties`
        })}</OptionSelector>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <OptionSelector option={'props.image_id'}>{translate({
            fr: `Couverture`,
            en: `Cover`
        })}</OptionSelector>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <OptionSelector option={'props.filters'}>{translate({
            fr: `Filtres`,
            en: `Filters`
        })}</OptionSelector>
      </div>
      <div className="col"></div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>
  </div>
}
