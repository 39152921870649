import React, { useContext } from 'react'

const OrderNotificationsContext = React.createContext({
  order_notifications: {
    activate: false,
    sound: false,
    notification: false,
    confetti: false,
  },
  set_order_notifications : () => {}
})

export const useOrderNotifications = () => useContext(OrderNotificationsContext)

export default OrderNotificationsContext
