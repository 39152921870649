import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import { useFrench } from '../../../context/lang'
import useCompanyId from '../../../hooks/useCompanyId'
import { MarketPlaceDetails, useSourceMarketPlace } from '../../../hooks/useMarketPlace'
import { useCachedContext } from '../../../hooks/useCachedContext'
import React from 'react'
import { useMutation } from '@apollo/client'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import IntellifoxModal from '../../../components/generic/modal'
import * as _ from 'lodash'
import { CompactItem } from '../../../components/generic/item'
import Flag from '../../../components/flag/flag'
import { FiArrowRight, FiCheck, FiPlus, FiRefreshCw } from 'react-icons/all'
import { await_mutate } from '../../../graphql/mutate_promise'
import Button from '../../../components/generic/button'
import InlineToggle from '../../../components/generic/inline-toggle'
import UpdateButtonV2 from '../../../components/generic/update_button_v2'
import moment from 'moment'
import { RefreshAmazonAdsAccount } from './refresh_amazon_ads_account'

const manage_campaign_mutation = create_mutation({
  mutation: 'manage_campaign', params: {
    company_id: 'ID!',
    amazon_ads_account_id: 'String',
    profile_id: 'String',
    region_id: 'String',
    market_place: 'String',
    campaign_id: 'String',
  },
})

export const AddManagedCampaign = () => {
  const french = useFrench()
  const company_id = useCompanyId()

  const source_market_place = useSourceMarketPlace()
  const amazon_ads_accounts = useCachedContext('amazon_ads_accounts')
  const [selected_amazon_ads_account_id, set_selected_amazon_ads_account_id] = React.useState('')
  const [selected_profile_id, set_selected_profile_id] = React.useState('')
  const [selected_campaign_id, set_selected_campaign_id] = React.useState('')

  const campaigns = useCachedContext('campaigns', selected_amazon_ads_account_id, selected_profile_id)
  const managed_campaigns = useCachedContext('managed_campaigns')
  const [manage_campaign] = useMutation(manage_campaign_mutation)

  React.useEffect(() => {
    if (amazon_ads_accounts && amazon_ads_accounts.length === 1 && !selected_amazon_ads_account_id) {
      set_selected_amazon_ads_account_id(amazon_ads_accounts[ 0 ].amazon_ads_account_id)
    }
  }, [amazon_ads_accounts])

  React.useEffect(() => {
    if (selected_amazon_ads_account_id) {
      const selected_amazon_account = amazon_ads_accounts.find((a) => a.amazon_ads_account_id === selected_amazon_ads_account_id)

      const profile_for_source_market_place = selected_amazon_account.profiles.find((p) => p.country_code.toLowerCase() === source_market_place)

      if (profile_for_source_market_place) {
        set_selected_profile_id(profile_for_source_market_place.profile_id)
      }
    }

  }, [selected_amazon_ads_account_id])

  if (amazon_ads_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_ads_accounts.error) {
    return <DefaultError/>
  }


  const selected_amazon_account = amazon_ads_accounts.find((a) => a.amazon_ads_account_id === selected_amazon_ads_account_id)

  return <IntellifoxModal title={french(`Gérer une nouvelle campagne`)}
                          body={<div className="row">
                            <div className="col-12 font-weight-bold h3">
                              1. {french(`Sélectionner le compte Amazon`)}
                            </div>
                            <div className="col-12">
                              <div className="row">
                                {amazon_ads_accounts.map(({ amazon_ads_account_id,region_id, email, profiles }, i) => {
                                  const is_selected = selected_amazon_ads_account_id === amazon_ads_account_id
                                  const merchant_ids = _.uniq(profiles.map(({ merchant_id }) => merchant_id)).join(' ')

                                  return <div className="col-12 col-lg-4" key={i}>
                                    <CompactItem
                                      label={<div className={"BLUR_FOR_WEBINAR"}>{email} <span className="badge badge-secondary">{merchant_ids}</span></div>}
                                      on_click={() => set_selected_amazon_ads_account_id(amazon_ads_account_id)}
                                      action={<RefreshAmazonAdsAccount amazon_ads_account_id={amazon_ads_account_id} region_id={region_id}/>}
                                      is_selected={is_selected} bottom_action={
                                      <FakeSelectButton is_selected={is_selected}/>
                                    }>
                                      {profiles.map(({ country_code }) => country_code.toLowerCase()).map((market_place) =>
                                        <Flag market_place={market_place}/>)}

                                      {profiles.length === 0 ? <div className={"alert alert-warning small"}>{french(`Ce compte Amazon Ads n'a aucun profil actif. Pour activer le profil, il faut d'abord créer une campagne sur le Seller Central, puis cliquer sur le bouton "rafraichir" ci-dessus :`)} <FiRefreshCw/></div>:null}
                                    </CompactItem>
                                  </div>
                                })}

                              </div>
                            </div>

                            <div className="col-12 font-weight-bold h3 mt-3"
                                 style={{ opacity: selected_amazon_ads_account_id ? 1 : 0.3 }}>
                              2. {french(`Sélectionner le pays`)}
                            </div>
                            <div className="col-12">
                              <div className="row">
                                {selected_amazon_account && selected_amazon_account.profiles ?
                                  selected_amazon_account.profiles.map(({ profile_id, country_code }) => {
                                    const is_selected = profile_id === selected_profile_id
                                    return <div className="col-auto">
                                      <CompactItem className={'small font-weight-bold'} is_selected={is_selected}
                                                   on_click={() => set_selected_profile_id(profile_id)}
                                                   bottom_action={
                                                     <FakeSelectButton is_selected={is_selected}/>
                                                   }>
                                        <MarketPlaceDetails market_place={country_code.toLowerCase()}/>
                                      </CompactItem>
                                    </div>
                                  }) : null}
                              </div>
                            </div>

                            <div className="col-12 font-weight-bold h3 mt-3"
                                 style={{ opacity: selected_amazon_ads_account_id && selected_profile_id ? 1 : 0.3 }}>
                              3. {french(`Sélectionner la campagne`)}
                            </div>
                            <div className="col-12">
                              {selected_amazon_ads_account_id && selected_profile_id ?
                                <CampaignSelector campaigns={campaigns} selected_campaign_id={selected_campaign_id}
                                                  set_selected_campaign_id={set_selected_campaign_id}/> : null}
                            </div>
                          </div>}
                          action_label={selected_campaign_id ? <><FiArrowRight/> {french(`Gérer cette campagne`)}</> : null}
                          on_click={async () => {
                            const selected_profile = selected_amazon_account.profiles.find((p) => p.profile_id === selected_profile_id)

                            await await_mutate(manage_campaign, {
                              variables: {
                                company_id,
                                amazon_ads_account_id: selected_amazon_ads_account_id,
                                profile_id: selected_profile_id,
                                region_id: selected_profile.region_id,
                                market_place: selected_profile.country_code.toLowerCase(),
                                campaign_id: selected_campaign_id,
                              },
                            })
                            await managed_campaigns.refresh()

                          }}
  >
    <FiPlus/> {french(`Gérer une campagne`)}
  </IntellifoxModal>
}


const FakeSelectButton = ({ is_selected }) => {
  const french = useFrench()
  return <>
    {
      is_selected ?
        <Button className={'btn-sm'} button={'success'}><FiCheck/> {french(`Sélectionné`)}</Button> :
        <Button className={'btn-sm'}><FiArrowRight/> {french(`Sélectionner`)}</Button>

    }
  </>
}

const CampaignSelector = ({ campaigns, selected_campaign_id, set_selected_campaign_id }) => {
  const french = useFrench()
  const managed_campaigns = useCachedContext('managed_campaigns')


  const [show_all, set_show_all] = React.useState(false)
  if (campaigns.loading || managed_campaigns.loading) {
    return <DefaultLoading/>
  }

  if (campaigns.error || managed_campaigns.error) {
    return <DefaultError/>
  }

  const filtered_campaigns = campaigns
    .filter((c) => show_all ? true : c.state === 'enabled')
    .filter((c) => !managed_campaigns.find((cc) => cc.campaign_id === c.campaign_id))
    .sort((c1, c2) => parseInt(c1.start_date) < parseInt(c2.start_date) ? 1 : -1)

  return <div className={'row'}>
    <div className="col-12 mb-2">
      <div className="row">
        <div className="col-6">
          <InlineToggle is_on={!show_all} on_toggle={() => set_show_all(!show_all)}>
            {french(`Voir uniquement les campagnes actives`)}
          </InlineToggle>
        </div>
        <div className="col-6 text-right">
          <UpdateButtonV2 trigger={() => campaigns.refresh()}><FiRefreshCw/> {french(`Rafraîchir`)}</UpdateButtonV2>
        </div>
      </div>
    </div>
    <div className="col-12">
      {
        filtered_campaigns.map(({ campaign_id, name, state, targeting, start_date }) => {
          const is_selected = campaign_id === selected_campaign_id

          return <div className="row">
            <div className="col-12">
              <CompactItem is_selected={is_selected}
                           on_click={() => set_selected_campaign_id(campaign_id)}
                           label={<span className={'BLUR_FOR_WEBINAR'} dangerouslySetInnerHTML={{ __html: name }}></span>} bottom_action={
                <FakeSelectButton is_selected={is_selected}/>
              }>
                {moment(start_date, 'YYYYMMDD').format('L')} - {state} - {targeting}
              </CompactItem>
            </div>
          </div>
        })
      }

    </div>
  </div>

}
