import React, { useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'

import '../node_modules/lato-font/css/lato-font.min.css'
import './fonts/Source_Code_Pro/stylesheet.css'
import './fonts/Trirong/stylesheet.css'
import './fonts/Tomorrow/stylesheet.css'
import './fonts/Taviraj/stylesheet.css'
import './fonts/Montserrat/stylesheet.css'
import './fonts/Mali/stylesheet.css'
import './fonts/KoHo/stylesheet.css'
import './fonts/Grenze/stylesheet.css'
import './fonts/Alegreya_Sans_SC/stylesheet.css'
import './fonts/DotMatrix/stylesheet.css'
// font added 2020-10-08
import './fonts/KGIndieFlower/stylesheet.css'
import './fonts/BlackChancery/stylesheet.css'
import './fonts/Blzee/stylesheet.css'
import './fonts/BurnstownDam/stylesheet.css'
import './fonts/GlazKrak/stylesheet.css'
import './fonts/PricedownBl/stylesheet.css'
import './fonts/YoureGone/stylesheet.css'
// Emoji font added 2021-11-08
import './fonts/TwemojiMozilla/stylesheet.css'

import 'react-tippy/dist/tippy.css'
import './app.scss'
import './discreet.scss'


import useLocalStorage from './hooks/useLocalStorage'
import UserContext, { DisconnectContext } from './context/user'
import MainContent from './components/layout/main-content'
import client from './graphql/client'
import { GET_ME_STR } from './graphql/queries/user'
import { GET_SNIPPETS } from './graphql/queries/snippet'
import {
  GET_FULL_PRODUCTS_STR,
  GET_MINIMAL_PRODUCTS,
  GET_PRODUCT_COUNT,
  GET_PRODUCT_STR,
  GET_PRODUCT_TITLE,
  GET_PRODUCT_WITH_SINGLE_VISUAL,
  GET_PRODUCTS_HIERARCHY,
  market_places_query,
  product_progress_for_market_place,
  product_title,
  SEARCH_PRODUCTS,
} from './graphql/queries/product'
import { GET_IMAGE, GET_IMAGES_STR, GET_PIXABAY_IMAGES } from './graphql/queries/images'
import { Router } from '@reach/router'
import VisualRendererStandalone from './routes/images/visual-renderer-standalone'

import { absolute } from './absolute_link'
import ThemeContext from './context/theme'
import { get_client } from './graphql/simple-client'
import { CachedValues, useCachedContext } from './hooks/useCachedContext'
import {
  GET_UPDATES,
  GET_UPDATES_SINGLE_PRODUCT,
} from './graphql/queries/publish'
import { GET_USAGE } from './graphql/queries/usage'
import { free_trial_url_checkout, GET_PLANS, plans } from './graphql/queries/plan'
import IntellifoxIcon from './images/intellifox-icon'
import { GET_EXCHANGE_RATE, GET_TRANSLATION, GET_TRANSLATIONS } from './graphql/queries/translation'


import LangContext, {
  get_browser_lang,
  InterfaceTranslationsContext,
  SetInterfaceTranslationsContext,
  useTranslate,
} from './context/lang'
import moment from 'moment'

import af from 'moment/locale/af'
import ar from 'moment/locale/ar'
import bg from 'moment/locale/bg'
import bn from 'moment/locale/bn'
import bs from 'moment/locale/bs'
import ca from 'moment/locale/ca'
import cs from 'moment/locale/cs'
import cy from 'moment/locale/cy'
import da from 'moment/locale/da'
import de from 'moment/locale/de'
import el from 'moment/locale/el'
import en_uk from 'moment/locale/en-gb'
import en_us from 'moment/locale/en-ca'
import es from 'moment/locale/es'
import et from 'moment/locale/et'
import fa from 'moment/locale/fa'
import fi from 'moment/locale/fi'
import fr from 'moment/locale/fr'
import fr_ca from 'moment/locale/fr-ca'
import ga from 'moment/locale/ga'
import gu from 'moment/locale/gu'
import he from 'moment/locale/he'
import hi from 'moment/locale/hi'
import hr from 'moment/locale/hr'
import hu from 'moment/locale/hu'
import id from 'moment/locale/id'
import is from 'moment/locale/is'
import it from 'moment/locale/it'
import ja from 'moment/locale/ja'
import kk from 'moment/locale/kk'
import kn from 'moment/locale/kn'
import ko from 'moment/locale/ko'
import ku from 'moment/locale/ku'
import lt from 'moment/locale/lt'
import lv from 'moment/locale/lv'
import mi from 'moment/locale/mi'
import ml from 'moment/locale/ml'
import mr from 'moment/locale/mr'
import ms from 'moment/locale/ms'
import mt from 'moment/locale/mt'
import nb from 'moment/locale/nb'
import nl from 'moment/locale/nl'
import pl from 'moment/locale/pl'
import pt from 'moment/locale/pt-br'
import pt_pt from 'moment/locale/pt'
import ro from 'moment/locale/ro'
import ru from 'moment/locale/ru'
import sk from 'moment/locale/sk'
import sl from 'moment/locale/sl'
import sv from 'moment/locale/sv'
import sw from 'moment/locale/sw'
import ta from 'moment/locale/ta'
import te from 'moment/locale/te'
import th from 'moment/locale/th'
import tr from 'moment/locale/tr'
import uk from 'moment/locale/uk'
import ur from 'moment/locale/ur'
import vi from 'moment/locale/vi'
import zh from 'moment/locale/zh-cn'


import ProgressBar from './components/generic/progress_bar'

import { GET_SHAPE, GET_SHAPES } from './graphql/queries/shape'
import { GET_SEARCH_TERM_DETAILS } from './graphql/queries/search_term'
import { GET_COMPETITORS_ASIN, GET_COMPETITORS_DETAILS } from './graphql/queries/competitors'
import TranslationsContext, { useTranslationsInit } from './context/translations'
import Button from './components/generic/button'
import { GET_ORDERS, GET_ORDERS_WITH_ASIN, GET_ORDERS_WITH_COMMENT_REQUEST } from './graphql/queries/order'
import OrderNotificationsContext from './context/order_notifications'
import { parse_json } from './graphql/parse_json'
import { parse_product } from './graphql/fetcher/product'
import axios from 'axios'
import { amazon_regions_query } from './graphql/queries/amazon_regions'
import { create_query } from './graphql/queries/_query_and_mutations_templates'
import { sold_by } from './graphql/queries/sold_by'
import { set_amazon_regions } from './hooks/useMarketPlace'
import { get_visuals_query } from './graphql/queries/visual'
import * as _ from 'lodash'
import { get_processes } from './graphql/queries/import'
import { subscription_details_query } from './graphql/queries/subscription'
import { StandaloneSalesResults } from './routes/order/results/standalone_result'
import { shareable_results } from './graphql/queries/shareable_results'
import { FetchV2Context } from './context/fetch_v2'
import { api_domain } from './constants'

// moment.updateLocale('fr', fr)
// moment.updateLocale('en', en_us)

const MaintenanceMode = ({ server_condition }) => {

  const translate = useTranslate()

  React.useEffect(() => {
    setTimeout(() => {
      window.location.reload()
    }, 60000)
  }, [])

  let title
  let explanation

  if (server_condition === 'maintenance') {
    title = translate({
      fr: `Une maintenance d'Intellifox est en cours`,
      en: `Intellifox is currently under maintenance`,
    })
    explanation = translate({
      fr: `Le service sera remis en route d'ici une quinzaine de minutes. Toutes nos excuses pour les désagréments causés.`,
      en: `The service should be back in the next fifteen minutes. We're sorry for the inconvenience.`,
    })
  } else if (server_condition === 'unknown') {
    title = translate({
      fr: `Intellifox est temporairement indisponible`,
      en: `Intellifox is temporarily unavailable`,
    })
    explanation = translate({
      fr: `Le service sera remis en route dès que possible. Toutes nos excuses pour les désagréments causés.`,
      en: `The service will be back as soon as possible. We're sorry for the inconvenience.`,
    })
  }

  return <div className="pt-5"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100vw',
                height: '100vh',
                background: 'black',
                color: 'white',
                fontSize: 120,
                overflow: 'auto',
              }}
  >
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IntellifoxIcon/>
        </div>
        <div className="col-12 h1">
          {title}
        </div>
        <div className="col-12 col-lg-6 h3 mt-5">
          {explanation} 🦊
        </div>
        <div className="col-12 h4 mt-5">
          {translate({
            fr: `L'équipe Intellifox`,
            en: `Intellifox Team`,
          })}
        </div>
      </div>
    </div>
  </div>
}

function UserApp() {
  const [user_in_localstorage, _set_user] = useLocalStorage('user', '')
  const no_delay_user_ref = React.useRef(user_in_localstorage)

  const set_user = (whatevs) => {
    _set_user(whatevs)
    no_delay_user_ref.current = whatevs
  }

  const fetch_me = async () => {
    if (!( no_delay_user_ref.current && no_delay_user_ref.current.user )) {
      return {}
    }

    console.error('fetch_me called')
    const client = get_client()
    const response = await client.request(GET_ME_STR, {
      user_id: no_delay_user_ref.current.user.user_id,
    })
    set_lang(response.me.user.lang)
    console.log('lang is', response.me.user.lang)

    if (response && response.me && response.me.company) {
      let meta_data
      try {
        meta_data = JSON.parse(response.me.company.meta_data)
      } catch (e) {

      }

      if (!meta_data) {
        meta_data = {}
      }

      response.me.company.meta_data = meta_data
    }

    return response.me
  }

  const [lang, set_lang] = useLocalStorage('intellifox_lang', get_browser_lang())

  const [wait_for_translations_preload, set_wait_for_translations_preload] = React.useState(lang !== 'fr')
  const [wait_for_amazon_regions, set_wait_for_amazon_regions] = React.useState(true)

  const [translation_cache, set_translation_cache] = React.useState({})

  const set_translation = React.useCallback((text, target_lang, translation) => {
    set_translation_cache((translation_cache) => {
      // console.log('set_translation for', text, 'with', { target_lang, translation })
      return {
        ...translation_cache,
        [ `${target_lang}-${text}` ]: translation,
      }
    })

  }, [set_translation_cache])

  React.useEffect(() => {
    const go = async () => {
      // Get preload preload_interface_translations
      if (wait_for_translations_preload) {
        const client = get_client()
        const response = await client.request(create_query({
          query: 'preload_interface_translations',
          params: {
            source_lang: 'String', target_lang: 'String',
          },
          output: '{ text, result }',
        }), {
          source_lang: 'fr',
          target_lang: lang,
        })

        const interface_translations = response.preload_interface_translations
        const cache = {}
        for (let i = 0; i < interface_translations.length; i++) {
          const { text, result } = interface_translations[ i ]
          cache[ `${lang}-${text}` ] = result
        }
        set_translation_cache(cache)

        set_wait_for_translations_preload(false)
      }
    }

    go().catch(e => console.error(e))
  }, [])

  React.useEffect(() => {
    document.querySelector('html').setAttribute('lang', lang)
  }, [lang])

  React.useEffect(() => {
    const go = async () => {
      if (wait_for_amazon_regions) {
        const client = get_client()
        const response = await client.request(amazon_regions_query)

        set_amazon_regions(response.amazon_regions)
        set_wait_for_amazon_regions(false)
      }
    }

    go().catch(e => console.error(e))
  }, [])

  const [server_condition, set_server_condition] = React.useState('unset')

  React.useEffect(() => {
    const go = async () => {
      try {
        const req = await axios.get(`${api_domain}/api/health_check/maintenance_mode`)
        console.log('maintenance_mode?', req.data.maintenance_mode)
        set_server_condition(req.data.maintenance_mode ? 'maintenance' : 'running')
      } catch (e) {
        set_server_condition('unknown')
      }
    }

    go().catch(e => console.error(e))
  }, [])

  if (server_condition === 'unset' || wait_for_translations_preload) {
    return <div className="text-center pt-5"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100vw',
                  height: '100vh',
                  background: 'black',
                  color: 'white',
                  fontSize: 120,
                }}
    >
      <IntellifoxIcon/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ProgressBar progress={100}/>
          </div>
        </div>
      </div>
    </div>
  }

  if (server_condition === 'maintenance' || server_condition === 'unknown') {
    return <LangContext.Provider value={{ lang, set_lang }}>
      <InterfaceTranslationsContext.Provider value={translation_cache || {}}>
        <SetInterfaceTranslationsContext.Provider value={set_translation}>
          <MaintenanceMode server_condition={server_condition}/>
        </SetInterfaceTranslationsContext.Provider>
      </InterfaceTranslationsContext.Provider>
    </LangContext.Provider>
  }

  return <CachedValues name={'me'} fetch_value={fetch_me}>
    <LangContext.Provider value={{ lang, set_lang }}>
      <InterfaceTranslationsContext.Provider value={translation_cache || {}}>
        <SetInterfaceTranslationsContext.Provider value={set_translation}>
          <App user_in_localstorage={user_in_localstorage} set_user={set_user} lang={lang} set_lang={set_lang}/>
        </SetInterfaceTranslationsContext.Provider>
      </InterfaceTranslationsContext.Provider>
    </LangContext.Provider>
  </CachedValues>
}

function App({ user_in_localstorage, set_user, lang, set_lang }) {
  const [theme, set_theme] = useLocalStorage('intellifox-theme', 'light')
  const [discreet_mode, set_discreet_mode] = useLocalStorage('intellifox_discreet_mode', false)
  const [order_notifications, set_order_notifications] = useLocalStorage('order_notifications', {
    activate: false,
    sound: 'intellifox',
    notification: false,
    confetti: false,
  })

  React.useEffect(() => {
    moment.locale(lang)
  }, [lang])

  const me = useCachedContext('me')

  const connected_ref = React.useRef(false)

  React.useEffect(() => {
    if (me.user) {
      const { user } = me
      const $crisp = window.$crisp
      if ($crisp) {
        $crisp.push(['safe', true])
        $crisp.push(['set', 'session:segments', [['customer']]])
        $crisp.push(['set', 'user:email', [user.email]])
        $crisp.push(['set', 'user:nickname', [`${user.first_name} ${user.last_name}`]])
        $crisp.push(['set', 'user:phone', [user.phone_number]])
        $crisp.push(['set', 'session:data', [[['user_id', user.user_id], ['company_id', me.company.company_id]]]])
      }

      if (!connected_ref.current) {
        connected_ref.current = true
      }
    }
  }, [me])

  useEffect(() => {
    datadogRum.init({
      applicationId: 'b7e0e1ec-cc47-4aff-8852-528d21114e54',
      clientToken: 'pub3041817efa61fe1abe53b159eaf90295',
      site: 'datadoghq.com',
      service: 'intellifox',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }, [])


  const disconnect = () => {
    set_user('')
    me.refresh()
    window.location.reload()
  }

  useEffect(() => {
    if (me.error) {
      disconnect()
    }
  }, [me.error])

  const company_id = me && me.company ? me.company.company_id : undefined


  const fetch_snippets = async () => {
    const client = get_client()
    const response = await client.request(GET_SNIPPETS, {
      company_id,
    })
    return response.snippets
  }

  const fetch_plans = async (currency, special) => {
    const client = get_client()
    const response = await client.request(plans, {
      currency,
      special,
    })
    return response.plans
  }

  const fetch_translation = async (source_lang, target_lang, text, keep) => {
    if (!text) {
      return { translated_text: '' }
    }

    const client = get_client()

    const response = await client.request(GET_TRANSLATION, {
      company_id,
      source_lang,
      target_lang,
      text,
      keep,
    })
    return response.translation
  }
  const translations_context = useTranslationsInit(company_id)

  const fetch_translations = async (source_lang, target_lang, texts) => {
    if (!texts || texts.length === 0) {
      return []
    }

    const client = get_client()

    const response = await client.request(GET_TRANSLATIONS, {
      company_id,
      source_lang,
      target_lang,
      texts,
    })
    return response.translations
  }

  const fetch_exchange_rate = async (from_currency, to_currency) => {
    if (!from_currency || !to_currency || from_currency === to_currency) {
      return { rate: 1 }
    }

    // console.log('Fetch exchange rate', { from_currency, to_currency })
    const client = get_client()

    const response = await client.request(GET_EXCHANGE_RATE, {
      company_id,
      from_currency, to_currency,
    })

    // console.log('Fetch echange rate done', { from_currency, to_currency, rate: response.exchange_rate })

    return { from_currency, to_currency, rate: response.exchange_rate }
  }

  const fetch_images = async (tags) => {
    const client = get_client()
    const response = await client.request(GET_IMAGES_STR, {
      company_id, tags,
    })
    return response.images
  }

  const fetch_image = async (image_id) => {
    if (image_id === null) {
      return {}
    }
    const client = get_client()
    const response = await client.request(GET_IMAGE, {
      company_id, image_id,
    })
    return response.image
  }

  const fetch_product = async (product_id, market_place) => {
    if (!product_id) {
      return {
        empty_product_id: true,
      }
    }
    const client = get_client()
    const response = await client.request(GET_PRODUCT_STR, {
      company_id, market_place, product_id,
    })
    try {
      const returned_product = parse_product(response.product)
      return returned_product
    } catch (e) {
    }
  }

  const fetch_product_several_market_places = async (product_id, market_places) => {
    if (!product_id) {
      return {}
    }
    const client = get_client()

    return await Promise.all(market_places.map(async (market_place) => {
      const response = await client.request(GET_PRODUCT_STR, {
        company_id, market_place, product_id,
      })
      try {
        const returned_product = parse_product(response.product)
        return returned_product
      } catch (e) {
      }
    }))

  }

  const fetch_product_title = async (product_id, market_place) => {
    const client = get_client()
    const response = await client.request(GET_PRODUCT_TITLE, {
      company_id, market_place, product_id,
    })
    return response.product
  }
  const fetch_product_progress = async (product_id, market_place) => {
    const client = get_client()
    const response = await client.request(product_progress_for_market_place, {
      company_id, market_place, product_id,
    })
    return response.product
  }
  const fetch_product_title_by_asin = async (asin, market_place) => {
    const client = get_client()
    const response = await client.request(product_title, {
      company_id, asin, market_place,
    })
    return {
      title: response.product_title,
    }
  }
  const fetch_product_count = async (state = 'active') => {
    const client = get_client()
    const response = await client.request(GET_PRODUCT_COUNT, {
      company_id, state,
    })
    return response.product_count
  }

  const fetch_products = async (market_place, offset = 0, limit = 20, state = 'active') => {
    const client = get_client()
    const response = await client.request(GET_FULL_PRODUCTS_STR, {
      company_id, market_place, state, offset, limit,
    })
    return response.products.map(product => parse_product(product))
  }
  const fetch_minimal_products = async (market_place, offset = 0, limit = 20, state = 'active') => {
    const client = get_client()
    const response = await client.request(GET_MINIMAL_PRODUCTS, {
      company_id, market_place, state, offset, limit,
    })
    return response.products.map(product => parse_product(product))
  }
  const fetch_search_products = async (market_place, query = '', offset = 0, limit = 20) => {
    if (query === '' || !query) {
      return []
    }

    const client = get_client()
    const response = await client.request(SEARCH_PRODUCTS, {
      company_id, market_place, query, offset, limit,
    })
    return {
      count: response.searchProducts.count,
      products: response.searchProducts.products.map(product => parse_product(product)),
    }
  }

  const fetch_products_with_updates = async (market_place, state = 'active') => {
    const client = get_client()
    const response = await client.request(GET_UPDATES, {
      company_id, market_place, state,
    })
    return response.products
  }
  const fetch_product_with_updates = async (market_place, product_id) => {
    const client = get_client()
    const response = await client.request(GET_UPDATES_SINGLE_PRODUCT, {
      company_id, market_place, product_id,
    })
    return response.product
  }


  const fetch_processes = async (type) => {
    const client = get_client()
    const response = await client.request(get_processes, {
      company_id, type,
    })
    const processes = response.processes || []
    return processes.map((process) => {
      return {
        ...process,
        results: JSON.parse(process.results || '{}'),
      }
    })
  }


  const fetch_pixabay_images = async (query = '', lang = '', category = '', page = '', per_page = '', colors = '') => {
    const client = get_client()
    const response = await client.request(GET_PIXABAY_IMAGES, {
      company_id, query, lang, category, page, per_page, colors,
    })
    return response.pixabay_images
  }


  if (me.loading) {
    return <div className="text-center pt-5"
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100vw',
                  height: '100vh',
                  background: 'black',
                  color: 'white',
                  fontSize: 120,
                }}
    >
      <IntellifoxIcon/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ProgressBar progress={100}/>
          </div>
        </div>
      </div>
    </div>
  }

  const fetch_usage = async (feature) => {
    const client = get_client()
    const response = await client.request(GET_USAGE, {
      company_id, feature,
    })
    return response
  }

  const fetch_product_with_single_visual = async (product_id, market_place, index) => {
    if (!product_id) {
      return {
        is_null: true,
      }
    }

    const client = get_client()

    try {
      const response = await client.request(GET_PRODUCT_WITH_SINGLE_VISUAL, {
        company_id, market_place, product_id, index,
      })

      const visual = response.product.product_info.visual_by_index

      console.log('fetch_product_with_single_visual', { visual })

      if (visual === null) {
        return {
          is_null: true,
          objects: [],
          overrides: [],
        }
      }

      return {
        ...visual,
        objects: visual.objects ? visual.objects.map((object) => {
          return {
            ...object,
            props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
          }
        }) : [],
        overrides: visual.overrides ? visual.overrides.map((object) => {
          return {
            ...object,
            props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
          }
        }) : [],
      }
    } catch (e) {
      console.error('failed to fetch product and visual', e)
      return {
        is_null: true,
        objects: [],
        overrides: [],
      }
    }
  }

  const map_visual = (visual) => {
    if (!visual) {
      return null
    }
    return {
      ...visual,
      objects: visual.objects ? visual.objects.map((object) => {
        return {
          ...object,
          props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
        }
      }) : [],
      overrides: visual.overrides ? visual.overrides.map((object) => {
        return {
          ...object,
          props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
        }
      }) : [],
    }
  }
  const fetch_visuals = async (product_id, market_place) => {
    const client = get_client()

    try {
      const response = await client.request(get_visuals_query, {
        company_id, market_place, product_id,
      })

      return response.get_visuals.map(map_visual)
    } catch (e) {
      console.error('failed to fetch product and visual', e)
      return {
        is_null: true,
        objects: [],
        overrides: [],
      }
    }
  }

  const fetch_products_hierarchy = async () => {

    const client = get_client()
    const response = await client.request(GET_PRODUCTS_HIERARCHY, {
      company_id, state: 'active', offset: 0, limit: 999999,
    })
    return response.products
  }

  const fetch_shapes = async (offset, limit) => {
    const client = get_client()
    const response = await client.request(GET_SHAPES, {
      company_id, offset, limit,
    })
    return response.shapes
  }

  const fetch_shape = async (shape_id) => {
    const client = get_client()
    const response = await client.request(GET_SHAPE, {
      company_id, shape_id,
    })
    return response.shape
  }
  const fetch_search_term_details = async (market_place, search_term) => {
    const client = get_client()
    const response = await client.request(GET_SEARCH_TERM_DETAILS, {
      company_id, market_place, search_term,
    })
    return response.get_search_term_details
  }

  const fetch_competitors_asin = async (product_id, market_place) => {
    const client = get_client()
    const response = await client.request(GET_COMPETITORS_ASIN, {
      company_id, market_place, product_id,
    })
    return response.product.product_info.competitors
  }
  const fetch_competitors = async (product_id, market_place) => {
    const client = get_client()
    const response = await client.request(GET_COMPETITORS_DETAILS, {
      company_id, market_place, product_id,
    })
    return response.product.product_info.competitors
  }
  const fetch_orders = async (from, to) => {
    const client = get_client()
    const response = await client.request(GET_ORDERS, {
      company_id, from, to,
    })
    return response.orders
  }
  const fetch_orders_with_asin = async (asin, market_place, from, to) => {
    const client = get_client()
    const response = await client.request(GET_ORDERS_WITH_ASIN, {
      company_id, asin, market_place, from, to,
    })
    return response.orders_with_asin
  }

  const fetch_orders_with_comment_request = async (limit) => {
    const client = get_client()
    const response = await client.request(GET_ORDERS_WITH_COMMENT_REQUEST, {
      company_id, limit,
    })
    return response.orders_with_comment_request
  }


  const fetch_v2 = ({
                      query, validate_user,
                      params = {},
                      output,
                    }, transformer = (a) => a) => {
    return async (...args) => {
      const client = get_client()

      if (validate_user) {
        params = {
          ...params,
          company_id: 'ID!',
        }
      }

      const keys = Object.keys(params).filter((k) => k !== 'company_id')
      const values = {}
      for (let i = 0; i < keys.length; i++) {
        values[ keys[ i ] ] = args[ i ]
      }
      const response = await client.request(
        create_query({
            query, params, output,
          },
        ),
        {
          company_id,
          ...values,
        })
      return transformer(response[ query ])
    }
  }
  const fetch_product_id_by_asin = fetch_v2({
    query: 'product_id_by_asin',
    params: {
      company_id: 'ID!',
      asin: 'String!',
    },
    output: `
    {
      product_id,
      parent_product_id
    }`,
  })

  const fetch_amazon_accounts = fetch_v2({
      query: 'amazon_accounts',
      params: {
        company_id: 'ID!',
      },
      output: `{
          label
          merchant_id
          is_not_vat_registered
          auto_entrepreneur_config
          market_places
          invalid_grant
        }`,
    },
    (amazon_accounts) => amazon_accounts.map((amazon_account) => {
      return {
        ...amazon_account,
        auto_entrepreneur_config: amazon_account.auto_entrepreneur_config ? JSON.parse(amazon_account.auto_entrepreneur_config) : {},
      }
    }),
  )
  const fetch_amazon_ads_accounts = fetch_v2({
      query: 'amazon_ads_accounts',
      params: {
        company_id: 'ID!',
      },
      output: `{
          amazon_ads_account_id
          name
          email
          is_not_vat_registered
          profiles {
            profile_id
            region_id
            country_code
            currency_code
            daily_budget
            timezone
            marketplace_id
            merchant_id
            type
            name
            valid_payment_method
          }
        }`,
    },
  )
  const fetch_campaigns = fetch_v2({
      query: 'campaigns',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
      },
      output: `{
          campaign_id
          name
          type
          targeting
          daily_budget
          start_date
          state
        }`,
    },
  )
  const fetch_ad_groups = fetch_v2({
      query: 'ad_groups',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
        region_id: 'String',
        campaign_id: 'String',
      },
      output: `{
          ad_group_id
          name
          default_bid
          state
          product_ads {
            ad_id
            asin
            sku
            state
          }
        }`,
    },
  )

  const fetch_campaign_keywords = fetch_v2({
      query: 'campaign_keywords',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
        region_id: 'String',
        market_place: 'String',
        campaign_id: 'String',
      },
      output: `{
          keyword_id
          ad_group_id
          campaign_id
          keyword_text
          match_type
          state
          bid
        }`,
    },
  )
  const fetch_campaign_targets = fetch_v2({
      query: 'campaign_targets',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
        region_id: 'String',
        market_place: 'String',
        campaign_id: 'String',
      },
      output: `{
          target_id
          ad_group_id
          campaign_id
          type
          state
          bid
          expression_type
          expression_value
          resolved_expression_type
          resolved_expression_value
        }`,
    },
  )

  const fetch_managed_products_ads = fetch_v2({
      query: 'managed_products_ads',
      params: {
        company_id: 'ID!',
      },
      output: `{
        asin
      }`,
    },
  )
  const fetch_managed_campaigns = fetch_v2({
      query: 'managed_campaigns',
      params: {
        company_id: 'ID!',
      },
      output: `{
        campaign_id
        
        amazon_ads_account_id
        profile_id
        region_id
        market_place
        
        target
        
        daily_schedules {
          day,
          intervals {
            start_hour
            start_minutes
            end_hour
            end_minutes
            state
          }
        }
        
        last_applied_strategy_date
      }`,
    },
  )
  const fetch_ad_groups_reports = fetch_v2({
      query: 'ad_groups_reports',
      params: {
        company_id: 'ID!',
        profile_id: 'String',
        from: 'Float',
        to: 'Float',
      },
      output: `{
        date
        campaign_id
        ad_group_id
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
    },
  )

  const fetch_keywords_reports = fetch_v2({
      query: 'keywords_reports',
      params: {
        company_id: 'ID!',
        profile_id: 'String',
        from: 'Float',
        to: 'Float',
      },
      output: `{
        date
        campaign_id
        ad_group_id
        keyword_id
        keyword_text
        match_type
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
    },
  )
  const fetch_targets_reports = fetch_v2({
      query: 'targets_reports',
      params: {
        company_id: 'ID!',
        profile_id: 'String',
        from: 'Float',
        to: 'Float',
      },
      output: `{
        date
        campaign_id
        ad_group_id
        target_id
        targeting_expression
        targeting_text
        targeting_type
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
    },
  )
  const fetch_product_ads_reports = fetch_v2({
      query: 'product_ads_reports',
      params: {
        company_id: 'ID!',
        asin: 'String',
        profile_id: 'String',
        from: 'Float',
        to: 'Float',
      },
      output: `{
        date
        campaign_id
        ad_group_id
        asin
        sku
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
    },
  )
  const fetch_all_product_ads_reports = fetch_v2({
      query: 'all_product_ads_reports',
      params: {
        company_id: 'ID!',
        from: 'Float',
        to: 'Float',
        currency: 'String',
      },
      output: `{
        amazon_ads_account_id
        market_place
        profile_id

        date
        campaign_id
        ad_group_id
        asin
        sku
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
    },
  )

  const fetch_keywords_bid_recommendations = fetch_v2({
      query: 'keywords_bid_recommendations',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
        region_id: 'String',
        keywords: '[KeywordBidRecommendationInput]',
      },
      output: `{
          keyword_text
          match_type
          low
          high
          suggested
        }`,
    },
  )
  const fetch_targets_bid_recommendations = fetch_v2({
      query: 'targets_bid_recommendations',
      params: {
        company_id: 'ID!',
        amazon_ads_account_id: 'String',
        profile_id: 'String',
        region_id: 'String',
        targets: '[TargetBidRecommendationInput]',
      },
      output: `{
ad_group_id
value
type
low
high
suggested
        }`,
    },
  )

  const intellifox_bid_recommendation = `{
    keyword_text
    match_type
    targeting_text
    targeting_type

    sales
    units
    clicks
    cost
    conversion_rate
    maximum_bid
    confidence
    global_new_bid
    global_confidence
    local_new_bid
    local_confidence
    new_bid
  }`
  const fetch_intellifox_bid_recommendations = fetch_v2({
      query: 'intellifox_bid_recommendations',
      params: {
        company_id: 'ID!',
        profile_id: 'String',
        asin: 'String',
        // Budget should be passed along in the currency of the marketplace linked to the profile_id
        budget: 'Float',
        multiplier: 'Float',
      },
      output: `{
        keywords ${intellifox_bid_recommendation}
        targets ${intellifox_bid_recommendation}
        keywords_stats ${intellifox_bid_recommendation}
        targets_stats ${intellifox_bid_recommendation}
      }`,
    },
  )

  const fetch_limits = fetch_v2({
      query: 'limits',
      params: {
        company_id: 'ID!',
        subscription_tier: 'String',
      },
      output: `
      {
        products
        products_limit
        
        ai_bg_removal
        ai_bg_removal_limit
        
        amazon_accounts
        amazon_accounts_limit
        
        publish
        publish_limit
        
        keywords_search
        keywords_search_limit
        
        managed_campaigns
        managed_campaigns_limit
      }`,
    },
  )
  const fetch_polls = fetch_v2({
      query: 'polls',
      params: {
        company_id: 'ID!',
      },
      output: `
      {
        poll_id
        label
        start_date
        end_date
        votes
        options {
          option_id
          label
          description
          is_current
        }
      }`,
    },
  )

  const fetch_product_costs = fetch_v2({
      query: 'product_costs',
      params: {
        company_id: 'ID!',
        asin: 'String',
        market_place: 'String',
      },
      output: `
      {
        product_id
        parent_product_id
        asin
        market_place
        currency
        currency_symbol
        price
        price_excl_vat
        vat
        margin
        margin_percentage
        fixed_cost
        commission
        expedition
        amazon_fees
        amazon_fees_commission
        auto_entrepreneur_rate
        auto_entrepreneur_taxes
        is_auto_entrepreneur
        is_not_vat_registered
      }`,
    },
  )

  const fetch_get_search_term_volume = fetch_v2({
      query: 'get_search_term_volume',
      params: {
        company_id: 'ID!',
        market_place: 'String',
        search_term: 'String',
      },
      output: `
      {
        count
      }`,
    },
  )
  const fetch_suggestion = fetch_v2({
      query: 'suggestion',
      params: {
        company_id: 'ID!',
        prompt_type: 'String',
        description: 'String',
        market_place: 'String',
        keywords: '[String]',
      },
      output: `{
        answer
      }`,
    },
  )

  const fetch_optimized_orders = fetch_v2({
      query: 'optimized_orders',
      params: {
        company_id: 'ID!',
        from: 'Float',
        to: 'Float',
        currency: 'String',
      },
      output: `
      {
      
        orders {
          order_id
          order_type
          merchant_id
          market_place
          status
          creation_date
          products {
            asin
            sku
            
            units_sold
            units_returned
            total_sales
            amazon_commissions
            amazon_fba
            amazon_other
            amazon_ads
            total_product_costs
            total_urssaf
            profit
          }
          units_sold
          units_returned
          total_sales
          amazon_commissions
          amazon_fba
          amazon_other
          amazon_ads
          total_product_costs
          total_urssaf
          profit
        }
        products {
          merchant_id
          product_id
          parent_product_id
          asin
          sku
          title
          market_places {
            market_place
          }
          first_visual {
            visual_id
            width
            height
            index
            objects {
                type,
                object_id,
                x,
                y,
                width,
                height,
                angle,
                props
            }
            overrides {
                type,
                object_id,
                x,
                y,
                width,
                height,
                angle,
                props
            }
            renderer
          }
          color_palette {
              colors {
                  color_id
                  color
              }
          }
        }
        
      }`,
    }, (what) => {
      return {
        ...what,
        products: what.products.map((product) => {
          return {
            ...product,
            first_visual: map_visual(product.first_visual),
          }
        }),
      }
    },
  )

  const fetch_subscription_details = async () => {
    const client = get_client()
    const response = await client.request(subscription_details_query, {
      company_id,
    })
    return response.subscription_details
  }


  const fetch_shareable_results = fetch_v2(shareable_results)


  const isLoggedIn = me && me.token


  // if (hash !== 'conrad_dev') {
  //   return <div><CenteredAbsolute><h1><IntellifoxIcon/> Intellifox est en cours de migration vers le cloud !</h1><br/>
  //     Je m'excuse pour les désagréments et je vous  demande de revenir d'ici quelques heures ;) - Conrad</CenteredAbsolute></div>
  // }


  return (
    <DisconnectContext.Provider value={disconnect}>
      <ThemeContext.Provider value={{ theme, set_theme }}>
        <OrderNotificationsContext.Provider value={{ order_notifications, set_order_notifications }}>
          <UserContext.Provider value={me}>
            <TranslationsContext.Provider value={translations_context}>
              <FetchV2Context.Provider value={{ fetch_v2 }}>
                <CachedValues name={'plans'} fetch_value={fetch_plans}>
                  <CachedValues name={'snippets'} fetch_value={fetch_snippets}>
                    <CachedValues name={'images'} fetch_value={fetch_images}>
                      <CachedValues name={'image'} fetch_value={fetch_image}>
                        <CachedValues name={'translation'} fetch_value={fetch_translation}>
                          <CachedValues name={'translations'} fetch_value={fetch_translations}>
                            <CachedValues name={'exchange_rate'} fetch_value={fetch_exchange_rate}>
                              <CachedValues name={'subscription_details'} fetch_value={fetch_subscription_details}>
                                <CachedValues name={'product_count'} fetch_value={fetch_product_count}>
                                  <CachedValues name={'products'} fetch_value={fetch_products}>
                                    <CachedValues name={'minimal_products'} fetch_value={fetch_minimal_products}>
                                      <CachedValues name={'search_products'} fetch_value={fetch_search_products}>
                                        <CachedValues name={'product'} fetch_value={fetch_product}>
                                          <CachedValues name={'product_several_market_places'}
                                                        fetch_value={fetch_product_several_market_places}>
                                            <CachedValues name={'product_market_places'}
                                                          fetch_value={fetch_v2(market_places_query)}>
                                              <CachedValues name={'product_title'} fetch_value={fetch_product_title}>
                                                <CachedValues name={'product_progress'}
                                                              fetch_value={fetch_product_progress}>
                                                  <CachedValues name={'product_title_by_asin'}
                                                                fetch_value={fetch_product_title_by_asin}>
                                                    <CachedValues name={'products_with_updates'}
                                                                  fetch_value={fetch_products_with_updates}>
                                                      <CachedValues name={'product_with_updates'}
                                                                    fetch_value={fetch_product_with_updates}>
                                                        <CachedValues name={'processes'} fetch_value={fetch_processes}>
                                                          <CachedValues name={'pixabay_images'}
                                                                        fetch_value={fetch_pixabay_images}>
                                                            <CachedValues name={'usage'} fetch_value={fetch_usage}>
                                                              <CachedValues name={'product_with_single_visual'}
                                                                            fetch_value={fetch_product_with_single_visual}>
                                                                <CachedValues name={'get_visuals'}
                                                                              fetch_value={fetch_visuals}>
                                                                  <CachedValues name={'product_id_by_asin'}
                                                                                fetch_value={fetch_product_id_by_asin}>
                                                                    <CachedValues name={'products_hierarchy'}
                                                                                  fetch_value={fetch_products_hierarchy}>
                                                                      <CachedValues name={'shapes'}
                                                                                    fetch_value={fetch_shapes}>
                                                                        <CachedValues name={'shape'}
                                                                                      fetch_value={fetch_shape}>
                                                                          <CachedValues name={'search_term_details'}
                                                                                        fetch_value={fetch_search_term_details}>
                                                                            <CachedValues name={'competitors_asin'}
                                                                                          fetch_value={fetch_competitors_asin}>
                                                                              <CachedValues name={'competitors'}
                                                                                            fetch_value={fetch_competitors}>
                                                                                <CachedValues name={'orders'}
                                                                                              fetch_value={fetch_orders}>
                                                                                  <CachedValues
                                                                                    name={'optimized_orders'}
                                                                                    fetch_value={fetch_optimized_orders}>
                                                                                    <CachedValues
                                                                                      name={'shareable_results'}
                                                                                      fetch_value={fetch_shareable_results}>
                                                                                      <CachedValues
                                                                                        name={'orders_with_asin'}
                                                                                        fetch_value={fetch_orders_with_asin}>
                                                                                        <CachedValues
                                                                                          name={'orders_with_comment_request'}
                                                                                          fetch_value={fetch_orders_with_comment_request}>
                                                                                          <CachedValues
                                                                                            name={'amazon_accounts'}
                                                                                            fetch_value={fetch_amazon_accounts}>
                                                                                            <CachedValues
                                                                                              name={'amazon_ads_accounts'}
                                                                                              fetch_value={fetch_amazon_ads_accounts}>
                                                                                              <CachedValues
                                                                                                name={'campaigns'}
                                                                                                fetch_value={fetch_campaigns}>
                                                                                                <CachedValues
                                                                                                  name={'ad_groups'}
                                                                                                  fetch_value={fetch_ad_groups}>
                                                                                                  <CachedValues
                                                                                                    name={'campaign_keywords'}
                                                                                                    fetch_value={fetch_campaign_keywords}>
                                                                                                    <CachedValues
                                                                                                      name={'campaign_targets'}
                                                                                                      fetch_value={fetch_campaign_targets}>
                                                                                                      <CachedValues
                                                                                                        name={'managed_campaigns'}
                                                                                                        fetch_value={fetch_managed_campaigns}>
                                                                                                        <CachedValues
                                                                                                          name={'managed_products_ads'}
                                                                                                          fetch_value={fetch_managed_products_ads}>
                                                                                                          <CachedValues
                                                                                                            name={'ad_groups_reports'}
                                                                                                            fetch_value={fetch_ad_groups_reports}
                                                                                                          >
                                                                                                            <CachedValues
                                                                                                              name={'keywords_reports'}
                                                                                                              fetch_value={fetch_keywords_reports}>
                                                                                                              <CachedValues
                                                                                                                name={'targets_reports'}
                                                                                                                fetch_value={fetch_targets_reports}>
                                                                                                                <CachedValues
                                                                                                                  name={'product_ads_reports'}
                                                                                                                  fetch_value={fetch_product_ads_reports}>
                                                                                                                  <CachedValues
                                                                                                                    name={'all_product_ads_reports'}
                                                                                                                    fetch_value={fetch_all_product_ads_reports}>
                                                                                                                    <CachedValues
                                                                                                                      name={'keywords_bid_recommendations'}
                                                                                                                      fetch_value={fetch_keywords_bid_recommendations}>
                                                                                                                      <CachedValues
                                                                                                                        name={'targets_bid_recommendations'}
                                                                                                                        fetch_value={fetch_targets_bid_recommendations}>
                                                                                                                        <CachedValues
                                                                                                                          name={'get_search_term_volume'}
                                                                                                                          fetch_value={fetch_get_search_term_volume}>
                                                                                                                          <CachedValues
                                                                                                                            name={'suggestion'}
                                                                                                                            fetch_value={fetch_suggestion}>
                                                                                                                            <CachedValues
                                                                                                                              name={'intellifox_bid_recommendations'}
                                                                                                                              fetch_value={fetch_intellifox_bid_recommendations}>
                                                                                                                              <CachedValues
                                                                                                                                name={'limits'}
                                                                                                                                fetch_value={fetch_limits}>
                                                                                                                                <CachedValues
                                                                                                                                  name={'polls'}
                                                                                                                                  fetch_value={fetch_polls}>
                                                                                                                                  <CachedValues
                                                                                                                                    name={'product_costs'}
                                                                                                                                    fetch_value={fetch_product_costs}>
                                                                                                                                    <CachedValues
                                                                                                                                      name={'sold_by'}
                                                                                                                                      fetch_value={fetch_v2(sold_by)}>
                                                                                                                                      <Router>
                                                                                                                                        <VisualRendererStandalone
                                                                                                                                          path={absolute('/visual_renderer_standalone/:company_id/:token/:product_id/:market_place/:index')}/>
                                                                                                                                        <VisualRendererStandalone
                                                                                                                                          path={absolute('/visual_renderer_standalone/:company_id/:token/:product_id/:market_place/:index/:width/:height')}/>
                                                                                                                                        <StandaloneSalesResults
                                                                                                                                          path={absolute('/sales_result_standalone/:company_id/:token/:from/:duration/:lang/:currency')}/>
                                                                                                                                        <MainContent
                                                                                                                                          is_logged_in={isLoggedIn}
                                                                                                                                          disconnect={disconnect}
                                                                                                                                          set_user={set_user}
                                                                                                                                          path={'*'}/>
                                                                                                                                      </Router>
                                                                                                                                    </CachedValues>
                                                                                                                                  </CachedValues>
                                                                                                                                </CachedValues>
                                                                                                                              </CachedValues>
                                                                                                                            </CachedValues>
                                                                                                                          </CachedValues>
                                                                                                                        </CachedValues>
                                                                                                                      </CachedValues>
                                                                                                                    </CachedValues>
                                                                                                                  </CachedValues>
                                                                                                                </CachedValues>
                                                                                                              </CachedValues>
                                                                                                            </CachedValues>
                                                                                                          </CachedValues>
                                                                                                        </CachedValues>
                                                                                                      </CachedValues>
                                                                                                    </CachedValues>
                                                                                                  </CachedValues>
                                                                                                </CachedValues>
                                                                                              </CachedValues>
                                                                                            </CachedValues>
                                                                                          </CachedValues>
                                                                                        </CachedValues>
                                                                                      </CachedValues>
                                                                                    </CachedValues>
                                                                                  </CachedValues>
                                                                                </CachedValues>
                                                                              </CachedValues>
                                                                            </CachedValues>
                                                                          </CachedValues>
                                                                        </CachedValues>
                                                                      </CachedValues>
                                                                    </CachedValues>
                                                                  </CachedValues>
                                                                </CachedValues>
                                                              </CachedValues>
                                                            </CachedValues>
                                                          </CachedValues>
                                                        </CachedValues>
                                                      </CachedValues>
                                                    </CachedValues>
                                                  </CachedValues>
                                                </CachedValues>
                                              </CachedValues>
                                            </CachedValues>
                                          </CachedValues>
                                        </CachedValues>
                                      </CachedValues>
                                    </CachedValues>
                                  </CachedValues>
                                </CachedValues>
                              </CachedValues>
                            </CachedValues>
                          </CachedValues>
                        </CachedValues>
                      </CachedValues>
                    </CachedValues>
                  </CachedValues>
                </CachedValues>
              </FetchV2Context.Provider>
            </TranslationsContext.Provider>
          </UserContext.Provider>
        </OrderNotificationsContext.Provider>
      </ThemeContext.Provider>
    </DisconnectContext.Provider>
  )
}

export default () => (
  <ApolloProvider client={client}>
    <UserApp/>
  </ApolloProvider>
)
