export const set_5_pacifier = [[{
  'type': 'shape',
  'object_id': '1',
  'x': 41.976334487074894,
  'y': 50.154162384378196,
  'width': 411.46613891661355,
  'height': 387.0924824504362,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'image',
  'object_id': '2',
  'x': -10.996916752312444,
  'y': -7.9136690647482055,
  'width': 391.75583319380837,
  'height': 291.6650459308579,
  'angle': null,
  'props': {
    'image_id': '-Ub03i5Rd',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.5)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '3',
  'x': 149.8735649203913,
  'y': 241.50738550377503,
  'width': 353.456342582178,
  'height': 263.65093558984296,
  'angle': null,
  'props': {
    'image_id': 'EGWL8kdUI',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.49)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -394.34737923946557,
  'y': -352.7235354573484,
  'width': 500,
  'height': 470.38194135445406,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 398.0061664953752,
  'y': -208.879753340185,
  'width': 500,
  'height': 470.38194135445406,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 21.227289590659893,
  'y': 193.8623354737236,
  'width': 308.7686039337659,
  'height': 290.4783506953386,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D', 'group_id': 'group_ezsjybuthh' },
}, {
  'type': 'shape',
  'object_id': '4',
  'x': 263.22112309528484,
  'y': 297.1100230379579,
  'width': 188.33952607044012,
  'height': 177.1830238135828,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D', 'image_object_reverse': true, 'group_id': 'group_ezsjybuthh' },
}, {
  'type': 'shape',
  'object_id': '5',
  'x': 121.35061949816253,
  'y': 81.33846488382557,
  'width': 217.7330218793164,
  'height': 204.8353630571293,
  'angle': -0.2369715316123683,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D', 'image_object_reverse': true, 'group_id': 'group_ezsjybuthh' },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 165.775950668037,
  'y': 27.543679342240495,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.38311487645207765,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': 72.7235354573484,
  'y': 85.05652620760534,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': 40.30832476875642,
  'y': 180.08221993833504,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -1.2373264383512017,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': 296.17677286742037,
  'y': 38.211716341212735,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.2696204037070512,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': 359.34224049331965,
  'y': 119.3627954779034,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.9082038892604561,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '11',
  'x': 376.7728674203494,
  'y': 227.74922918807815,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 1.3875714172316282,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'image',
  'object_id': '13',
  'x': 161.6649537512847,
  'y': 192.49743062692707,
  'width': 205.50814709831724,
  'height': 153.29309090332444,
  'angle': 0.25067362217289685,
  'props': {
    'image_id': 'EGWL8kdUI',
    'filters': {
      'border_width': 5,
      'border_color': 'black',
      'border': false,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.43)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '12',
  'x': 100.51387461459402,
  'y': 96.91675231243576,
  'width': 210.63754752296293,
  'height': 156.82117474088767,
  'angle': null,
  'props': {
    'image_id': '-Ub03i5Rd',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.47)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '14',
  'x': 46.09475032010245,
  'y': 287.6653732319342,
  'width': 170.29449423815618,
  'height': 94.7503201024328,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>pack de deux</p>',
    'fontSize': 38,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 40,
  },
}, {
  'type': 'text',
  'object_id': '15',
  'x': 129.47503201024327,
  'y': 372.17241548545536,
  'width': 170.29449423815618,
  'height': 29.449423815621003,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>seulement</p>',
    'fontSize': 22,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 40,
  },
}, {
  'type': 'text',
  'object_id': '16',
  'x': 105.14724711907812,
  'y': 380.6487279054297,
  'width': 202.15108834827143,
  'height': 94.7503201024328,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>8,10 €</p>',
    'fontSize': 66,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 64,
  },
}, {
  'type': 'text',
  'object_id': '17',
  'x': 313.854033290653,
  'y': 341.596231106454,
  'width': 131.08834827144688,
  'height': 94.7503201024328,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>0-6 mois</p>',
    'fontSize': 38,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 40,
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '18',
  'x': -115.74953053860219,
  'y': 201.1372084715298,
  'width': 414.36097001075586,
  'height': 292.85078665596086,
  'angle': 2.6923704561564366,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '20',
  'x': 200.69877860500537,
  'y': 337.25811771954164,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 1.7039410924654703,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '19',
  'x': 25.5263648119019,
  'y': 235.91417430663273,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -153.84658167213084,
  'y': -168.22339903880504,
  'width': 328.38927007843586,
  'height': 308.93676475893363,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 282.1080614167827,
  'y': -20.37548878732356,
  'width': 171.15526275228382,
  'height': 161.01668953290186,
  'angle': 2.99635386558087,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 194.74182018215913,
  'y': 13.207013714267557,
  'width': 21.833827833625616,
  'height': 41.709244256546015,
  'angle': 0.9506962390130201,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': 20.22673648167619,
  'y': 82.49570674537743,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': 298.0448411259624,
  'y': 312.70821463329526,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -1.2373264383512017,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': 242.2422016914699,
  'y': 43.07466948002795,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.9285027163402331,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': 337.11974075689886,
  'y': 164.86407405261633,
  'width': 21.680501339373894,
  'height': 41.709244256546015,
  'angle': -0.5613617634275053,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '11',
  'x': 424.9603121595183,
  'y': 163.20457843653088,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.29829778219320724,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'image',
  'object_id': '12',
  'x': 32.43253067118107,
  'y': 134.30307566652195,
  'width': 283.93126212467484,
  'height': 211.38887437530718,
  'angle': 0.2133423480082236,
  'props': {
    'image_id': '-Ub03i5Rd',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.47)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '13',
  'x': -85.57329334771472,
  'y': 269.34006441011564,
  'width': 296.0972927580309,
  'height': 220.8655464801121,
  'angle': -0.1806418170728587,
  'props': {
    'image_id': 'EGWL8kdUI',
    'filters': {
      'border_width': 5,
      'border_color': 'black',
      'border': false,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.43)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'shape',
  'object_id': '21',
  'x': 332.5133520212502,
  'y': 198.31118485371897,
  'width': 134.3936912575766,
  'height': 126.43273079906,
  'angle': 2.99635386558087,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '22',
  'x': 290.62122116360206,
  'y': 356.24221933647766,
  'width': 134.3936912575766,
  'height': 126.43273079906,
  'angle': 0.1370339419410341,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '23',
  'x': 445.4023988085015,
  'y': 350.3133317521807,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.8438773945053424,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'text',
  'object_id': '24',
  'x': 10.243277848911646,
  'y': 19.27016645326505,
  'width': 151.08834827144685,
  'height': 98.10071254051296,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>recommandé par des <b>experts</b></p>',
    'fontSize': 21,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '25',
  'x': 282.48399487836105,
  'y': 11.101152368758008,
  'width': 151.08834827144685,
  'height': 98.10071254051296,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>accepté par <b>95% des bébés</b></p>',
    'fontSize': 21,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '26',
  'x': 334.1869398207426,
  'y': 211.63892445582587,
  'width': 112.98335467349551,
  'height': 98.10071254051296,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>sans <b>BPA</b></p>',
    'fontSize': 36,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '27',
  'x': 263.27784891165174,
  'y': 374.7375160051216,
  'width': 151.08834827144685,
  'height': 98.10071254051296,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>fabriqué en France</p>',
    'fontSize': 26,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -248.51404590613225,
  'y': -514.529091012904,
  'width': 865.0954580418107,
  'height': 813.850562021255,
  'angle': -0.14087691005477648,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': 53.97353545734842,
  'y': 21.86208176316088,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '13',
  'x': 320.63801838063,
  'y': 26.443267742346578,
  'width': 168.92254447607576,
  'height': 158.91622881838126,
  'angle': -2.8204999728699316,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'F' },
}, {
  'type': 'image',
  'object_id': '12',
  'x': 338.7083190590385,
  'y': 53.51397453465796,
  'width': 166.96825993710002,
  'height': 124.30907488097967,
  'angle': null,
  'props': {
    'image_id': '-Ub03i5Rd',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.47)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'shape',
  'object_id': '14',
  'x': 293.4179799017928,
  'y': 10.264859540938673,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '15',
  'x': 457.1679799017928,
  'y': 2.487081763160891,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.8004073353415244,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '16',
  'x': 47.2374243462373,
  'y': 80.75097065204977,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.5196124663931184,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '17',
  'x': 50.91797990179285,
  'y': 158.73708176316086,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.9231627122506257,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'text',
  'object_id': '18',
  'x': 91.84027777777777,
  'y': 16.87500211927626,
  'width': 234.02777777777777,
  'height': 196.875,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>100% sans danger</p>\n<p>boîte de rangement inclue</p>\n<p>respect des normes<b> UE-28931 PRO</b></p>',
    'fontSize': 20,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
  },
}, {
  'type': 'text',
  'object_id': '19',
  'x': 243.4375,
  'y': 292.777779897054,
  'width': 234.02777777777777,
  'height': 196.875,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>matériaux cheap</p>\n<p>produits chimiques</p>\n<p>qualité incertaine</p>',
    'fontSize': 20,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 100,
  },
}, {
  'type': 'shape',
  'object_id': '20',
  'x': 32.80497390758566,
  'y': 335.2688114537675,
  'width': 139.98499485218068,
  'height': 139.98499485218065,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'text',
  'object_id': '21',
  'x': 321.7708333333333,
  'y': 147.84722434149845,
  'width': 98.54166666666663,
  'height': 80.83333333333337,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p><b>notre</b> produit</p>',
    'fontSize': 28,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 24,
  },
}, {
  'type': 'text',
  'object_id': '22',
  'x': 84.82638888888887,
  'y': 286.9444465637207,
  'width': 98.54166666666663,
  'height': 80.83333333333337,
  'angle': 0.3302835947499958,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>produit <b>générique</b></p>',
    'fontSize': 20,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 400,
    'lineHeight': 24,
  },
}, {
  'type': 'shape',
  'object_id': '23',
  'x': 211.83275168536343,
  'y': 335.12992256487865,
  'width': 19.25691004956326,
  'height': 19.25691004956326,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '24',
  'x': 214.1244183520301,
  'y': 383.25492256487865,
  'width': 19.25691004956326,
  'height': 19.25691004956326,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '25',
  'x': 207.04108501869678,
  'y': 429.99103367598974,
  'width': 19.25691004956326,
  'height': 19.25691004956326,
  'angle': 0,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'image',
  'object_id': '26',
  'x': 34.55521400905155,
  'y': 377.5096218377952,
  'width': 105.45152856894654,
  'height': 98.84965622199047,
  'angle': null,
  'props': { 'image_id': 'xfu19eGaj', 'filters': { 'contrast': true, 'contrast_value': 0.16 } },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'F' },
}, {
  'type': 'shape',
  'object_id': '25',
  'x': -6.928710017505296,
  'y': 278.8872980177904,
  'width': 409.11978832021435,
  'height': 384.8851205531714,
  'angle': -1.1406822847803102,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '21',
  'x': 274.4945533326704,
  'y': 15.360770381065237,
  'width': 323.75018067172834,
  'height': 304.57247699644563,
  'angle': 2.99635386558087,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -124.33269278324195,
  'y': -145.65395459436058,
  'width': 403.47694328763043,
  'height': 379.5765357507931,
  'angle': -1.370395087218544,
  'props': { 'shape_id': 'set_5_egg.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '19',
  'x': 16.151364811901903,
  'y': 124.80306319552162,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': -0.7872247012460372,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'F' },
}, {
  'type': 'image',
  'object_id': '12',
  'x': 99.96391888796097,
  'y': 7.472295287616497,
  'width': 144.9732995301515,
  'height': 107.93366807455104,
  'angle': 0,
  'props': {
    'image_id': '-Ub03i5Rd',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.47)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '13',
  'x': 2.2739288745075186,
  'y': 5.45117552122673,
  'width': 142.1615862149014,
  'height': 106.04148432215668,
  'angle': 0,
  'props': {
    'image_id': 'EGWL8kdUI',
    'filters': {
      'border_width': 5,
      'border_color': 'black',
      'border': false,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.43)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '24',
  'x': 46.701611182244946,
  'y': 95.31183311993175,
  'width': 172.5692221510884,
  'height': 98.10071254051296,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p><b>approuvé</b> par des parents</p>',
    'fontSize': 21,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'shape',
  'object_id': '26',
  'x': 282.6791425896797,
  'y': 5.219729862188274,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.5036198693317817,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '27',
  'x': 418.65136481190194,
  'y': 389.10861875107713,
  'width': 28.945708421131144,
  'height': 41.709244256546015,
  'angle': 0.09707708544041982,
  'props': { 'shape_id': 'set_5_heart.svg', 'fill': 'D' },
}, {
  'type': 'shape',
  'object_id': '28',
  'x': 95.76971903881358,
  'y': 310.7507128606847,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_advpak6bkp' },
}, {
  'type': 'shape',
  'object_id': '29',
  'x': 127.48846903881358,
  'y': 312.34793508290693,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_advpak6bkp' },
}, {
  'type': 'shape',
  'object_id': '30',
  'x': 155.6481912610358,
  'y': 306.91390730512916,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_advpak6bkp' },
}, {
  'type': 'shape',
  'object_id': '31',
  'x': 187.62735792770246,
  'y': 309.5527961940181,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0.4719331308935429,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_advpak6bkp' },
}, {
  'type': 'shape',
  'object_id': '32',
  'x': 213.53013570548023,
  'y': 307.41737952735144,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_advpak6bkp' },
}, {
  'type': 'shape',
  'object_id': '33',
  'x': 333.4780523721469,
  'y': 38.04237952735133,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_8u419mxko0o' },
}, {
  'type': 'shape',
  'object_id': '34',
  'x': 365.1968023721469,
  'y': 39.63960174957356,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_8u419mxko0o' },
}, {
  'type': 'shape',
  'object_id': '35',
  'x': 393.35652459436915,
  'y': 34.205573971795786,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_8u419mxko0o' },
}, {
  'type': 'shape',
  'object_id': '36',
  'x': 425.33569126103583,
  'y': 36.8444628606847,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0.4719331308935429,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_8u419mxko0o' },
}, {
  'type': 'shape',
  'object_id': '37',
  'x': 451.23846903881355,
  'y': 34.70904619401807,
  'width': 29.230280961186395,
  'height': 28.19857266514481,
  'angle': 0,
  'props': { 'shape_id': 'set_5_star.svg', 'fill': 'F', 'group_id': 'group_8u419mxko0o' },
}, {
  'type': 'text',
  'object_id': '38',
  'x': 50.72938896002279,
  'y': 365.8333354526096,
  'width': 329.30533326219944,
  'height': 71.18055555555554,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>J\'ai l\'esprit serein avec ces sucettes, mes enfants adorent !</p>',
    'fontSize': 18,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '39',
  'x': 49.896055626689474,
  'y': 428.54166878594293,
  'width': 329.30533326219944,
  'height': 35.20833333333337,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>- Michelle Rigeaux - Amazon.fr</p>',
    'fontSize': 14,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '40',
  'x': 293.57638888888886,
  'y': 71.38889100816516,
  'width': 196.04166666666669,
  'height': 160.27777777777777,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On a essayé plein de sucettes, celle-ci sont les meilleures, et de loin ! On ne peut que recommander !</p>',
    'fontSize': 18,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}, {
  'type': 'text',
  'object_id': '41',
  'x': 339.0625,
  'y': 228.3333354526096,
  'width': 160.7638888888889,
  'height': 50.694444444444485,
  'angle': 0,
  'props': {
    'fill': 'B',
    'textAlign': 'right',
    'verticalAlign': 'center',
    'content': '<p>- Sarah Fox - Amazon.fr</p>',
    'fontSize': 14,
    'fontFamily': 'KG Indie Flower',
    'fontWeight': 500,
  },
}]]
