import React from 'react'
import { Browse_Content_Section, Browse_Content_SubTitle } from '../browse__content'
import { ShowAllImagesWithTags } from './product_images'

export const PicturesSelector = ({ on_click }) => {


  return <>
    {/* eslint-disable-next-line react/jsx-pascal-case */}
    <Browse_Content_Section>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <Browse_Content_SubTitle>Images globales</Browse_Content_SubTitle>

      <ShowAllImagesWithTags tags={['GLOBAL']} onClick={on_click}/>
    </Browse_Content_Section>
  </>

}
