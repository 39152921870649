import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content from './select__content'
import { PicturesSelectorV2 } from '../designer/pictures/picture_selector_v2'
import { Browse_Content_Section, Browse_Content_Title } from '../designer/browse__content'
import { useTranslate } from '../../../context/lang'


export default function SelectImage() {
  const { get_selected_object, update_object_props } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = get_selected_object()

  const on_click = ({ image_id, width, height }) => update_object_props({
    object: selected_object,
    field: 'image_id',
    value: image_id,
  })
  return (
    <Option_Content>
      <Browse_Content_Title>{translate({
          fr: `Remplacer l'image`,
          en: `Replace image`
      })}</Browse_Content_Title>
      <Browse_Content_Section>
        <span className="font-weight-bold">
        {translate({
            fr: `Choisis l'image que tu veux mettre à la place de l'image sélectionnée :`,
            en: `Select the image you would like to replace the current image with:`
        })}</span>
      </Browse_Content_Section>
      <PicturesSelectorV2 on_click={on_click}/>
    </Option_Content>
  )
}
