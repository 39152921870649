import React from 'react'
import BackToHome from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { useFrench, useTranslate } from '../../context/lang'
import BigBox from '../../components/layout/big_box'
import { FiDownload } from 'react-icons/all'
import moment from 'moment'
import Button from '../../components/generic/button'

export const force_download_json = (object, file_name) => {

  const blob_href = window.URL.createObjectURL(new Blob([JSON.stringify(object)]))
  console.log('force_download success, create link...')
  const link = document.createElement('a')
  link.href = blob_href
  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  console.log('force_download success, click link...')
  link.click()
  console.log('force_download success, success?')
  setTimeout(() => {
    console.log('force_download success, clean link')
    document.body.removeChild(link)
  }, 20000)
}


export default function Support() {
  const translate = useTranslate()
  const french = useFrench()

  return (
    <div className="container">
      <BackToHome/>
      <Title color={section_color('settings')} style={{ color: 'black' }}><WelcomeIcon section={'settings'}/> {translate({
        fr: `Paramètres`,
        en: `Settings`,
      })}
      </Title>

      <BigBox>
        <div className="row">
          <div className="col-12 col-lg-6">
            {french(`Télécharger le cache local`)}
          </div>
          <div className="col-12 col-lg-6">
            <Button onClick={() => force_download_json(window.localStorage, `intellifox_local_storage_${moment().format('YYYYMMDD_HHmm')}.json`)}><FiDownload/> {french(`Télécharger`)}</Button>
          </div>
        </div>
      </BigBox>
    </div>
  )
}
