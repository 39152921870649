import React from 'react'
import { OpacityDrowndown } from './opacity_dropdown'
import { ChangeOrder } from './order_dropdown'
import TextButton from '../../generic/text_button'
import { useDesignerContext } from '../designer_context'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { OptionSeparator } from './options'
import { useTranslate } from '../../../context/lang'

export default function SingleObjectOptions({ object }) {
  const { delete_selected_objects, reinitialize_selected_objects, duplicate_object, edit_overrides, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  object = apply_overrides_to_object(object, current_objects.overrides[ current_product_id ])

  return (
    <div className="row no-gutters align-items-center">
      <div className="col-auto">
        <OpacityDrowndown object={object}/>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <ChangeOrder object={object}/>
      </div>
      <OptionSeparator/>
      {edit_overrides ? <>
        <div className="col-auto">
          <TextButton onClick={() => reinitialize_selected_objects()}>{translate({
              fr: `Réinitialiser`,
              en: `Reset`
          })}</TextButton>
        </div>
        <OptionSeparator/>
      </> : null}
      <div className="col-auto">
        <TextButton onClick={() => duplicate_object()} disabled={edit_overrides}>{translate({
            fr: `Dupliquer`,
            en: `Duplicate`
        })}</TextButton>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <TextButton onClick={() => delete_selected_objects()} disabled={edit_overrides}>{translate({
            fr: `Supprimer`,
            en: `Delete`
        })}</TextButton>
      </div>
    </div>
  )
}
