import React, { useContext } from 'react'
import { BackTo } from '../../components/navigation/back_to_home'
import Title, { ColorBar } from '../../components/layout/title'
import {
  compute_most_likely_region_and_market_place,
  compute_region_id_for_market_place,
  MarketPlaceContext,
  MarketPlaceDetails, useRegionForProduct,
  useSourceMarketPlace,
} from '../../hooks/useMarketPlace'
import { MenuTitle } from '../../components/navigation/left-menu'
import { MarketPlaceChooser, useProductMenu } from './edit'
import PageSubTitle from '../../components/generic/page-sub-title'
import { section_color } from '../colors'
import { extract_params } from '../../components/layout/tabs_util'
import { useProduct } from '../../context/products'
import { DefaultError } from '../../components/i18n/translations'
import { useCachedContext } from '../../hooks/useCachedContext'
import useHashParam from 'use-hash-param'
import { scroll_to_node } from './scroll'
import { SquareVisual } from './product_preview/product_preview'
import { clean_for_label } from '../product/text-substitution'

import './product_menu.scss'
import RoundDecorationRight from '../../components/generic/RoundDecorationRight'
import { FaArrowRight } from 'react-icons/fa'
import Flag from '../../components/flag/flag'
import UserContext from '../../context/user'
import { useFrench, useTranslate } from '../../context/lang'
import useLocalStorage from '../../hooks/useLocalStorage'
import InternalLink from '../../components/generic/internal-link'
import TextButton from '../../components/generic/text_button'
import { useWindowSize } from '../../hooks/useWindowSize'
import { absolute } from '../../absolute_link'
import { MdClose, MdMenu } from 'react-icons/md'
import { designer_context_for_product, DesignerContext } from '../../components/visual/designer_context'
import MagicButton from './magic_button'

export default function ProductMenu({ product_id, location }) {
  const translate = useTranslate()
  return (
    <Menu>
      <BackTo to={'/product'} label={translate({
        fr: 'Liste des produits',
      })} pt={'pt-2'}/>
      <CurrentProductMenu path={location.pathname}/>

      <LatestProducts path={location.pathname}/>
    </Menu>
  )
}

const Menu = ({ children }) => {
  const window_size = useWindowSize()
  const french = useFrench()

  const is_mobile = window_size.width < 1080
  const is_not_that_large = window_size.width < 1280

  const [is_open_on_mobile, set_is_open_on_mobile] = React.useState(false)

  if (is_mobile || is_not_that_large) {
    return null
  }

  const width = is_not_that_large ? 160 : 264

  return <div className={`col-auto product_menu ${is_not_that_large ? 'compact' : ''}`} style={{
    height: '100vh', width,
    overflowX: 'hidden',
    overflowY: 'auto',
  }}>
    {children}
  </div>
}

export function ProductListMenu({ location }) {


  return (
    <Menu>
      <LatestProducts path={location.pathname}/>
    </Menu>
  )
}


export const CurrentProductMenu = ({ path }) => {
  const { product_id, market_place } = extract_params(path, '/product/edit/:product_id/:market_place', '')
  const product = useProduct(product_id, market_place)

  if (product.loading) {
    return <div></div>
  }

  if (product.error) {
    return <DefaultError/>
  }

  return <ProductLoadedForMenu product={product} market_place={market_place}/>
}


export const SecondaryMenuItem = ({ children, style = {}, className = '', color, opacity, on_click }) => {
  return <div className={`row pl-2 pr-2 ${className}`} style={style} onClick={on_click}>
    <div className="col-12 product_menu_header position-relative">
      <ColorBar color={color} opacity={opacity}/>
      <div className="position-relative" style={{ zIndex: 2 }}>
        {children}
      </div>
    </div>
  </div>
}

const ProductLoadedForMenu = ({ product, market_place }) => {
  const region_id = compute_region_id_for_market_place(market_place)
  const first_visual = useCachedContext('product_with_single_visual', product.product_id, region_id, 0)

  React.useEffect(() => {
    first_visual.refresh()
  }, [product])

  const [stats, _] = [true] // useLocalStorage('beta_feature_stats', false)

  const menu = useProductMenu(product, null, stats)
  const default_menu = 'start'
  const [current_menu, set_current_menu] = useHashParam('menu', default_menu)

  return <>
    <MarketPlaceContext.Provider value={market_place}>
      <DesignerContext.Provider value={designer_context_for_product(product)}>
        <div className="row">
          <div className="col-12">
            <SecondaryMenuItem className={'mb-3'} color={section_color('product')} opacity={1}
                               style={{ color: 'white' }}>
              <div className="row align-items-center no-gutters clickable p-2" onClick={() => {
                set_current_menu('')
                setTimeout(() => {
                  scroll_to_node(`amazon_preview`)
                }, 250)
              }
              }>
                <div className="col-auto mr-2">
                  <div style={{
                    background: 'white',
                    overflow: 'hidden',
                    borderRadius: '4px',
                    width: 50,
                    height: 50,
                  }}>
                    <SquareVisual visual={first_visual} width={50} reduce_image_size={true}/>
                  </div>
                </div>
                <div className="col">
                  <h5>{clean_for_label(product.product_info.title, 30, [])}</h5>
                </div>
              </div>
            </SecondaryMenuItem>


            <SecondaryMenuItem className="mb-2">
              <div className="row text-center">
                <div className="col-12 font-weight-bold mb-2">
                  <MarketPlaceDetails market_place={market_place}/>
                </div>
                <div className="col">
                  <MarketPlaceChooser product_id={product.product_id} market_place={market_place}/>
                </div>
              </div>
            </SecondaryMenuItem>

            <div className="row pl-2 pr-2">
              <div className="col-12 color_bar top">
              </div>
              <div className="col-12 product_menu_items_container">

                {menu.map(({ label, Icon, key, background, color, menu }, i) => {
                  const is_current = key === current_menu
                  const is_default = key === default_menu

                  return <div key={i}
                              className={`row  product_menu_item clickable align-items-center mb-2 pt-2 pb-2 position-relative ${is_current ? 'current' : ''}`}
                              onClick={() => {
                                if (is_default) {
                                  set_current_menu(key)
                                  setTimeout(() => {
                                    scroll_to_node(`current_menu`)
                                  }, 200)
                                } else if (is_current) {
                                  set_current_menu('')
                                  setTimeout(() => {
                                    scroll_to_node(`amazon_preview`)
                                  }, 500)
                                } else {
                                  set_current_menu(key)
                                  setTimeout(() => {
                                    scroll_to_node(`current_menu`)
                                  }, 500)
                                }
                              }
                              }>
                    <div className={`color position-absolute ${is_current ? 'active' : ''}`} style={{
                      background,
                    }}></div>

                    <div className="col-auto pl-3 pl-xl-4">
                      <Icon/>
                    </div>
                    <div className="col product_menu_label text-ellipsis">
                      {label}
                    </div>
                    {is_current ? <div className="col-auto">
                      <FaArrowRight/>
                    </div> : null}

                    {/*{is_current ? <RoundDecorationRight size={10}/> : null}*/}
                    {is_current && menu.length > 1 ? <>
                      <div className="col-12 mt-3 mb-2">
                        {menu.map((sub_menu_item, i) => {
                          return <div className="row clickable position-relative sub_item" key={sub_menu_item.key}
                                      onClick={(e) => {
                                        set_current_menu(key)
                                        setTimeout(() => {
                                          scroll_to_node(`${key}-${sub_menu_item.key}`)
                                        }, 50)

                                        e.stopPropagation()
                                      }
                                      }>
                            <div className="col-12 text-ellipsis mb-2 ">
                              {sub_menu_item.label}
                            </div>
                          </div>
                        })}
                      </div>
                    </> : null}
                  </div>
                })}

              </div>
              <div className="col-12 color_bar bottom">

              </div>
            </div>

          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 text-center">
            <MagicButton product={product}/>
          </div>
        </div>
      </DesignerContext.Provider>
    </MarketPlaceContext.Provider>
  </>
}


export const LatestProducts = ({ path, MenuTitle }) => {
  const user = useContext(UserContext)
  const translate = useTranslate()

  const [latest_products, set_latest_products] = useLocalStorage(`latest_products_${user.user.user_id}`, [])

  const [show_latest_products, set_show_latest_products] = useLocalStorage('show_latest_products', true)
  const { product_id } = extract_params(path, '/product/edit/:product_id', '')

  React.useEffect(() => {
    const is_product_path = /\/product\/edit\//.test(path)
    if (product_id && is_product_path) {
      set_latest_products([product_id, ...latest_products.filter((id) => id !== product_id)])
    }
  }, [product_id, path, set_latest_products])

  const on_error = (product_id) => {
    if (product_id) {
      set_latest_products([...latest_products.filter((id) => id !== product_id)])
    }
  }

  if (latest_products.length === 0) {
    return null
  }

  return <>
    <SecondaryMenuItem className="mt-5 font-weight-bold text-center mb-2 clickable" color={section_color('product')}
                       opacity={1}
                       style={{ color: 'white' }} on_click={() => set_show_latest_products(!show_latest_products)}>
      {translate({
        fr: `Produits récents`,
        en: `Recently opened products`,
      })}
    </SecondaryMenuItem>
    {show_latest_products ? <>{latest_products.filter((p, i) => p !== product_id && i < 8).map((product_id) => {
      return <LoadMarketPlacesForProduct product_id={product_id} MenuTitle={MenuTitle} on_error={on_error}
                                         key={product_id}/>
    })}</> : null}

    {latest_products.length === 0 ? null :
      <div className="row text-center">
        <div className="col-12">
          {show_latest_products ?
            <TextButton className={'btn-sm'} onClick={() => set_show_latest_products(!show_latest_products)}>
              {translate({
                fr: `Replier les produits récents`,
                en: `Hide recently opened products`,
              })}
            </TextButton> :
            <TextButton className={'btn-sm'} onClick={() => set_show_latest_products(!show_latest_products)}>
              {translate({
                fr: `Voir les produits récents`,
                en: `See recently opened products`,
              })}
            </TextButton>}
        </div>
        {show_latest_products ? <div className="col-12">
          <TextButton className={'btn-sm'} onClick={() => set_latest_products([])}>
            {translate({
              fr: `Vider la liste`,
              en: `Empty the list`,
            })}
          </TextButton>
        </div> : null}
      </div>
    }
  </>

}

const LoadMarketPlacesForProduct = ({ product_id, on_error }) => {
  const product = useCachedContext('product_market_places', product_id)

  React.useEffect(() => {
    if (product.error) {
      on_error(product_id)
    }
  }, [product])

  if (product.loading) {
    return <div></div>
  }

  if (product.error) {
    return <div></div>
  }

  return <LoadProduct product_id={product_id} market_places={product.market_places} on_error={on_error}/>
}
const LoadProduct = ({ product_id, on_error, market_places }) => {
  const source_market_place = useSourceMarketPlace()

  const { region_id, market_place } = compute_most_likely_region_and_market_place(market_places, source_market_place)
  const product = useProduct(product_id, market_place)

  React.useEffect(() => {
    if (product.error) {
      on_error(product_id)
    }
  }, [product])
  if (product.loading) {
    return <div></div>
  }

  if (product.error) {
    return <div></div>
  }

  return <ProductMenuTitle product={product} region_id={region_id}/>
}

const ProductMenuTitle = ({ product, region_id }) => {
  const first_visual = useCachedContext('product_with_single_visual', product.product_id, region_id, 0)


  React.useEffect(() => {
    first_visual.refresh()
  }, [product])

  if (!first_visual || !first_visual.objects) {
    return null
  }

  return <>
    <DesignerContext.Provider value={designer_context_for_product(product)}>
      <InternalLink to={`/product/edit/${product.product_id}`}>
        <SecondaryMenuItem className="mb-2" color={section_color('product')}>
          <div className="row no-gutters pt-2 pb-2">
            <div className="col-auto mr-2">
              <div style={{ borderRadius: '4px', overflow: 'hidden', marginLeft: 22, width: 48, height: 48 }}>
                <SquareVisual visual={first_visual} width={48} reduce_image_size={true}/></div>
            </div>
            <div className="col small font-weight-bold">
              {clean_for_label(product.product_info.title, 25, [])}
            </div>
          </div>
        </SecondaryMenuItem>
      </InternalLink>
    </DesignerContext.Provider>
  </>
}
