import Slider from 'react-input-slider'
import DropdownButton from 'react-bootstrap/DropdownButton'
import React from 'react'
import { useDesignerContext } from '../designer_context'
import { useTranslate } from '../../../context/lang'

export const OpacityDrowndown = ({ object }) => {
  const { update_object_props } = useDesignerContext()
  const translate = useTranslate()

  return <DropdownButton title={translate({
      fr: "Opacité",
      en: "Opacity",
  })} variant="link">
    <div className="container">
      <div className="row no-gutters">
        <div className="col-12">
          <Slider axis={'x'} x={( 100 * object.props.opacity || 100 ).toFixed(0)}
                  onChange={({ x }) => update_object_props({ object, field: 'opacity', value: x / 100 })}
                  xmin={0}
                  xmax={100}/>
        </div>
      </div>
    </div>
  </DropdownButton>
}
