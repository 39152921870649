import React from 'react'
import Button from './button'
import { useTranslate } from '../../context/lang'

export default function UpdateButton(
  {
    children,
    disabled = false,
    className = '',
    button = 'primary',
    outline = false,
    style,
    loading, loadingMessage,
    error, errorMessage,
    data, successMessage,
    onClick = () => {
    },
    never_go_back = false,
  },
) {
  const translate = useTranslate()

  const [success, set_success] = React.useState(false)
  const timeout_ref = React.useRef(-1)

  if (!loadingMessage) {
    loadingMessage = translate({
      fr: `Modification en cours`,
      en: `Update in progress`,
    })
  }
  if (!errorMessage) {
    errorMessage = translate({
      fr: `Erreur pendant la modification`,
      en: `An error occurred`,
    })
  }
  if (!successMessage) {
    successMessage = translate({
      fr: 'Modification réussie',
      en: `Update successful`,
    })
  }

  React.useEffect(() => {
    if (data) {
      set_success(true)
      clearTimeout(timeout_ref.current)
      if (never_go_back) {
        return
      }
      timeout_ref.current = setTimeout(() => {
        set_success(false)
      }, 10000)
    }
  }, [data])
  return (
    <Button disabled={disabled || loading} onClick={onClick} className={className}
            outline={outline} button={success ? 'success' : button} style={style}>
      {loading ? loadingMessage : null}
      {error ? errorMessage : null}
      {success ? successMessage : null}
      {( !loading && !error && !success ) ? children : null}
    </Button>
  )
}
