import React from 'react'
import { IoIosGrid, IoIosApps, IoIosImages } from 'react-icons/io'
import { MdTextFields } from 'react-icons/md'
import { FaShapes } from 'react-icons/fa'
import BrowseBackgrounds from './browse_backgrounds'
import BrowseElements from './browse_elements'
import BrowseModels from './browse_models'
import { BrowsePictures } from './browse_pictures'
import BrowseTexts from './browse_texts'
import TextButton from '../../generic/text_button'
import { useDesignerContext } from '../designer_context'
import { BrowseProductPictures } from './browse_product_pictures'
import { FaChess } from 'react-icons/fa'
import { IoMdArrowDropleft, IoMdArrowDropright, MdSlowMotionVideo } from 'react-icons/all'
import BrowseTutorials from './browse_tutorials'
import { useLang, useTranslate } from '../../../context/lang'

export const browse_pictures = {
  key: 'browse_pictures',
  label: 'Photos',
  label_translations: {
    fr: 'Photos',
    en: 'Pictures',
  },
  icon: <IoIosImages/>,
  content: <BrowsePictures/>,
  color: 'var(--color-menu-3)',
}

export const menu_categories = [
  {
    key: 'help', label: 'Tutoriels', label_translations: {
      fr: 'Tutoriels',
      en: 'Tutorials',
    }, icon: <MdSlowMotionVideo/>, content: <BrowseTutorials/>,
    color: 'var(--color-menu-1)',
  },
  {
    key: 'browse_models', label: 'Modèles', label_translations: {
      fr: 'Modèles',
      en: 'Templates',
    }, icon: <IoIosGrid/>, content: <BrowseModels/>,
    color: 'var(--color-menu-2)',
  },
  browse_pictures,
  {
    key: 'browse_elements', label: 'Éléments', label_translations: {
      fr: 'Éléments',
      en: 'Elements',
    }, icon: <FaShapes/>, content: <BrowseElements/>,
    color: 'var(--color-menu-4)',
  },
  {
    key: 'browse_texts', label: 'Textes', label_translations: {
      fr: 'Textes',
      en: 'Texts',
    }, icon: <MdTextFields/>, content: <BrowseTexts/>,
    color: 'var(--color-menu-5)',
  },
  {
    key: 'browse_backgrounds', label: 'Arrière-plan', label_translations: {
      fr: 'Arrière-plan',
      en: 'Backgrounds',
    }, icon: <IoIosApps/>, content: <BrowseBackgrounds/>,
    color: 'var(--color-menu-6)',
  },
]

export const MenuButton = ({ icon, color, label, on_click, style = {}, is_current }) => {
  return <TextButton style={style}
                     className={`btn-sm smaller position-relative left_menu_like mb-2 ${is_current ? 'is_current' : ''}`}
                     onClick={on_click}>
    <div className="row text-center">
      <div className="col-12 pb-1" style={{ fontSize: 40, height: 45, lineHeight: '45px', color }}>
        {icon}
      </div>
      <div className="col-12">
        {label}
      </div>
    </div>
    {is_current ? <div className="position-absolute" style={{
      top: -11,
      color: 'var(--color-text-menu)',
      right: -23,
      fontSize: 40,
      height: '100%',
      lineHeight: '70px',
    }}>
      <IoMdArrowDropright/>
    </div> : null}
  </TextButton>

}

const MenuCategory = ({
                        icon,
                        color,
                        label,
                        current_menu,
                        menu_content_key,
                        set_current_menu,
                        current_option,
                        edit_overrides,
                        index,
                      }) => {
  const is_current = current_menu === menu_content_key && !current_option

  return <>
    <MenuButton style={{
      height: 70, opacity: edit_overrides ? 0.2 : 1,
      marginLeft: 5,
      marginRight: is_current ? 8 : 5,
      marginBottom: 5,
    }}
                is_current={is_current}
                on_click={() => {
                  if (is_current) {
                    set_current_menu('')
                  } else {
                    set_current_menu(menu_content_key)
                  }
                }}
                color={color}
                icon={icon} label={label}>
    </MenuButton>
  </>
}

export default function MenuCategories({}) {
  const translate = useTranslate()

  const lang = useLang()

  const { current_menu, set_current_menu, set_current_option, edit_overrides, current_option } = useDesignerContext()

  let filtered_menu_categories = menu_categories
  if (lang !== 'fr') {
    filtered_menu_categories = menu_categories.filter((m, i) => i !== 0)
  }
  return (
    <>
      {filtered_menu_categories.map(({ label_translations, icon, key, color }, i) => <MenuCategory key={key}
                                                                                                   menu_content_key={key}
                                                                                                   color={color}
                                                                                                   label={translate(label_translations)}
                                                                                                   index={i}
                                                                                                   icon={icon}
                                                                                                   edit_overrides={edit_overrides}
                                                                                                   current_menu={current_menu}
                                                                                                   current_option={current_option}
                                                                                                   set_current_menu={(menu) => {
                                                                                                     set_current_option(null)
                                                                                                     set_current_menu(menu)
                                                                                                   }}/>)}
    </>
  )
}
