import { gql } from '@apollo/client'

export const GET_BILLS = gql`
    query getBills($company_id: ID!, $page:Float) {
        bills(company_id: $company_id, page:$page) {
            date
            receipt_url
            amount
            currency
        }
    }
`
