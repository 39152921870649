import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, {
  Option_Content_Picker,
  Option_Content_Picker_Item, Option_Content_Section, Option_Content_SubSubTitle,
  Option_Content_SubTitle,
  Option_Content_Title,
} from './select__content'
import tinycolor from 'tinycolor2'
import tinygradient from 'tinygradient'
import { ChromePicker } from 'react-color'
import Button from '../../generic/button'
import TextArea from '../../generic/text-area'
import TextButton from '../../generic/text_button'
import { Numeric } from './numeric'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { useTranslate } from '../../../context/lang'

const FontOptions = ({ options, field }) => {
  const { get_selected_object, update_object_props, current_product_id, current_objects } = useDesignerContext()

  const object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])
  const translate = useTranslate()

  const base_style = {
    fontWeight: object.props.fontWeight,
    fontFamily: object.props.fontFamily,
    fontSize: 20,
  }

  return <Option_Content_Picker>
    <div className="col-12">
      {options.map(({ value, label }, i) => {
        return <div className="row">
          <div className="col-4">
            {label}{translate({
                fr: ` :`,
                en: `:`
            })}
          </div>
          <div className="col-8">

            <TextButton className={`ml-3 mr-3 ${object.props[ field ] === value ? 'focus' : ''}`} key={i}
                        onClick={() => update_object_props({ object, field, value })}>
              <span style={{
                ...base_style,
                [ field ]: value,
              }} dangerouslySetInnerHTML={{ __html: object.props.content }}></span>
            </TextButton>
          </div>
        </div>
      })}
    </div>
  </Option_Content_Picker>
}

export default function SelectFont({}) {
  const translate = useTranslate()
  const font_options = [
    { value: 'Lato', label: 'Lato' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'KoHo', label: 'KoHo' },
    { value: 'Trirong', label: 'Trirong' },
    { value: 'Taviraj', label: 'Taviraj' },
    { value: 'Tomorrow', label: 'Tomorrow' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Grenze', label: 'Grenze' },
    { value: 'Alegreya Sans SC', label: 'Alegreya Sans SC' },
    { value: 'Source Code Pro ', label: 'Monospace' },
    { value: 'Dot Matrix', label: 'Dot Matrix' },
    { value: 'KG Indie Flower', label: 'KG Indie Flower' },
    { value: 'BlackChancery', label: 'BlackChancery' },
    { value: 'blzee', label: 'blzee' },
    { value: 'Burnstown Dam', label: 'Burnstown Dam' },
    { value: 'GlazKrak', label: 'GlazKrak' },
    { value: 'Pricedown Bl', label: 'Pricedown Bl' },
    { value: 'Youre Gone', label: 'Youre Gone' },
  ]

  return (
    <>
      <Option_Content_Title>{translate({
          fr: `Police`,
          en: `Font`
      })}</Option_Content_Title>

      <Option_Content_Section>
        <Option_Content_SubTitle>{translate({
            fr: `Taille de la police`,
            en: `Font size`
        })}</Option_Content_SubTitle>
        <Option_Content_Picker>
          <Numeric field={'fontSize'} max_value={100}>{translate({
              fr: `Taille`,
              en: `Size`
          })}</Numeric>
        </Option_Content_Picker>
      </Option_Content_Section>

      <Option_Content_Section>
        <Option_Content_SubTitle>{translate({
            fr: `Finesse de la police`,
            en: `Font weight`
        })}</Option_Content_SubTitle>
        <Option_Content_Picker>
          <Numeric field={'fontWeight'} min_value={100} max_value={1000} step={100}>{translate({
              fr: `Finesse`,
              en: `Weight`
          })}</Numeric>
        </Option_Content_Picker>
      </Option_Content_Section>
      <Option_Content_Section>
        <Option_Content_SubTitle>{translate({
            fr: `Interligne`,
            en: `Line height`
        })}</Option_Content_SubTitle>
        <Option_Content_Picker>
          <Numeric field={'lineHeight'} min_value={0} max_value={200} step={2}>{translate({
              fr: `Interligne`,
              en: `Line height`
          })}</Numeric>
        </Option_Content_Picker>
      </Option_Content_Section>

      <Option_Content_Section>
        <FontOptions options={font_options} field={'fontFamily'}/>
      </Option_Content_Section>



    </>
  )
}
