import { useDesignerContext } from '../designer_context'
import React from 'react'
import { DraggableCore } from 'react-draggable'
import * as _ from 'lodash'
import { compute_rectangle, distance, project_point, rotate_rectangle } from './geometry'
import { MdCropRotate } from 'react-icons/md'
import { MdEdit } from 'react-icons/all'

function find_angle(reference, center, mouse) {
  const reference_center = Math.sqrt(Math.pow(center.x - reference.x, 2) + Math.pow(center.y - reference.y, 2))
  const center_mouse = Math.sqrt(Math.pow(center.x - mouse.x, 2) + Math.pow(center.y - mouse.y, 2))
  const reference_mouse = Math.sqrt(Math.pow(mouse.x - reference.x, 2) + Math.pow(mouse.y - reference.y, 2))
  const angle = Math.acos(( center_mouse * center_mouse + reference_center * reference_center - reference_mouse * reference_mouse ) / ( 2 * center_mouse * reference_center ))

  if (mouse.x > center.x) {
    return -angle
  }
  return angle
}

export const apply_move_delta_to_object = ({ delta_x, delta_y }, object_on_start) => {
  if (object_on_start.type === 'arrow') {
    return {
      props: {
        ...object_on_start.props,
        start_x: object_on_start.props.start_x + delta_x,
        start_y: object_on_start.props.start_y + delta_y,
        end_x: object_on_start.props.end_x + delta_x,
        end_y: object_on_start.props.end_y + delta_y,
      },
    }
  }
  return {
    x: object_on_start.x + delta_x,
    y: object_on_start.y + delta_y,
  }
}

export const compute_rect_style = ({ x, y, width, height, angle = 0, props = {} }, compute_color = (c) => c) => {

  if (!compute_color) {
    compute_color = (c) => c
  }

  if (props === null) {
    return {}
  }

  let filters_css = ''
  const filters = props.filters

  let border_radius = {}
  let border = {}

  if (filters) {
    if (filters.drop_shadow) {
      filters_css += `drop-shadow(${filters.drop_shadow_x}px ${filters.drop_shadow_y}px ${filters.drop_shadow_spread}px ${compute_color(filters.drop_shadow_color)})`
    }
    if (filters.contrast) {
      filters_css += ` contrast(${filters.contrast_value})`
    }
    if (filters.brightness) {
      filters_css += ` brightness(${filters.brightness_value})`
    }
    if (filters.blur) {
      filters_css += ` blur(${filters.blur_value}px)`
    }

    if (filters[ 'saturate' ]) {
      filters_css += ` saturate(${filters[ 'saturate_value' ]})`
    }
    if (filters[ 'sepia' ]) {
      filters_css += ` sepia(${filters[ 'sepia_value' ]})`
    }
    if (filters[ 'grayscale' ]) {
      filters_css += ` grayscale(${filters[ 'grayscale_value' ]})`
    }
    if (filters[ 'hue-rotate' ]) {
      filters_css += ` hue-rotate(${filters[ 'hue-rotate_value' ]}deg)`
    }
    if (filters[ 'invert' ]) {
      filters_css += ` invert(${filters[ 'invert_value' ]})`
    }


    if (filters.border_radius) {
      const option = filters.border_radius_option || '%'
      border_radius = {
        borderRadius: `${filters.border_radius_value}${option}`,
        overflow: 'hidden',
      }
    }

    if (filters.border) {
      border = {
        border: `${filters.border_width}px solid ${compute_color(filters.border_color)}`,
      }
    }
  }

  if (angle === null) {
    angle = 0
  }

  return {
    left: x,
    top: y,
    width,
    height,
    transform: `rotate(${angle}rad) translateZ(0)`,
    opacity: props.opacity,
    filter: filters_css,
    ...border_radius,
    ...border,
  }
}


export const DraggableHandler = ({
                                   children,
                                   object,
                                   handle_deltas,
                                   controller_ref,
                                   scale,
                                   on_start,
                                   on_stop,
                                   update_objects,
                                 }) => {
  const { save_history, update_multiple_objects, get_selected_objects } = useDesignerContext()
  // const { set_debug_info } = React.useContext(ControllerDebugContext)

  if (!update_objects) {
    update_objects = update_multiple_objects
  }


  const start_x_ref = React.useRef(-1)
  const start_y_ref = React.useRef(-1)

  const value_on_start_ref = React.useRef(null)
  const is_dragging_ref = React.useRef(false)


  return <DraggableCore
    onStart={(e) => {
      save_history()

      const controller_dimension = controller_ref.current.getBoundingClientRect()

      let touchX = 0
      let touchY = 0

      if (e.touches && e.touches.length > 0) {
        touchX = e.touches[ 0 ].clientX
        touchY = e.touches[ 0 ].clientY
      }

      const clientX = touchX ? touchX : e.clientX
      const clientY = touchY ? touchY : e.clientY

      const x = ( clientX - controller_dimension.left ) / scale
      const y = ( clientY - controller_dimension.top ) / scale


      start_x_ref.current = x
      start_y_ref.current = y
      console.log('yo', get_selected_objects())
      value_on_start_ref.current = _.clone(get_selected_objects())

      on_start && on_start()
      // set_debug_info({ x, y, delta_x: 0, delta_y: 0 })
    }}
    onDrag={(e) => {
      is_dragging_ref.current = true
      e.stopPropagation()
      const controller_dimension = controller_ref.current.getBoundingClientRect()

      let touchX = 0
      let touchY = 0

      if (e.touches && e.touches.length > 0) {
        touchX = e.touches[ 0 ].clientX
        touchY = e.touches[ 0 ].clientY
      }

      const clientX = touchX ? touchX : e.clientX
      const clientY = touchY ? touchY : e.clientY

      const x = ( clientX - controller_dimension.left ) / scale
      const y = ( clientY - controller_dimension.top ) / scale


      const delta_x = x - start_x_ref.current
      const delta_y = y - start_y_ref.current

      const objects = get_selected_objects().map(({ object_id }) => {
        return {
          object_id,
          updates: handle_deltas({
            x,
            y,
            delta_x,
            delta_y,
            shift: e.shiftKey,
          }, value_on_start_ref.current.find((o) => o.object_id === object_id)),
        }
      })

      update_objects(objects, false)
      // update_single_object(object.object_id, {
      //   ...handle_deltas({ x, y, delta_x, delta_y, shift: e.shiftKey }, value_on_start_ref.current),
      // }, false)

      // set_debug_info({ x, y, delta_x, delta_y, })

    }}
    onStop={(e) => {
      if (is_dragging_ref.current) {
        e.stopPropagation()
      }
      setTimeout(() => {
        is_dragging_ref.current = false
      }, 400)
      on_stop && on_stop()
    }}
  >
    <div onClick={(e) => {
      if (is_dragging_ref.current) {
        e.stopPropagation()
      }
    }}>
      {children}
    </div>
  </DraggableCore>
}
export const GlobalControl = ({ object, scale, controller_ref }) => {
  return <DraggableHandler object={object} scale={scale} controller_ref={controller_ref}
                           handle_deltas={apply_move_delta_to_object}>
    <div className="position-absolute handle global" style={{
      left: 0, top: 0, width: '100%', height: '100%',
    }}></div>
  </DraggableHandler>
}

const angle_is_close_to = (angle, value) => angle < value + 0.05 && angle > value - 0.05


const RotateControl = ({ object, scale, controller_ref }) => {
  const center = {
    x: object.x + object.width / 2,
    y: object.y + object.height / 2,
  }

  const reference = {
    x: center.x,
    y: center.y + 100,
  }

  const [show_angle, set_show_angle] = React.useState(false)


  return <DraggableHandler object={object} scale={scale} controller_ref={controller_ref}
                           on_start={() => set_show_angle(true)}
                           on_stop={() => set_show_angle(false)}
                           handle_deltas={({ x, y, shift }) => {
                             let angle = find_angle(reference, center, { x, y })

                             if (!shift) {
                               if (angle_is_close_to(angle, 0)) {
                                 angle = 0
                               }
                               if (angle_is_close_to(angle, Math.PI)) {
                                 angle = Math.PI
                               }
                               if (angle_is_close_to(angle, Math.PI / 2)) {
                                 angle = Math.PI / 2
                               }
                               if (angle_is_close_to(angle, -Math.PI / 2)) {
                                 angle = -Math.PI / 2
                               }
                             }

                             return ( {
                               angle,
                             } )
                           }}>
    <div className="position-absolute handle rotate" style={{
      left: '50%', bottom: -40, transform: 'translateX(-50%)',
    }}><MdCropRotate/> {show_angle ? <span>{( object.angle * 360 / ( 2 * Math.PI ) ).toFixed(0)}°</span> : null}
    </div>
  </DraggableHandler>
}


const EditControl = ({ object, scale, controller_ref }) => {
  const reversed_scale = 1 / scale

  const { set_selected_objects } = useDesignerContext()

  return <div className="position-absolute handle edit clickable" style={{
    left: '50%', top: '50%', zIndex: 99999, transform: `translateX(-50%) translateY(-50%) scale(${reversed_scale})`,
  }}
              onClick={(e) => {
                e.stopPropagation()
                set_selected_objects({
                  [ object.object_id ]: true,
                })
              }}
  ><MdEdit/>
  </div>
}


function isIpadOS() {
  return navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
}

const radius = isIpadOS() ? 12 : 8

const scale_radius = (scale) => radius / scale


const CornerControl = ({ object, scale, controller_ref, fixed_point, handle, children, keep_ratio, update_only }) => {
  // DEBUG const { set_debug_points } = React.useContext(ControllerDebugContext)

  return <DraggableHandler object={object} controller_ref={controller_ref} scale={scale}
                           handle_deltas={({ x, y, delta_x, delta_y, shift }, value_on_start) => {

                             const rectangle = rotate_rectangle(value_on_start, object.angle)

                             let m = { x, y }
                             if (keep_ratio && !shift) {
                               const fixed_point_to_mouse = distance(rectangle[ fixed_point ].x, rectangle[ fixed_point ].y, x, y)
                               m = project_point(rectangle[ fixed_point ], rectangle[ handle ], fixed_point_to_mouse)
                             }

                             const {
                               debug_points,
                               ...dim_pos
                             } = compute_rectangle(rectangle, object.angle, fixed_point, m)

                             // DEBUG set_debug_points([...debug_points, { x: 10, y: 10, l: object.angle }])

                             if (update_only) {
                               return {
                                 x: dim_pos.x,
                                 y: dim_pos.y,
                                 [ update_only ]: dim_pos[ update_only ],
                               }
                             }

                             return dim_pos
                           }}>
    {children}
  </DraggableHandler>
}

const BottomRightControl = ({ object, scale, controller_ref, keep_ratio }) => {
  const scaled_radius = scale_radius(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'top_left'} handle='bottom_right' keep_ratio={keep_ratio}>
    <div className="position-absolute handle round bottom_right" style={{
      bottom: -scaled_radius,
      right: -scaled_radius,
      width: scaled_radius * 2,
      height: scaled_radius * 2,
    }}></div>
  </CornerControl>
}


const TopRightControl = ({ object, scale, controller_ref, keep_ratio }) => {
  const scaled_radius = scale_radius(scale)
  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'bottom_left'} handle={'top_right'} keep_ratio={keep_ratio}>
    <div className="position-absolute handle round top_right" style={{
      top: -scaled_radius,
      right: -scaled_radius,
      width: scaled_radius * 2,
      height: scaled_radius * 2,
    }}></div>
  </CornerControl>
}


const TopLeftControl = ({ object, scale, controller_ref, keep_ratio }) => {
  const scaled_radius = scale_radius(scale)
  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'bottom_right'} handle={'top_left'} keep_ratio={keep_ratio}>
    <div className="position-absolute handle round top_left" style={{
      top: -scaled_radius,
      left: -scaled_radius,
      width: scaled_radius * 2,
      height: scaled_radius * 2,
    }}></div>
  </CornerControl>
}


const BottomLeftControl = ({ object, scale, controller_ref, keep_ratio }) => {
  const scaled_radius = scale_radius(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'top_right'} handle={'bottom_left'} keep_ratio={keep_ratio}>
    <div className="position-absolute handle round bottom_left" style={{
      bottom: -scaled_radius,
      left: -scaled_radius,
      width: scaled_radius * 2,
      height: scaled_radius * 2,
    }}></div>
  </CornerControl>
}

const side_control_width = isIpadOS() ? 40 : 30
const side_control_height = isIpadOS() ? 15 : 10

const scale_side_control = (scale) => ( {
  width: side_control_width / scale,
  height: side_control_height / scale,
} )

const BottomControl = ({ object, scale, controller_ref }) => {
  const dimensions = scale_side_control(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'top_left'} handle={'bottom_right'} update_only={'height'}>
    <div className="position-absolute handle rectangle bottom" style={{
      bottom: -dimensions.height / 2,
      left: '50%',
      transform: 'translateX(-50%)',
      ...dimensions,
    }}></div>
  </CornerControl>
}
const TopControl = ({ object, scale, controller_ref }) => {
  const dimensions = scale_side_control(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'bottom_left'} handle={'top_right'} update_only={'height'}>
    <div className="position-absolute handle rectangle bottom" style={{
      top: -dimensions.height / 2,
      left: '50%',
      transform: 'translateX(-50%)',
      ...dimensions,
    }}></div>
  </CornerControl>
}
const RightControl = ({ object, scale, controller_ref }) => {
  const dimensions = scale_side_control(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'top_left'} handle={'bottom_left'} update_only={'width'}>
    <div className="position-absolute handle rectangle right" style={{
      right: 0,
      top: '50%',
      transform: 'translateX(50%) rotate(90deg)',
      ...dimensions,
    }}></div>
  </CornerControl>
}
const LeftControl = ({ object, scale, controller_ref }) => {
  const dimensions = scale_side_control(scale)

  return <CornerControl object={object} controller_ref={controller_ref} scale={scale}
                        fixed_point={'top_right'} handle={'bottom_left'} update_only={'width'}>
    <div className="position-absolute handle rectangle right" style={{
      left: 0,
      top: '50%',
      transform: 'translateX(-50%) rotate(90deg)',
      ...dimensions,
    }}></div>
  </CornerControl>
}

export const RectControl = ({
                              scale,
                              controller_ref,
                              keep_ratio,
                              on_double_click,
                              selected_objects: props_selected_objects,
                              ...object
                            }) => {
  const { object_id } = object

  const { click_on_object, selected_objects, set_selected_objects, multiple_selection } = useDesignerContext()

  const is_selected = selected_objects[ object_id ]
  const is_alone = props_selected_objects.length === 1

  const double_click_ref = React.useRef(false)
  const single_click_timeout_ref = React.useRef(0)

  const reversed_scale = 1 / scale
  const px = (p) => `${( reversed_scale * p )}px`

  return (
    <div className={`position-absolute control ${is_selected ? 'is_selected' : ''}`}
         style={{
           ...compute_rect_style(object),
           background: 'transparent',
           filter: '',
           opacity: 1,
           overflow: 'visible',
           cursor: multiple_selection ? 'copy' : undefined,
           // boxShadow: is_selected ? `0 0 ${px(4)} black` : '',

           border: is_selected ? `${px(1)} solid rgba(0,0,244,0.7)` : '',
         }}
         onClick={(e) => {
           clearTimeout(single_click_timeout_ref.current)
           e.stopPropagation()
           single_click_timeout_ref.current = setTimeout(() => {
             if (double_click_ref.current) {
               return
             }


             return click_on_object(e, object_id)
           }, 0)

         }}
         onDoubleClick={(e) => {
           // console.log('double clic !!! ', on_double_click)
           if (on_double_click) {
             double_click_ref.current = true
             set_selected_objects({ [ object_id ]: true })
             on_double_click()
             setTimeout(() => {
               double_click_ref.current = false
             }, 400)
           }
         }}>
      {is_selected ? <>
        <GlobalControl scale={scale} object={object} controller_ref={controller_ref}/>
      </> : null}
      {is_selected && is_alone ? <>
        <TopLeftControl scale={scale} object={object} controller_ref={controller_ref} keep_ratio={keep_ratio}/>
        <TopRightControl scale={scale} object={object} controller_ref={controller_ref} keep_ratio={keep_ratio}/>
        <BottomRightControl scale={scale} object={object} controller_ref={controller_ref} keep_ratio={keep_ratio}/>
        <BottomLeftControl scale={scale} object={object} controller_ref={controller_ref} keep_ratio={keep_ratio}/>
        <BottomControl scale={scale} object={object} controller_ref={controller_ref}/>
        <TopControl scale={scale} object={object} controller_ref={controller_ref}/>
        <RightControl scale={scale} object={object} controller_ref={controller_ref}/>
        <LeftControl scale={scale} object={object} controller_ref={controller_ref}/>
        <RotateControl scale={scale} object={object} controller_ref={controller_ref}/>
      </> : null}
      {is_selected && !is_alone ? <>
        <EditControl scale={scale} object={object} controller_ref={controller_ref}/>
      </> : null}

    </div>
  )
}
