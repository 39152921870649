import { useFrench } from '../../context/lang'
import { FiEdit } from 'react-icons/all'
import React from 'react'
import Button from '../generic/button'
import { usePrices, useSkuAsinFnsku } from '../../routes/product_v2/edit_product_global_properties'
import { QuickUpdateModal } from './quick_update_modal'

export const UpdateSKUAsinVariation = ({ product, parent_product, market_place }) => {
  const french = useFrench()

  const [show, set_show] = React.useState(false)

  return <>
    <span className={'badge badge-light clickable'} onClick={() => set_show(true)}><FiEdit/> {french(`Modifier l'ASIN ou le SKU`)}</span>
    <QuickUpdateModal title={french(`Modifier l'ASIN, le SKU ou le nom de la variation`)}
                      product={product}
                      parent_product={parent_product}
                      market_place={market_place}
                      useFields={useSkuAsinFnsku}
                      show={show} close={() => set_show(false)}/>
  </>
}


