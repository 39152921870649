import useCompanyId from '../../hooks/useCompanyId'
import { useFrench } from '../../context/lang'
import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { useQuery } from '../../graphql/simple-client'
import { await_mutate } from '../../graphql/mutate_promise'
import Button from '../generic/button'
import { FiCheck, FiRefreshCw, FiX } from 'react-icons/all'

export const UpdateAmazonFees = ({ product, parent_product, merchant_id, market_place, asin, price, currency }) => {
  const company_id = useCompanyId()
  const french = useFrench()

  const [loading, set_loading] = React.useState(false)
  const [error, set_error] = React.useState(false)
  const [success, set_success] = React.useState(false)


  const [update_product] = useMutation(UPDATE_PRODUCT)

  const amazon_product_fees = useQuery({
    query: 'amazon_product_fees',
    params: {
      merchant_id: 'String',
      market_place: 'String',
      asin: 'String',
      price: 'Float',
      currency: 'String',
    },
    output: `{
      commission_rate
      commission
      expedition
      currency
    }`,
  })

  const update_amazon_fees = async () => {
    set_loading(true)
    set_error(false)
    set_success(false)

    try {
      const fees = await amazon_product_fees({
        merchant_id,
        market_place,
        asin,
        price,
        currency,
      })


      await await_mutate(update_product, {
        variables: {
          company_id,
          market_place,
          product_id: product.product_id,
          price: JSON.stringify({
            ...( product.product_info.price || {} ),
            expedition: fees.expedition,
            commission: Math.floor(fees.commission_rate * 10000) / 100,
          }),
        },
      })

      if (product && product.refresh) {
        await product.refresh()
      }

      if (parent_product && parent_product.refresh) {
        await parent_product.refresh()
      }
      set_success(true)
      set_loading(false)

      setTimeout(() => {
        set_success(false)
      }, 2000)
    } catch (e) {
      console.log('Failed to update amazon fees', e)
      set_loading(false)
      set_error(true)
    }
  }

  return <Button className={'btn-sm'} onClick={update_amazon_fees} disabled={loading}>
    {!loading && !error && !success ? <><FiRefreshCw/> {french(`Mettre à jour`)}</> : null}
    {loading ? <><FiRefreshCw className={'spin'}/> {french(`Mise à jour...`)}</> : null}
    {error ? <><FiX/> {french(`Erreur`)}</> : null}
    {success ? <><FiCheck/> {french(`Mise à jour réussie`)}</> : null}
  </Button>

}
