import React from 'react'

import './product_preview.css'
import { apply_variables, clean_for_label, compute_variables } from '../../product/text-substitution'
import safe from '../../product/safe'
import Item from '../../../components/generic/item'
import { indices } from '../edit_visual'
import useMarketPlace, {
  compute_region_id_for_market_place,
  useMarketPlaceDetails,
} from '../../../hooks/useMarketPlace'
import { compute_scale, DomRenderer, Scaler } from '../../../components/visual/renderer/dom_renderer'
import { apply_overrides } from '../../../components/visual/override/apply_overrides'
import useHashParam from 'use-hash-param'
import Button from '../../../components/generic/button'
import useCompanyId from '../../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { Modal } from 'react-bootstrap'
import { await_mutate } from '../../../graphql/mutate_promise'
import { scroll_to_node } from '../scroll'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { useProduct } from '../../../context/products'
import IntellifoxIcon from '../../../images/intellifox-icon'
import { FaRegEdit } from 'react-icons/all'
import InternalLink from '../../../components/generic/internal-link'
import { DarkSmallCopyButton } from '../../../components/generic/copy-button'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import { useFrench, useTranslate } from '../../../context/lang'
import { DesignerContext } from '../../../components/visual/designer_context'
import ShowCompetitors from './competitors'
import Stars from '../../../components/generic/stars'
import moment from 'moment'
import { start_process } from '../../../graphql/queries/import'
import { Loader } from '../../../components/generic/loader'
import ProductStarter from './product_starter'

export const flatten_nested_p_tags = (html) => {
  // This pattern captures content between opening and closing <p> tags that are nested inside another <p> tag
  const nestedPattern = /<p>([^<]*?)<p>([^<]*?)<\/p>([^<]*?)<\/p>/g

  // Merge the captured groups to form a single <p> tag
  const transformed = html.replace(nestedPattern, '<p>$1$2$3</p>')

  return transformed
}


export const EditButton = ({ hash, sub_menu, children, className = '', margin = 'ml-3', fontSize = 13 }) => {

  const [_, set_current_menu] = useHashParam('menu')
  const translate = useTranslate()


  return <Button onClick={() => {
    set_current_menu(hash)
    setTimeout(() => {
      const id = sub_menu ? `${hash}-${sub_menu}` : 'current_menu'
      console.log(`document.getElementById('${id}').scrollIntoView({ behavior: 'smooth', block: 'start' })`)
      scroll_to_node(id)
    }, 500)
  }} className={`${margin} ${className}`}
                 style={{ fontSize }}> {children ? children : <>{translate({
    fr: `Modifier`,
    en: `Edit`,
  })} <FaRegEdit/></>}</Button>
}


const SquarePlaceholder = ({ style, width }) => {
  return <div style={{
    ...style,
    overflow: 'hidden',
    opacity: 0,
    height: width,
  }}>
  </div>
}

export const SquareVisual = ({
                               visual, width, className, reduce_image_size, style = {}, open = () => {
  },
                               auto_scale = false,
                             }) => {


  if (!visual || visual.loading || visual.error) {
    return <div
      className={className} style={{ width, height:width, background: 'var(--color-white)', opacity: 0.3}}><span> </span></div>
  }


  const scale = compute_scale({
    available_height: width,
    available_width: width,
    width: visual.width,
    height: visual.height,
  })

  if (!visual.objects) {
    return <div
      className={className} style={{ width, height:width }}><span> </span></div>
  }

  if (auto_scale) {
    return <DomRenderer objects={apply_overrides(visual.objects, visual.overrides)}
                        width={visual.width}
                        height={visual.height}
                        onClick={open}
                        reduce_image_size={reduce_image_size}
    />
  }

  return <div
    className={className}
    style={{
      width,
      height: width,
      overflow: 'hidden',
      border: visual.objects.length === 0 ? '1px solid grey' : '', ...style,
    }}>
    <Scaler scale={scale}>
      <DomRenderer objects={apply_overrides(visual.objects, visual.overrides)}
                   width={visual.width}
                   height={visual.height}
                   onClick={open}
                   reduce_image_size={reduce_image_size}
      />
    </Scaler>
  </div>
}

// const WtfVisual = ({ visual, className = 'clickable', product, width }) => {
//   const [close, set_close] = React.useState(null)
//
//   const tags = get_product_tags({
//     sku: product.sku,
//     product,
//   })
//
//   const get_button = React.useCallback((open) => {
//     return <SquareVisual visual={visual} width={width} className={className} open={open}/>
//   }, [product, visual])
//
//   return <WtfButton force_open={false}
//                     set_close={set_close}
//                     button={get_button}>
//     <EditVisual product={product} visual={visual} close={close}/>
//   </WtfButton>
// }


const Visual = ({ product, parent_product, index, className = '', width, reduce_image_size }) => {
  const [real_index, set_real_index] = React.useState(index)
  let selected_visual = product.product_info.visuals.find((v) => v.index === real_index)
  let parent_visual

  if (parent_product) {
    parent_visual = parent_product.product_info.visuals.find((v) => v.index === real_index)
  }

  React.useEffect(() => {
    set_real_index(-1)
    setTimeout(() => {
      set_real_index(index)
    }, 50)
  }, [index])

  if (!selected_visual) {
    return <SquarePlaceholder width={width} style={{}}/>
  }

  const designer_context = {
    compute_color: (value) => {
      if (!product || !product.color_palette) {
        return value
      }

      // first find if value is a color id in product palette
      const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
      if (palette_color) {
        return palette_color.color
      }

      return value
    },
    product: parent_product || product,

    current_product_id: ( product || parent_product ).product_id,
  }

  return (
    <DesignerContext.Provider value={designer_context}>
      <SquareVisual visual={selected_visual} width={width} className={'clickable'}
                    reduce_image_size={reduce_image_size}/>
    </DesignerContext.Provider>
  )
}

const VisualPreview = ({ product, parent_product, width, show_edit_button }) => {

  const [selected_index, set_selected_index] = React.useState(0)
  const market_place = useMarketPlace()
  const translate = useTranslate()


  return <div className="row no-gutters">
    <div className="col-auto" style={{ width: 40, marginRight: 10 }}>
      <div className="row">
        {indices.map(({ index, label, grey }, i) => {

          return <div className="col-12 mb-2" onClick={() => set_selected_index(index)} key={i}
                      style={{ height: 40, opacity: grey ? 0.3 : 1 }}>
            <Visual
              className={`image_selector clickable ${selected_index === index ? 'selected' : ''}`}
              product={product}
              width={40}
              parent_product={parent_product}
              index={index} reduce_image_size={true}/>
          </div>
        })}
      </div>
    </div>
    <div className="col-auto" style={{ width: width - 50, height: width - 50 }}>
      <InternalLink
        to={`/product/edit/${( parent_product || product ).product_id}/${market_place}/visual/${selected_index}`}>
        <Visual product={product}
                width={width - 50}
                parent_product={parent_product}
                index={selected_index}/>
      </InternalLink>
      <div className="text-center">{show_edit_button ? <EditButton hash={'images'} className={'mt-2'}>{translate({
        fr: `Éditer les images`,
        en: `Edit images`,
      })}</EditButton> : null}</div>
      {indices[ selected_index ].grey ?
        <div className="text-center mt-2"><small>
          {translate({
            fr: `Cette image apparaît uniquement lorsque l'utilisateur clique sur
          l'image principale.`,
            en: `This image only appears when the user browse the gallery of images.`,
          })}
        </small></div> : null}
    </div>
  </div>
}


export const ImportFromAmazon = ({ product, source_product }) => {
  const [show, set_show] = React.useState(false)

  const handleClose = () => set_show(false)
  const onSuccess = () => {
    console.log('ImportFromAmazon launch success, refresh...')
    source_product.refresh()
    product.refresh()
    setTimeout(() => {
      source_product.refresh()
      product.refresh()
    }, 1000)
    setTimeout(() => {
      source_product.refresh()
      product.refresh()
    }, 3000)
    setTimeout(() => {
      source_product.refresh()
      product.refresh()
    }, 6000)
  }

  return <>
    <Button onClick={() => set_show(true)} button={'danger'}>Importer à nouveau le produit depuis Amazon</Button>
    <ImportDataModal show={show} handleClose={handleClose} onSuccess={onSuccess} product={product}/>
  </>
}

const ImportDataModal = ({ show, handleClose, onSuccess, product }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const translate = useTranslate()
  const french = useFrench()


  const [in_progress, set_in_progress] = React.useState(false)
  const [success, set_success] = React.useState(false)
  const [error, set_error] = React.useState('')
  const [import_texts, set_import_texts] = React.useState(false)
  const [import_images, set_import_images] = React.useState(false)
  const [is_confirmed, set_is_confirmed] = React.useState(false)

  const [import_product, mutation] = useMutation(start_process, {
    variables: {
      company_id,
      type: 'reimport_product',
      initial_data: JSON.stringify({
        market_place,
        product_id: product.product_id,
        import_texts,
        import_images: 'only_if_empty',
      }),

    },
  })

  React.useEffect(() => {
    set_is_confirmed(false)
  }, [show])

  return <>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{translate({
          fr: `Importer depuis Amazon`,
          en: `Import from Amazon`,
        })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger">
                {translate({
                  fr: `Attention ! Cela écrase les données présentes. Il est impossible de
                revenir en arrière.`,
                  en: `Beware! This will DESTROY your current values in Intellifox. It is IMPOSSIBLE to go back`,
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {is_confirmed ? <div className="alert alert-danger font-weight-bold h4">
          {french(`Attention, en cliquant sur le bouton ci-dessous, voici ce qu'il va se passer :`)}
          <ol>
            <li><IntellifoxIcon/> {french(`Intellifox va aller récupérer la dernière version du produit sur Amazon`)}
            </li>
            <li>{french(`Le contenu présent sur Intellifox va être ÉCRASÉ par la dernière version du produit sur Amazon`)}</li>
            {import_images ? <li>{french(`Les images seront SUPPRIMÉES et importées à nouveau`)}</li> : null}
          </ol>
          {french(`Après avoir cliqué sur le bouton ci-dessous, il est IMPOSSIBLE de revenir en arrière.`)}
        </div> : null}
        {in_progress ? null : <Button outline={true} onClick={handleClose} className="mr-2">
          {translate({
            fr: `Annuler`,
            en: `Cancel`,
          })}
        </Button>}
        <Button disabled={in_progress} onClick={async () => {
          if (!is_confirmed) {
            set_is_confirmed(true)
          } else {
            set_in_progress(true)
            console.log('todo')
            const what = await await_mutate(import_product)
            if (what.start_process) {
              set_success(true)
              set_in_progress(false)
              handleClose()
              onSuccess && onSuccess()
            } else {
              set_in_progress(false)
              set_error(translate({
                fr: `L'import n'a pas fonctionné`,
                en: `The import failed`,
              }))
            }
          }
        }}>
          {in_progress ? translate({
            fr: `Import en cours...`,
            en: <>Import in progress...</>,
          }) : translate({
            fr: `Lancer l'import`,
            en: <>Launch import</>,
          })}
        </Button>
        {in_progress ? <small>{translate({
          fr: `L'import peut prendre jusqu'à une minute...`,
          en: `The import can take up to one minute...`,
        })}</small> : null}
        {error ? <small>{error}</small> : null}
      </Modal.Footer>
    </Modal>
    <Modal show={success}>
      <Modal.Header closeButton>
        <Modal.Title>{translate({
          fr: `Import lancé !`,
          en: `Import launched!`,
        })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `L'import peut prendre entre 10 et 120 secondes, en fonction du nombre de produits et de variations.`,
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => set_success(false)}>
          {translate({
            fr: `Fermer`,
            en: `Close`,
          })}
        </Button>

      </Modal.Footer>
    </Modal>
  </>
}

export const ProductAsinAndSku = ({ product }) => {
  const french = useFrench()
  return <>
    <DarkSmallCopyButton text_to_copy={product.sku} className={'btn-sm mr-1 mb-1'}>
      <span className={'small font-weight-bold mr-2'}>SKU</span>
      <div className={'d-inline-block text-ellipsis position-relative'}
           style={{ maxWidth: '120px', textOverflow: 'ellipsis', lineHeight: '12px', top: 2 }}>
        {product.sku === 'n/a' ? french('Non renseigné') : product.sku}
      </div>
    </DarkSmallCopyButton>
    <DarkSmallCopyButton text_to_copy={product.asin} className={'btn-sm mb-1'}>
      <span className={'small font-weight-bold mr-2'}>ASIN</span>
      <div className={'d-inline-block text-ellipsis position-relative'}
           style={{ maxWidth: '120px', textOverflow: 'ellipsis', lineHeight: '12px', top: 2 }}>
        {product.asin || french('Non renseigné')}
      </div>
    </DarkSmallCopyButton>
  </>
}

export default function ProductPreview({
                                         product,
                                         current_view = 'product',
                                         show_edit_button = true,
                                         show_starter_if_necessary = false,
                                       }) {
  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)
  const source_product = useProduct(product.product_id, region_id)

  const translate = useTranslate()

  const edit_style = show_edit_button ? { borderRight: '1px solid rgba(0, 0, 0, 0.1)' } : {}

  const has_variation = product.variations && product.variations.length > 0
  const [selected_product_id, set_selected_product_id] = React.useState(has_variation ? product.variations[ 0 ].product_id : product.product_id)

  const market_place_currency = useMarketPlaceDetails(market_place).currency_symbol

  const product_id_ref = React.useRef(product.product_id)
  React.useEffect(() => {
    if (product.product_id !== product_id_ref.current) {
      const has_variation = product.variations && product.variations.length > 0
      set_selected_product_id(has_variation ? product.variations[ 0 ].product_id : product.product_id)
      product_id_ref.current = product.product_id
    }
  }, [product])

  const selected_product = has_variation ? product.variations.find(v => v.product_id === selected_product_id) || product : product
  const selected_source_product = has_variation ? source_product.variations.find(v => v.product_id === selected_product_id) || source_product : source_product

  const value = React.useCallback((...keys) => {
    return safe(selected_product, ...keys) || safe(product, ...keys) || ''
  }, [selected_product, product])


  const snippets = useCachedContext('snippets')

  if (!selected_product) {
    return <DefaultLoading/>
  }

  if (snippets.loading) {
    return <DefaultLoading/>
  }

  if (snippets.error) {
    return <DefaultError/>
  }

  const variables = compute_variables(selected_product, snippets)


  const sale_from_date = value('product_info', 'sale_from_date')
  const sale_end_date = value('product_info', 'sale_end_date')

  const price = {
    standard_price: value('product_info', 'standard_price') || 0,
    sale_price: value('product_info', 'sale_price'),
  }


  const is_sale_price_active = !!price.sale_price && !!sale_from_date && !!sale_end_date && moment().isBefore(moment(sale_end_date))

  price.diff = price.standard_price - price.sale_price
  price.percentage = ( price.diff / price.standard_price ) * 100

  const display_price = (price) => price.toFixed(2).replace('.', ',')

  const has_bullet_point = !!value('product_info', 'key_points', 0).replace('<p><br></p>', '') ||
    !!value('product_info', 'key_points', 1).replace('<p><br></p>', '') ||
    !!value('product_info', 'key_points', 2).replace('<p><br></p>', '') ||
    !!value('product_info', 'key_points', 3).replace('<p><br></p>', '') ||
    !!value('product_info', 'key_points', 4).replace('<p><br></p>', '')
  const has_description = value('product_info', 'description')

  const has_description_but_no_bullet_points = !has_bullet_point && has_description


  const bullet_point = (i) => {
    return <li>
      <div className="row no-gutters align-items-center">
        <div className="col mb-0 pr-1" style={edit_style}>
          {clean_for_label(value('product_info', 'key_points', i), 500, variables) || <><i>{translate({
            fr: `Point fort`,
            en: `Bullet point`,
          })}
            {i + 1}</i></>}
        </div>
        <div className="col-auto">
          {show_edit_button ? <EditButton
            hash={'texts'} sub_menu={`key_point_${i}`}/> : null}
        </div>
      </div>
    </li>
  }
  const bullet_points = <div className="row">
    <div className="col-12">
      <ul className={'amazon_key_points'}>
        {product.import_in_progress ? <Loader size={20}/> : bullet_point(0)}
        {product.import_in_progress ? <Loader size={20}/> : bullet_point(1)}
        {product.import_in_progress ? <Loader size={20}/> : bullet_point(2)}
        {product.import_in_progress ? <Loader size={20}/> : bullet_point(3)}
        {product.import_in_progress ? <Loader size={20}/> : bullet_point(4)}
      </ul>
    </div>
  </div>

  const description = <div className="row">

    <div className="col-12 title_2 mb-2">
      {translate({
        fr: `Description du produit`,
        en: `Product description`,
      })} {show_edit_button ? <EditButton hash={'texts'} sub_menu={'description'}/> : null}
    </div>

    {product.import_in_progress ? <Loader size={20}/> : <div className="col-12 pl-5"
                                                             dangerouslySetInnerHTML={{ __html: flatten_nested_p_tags(apply_variables(value('product_info', 'description'), variables)) }}>
    </div>}

  </div>
  const product_info_does_not_exist_or_marketplace = !product.product_info || product.product_info.title === null

  return (
    <div className="row BLUR_FOR_WEBINAR">
      <a name="product_preview" id="product_preview" className="position-relative" style={{ top: -100 }}></a>

      {show_starter_if_necessary ? <div className="col-12">
        <ProductStarter product={product}/>
      </div> : null}

      <div className="col-12">
        <div id="amazon_preview" className="amazon_preview row ">
          {current_view === 'product' ? <div className="col-12">
            <Item style={{ background: 'var(--color-white)' }}>
              <div className="row">
                <div className="col-12 col-lg-auto" style={{ width: 430 }}>
                  {
                    source_product.import_in_progress ?
                      <div className="row">
                        <div className="col-12 text-center">
                          <Loader size={200} label="Importation des images en cours"/>
                        </div>
                      </div> :
                      <VisualPreview product={selected_source_product} parent_product={source_product} width={400}
                                     show_edit_button={show_edit_button}/>
                  }
                </div>
                <div className="col-12 col-lg">

                  <div className="row no-gutters align-items-center">
                    <div className="col" style={edit_style}>
                      <div className="title">
                        {product.import_in_progress ?
                          <Loader size={20}/> : clean_for_label(value('product_info', 'title'), 200, variables)}
                      </div>
                    </div>
                    {show_edit_button ? <div className="col-auto">
                      <EditButton hash={'texts'}/>
                    </div> : null}

                    <div className="col-12">
                      <Stars rating={product.rating} ratings_total={product.ratings_total}/>
                    </div>
                    <div className="col-12">

                      {show_edit_button ? <div className="row no-gutters mb-2">
                        <div className="col" style={edit_style}>
                          <ProductAsinAndSku product={selected_product}/>
                        </div>
                        <div className="col-auto">
                          <EditButton hash={'properties'} sub_menu={'sku-asin-variations'}/>
                        </div>
                      </div> : null}
                    </div>
                    <div className="col-12">
                      <hr/>
                    </div>
                  </div>
                  <div className="row no-gutters no-gutters align-items-center">
                    <div className="col" style={edit_style}>
                      {is_sale_price_active ? <>
                        <div className="row grey no-gutters">
                          <div className="col-auto text-right" style={{ width: 90 }}>
                            {translate({
                              fr: `Prix :`,
                              en: `Price:`,
                            })}
                          </div>
                          <div className="col pl-1">
                            <del>{display_price(price.standard_price)} {market_place_currency}</del>
                          </div>
                        </div>
                        <div className="row red no-gutters align-items-center">
                          <div className="col-auto text-right" style={{ width: 90 }}>
                            {translate({
                              fr: `Nouveau Prix :`,
                              en: `Deal Price:`,
                            })}
                          </div>
                          <div className="col pl-1">
                            <span
                              className="sale_price">{display_price(price.sale_price)} {market_place_currency}</span>
                          </div>
                        </div>
                        <div className="row grey no-gutters">
                          <div className="col-auto text-right" style={{ width: 90 }}>
                            {translate({
                              fr: `Économisez :`,
                              en: `You Save:`,
                            })}
                          </div>
                          <div className="col pl-1 red">
                            {display_price(price.diff)} {market_place_currency} ({price.percentage.toFixed(0)} %)
                          </div>
                        </div>
                      </> : <div className="row sale_price no-gutters">
                        <div className="col-auto text-right" style={{ width: 90 }}>
                          {translate({
                            fr: `Prix :`,
                            en: `Price:`,
                          })}
                        </div>
                        <div className="col pl-1">
                          {display_price(price.standard_price)} {market_place_currency}
                        </div>
                      </div>}

                      {product.import_in_progress ? <Loader size={20}/> : null}
                    </div>
                    {show_edit_button ? <div className="col-auto">
                      <EditButton hash={'price'}/>
                    </div> : null}
                  </div>


                  <div className="row mt-3 mb-3 no-gutters align-items-center">
                    {has_variation ? <div className="col-12">
                      <span className="grey">Variation :</span>
                      <b>{clean_for_label(value('product_info', 'variation'), 50, variables)}</b>
                    </div> : null}

                    <div className="col" style={edit_style}>
                      {product.variations.map((variation, i) => {
                        return <div key={i}
                                    className={`d-inline-block amazon_variation_button clickable ${variation.product_id === selected_product.product_id ? 'selected' : ''}`}
                                    onClick={() => set_selected_product_id(variation.product_id)}>{
                          clean_for_label(variation.product_info.variation, 50, variables)}</div>
                      })}
                    </div>
                    {show_edit_button ? <div className="col-auto">
                      <EditButton hash={'properties'} sub_menu={'add-variation'}/>
                    </div> : null}
                  </div>

                  {has_description_but_no_bullet_points ? description : bullet_points}

                </div>

                {!has_description_but_no_bullet_points ?
                  <div className="col-12">
                    {description}
                  </div> : null}

              </div>
            </Item>
          </div> : null}

          {current_view === 'competitors' ? <div className="col-12">
            <Item style={{ background: 'var(--color-white)' }}>
              <div className="row">
                <ShowCompetitors product={product}/>
              </div>
            </Item>
          </div> : null}
        </div>
      </div>
    </div>
  )
}
