import { useDesignerContext } from '../designer_context'
import React from 'react'
import { apply_move_delta_to_object, compute_rect_style, DraggableHandler, GlobalControl } from './rect_controls'
import { absolute } from '../../../absolute_link'


const arrow_control_size = 20

const Control = ({ object, on_double_click, on_move, controller_ref, scale, left, top, width, height, center, hide_selected }) => {
  const { object_id } = object

  const { click_on_object, selected_objects, set_selected_objects, multiple_selection } = useDesignerContext()

  const is_selected = selected_objects[ object_id ]

  const double_click_ref = React.useRef(false)
  const single_click_timeout_ref = React.useRef(0)

  const reversed_scale = 1 / scale
  const px = (p) => `${( reversed_scale * p )}px`

  return <div className={`position-absolute control ${is_selected ? 'is_selected' : ''}`}
              style={{
                left: center ? left - width / 2 : left,
                top: center ? top - height / 2 : top,
                width,
                height,
                background: 'transparent',
                filter: '',
                opacity: 1,
                overflow: 'visible',
                cursor: multiple_selection ? 'copy' : undefined,
                border: is_selected && !hide_selected ? `${px(1)} solid rgba(0,0,244,0.7)` : '',
              }}
              onClick={(e) => {
                clearTimeout(single_click_timeout_ref.current)
                e.stopPropagation()
                single_click_timeout_ref.current = setTimeout(() => {
                  if (double_click_ref.current) {
                    return
                  }


                  return click_on_object(e, object_id)
                }, 0)

              }}
              onDoubleClick={(e) => {
                // console.log('double clic !!! ', on_double_click)
                if (on_double_click) {
                  double_click_ref.current = true
                  set_selected_objects({ [ object_id ]: true })
                  on_double_click()
                  setTimeout(() => {
                    double_click_ref.current = false
                  }, 400)
                }
              }}
  >
    <DraggableHandler object={object} scale={scale} controller_ref={controller_ref}
                      handle_deltas={on_move}

    >
      <div className="position-absolute handle global" style={{
        left: 0, top: 0, width: '100%', height: '100%',
      }}></div>
    </DraggableHandler>
  </div>
}
export const ArrowControl = ({ scale, controller_ref, keep_ratio, on_double_click, selected_objects: props_selected_objects, ...object }) => {
  return (
    <>
      <Control object={object} scale={scale} controller_ref={controller_ref}
               center={false}
               hide_selected={true}
               left={Math.min(object.props.end_x, object.props.start_x)}
               top={Math.min(object.props.end_y, object.props.start_y)}
               height={Math.max(10, Math.abs(object.props.start_y - object.props.end_y))}
               width={Math.max(10, Math.abs(object.props.start_x - object.props.end_x))} on_double_click={on_double_click}
               on_move={apply_move_delta_to_object}/>

      <Control object={object} scale={scale} controller_ref={controller_ref}
               left={object.props.start_x}
               top={object.props.start_y}
               height={arrow_control_size}
               width={arrow_control_size}
               center={true}
               on_double_click={on_double_click} on_move={({ delta_x, delta_y }, value_on_start) => {

        return ( {
          props: {
            ...value_on_start.props,
            start_x: value_on_start.props.start_x + delta_x,
            start_y: value_on_start.props.start_y + delta_y,
          },
        } )
      }}/>

      <Control object={object} scale={scale} controller_ref={controller_ref} left={object.props.end_x}
               top={object.props.end_y} height={arrow_control_size} width={arrow_control_size}
               center={true}
               on_double_click={on_double_click} on_move={({ delta_x, delta_y }, value_on_start) => ( {
        props: {
          ...value_on_start.props,
          end_x: value_on_start.props.end_x + delta_x,
          end_y: value_on_start.props.end_y + delta_y,
        },
      } )}/>
    </>
  )

}
