import { gql } from '@apollo/client'

export const GET_SNIPPETS = `
    query GetSnippets($company_id: ID!) {
        snippets(company_id: $company_id) {
            snippet_id
            code
            content_fr {
                content
            }
            content_en {
                content
            }
        }
    }
`

export const GET_SNIPPET = `
    query GetSnippets($company_id: ID!) {
        snippets(company_id: $company_id) {
            snippet_id
            code
            content_fr {
                content
            }
            content_en {
                content
            }
        }
    }
`

export const ADD_SNIPPET = gql`
    mutation AddSnippet($company_id: ID!, $code: String!) {
        addSnippet(company_id: $company_id, code: $code) {
            snippet_id
        }
    }
`


export const UPDATE_SNIPPET = gql`
    mutation UpdateSnippet($company_id: ID!, $snippet_id: ID!, $code: String!, $lang: String, $content: String) {
        updateSnippet(company_id: $company_id, snippet_id: $snippet_id, code: $code, lang:  $lang, content: $content) {
            snippet_id
        }
    }
`

export const DELETE_SNIPPET = gql`
    mutation DeleteSnippet($company_id: ID!, $snippet_id: ID!) {
        deleteSnippet(company_id: $company_id, snippet_id: $snippet_id)
    }
`


