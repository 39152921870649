import React from 'react'
import moment from 'moment'
import TextButton from '../../components/generic/text_button'
import { useTranslate } from '../../context/lang'

export const OrdersAutoUpdater = ({ update_orders }) => {
  const [last_verification, set_last_verification] = React.useState(Date.now())
  const [update_in_progress, set_update_in_progress] = React.useState(false)
  const [error, set_error] = React.useState(false)
  const translate = useTranslate()

  const update_order_periodically = React.useCallback(() => {
    const do_it = async () => {
      try {
        set_error(false)
        set_update_in_progress(true)
        await update_orders()
        set_last_verification(Date.now())
        set_update_in_progress(false)
      } catch (e) {
        console.log(e)
        set_error(true)
        set_update_in_progress(false)
      }
    }
    do_it().catch(e => console.log(e))
  }, [update_orders])

  React.useEffect(() => {

    const updater = setInterval(update_order_periodically, 5 * 60 * 1000)

    return () => {
      console.log('clean order notification checker!')
      clearInterval(updater)
    }
  }, [])

  if (update_in_progress) {
    return <div className="row small">
      <div className="col-12">
        {translate({
            fr: `Mise à jour en cours...`,
            en: `Update in progress`
        })}
      </div>
    </div>
  }

  return (
    <div className="row small">
      {error ? <div className="col-12">
        {translate({
            fr: `Erreur lors de la mise à jour des commandes. Recharger la page pour rééssayer.`,
            en: `There was an error while updating orders. Reload page to retry.`
        })}
      </div> : <>
        <div className="col-12 clickable" onClick={() => update_order_periodically()}>
          {translate({
              fr: `Mise à jour automatique`,
              en: `Automatic update`
          })}  {moment(last_verification).format('LL')}
        </div>
      </>}
    </div>
  )
}
