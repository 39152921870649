import React from 'react'
import { TiArrowBack, TiArrowForward } from 'react-icons/ti'
import { useHotkeys } from 'react-hotkeys-hook'
import { Tooltip } from 'react-tippy'

export default function History({ history }) {
  useHotkeys('ctrl+z', history.backward, [history])
  useHotkeys('ctrl+shift+z', history.forward, [history])
  useHotkeys('ctrl+y', history.forward, [history])

  return (
    <div className="btn-group">
      <Tooltip title={'CTRL+Z'} position={'top'} trigger={'mouseenter'}
               className={`btn btn-link ${history.can_go_backward ? '' : 'disabled'}`}
      >
        <div className="w-100" onClick={history.backward}>
          <TiArrowBack/>
        </div>
      </Tooltip>
      <Tooltip title={'CTRL+MAJ+Z<br/>CTRL+Y'} position={'top'} trigger={'mouseenter'}
               className={`btn btn-link ${history.can_go_forward ? '' : 'disabled'}`}>
        <div className="w-100" onClick={history.forward}>
          <TiArrowForward/>
        </div>
      </Tooltip>

    </div>
  )
}
