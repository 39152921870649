import React from 'react'
import Item from '../generic/item'

export default function BigBox({ title, wrap_in_item = true, children, is_active, actions, no_padding, item_style }) {
  return (
    <div className="row mb-5">
      <div className="col-12 mb-2">
        <div className="row">
          <div className="col">
            <h2 className="font-weight-bold">{title}</h2>
          </div>
          <div className="col-auto">
            {actions}
          </div>
        </div>
      </div>
      <div className="col-12">
        {children ? (
          wrap_in_item ? <Item active={is_active} no_padding={no_padding} style={item_style}>
            {children}
          </Item> : children
        ) : null}
      </div>
    </div>
  )
}
