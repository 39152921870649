import React from 'react'
import useMarketPlace, {
  amazon_regions, compute_region_id_for_market_place, MarketPlaceDetails,
  useRegion,
  useRegionForProduct,
  useSourceMarketPlace,
} from '../../hooks/useMarketPlace'
import { clean_for_label } from '../product/text-substitution'
import { AutomaticScaler, DomRenderer } from '../../components/visual/renderer/dom_renderer'
import { apply_overrides } from '../../components/visual/override/apply_overrides'
import TextButton from '../../components/generic/text_button'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { useMutation } from '@apollo/client'
import { ADD_SHARED_VISUAL, ADD_VISUAL, SWITCH_VISUALS, UPDATE_VISUAL } from '../../graphql/queries/visual'
import useCompanyId from '../../hooks/useCompanyId'
import { await_mutate } from '../../graphql/mutate_promise'
import { useProduct } from '../../context/products'
import InternalLink from '../../components/generic/internal-link'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useTranslate } from '../../context/lang'
import EditColorPalette from '../../components/visual/options/edit_color_palette'
import { designer_context_for_product, DesignerContext } from '../../components/visual/designer_context'
import CopyButton from '../../components/generic/copy-button'
import Button from '../../components/generic/button'
import IntellifoxModal from '../../components/generic/modal'
import { map_objects } from './edit_visual'
import Field from '../../components/generic/field'
import {
  AiOutlineExport,
  AiOutlineImport,
  FaAmazon,
  FaArrowRight,
  FaCheck,
  FaEdit,
  FiEdit,
  TiExport,
} from 'react-icons/all'
import NavigationButton from '../../components/generic/navigation-button'
import { useCachedContext } from '../../hooks/useCachedContext'
import Item, { CompactItem, ItemTitle } from '../../components/generic/item'
import Flag from '../../components/flag/flag'
import { Loader } from '../../components/generic/loader'
import { MdWarning } from 'react-icons/md'
import { DisplayVariations, ProductPresentation, VariationSeparator } from './product_header'


const ProductVisual = ({ product, parent_product, visual, is_variation, index, is_shared_with_parent, tags }) => {
  const objects = apply_overrides(visual.objects, visual.overrides)
  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)

  return <>
    <div className="row">
      <div className="col-12 clickable">
        <div className={'rounded shadow-sm'} style={{ overflow: 'hidden' }}>
          <InternalLink
            to={`/product/edit/${( parent_product || product ).product_id}/${market_place}/visual/${region_id}/${index}#?p=${product.product_id}`}>
            <AutomaticScaler visual={visual}>
              <DomRenderer {...visual} objects={objects}/>
            </AutomaticScaler>
          </InternalLink>
        </div>
      </div>
    </div>
  </>
}

const ImportAllVisual = ({ product, on_success }) => {

  const translate = useTranslate()
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)

  const [json_to_import, set_json_to_import] = React.useState('')

  const [updateVisual, save_mutation] = useMutation(UPDATE_VISUAL, {
    variables: {
      company_id,
      market_place: region_id,
    },
  })

  const save_visual_for_product = async () => {
    const imported_visuals = JSON.parse(json_to_import)

    await Promise.all(product.product_info.visuals.map((visual, i) => {
      await_mutate(updateVisual, {
        variables: {
          product_id: product.product_id,
          visual_id: visual.visual_id,
          visual: {
            width: visual.width,
            height: visual.height,
            objects: map_objects(imported_visuals[ i ]),
            overrides: [],
          },
        },
      })
    }))

    if (on_success) {
      await on_success()
    }
    await product.refresh()
  }

  return <IntellifoxModal title={translate({
    fr: `Importer des images`,
    en: `Import images`,
  })}
                          body={<>
                            <div className="row">
                              <Field type="text" value={json_to_import} setter={set_json_to_import} label={translate({
                                fr: 'Images à importer',
                                en: 'Visuals to import',
                              })}/>
                            </div>
                          </>}

                          on_click={() => save_visual_for_product()}
                          action_label={translate({
                            fr: 'Importer les images',
                            en: 'Import visuals',
                          })}
  >
    {translate({
      fr: `Importer tout`,
      en: `Import all`,
    })} <AiOutlineImport/>
  </IntellifoxModal>
}

const ProductVisuals = ({ region_id, market_place, product, parent_product, indices, show_switch = false }) => {
  const french = useFrench()
  const { sku, product_info, product_id } = product
  const { visuals } = product_info

  const company_id = useCompanyId()

  const designer_context = designer_context_for_product(product)


  const visual_map = visuals.reduce((visual_map, visual) => {
    visual_map[ visual.index ] = visual
    return visual_map
  }, {})

  const parent_visual_map = parent_product ? parent_product.product_info.visuals.reduce((visual_map, visual) => {
    visual_map[ visual.index ] = visual
    return visual_map
  }, {}) : {}

  const [switchVisuals] = useMutation(SWITCH_VISUALS)

  const [loading, set_loading] = React.useState(false)

  const do_the_switch = async (index_1, index_2) => {
    const product_ids = [
      product.product_id,
      ...product.variations.map((v) => v.product_id),
    ]
    set_loading(true)

    await Promise.all(product_ids.map(async (product_id) => {
      await await_mutate(switchVisuals, {
        variables: {
          company_id,
          market_place: region_id,
          product_id: product_id,
          index_1,
          index_2,
        },
      })
    }))
    set_loading(false)

    await product.refresh()
  }

  return <DesignerContext.Provider value={designer_context}>
    <div className="row align-items-end">
      {indices.map(({ index, label }, i) => {

        let visual = visual_map[ index ]
        let parent_visual = parent_visual_map[ index ]

        let is_shared_with_parent = false
        if (visual && parent_visual) {
          is_shared_with_parent = visual.visual_id === parent_visual.visual_id
        }

        // if (!visual) {
        //   visual = parent_visual
        // }

        return <div className="col-6 col-sm-4 col-md-3 col-lg-2" style={{ width: `100%` }} key={i}>
          <div className="row">
            <div className="col-12">
              <CompactItem label={label}>
                {visual ?
                  <ProductVisual index={index} product={product} parent_product={parent_product || product}
                                 visual={visual}
                                 is_shared_with_parent={is_shared_with_parent}
                                 is_variation={!!parent_product}
                  /> : null}
                <hr/>
                {show_switch ? <div className="row mt-1">
                  <div className="col-12">
                    <NavigationButton className="btn-sm btn-block"
                                      to={`/product/edit/${( parent_product || product ).product_id}/${market_place}/visual/${region_id}/${index}#?p=${product.product_id}`}><FiEdit/> {french(`Modifier`)}</NavigationButton>
                  </div>
                </div> : null}

                {show_switch ? <div className="row">
                  <div className="col-12">
              <span className="btn-group btn-group-sm btn-block">
                <Button className="btn-sm" onClick={async () => {
                  await do_the_switch(index, index - 1)
                }} disabled={loading || index === 0} outline={true}><FaChevronCircleLeft/></Button>
                <Button className="btn-sm" onClick={async () => {
                  await do_the_switch(index, index + 1)
                }} disabled={loading || (index === indices.length - 1)} outline={true}><FaChevronCircleRight/></Button>
              </span>
                  </div>
                </div> : null}
              </CompactItem>
            </div>
          </div>
        </div>
      })}
    </div>
  </DesignerContext.Provider>
}

export const EditVisuals = ({ product }) => {
  const current_region = useRegion()
  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)
  const region = amazon_regions.find((r) => r.region_id === region_id)
  const source_product = useProduct(product.product_id, region_id)
  const translate = useTranslate()
  const french = useFrench()

  const indices = [
    {
      index: 0, label: translate({
        fr: 'Principale',
        en: 'Main',
      }),
    },
    { index: 1, label: 'Image 2' },
    { index: 2, label: 'Image 3' },
    { index: 3, label: 'Image 4' },
    { index: 4, label: 'Image 5' },
    { index: 5, label: 'Image 6' },
    { index: 6, label: 'Image 7' },
    { index: 7, label: 'Image 8' },
    { index: 8, label: 'Image 9' },
  ]

  React.useEffect(() => {
    source_product.refresh()
  }, [])

  if (product.import_in_progress) {
    return <div className="row">
      <Loader size={200} label="Importation des images en cours"/>
    </div>
  }

  if (source_product.loading) {
    return <DefaultLoading/>
  }

  if (source_product.error) {
    return <DefaultError/>
  }


  return (
    <>
      <div className="row mb-4">
        <div className="col-12">
          <Item>
            <ProductPresentation product={product}/>
            <ProductVisuals product={source_product} indices={indices} show_switch={true} market_place={market_place}
                            region_id={region_id}/>
          </Item>
        </div>
        <div className="col-12">
          <DisplayVariations product={source_product} variation_mapper={(variation, parent_product) => {
            return <Item>
              <ProductPresentation product={variation} parent_product={product}/>
              <ProductVisuals product={variation} parent_product={parent_product}
                              indices={indices} market_place={region_id}/>
            </Item>
          }}/>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        <div className="col-12">
          <div className="alert alert-primary">
            <div className="row">
              <div className="col-12 font-weight-bold mb-2">
                <MdWarning/> {french(`Amazon utilise les mêmes images sur plusieurs pays.`)}
              </div>
              <div className="col-12 mb-2">
                {french(`En modifiant les images ici, tu modifies aussi les images pour les pays suivants :`)}
              </div>
              <div className="col-12 font-weight-bold">
                {region.market_places.map((market_place_details) => {
                  return <MarketPlaceDetails market_place={market_place_details.market_place}
                                             highlight={market_place_details.market_place === market_place}/>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-12">
          <ItemTitle>{french(`Gestion avancée des images`)}</ItemTitle>
        </div>
        <div className="col-auto">
          <EditColorPalette product={product}/>
        </div>
        <div className="col-auto">
          <CopyButton
            text_to_copy={JSON.stringify(source_product.product_info.visuals.map(({ objects }) => objects))}>{translate({
            fr: `Exporter toutes les images`,
            en: `Export all images`,
          })} <AiOutlineExport/></CopyButton>
        </div>
        <div className="col-auto">
          <ImportAllVisual product={source_product} on_success={async () => await source_product.refresh()}/>
        </div>

      </div>


    </>
  )
}
