import React from 'react'
import { useCachedContext } from '../../../../hooks/useCachedContext'
import { useDesignerContext } from '../../designer_context'
import Button from '../../../generic/button'
import { DefaultError } from '../../../i18n/translations'
import { useFrench } from '../../../../context/lang'

const categories = ['backgrounds', 'fashion', 'nature', 'science', 'education', 'feelings', 'health', 'people', 'religion', 'places', 'animals', 'industry', 'computer', 'food', 'sports', 'transportation', 'travel', 'buildings', 'business', 'music']

export const displayed_categories = ['fashion', 'nature', 'science', 'education', 'feelings', 'health', 'people', 'places', 'animals', 'industry', 'computer', 'food', 'sports', 'transportation', 'travel', 'buildings', 'business', 'music']
export const categories_label_fr = {
  'backgrounds': 'Arrière-plans',
  'fashion': 'Mode',
  'nature': 'Nature',
  'science': 'Science',
  'education': 'Éducation',
  'feelings': 'Sentiments',
  'health': 'Santé',
  'people': 'Personnes',
  'religion': 'Religion',
  'places': 'Lieux',
  'animals': 'Animaux',
  'industry': 'Industrie',
  'computer': 'Ordinateurs',
  'food': 'Nourriture',
  'sports': 'Sports',
  'transportation': 'Transports',
  'travel': 'Voyages',
  'buildings': 'Architecture',
  'business': 'Business',
  'music': 'Musique',
}

const PixabayResults = ({ query = '', lang = 'fr', category = '', page = 1, per_page = 20, colors = '', size = 140, on_click, can_load_more, square=false }) => {

  const french = useFrench()

  const [show_more, set_show_more] = React.useState(false)
  let initial_per_page = per_page
  if (per_page < 3) {
    per_page = 3
  }

  if (categories.indexOf(query) !== -1) {
    category = query
    query = ''

  }
  const results = useCachedContext('pixabay_images', query, lang, category, '' + page, '' + per_page, colors)

  if (results.loading) {
    const placeholders = []
    for (let i = 0; i < per_page; i++) {
      placeholders.push(i)
    }
    return <>
      {placeholders.map((i) => {
        return <div key={i} className={'d-inline-block mr-1 mb-1 loading'}
                    style={{ width: size, height: size, background: 'grey' }}>
        </div>
      })}
    </>
  }

  if (results.error) {
    return <DefaultError/>
  }

  if (results.length === 0) {
    return <div>Aucun résultat pour "{query}".</div>
  }

  return (
    <>
      {results.filter((_, i) => i < initial_per_page).map(({
                                                             pixabay_id,
                                                             previewURL,
                                                             previewWidth,
                                                             previewHeight,
                                                             imageWidth,
                                                             imageHeight,
                                                           }) => {
        const ratio = imageWidth / imageHeight

        return <div key={pixabay_id} className={'d-inline-block clickable'}
                    style={{ width: size, height: size, marginRight: 2, marginBottom: 2 }}>
          <img src={previewURL} alt="" style={{
            width: '100%',
            height: '100%',
            objectFit: square ? 'cover': 'contain',
          }}
               onClick={() => {
                 on_click({
                   width: 200 * ratio,
                   height: 200,
                   image_id: `pixabay:${pixabay_id}`,
                 })
                 // if (is_background) {
                 //   update_or_create_background({
                 //     type: 'image',
                 //     props: {
                 //       image_id: `pixabay:${pixabay_id}`,
                 //     },
                 //   })
                 // } else {
                 //   add_object({
                 //     type: 'image',
                 //     x: 100,
                 //     y: 100,
                 //     width: 200 * ratio,
                 //     height: 200,
                 //     angle: 0,
                 //     props: {
                 //       image_id: `pixabay:${pixabay_id}`,
                 //     },
                 //   })
                 // }
               }
               }/>
        </div>
      })}

      {can_load_more && !show_more ?
        <div className="text-center mt-5 mb-5"><Button button={'primary'}
                                                       onClick={() => set_show_more(true)}>{french(`Voir plus`)}</Button>
        </div> : null}

      {show_more ?
        <PixabayResults query={query} lang={lang} category={category} page={~~page + 1} per_page={per_page}
                        colors={colors}
                        size={size} on_click={on_click} can_load_more={true} square={square}/> : null}
    </>
  )
}

export default PixabayResults
