import React from 'react'
import { useMutation } from '@apollo/client'
import DeleteButton from '../../components/generic/delete-button'
import { DELETE_SNIPPET } from '../../graphql/queries/snippet'
import useCompanyId from '../../hooks/useCompanyId'
import { useCachedContext } from '../../hooks/useCachedContext'
import { useFrench, useTranslate } from '../../context/lang'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import { await_mutate } from '../../graphql/mutate_promise'

export const SnippetDeleteModal = ({ snippet_id, show_modal, close }) => {
  const company_id = useCompanyId()
  const snippets = useCachedContext('snippets')
  const french = useFrench()

  const [delete_snippet] = useMutation(DELETE_SNIPPET)
  const do_it = async () => {
    await await_mutate(delete_snippet, {
      variables: {
        company_id, snippet_id,
      },
    })
    await snippets.refresh()
  }
  return <IntellifoxModalComponent show_modal={show_modal} close={close}
                                   title={french(`Supprimer la variable`)}
                                   action_label={french(`Supprimer la variable`)}
                                   on_click={do_it}

  />
}
