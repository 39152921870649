import { gql } from '@apollo/client'


export const subscription_details_query = `
query subscription_details($company_id: ID!) {
  subscription_details(company_id: $company_id) {
    subscription_id
    plan_id
    update_url
    cancel_url
    state
    last_payment {
      amount
      currency
      date
    }
    next_payment {
      amount
      currency
      date
    }
    payment_information {
      payment_method
      card_type
      last_four_digits
      expiry_date
    }
  } 
}
`



export const cancel_subscription_query = gql`
    mutation cancel_subscription(
      $company_id: ID!,
      $reason: String,
    ) {
        cancel_subscription(
            company_id:$company_id,
            reason:$reason,
        )
    }
`
