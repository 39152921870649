import { get_client } from '../simple-client'
import { GET_PRODUCT_STR } from '../queries/product'
import { parse_json } from '../parse_json'

export const parse_product = (product) => {
  return {
    ...product,
    product_info: {
      ...product.product_info,
      sale_from_date: product.product_info.sale_from_date ? new Date(product.product_info.sale_from_date) : product.product_info.sale_from_date,
      price: parse_json(product.product_info.price, null),
      visuals: product.product_info.visuals ? product.product_info.visuals.map((visual) => {
        return {
          ...visual,
          objects: visual.objects ? visual.objects.map((object) => {
            return {
              ...object,
              props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
            }
          }) : [],
          overrides: visual.overrides ? visual.overrides.map((object) => {
            return {
              ...object,
              props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
            }
          }) : [],
        }
      }) : [],
    },
    variations: product.variations ? product.variations.map((variation) => parse_product(variation)) : [],
  }
}

export const fetch_product = async ({ company_id, product_id, market_place }) => {
  if (!product_id) {
    return {}
  }
  const client = get_client()
  const response = await client.request(GET_PRODUCT_STR, {
    company_id, market_place, product_id,
  })
  try {
    const returned_product = parse_product(response.product)
    return returned_product
  } catch (e) {
  }
}
