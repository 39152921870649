import React from 'react'
import IntellifoxModal, { IntellifoxModalComponent } from '../../components/generic/modal'
import { AiOutlineEnter, FaHatWizard, FaSearch, MdContentCopy } from 'react-icons/all'
import Button from '../../components/generic/button'
import { useCachedContext } from '../../hooks/useCachedContext'
import useMarketPlace from '../../hooks/useMarketPlace'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import BigBox from '../../components/layout/big_box'
import Item from '../../components/generic/item'
import { ProductFirstVisual } from './index'
import { clean_for_label, compute_variables } from '../product/text-substitution'
import Field from '../../components/generic/field'
import { fetch_product } from '../../graphql/fetcher/product'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { await_mutate } from '../../graphql/mutate_promise'
import { SET_SEARCH_TERMS } from '../../graphql/queries/search_term'
import { SELECT_KEYWORDS } from '../../graphql/queries/keyword'
import { MdTextFields } from 'react-icons/md'
import { useFrench, useTranslate } from '../../context/lang'
import { IoMdImages } from 'react-icons/io'


export const Pagination = ({ label, page, set_page, max_page }) => {

  max_page = Math.floor(max_page)

  const pages = []
  for (let i = 0; i <= max_page; i++) {
    pages.push(i)
  }
  const has_previous_page = page > 0
  const has_next_page = page < max_page

  if (!has_previous_page && !has_next_page) {
    return null
  }

  return <nav aria-label={label}>
    <ul className="pagination justify-content-end">
      <li className={`page-item ${has_previous_page ? '' : 'disabled'}`}><a className="page-link" href="#" onClick={() => set_page(page-1)}>Previous</a>
      </li>
      {pages.map((p) => {
        return <li className={`page-item ${p === page?'active':''}`}><a className="page-link" href="#" onClick={() => set_page(p)}>{p + 1}</a></li>
      })}
      <li className={`page-item ${has_next_page ? '' : 'disabled'}`}><a className="page-link" href="#" onClick={() => set_page(page+1)}>Next</a></li>
    </ul>
  </nav>
}

export const ProductSelector = ({ select, selected_product_id, current_product_ids, search }) => {
  const market_place = useMarketPlace()
  const page_size = 20
  const [page, set_page] = React.useState(0)
  const products = useCachedContext('minimal_products', market_place, page, page_size, 'active')
  const search_products = useCachedContext('search_products', market_place, search, page, page_size)
  const french = useFrench()

  const snippets = useCachedContext('snippets')

  React.useEffect(() => {
    set_page(0)
  }, [search])


  if (products.loading || search_products.loading || snippets.loading) {
    return <DefaultLoading/>
  }

  if (products.error || search_products.error || snippets.error) {
    return <DefaultError/>
  }


  const is_search_activated = !!search
  const products_to_display = is_search_activated ? search_products.products : products

  return <div className="row">
    {is_search_activated ? <div className="col-12 mb-2 font-weight-bold">
      {search_products.count} {french('résultat(s)')}
    </div> : null}
    <div className="col-12">
      <Pagination page={page} set_page={set_page} max_page={products_to_display.length / page_size}/>
    </div>
    <div className="col-12">
      {products_to_display.map((p) => {
        if (current_product_ids.indexOf(p.product_id) !== -1) {
          return null
        }

        if (selected_product_id && p.product_id !== selected_product_id) {
          return null
        }
        return <Item key={p.product_id} className={'mb-2'}
                     active={selected_product_id === p.product_id}>
          <div className="row align-items-center">
            <div className="col-auto">
              <ProductFirstVisual product={p} width={80}/>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-12 font-weight-bold mb-1">
                  {clean_for_label(p.product_info.title || '', 100, compute_variables(p, snippets))}
                </div>
                <div className="col-12">
                  <span className="badge badge-secondary mr-2">{french(`SKU :`)} {p.sku}</span>
                  <span className="badge badge-secondary">{french(`ASIN :`)} {p.asin}</span>
                </div>
              </div>
            </div>
            <div className="col-auto">
              <Button onClick={() => select(p.product_id)}>{french(`Sélectionner`)}</Button>
            </div>
          </div>
        </Item>
      })}
    </div>
    <div className="col-12">
      <Pagination page={page} set_page={set_page} max_page={products_to_display.length / page_size}/>
    </div>
  </div>
}


export const SelectProduct = ({ current_product_ids = [], selected_product, set_selected_product }) => {
  const french = useFrench()

  const [search, set_search] = React.useState('')
  const [current_search, set_current_search] = React.useState('')

  return <div className="row">
    <div className="col-12">
      <BigBox>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col">
                <div className="row">
                  <Field type={'text'} value={search} setter={set_search}
                         placeholder={french('Rechercher avec ASIN ou SKU')}
                         on_enter={() => set_current_search(search)}/>
                </div>
              </div>
              <div className="col-auto text-right">
                <Button onClick={() => set_current_search(search)} className={`mb-1`}><FaSearch/></Button><br/>
                <span
                  className={`small ${search !== current_search ? 'font-weight-bold' : ''}`}>{french(`Entrée`)}
                  <AiOutlineEnter/></span>
              </div>
            </div>
          </div>
          <div className="col-12 mb-2">
            <ProductSelector
              select={(product_id) => set_selected_product(selected_product === product_id ? null : product_id)}
              selected_product_id={selected_product} current_product_ids={current_product_ids}
              search={current_search}/>
          </div>
        </div>
      </BigBox>
    </div>

  </div>
}
const LaunchUseProductAsTemplate = ({ product, selected_product_id, destroy }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const product_id = product.product_id
  const french = useFrench()

  const [update_product] = useMutation(UPDATE_PRODUCT)
  const [set_search_terms] = useMutation(SET_SEARCH_TERMS)
  const [select_keywords] = useMutation(SELECT_KEYWORDS)

  const steps = [
    {
      label: french('1. Récupération des données du modèle sélectionné'),
      action: async () => {
        const selected_product = await fetch_product({
          company_id,
          product_id: selected_product_id,
          market_place,
        })
        console.log('Step 1', selected_product)
        return selected_product
      },
    },
    {
      label: french('2. Mise à jour du titre, des points forts et de la description'),
      action: async ([selected_product]) => {
        await await_mutate(update_product, {
          variables: {
            company_id,
            product_id,
            market_place,

            title: selected_product.product_info.title,
            key_points: selected_product.product_info.key_points,
            description: selected_product.product_info.description,
          },
        })
      },
    },
    {
      label: french('3. Mise à jour de la requête cible'),
      action: async ([selected_product]) => {
        await await_mutate(set_search_terms, {
          variables: {
            company_id,
            product_id,
            market_place,

            search_terms: selected_product.product_info.search_terms,
          },
        })
      },
    },
    {
      label: french('4. Mise à jour des mots-clés sélectionnés'),
      action: async ([selected_product]) => {
        await await_mutate(select_keywords, {
          variables: {
            company_id,
            product_id,
            market_place,

            keywords: selected_product.product_info.selected_keywords,
          },
        })
      },
    },
    {
      label: french('5. Rafraichissement du produit'),
      action: async () => {
        await product.refresh()
      },
    },
  ]

  return <LaunchSteps steps={steps} destroy={destroy}/>
}


export const LaunchSteps = ({ steps, destroy }) => {
  const [show, set_show] = React.useState(true)
  const translate = useTranslate()

  const [current_step, set_current_step] = React.useState(0)

  const [error, set_error] = React.useState(false)

  React.useEffect(() => {
    const go = async () => {
      set_error(false)
      const returned_values = []
      try {
        for (let i = 0; i < steps.length; i++) {
          set_current_step(i)
          const returned_value = await steps[ i ].action(returned_values)
          returned_values.push(returned_value)
        }
        set_current_step(steps.length + 1)
      } catch (e) {
        console.log(e)
        set_error(true)
      }
    }

    go().catch(e => console.error(e))
  }, [])

  const is_done = current_step > steps.length

  return <IntellifoxModalComponent
    show_modal={show}
    close={() => {
      set_show(false)
      destroy()
    }}
    title={translate({
      fr: error ? 'Erreur' : ( is_done ? 'Terminé' : 'Travail en cours...' ),
      en: error ? 'Error' : ( is_done ? 'Done' : 'Work in progress...' ),
    })}
    body={
      <>
        <div className="row">
          <div className="col-12">
            {steps.map(({ label }, i) => {
              const is_done = i < current_step
              const is_in_progress = current_step === i

              let className = ''
              if (is_done) {
                className = 'font-weight-bold'
              } else if (is_in_progress) {
                className = 'font-weight-bold font-italic'
              }

              if (is_in_progress && error) {
                return <div className="row">
                  <div className="col-auto font-weight-bold">
                    {label}
                  </div>
                  <div className="col">
                    <span className="badge badge-danger">Erreur</span>
                  </div>
                </div>
              }

              return <div className="row ">
                <div className={`col-12 ${className}`}>
                  {label}{is_in_progress ? '...' : ''}
                </div>
              </div>
            })}
          </div>
          {current_step > steps.length ? <div className="col-12 mt-3">
            <div className="alert alert-success font-weight-bold">
              {translate({
                fr: `L'opération s'est bien passée`,
                en: `Everything went well`,
              })}
            </div>
          </div> : null}
        </div>
      </>
    }
  />
}


export const ConfirmLaunch = ({ warning, confirmation_label, children, destroy }) => {

  const [show, set_show] = React.useState(true)
  const [confirm, set_confirm] = React.useState(false)
  return <>
    <IntellifoxModalComponent
      show_modal={show}
      body={<>
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger font-weight-bold">
              {warning}
            </div>
          </div>
        </div>
      </>}
      action_label={confirmation_label}
      on_click={() => {
        set_show(false)
        set_confirm(true)
      }}
      close={() => {
        destroy()
      }}
    />

    {confirm ? children : null}
  </>

}

export default function MagicButton({ product, show_modal, close }) {
  const market_place = useMarketPlace()

  const translate = useTranslate()
  const french = useFrench()

  const [choice, set_choice] = React.useState('none')
  const [selected_product, set_selected_product] = React.useState(null)

  const [launch_texts, set_launch_texts] = React.useState(false)

  let modal_options = {}

  if (choice === 'texts') {
    modal_options = {
      action_label: translate({
        fr: 'Utiliser le produit sélectionné comme modèle',
        en: 'Use selected product as model',
      }),
      disable_action: !selected_product,
      on_click: async () => {
        // For current marketplace
        // 1. Get all data for products for the other product
        // 2. Update current product with : title, key_points, description, search term, keywords
        set_launch_texts(true)
      },
    }
  }
  if (choice === 'images') {
    modal_options = {
      action_label: translate({
        fr: 'Utiliser le produit sélectionné comme modèle',
        en: 'Use selected product as model',
      }),
      disable_action: !selected_product,
      on_click: async () => {

      },
    }
  }

  return (
    <>
      <IntellifoxModalComponent show_modal={show_modal} close={close}
                                title={<><MdContentCopy/> {french(`Importer depuis un autre produit`)}</>}
                                body={<>
                                  <div className="row">
                                    <div className="col-12">
                                      {french(`Quel opération souhaites-tu réaliser ?`)}
                                    </div>

                                    <div className="col-12">
                                      <Button button={choice === 'texts' ? 'primary' : 'secondary'}
                                              onClick={() => set_choice(choice === 'texts' ? 'none' : 'texts')}
                                              className="mr-2 mb-2"><MdTextFields/> {french(`Utiliser un autre produit comme modèle pour
                               les textes et les
                               mots-clés`)}</Button>
                                      {/*<Button button={choice === 'images' ? 'primary' : 'secondary'}*/}
                                      {/*        onClick={() => set_choice(choice === 'images' ? 'none' : 'images')}*/}
                                      {/*        className="mr-2 mb-2"><IoMdImages/> {french(`Utiliser un autre produit comme modèle pour*/}
                                      {/*  les images`)}</Button>*/}
                                    </div>


                                    {choice === 'texts' ? <div className="col-12">
                                      <SelectProduct current_product_ids={[product.product_id]}
                                                     selected_product={selected_product}
                                                     set_selected_product={set_selected_product}/>
                                    </div> : null}
                                    {choice === 'images' ? <div className="col-12">
                                      <SelectProduct current_product_ids={[product.product_id]}
                                                     selected_product={selected_product}
                                                     set_selected_product={set_selected_product}/>
                                    </div> : null}
                                  </div>
                                </>}
                                {...modal_options}
      />
      {launch_texts ? <ConfirmLaunch
        destroy={() => set_launch_texts(false)}
        confirmation_label={french(`J'ai bien compris que j'allais écraser les textes du produit sur lequel je me trouve`)}
        warning={french(`Attention ! Cela va ÉCRASER les textes du produit ACTUEL avec les textes du PRODUIT SÉLECTIONNÉ.`)}>
        <LaunchUseProductAsTemplate product={product} selected_product_id={selected_product}
                                    destroy={() => set_launch_texts(false)}/>
      </ConfirmLaunch> : null}
    </>
  )
}
