import React from 'react'
import { ColorOptionSelector } from '../options/select_color'
import SingleObjectOptions from '../options/single_object_options'
import { compute_rect_style } from '../controller/rect_controls'
import { useDesignerContext } from '../designer_context'
import TextButton from '../../generic/text_button'
import * as _ from 'lodash'
import './text.css'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'
import { apply_variables, compute_variables } from '../../../routes/product/text-substitution'
import emoji_regex from 'emoji-regex'

const regex = emoji_regex()

const apply_emoji_font = (text) => {
  if (!text) {
    return text
  }

  const emojis = text.match(regex)

  if (!emojis) {
    return text
  }

  for (let i = 0; i < emojis.length; i++) {
    const emoji = emojis[ i ]
    text = text.replace(emoji, `<span class="emoji">${emoji}</span>`)

  }
  return text
}

export default function Text({ object_id, onLoad, ...props }) {
  React.useEffect(() => {
    onLoad && onLoad()
  }, [])

  const { compute_color, current_product_id, product } = useDesignerContext()

  const container_style = {
    ...compute_rect_style(props),
    display: 'flex',
    alignItems: props.props.verticalAlign || 'center', // baseline / center / end
    justifyContent: props.props.textAlign || 'center',
    background: compute_color ? compute_color(props.props.background) : props.props.background,
  }

  let alignSelf = props.props.verticalAlign || 'center'
  if (alignSelf === 'end') {
    alignSelf = 'flex-end'
  }

  const content_style = {
    color: compute_color ? compute_color(props.props.fill) : props.props.fill,
    fontSize: props.props.fontSize,
    fontWeight: props.props.fontWeight,
    fontFamily: props.props.fontFamily,
    textAlign: props.props.textAlign,
    lineHeight: props.props.lineHeight + 'px',
    width: '100%',
    alignSelf,
  }

  let content = props.props.content

  content = apply_emoji_font(content)

  if (product) {
    let current_product = product
    if (product.product_id !== current_product_id && product.variations) {
      for (let i = 0; i < product.variations.length; i++) {
        const variation = product.variations[i]

        if (variation.product_id === current_product_id) {
          current_product = variation
        }
      }
    }

    const variables = compute_variables(current_product, [])

    content = apply_variables(content, variables)
  }

  let repeated_text = null
  if (props.props.filters && props.props.filters.repeat > 0) {
    const repeat_value = ~~props.props.filters.repeat_value
    const repeat_x = ~~props.props.filters.repeat_x || -2
    const repeat_y = ~~props.props.filters.repeat_y || -2
    const texts = _.times(repeat_value)
    repeated_text = texts.map((_, i) => <div className="position-absolute dom_renderer_text" key={i} style={{
      ...container_style,
      opacity: 1 - ( i + 1 ) / ( repeat_value + 1 ),
      left: container_style.left + repeat_x * ( i + 1 ),
      top: container_style.top + repeat_y * ( i + 1 ),
    }}>
      <div className={'content'} style={content_style}
           dangerouslySetInnerHTML={{ __html: content }}></div>
    </div>)
  }

  return (
    <>
      {repeated_text}
      <div className="position-absolute dom_renderer_text" id={object_id} style={container_style}>
        <div className={'content'} style={content_style}
             dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </>
  )
}

const SelectOption = ({ option, children }) => {
  const { current_option, set_current_option } = useDesignerContext()
  const is_current = current_option === option

  return <TextButton onClick={() => set_current_option(is_current ? null : option)}>{children}</TextButton>
}

export const TextOptions = ({ scale, ...object }) => {
  const { set_current_option, compute_color } = useDesignerContext()
  const translate = useTranslate()

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 justify-content-between">
      <div className="col-auto ">
        <div className="row align-items-center no-gutters">
          <div className="col-auto" style={{ height: 32 }}>
            <ColorOptionSelector color={compute_color(object.props.fill)} option={'props.fill'}/>
          </div>
          <OptionSeparator/>
          <div className="col-auto" style={{ height: 32 }}>
            <ColorOptionSelector color={compute_color(object.props.background)} option={'props.background'}/>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <OptionSelector option={'props.text'}>{translate({
              fr: `Modifier le texte`,
              en: `Edit text`,
            })}</OptionSelector>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <OptionSelector option={'props.filters'}>{translate({
              fr: `Filtres`,
              en: `Filters`,
            })}</OptionSelector>
          </div>
        </div>
      </div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>


  </div>
}
