import { create_mutation } from './_query_and_mutations_templates'

export const refresh_amazon_ads_account = create_mutation({
  mutation: 'refresh_amazon_ads_account',
  params: {
    company_id: 'ID!',
    region_id: 'String',
    amazon_ads_account_id: 'String',
  },
})
