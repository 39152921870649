import React from 'react'
import Item from '../../components/generic/item'
import Button from '../../components/generic/button'
import useLocalStorage from '../../hooks/useLocalStorage'
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'
import UserContext from '../../context/user'
import IntellifoxIcon from '../../images/intellifox-icon'

import mastermind from '../../images/intellifox_mastermind.png'

import './onboarding.css'
import { useCachedContext } from '../../hooks/useCachedContext'
import MastermindCreateName from '../../mastermind/mastermind_create_name'
import { GiMineExplosion } from 'react-icons/gi'
import TextButton from '../../components/generic/text_button'
import { MdClose, MdHelp, MdQuestionAnswer } from 'react-icons/md'
import { DisplayUserFirstName } from '../../components/user/display_name'
import ReferenceMarketPlaceChooser from './ReferenceMarketPlaceChooser'
import { useFrench, useTranslate } from '../../context/lang'

import strategy from '../../images/story/strategy.svg'
import new_product from '../../images/story/new_product.svg'
import { FaArrowRight, FaRegCheckSquare, FaRegSquare } from 'react-icons/all'
import { AmazonAccounts } from '../setting/amazon_accounts'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Conrad from '../../components/generic/conrad'
import ImportProducts from '../../components/import/import'

export const ShowProgress = ({ progress, goal, next_step }) => {
  const translate = useTranslate()

  const is_reached = progress >= goal

  return <div className="position-fixed rounded-top shadow text-center" style={{
    bottom: 0,
    background: is_reached ? 'green' : 'var(--color-white)',
    color: is_reached ? 'white' : 'var(--color-black)',
    border: '1px solid grey',
    paddingBottom: 20,
    paddingTop: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '100vw',
  }}>
    <div className="container">
      <div className="row">
        <div className="col-12 mb-2">
          <h4>{is_reached ? <FaRegCheckSquare/> : <FaRegSquare/>} <span
            className="ml-2"><b>{translate({
            fr: `Objectif`,
            en: `Goal`,
          })}</b> : {progress > goal ? `${goal}+` : progress} / {goal}</span></h4>
        </div>
        <div className="col-12">
          {is_reached ? <Button onClick={next_step}>
            {translate({
              fr: `Étape suivante`,
              en: `Next step`,
            })} <FaArrowRight/>
          </Button> : <Button onClick={next_step} outline={true}>
            {translate({
              fr: `Passer cette étape`,
              en: `Skip this step`,
            })} <FaArrowRight/>
          </Button>
          }
        </div>
      </div>
    </div>
  </div>
}

export const TutorialStep = ({
                               step,
                               children,
                               title,
                               current_step,
                               set_current_step,
                               next_step,
                               on_next_step = () => 0,
                               previous_step,
                               progress_label,
                               progress,
                               goal,
                               show_progress,
                             }) => {
  const [is_new, set_is_new] = React.useState(true)
  const is_current = step === current_step
  const is_previous = step < current_step
  const is_next = step > current_step
  const distance = Math.abs(step - current_step)
  let transform = ''
  if (is_previous) {
    transform = `translateZ(0) translateX(-${distance * 80}%) rotateY(${distance * 35}deg)`
  } else if (is_next) {
    transform = `translateZ(0) translateX(${distance * 80}%) rotateY(-${distance * 35}deg)`
  }

  React.useEffect(() => {
    setTimeout(() => set_is_new(false), 1)
  }, [])
  return <div className={`row position-absolute ${current_step ? 'current-step' : ''}`}
              style={{
                transition: 'opacity 1s, transform 1s',
                transform,
                top: 0,
                width: '100%',
                height: '100%',
                overflow: is_current ? 'auto' : 'hidden',
                opacity: is_new ? 0 : 1 - distance * 0.5,
                zIndex: 40 - distance,
              }}>


    <div className={`d-none d-md-block col-md-1 col-xl-2 ${previous_step ? 'clickable' : ''}`}
         onClick={() => previous_step ? set_current_step(previous_step.index) : null}>
    </div>
    <div className="col-12 col-md-10 col-xl-8">
      <div className="row">
        <div className="container">

          <div className="row">
            <div className="col-12 text-center pt-5" style={{ color: 'var(--color-black)' }}>
              <h1>{title}</h1>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col">
              {previous_step ? <Button onClick={() => set_current_step(previous_step.index)}
                                       button={'link'}>
                <FaLongArrowAltLeft/> {previous_step.title}
              </Button> : null}
            </div>
            {progress_label ? <div className="col-auto font-weight-bold mb-1">
              {progress_label}
            </div> : null}
          </div>
          <Item className=" mb-3 position-relative">
            <div className="row p-0 p-md-3 onboarding_step m-2 align-content-start">
              {children}
            </div>
          </Item>
          <div className="row">
            <div className="col-12 text-right pb-5">
              {next_step ? <Button onClick={(e) => {
                const delay = on_next_step(e)
                setTimeout(() => set_current_step(next_step.index), delay)
              }}
                                   button={'link'}>
                {next_step.title} <FaLongArrowAltRight/>
              </Button> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={`d-none d-md-block col-md-1 col-lg-2 ${next_step ? 'clickable' : ''}`}
         onClick={(e) => {
           if (next_step) {
             const delay = on_next_step(e)
             setTimeout(() => set_current_step(next_step.index), delay)
           }
         }}>
    </div>

    {show_progress ?
      <ShowProgress progress={progress} goal={goal} next_step={(e) => {
        const delay = on_next_step(e)
        setTimeout(() => set_current_step(next_step ? next_step.index : null), delay)
      }}/> : null}
  </div>
}

export default function Onboarding({ close, current_step, set_current_step, on_finish, spapi_oauth_code }) {
  const user = React.useContext(UserContext)
  const import_processes = useCachedContext('processes', 'import_products')

  const amazon_accounts = useCachedContext('amazon_accounts')

  const translate = useTranslate()
  const french = useFrench()

  const [selected_option_step_2, set_selected_option_step_2] = useLocalStorage(`onboarding_selected_option_step_2_${user.user.user_id}`, '')

  const steps = [
    {
      index: 1, title: translate({
        fr: `C'est parti !`,
        en: `Let's go!`,
      }),
    },
    {
      index: 2, title: translate({
        fr: `Importer les produits`,
        en: `Import your products`,
      }),
    },
  ]

  if (amazon_accounts.loading || import_processes.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error || import_processes.loading) {
    return <DefaultError/>
  }


  const no_amazon_account = selected_option_step_2 === 'no_amazon_account'
  const has_amazon_account = selected_option_step_2 === 'has_amazon_account'
  const has_launched_import = import_processes && import_processes.length > 0

  if (no_amazon_account || has_amazon_account) {
    steps.push({
      index: 3, title: translate({
        fr: `Bienvenue`,
        en: `Welcome`,
      }),
    })
  }

  const step = (step) => steps.find(({ index }) => index === step)


  return (
    <div className="position-fixed w-100 h-100"
         style={{
           left: 0,
           top: 0,
           background: 'var(--color-bg-global)',
           zIndex: 20000,
           overflowX: 'hidden',
           overflowY: 'hidden',
           cursor: 'default',
         }}>

      <div className="w-100 h-100 container-fluid" style={{ perspective: '1000px', perspectiveOrigin: '50% 50%' }}>
        <TutorialStep step={1} current_step={current_step > steps.length ? 1 : current_step} set_current_step={set_current_step}
                      next_step={step(2)}
                      title={translate({
                        fr: 'Quelle est ta marketplace de référence ?',
                        en: <>What is your main marketplace?</>,
                      })}>

          <div className="row">
            <div className="col-12">
              <div className="alert alert-success">
                <div className="row">
                  <div className="col-12 font-weight-bold">
                    <MdHelp/> {translate({
                    fr: `Sélectionne ta marketplace principale où tu es à l'aise avec la langue`,
                    en: `Select your main marketplace, where you're comfortable with the language`,
                  })}
                  </div>
                  <div className="col-12">
                    {translate({
                      fr: `C'est cette marketplace qui sera utilisée par défaut dans l'application pour l'affichage et la navigation.`,
                      en: `This defines which marketplace will be used by default for display and navigation`,
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <ReferenceMarketPlaceChooser/>
            </div>

            <div className="col-12 text-right">
              <Button onClick={(e) => {
                set_current_step(2)
              }}>                              {translate({
                fr: `Passer à l'étape suivante`,
                en: `Go to the next step`,
              })} <FaArrowRight/></Button>
            </div>
          </div>

        </TutorialStep>
        <TutorialStep step={2} current_step={current_step} set_current_step={set_current_step}
                      previous_step={step(1)}
                      next_step={( has_amazon_account && has_launched_import ) || no_amazon_account ? step(3) : null}
                      title={translate({
                        fr: `As-tu un compte vendeur sur Amazon ?`,
                        en: `Do you have an Amazon seller account?`,
                      })}>

          <div className="col-12  mb-5">
            <Item>
              <div className="btn-group w-100">
                <Button outline={selected_option_step_2 !== 'has_amazon_account'} className="w-50"
                        onClick={() => {
                          set_selected_option_step_2(has_amazon_account ? '' : 'has_amazon_account')
                          // setTimeout(() => set_current_step(3), 500)
                        }}>
                  <>{translate({
                    fr: `Oui`,
                    en: `Yes`,
                  })}</>
                </Button>
                <Button outline={selected_option_step_2 !== 'no_amazon_account'} className="w-50"
                        onClick={() => {
                          set_selected_option_step_2(no_amazon_account ? '' : 'no_amazon_account')
                          // setTimeout(() => set_current_step(3), 500)
                        }}>
                  <>{translate({
                    fr: `Non`,
                    en: `No`,
                  })}</>
                </Button>
              </div>
            </Item>
          </div>

          {has_amazon_account ? <>
              <div className="col-12 mb-5">
                {translate({
                  fr: `Parfait ! Grâce à la connexion au compte vendeur Amazon, tu vas pouvoir gagner un temps considérable sur
                les optimisations de tes produits. Cela commence par l'import automatique de tous les produits actuels.`,
                  en: `Perfect! By connecting your Amazon seller account, you'll save a bunch of time for your listing optimization. It will start with the automatic import of all you current products.`,
                })}
              </div>

              <div className="col-12 mb-5">
                <div className="row">
                  <div className="col-12 font-weight-bold mb-2">
                    <span className="step-number mr-1">1</span>{translate({
                    fr: `Connecte ton compte Amazon à Intellifox`,
                    en: `Connect your Amazon account to Intellifox`,
                  })}
                  </div>
                  <div className="col-12">
                    <Item active={amazon_accounts.length > 0}>
                      <AmazonAccounts current_path={'welcome'} hide_details={true}/>
                    </Item>
                  </div>
                </div>
              </div>


              {amazon_accounts.length > 0 ? <>

                <div className="col-12 mb-3 pb-5">
                  <div className="row">
                    <div className="col-12 font-weight-bold mb-2">
                      <span className="step-number mr-1">2</span>{translate({
                      fr: `Lancer l'import des produits`,
                      en: `Launch products import`,
                    })}
                    </div>
                    <div className="col-12">
                      <Item className="w-100 mb-3" active={has_launched_import}>
                        {!has_launched_import ? <ImportProducts/> : <div className="row">
                          <div className="col-12">
                            <ImportProducts show_only_if_in_progress={true} className={'mb-3'}/>
                          </div>
                        </div>}
                      </Item>
                    </div>


                    {has_launched_import ? <>
                      <div className="col-12 text-right">
                        <Button onClick={() => set_current_step(current_step + 1)}>
                          {translate({
                            fr: `Passer à l'étape suivante`,
                            en: `Go to the next step`,
                          })} <FaArrowRight/>
                        </Button>
                      </div>
                      <div className="col-12 text-right font-italic">
                        {french(`Tu peux passer à l'étape suivante même si la synchronisation n'est pas terminée.`)}
                      </div>
                    </> : null}
                  </div>
                </div>
              </> : null}

            </>
            : null}

          {no_amazon_account ? <>
            <div className="col-12 mb-3">
              {translate({
                fr: `Pas de souci ! Sans compte vendeur Amazon, tu peux déjà commencer à travailler sur tes produits.
              Le jour où tu connecteras ton compte Amazon, tu pourras très rapidement publier
              tes produits et bénéficier de l'ensemble des avantages d'Intellifox.`,
                en: `No problem! Without an Amazon seller account, you can already start working on your products. As soon as you connect your Amazon account, you will be able to quickly publish your work and enjoy the "publish" feature from Intellifox.`,
              })}
            </div>
            <div className="col-12 mb-3">
              <Item>
                <Button className={'btn-block'} onClick={() => set_current_step(current_step + 1)}>
                  {translate({
                    fr: `Continuer sans compte Amazon`,
                    en: `Continue without Amazon seller account`,
                  })}
                </Button>
              </Item>
            </div>
          </> : null}
        </TutorialStep>


        {/*{has_amazon_account || no_amazon_account ? <TutorialStep step={3}*/}
        {/*                                                         current_step={current_step}*/}
        {/*                                                         set_current_step={set_current_step}*/}
        {/*                                                         previous_step={step()}*/}
        {/*                                                         next_step={user.user.mastermind_name ? step(5) : null}*/}
        {/*                                                         title={<><img src={mastermind}*/}
        {/*                                                                       className="w-50"/></>}>*/}
        {/*  <div className="col-12 mb-3">*/}

        {/*    {french(`Rejoindre Intellifox, c'est aussi intégrer une communauté d'entrepreneurs du e-commerce.*/}
        {/*        Tu vas rejoindre le mastermind Intellifox, le groupe privé réservé aux membres d'Intellifox.*/}
        {/*        Grâce à ce mastermind, tu vas pouvoir :`)}*/}
        {/*    <ul>*/}
        {/*      <li>{french(`Échanger avec des entrepreneurs qui réussissent sur Amazon`)}</li>*/}
        {/*      <li>{french(`Trouver de l'aide pour tes problèmes`)}</li>*/}
        {/*      <li>{french(`Suggérer des nouvelles fonctionnalités`)}</li>*/}
        {/*      <li>{french(`Voir les futures fonctionnalités sur la roadmap`)}</li>*/}
        {/*      <li>{french(`Obtenir de l'aide sur l'utilisation d'Intellifox`)}</li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*  <div className="col-12 mb-3">*/}
        {/*    {translate({*/}
        {/*      fr: `Ce mastermind est privé et uniquement ouvert aux membres confirmés d'Intellifox. Il est interdit de*/}
        {/*    partager*/}
        {/*    le contenu du mastermind à l'extérieur. C'est un espace d'entraide et de bienveillance.`,*/}
        {/*      en: `This Mastermind is private and reserved for the validated Intellifox users. It is forbidden to share its content anywhere.`,*/}
        {/*    })}*/}
        {/*  </div>*/}

        {/*  <MastermindCreateName/>*/}

        {/*  {user.user.mastermind_name ? <div className="col-12 mb-3 text-right">*/}
        {/*    <Button onClick={() => set_current_step(current_step + 1)}>*/}
        {/*      {translate({*/}
        {/*        fr: `Passer à l'étape suivante`,*/}
        {/*        en: `Go to the next step`,*/}
        {/*      })} <FaArrowRight/></Button>*/}
        {/*  </div> : null}*/}

        {/*</TutorialStep> : null}*/}

        <TutorialStep step={3}
                      current_step={current_step}
                      set_current_step={set_current_step}
                      previous_step={step(2)}
                      title={translate({
                        fr: `Bienvenue`,
                        en: <>Welcome</>,
                      })}>
          {}
          <div className="row mt-5 text-center">
            <div className="col-12 red font-weight-bold">
              {translate({
                en: 'Ready!',
                fr: 'Prêt !',
              })}
            </div>
            <div className="col-12">
              <h1 className="font-weight-bold">
                {translate({
                  fr: `Commence à optimiser tes fiches produits MAINTENANT!`,
                  en: `Start Optimizing Your Listings NOW!`,
                })}
              </h1>
            </div>
            <div className="col-12 col-lg-8 offset-lg-2">
              <h5>{translate({
                fr: `Les Intellinautes augmentent leur CA entre 30 et 50 % sur chaque produit en utilisant Intellifox. Commence à optimiser les tiens maintenant !`,
                en: `Users add anywhere between 30-50% more revenue on each product listing by using Intellifox. Start doing yours now!`,
              })}</h5>
            </div>
            <div className="col-12 text-center mt-5">
              <Button className="btn-lg" onClick={(e) => {
                on_finish()
              }}>
                {translate({
                  fr: `Commencer à optimiser mes produits`,
                  en: `Optimize Product Listings`,
                })} <FaArrowRight/>
              </Button>
            </div>
          </div>

          <div className="col-12  mb-5 clickable" onClick={(e) => {
            on_finish()
          }}>
            <img src={new_product} alt="" className={'w-100'}/>
          </div>
        </TutorialStep>
      </div>

      <div className="position-absolute text-center p-1"
           style={{ right: 20, top: 0 }}>
        <TextButton onClick={() => close()}><h1><MdClose/></h1></TextButton>
      </div>
    </div>
  )
}
