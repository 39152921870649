import React, { Fragment, useContext, useState } from 'react'
import PasswordHelper, { validatePassword } from './password-rule'
import { displayFields } from '../generic/field'
import bcrypt from 'bcryptjs'
import UpdateButton from '../generic/update-button'
import UserContext from '../../context/user'
import CreateButton from '../generic/create-button'
import { DisplayErrors } from '../generic/display-errors'
import { useMutation } from '@apollo/client'
import { ADD_USER } from '../../graphql/queries/user'

import ExternalLink from '../generic/external-link'
import Button from '../generic/button'
import { await_mutate } from '../../graphql/mutate_promise'
import { useLogin } from './login'
import {
  get_referral_override_with_coupon,
  get_user_referral_id,
  get_stored_referral,
  get_stored_utm,
} from '../referral/referral'
import { useLang, useFrench, useTranslate } from '../../context/lang'
import useHashParam from 'use-hash-param'
import TextButton from '../generic/text_button'
import { FaAmazon, FaArrowLeft } from 'react-icons/all'
import IntellifoxIcon from '../../images/intellifox-icon'
import UpdateButtonV2 from '../generic/update_button_v2'


export default function UserFields({ onSubmit, mutation: { data, loading, error }, children, type }) {
  const user = useContext(UserContext)
  const translate = useTranslate()

  const [email, set_email] = useState(user && user.user ? user.user.email : '')
  const [first_name, set_first_name] = useState(user && user.user ? user.user.first_name : '')
  const [last_name, set_last_name] = useState(user && user.user ? user.user.last_name : '')
  const [password, set_password] = useState('')
  const [accept_intellifox_news, set_accept_intellifox_news] = useState(user && user.user ? user.user.accept_intellifox_news || false : false)
  const [accept_weekly_summary, set_accept_weekly_summary] = useState(user.user.accept_weekly_summary)
  const [accept_monthly_summary, set_accept_monthly_summary] = useState(user.user.accept_monthly_summary)

  const isUpdate = type === 'update'
  const fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      label: translate({
        fr: 'Adresse email',
        en: 'Email address',
      }),
    },
    {
      type: 'text',
      value: first_name,
      setter: set_first_name,
      label: translate({
        fr: 'Prénom',
        en: 'First name',
      }),
    },
    {
      type: 'text',
      value: last_name,
      setter: set_last_name,
      label: translate({
        fr: 'Nom',
        en: 'Last name',
      }),
    },
    {
      type: 'checkbox',
      value: accept_weekly_summary,
      setter: set_accept_weekly_summary,
      label: translate({
        fr: 'Recevoir un résumé hebdomadaire',
        en: 'Get weekly summary',
      }),
    },
    {
      type: 'checkbox',
      value: accept_monthly_summary,
      setter: set_accept_monthly_summary,
      label: translate({
        fr: 'Recevoir un résumé mensuel',
        en: 'Get monthly summary',
      }),
    },
  ]

  if (!isUpdate) {
    fields.push({
      type: 'password',
      value: password,
      setter: set_password,
      label: 'Entrez un mot de passe *',
      help: <PasswordHelper/>,
      validate: validatePassword,
    })
  }

  const handleSubmit = async () => {
    try {
      await onSubmit({
        email,
        first_name,
        last_name,
        password_hash: password ? bcrypt.hashSync(password, bcrypt.genSaltSync(8)) : null,
        accept_intellifox_news,
        accept_weekly_summary,
        accept_monthly_summary,
      })
    } catch (e) {
    }
  }
  const isDisabled = isUpdate ? false : !validatePassword(password)

  return (
    <Fragment>
      <div className="col-12">
        {displayFields(fields, handleSubmit)}
      </div>
      <div className={'col-12 ' + ( error ? 'error' : '' )}>
        {isUpdate ? <UpdateButton onClick={handleSubmit} loading={loading} error={error} disabled={isDisabled}
                                  data={data}>{children}</UpdateButton> :
          <CreateButton onClick={handleSubmit} loading={loading} error={error} disabled={isDisabled}
                        data={data}>{children}</CreateButton>}
      </div>
    </Fragment>
  )
}

export const CreateUserFields = ({ set_user, email, set_email }) => {
  const translate = useTranslate()
  const french = useFrench()

  const [step, set_step] = React.useState(1)
  const [error, set_error] = React.useState('')
  const lang = useLang()
  const [createAccount, mutation] = useMutation(ADD_USER)
  const [coupon_code, set_coupon_code] = useHashParam('coupon', '')

  const [login] = useLogin(set_user)

  const [accept_cgv, set_accept_cgv] = useState(false)
  const [accept_intellifox_news, set_accept_intellifox_news] = useState(false)

  const [first_name, set_first_name] = useState('')
  const [last_name, set_last_name] = useState('')
  const [password, set_password] = useState('')

  const first_fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      label: translate({
        fr: 'Entrez votre email : *',
        en: 'Enter your email: *',
      }),
      placeholder: translate({
        fr: 'Entrez votre adresse email ici',
        en: 'Enter your email address here',
      }),
    },

    {
      type: 'checkbox',
      value: accept_cgv,
      setter: set_accept_cgv,
      label: <>
        {french(`J'ai lu et j'accepte :`)}
        <ExternalLink className={'ml-1'} to={'https://intellifox.com/legal/'}>
          {french(`les modalités et conditions, la politique de confidentialité, la politique en matière de cookies et l'avis de non-responsabilité`)}
        </ExternalLink>
      </>,
    },

    {
      type: 'checkbox',
      value: accept_intellifox_news,
      setter: set_accept_intellifox_news,
      label: french(`Intellifox ne cesse de s'améliorer ! Cochez cette case pour recevoir les dernières actualités sur les produits, des tutoriels vidéo et des démos utiles. Vous pouvez changer d'avis à tout moment.`),
    },
  ]

  const second_fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      label: translate({
        fr: 'Entrez votre email *',
        en: 'Enter your email*',
      }),
      placeholder: translate({
        fr: 'Entrez votre adresse email ici',
        en: 'Enter your email address here',
      }),
    },
    {
      type: 'text',
      value: first_name,
      setter: set_first_name,
      label: translate({
        fr: 'Entrez votre prénom *',
        en: 'Enter your first name*',
      }),
      placeholder: translate({
        fr: 'Entrez votre prénom, ex : Brent',
        en: 'Enter your first name, e.g.: Brent',
      }),
    },
    {
      type: 'text',
      value: last_name,
      setter: set_last_name,
      label: translate({
        fr: 'Entrez votre nom *',
        en: 'Enter your last name*',
      }),
      placeholder: translate({
        fr: 'Entrez votre nom, ex : Spiner',
        en: 'Enter your last name, e.g.: Spiner',
      }),
    },
    {
      type: 'password',
      value: password,
      setter: set_password,
      label: translate({
        fr: 'Entrez un mot de passe *',
        en: 'Enter a password*',
      }),
      help: <PasswordHelper password={password}/>,
      validate: validatePassword,
    },
  ]


  const handleSubmit = async () => {
    if (step === 1) {
      if (email && /.+@.+/.test(email) && accept_cgv) {
        set_error('')
        set_step(2)
      } else {
        set_error('missing-first-step')
      }
      return
    }

    if (step === 2) {
      if (!validatePassword(password)) {
        set_error('password-too-short')
        return
      }
    }

    const referral = get_stored_referral() || {}
    const utm = get_stored_utm()

    const referral_override = get_referral_override_with_coupon(coupon_code)

    const referral_id = referral_override ? referral_override.referral_id : referral.referral_id

    const user_referral_id = get_user_referral_id()

    try {
      const response = await await_mutate(createAccount, {
        variables: {
          email, first_name, last_name, accept_intellifox_news,
          password_hash: password ? bcrypt.hashSync(password, bcrypt.genSaltSync(8)) : '',
          lang, referral_id,
          utm: JSON.stringify(utm),
          user_referral_id,
        },
      })

      const { success, error } = response.addUser

      if (success) {
        await login(email, password)
      } else {
        set_error(error)
      }


      if (window.$crisp) {
        window.$crisp.push(['set', 'session:event', ['has_just_created_account']])
      }
      if (window.gtag) {
        window.gtag('event', 'conversion', { 'send_to': 'AW-10960420121/vA-rCPzt_9EDEJn6quoo' })
      }
    } catch (e) {
      set_error('missing-param')
      console.log('addUser error', e)
    }

  }

  return (
    <div className="row">
      <>
        <div className="col-12">
          {step === 1 ? displayFields(first_fields, handleSubmit) : null}
          {step === 2 ? displayFields(second_fields, handleSubmit) : null}
        </div>

        <div className="col-12 error font-weight-bold red mt-3">
          {error === 'missing-first-step' ? translate({
            fr: 'Vous devez entrer votre email et accepter les conditions générales pour continuer.',
            en: 'You need to enter your email address and accept terms and conditions to continue.',
          }) : null}

          {error === 'missing-param' ? french(`Il faut entrer votre nom et prénom.`) : null}
          {error === 'invalid-email' ? french(`L'email est invalide.`) : null}
          {error === 'password-too-short' ? french(`Le mot de passe n'est pas assez long.`) : null}
          {error === 'email-already-exists' ? french(`Un compte existe déjà avec cet adresse email.`) : null}
        </div>

        <div className="col-6">
          {step === 2 ? <>
            <TextButton onClick={() => set_step(1)}><FaArrowLeft/> {translate({
              fr: `Retour`,
              en: `Back`,
            })}</TextButton>
          </> : <>
          </>}
        </div>
        <div className={'col-6 text-right ' + ( mutation.error ? 'error' : '' )}>
          <div>
            {step === 2 ? <UpdateButtonV2 trigger={handleSubmit} disable_success_state={true}>
                <IntellifoxIcon/> {translate({
                fr: `Rejoindre Intellifox`,
                en: `Join Intellifox`,
              })}
              </UpdateButtonV2> :
              <Button onClick={handleSubmit}><IntellifoxIcon/> {translate({
                fr: 'Créer un nouveau compte',
                en: `Create a new account`,
              })}</Button>}
          </div>
        </div>
      </>

    </div>
  )
}
