import React from 'react'
import { AiFillQuestionCircle, FaCalculator, FiEye, FiHelpCircle } from 'react-icons/all'
import Button from '../generic/button'
import { useFrench, useLang } from '../../context/lang'
import IntellifoxModal, { IntellifoxModalComponent } from '../generic/modal'
import { ProductPresentation } from '../../routes/product_v2/product_header'
import { DisplayMoney, format_money, format_percentage } from '../../routes/order/format_numbers'
import Item, { CompactItem, NiceNumbers } from '../generic/item'
import { useMarginSimulations } from './margin_simulations'
import { PricingSimulation } from './Pricing'
import * as _ from 'lodash'
import { Tooltip } from 'react-tippy'

export default function ExploreMarginDetails({ product, parent_product, details, market_place }) {
  const lang = useLang()
  const french = useFrench()

  const {
    price_excl_vat,
    currency,
    margin,
    fixed_cost,
    amazon_fees,
    commission,
    amazon_fees_commission,
    expedition,
    auto_entrepreneur_taxes,
    auto_entrepreneur_rate,
    is_auto_entrepreneur,
  } = details


  const margin_simulations = useMarginSimulations(details)


  const auto_entrepreneurs_details = is_auto_entrepreneur ? [{
      description: <>URSSAF <span className="small">({format_percentage(auto_entrepreneur_rate, 2)})</span></>,
      value: -auto_entrepreneur_taxes,
      bg: 'gradient_bg_profit-flat',
    }]
    : []

  return (
    <IntellifoxModal
      title={<><FiEye/> {french(`Détails du calcul de marge`)}</>}
      body={
        <div className="row">
          <div className="col-12">
            <ProductPresentation product={product} parent_product={parent_product}/>
          </div>

          <div className="col-12">
            <Item no_padding={true}>
              <NumbersBreakdownTable details={[
                {
                  description: french(`Prix de vente hors-taxes`),
                  value: price_excl_vat,
                  bg: 'gradient_bg',
                },
                {
                  description: french(`Frais d'expédition par Amazon`),
                  value: -expedition,
                  bg: 'gradient_bg_amazon',
                },
                {
                  description: <>{french(`Commission Amazon`)} <span
                    className="small">({format_percentage(commission, 2)})</span>
                  </>,
                  value: -amazon_fees_commission,
                  bg: 'gradient_bg_amazon',
                },

                {
                  description: french(`Coût du produit`),
                  value: -fixed_cost,
                  bg: 'gradient_bg_profit',
                },
                ...auto_entrepreneurs_details,

              ]} currency={currency}
              roi={<ROI roi={margin / fixed_cost} currency={currency}/>}
              />

            </Item>
          </div>

          <div className="col-12 mt-3">
            <h4 className="font-weight-bold">{french(`Simulations de marges de 0% à 50%`)}</h4>
          </div>
          <div className="col-12">
            {margin_simulations.map(({ price, percentage }, i) => {
              return <CompactItem key={i} className={'mb-3'} label={format_percentage(percentage, 2)}>
                <PricingSimulation product={product} parent_product={parent_product} price={price}
                                   market_place={market_place} roi={true}/>
              </CompactItem>
            })}
          </div>
        </div>
      }
      button_props={{
        className: 'btn-sm btn-block',
        outline: true,
      }}>
      <FiEye/> {french(`Voir le détail`)}
    </IntellifoxModal>
  )
}

export const ROI = ({roi, currency}) => {
  const lang = useLang()
  const french = useFrench()
  return <Tooltip html={<div className="row text-left">
    <div className="col-12">
      {french(`Cela signifie que`)}
      <ul>
        <li>{french(`Si tu investis dans du stock la somme de :`)} {format_money(lang, 100, currency)}</li>
        <li>{french(`Alors tu récupères ta mise et tu génères en plus :`)} {format_money(lang, 100*roi, currency, 2)}</li>
      </ul>
    </div>
  </div>}>
    <span className="small font-weight-bold">{french(`Retour sur investissement :`)} {format_percentage(roi,2)} <FiHelpCircle/></span>
  </Tooltip>
}

const MarginsDetailsTableRow = ({ description, value, currency, bg }) => {
  const lang = useLang()

  if (value === 0 || value === -0) {
    return null
  }

  return <tr className={`bg-${bg}-flat`}>
    <td className={'small font-weight-bold'}>{description}</td>
    <td
      className={'small font-weight-bold text-right'}>{value >= 0 ? format_money(lang, value, currency, 2) : null}</td>
    <td className={'small font-weight-bold text-right'}>{value < 0 ? format_money(lang, value, currency, 2) : null}</td>
  </tr>
}

export const NumbersBreakdownTable = ({ details, currency, roi }) => {

  const french = useFrench()
  const lang = useLang()

  const total_plus = _.sumBy(details.filter(({ value }) => value >= 0), 'value')
  const total_minus = _.sumBy(details.filter(({ value }) => value < 0), 'value')
  const margin = total_plus + total_minus

  return <Item no_padding={true}>
    <table className={'table'}>
      <thead>
      <tr>
        <th>{french(`Description`)}</th>
        <th>{french(`Revenus`)}</th>
        <th>{french(`Coûts`)}</th>
      </tr>
      </thead>
      <tbody>
      {details.map(({ description, value, bg, hr }, i) => {
        if (hr) {
          return <tr>
            <td colSpan={'100%'}>
              <hr/>
            </td>
          </tr>
        }
        return <MarginsDetailsTableRow key={i} description={description} value={value} currency={currency} bg={bg}/>
      })}

      <tr style={{ borderTop: '1px solid rgba(128,128,128,0.2)' }}>
        <td className="font-weight-bold">{french(`Totaux`)}</td>
        <td className={'text-right font-weight-bold'}>{format_money(lang, total_plus, currency, 2)}</td>
        <td
          className={'text-right font-weight-bold'}>{format_money(lang, total_minus, currency, 2)}</td>
      </tr>

      <tr style={{ borderTop: '1px solid rgba(128,128,128,0.2)' }}>
        <td></td>
        <td className={'text-right font-weight-bold'} colSpan={2}>
          <NumbersSummary currency={currency} profit_or_loss={margin}/>
          {roi ? <><br/>{roi}</>:null}
        </td>
      </tr>
      </tbody>
    </table>
  </Item>
}

export const useProfitOrLoss = (profit_or_loss) => {
  const french = useFrench()
  let summary_bg
  let summary_emoji
  let summary_label
  if (profit_or_loss === 0) {
    summary_bg = 'secondary'
    summary_emoji = '🤔'
    summary_label = '?'
  } else if (profit_or_loss > 0) {
    summary_bg = 'success'
    summary_emoji = '👍'
    summary_label = french('Profit')
  } else {
    summary_bg = 'danger'
    summary_emoji = '😓'
    summary_label = french('Perte')
  }

  return {
    summary_bg,
    summary_emoji,
    summary_label,
  }
}

export const NumbersSummary = ({ currency, label, profit_or_loss, action, percentage, details }) => {
  const lang = useLang()
  const french = useFrench()

  const {
    summary_bg,
    summary_emoji,
    summary_label,
  } = useProfitOrLoss(profit_or_loss)

  return <NiceNumbers label={label} icon={summary_emoji} bg={summary_bg}
                      conclusion={<div className="row align-items-center">
                        <div className="col">
                          {summary_label}
                        </div>
                        {percentage ? <div className={'col-auto'}
                                           style={{ fontSize: '12px' }}>({format_percentage(percentage)})</div> : null}
                        {details ? <div className={'col-auto'}>
                          <NumbersBreakdownModal details={details} currency={currency}>
                            <FiHelpCircle/>
                          </NumbersBreakdownModal>
                        </div> : null}
                        <div className="col-auto text-right">
                          {profit_or_loss > 0 ? '+' : ''}<DisplayMoney value={profit_or_loss} currency={currency}/>
                        </div>

                      </div>} action={action}/>
}

export const NumbersBreakdownModal = ({ details, currency, children }) => {
  // details : {value, description}
  const french = useFrench()
  const [show, set_show] = React.useState(false)

  return <>
    <div className="d-inline-block clickable" onClick={() => set_show(true)}>{children}</div>
    <IntellifoxModalComponent show_modal={show} close={() => set_show(false)}
                              title={<><FaCalculator/> {french(`Détails des calculs`)}</>}
                              body={<div className="row">
                                <div className="col-12">
                                  <NumbersBreakdownTable details={details} currency={currency}/>
                                </div>
                              </div>}/>
  </>
}
