import React, { Fragment, useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'


import PageTitle from '../generic/page-title'
import { displayFields } from '../generic/field'
import Button from '../generic/button'
import PasswordHelper from './password-rule'
import Item, { CompactItem } from '../generic/item'
import useLocalStorage from '../../hooks/useLocalStorage'
import { Checkbox } from '../generic/checkbox'
import { FullScreenCentered } from '../generic/centered'
import IntellifoxIcon from '../../images/intellifox-icon'
import { error_to_i18n } from '../i18n/errors'
import CreateAccount from './create-account'
import { await_mutate } from '../../graphql/mutate_promise'
import TextButton from '../generic/text_button'
import UserContext from '../../context/user'
import useHashParam from 'use-hash-param'
import IntellifoxModal, { IntellifoxModalComponent } from '../generic/modal'
import { RESET_PASSWORD } from '../../graphql/queries/user'
import { useFrench, useTranslate } from '../../context/lang'
import LoginWithAmazon from './login_with_amazon'
import { MdHelp } from 'react-icons/md'
import { FaArrowDown, FaArrowRight, FiArrowRight, FiLogIn, FiMail, FiUserPlus } from 'react-icons/all'
import FlatChoice, { FlatChoices } from '../generic/flat_choice'
import GlobalProgressBar from './global_progress_bar'
import OrSeparator from '../generic/or_separator'


const GET_TOKEN = gql`
    mutation GetToken($email: String!, $password: String!, $expires: Int!) {
        getToken(email:$email, password:$password, expires:$expires) {
            token,
            valid_until,
            user {
                user_id
                email
                first_name
                last_name
            }
            company {
                company_id
                label
                url
            }
        }
    }
`

export const useLogin = (set_user) => {
  const [getToken, getTokenMutation] = useMutation(GET_TOKEN) // 1 day = 60*60*24
  const user = React.useContext(UserContext)

  const login = React.useCallback(async (email, password, expires = 8 * 60 * 60 * 24) => {
    await await_mutate(getToken, {
      variables: { email, password: password, expires },
      update: (mutation) => {
        set_user({
          ...mutation.data.getToken,
        })
        // This was a way of sending the token to the Web extension
        // window.postMessage({
        //   direction: 'intellifox-ui',
        //   user: null,
        // }, '*')
        user.refresh()
      },
    })
  }, [getToken, set_user])

  return [login, getTokenMutation]
}


export const LostPasswordModal = ({ email, set_email }) => {
  const translate = useTranslate()

  const [success, set_success] = React.useState(false)
  const fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      placeholder: translate({
        fr: 'Entrez votre adresse email',
        en: 'Enter your email address',
      }),
    },
  ]

  const [reset_password] = useMutation(RESET_PASSWORD)

  const on_click = async () => {
    const success = await await_mutate(reset_password, {
      variables: { email },
    })

    if (!success.resetPassword) {
      throw new Error(translate({
        fr: `L'email "${email}" ne correspond à aucun compte sur Intellifox`,
        en: `The email "${email}" doesn't match any account on Intellifox`,
      }))
    }

    set_success(true)
  }
  return <><IntellifoxModal title={translate({
    fr: `J'ai besoin d'aide pour me connecter`,
    en: `I need help to log in`,
  })}
                            action_label={translate({
                              fr: 'Recevoir des instructions par email',
                              en: 'Receive instructions by email',
                            })}
                            action_in_progress_label={translate({
                              fr: 'Vérification en cours',
                              en: 'Verification in progress',
                            })}
                            on_click={on_click}
                            body={<>
                              <div className="row">
                                <div className="col-12">
                                  {translate({
                                    fr: `Pour pouvoir récupérer votre mot de passe, vous devez entrer votre adresse email dans le
                                  champs
                                  ci-dessous :`,
                                    en: `In order to reset your password, you need to enter your email address:`,
                                  })}
                                </div>
                                <div className="col-12">
                                  {displayFields(fields)}
                                </div>

                                <div className="col-12">
                                  {translate({
                                    fr: `Ensuite, cliquez sur le bouton en bas, "Récupérer mon mot de passe".`,
                                    en: `Then click on the button "Reset password"`,
                                  })}
                                </div>
                                <div className="col-12 mt-3">
                                  <div className="alert alert-info">
                                    <div className="row">
                                      <div className="col-12 font-weight-bold">
                                        {translate({
                                          fr: `Que va-t-il se passer ?`,
                                          en: `What's going to happen?`,
                                        })}
                                      </div>
                                      <div className="col-12">
                                        {translate({
                                          fr: `Si l'adresse email est correcte, alors vous allez recevoir un email avec des
                                        instructions
                                        qui vont vous permettre de recréer un nouveau mot de passe.`,
                                          en: `If the email address is correct, then you'll receive an email with instructions on how to reset your password.`,
                                        })}
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>}
                            button_props={{ className: 'btn-sm', outline: true }}
  >
    <MdHelp/> {translate({
    fr: `J'ai besoin d'aide pour me connecter`,
    en: `I need help to log in`,
  })}
  </IntellifoxModal>
    <IntellifoxModalComponent show_modal={success} close={() => set_success(false)}
                              title={<>
                                {translate({
                                  fr: `Les instructions pour remettre à zéro votre mot de passe ont été envoyées à`,
                                  en: `Instructions on how to reset your password have been sent to`,
                                })} "{email}"</>}
                              body={<>
                                <div className="row">
                                  <div className="col-12">
                                    {translate({
                                      fr: `Vérifiez votre boîte mail (et votre dossier spam si besoin) et
                                        suivez
                                        les instructions présentes dans l'email.`,
                                      en: <>Check your inbox for <b>{email}</b> and your <b>spam</b> folder if need.
                                        Then simply follow the instructions contained in the email.</>,
                                    })}
                                  </div>
                                  <div className="col-12">
                                    {translate({
                                      fr: `Bonne journée à vous !`,
                                      en: `Have a lovely day!`,
                                    })}
                                  </div>
                                </div>
                              </>}/>
  </>
}

export default function Login({ set_user }) {
  const [stored_email, set_stored_email] = useLocalStorage('intellifox_login', '')
  const [remember_me, set_remember_me] = useLocalStorage('intellifox_remember_login', true)
  const translate = useTranslate()
  const french = useFrench()

  const [email, set_email] = useState(stored_email || '')
  const [password, set_password] = useState('')

  const [other_option, set_other_option] = React.useState(email ? 'connect_with_email' : '')
  const [connect_or_create_account, set_connect_or_create_account] = React.useState(email ? 'connect' : '')

  const [login, getTokenMutation] = useLogin(set_user)

  useEffect(() => {
    if (remember_me) {
      set_stored_email(email)
    } else {
      set_stored_email('')
    }
  }, [remember_me, stored_email, email])

  const fields = [
    {
      type: 'email',
      value: email,
      setter: set_email,
      placeholder: translate({
        fr: 'Entrez votre adresse email',
        en: 'Enter your email address',
      }),
    },
    {
      type: 'password',
      value: password,
      setter: set_password,
      placeholder: translate({
        fr: 'Entrez votre mot de passe',
        en: 'Enter your password',
      }),
      helper: <PasswordHelper/>,
    },
  ]


  const on_submit = async () => {
    try {
      await login(email, password)
    } catch (e) {
    }
  }

  const connect_with_email = <>
    <div className="row">
      <div className="col-12">
        {displayFields(fields, on_submit)}
      </div>
      <Checkbox col={6} value={remember_me} setter={set_remember_me}>{translate({
        fr: `Se souvenir de l'email`,
        en: `Remember me`,
      })}</Checkbox>
      <div className="col-6 text-right">
        <LostPasswordModal email={email} set_email={set_email}/>
      </div>
      {getTokenMutation.error ? <div className="col-12 mb-2 mt-2">
        <div className="alert alert-danger"><span
          className="font-weight-bold">{translate({
          fr: `Erreur :`,
          en: `Error:`,
        })}</span> {error_to_i18n(getTokenMutation.error)}</div>
      </div> : null}
      <div className="col-12 mt-4">
        <Button onClick={on_submit} disabled={getTokenMutation.loading} className={'w-100'} outline={true}>{translate({
          fr: `Se connecter`,
          en: `Log in`,
        })} <FiArrowRight/></Button>
      </div>
    </div>
  </>

  const create_account = <>
    <CreateAccount set_user={set_user} set_other_option={set_other_option} email={email} set_email={set_email}/>
  </>


  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-5 text-center">
          <h1 dangerouslySetInnerHTML={{
            __html: translate({
              fr: `Tu es à <u>2 minutes</u> de pouvoir optimiser tes fiches produits`,
              en: `You're <u>2 Mins Away</u> From Optimizing Your Product Listings`,
            }),
          }}></h1>
        </div>
        <div className="col-12 col-md-8 offset-md-2 pt-5">
          <GlobalProgressBar step={0}/>

          <div className="row">
            <div className="col-12 col-lg-6 mb-2">
              <CompactItem label={translate({
                fr: `Nouveau ?`,
                en: `New user?`,
              })}
                           style={{
                             opacity: !connect_or_create_account || connect_or_create_account === 'create_account' ? 1 : 0.3,
                           }}>
                <div className="row">
                  <div className="col-12 text-center">
                    <FiUserPlus style={{ fontSize: '150px' }}/>
                  </div>
                  <div className="col-12">
                    <Button className={'btn-block'}
                            onClick={() => set_connect_or_create_account(connect_or_create_account === 'create_account' ? '' : 'create_account')}
                            button={!connect_or_create_account || connect_or_create_account === 'create_account' ? 'primary' : 'secondary'}>
                      {translate({
                        fr: `Je crée un nouveau compte`,
                        en: `Create an account`,
                      })}</Button>
                  </div>
                </div>
              </CompactItem>
            </div>
            <div className="col-12 col-lg-6 mb-2">
              <CompactItem label={translate({
                fr: `De retour ?`,
                en: `You're back?`,
              })}
                           style={{
                             opacity: !connect_or_create_account || connect_or_create_account === 'connect' ? 1 : 0.3,
                           }}>
                <div className="row">
                  <div className="col-12 text-center">
                    <FiLogIn style={{ fontSize: '150px' }}/>
                  </div>
                  <div className="col-12">
                    <Button className={'btn-block'}
                            onClick={() => set_connect_or_create_account(connect_or_create_account === 'connect' ? '' : 'connect')}
                            button={!connect_or_create_account || connect_or_create_account === 'connect' ? 'primary' : 'secondary'}>
                      {translate({
                        fr: `Je me connecte`,
                        en: `Log-in`,
                      })}</Button>
                  </div>
                </div>
              </CompactItem>
            </div>
          </div>

          {connect_or_create_account ? <>
            <div className="row">
              <div className="col-12">
                <CompactItem label={<>
                  {connect_or_create_account === 'connect' ? french('Connexion') : null}
                  {connect_or_create_account === 'create_account' ? french(`Création d'un compte`) : null}
                </>}>
                  <div className="row">
                    <div className="col-12 my-4">
                      <LoginWithAmazon connect_or_create_account={connect_or_create_account}/>
                    </div>
                  </div>

                  <OrSeparator/>

                  <div className="row">
                    <div className="col-12">
                      {connect_or_create_account === 'connect' ? connect_with_email : null}
                      {connect_or_create_account === 'create_account' ? create_account : null}
                    </div>
                  </div>
                </CompactItem>
              </div>
            </div>
          </> : null}

        </div>
      </div>
    </div>
  )
}
