export const find_matching_route = (current_path, routes, prefix = '') => {

  const split_current_path = current_path.split('/')
  return routes.find(({ path }) => {
    path = prefix + path
    if (path === current_path) {
      return true
    }

    const split_path = path.split('/')

    let is_current_path = true
    for (let i = 0; i < split_current_path.length; i++) {
      if (typeof split_path[ i ] !== 'undefined') {
        if (split_path[ i ][0] === ':') {

        } else {
          is_current_path = is_current_path && split_current_path[ i ] === split_path[ i ]
        }
      } else {
        return false
      }
    }

    return is_current_path
  })
}

export const extract_params = (current_path, path, prefix = '') => {
  const split_current_path = current_path.split('/')
  const split_path = (prefix + path).split('/')

  const params = {}
  for (let i = 0; i < split_current_path.length; i++) {
    if (split_path[ i ] && split_path[ i ][0] === ':') {
        params[split_path[i].substring(1)] = split_current_path[i]
    }
  }

  return params
}
