import React, { useContext } from 'react'
import DeleteButton from '../../components/generic/delete-button'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { RESET_IMPORT_DELETE_EVERYTHING } from '../../graphql/queries/import'
import { useDetailedProducts } from '../../context/products'
import { useCachedContext } from '../../hooks/useCachedContext'
import useLocalStorage from '../../hooks/useLocalStorage'
import UserContext from '../../context/user'
import { useFrench, useTranslate } from '../../context/lang'

export default function ResetAccount() {
  const company_id = useCompanyId()
  const french = useFrench()
  const user = useContext(UserContext)
  const translate = useTranslate()

  const [_, set_latest_products] = useLocalStorage(`latest_products_${user.user.user_id}`, [])

  const [resetImportDeleteEverything, mutation] = useMutation(RESET_IMPORT_DELETE_EVERYTHING, {
    variables: {
      company_id,
    },
    update:(_,mutation) => {
      if (mutation.data) {
        set_latest_products([])
        window.location.reload()
      }
    }
  })

  if (company_id === 'KRT5f683_V') {
    return <div className="row">
      <div className="col-12">
        <alert className="alert danger">
          {french(`This operation is not available for your account. Contact the support to proceed.`)}
        </alert>
      </div>
    </div>
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">
          <div className="row">
            <div className="col-12">
              {translate({
                  fr: `Cette zone permet de supprimer tous les produits. Il est ensuite possible de
              relancer l'import depuis la page principale.`,
                  en: `This area allows you to delete all your products. You can then launch the global import again from the index.`
              })}
            </div>
            <div className="col-12">
              { mutation.data ? <span className="font-weight-bold">{translate({
                  fr: `Tous les produits ont été supprimés`,
                  en: `All the products have been deleted`
              })}</span> : <DeleteButton onClick={() => resetImportDeleteEverything()} mutation={mutation}>{translate({
                  fr: `Je supprime tous mes produits`,
                  en: `Delete all my products`
              })}</DeleteButton>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
