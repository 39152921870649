import React, { useContext } from 'react'
import { useCachedContext } from '../hooks/useCachedContext'

const ProductsContext = React.createContext({
  loading: true,
  error: false,
  data: null,
})

export const useProducts = () => useContext(ProductsContext)

export const useDetailedProducts = (market_place, current_page, products_per_page) => useCachedContext('products', market_place, current_page, products_per_page)

export const useProduct = (product_id, market_place) => useCachedContext('product', product_id, market_place)

export const useProductSeveralMarketplaces = (product_id, market_places) => useCachedContext('product_several_market_places', product_id, market_places)

export default ProductsContext
