export const scroll_to_node = (id) => {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export const scroll_to_top = () => {
  const main_content_scrollable = document.querySelector('.main-content-scrollable')
  if (main_content_scrollable) {
    main_content_scrollable.scrollTop = 0
  }
}
