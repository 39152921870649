import React from 'react'
import TimezoneSelect from 'react-timezone-select'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import { useCachedContext } from '../../hooks/useCachedContext'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import { FaCheck, FiClock, FiEdit, FiGlobe, FiMapPin } from 'react-icons/all'
import Button from '../../components/generic/button'
import { useFrench } from '../../context/lang'


const update_timezone_mutation = create_mutation({
  mutation: 'update_timezone',
  params: {
    company_id: 'ID!',
    timezone: 'String',
  },
})

export default function TimezoneSelector() {
  const french = useFrench()
  const company_id = useCompanyId()
  const me = useCachedContext('me')
  const { timezone } = me.company
  const current_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [show_edit, set_show_edit] = React.useState(false)
  const [selected_timezone, set_selected_timezone] = React.useState(timezone || current_timezone)

  const [update_timezone] = useMutation(update_timezone_mutation)
  React.useEffect(() => {
    if (timezone !== selected_timezone) {
      const go = async () => {
        await await_mutate(update_timezone, {
          variables: {
            company_id,
            timezone: selected_timezone,
          },
        })

        await me.refresh()
      }

      go().catch(e => console.error(e))
    }
  }, [selected_timezone])

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <div className="row">
          <div className="col-12">
            <div className="btn-group">
              <Button onClick={() => {
                set_selected_timezone(current_timezone)
                set_show_edit(false)
              }} button={current_timezone === timezone ? 'primary' : 'secondary'}><FiMapPin/> {french(`Fuseau horaire actuel :`)} {current_timezone}</Button>
              <Button onClick={() => {
                set_show_edit(!show_edit)
              }} button={current_timezone !== timezone ? 'primary' : 'secondary'}><FiGlobe/> {french(`Autre`)} {selected_timezone !== current_timezone ? <span>: {selected_timezone}</span> : null}</Button>
            </div>
          </div>
          {show_edit ? <div className="col-12">
            <div style={{
              maxWidth: 500,
            }}>
              <TimezoneSelect

                value={selected_timezone}
                onChange={(timezone) => set_selected_timezone(timezone.value)}
              />

            </div>
          </div> : null}
        </div>
      </div>
    </div>
  )
}
