import React, { useContext } from 'react'
import useCompanyId, {
  useActiveFreeTrial,
  useCompany,
  useHasActiveSubscription,
  useIsCanceled, useIsPaused,
} from '../../hooks/useCompanyId'
import Item from '../generic/item'
import Button from '../generic/button'
import strategy from '../../images/story/strategy.svg'
import { DisplayMenuItem } from '../../routes/product_v2/content'
import { FullScreenCentered } from '../generic/centered'
import UserContext from '../../context/user'
import moment from 'moment'
import { useFrench, useTranslate } from '../../context/lang'
import { FaArrowRight, FaArrowUp } from 'react-icons/all'
import NavigationButton from '../generic/navigation-button'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import UpdateButtonV2 from '../generic/update_button_v2'
import { await_mutate } from '../../graphql/mutate_promise'
import IntellifoxModal from '../generic/modal'
import SelectPlan, { CardWarning } from '../user/select-plan'
import {  UpgradeSelector } from '../../routes/subscription/upgrade'

export const useCanStartFreeTrial = () => {
  const company = useCompany()

  return company.subscription_tier === 'new_customer'
}

export const useNeedsToUpgrade = () => {
  const company = useCompany()

  return company.subscription_tier === 'canceled' || company.subscription_tier === 'paused'
}

const StartFreeTrialButton = ({ className, children }) => {
  const company_id = useCompanyId()
  const user = useContext(UserContext)
  const french = useFrench()

  // const [start_free_trial] = useMutation(create_mutation({
  //   mutation: 'start_free_trial',
  //   params: {
  //     company_id: 'ID!',
  //   },
  // }))
  //
  // const do_it = async () => {
  //   await await_mutate(start_free_trial, {
  //     variables: {
  //       company_id,
  //     },
  //   })
  //   await user.refresh()
  // }

  return <IntellifoxModal button_props={{ className }}
                          title={french(`Démarrer la période d'essai gratuite`)}>{children}</IntellifoxModal>
}

export default function StartFreeTrial() {
  const translate = useTranslate()


  return (
    <div className="row">
      <div className="col-12">
        <Item>
          <div className="row mt-5 mb-5 text-center">
            <div className="col-12 red font-weight-bold">
              {translate({
                fr: 'Dernière étape !',
                en: 'Last Step!'
              })}
            </div>
            <div className="col-12">
              <h1 className="font-weight-bold">{translate({
                fr: 'Avant de démarrer, tu dois choisir un plan ci-dessous',
                en: 'Before You Get Started, Please Choose A Plan Below'
              })} 👇</h1>
            </div>
            <div className="col-12">
              <h4>{translate({
                fr: `Tu ne seras PAS facturé aujourd'hui`,
                en: `You WON'T be billed today`
              })}<FaArrowRight className="mx-2"/>{translate({
                fr: `Annule ou modifie ton plan à tout moment`,
                en: 'Cancel or change your plan at any time'
              })}</h4>
            </div>
          </div>
          <CardWarning/>
          <UpgradeSelector/>
        </Item>
      </div>
    </div>
  )
}


export function WelcomeBack() {
  const translate = useTranslate()


  return (
    <div className="row">
      <div className="col-12">
        <Item>
          <div className="row mt-5 mb-5 text-center">
            <div className="col-12 red font-weight-bold">
              {translate({
                fr: 'De retour ?',
                en: 'Last Step!'
              })}
            </div>
            <div className="col-12">
              <h1 className="font-weight-bold">{translate({
                fr: 'Avant de continuer, tu dois choisir un plan ci-dessous',
                en: 'Before You Continue, Please Choose A Plan Below'
              })} 👇</h1>
            </div>
          </div>
          <UpgradeSelector/>
        </Item>
      </div>
    </div>
  )
}

const Upgrade = () => {
  const french = useFrench()

  return (
    <div className="row">
      <div className="col-12">
        <Item>
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <img src={strategy} alt=""/>
            </div>
            <div className="col-12 col-md-8">
              <h1>{french(`Mettre à niveau`)}</h1>
              <NavigationButton to={'/subscription/upgrade'}>{french(`Choisir un forfait`)}</NavigationButton>
            </div>
          </div>
        </Item>
      </div>
    </div>
  )
}


export const OnlyPremium = ({ is_active = true, children, background = 'var(--color-bg-global)', fixed = false }) => {

  const can_start_free_trial = useCanStartFreeTrial()
  const needs_to_upgrade = useNeedsToUpgrade()

  const display_start_trial = can_start_free_trial && is_active
  const display_upgrade = needs_to_upgrade && is_active

  const hide_content = display_start_trial || display_upgrade

  if (!is_active) {
    return children
  }

  if (!hide_content) {
    return children
  }

  if (fixed) {
    return <>
      <div className="row position-fixed">
        <div className="col-12 h-100 w-100" style={{
          overflow: 'hidden',
        }}>
          {children}
        </div>
        <div className="h-100 w-100 position-fixed" style={{
          left: 0,
          top: 52,
          background: `linear-gradient(rgba(255,255,255,0), ${background})`,
        }}></div>
      </div>
      <FullScreenCentered>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {can_start_free_trial ? <StartFreeTrial/> : null}
              {needs_to_upgrade ? <WelcomeBack/> : null}
            </div>
          </div>

        </div>

      </FullScreenCentered>
    </>
  }

  return <>
    <div className="row position-relative">
      <div className="col-12" style={{
        height: 50,
        overflow: 'hidden',
        opacity: 1,
      }}>
        {children}
      </div>
      <div className="h-100 w-100 position-absolute" style={{
        left: 0,
        top: 0,
        background: `linear-gradient(rgba(255,255,255,0), ${background})`,
      }}></div>
    </div>
    <div className="row position-relative">
      <div className="col-12">
        {can_start_free_trial ? <StartFreeTrial/> : null}
        {needs_to_upgrade ? <WelcomeBack/> : null}
      </div>
    </div>
  </>
}


export const ActiveFreeTrial = () => {
  const has_active_free_trial = useActiveFreeTrial()
  const is_canceled = useIsCanceled()
  const is_paused = useIsPaused()
  const can_start_free_trial = useCanStartFreeTrial()
  const user = useContext(UserContext)
  const french = useFrench()
  const translate = useTranslate()

  if (!has_active_free_trial && !can_start_free_trial && !is_canceled && !is_paused) {
    return null
  }

  if (can_start_free_trial) {
    return <div className="row">
      <div className="col-12 text-center px-4 py-2">
        <StartFreeTrialButton className={'btn-sm'}><FaArrowRight/> {french(`Démarrer l'essai gratuit`)}
        </StartFreeTrialButton>
      </div>
    </div>
  }

  const { free_trial_end } = user.company

  const days_left = moment(free_trial_end).diff(moment(), 'days') + 1

  const current = days_left
  const limit = 21

  return <div className="row">
    <div className="col-12 px-4 py-2">
      <div className="small text-center w-100 ">
        {has_active_free_trial ? <>
          <div className="text-uppercase font-weight-bold">
            {translate({
              fr: `Période d'essai`,
              en: `Free trial`,
            })}
          </div>
          <div className="font-weight-bold">
            <div className="progress d-inline-block" style={{ width: '100%', height: 30 }}>
              <div
                className={`progress-bar px-1 rounded ${current > 15 ? 'bg-success' : 'bg-danger'}`}
                role="progressbar"
                aria-valuenow={current} aria-valuemin="0" aria-valuemax={limit}
                style={{
                  minWidth: ( 100 * ( current / limit ) ) + '%',
                  fontSize: 16,
                  minHeight: 30,
                  fontWeight: 600,
                }}><b>{current.toFixed(0)} {french(`jour(s) restant(s)`)}</b></div>
            </div>
          </div>
        </> : null}
        <div>
          {is_paused ? <UnpauseAccount/> :
            <NavigationButton className={'btn-sm btn-block'} to={'/subscription/upgrade'}>
              <FaArrowUp/> {french(`Mettre à niveau`)}
            </NavigationButton>}
        </div>
      </div>
    </div>
  </div>
}

const UnpauseAccount = () => {
  return <NavigationButton>Réactiver mon abonnement</NavigationButton>
}
