import React from 'react'
import { compute_scale, DomRenderer, Scaler } from '../renderer/dom_renderer'
import { apply_overrides } from '../override/apply_overrides'
import { clean_for_label } from '../../../routes/product/text-substitution'
import { DesignerContext, useDesignerContext } from '../designer_context'
import Button from '../../generic/button'
import TextButton from '../../generic/text_button'
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md'
import { absolute } from '../../../absolute_link'
import { FaCheckCircle } from 'react-icons/all'
import { useTranslate } from '../../../context/lang'

export default function ModelAndVariations({ product, dimensions, paddingTop, paddingBottom }) {

  const [show_variations_start, set_show_variations_start] = React.useState(0)
  const max_shown_variations = 3
  const translate = useTranslate()

  const {
    width, height, current_product_id, set_edit_overrides,
    set_current_product_id,
    set_current_menu,
    set_current_option, current_objects, 
  } = useDesignerContext()

  const scale = compute_scale({
    available_width: dimensions.height - paddingTop - paddingBottom,
    available_height: dimensions.height - paddingTop - paddingBottom,
    width,
    height,
  })

  const RenderObjects = ({ product_id, is_variation, objects, overrides, width, height, label }) => {
    const is_current = current_product_id === product_id
    const designer_context = useDesignerContext()
    return <DesignerContext.Provider value={{
      ...designer_context,
      current_product_id: product_id
    }}>
      <div className="col-auto ml-4 mr-2 position-relative clickable"
           style={{
             width: dimensions.height - paddingTop - paddingBottom,
             height: dimensions.height,
             paddingTop,
             paddingBottom,
           }} onClick={() => {
        set_edit_overrides(is_variation)
        set_current_product_id(product_id)
        set_current_menu(null)
        set_current_option(null)
      }}>
        <Scaler scale={scale}>
          <DomRenderer objects={objects} overrides={overrides}
                       width={width}
                       height={height}
                       style={{ border: is_current ? '20px solid grey' : '5px solid grey' }}/>
        </Scaler>
        <div className="position-absolute text-uppercase text-center" style={{
          width: dimensions.height - paddingTop - paddingBottom,
          fontSize: 16,
          fontWeight: is_current ? 500 : 300,
          paddingLeft: 5,
          paddingRight: 5,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          wordWrap: 'normal',
          left: 0,
          bottom: paddingBottom,
          background: 'grey',
          color: 'white',
        }}>{label}</div>
        {is_current ? <div className="position-absolute" style={{ top: 0, left: -5, color: 'grey' }}>
          <FaCheckCircle style={{ borderRadius: '100%', background: 'white' }}/>
        </div> : null}
      </div>
    </DesignerContext.Provider>
  }

  const variations = product.variations
    .filter((_, i) => i >= show_variations_start && i < show_variations_start + max_shown_variations)
    .map((variation, i) => {

      const objects = apply_overrides(current_objects.objects, current_objects.overrides[ variation.product_id ])

      return <RenderObjects key={i}
                         is_variation={true}
                         product_id={variation.product_id}
                         objects={objects}
                         width={width}
                         height={height}
                         label={clean_for_label(variation.product_info.variation, 50, [])}/>
    })

  const show_prev = show_variations_start > 0
  const show_next = show_variations_start + max_shown_variations < product.variations.length

  return <div className="container-fluid  h-100 menu-colors" style={{ background: 'var(--color-bg-global-darker)' }}>
    <div className="row no-gutters justify-content-center">
      <RenderObjects product_id={product.product_id}
                  objects={current_objects.objects}
                  overrides={[]}
                  width={width}
                  height={height}
                  label={translate({
                    fr: 'Modèle',
                    en: 'Model',
                  })}/>
      {/*{product.variations.length > max_shown_variations ?*/}
      <div className="col-auto m-2" style={{ opacity: show_prev ? 1 : 0 }}><TextButton
        onClick={() => set_show_variations_start(show_variations_start - 1)}
        disabled={!( show_prev )}><MdNavigateBefore style={{ fontSize: 70 }}/></TextButton>
      </div>
      {/*: null}*/}
      {variations}
      {product.variations.length > max_shown_variations ?
        <div className="col-auto m-2" style={{ opacity: show_next ? 1 : 0 }}><TextButton
          onClick={() => set_show_variations_start(show_variations_start + 1)}
          disabled={!( show_next )}><MdNavigateNext
          style={{ fontSize: 70 }}/></TextButton></div> : null}
    </div>
  </div>
}
