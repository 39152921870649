import React from 'react'
import InternalLink from '../generic/internal-link'
import { FaArrowLeft, FaChevronRight } from 'react-icons/all'
import { useTranslate } from '../../context/lang'
import TextButton from '../generic/text_button'

export default function BackToHome() {
  const translate = useTranslate()
  return (
    <BackTo to={"/welcome"} label={translate({
        fr: "Retour à l'accueil",
        en: "Go back",
    })}/>
  )
}

export function BackTo({to, label, dropdown, pt = 'pt-5', pb = 'pb-2', breadcrumb, on_click}) {

  if (on_click) {
    return <div className={`row ${pt} ${pb}`}>
      <div className="col-12 col-md-auto clickable">
        <a className="internal_link" onClick={on_click}><FaArrowLeft/> <span className="small font-weight-bold">{label}</span></a> {dropdown}
      </div>
      {breadcrumb ? <div className="col-12 col-md text-right">
        {breadcrumb}
      </div> : null}
    </div>
  }


  return (
    <div className={`row ${pt} ${pb}`}>
      <div className="col-12 col-md-auto">
        <InternalLink to={to}><span className="small font-weight-bold"><FaArrowLeft/> {label}</span></InternalLink> {dropdown}
      </div>
      {breadcrumb ? <div className="col-12 col-md text-right">
        {breadcrumb}
      </div> : null}
    </div>
  )
}
