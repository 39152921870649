import React from 'react'
import { useTranslate } from '../../context/lang'

export const validatePassword = (password) => {
  return password.length >= 8
}

export default function PasswordHelper({password}) {
  const translate = useTranslate()

  const check_length = password.length >= 8

  if (check_length) {
    return null
  }
  return (
    <div className={"mt-2"}>{translate({
        fr: `Le mot de passe doit être constitué de :`,
        en: `The password must have:`
    })}
      <ul>
        <li className={`${check_length ? 'green':''}`}>{translate({
            fr: `Minimum 8 caractères`,
            en: `Have a length of at least 8 characters`
        })}</li>
      </ul>
    </div>
  )
}
