import React from 'react'

import './button.css'

export default function Button({ children, className = '', onClick, onMouseOver, disabled, button = 'primary', outline = false, style = {}, mb='mb-1' }) {
  return (
    <button className={`${mb} btn btn-${outline ? 'outline-' : ''}${button} ${className}`} disabled={disabled} style={style}
            onMouseOver={() => onMouseOver && onMouseOver()}
            onClick={(e) => {
              e.preventDefault()
              if (!disabled && onClick) {
                onClick(e)
              }
            }}>{children}</button>
  )
}


export const IconLabel = ({icon, children})=> {

  return <><span className="mr-3">{icon}</span><span>{children}</span></>
}
