const keep_not_null_values = (object) => {
  if (!object) {
    return {}
  }
  return Object.keys(object).reduce((not_null_values, key) => {
    if (object[ key ] !== null) {
      not_null_values[ key ] = object[ key ]
    }
    return not_null_values
  }, {})
}

export const apply_overrides_to_object = (object, overrides) => {
  if (!overrides || !object) {
    return object
  }

  const override = overrides.find(o => o.object_id === object.object_id)
  if (override) {
    return {
      ...object,
      ...keep_not_null_values(override),
      props: {
        ...object.props,
        ...keep_not_null_values(override.props),
      },
    }
  }

  return object
}

export const apply_overrides = (objects, overrides) => {
  if (!overrides) {
    return objects
  }

  return objects.map((object) => {
    return apply_overrides_to_object(object, overrides)
  })
}
