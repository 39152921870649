import React from 'react'
import IntellifoxModal from '../../components/generic/modal'
import { ConfirmLaunch, LaunchSteps, SelectProduct } from './magic_button'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { useAddVariation } from './edit_product_global_properties'
import { fetch_product } from '../../graphql/fetcher/product'
import { useTranslate } from '../../context/lang'

export default function DeleteProducts({ selected_product_ids, refresh_product }) {
  const company_id = useCompanyId()
  const translate = useTranslate()


  const [update_product] = useMutation(UPDATE_PRODUCT)

  const delete_products = async () => {
    await Promise.all(selected_product_ids.map(async (product_id) => {

      await await_mutate(update_product, {
        variables: {
          company_id,
          product_id,

          state: 'deleted',
        },
      })
    }))

    await refresh_product()
  }


  return (
    <IntellifoxModal action_label={translate({
      fr: 'Déplacer les produits vers la corbeille',
      en: 'Move products to trash',
    })} on_click={delete_products} title={translate({
      fr: 'Déplacer les produits vers la corbeille',
      en: 'Move products to trash',
    })} button_props={{ button: 'danger' }}>
      {translate({
        fr: `Déplacer vers la corbeille`,
        en: `Move to trash`,
      })}
    </IntellifoxModal>
  )
}
