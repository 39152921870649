import { create_query } from './_query_and_mutations_templates'

export const shareable_results = {
  query: 'shareable_results',
  params: {
    company_id: 'ID!',
  },
  output : `{
    shareable_result_id
    first_name
    lang
    image_url
    share_url
    duration
    start
  }`
}
