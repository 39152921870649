import React from 'react'
import { apply_variables, remove_html } from './text-substitution'
import { ShowProgress } from '../onboarding/onboarding'
import { Toggle } from '../product_v2/edit_texts'
import { IoIosArrowForward } from 'react-icons/all'
import { useFrench, useTranslate } from '../../context/lang'
import { Tooltip } from 'react-tippy'
import { useCachedContext } from '../../hooks/useCachedContext'
import './progress.css'
import Item from '../../components/generic/item'
import Button from '../../components/generic/button'
import { useProduct } from '../../context/products'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'

const percent = (value, parent_value, variables, target, transform_value = (v) => v) => {
  if (!value && !parent_value) {
    return 0
  }

  if (value && value.length > 0) {
    return Math.min(100, 100 * apply_variables(transform_value(value), variables).length / target)
  }

  if (parent_value) {
    return Math.min(100, 100 * apply_variables(transform_value(parent_value), variables).length / target)
  }

  return 0
}

const join = (array, map = (e) => e) => {
  if (!array) {
    return ''
  }

  return array.map(map).join('')
}

const filter = (array, field, val = true) => {
  if (!array) {
    return []
  }
  return array.filter(obj => obj[ field ] === val)
}

const publish_progress = (product_info) => percent(product_info.updates.filter(({
                                                                                  value,
                                                                                  previous_value,
                                                                                }) => value === previous_value), undefined, undefined, product_info.updates.length)
const is_publishable = (product) => product.asin && product.sku

export const compute_progress = (product, variables = [], parent_product = { product_info: {} }, is_variation, product_images) => {
  const { product_info } = product
  const parent_product_info = parent_product.product_info


  const variation_publish_state = ( product.variations || [] ).reduce((state, variation) => {
    if (!is_publishable(variation)) {
      return state
    }
    return {
      total: state.total + publish_progress(variation.product_info),
      count: state.count + 1,
    }
  }, {
    total: 0,
    count: 0,
  })

  const variation_publish_average = variation_publish_state.count > 0 ? ( variation_publish_state.total / variation_publish_state.count ) : 0

  const publish_total = is_publishable(product) ?
    ( publish_progress(product_info) + variation_publish_state.total ) / ( variation_publish_state.count + 1 )
    : variation_publish_average

  const progress = {
    dimensions:
      avg(
        percent(product.packaging_length, parent_product.packaging_length, variables, 1),
        percent(product.packaging_width, parent_product.packaging_width, variables, 1),
        percent(product.packaging_height, parent_product.packaging_height, variables, 1),
      ),
    keywords:
      percent(product_info.keywords, parent_product_info.keywords, variables, 1),
    variation:
      percent(product_info.variation, parent_product_info.variation, variables, 1),
    selected_keywords:
      percent(
        filter(product_info.selected_keywords, 'selected'),
        filter(parent_product_info.selected_keywords, 'selected'),
        variables, 50),
    title:
      percent(product_info.title, parent_product_info.title, variables, 180, remove_html),
    key_points:
      percent(join(product_info.key_points, remove_html), join(parent_product_info.key_points, remove_html), variables, 1000),
    description:
      percent(product_info.description, parent_product_info.description, variables, 1800),

    price: product_info.standard_price > 0 ? 100 : 0,

    publish: publish_total,
    images:
      avg(
        percent(product_images.packaging_front, undefined, variables, 1),
        percent(product_images.packaging_back, undefined, variables, 1),
        percent(product_images.product_shots, undefined, variables, 2),
      ),
    visuals:
      percent(product_info.visuals, parent_product_info.visuals, variables, 10),
  }

  progress.average = average_progress(progress, is_variation)

  return progress
}

const badge = (val) => {
  if (val >= 90) return 'success'
  if (val >= 50) return 'warning'
  return 'danger'
}

export const ProgressBadge = ({ val, className = '' }) => {
  if (typeof val !== 'number') {
    return null
  }
  let has_decimal = false
  if (val <= 99) {
    has_decimal = val % 10 !== 0
  }
  if (val <= 2) {
    val = 0
    has_decimal = false
  }

  return <span
    className={`p-1 font-weight-bold progress_badge progress_badge-${badge(val)} ${className}`}>
    {!has_decimal ? Math.round(val) / 10 : ( val / 10 ).toFixed(1)}/10
  </span>
}

export const ProductProgressBadge = ({ product_id, market_place }) => {
  const product = useProduct(product_id, market_place)
  const [average, details] = useProgressDetails(product)

  if (product.loading) {
    return <DefaultLoading/>
  }

  if (product.error) {
    return <DefaultError/>
  }

  return <ProgressBadge val={average}/>
}


export const useProgressDetails = (product) => {

  const french = useFrench()

  if (product.loading || product.error) {
    return [null, null]
  }

  if (!product.progress) {
    return [null, null]
  }

  const {
    search_terms,
    competitors,
    selected_keywords,
    applied_keywords,
    visuals,
    price,
    average,
  } = product.progress


  const progresses = [
    {
      label: french(`Requête cible, mots-clés et concurrents`),
      component: 'search_term_init',

      progresses: [
        { search_term_init_step: 2, progress: search_terms, label: french(`Configurer la requête cible`) },
        { search_term_init_step: 3, progress: competitors, label: french(`Sélectionner 10 concurrents`) },
        { search_term_init_step: 4, progress: selected_keywords, label: french(`Sélectionner 100 mots-clés`) },
      ],

      start_here: search_terms === 0,
    },
    {
      label: french(`Optimisation des textes`),
      menu: 'texts',
      progresses: [
        { progress: applied_keywords, label: french(`Intégrer 100 mots-clés dans les textes`) },
      ],
      outline: search_terms === 0,
    },
    {
      label: french(`Optimisation des images`),
      menu: 'images',
      progresses: [
        { progress: visuals, label: french(`Mettre en place 7 images`) },
      ],
      outline: search_terms === 0,
    },
    {
      label: french(`Optimisation du prix`),
      menu: 'price',
      progresses: [
        { progress: price, label: french(`Définir le prix`) },
      ],
      outline: search_terms === 0,
    },
  ]

  const details = progresses.map(({ progresses, ...whatever }) => {
    const total = progresses.reduce((total, { progress, label }) => {
      return total + progress
    }, 0)

    return {
      ...whatever,
      progresses,
      average: total / progresses.length,
    }

  })

  return [average, details]


}

export const ShowGlobalProgress = ({ product_id, market_place, min_progress = 0, show_tooltip }) => {
  const translate = useTranslate()

  const product = useCachedContext('product_progress', product_id, market_place)


  React.useEffect(() => {
    product.refresh()
  }, [])

  if (product.loading) {
    return null
  }

  if (product.error) {
    return null
  }

  return <ShowGlobalProgressWithProductLoaded product={product} show_tooltip={show_tooltip}
                                              min_progress={min_progress}/>
}


export const ShowGlobalProgressWithProductLoaded = ({ product, show_tooltip = true, min_progress }) => {
  const [average, details] = useProgressDetails(product)

  if (average < min_progress) {
    return null
  }

  const badge = <ProgressBadge val={average}/>
  if (show_tooltip) {
    return <Tooltip arrow={true} html={<>
      {details.map(({ label, average, progresses }, i) => {
        return <div className="text-right mb-3" key={i}>
          <div>
            <span className="font-weight-bold">{label}</span> <ProgressBadge val={average}/>
          </div>
          {progresses.map(({ label, progress }, i) => {
            return <div className="small mt-1" key={i}>
              {label} <ProgressBadge val={progress}/>
            </div>
          })}
        </div>
      })}
    </>}>
      {badge}
    </Tooltip>
  }

  return badge

}
export const GlobalProgressButton = ({ product, className }) => {
  const [average, details] = useProgressDetails(product)

  const [show_tooltip, set_show_tooltip] = React.useState(false)

  return <Tooltip arrow={true} open={show_tooltip} html={<>
    {details.map(({ label, average, progresses }, i) => {
      return <div className="text-right mb-3" key={i}>
        <div>
          <span className="font-weight-bold">{label}</span> <ProgressBadge val={average}/>
        </div>
        {progresses.map(({ label, progress }, i) => {
          return <div className="small mt-1" key={i}>
            {label} <ProgressBadge val={progress}/>
          </div>
        })}
      </div>
    })}
  </>}>
    <Button button={badge(average)} className={className}
            onClick={() => set_show_tooltip(!show_tooltip)}>{average.toFixed(0)}&nbsp;%</Button>
  </Tooltip>
}
const average_progress = (progress, is_variation) => {
  let i = 0
  const total = Object.keys(progress).reduce((total, key) => {
    if (!is_variation && key === 'variation') {
      return total
    }
    if (is_variation && key === 'keywords') {
      return total
    }
    if (is_variation && key === 'selected_keywords') {
      return total
    }
    i++
    return total + Math.min(progress[ key ], 100)
  }, 0)
  return total / i
}


export const avg = (...values) => values.reduce((total, val) => total + val, 0) / values.length
