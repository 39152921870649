import React from 'react'
import Browse_Content, {
  Browse_Content_Picker,
  Browse_Content_Picker_Item, Browse_Content_Section,
  Browse_Content_SubTitle,
  Browse_Content_Title,
} from './browse__content'
import { DomRenderer } from '../renderer/dom_renderer'
import { useDesignerContext } from '../designer_context'
import { useTranslate } from '../../../context/lang'
import BrowseShapes from './browse_shapes'

export const ObjectSelectorNeutral = ({ object, margin = 0, width = 95, on_click }) => {
  const ratio = object.width / object.height

  return <Browse_Content_Picker_Item
    onClick={on_click}>
    <DomRenderer width={width} height={width / ratio} background="transparent"
                 objects={[{
                   ...object,
                   width: width - margin * 2,
                   height: ( width / ratio ) - margin * 2,
                   x: margin,
                   y: margin,
                 }]}/>
  </Browse_Content_Picker_Item>
}

export const ObjectSelector = ({ object, margin = 0, width = 95 }) => {
  const { add_object } = useDesignerContext()

  const ratio = object.width / object.height

  return <Browse_Content_Picker_Item
    onClick={() => add_object({ ...object, x: 100, y: 100 })}>
    <DomRenderer width={width} height={width / ratio} background="transparent"
                 objects={[{
                   ...object,
                   width: width - margin * 2,
                   height: ( width / ratio ) - margin * 2,
                   x: margin,
                   y: margin,
                 }]}/>
  </Browse_Content_Picker_Item>
}

const Rectangles = ({ color }) => {

  const rectangle1 = {
    type: 'rect',
    x: 0,
    y: 0,
    width: 250,
    height: 150,
    angle: 0,
    props: { fill: color, opacity: 1 },
  }
  const rectangle2 = {
    type: 'rect',
    x: 0,
    y: 0,
    width: 250,
    height: 150,
    angle: 0,
    props: { fill: color, opacity: .6 },
  }
  const rectangle3 = {
    type: 'rect',
    x: 0,
    y: 0,
    width: 250,
    height: 150,
    angle: 0,
    props: { fill: color, opacity: .3 },
  }


  return <>
    <ObjectSelector object={rectangle1}/>
    <ObjectSelector object={rectangle2}/>
    <ObjectSelector object={rectangle3}/>
  </>
}

export default function BrowseElements({}) {
  const translate = useTranslate()

  const book_mockup = {
    'type': 'book',
    'width': 250,
    'height': 350,
    'props': {
      'image_id': 'pixabay:715588',
      'book_properties': {
        'angle_value': 30,
        'pages_value': 400,
      },
      'filters': {
        drop_shadow_x: 0,
        drop_shadow_y: 10,
        drop_shadow_spread: 10,
        drop_shadow_color: 'black',
        drop_shadow: true,
      },
    },
    'angle': 0,
  }
  const french_flag = {
    'type': 'image',
    'width': 300,
    'height': 200,
    'props': { 'image_id': 'pixabay:28463' },
    'angle': 0,
  }
  const euro_stars = {
    'type': 'image',
    'x': 138.95071542130367,
    'y': 149.2845786963434,
    'width': 201.36339800734135,
    'height': 200,
    'props': { 'image_id': 'pixabay:38740' },
  }
  const germany = {
    'type': 'image',
    'x': 88.07631160572339,
    'y': 143.7201907790143,
    'width': 283.75286041189935,
    'height': 200,
    'props': { 'image_id': 'pixabay:2888541' },
  }

  const bubble1 = {
    'type': 'image',
    'width': 210.29572836801754,
    'height': 200,
    'props': { 'image_id': 'pixabay:25916' },
  }
  const bubble2 = {
    'type': 'image',
    'x': 100,
    'y': 100,
    'width': 223.7762237762238,
    'height': 200,
    'props': { 'image_id': 'pixabay:25913' },
  }
  const bubble3 = {
    'type': 'image',
    'x': 100,
    'y': 100,
    'width': 213.45191773207338,
    'height': 200,
    'props': { 'image_id': 'pixabay:25904' },
  }
  const bubble4 = {
    'type': 'image',
    'x': 22.893481717011127,
    'y': 247.05882352941174,
    'width': 212.27197346600332,
    'height': 200,
    'props': { 'image_id': 'pixabay:25917' },
  }
  const bubble5 = {
    'type': 'image',
    'x': 100,
    'y': 100,
    'width': 212.74238227146816,
    'height': 200,
    'props': { 'image_id': 'pixabay:25905' },
  }


  const arrow = [{
    'type': 'arrow',
    'width': 100,
    'height': 100,
    'props': {
      'start_x': 16.950959488272936,
      'start_y': 17.484008528784486,
      'start_type': 'none',
      'end_x': 64.41364605543707,
      'end_y': 86.92963752665243,
      'end_type': 'arrow1',
      'stroke_width_value': 3,
      'fill': 'A',
    },
    'x': 100,
    'y': 100,
    'object_id': '8',
  }]

  const arrow2 = [{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"point","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"arrow1","stroke_width_value":3,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const arrow3=[{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"arrow1","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"arrow1","stroke_width_value":3,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const measure = [{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"measure","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"measure","stroke_width_value":3,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const line=[{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"none","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"none","stroke_width_value":3,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const line2=[{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"none","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"none","stroke_width_value":5,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const line3=[{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"none","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"none","stroke_width_value":8,"fill":"A"},"x":100,"y":100,"object_id":"8"}]
  const line4=[{"type":"arrow","width":100,"height":100,"props":{"start_x":16.950959488272936,"start_y":17.484008528784486,"start_type":"none","end_x":64.41364605543707,"end_y":86.92963752665243,"end_type":"none","stroke_width_value":13,"fill":"A"},"x":100,"y":100,"object_id":"8"}]

  return (
    <Browse_Content>
      <Browse_Content_Title>{translate({
        fr: `Éléments`,
        en: `Elements`,
      })}</Browse_Content_Title>
      <Browse_Content_SubTitle>{translate({
          fr: `Flèches`,
          en: `Arrows`
      })}</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <ObjectSelector object={arrow[0]} margin={10} width={120}/>
        <ObjectSelector object={arrow2[0]} margin={10} width={120}/>
        <ObjectSelector object={arrow3[0]} margin={10} width={120}/>
        <ObjectSelector object={measure[ 0 ]} margin={10} width={120}/>
      </Browse_Content_Picker>
      <Browse_Content_SubTitle>{translate({
          fr: `Ligne`,
          en: `Lines`
      })}</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <ObjectSelector object={line[0]} margin={10} width={120}/>
        <ObjectSelector object={line2[0]} margin={10} width={120}/>
        <ObjectSelector object={line3[0]} margin={10} width={120}/>
        <ObjectSelector object={line4[0]} margin={10} width={120}/>
      </Browse_Content_Picker>
      <Browse_Content_SubTitle>Mockup</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <ObjectSelector object={book_mockup} margin={10} width={120}/>
      </Browse_Content_Picker>
      <Browse_Content_SubTitle>Rectangles</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <Rectangles color={'black'}/>
        <Rectangles color={'green'}/>
        <Rectangles color={'blue'}/>
        <Rectangles color={'red'}/>
        <Rectangles color={'yellow'}/>
        <Rectangles color={'teal'}/>
      </Browse_Content_Picker>
      <Browse_Content_SubTitle>{translate({
        fr: `Formes`,
        en: `Shapes`,
      })}</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <BrowseShapes/>
      </Browse_Content_Picker>

      <Browse_Content_SubTitle>{translate({
        fr: `Éléments prédéfinis`,
        en: `Predefined elements`,
      })}</Browse_Content_SubTitle>
      <Browse_Content_Picker>
        <ObjectSelector object={french_flag}/>
        <ObjectSelector object={germany}/>
        <ObjectSelector object={euro_stars}/>

        <ObjectSelector object={bubble1}/>
        <ObjectSelector object={bubble2}/>
        <ObjectSelector object={bubble3}/>
        <ObjectSelector object={bubble4}/>
        <ObjectSelector object={bubble5}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 226.8163024217366,
          'height': 200,
          'props': { 'image_id': 'pixabay:304093' },
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 200,
          'height': 200,
          'props': { 'image_id': 'pixabay:2438744' },
          'object_id': '1',
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 200,
          'height': 200,
          'props': { 'image_id': 'pixabay:2402086' },
          'object_id': '1',
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 200,
          'height': 200,
          'props': { 'image_id': 'pixabay:2445095' },
          'object_id': '1',
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 200,
          'height': 200,
          'props': { 'image_id': 'pixabay:2402087' },
          'object_id': '1',
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 175.7083241818581,
          'height': 200,
          'props': { 'image_id': 'pixabay:2452644' },
          'object_id': '1',
        }}/>
        <ObjectSelector object={{
          'type': 'image',
          'x': 100,
          'y': 100,
          'width': 200,
          'height': 200,
          'props': { 'image_id': 'pixabay:1917228' },
          'object_id': '1',
        }}/>

      </Browse_Content_Picker>
    </Browse_Content>
  )
}
