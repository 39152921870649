import React, { useEffect, useRef, useState } from 'react'

import './text-area.css'
import RichTextEditor from 'react-rte'
import { apply_variables, clean_for_label } from '../../routes/product/text-substitution'
import KeywordHighlighter from './keyword-highlighter'
import { useFrench, useTranslate } from '../../context/lang'
import Item from './item'
import moment from 'moment'
import TextButton from './text_button'
import Button from './button'
import CopyButton from './copy-button'
import { MdContentCopy } from 'react-icons/all'

let global_textarea_id = 0

export const TextAreaV2 = (props) => {
  let {
    type,
    value,
    setter,
    help,
    placeholder,
    label,
    validate = () => true,
    trim = false,
    edit = true,
    col = '12',
    parent_value,
    margin_bottom = 'mb-3',
    need_to_be_filled,
    step = '0.01',
    lock = false,
    style = {},
    hidden,
    disabled,
    presets,
    copy_button,
    on_enter,
    unit,
    is_html_authorized,
  } = props

  const [is_locked, set_is_locked] = React.useState(lock)

  const translate = useTranslate()
  const french = useFrench()

  const [field_id] = useState(global_textarea_id)
  global_textarea_id = global_textarea_id + 1

  if (value && !is_html_authorized) {
    value = value.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')
  }

  return (
    <>
      <div className={`${margin_bottom} col-12 col-md-${col} field`}>
        {label ? <label htmlFor={`field_textarea_v2_${field_id}`}>{label}</label> : null}

          <div className="row align-items-center texteditor-container">
            <div className="col">
              {!is_locked ?
                <>
                  <textarea id={`field_textarea_v2_${field_id}`}
                            className={`input-global-colors ${lock ? 'w-75' : 'w-100'} ${( validate(value) ? 'valid' : 'invalid' )} ${!value && parent_value ? 'fade-color' : ''} ${need_to_be_filled && !value ? 'need_to_be_filled' : ''}`}
                            disabled={disabled}
                            value={type === 'date' && ( value || parent_value ) ? moment(value || parent_value).format('YYYY-MM-DD') : ( value || parent_value )}
                            style={{
                              ...style,
                              height: 200,
                            }}
                            placeholder={placeholder}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.keyCode === 13) {
                                if (lock) {
                                  set_is_locked(true)
                                }
                                if (on_enter) {
                                  on_enter()
                                }
                              }
                            }}
                            onChange={(e) => {

                              return setter(( trim ? e.target.value.trim() : e.target.value ).replace(/\n/g, ''))
                            }}/>
                </> :
                <div style={{ padding: 10, paddingLeft: 0 }}
                     className="font-weight-bold">{clean_for_label(value, 200, [])}
                </div>}

            </div>

            {unit ? <div className="col-auto font-weight-bold">
              {unit}
            </div> : null}
            {value && parent_value ? <div className="col-12">
              <TextButton className={'btn-sm mt-1'}
                          onClick={() => setter(0)}>{french(`Réinitialiser à la valeur du produit parent`)}</TextButton>
            </div> : null}
            {presets ? <div className="col-12">
              {presets(setter)}
            </div> : null}

            {lock ?
              <div className="col-12">
                <Button onClick={() => set_is_locked(!is_locked)} className={'btn-sm mt-1'}>{translate({
                  fr: `Modifier`,
                  en: `Edit`,
                })}</Button>
              </div> :

              null
            }
            {copy_button ? <div className="col-12 mt-1 mb-1">
              <CopyButton text_to_copy={value && value.trim ? value.trim() : value}
                          className={'btn-sm'}><MdContentCopy/> {translate({
                fr: `Copier`,
                en: `Copy`,
              })}</CopyButton>
            </div> : null}

            {help ? <div className={`col-12 small`}>
              {help}
            </div> : null}
          </div>
      </div>

    </>
  )
}
