import React from 'react'
import Item from '../../../components/generic/item'
import Button from '../../../components/generic/button'
import useMarketPlace, { MarketPlaceDetails } from '../../../hooks/useMarketPlace'
import { useFrench } from '../../../context/lang'
import { MdCreateNewFolder, MdImportExport, MdTranslate } from 'react-icons/all'
import ProductTranslater from '../i18n/product_translater_modal'

export default function ProductStarter({ product }) {
  const market_place = useMarketPlace()
  const french = useFrench()

  const offer_quick_start_options = !product.product_info.title

  const [show_modal, set_show_modal] = React.useState(offer_quick_start_options)

  if (!offer_quick_start_options) {
    return null
  }

  return <ProductTranslater product={product} show_modal={show_modal} close={() => set_show_modal(false)} />
}
