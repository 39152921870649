import React from 'react'


export const Option_Content_Title = ({ children }) => {
  return <div className="row pt-2">
    <div className="col-12 h4">
      {children}
    </div>
  </div>
}
export const Option_Content_SubTitle = ({ children }) => {
  return <div className="row pt-2">
    <div className="col-12 h5">
      {children}
    </div>
  </div>
}
export const Option_Content_Picker = ({ children }) => {
  return <div className="row no-gutters pb-2">
    {children}
  </div>
}

export const Option_Content_Picker_Item = ({ children }) => {
  return <div className="col-auto mr-2" >
    {children}
  </div>
}

export const Option_Content_Section = ({children}) => {
  return <div className="row pb-3">
    <div className="col-12">
      {children}
    </div>
  </div>
}

export default function Option_Content({ children, minHeight='100%' }) {
  return (
    <div className="container" style={{ minHeight, background: 'var(--color-bg-global-darker2)'}}>
      {children}
    </div>
  )
}
