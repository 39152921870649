import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { keyword_category_to_button, KeywordSelector } from '../../components/generic/keyword-chooser'
import { useMutation } from '@apollo/client'
import { SELECT_KEYWORD, SELECT_KEYWORDS } from '../../graphql/queries/keyword'
import useCompanyId from '../../hooks/useCompanyId'
import { ADD_COMPETITOR } from '../../graphql/queries/competitors'
import IntellifoxModal from '../../components/generic/modal'
import { await_mutate } from '../../graphql/mutate_promise'
import { AddKeywords } from './edit_keywords'
import { TutorialStep } from '../onboarding/onboarding'
import useMarketPlace, { get_marketplace, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import ProgressBar from './progress_bar'
import Button from '../../components/generic/button'
import { useAddWordsToTranslate, useKeywordTranslate, useTranslationsStore } from '../../context/translations'
import { useTranslate } from '../../context/lang'

const ResetKeywords = ({ select_keywords, keywords }) => {
  const translate = useTranslate()

  return <IntellifoxModal title={translate({
    fr: 'Remettre à zéro les mots clés',
    en: 'Reset keywords',
  })}
                          action_label={translate({
                            fr: 'Confirmer',
                            en: 'Confirm',
                          })}
                          action_in_progress_label={translate({
                            fr: 'En cours...',
                            en: 'In progress...',
                          })}
                          on_click={async () => {

                              try {
                                await select_keywords({
                                  keywords: keywords.map(({keyword, intensity, category}) => {
                                    return {
                                      keyword, intensity, category,
                                      selected: false
                                    }
                                  })
                                })
                              } catch (e) {
                                throw e
                              }
                          }
                          }
                          button_props={{
                            outline: true,
                            button: 'danger',
                          }}>
    {translate({
      fr: 'Remettre à zéro les mots clés',
      en: 'Reset keywords',
    })}
  </IntellifoxModal>


}

const SelectAllSuggestedKeywords = ({ product, market_place, keywords, select_keywords, category }) => {
  const translate = useTranslate()

  return <IntellifoxModal title={translate({
    fr: 'Sélectionner tous les mots clés de cette catégorie',
    en: 'Select all keywords for this category',
  })}
                          action_label={translate({
                            fr: 'Confirmer',
                            en: 'Confirm',
                          })}
                          action_in_progress_label={translate({
                            fr: 'En cours...',
                            en: 'In progress...',
                          })}
                          on_click={async () => {
                            try {
                              await select_keywords({
                                keywords: keywords
                                  .filter(({ found_in_brand }) => !found_in_brand)
                                  .map(({ keyword, intensity }) => {
                                    return {
                                      keyword, intensity,
                                      selected: true,
                                      category,
                                    }
                                  }),
                                selected: true,
                                refresh: true,
                              })
                            } catch (e) {
                              throw e
                            }
                          }
                          }
                          button_props={{
                            outline: true,
                            className: 'btn-sm',
                            button: keyword_category_to_button(category),
                          }}>
    {translate({
      fr: `Tout sélectionner`,
      en: `Select all`,
    })}
  </IntellifoxModal>

}


const ShowKeywords = ({ product, label, keywords, is_suggested, select, select_keywords, really_selected_keywords, category }) => {
  const market_place = useMarketPlace()
  const source_market_place = useSourceMarketPlace()

  const translate = useTranslate()

  const unselected_keywords = keywords.filter(({ keyword }) => !really_selected_keywords.find(k => k.keyword === keyword))

  const unselected_keywords_found_in_brand = unselected_keywords.filter(({ found_in_brand }) => found_in_brand)

  const are_all_keywords_selected = unselected_keywords.length === 0 && keywords.length > 0

  const add_words_to_translate = useAddWordsToTranslate()

  React.useEffect(() => {
    add_words_to_translate(keywords.map(({ keyword }) => keyword))
  }, [add_words_to_translate, keywords, market_place, source_market_place])


  return <div className="row mb-5">
    <div className="col-12">
      <div className="row">
        <div className="col">
          <h3>{keywords.length} {label}</h3>
        </div>
        {!are_all_keywords_selected ? <div className="col-auto">
          <SelectAllSuggestedKeywords product={product} market_place={market_place} keywords={keywords}
                                      select_keywords={select_keywords}
                                      category={category}/>
        </div> : null}
      </div>

      {are_all_keywords_selected ? <div className="row">
        <div className="col-12">
          {translate({
            fr: `Tous les mots-clés de cette section ont été sélectionnés.`,
            en: `Every keywords from this category have been selected.`,
          })}
        </div>
      </div> : null}

      <div className="row no-gutters">
        {unselected_keywords.map(({ keyword, intensity, found_in_brand }, i) => {
          if (found_in_brand) {
            return null
          }
          return <KeywordSelector key={keyword} keyword={keyword}
                                  intensity={intensity}
                                  select={select}
                                  suggested={is_suggested}
                                  category={category}>
          </KeywordSelector>
        })}
      </div>

      {unselected_keywords_found_in_brand.length > 0 ? <div className="row no-gutters">
        <div className="col-12">
          <hr/>
        </div>
        <div className="col-12 small mb-2">
          {translate({
            fr: `Ces mots-clés ont été détectés dans des noms de marques, à toi de juger leur pertinence :`,
            en: `These keywords have been detected in brand names, it's up to you to judge to select them or not:`,
          })}
        </div>

        {unselected_keywords_found_in_brand.map(({ keyword, intensity, found_in_brand }, i) => {
          return <KeywordSelector key={keyword} keyword={keyword}
                                  intensity={intensity}
                                  select={select}
                                  suggested={is_suggested}
                                  category={category}>
          </KeywordSelector>
        })}


      </div> : null}
    </div>
  </div>
}

const ReallySelectedKeyword = ({ select, keyword, category, intensity }) => {
  const [is_clicked, set_is_clicked] = React.useState(false)

  const translation = useKeywordTranslate(keyword)

  if (is_clicked) {
    return false
  }

  return <Button key={keyword} button={keyword_category_to_button(category)}
                 onClick={() => {
                   set_is_clicked(true)
                   select({
                     keyword,
                     intensity,
                     category,
                     selected: false,
                   })
                 }}
                 className={'btn-sm mr-1 mb-1'}>{keyword}{translation && translation !== keyword ?
    <><br/><span className="small font-weight-bold">{translation}</span></> : null}
  </Button>
}


export default function SearchTermTutorialSelectKeywords({ product, market_place }) {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const search_term_details = useCachedContext('search_term_details', market_place, product.product_info.search_terms[ 0 ])

  const selected_keywords = product.product_info.selected_keywords
  const really_selected_keywords = selected_keywords.filter(({ selected }) => selected)

  const [select_keyword] = useMutation(SELECT_KEYWORD)
  const [select_keywords] = useMutation(SELECT_KEYWORDS)
  const add_words_to_translate = useAddWordsToTranslate()

  React.useEffect(() => {
    add_words_to_translate(really_selected_keywords.map(({ keyword }) => keyword))
  }, [add_words_to_translate, really_selected_keywords])


  const select = async ({ keyword, intensity, selected, on_update, refresh = true, category }) => {
    try {
      await await_mutate(select_keyword, {
        variables: {
          company_id, product_id: product.product_id,
          market_place,
          keyword, intensity, selected, category,
        },
      })
      if (refresh) {
        await product.refresh()
      }
      on_update && on_update()
    } catch (e) {
      console.log(e)
    }
  }

  const do_select_keywords = async ({ keywords, on_update, refresh = true }) => {
    try {
      await await_mutate(select_keywords, {
        variables: {
          company_id, product_id: product.product_id,
          market_place,
          keywords,
        },
      })
      if (refresh) {
        await product.refresh()
      }
      on_update && on_update()
    } catch (e) {
      console.log(e)
    }
  }

  if (!product.product_info.search_terms || !product.product_info.search_terms[ 0 ]) {
    return <div className="row">
      <div className="col-12 font-weight-bold">
        {translate({
          fr: `Il faut renseigner une recherche dans l'étape précédente pour voir les mots-clés.`,
          en: `Define a search query in the previous step to see keyword suggestions.`,
        })}

      </div>
    </div>
  }

  if (search_term_details.loading) {
    return <ProgressBar time={60}/>
  }

  if (search_term_details.error) {
    return <DefaultError/>
  }

  const keywords_from_search_details = {}
  const [highly_suggested_keywords, other_suggested_keywords, other_keywords] = search_term_details.keywords
    .reduce(([highly_suggested_keywords, other_suggested_keywords, other_keywords], keyword_obj) => {
      const { keyword, title, bullet_point, description, found_in_brand } = keyword_obj

      keywords_from_search_details[ keyword ] = true
      if (found_in_brand) {
        if (title > 0.3) {
          highly_suggested_keywords.push(keyword_obj)
        } else {
          other_keywords.push(keyword_obj)
        }
      } else {
        if (title > 0.1) {
          highly_suggested_keywords.push(keyword_obj)
        } else if (bullet_point > 0.1) {
          other_suggested_keywords.push(keyword_obj)
        } else {
          other_keywords.push(keyword_obj)
        }
      }

      return [highly_suggested_keywords, other_suggested_keywords, other_keywords]
    }, [[], [], []])

  really_selected_keywords.forEach((keyword_obj) => {
    const { keyword, category } = keyword_obj

    if (!keywords_from_search_details[ keyword ] && category === null) {
      other_suggested_keywords.push(keyword)
    }
  })


  return (
    <div className="row">
      <div className="col-12 mb-5">
        <div className="row">
          <div className="col">
            <h5 className="text-uppercase">{translate({
              fr: `Étape 3`,
              en: `Step 3`,
            })}</h5>
            <h2>{translate({
              fr: `Quels sont les mots clés pertinents pour ton produit ?`,
              en: `What keywords match your product?`,
            })}</h2>
          </div>
          <div className="col-auto text-right">
            <AddKeywords product={product}/><br/>
            <ResetKeywords keywords={really_selected_keywords} select_keywords={do_select_keywords}/>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-8">
            <ShowKeywords product={product} select={select} select_keywords={do_select_keywords}
                          really_selected_keywords={really_selected_keywords}
                          label={translate({
                            fr: 'mots-clés fortement suggérés',
                            en: 'highly suggested keywords',
                          })} keywords={highly_suggested_keywords}
                          category={'red'}
            />
            <ShowKeywords product={product} select={select} select_keywords={do_select_keywords}
                          really_selected_keywords={really_selected_keywords}
                          label={translate({
                            fr: 'mots-clés suggérés',
                            en: 'suggested keywords',
                          })} keywords={other_suggested_keywords}
                          category={'yellow'}/>
            <ShowKeywords product={product} select={select} select_keywords={do_select_keywords}
                          really_selected_keywords={really_selected_keywords}
                          label={translate({
                            fr: 'autres mots-clés',
                            en: 'other keywords',
                          })} keywords={other_keywords}
                          category={'green'}/>
          </div>
          <div className="col-12 col-md-4">
            <div className="row">
              <div className="col-12">
                {really_selected_keywords.length} {translate({
                fr: `mots-clés sélectionnés`,
                en: `selected keywords`,
              })}
              </div>
            </div>
            {really_selected_keywords.map(({ keyword, category, intensity }) => {
              return <ReallySelectedKeyword key={keyword} keyword={keyword} category={category} intensity={intensity}
                                            select={select}/>
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
