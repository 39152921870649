import React from 'react'
import { useFrench } from '../../context/lang'
import { useCachedContext } from '../../hooks/useCachedContext'
import { MdHelp, MdHelpOutline, MdWarning } from 'react-icons/md'
import { Tooltip } from 'react-tippy'
import { IoMdInfinite, MdAdd } from 'react-icons/all'
import Button from '../generic/button'
import NavigationButton from '../generic/navigation-button'
import Item from '../generic/item'


const UsageBar = ({
                    current, limit, explanation,
                    class_name_if_below_threshold = 'bg-success',
                    class_name_if_above_threshold = 'bg-danger',
                    threshold = limit,
                  }) => {

  const is_infinite = limit === -1

  let width = ( 100 * ( current / limit ) )
  if (is_infinite) {
    limit = 9999
    threshold = 9999
    width = 100
  }
  return <div className="row position-relative">
    <div className="col-12 ">
      <div className="progress" style={{ minWidth: 120, height: 30 }}>
        <div
          className={`progress-bar rounded ${current < threshold ? class_name_if_below_threshold : class_name_if_above_threshold}`}
          role="progressbar"
          aria-valuenow={current} aria-valuemin="0" aria-valuemax={limit}
          style={{
            width: width + '%',
            fontSize: 16,
            minHeight: 30,
            minWidth: 70,
            fontWeight: 600,
          }}><b>{current.toFixed(0)} / {is_infinite ? <IoMdInfinite/> : limit}</b></div>
      </div>
      {explanation ? <div className="position-absolute" style={{ right: 21, top: -1, fontSize: 20, color: 'black' }}>
        <Tooltip html={explanation} arrow={true}>
          <MdHelp/>
        </Tooltip>
      </div> : null}

    </div>
  </div>
}

const Limit = ({ label, value, limit, explanation }) => {
  const french = useFrench()

  return <Item>
    <div>
      <div className="small text-uppercase">
        {label}
      </div>
      <div>
        <UsageBar current={value} limit={limit} explanation={explanation}/>
      </div>
      {limit === -1 ? null : <>
        <div className="mt-1">
          <NavigationButton className={'btn-sm btn-block'}
                            to={'/subscription/upgrade'}><MdAdd/> {french(`Ajouter de la capacité`)}</NavigationButton>
        </div>
      </>
      }
    </div>
  </Item>
}

export const ProductsLimit = () => {
  const french = useFrench()

  const limits = useCachedContext('limits')

  React.useEffect(() => {
    limits.refresh()
  }, [])

  if (limits.loading) {
    return null
  }

  if (limits.error) {
    return null
  }

  const { products, products_limit } = limits


  return <Limit label={french(`Usage`)} value={products} limit={products_limit} explanation={<div className="row">
    <div className="col-12">
      {french(`Chaque produit et variation est comptabilisée.`)}
    </div>
    <div className="col-12">
      {french(`Les produits qui sont dans la corbeille sont aussi comptabilisés.`)}
    </div>
  </div>}/>
}

export const AIBackgroundRemovalLimit = () => {
  const french = useFrench()

  const limits = useCachedContext('limits')

  React.useEffect(() => {
    limits.refresh()
  }, [])

  if (limits.loading) {
    return null
  }

  if (limits.error) {
    return null
  }

  const { ai_bg_removal, ai_bg_removal_limit } = limits

  return <Limit label={french(`Usage sur les 30 derniers jours`)} value={ai_bg_removal} limit={ai_bg_removal_limit}
                explanation={<div className="row">
                  <div className="col-12">
                    {french(`Cette jauge montre l'utilisation de la fonction "rendre l'image transparente" sur les trente derniers jours. Celle limitation peut être augmentée grâce au passage à un forfait supérieur ou à l'achat d'options (à venir).`)}
                  </div>
                </div>}/>
}

export const ManagedCampaignsLimit = () => {
  const french = useFrench()

  const limits = useCachedContext('limits')

  React.useEffect(() => {
    limits.refresh()
  }, [])

  if (limits.loading) {
    return null
  }

  if (limits.error) {
    return null
  }

  const { managed_campaigns, managed_campaigns_limit } = limits

  return <Limit label={french(`Usage`)} value={managed_campaigns} limit={managed_campaigns_limit}
                explanation={<div className="row">
                  <div className="col-12">
                    {french(`Cette jauge montre le nombre de campagne actuellement gérées par Intellifox. Celle limitation peut être augmentée grâce au passage à un forfait supérieur.`)}
                  </div>
                </div>}/>
}

export const KeywordsSearchLimit = () => {
  const french = useFrench()

  const limits = useCachedContext('limits')

  React.useEffect(() => {
    limits.refresh()
  }, [])

  if (limits.loading) {
    return null
  }

  if (limits.error) {
    return null
  }

  const { keywords_search, keywords_search_limit } = limits

  if (keywords_search_limit === -1) {
    return null
  }

  return <div className="row">
    <div className="col-12">
      <span className="small text-uppercase mr-2">{french(`Limite d'utilisations (période d'essai)`)}</span>
      <div className="d-inline-block" style={{minWidth: 240}}>
        <UsageBar current={keywords_search} limit={keywords_search_limit} explanation={<div className="row">
          <div className="col-12">
            {french(`Cette limite est présente pour éviter les abus. Pour bénéficier d'un plus grand nombre d'utilisations, il faut ajouter un compte Amazon ou mettre à niveau son compte.`)}
          </div>
        </div>}/>
      </div>
    </div>
    {keywords_search >= keywords_search_limit ? <div className="col-12 mt-2">
      <div className="alert alert-danger font-weight-bold">
      <MdWarning/> {french(`Tu as atteint la limite d'utilisation gratuite`)}<br/>
      {french(`Pour bénéficier de plus d'utilisations, tu as deux options :`)}
       <div>
         <NavigationButton to={'/settings'}>{french(`Ajouter un compte Amazon`)}</NavigationButton> <span className="font-weight-bold mx-3">{french(`ou`)}</span> <NavigationButton to={'/subscription/upgrade'}>{french(`Mettre à niveau mon abonnement`)}</NavigationButton>
       </div>
      </div>
    </div> : null}
  </div>

}
