import React from 'react'
import UserContext from '../../context/user'

export const DisplayUserFirstName = () => {
  const user = React.useContext(UserContext)

  return (
    <span className="text-capitalize blur_on_discreet">{user.user.first_name}</span>
  )
}

export const DisplayUserLastName = () => {
  const user = React.useContext(UserContext)

  return (
    <span className="text-capitalize">{user.user.last_name}</span>




  )
}
