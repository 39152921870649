import { ApolloClient, ApolloLink, concat, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { api_domain } from '../constants'

export const get_token = () => {
  let user = localStorage.getItem('user')
  let token = null

  if (user) {
    user = JSON.parse(user)
    token = user.token
  }

  return token
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = get_token()

  operation.setContext({
    headers: {
      authorization: token || null,
    },
  })

  return forward(operation)
})

const httpLink = createUploadLink({
  uri: `${api_domain}/api/graphql`,
})
const policy = (type, id_field = `${type.toLowerCase()}_id`) => ( {
  [ type ]: { keyFields: [id_field] },
} )
const client = new ApolloClient({
  cache: new InMemoryCache({
    ...policy('Update'),
  }),
  link: concat(authMiddleware, httpLink),
})


export default client
