import { find_market_place } from '../../hooks/useMarketPlace'
import { useLang } from '../../context/lang'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'

import React from 'react'
import { useUserCurrency } from '../subscription/upgrade'

export const convert = ({ amount, input, output, exchanges_rates }) => {
  if (input === output) {
    return amount
  }

  const rate = exchanges_rates.find((r) => r.from_currency === input && r.to_currency === output)


  if (rate && rate.rate) {
    return amount * rate.rate
  }
  return amount
}

export const sum = ({
                      order,
                      type = 'earnings',
                      avoid = ['Tax'],
                      filter_items = () => true,
                    }) => {
  return order.items.filter(filter_items).reduce((sub_total, item) => {
    return sub_total + item.events.reduce((sub_sub_total, event) => {
      return sub_sub_total + event[ type ].reduce((sub_sub_sub_total, earning) => {
        if (avoid.indexOf(earning.type) !== -1) {
          return sub_sub_sub_total
        }
        return sub_sub_sub_total + earning.amount
      }, 0)
    }, 0)
  }, 0)

}
export const format_big_number = (sum, digits = 2, locale = 'fr-FR') => {
  return sum.toLocaleString(
    locale,
    {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    },
  )
}

export const format_money = (locale, amount, currency, precision = 0) => {
  if (!currency) {
    return 0
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency, maximumFractionDigits: precision }).format(amount)
}

export const DisplayMoney = ({ value, currency }) => {
  const lang = useLang()
  let output = useUserCurrency().code
  const exchange_rate = useCachedContext('exchange_rate', currency, output)

  if (isNaN(value)) {
    return null
  }


  let rate = 1
  if (exchange_rate.loading) {
    return <DefaultLoading/>
  }

  if (exchange_rate.error) {
    output = currency
  } else {
    rate = exchange_rate.rate
  }


  return <>
    {format_money(lang, value * rate, output, 2)}
  </>
}

export const format_price = (sum, unit = '€') => {
  return `${format_big_number(sum, 2)}${unit}`
}

export const format_percentage = (percentage, precision = 0) => {
  if (!percentage || isNaN(percentage)) {
    return '0%'
  }
  return `${( percentage * 100 ).toFixed(precision)}%`
}

export const format_kilo = (num) => {
  return Math.abs(num) > 999 ? Math.sign(num) * ( ( Math.abs(num) / 1000 ).toFixed(1) ) + 'k' : Math.sign(num) * Math.abs(num)
}

export const sum_earnings = ({
                               orders,
                               output,
                               filter_items,
                               exchanges_rates,
                             }) => orders.reduce((total_earnings, order) => {
  return total_earnings + sum({ order, type: 'earnings', output, filter_items, exchanges_rates })
}, 0)


export const sum_ads_fees = ({
                               output,
                               exchanges_rates,
                               filter_items,
                               filter_ads = () => true,
                               products_ads_reports,
                             }) => {
  return -products_ads_reports.reduce((ads_fees, report) => {
    return ads_fees + report
      .filter(filter_items)
      .filter(filter_ads)
      .reduce((ads_fees, { cost, currency }) => ads_fees + convert({
        amount: cost,
        input: currency,
        output,
        exchanges_rates,
      }), 0)
  }, 0)
}

export const sum_amazon_fees = ({ orders, output, filter_items, exchanges_rates }) => {
  return orders.reduce((total_fees, order) => {
    return total_fees + sum({ order, type: 'fees', output, filter_items, exchanges_rates })
  }, 0)
}

export const sum_fees = (sum_parameters) => {
  const ads_fees = sum_ads_fees(sum_parameters)
  const amazon_fees = sum_amazon_fees(sum_parameters)
  return amazon_fees + ads_fees
}

export const sum_cash_flow = (sum_parameters) => {
  return sum_earnings(sum_parameters) + sum_fees(sum_parameters)
}

export const sum_urssaf = ({
                             orders,
                             output,
                             filter_items,
                             products_costs,
                             products_ads_reports,
                             urssaf_rates_map, exchanges_rates,
                           }) => {
  return -orders.reduce((total_urssaf, order) => {
    return total_urssaf + sum({
      order,
      type: 'earnings',
      output,
      filter_items,
      exchanges_rates,
    }) * urssaf_rates_map[ order.merchant_id ]
  }, 0)
}

export const sum_product_costs = ({
                                    orders,
                                    products_costs,
                                    exchanges_rates,
                                    output,
                                    filter_items,
                                  }) => {
  return -orders.reduce((total_products_costs, order) => {
    const { items, market_place } = order
    const market_place_details = find_market_place(market_place)
    return total_products_costs + items.filter(filter_items).reduce((total_products_costs, item, i) => {

      const product_costs = products_costs.find((i) => i.asin === item.asin && i.market_place === market_place)

      if (!product_costs) {
        return total_products_costs
      }

      return total_products_costs + convert({
        amount: item.quantity * product_costs.fixed_cost,
        input: market_place_details.currency,
        output,
        exchanges_rates,
      })
    }, 0)
  }, 0)
}

export const sum_profits = (sum_parameters) => {
  const cash_flow = sum_cash_flow(sum_parameters)
  const total_products_costs = sum_product_costs(sum_parameters)
  const total_urssaf = sum_urssaf(sum_parameters)

  return cash_flow + total_products_costs + total_urssaf
}

