import { useFrench, useLang, useTranslate } from '../../context/lang'
import useMarketPlace, { useAmazonUrls } from '../../hooks/useMarketPlace'
import useCompanyId from '../../hooks/useCompanyId'
import React from 'react'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { await_mutate } from '../../graphql/mutate_promise'
import IntellifoxModal from '../generic/modal'
import { AiFillWarning, FaAmazon, FiEdit, FiInfo, FiSave } from 'react-icons/all'
import Field from '../generic/field'
import Button from '../generic/button'
import { QuickUpdateModal } from '../product/quick_update_modal'
import {
  usePrices,
  useProductAmazonFeeConfig,
  useProductPriceConfig,
  useProductVat,
} from '../../routes/product_v2/edit_product_global_properties'
import ExternalLinkButton from '../generic/external-link-button'


export const UpdateVAT = ({ product, parent_product, market_place, is_auto_entrepreneur, is_not_vat_registered }) => {
  const french = useFrench()
  const amazon_urls = useAmazonUrls(market_place)

  const [show, set_show] = React.useState(false)

  return <>
    <Button className={'btn-sm'} outline={true} onClick={() => set_show(true)}>
      <FiEdit/> {french(`TVA`)}
    </Button>
    <QuickUpdateModal title={french(`Modifier le taux de TVA`)}
                      product={product}
                      parent_product={parent_product}
                      market_place={market_place}
                      useFields={useProductVat}
                      update_transformer={(values) => {
                        return {
                          price: JSON.stringify({
                            ...product.product_info.price,
                            ...values,
                          }),
                        }
                      }}
                      show={show} close={() => set_show(false)}
                      helper={<div className="row">
                        <div className="col-12">
                          {is_auto_entrepreneur ? <>
                            {french(`Le taux de TVA devrait être mis à zéro en mode auto-entrepreneur.`)}
                          </> : null}
                        </div>
                        <div className="col-12">
                          {french(`Le taux de TVA indiqué ici sert uniquement au calcul de marge.`)}
                        </div>
                        <div className="col-12">
                          {french(`Attention à bien modifier cette valeur sur le Seller Central directement.`)}
                          <ExternalLinkButton
                            to={amazon_urls[ market_place ].edit(product.asin, product.sku)}
                            className={'btn-sm ml-2'}><FaAmazon/> {french(`Modifier le produit sur le Seller Central`)}
                          </ExternalLinkButton>
                        </div>
                      </div>}/>
  </>
}


export const UpdateAmazonFeesManually = ({
                                           product,
                                           parent_product,
                                           market_place,
                                           is_auto_entrepreneur,
                                           is_not_vat_registered,
                                         }) => {
  const french = useFrench()

  const [show, set_show] = React.useState(false)

  return <>
    <Button className={'btn-sm'} outline={true} onClick={() => set_show(true)}>
      <FiEdit/> {french(`Modifier`)}
    </Button>
    <QuickUpdateModal title={french(`Modifier les frais d'Amazon`)}
                      product={product}
                      parent_product={parent_product}
                      market_place={market_place}
                      useFields={useProductAmazonFeeConfig}
                      update_transformer={(values) => {
                        return {
                          price: JSON.stringify({
                            ...product.product_info.price,
                            ...values,
                          }),
                        }
                      }}
                      show={show} close={() => set_show(false)}
                      helper={is_auto_entrepreneur || is_not_vat_registered ? <>
                        {french(`La TVA sur les frais Amazon est ajoutée automatiquement. Il faut entrer ici les frais HT.`)}
                      </> : null}
    />
  </>
}

export const UpdateCostV2 = ({ product, parent_product, market_place, on_update }) => {
  const french = useFrench()
  const lang = useLang()
  const [show, set_show] = React.useState(false)

  return <>
    <Button className={'btn-sm btn-block'} onClick={() => set_show(true)}>
      <FiEdit/> {french(`Modifier`)}
    </Button>
    <QuickUpdateModal title={french(`Modifier les coûts du produit`)}
                      product={product}
                      parent_product={parent_product}
                      market_place={market_place}
                      useFields={useProductPriceConfig}
                      update_transformer={(values) => {
                        return {
                          price: JSON.stringify({
                            ...product.product_info.price,
                            ...values,
                          }),
                        }
                      }}
                      on_update={on_update}
                      show={show} close={() => set_show(false)}
                      helper={<div className="row">
                        <div className="col-12">
                          {french(`Il est possible d'utiliser des formules de calcul simples.`)}
                        </div>
                        <div className="col-12">
                          {french(`Par exemple, si un des coûts vaut pour 80 unités, il est possible d'écrire :`)}
                        </div>
                        <div className="col-12">
                          <code>
                            131.24/80
                          </code>
                        </div>
                        {lang === 'fr' ? <>
                          <div className="col-12">
                            <hr/>
                          </div>
                          <div className="col-12">
                            <AiFillWarning/> {french(`Important`)}
                            <ul>
                              <li>{french(`Si tu es auto-entrepreneur, entre tous les coûts en TTC`)}</li>
                              <li>{french(`Si tu récupères la TVA, entre tous les coûts en HT`)}</li>
                            </ul>
                          </div>
                        </> : null}
                      </div>}/>
  </>
}
