import React from 'react'
import { ColorOptionSelector } from '../options/select_color'
import SingleObjectOptions from '../options/single_object_options'
import { useDesignerContext } from '../designer_context'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'
import { compute_rect_style } from '../controller/rect_controls'

export const compute_background = (compute_color, props) => {
  const { fill, gradient, gradient_color, gradient_angle } = props.props

  let background = compute_color ? compute_color(fill) : fill
  if (gradient) {
    background = `linear-gradient(${props.props.gradient_angle || 0}deg, ${background} 0%, ${compute_color ? compute_color(gradient_color) : gradient_color} 100%)`
  }
  return background
}

const PointDef = ({ id }) => {

  return <marker id={id} viewBox="0 0 6 6" refX="3" refY="3"
                 markerWidth="3" markerHeight="3"
                 stroke="none">
    <circle cx="3" cy="3" r="3"/>
  </marker>
}
const ArrowDef = ({ id }) => {

  return <marker id={id} viewBox="0 0 10 10" refX="5" refY="5"
                 markerWidth="6" markerHeight="6"
                 stroke="none"
                 orient="auto-start-reverse">
    <path d="M 0 0 L 10 5 L 0 10 z"/>
  </marker>
}
const LineDef = ({ id }) => {

  const width = 1
  const height = width * 5

  return <marker id={id} viewBox={`0 0 ${width} ${height}`} refX={0} refY={height-0.5}
                 markerWidth={width} markerHeight={height}
                 orient='auto'>
    <rect x="0" y="0" width={width} height={height}/>
  </marker>
}

const IntellifoxDef = ({ id }) => {
  return <marker id={id} viewBox="0 0 135.46666 135.46667" refX="66" refY="66"
                 markerWidth="10" markerHeight="10"
                 stroke="none">
    <path
      d="M 67.733523,21.227197 C 53.542538,25.393225 42.48908,43.349429 40.30398,51.431807 34.552378,55.298866 29.66042,61.003773 26.524468,66.36247 30.123916,66.3509 33.078412,66.494368 35.744532,68.280496 24.752748,68.808587 8.8243182,74.596876 2.3585634,86.727961 8.1709133,85.58863 11.827398,84.943567 16.929607,86.468023 14.768994,88.511698 12.12535,88.046471 8.1813186,98.317457 25.041753,89.199995 46.310123,101.08916 68.142918,109.54729 c 18.777474,7.27448 41.174362,6.661 57.870072,-5.005 10.88598,-7.916899 8.85718,-8.080382 -1.52436,-11.517619 C 95.924214,83.465507 99.85667,61.521876 88.752165,53.393953 82.57134,48.869912 76.49079,47.162805 69.885345,46.636566 78.06947,44.844999 82.019573,46.289468 86.372319,48.62274 82.777881,41.205134 79.367279,34.063239 78.905163,27.593596 c -3.995151,4.075513 -7.68769,12.850736 -9.36369,19.020949 -1.721213,-0.126113 -3.482456,-0.175092 -5.287031,-0.173936 0.547147,-7.594085 2.181941,-18.894911 3.479081,-25.213219 z m -9.471676,6.620052 C 52.716802,34.77965 48.661691,41.816103 46.46484,48.50839 l -2.489924,0.262252 C 46.649806,38.767114 53.990005,31.553911 58.261847,27.847403 Z M 78.16343,66.606712 c 5.002412,4.472978 5.003106,4.460636 10.674179,7.197738 C 78.126996,73.962187 78.071321,71.832623 78.16343,66.606712 Z"/>
  </marker>
}

const Marker = ({ type, id, stroke_width, is_start }) => {
  if (type === 'arrow1') {
    return <ArrowDef id={id}/>
  }
  if (type === 'point') {
    return <PointDef id={id}/>
  }
  if (type === 'intellifox') {
    return <IntellifoxDef id={id}/>
  }
  if (type === 'measure') {
    return <LineDef id={id} stroke_width={stroke_width} is_start={is_start}/>
  }
  return null
}

export default function Arrow({ object_id, onLoad, ...props }) {
  const { compute_color, width = 500, height = 500 } = useDesignerContext()

  const [arrow_id] = React.useState(`arrow_${Math.random().toString(36).substr(2, 16)}\``) // njsscan-ignore: node_insecure_random_generator

  const {
    start_x, start_y, start_type,
    end_x, end_y, end_type,
    stroke_width_value = 5,
    fill = 'black',
  } = props.props

  React.useEffect(() => {
    onLoad && onLoad()
  }, [])

  const start_id = `${arrow_id}_start`
  const end_id = `${arrow_id}_end`

  const color = compute_color ? compute_color(fill) : fill

  const style = compute_rect_style(props, compute_color)

  return (
    <>
      {/*<div>{start_x}, {start_y}, {end_x}, {end_y}, {start_type}, {end_type}</div>*/}
      <div className="position-absolute"
           style={{ left: 0, top: 0, width, height, filter: style.filter, opacity: style.opacity, transform: 'translateZ(0)' }}>
        <svg className="position-absolute" style={{ left: 0, top: 0, width, height }} viewBox={`0 0 ${width} ${height}`}
             width={width} height={height} stroke={color} fill={color}>
          <defs>
            <Marker id={start_id} type={start_type} stroke_width={stroke_width_value} is_start={true}/>
            <Marker id={end_id} type={end_type} stroke_width={stroke_width_value} is_start={false}/>
          </defs>

          <polyline points={`${start_x},${start_y} ${end_x},${end_y}`} fill="none" stroke={'inherit'}
                    strokeWidth={stroke_width_value}
                    markerStart={`url(#${start_id})`} markerEnd={`url(#${end_id})`}
          />
        </svg>
      </div>
    </>
  )
}

export const ArrowOptions = ({ scale, ...object }) => {
  const { set_current_option } = useDesignerContext()
  const translate = useTranslate()

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 justify-content-between">
      <div className="col-auto ">
        <div className="row align-items-center no-gutters">
          <div className="col-auto" style={{ height: 32 }}>
            <ColorOptionSelector color={object.props.fill} option={'props.fill'}/>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <OptionSelector option={'props.arrow'}>{translate({
              fr: `Flèche`,
              en: `Arrow`,
            })}</OptionSelector>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <OptionSelector option={'props.filters'}>{translate({
              fr: `Filtres`,
              en: `Filters`,
            })}</OptionSelector>
          </div>
        </div>
      </div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>


  </div>
}
