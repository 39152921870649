import React from 'react'
import { IntellifoxModalComponent } from '../../../components/generic/modal'
import useMarketPlace, {
  MarketPlaceDetails,
  MarketPlaceSelector,
  useAmazonUrls,
  useMarketPlaceDetails,
  useSourceMarketPlace,
} from '../../../hooks/useMarketPlace'
import Button from '../../../components/generic/button'
import TranslateProduct from './translate_product'
import { useProduct } from '../../../context/products'
import { get_value, useCachedContext, useContextProgrammatically } from '../../../hooks/useCachedContext'
import { compute_variables } from '../../product/text-substitution'
import { UPDATE_PRODUCT } from '../../../graphql/queries/product'
import { useMutation } from '@apollo/client'
import useCompanyId from '../../../hooks/useCompanyId'
import { await_mutate } from '../../../graphql/mutate_promise'
import { ADD_SHARED_VISUAL } from '../../../graphql/queries/visual'
import { useFrench, useTranslate } from '../../../context/lang'
import Flag from '../../../components/flag/flag'
import { FaArrowRight, FaArrowUp, MdFiberNew, MdImportExport, MdTranslate } from 'react-icons/all'
import { ShowGlobalProgress } from '../../product/progress'
import { start_process } from '../../../graphql/queries/import'
import { MdWarning } from 'react-icons/md'
import NavigationButton from '../../../components/generic/navigation-button'
import IntellifoxIcon from '../../../images/intellifox-icon'
import { EditProductGlobalProperties } from '../edit_product_global_properties'
import ExternalLink from '../../../components/generic/external-link'


const translate_product = async ({
                                   exchange_rate,
                                   context,
                                   source_product,
                                   source_lang,
                                   target_lang,
                                   target_market_place,
                                   keep,
                                 }) => {

  const get_translation = async (text) => ( await get_value(context, 'translation', source_lang, target_lang, text, keep) ).translated_text

  const [title, variation, key_point_0, key_point_1, key_point_2, key_point_3, key_points_4, description] = await Promise.all(
    [
      get_translation(source_product.product_info.title),
      get_translation(source_product.product_info.variation),
      get_translation(source_product.product_info.key_points[ 0 ]),
      get_translation(source_product.product_info.key_points[ 1 ]),
      get_translation(source_product.product_info.key_points[ 2 ]),
      get_translation(source_product.product_info.key_points[ 3 ]),
      get_translation(source_product.product_info.key_points[ 4 ]),
      get_translation(source_product.product_info.description),
    ],
  )

  const apply_rate = (price) => {
    if (!price) {
      return 0
    }
    return parseFloat(( parseFloat(price) * parseFloat(exchange_rate) ).toFixed(2))
  }

  return {
    product_id: source_product.product_id,
    market_place: target_market_place,
    title,
    variation,
    key_points: [
      key_point_0, key_point_1, key_point_2, key_point_3, key_points_4,
    ],
    description,
    standard_price: apply_rate(source_product.product_info.standard_price),
    sale_price: apply_rate(source_product.product_info.sale_price),
    sale_from_date: source_product.product_info.sale_from_date,
    sale_end_date: source_product.product_info.sale_end_date,
    concurrent_url_1: source_product.product_info.concurrent_url_1,
    concurrent_url_2: source_product.product_info.concurrent_url_2,
    concurrent_url_3: source_product.product_info.concurrent_url_3,
    concurrent_url_4: source_product.product_info.concurrent_url_4,
    concurrent_url_5: source_product.product_info.concurrent_url_5,
    price: JSON.stringify(source_product.product_info.price),
  }
}

const translate_product_and_variations = async ({
                                                  exchange_rate,
                                                  context,
                                                  source_product,
                                                  source_market_place,
                                                  source_lang,
                                                  target_lang,
                                                  target_market_place,
                                                  keep,
                                                }) => {

  const product_and_variations = [source_product]

  if (source_product.variations && source_product.variations.length > 0) {
    product_and_variations.push(...source_product.variations)
  }

  return Promise.all(product_and_variations.map(async (product_or_variation) => await translate_product({
    exchange_rate,
    context,
    source_product: product_or_variation,
    source_market_place,
    source_lang,
    target_lang,
    target_market_place,
    keep,
  })))
}


const get_visuals_to_share_with_product_and_variations = ({ source_product, target_product }) => {

  const product_and_variations = [target_product]

  if (target_product.variations && target_product.variations.length > 0) {
    product_and_variations.push(...target_product.variations)
  }

  const visuals = []
  for (let i = 0; i < source_product.product_info.visuals.length; i++) {
    visuals.push({
      visual_id: source_product.product_info.visuals[ i ].visual_id,
      index: source_product.product_info.visuals[ i ].index,
      apply_to: product_and_variations
        .filter((p) => !p.product_info.visuals || !p.product_info.visuals[ i ])
        .map((p) => p.product_id),
    })
  }
  return visuals
}

export const useKeep = (product) => {
  const snippets = useCachedContext('snippets')

  if (snippets.loading) {
    return []
  }

  if (snippets.error) {
    return []
  }


  return compute_variables(product, snippets).map(({ key }) => key)
}

export default function ProductTranslater({ show_modal, close, start_open, product, initial_choice = 'none', title }) {
  const company_id = useCompanyId()

  const target_market_place_code = useMarketPlace()
  const source_market_place_code = useSourceMarketPlace()
  const [other_source_market_place_code, set_other_source_market_place_code] = React.useState(source_market_place_code)
  const translate = useTranslate()
  const french = useFrench()

  const source_product = useProduct(product.product_id, source_market_place_code)
  const other_source_product = useProduct(product.product_id, other_source_market_place_code)
  const snippets = useCachedContext('snippets')

  const translation_context = useContextProgrammatically('translation')


  const [choice, set_choice] = React.useState(initial_choice)

  const source_market_place = useMarketPlaceDetails(source_market_place_code)
  const other_source_market_place = useMarketPlaceDetails(other_source_market_place_code)
  const target_market_place = useMarketPlaceDetails(target_market_place_code)

  const exchange_rate = useCachedContext('exchange_rate', source_market_place.currency, target_market_place.currency)
  const other_exchange_rate = useCachedContext('exchange_rate', other_source_market_place.currency, target_market_place.currency)

  const amazon_urls = useAmazonUrls()

  const [update_product] = useMutation(UPDATE_PRODUCT)
  const [add_shared_visual] = useMutation(ADD_SHARED_VISUAL)

  const [import_product, mutation] = useMutation(start_process, {
    variables: {
      company_id,
      type: 'reimport_product',
      initial_data: JSON.stringify({
        market_place: target_market_place_code,
        product_id: product.product_id,
        import_texts: true,
        import_images: 'only_if_empty',
      }),

    },
  })

  // const [import_product, mutation] = useMutation(IMPORT_PRODUCT_FROM_AMAZON, {
  //   variables: {
  //     company_id,
  //     market_place: target_market_place_code,
  //     source_market_place: source_market_place_code,
  //     product_id: product.product_id,
  //     import_texts: true,
  //     import_images: false,
  //     import_prices: true,
  //   },
  // })


  if (exchange_rate.loading || source_product.loading || snippets.loading) {
    return null
  }

  if (exchange_rate.error || source_product.error || snippets.error) {
    return null
  }


  if (!target_market_place) {
    return <div>Impossible de trouver la marketplace {target_market_place.marketplace}</div>
  }


  const keep = compute_variables(source_product, snippets).map(({ key }) => key)

  let action_in_progress_label = translate({
    fr: 'Mise à jour en cours...',
    en: 'Update in progress...',
  })
  let action_label = translate({
    fr: `Choisir une des options ci-dessus`,
    en: `Select one of the options`,
  })
  switch (choice) {
    case 'translate':
      action_in_progress_label = translate({
        fr: 'Traduction automatique en cours...',
        en: 'Automatic translation in progress...',
      })
      action_label = <>{translate({
        fr: `Lancer la traduction`,
        en: `Launch translation`,
      })} <Flag market_place={source_market_place_code}/> <FaArrowRight/> <Flag
        market_place={target_market_place_code}/> </>
      break
    case 'translate_other':
      action_in_progress_label = translate({
        fr: 'Traduction automatique en cours...',
        en: 'Automatic translation in progress...',
      })
      action_label = <>{translate({
        fr: `Lancer la traduction`,
        en: `Launch translation`,
      })}
        <Flag market_place={other_source_market_place_code}/> <FaArrowRight/> <Flag
          market_place={target_market_place_code}/>
      </>
      break
    case 'import':
      action_in_progress_label = translate({
        fr: 'Import en cours...',
        en: 'Import in progress...',
      })
      action_label = <>{translate({
        fr: `Importer depuis`,
        en: `Import from`,
      })} <MarketPlaceDetails market_place={target_market_place.market_place} show_domain={true}/> </>
      break
    case 'empty':
      action_label = translate({
        fr: `Démarrer avec une fiche vide`,
        en: `Start with an empty listing`,
      })
      break
    default:
      break
  }

  const apply_choice = async () => {

    /* const copy_images_from_source_product = async () => {

       const visuals = get_visuals_to_share_with_product_and_variations({ source_product, target_product: product })

       for (let i = 0; i < visuals.length; i++) {
         const { visual_id, index, apply_to } = visuals[ i ]

         for (let j = 0; j < apply_to.length; j++) {
           const product_id = apply_to[ j ]

           await await_mutate(add_shared_visual, {
             variables: {
               company_id,
               product_id,
               market_place: target_market_place,
               index,
               visual_id,
             },
           })
         }
       }
     }*/

    if (choice === 'translate') {
      const product_translations = await translate_product_and_variations({
        exchange_rate: exchange_rate.rate,
        context: translation_context,
        source_product,
        source_lang: source_market_place.lang,
        target_market_place: target_market_place.market_place,
        target_lang: target_market_place.lang,
        keep,
      })

      console.log(product_translations)

      await Promise.all(product_translations.map(async (product_translation) => {
        await await_mutate(update_product, {
          variables: {
            company_id,
            ...product_translation,
          },
        })
      }))

      // await copy_images_from_source_product()
      await product.refresh()

    } else if (choice === 'translate_other') {
      const product_translations = await translate_product_and_variations({
        exchange_rate: other_exchange_rate.rate,
        context: translation_context,
        source_product: other_source_product,
        source_lang: other_source_market_place.lang,
        target_market_place: target_market_place.market_place,
        target_lang: target_market_place.lang,
        keep,
      })

      console.log(product_translations)

      await Promise.all(product_translations.map(async (product_translation) => {
        await await_mutate(update_product, {
          variables: {
            company_id,
            ...product_translation,
          },
        })
      }))

      // await copy_images_from_source_product()
      await product.refresh()

    } else if (choice === 'empty') {
      await await_mutate(update_product, {
        variables: {
          company_id,
          product_id: product.product_id,
          market_place: target_market_place_code,
          title: translate({
            fr: 'À définir',
            en: 'Title to define',
          }),
        },
      })
      await product.refresh()
    } else if (choice === 'import') {
      if (!product.asin) {
        throw new Error('no asin for product')
      }
      await await_mutate(import_product)
      await product.refresh()
      setTimeout(() => {
        source_product.refresh()
        product.refresh()
      }, 1000)
      setTimeout(() => {
        source_product.refresh()
        product.refresh()
      }, 3000)
      setTimeout(() => {
        source_product.refresh()
        product.refresh()
      }, 6000)
    }
  }


  return (
    <IntellifoxModalComponent
      show_modal={show_modal}
      close={close}
      modal_size={"lg"}
      show_modal_default={start_open}
      title={<><MarketPlaceDetails market_place={target_market_place.market_place}/> : {title ? title : translate({
        fr: `Nouvelle marketplace`,
        en: `New marketplace`,
      })}  </>}
      body={<>
        <div className="row">
          {initial_choice === 'none' ?
            <div className="col-12 mb-3">
              {source_market_place.market_place !== target_market_place.market_place ? <Button className="mr-2"
                                                                                               button={choice === 'translate' || choice === 'none' ? 'primary' : 'secondary'}
                                                                                               onClick={() => set_choice('translate')}>
                <MdTranslate/> {translate({
                fr: `Traduire depuis`,
                en: `Translate from`,
              })} <MarketPlaceDetails market_place={source_market_place.market_place}/> <ShowGlobalProgress
                market_place={source_market_place.market_place}
                product_id={product.product_id}/>
              </Button> : null}
              <Button className="mr-2"
                      button={choice === 'translate_other' || choice === 'none' ? 'primary' : 'secondary'}
                      onClick={() => set_choice('translate_other')}>
                <MdTranslate/> {translate({
                fr: `Traduire depuis une autre langue`,
                en: `Translate from another language`,
              })}
              </Button>
              <Button className="mr-2"
                      button={choice === 'import' || choice === 'none' ? 'primary' : 'secondary'}
                      onClick={() => set_choice('import')} disabled={!product.asin}>
                <MdImportExport/> {translate({
                fr: `Importer depuis`,
                en: `Import from`,
              })} <MarketPlaceDetails market_place={target_market_place.market_place} show_domain={true}/>
              </Button>
              <Button className="mr-2"
                      button={choice === 'empty' || choice === 'none' ? 'primary' : 'secondary'}
                      onClick={() => set_choice('empty')}>
                <MdFiberNew/> {translate({
                fr: `Démarrer avec une fiche vide`,
                en: `Start with an empty listing`,
              })}
              </Button>
            </div>
            : null}

          <div className="col-12">
            {choice === 'translate' ? <TranslateProduct/> : null}

            {choice === 'translate_other' ? <><MarketPlaceSelector on_click={(what, e) => {
              set_other_source_market_place_code(what)
            }}
                                                                   current_market_place={other_source_market_place_code}
                                                                   show_detail={(market_place) => <ShowGlobalProgress
                                                                     market_place={market_place}
                                                                     product_id={product.product_id}/>}
            />
              <TranslateProduct/>
            </> : null}


            {choice === 'empty' ? <div className="row">

            </div> : null}

            {choice === 'import' && !product.asin ? <div className="row">
        <div className="col-12">
          <div className="alert alert-danger">
            <div className="row">
              <div className="col-12">
                <h4>
                  <IntellifoxIcon/> {french(`Intellifox a besoin de connaître l'ASIN afin d'importer la fiche produit correctement`)}
                </h4>
                <p>{french(`Il faut ajouter l'ASIN du produit ci-dessous, puis cliquer sur "Sauvegarder" en bas à droite`)}</p>
              </div>
              <div className="col-12">
                <EditProductGlobalProperties product={product} show_auto_save={false}/>
              </div>
            </div>
          </div>
        </div>
            </div> : null}

            {choice === 'import' && product.asin ?
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-success">
                    {french(`La fiche produit suivante sera importée depuis Amazon.`)}<br/>
                    <ExternalLink to={amazon_urls[ target_market_place_code ].public(product.asin)}>{french(`Voir la fiche produit`)} ASIN : {product.asin}</ExternalLink>
                    <div className="alert alert-warning">
                      <span className="small"><FaArrowUp/> {french(`En cas de problème avec l'import, vérifier que ce lien pointe bien vers la fiche produit et que celle-ci est entièrement accessible`)}</span>
                    </div>
                  </div>
                </div>
              </div> : null}

          </div>
        </div>
      </>
      }
      disable_action={choice === 'none'}
      action_in_progress_label={action_in_progress_label}
      action_label={action_label}
      on_click={apply_choice}
      button_props={{ className: 'btn-sm' }}
    />
  )
}
