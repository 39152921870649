import React from 'react'


export const Browse_Content_Title = ({ children }) => {
  return <div className="row align-items-center mb-2 pt-2" style={{ background: '#bdc8c3', height: 45 }}>
    <div className="col-12 h5 text-uppercase">
      {children}
    </div>
  </div>
}
export const Browse_Content_SubTitle = ({ children, button }) => {
  return <div className="row">
    <div className="col h5 text-ellipsis" style={{ color: 'var(--color-black)', marginBottom: 0 }}>
      {children}
    </div>
    <div className="col-auto">
      {button}
    </div>
    <div className="col-12 mb-2">
      <hr style={{
        borderTop: '2px solid var(--color-black)',
        margin: 0,
      }}/>
    </div>
  </div>
}
export const Browse_Content_Picker = ({ children }) => {
  return <div className="row no-gutters">
    {children}
  </div>
}

export const Browse_Content_Picker_Item = ({ children, onClick }) => {
  return <div className="col-auto clickable text-center" onClick={onClick} style={{marginRight: 2, marginBottom: 2}}>
    {children}
  </div>
}

export const Browse_Content_Section = ({ children, col = 12}) => {
  return <div className="row mb-3">
    <div className={`col-${col}`}>
      {children}
    </div>
  </div>
}

export default function Browse_Content({ children, background }) {
  return (
    <div className="container" style={{ minHeight: '100%', background}}>
      {children}
    </div>
  )
}
