import React from 'react'
import Browse_Content, { Browse_Content_Title } from './browse__content'
import { useDesignerContext } from '../designer_context'
import { PicturesSelector } from './pictures/picture_selector'
import Images from '../../../routes/images/images'
import { PicturesSelectorV2 } from './pictures/picture_selector_v2'
import { useTranslate } from '../../../context/lang'

export const BrowsePictures = () => {
  const { add_object } = useDesignerContext()
  const translate = useTranslate()

  return <Browse_Content>
    <Browse_Content_Title>{translate({
        fr: `Photos`,
        en: `Pictures`
    })}</Browse_Content_Title>

    <PicturesSelectorV2 on_click={({ image_id, width, height }) => add_object({
      type: 'image', x: 100, y: 100,
      width, height,
      props: { image_id },
    })} />
  </Browse_Content>
}

