import React from 'react'
import { AddAmazonAdsAccount, get_code_from_url } from '../../components/user/login_with_amazon'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { await_mutate } from '../../graphql/mutate_promise'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import useCompanyId from '../../hooks/useCompanyId'
import { navigate } from '../../absolute_link'
import Item, { CompactItem } from '../../components/generic/item'
import DeleteButton from '../../components/generic/delete-button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { FiCheck, FiDelete, FiEdit, FiSave, FiTrash, FiX } from 'react-icons/all'
import { MarketPlaceDetails, MarketPlacesTable } from '../../hooks/useMarketPlace'
import * as _ from 'lodash'
import { Checkbox } from '../../components/generic/checkbox'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import IntellifoxModal from '../../components/generic/modal'
import Field from '../../components/generic/field'

export const AmazonAdsAccounts = ({}) => {
  const amazon_ads_accounts = useCachedContext('amazon_ads_accounts')

  React.useEffect(() => {
    amazon_ads_accounts.refresh()
  }, [])

  if (amazon_ads_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_ads_accounts.error) {
    return <DefaultError/>
  }


  return <div className="row">
    {amazon_ads_accounts.length > 0 ? <div className="col-12 mb-3">
      {amazon_ads_accounts.map((amazon_ads_account) => {
        return <div className="row" key={amazon_ads_account.user_id}>
          <div className="col-12 mb-3">
            <AmazonAdsAccount amazon_ads_account={amazon_ads_account}
                              on_delete={() => amazon_ads_accounts.refresh()}
                              on_update={() => amazon_ads_accounts.refresh()}
            />
          </div>
        </div>
      })}
    </div> : null}

    <div className="col-12">
      <AddAmazonAdsAccount/>
    </div>
  </div>
}

const AmazonAdsAccount = ({ amazon_ads_account, on_delete, on_update }) => {
  const french = useFrench()
  const translate = useTranslate()
  const company_id = useCompanyId()

  const merchant_ids = _.uniq(amazon_ads_account.profiles.map(({ merchant_id }) => merchant_id))


  return <Item>
    <div className="row">
      <div className="col-12 font-weight-bold BLUR_FOR_WEBINAR">
        <h4>{amazon_ads_account.email}</h4>
        {merchant_ids.map((merchant_id) => <span className="badge badge-secondary BLUR_FOR_WEBINAR">{translate({
          fr: `Identifiant Amazon`,
          en: `Amazon ID`,
        })} {merchant_id}</span>)}
      </div>
      <div className="col-12">
        <MarketPlacesTable
          market_places={amazon_ads_account.profiles.map(({ country_code }) => country_code.toLowerCase())}
          show_vats={amazon_ads_account.is_not_vat_registered}
        />
      </div>
      <div className="col-12 col-lg-6">
        {amazon_ads_account.is_not_vat_registered ?
          <span
            className="badge badge-secondary"><FiX/> {french(`Ne récupère pas la TVA sur les dépenses publicitaires`)}</span> :
          <span
            className="badge badge-success"><FiCheck/> {french(`Récupère la TVA sur les dépenses publicitaires`)}</span>
        }
      </div>
      <div className="col-12 col-lg-6 text-right">
        <DeleteAmazonAdsAccount amazon_ads_account_id={amazon_ads_account.amazon_ads_account_id} on_delete={on_delete}/>
        <UpdateAmazonAdsAccount amazon_ads_account={amazon_ads_account} on_update={on_update}/>
      </div>
    </div>
  </Item>
}

const UpdateAmazonAdsAccount = ({ amazon_ads_account, on_update }) => {
  const french = useFrench()
  const company_id = useCompanyId()
  const translate = useTranslate()

  const [is_not_vat_registered, set_is_not_vat_registered] = React.useState(amazon_ads_account.is_not_vat_registered)

  const [update_amazon_ads_account] = useMutation(create_mutation({
    mutation: 'update_amazon_ads_account',
    params: {
      company_id: 'ID!',
      amazon_ads_account_id: 'String',
      is_not_vat_registered: 'Boolean',
    },
  }))

  const do_save = async () => {
    await await_mutate(update_amazon_ads_account, {
      variables: {
        company_id,
        amazon_ads_account_id: amazon_ads_account.amazon_ads_account_id,
        is_not_vat_registered,
      },
    })

    await on_update()
  }

  return <IntellifoxModal title={<><FiEdit/> {french(`Modifier le compte Amazon`)}</>}
                          body={<div className="row">
                            <div className="col-12">
                              <CompactItem label={french(`Options supplémentaires`)}>
                                <div className="row">
                                  <Checkbox value={is_not_vat_registered} setter={set_is_not_vat_registered}>
                                    {french(`Ne récupère pas la TVA sur les frais de publicités`)}
                                  </Checkbox>
                                </div>
                              </CompactItem>
                            </div>

                          </div>}
                          action_label={<><FiSave/> {french(`Sauvegarder`)}</>}
                          on_click={do_save}
                          button_props={{ className: 'ml-2' }}
  >
    <FiEdit/> {translate({
    fr: `Modifier les paramètres de TVA`,
    en: `Edit VAT settings`,
  })}
  </IntellifoxModal>
}

const add_amazon_ads_account_mutation = create_mutation({
  mutation: 'add_amazon_ads_account',
  params: {
    company_id: 'ID!',
    code: 'String',
  },
})

export const AddAmazonAdsAccountReturn = () => {
  const [error, set_error] = React.useState(false)
  const company_id = useCompanyId()

  const [add_amazon_ads_account] = useMutation(add_amazon_ads_account_mutation)
  React.useEffect(() => {
    const code = get_code_from_url()

    const go = async () => {
      await await_mutate(add_amazon_ads_account, {
        variables: {
          company_id,
          code,
        },
      })

      await navigate('/settings')
    }

    go().catch(e => {
      console.error(e)
      set_error(true)
    })
  }, [])

  if (error) {
    return <DefaultError/>
  }

  return <DefaultLoading/>
}


const DeleteAmazonAdsAccount = ({ amazon_ads_account_id, on_delete }) => {

  const french = useFrench()


  const company_id = useCompanyId()

  const remove_amazon_ads_account_mutation = create_mutation({
    mutation: 'remove_amazon_ads_account',
    params: {
      company_id: 'ID!',
      amazon_ads_account_id: 'String',
    },
  })

  const [remove_amazon_ads_account] = useMutation(remove_amazon_ads_account_mutation)

  const do_delete = async () => {
    await await_mutate(remove_amazon_ads_account, {
      variables: {
        company_id,
        amazon_ads_account_id,
      },
    })

    await on_delete()
  }

  return <DeleteButton onClick={do_delete}><FiTrash/> {french(`Supprimer`)}</DeleteButton>
}
