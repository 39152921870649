import { useCompany } from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import { useCachedContext } from '../../hooks/useCachedContext'
import UpdateButtonV2 from '../generic/update_button_v2'
import React, { useCallback } from 'react'
import { await_mutate } from '../../graphql/mutate_promise'

export const useMetaData = (key) => {
  const company = useCompany()

  return company.meta_data[ key ]
}

export const useUpdateMetaData = ({ meta_data_key, value }) => {
  const me = useCachedContext('me')

  const { company_id, meta_data } = useCompany()

  const [update_meta_data] = useMutation(create_mutation({
    mutation: 'update_company_meta_data',
    params: {
      company_id: 'ID!',
      meta_data: 'String',
    },
  }))

  return useCallback(async () => {
    await await_mutate(update_meta_data, {
      variables: {
        company_id,
        meta_data: JSON.stringify({
          ...meta_data,
          [ meta_data_key ]: value,
        }),
      },
    })
    await me.refresh()
  }, [company_id, me, meta_data, meta_data_key, update_meta_data, value])
}

export const useMetaDataAutoUpdate = (key, default_value) => {
  const value = useMetaData(key) || default_value
  const [intermediate_value, set_intermediate_value] = React.useState(value)
  const update_value = useUpdateMetaData({meta_data_key: key, value: intermediate_value})

  const timeout_ref = React.useRef(null)

  React.useEffect(() => {
    clearTimeout(timeout_ref.current)
    timeout_ref.current = setTimeout(() => {
      update_value()
    }, 500)
  }, [intermediate_value])

  return [intermediate_value, set_intermediate_value]
}

export const UpdateMetaData = ({ meta_data_key, value, children, outline, className }) => {

  const update_meta_data = useUpdateMetaData({
    meta_data_key, value
  })

  return <UpdateButtonV2 trigger={async () => {
    await update_meta_data()
  }} outline={outline} className={className}>{children}</UpdateButtonV2>
}

