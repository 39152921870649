import React from 'react'
import Item from '../generic/item'
import { FullScreenCentered } from '../generic/centered'
import PageTitle from '../generic/page-title'
import IntellifoxIcon from '../../images/intellifox-icon'
import PasswordHelper, { validatePassword } from './password-rule'
import { displayFields } from '../generic/field'
import Button from '../generic/button'
import bcrypt from 'bcryptjs'
import { UPDATE_RESET_PASSWORD } from '../../graphql/queries/user'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import UpdateButton from '../generic/update-button'
import { navigate } from '../../absolute_link'

export default function ResetPassword({ reset_id }) {

  const [success, set_success] = React.useState(false)
  const [in_progress, set_in_progress] = React.useState(false)
  const [error, set_error] = React.useState('')

  const [password, set_password] = React.useState('')

  const fields = [
    {
      type: 'password',
      value: password,
      setter: set_password,
      label: 'Entrez un mot de passe *',
      help: <PasswordHelper password={password}/>,
      validate: validatePassword,
    },
  ]


  const [update_reset_password] = useMutation(UPDATE_RESET_PASSWORD)

  const on_submit = async () => {
    set_error('')
    set_in_progress(true)
    const success = await await_mutate(update_reset_password, {
      variables: {
        reset_id,
        new_password_hash: password ? bcrypt.hashSync(password, bcrypt.genSaltSync(8)) : null,
      },
    })

    set_success(success.updateResetPassword)

    if (!success.updateResetPassword) {
      set_error(`Erreur lors de réinitilisation du mot de passe`)
    } else {
      navigate('/')
    }
  }
  return (
    <FullScreenCentered>
      <Item className="min-vw-md-100">
        <div className="row">
          <div className="col-12">
            <div className="row mb-3">
              <PageTitle><IntellifoxIcon/> Remise à zéro de ton mot de passe</PageTitle>
            </div>
            {displayFields(fields, on_submit)}
            <hr/>
            <div className="row">
              <div className="col-12">
                {error}
              </div>
              <div className="col-12">
                <UpdateButton onClick={() => on_submit()} loading={in_progress} error={error} data={success}>
                  Valider le nouveau mot de passe et me connecter
                </UpdateButton>
              </div>
            </div>
          </div>
        </div>
      </Item>
    </FullScreenCentered>
  )
}
