import React from 'react'
import './Pricing.css'
import useMarketPlace from '../../hooks/useMarketPlace'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import Item, { NiceNumbers } from '../generic/item'
import { format_money, format_percentage } from '../../routes/order/format_numbers'
import { FaAmazon, FiBox, FiCheck, FiEdit, FiEye, FiShoppingCart, FiX, GiReceiveMoney } from 'react-icons/all'
import { Conclusion } from '../../routes/ppc/ppc_v2/campaign_results'
import { usePrices } from '../../routes/product_v2/edit_product_global_properties'
import Button from '../generic/button'
import Flag from '../flag/flag'
import { AutoEntrepreneurRate, UpdateAmazonAccount } from '../../routes/setting/amazon_accounts'
import { compute_current_price, compute_margin_bg, useProductPricingDetails } from './pricing_formulas'
import { UpdateAmazonFees } from './update_amazon_fees'
import { UpdateAmazonFeesManually, UpdateCostV2, UpdateVAT } from './update_cost'
import { UpdatePrice } from '../product/update_price'
import { DefaultError, DefaultLoading } from '../i18n/translations'
import { DisplayVariations, ProductPresentation } from '../../routes/product_v2/product_header'
import ExploreMarginDetails, { ROI } from './margin_details'
import NavigationButton from '../generic/navigation-button'
import { FiSettings } from 'react-icons/fi'
import { useResetCachedValues } from '../../hooks/useCachedContext'
import { OnboardingVideo } from '../../routes/onboarding/onboarding_videos'


export const Pricing = ({ product, is_variation, market_place, parent_product, show_only_pricing }) => {

  const current_market_place = useMarketPlace()
  if (!market_place) {
    market_place = current_market_place
  }
  const french = useFrench()

  const details = useProductPricingDetails({
    product, market_place, price: compute_current_price(product, parent_product),
    parent_product,
  })

  const invalidate_amazon_accounts_cached_values = useResetCachedValues('amazon_accounts')

  if (details.loading) {
    return <DefaultLoading/>
  }

  if (details.error) {
    return <DefaultError/>
  }


  const {
    amazon_account,
    is_auto_entrepreneur,
    is_not_vat_registered,
  } = details

  return <div className="row">
    <div className="col-12">
      {!is_variation && !show_only_pricing ? <OnboardingVideo video_key={'profit_calculator'}/> : null}
    </div>
    <div className="col-12">
      {amazon_account && !is_variation && !show_only_pricing ? <div className="row mb-2">
        <div className="col-12 text-right">
          <UpdateAmazonAccount className={'btn-sm'} amazon_account={amazon_account}
                               button_label={<><FiSettings/> {french(`Configurer`)} {amazon_account.label}
                                 {!is_variation && is_auto_entrepreneur ?
                                   <span className="small ml-2"><FiCheck/> {french(`Auto-entrepreneur`)}</span> : null}

                                 {!is_variation && !is_auto_entrepreneur && is_not_vat_registered ?
                                   <span className="small ml-2"><FiX/> {french(`TVA non récupérable`)}</span> : null}
                               </>}
                               on_success={() => invalidate_amazon_accounts_cached_values()}/>
        </div>
      </div> : null}


      <Item className={'mb-3'}>

        <div className="row">
          <div className="col-12">
            <ProductPresentation product={product} parent_product={parent_product}/>
          </div>
        </div>

        <PricingDetails product={product} parent_product={parent_product} details={details}
                        market_place={market_place}/>
      </Item>


      {!is_variation && !show_only_pricing ?
        <DisplayVariations product={product} inherit={true} variation_mapper={(variation, parent_product) => {
          return <Pricing product={variation} is_variation={true}
                          parent_product={parent_product}/>
        }
        }/> : null}
    </div>
  </div>
}

export const PricingSimulation = ({ product, parent_product, market_place, price, roi }) => {
  const details = useProductPricingDetails({
    product, market_place, price,
    parent_product,
  })

  if (details.loading) {
    return <DefaultLoading/>
  }

  if (details.error) {
    return <DefaultError/>
  }

  return <PricingDetails details={details} product={product} parent_product={parent_product} market_place={market_place}
                         hide_actions={true} roi={roi}/>

}

export const PricingDetails = ({ details, product, parent_product, market_place, hide_actions, roi }) => {
  const lang = useLang()
  const french = useFrench()

  const {
    amazon_account,
    price,
    currency,
    margin,
    margin_percentage,
    fixed_cost,
    amazon_fees,
    commission,
    expedition,
    vat,
    is_auto_entrepreneur,
    is_not_vat_registered,
  } = details

  if (roi) {
    roi = <ROI roi={margin / fixed_cost} currency={currency}/>
  }

  return <div className="row">
    <div className="col-12 col-lg-3">
      <NiceNumbers label={<>{french(`Prix de vente sur Amazon`)} <Flag market_place={market_place}/></>}
                   icon={<FiShoppingCart/>}
                   conclusion={format_money(lang, price, currency, 2)}
                   detail={<>{french(`TVA`)} {format_percentage(vat, 2)}</>}
                   bg={compute_margin_bg(margin_percentage)}
                   action={!hide_actions ? <>
                     <div className="btn-group btn-group-sm btn-block">
                       <UpdatePrice product={product}
                                    parent_product={parent_product} market_place={market_place}/>
                       <UpdateVAT product={product}
                                  parent_product={parent_product}
                                  market_place={market_place}
                                  is_auto_entrepreneur={is_auto_entrepreneur}/>
                     </div>
                   </> : null}
      />
    </div>
    <div className="col-12 col-lg-3">
      <NiceNumbers label={french(`Coûts du produit`)}
                   icon={<FiBox/>}
                   conclusion={<>{format_money(lang, -fixed_cost, currency, 2)}</>}

                   bg={'info'}
                   action={!hide_actions ? <UpdateCostV2 product={product}
                                                         parent_product={parent_product}
                                                         market_place={market_place}
                                                         is_auto_entrepreneur={is_auto_entrepreneur}/> : null}
      />
    </div>
    <div className="col-12 col-lg-3">
      <NiceNumbers label={french(`Frais Amazon`)}
                   icon={<FaAmazon/>}
                   conclusion={<>{format_money(lang, -amazon_fees, currency, 2)}</>}
                   detail={<>
                     {format_percentage(commission, 2)}<br/>
                     {format_money(lang, expedition, currency, 2)}
                   </>}
                   bg={'warning'}
                   action={!hide_actions ? <>
                     <div className="btn-group btn-group-sm btn-block">
                       <UpdateAmazonFees product={product} merchant_id={amazon_account.merchant_id}
                                         parent_product={parent_product}
                                         currency={currency}
                                         market_place={market_place} asin={product.asin} price={price}/>
                       <UpdateAmazonFeesManually product={product}
                                                 parent_product={parent_product}
                                                 market_place={market_place}
                                                 is_auto_entrepreneur={is_auto_entrepreneur}
                                                 is_not_vat_registered={is_not_vat_registered}
                       />
                     </div>

                   </> : null}

      />
    </div>
    <div className="col-12 col-lg-3">
      <NiceNumbers label={french(`Marge`)}
                   icon={<GiReceiveMoney/>}
                   conclusion={<>{format_money(lang, margin, currency, 2)} <span
                     className="small">({format_percentage(margin_percentage, 2)})</span></>}
                   bg={compute_margin_bg(margin_percentage)}
                   action={!hide_actions ? <>
                     <ExploreMarginDetails product={product} parent_product={parent_product} details={details}
                                           market_place={market_place}/>
                   </> : null}
      />
    </div>

    {roi ? <div className="col-12 text-right">
      {roi}
    </div> : null}
  </div>
}
