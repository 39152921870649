import React from 'react'
import { useDesignerContext } from '../designer_context'
import Button from '../../generic/button'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { apply_overrides } from '../override/apply_overrides'
import { DefaultError, DefaultLoading } from '../../i18n/translations'
import { useTranslate } from '../../../context/lang'
import IntellifoxModal from '../../generic/modal'
import { DomRenderer, Scaler } from '../renderer/dom_renderer'
import { clean_for_label } from '../../../routes/product/text-substitution'
import SelectCropImage from './select_crop_image'
import { AddImageDropzone, CreateTransparentImageModal } from '../designer/pictures/product_images'


const ProductImageSelector = ({ product, is_variation }) => {
  const translate = useTranslate()

  const { get_selected_object, current_objects, update_object_several_props, update_single_object } = useDesignerContext()
  const selected_object = apply_overrides([get_selected_object()], current_objects.overrides[ product.product_id ])[ 0 ]
  const [show_create_transparent_image_modal, set_show_create_transparent_image_modal] = React.useState(false)

  const product_image_tag = `product_id:${product.product_id}`
  const images = useCachedContext('images', [product_image_tag])

  if (images.loading) {
    return <DefaultLoading/>
  }

  if (images.error) {
    return <DefaultError/>
  }


  const is_transparent = /transparent/.test(selected_object.props.image_id)

  const transparent_image_exists = is_transparent || images.find(({ image_id }) => image_id === `${selected_object.props.image_id}:transparent`)


  return <div className="row">
    <div className="col-12 font-weight-bold">
      {is_variation ? `Variation ${clean_for_label(product.product_info.variation, 50, [])}` : 'Modèle'}
    </div>
    <div className="col-auto" style={{ width: 250, overflow: 'hidden' }}>
      <Scaler scale={0.5} width={250} height={250}>
        <DomRenderer width={500} height={500} objects={[selected_object]}/>
      </Scaler>

      <div className="row mt-2">
        <div className="col-12 mb-1">
          <div className="btn-group w-100">
            <Button className={'btn-sm'} onClick={() => {
              update_single_object(
                selected_object.object_id,
                { angle: ( selected_object.angle + Math.PI / 2 ) % ( Math.PI * 2 ) },
                true,
                product.product_id,
                is_variation,
              )
            }
            }>Tourner</Button>

            <Button className={'btn-sm'} outline={true} onClick={() => {
              update_single_object(
                selected_object.object_id,
                { angle: null },
                true,
                product.product_id,
                is_variation,
              )
            }
            }>Réinitialiser</Button>
          </div>
        </div>

        <div className="col-12 mb-1">
          <div className="btn-group w-100">
            <SelectCropImage product_id={product.product_id} do_edit_overrides={is_variation}
                             button={'primary'}
                             buttonClassName={'btn-sm'}>Découper</SelectCropImage>

            <Button className={'btn-sm'} outline={true} onClick={() => {
              update_single_object(
                selected_object.object_id,
                {
                  props: {
                    ...selected_object.props,
                    crop: null,
                  },
                },
                true,
                product.product_id,
                is_variation,
              )
            }
            }>Réinitialiser</Button>
          </div>
        </div>

        <div className="col-12 mb-1">
          <div className="btn-group w-100">
            <Button className={'btn-sm'} button={is_transparent ? 'secondary' : 'primary'}
                    onClick={() => update_single_object(
                      selected_object.object_id,
                      {
                        props: {
                          ...selected_object.props,
                          image_id: selected_object.props.image_id.replace(':transparent', ''),
                        },
                      },
                      true,
                      product.product_id,
                      is_variation,
                    )}>Normal</Button>
            {transparent_image_exists ? <Button className={'btn-sm'} button={!is_transparent ? 'secondary' : 'primary'}
                                                onClick={() => update_single_object(
                                                  selected_object.object_id,
                                                  {
                                                    props: {
                                                      ...selected_object.props,
                                                      image_id: `${selected_object.props.image_id}:transparent`,
                                                    },
                                                  },
                                                  true,
                                                  product.product_id,
                                                  is_variation,
                                                )}>Transparent</Button> : <>
              <Button className={'btn-sm'} button='secondary' onClick={() => set_show_create_transparent_image_modal(true)}>Transparente</Button>
              <CreateTransparentImageModal show={show_create_transparent_image_modal}
                                           handleClose={() => set_show_create_transparent_image_modal(false)}
                                           image_id={selected_object.props.image_id} images={images} on_success={() => update_single_object(
                selected_object.object_id,
                {
                  props: {
                    ...selected_object.props,
                    image_id: `${selected_object.props.image_id}:transparent`,
                  },
                },
                true,
                product.product_id,
                is_variation,
              )}/>
            </>}
          </div>
        </div>
      </div>


    </div>
    <div className="col">
      <div className="row">
        {images.map(({ url, width, height, image_id }) => {
          // https://res.cloudinary.com/intellifox/image/upload/v1594911621/company/b77ba9a3-a001-41ee-b363-b068c018e94b/image/gMFJpDSbq-IMG_0261.JPG.jpg

          return <div className='col-auto mb-2 clickable' onClick={() => {
            update_object_several_props({
              object: selected_object,
              props: [{
                field: 'image_id',
                value: image_id,
              }],
              product_id: product.product_id,
              do_edit_overrides: is_variation,
            })
          }}>
            <img src={url.replace('original', 'resize_125x125')} alt="" style={{ width: 125 }}/>
          </div>
        })}
        <div className="col-auto">
          <AddImageDropzone tags={[product_image_tag]} on_add={() => images.refresh()}/>
        </div>
      </div>
    </div>


  </div>
}

export default function SelectVariationImages() {
  const translate = useTranslate()

  const { product } = useDesignerContext()


  return <IntellifoxModal body={<>
    <ProductImageSelector product={product} is_variation={false}/>

    {product.variations && product.variations.map((variation) => {
      return <ProductImageSelector product={variation} is_variation={true}/>
    })}

  </>} button_props={{ button: 'link' }} title={`Choisir les images pour les variations`}>
    Variations
  </IntellifoxModal>
}
