import React from 'react'
import Item from '../generic/item'
import tinycolor2 from 'tinycolor2'

export const ColorBar = ({ color, className = '', opacity = 0.4 }) => {
  return <div className="position-absolute color_background"
              style={{
                background: color,
                height: '100%',
                width: '100%',
                // zIndex: 1,
                left: 0,
                top: 0,
                opacity,
                borderRadius: '5px',
              }}></div>
}

export default function Title({ children, margin = 'mb-5', style = {}, color, actions, h = 'h1', opacity =1, details}) {

  return (
    <div className={`row ${margin} align-items-center  mt-2`}>
      <div className={`col-12 col-md  position-relative`}>
        <div className="p-3 position-relative list-item-shadow">
          <ColorBar color={color} opacity={opacity}/>
          <div className="row align-items-center" style={{...style}}>
            <div className="col-12 col-sm">
              <span className={`position-relative ${h} m-0 font-weight-bold`} style={{ zIndex: 2}}>{children}</span>
            </div>
            <div className="col-12 col-sm-auto">
              {details}
            </div>
          </div>

        </div>
      </div>
      {actions ? <div className="col-12 col-md-auto">
        {actions}
      </div> : null}
    </div>
  )
}
