export const set_2_leash = [[{
  'type': 'shape',
  'object_id': '1',
  'x': 177.22174288179468,
  'y': 62.0362381363244,
  'width': 286.06658311778176,
  'height': 286.06658311778176,
  'angle': 0,
  'props': { 'shape_id': 'rond.svg', 'fill': 'C', 'group_id': 'group_15dvotxs057' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 36.73856773080243,
  'y': 127.33390854184638,
  'width': 286.06658311778176,
  'height': 286.06658311778176,
  'angle': 0,
  'props': { 'shape_id': 'rond.svg', 'fill': 'C', 'group_id': 'group_15dvotxs057' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 188.74892148403796,
  'y': 227.5754961173425,
  'width': 215.9087679299752,
  'height': 215.90876792997523,
  'angle': 0,
  'props': { 'shape_id': 'rond.svg', 'fill': 'C', 'group_id': 'group_15dvotxs057' },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 57.377049180327845,
  'y': 72.57950172966864,
  'width': 378.2401704014789,
  'height': 378.2401704014789,
  'angle': null,
  'props': {
    'image_id': 'x75aIlhPe',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'black',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -78.30915658720328,
  'y': 145.54724946914774,
  'width': 430.2462998130701,
  'height': 430.2462998130701,
  'angle': 0,
  'props': { 'shape_id': 'rond.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 271.9671549288216,
  'y': -121.4245934190242,
  'width': 354.3409587663026,
  'height': 354.3409587663027,
  'angle': 0,
  'props': { 'shape_id': 'rond.svg', 'fill': 'C', 'group_id': 'group_lmpwa4sj2lm' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 35.34031625165383,
  'y': 49.15941172904083,
  'width': 52.69271261636786,
  'height': 52.69271261636783,
  'angle': 0.4971836325048042,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'B', 'group_id': 'group_blfpftgl0lr' },
}, {
  'type': 'shape',
  'object_id': '4',
  'x': 75.47596786866698,
  'y': 19.90582367698775,
  'width': 34.81994841823064,
  'height': 34.819948418230624,
  'angle': -0.3416211889351703,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C', 'group_id': 'group_blfpftgl0lr' },
}, {
  'type': 'shape',
  'object_id': '5',
  'x': 128.38057210262826,
  'y': 16.68901178838475,
  'width': 41.09225082293686,
  'height': 41.092250822936876,
  'angle': -0.7737750783025568,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'white', 'group_id': 'group_blfpftgl0lr' },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 365.01863605833177,
  'y': 392.84674383167606,
  'width': 67.6611079260832,
  'height': 67.66110792608312,
  'angle': 1.1445650930811215,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'B', 'group_id': 'group_nonrvotkke' },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': 434.6719477681645,
  'y': 340.78522066191243,
  'width': 34.81994841823064,
  'height': 34.819948418230624,
  'angle': -0.3416211889351703,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C', 'group_id': 'group_nonrvotkke' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': 388.9540427474871,
  'y': 316.89915447050623,
  'width': 27.06395305498259,
  'height': 27.06395305498259,
  'angle': 0.18971839898392098,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'white', 'group_id': 'group_nonrvotkke' },
}, {
  'type': 'text',
  'object_id': '9',
  'x': 305.9045226130653,
  'y': 2.3031953591198118,
  'width': 195.9798994974875,
  'height': 195.97989949748745,
  'angle': 0,
  'props': {
    'fill': 'F',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Offre spéciale</p>',
    'fontSize': 48,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'lineHeight': 36,
    'group_id': 'group_lmpwa4sj2lm',
  },
}, {
  'type': 'image',
  'object_id': '10',
  'x': 21.484037963761864,
  'y': 189.30112165660051,
  'width': 280,
  'height': 290.17667844522964,
  'angle': null,
  'props': {
    'image_id': 'x75aIlhPe',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.61)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': -0.6281407035175732,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -219.72361809045225,
  'y': 167.8391959798995,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -280.7537688442211,
  'y': -15.050251256281399,
  'width': 356.33730693021107,
  'height': 356.33730693021107,
  'angle': -0.9833915297703326,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 18.14070351758795,
  'y': -303.4673366834171,
  'width': 356.33730693021107,
  'height': 356.33730693021107,
  'angle': 0.07721215843590302,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '4',
  'x': 372.3115577889448,
  'y': -8.969849246231206,
  'width': 356.33730693021107,
  'height': 356.33730693021107,
  'angle': 0.07721215843590302,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '5',
  'x': 347.0854271356784,
  'y': 270.45226130653265,
  'width': 356.33730693021107,
  'height': 356.33730693021107,
  'angle': 0.6802664758956556,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'image',
  'object_id': '6',
  'x': 67.33668341708542,
  'y': 58.542713567839186,
  'width': 112.7460993142279,
  'height': 111.2416235847557,
  'angle': null,
  'props': {
    'image_id': 'h8j7JZTYQ',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 1,
      'border_color': 'black',
      'border': true,
    },
    'group_id': 'group_g0n1fq3u6xt',
  },
}, {
  'type': 'image',
  'object_id': '7',
  'x': 318.4924623115578,
  'y': 92.98994974874371,
  'width': 112.7460993142279,
  'height': 111.2416235847557,
  'angle': null,
  'props': {
    'image_id': 'SsWbLBoLv',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 1,
      'border_color': 'black',
      'border': true,
    },
    'group_id': 'group_7wabbl4bc8f',
  },
}, {
  'type': 'image',
  'object_id': '8',
  'x': 300.80402010050256,
  'y': 327.1859296482412,
  'width': 112.7460993142279,
  'height': 111.2416235847557,
  'angle': null,
  'props': {
    'image_id': '5-ipHwTry',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 1,
      'border_color': 'black',
      'border': true,
    },
    'group_id': 'group_mc9bm9qrgy',
  },
}, {
  'type': 'text',
  'object_id': '9',
  'x': 165.26265664930443,
  'y': 79.2998514611366,
  'width': 146.03613382790695,
  'height': 31.643441803884155,
  'angle': -0.144108555710699,
  'props': {
    'fill': 'E',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Durable</p>',
    'fontSize': 23,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'C',
    'group_id': 'group_g0n1fq3u6xt',
  },
}, {
  'type': 'text',
  'object_id': '10',
  'x': 268.2708175236312,
  'y': 169.1650627403468,
  'width': 103.28590061584023,
  'height': 31.643441803884155,
  'angle': -0.144108555710699,
  'props': {
    'fill': 'E',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Léger</p>',
    'fontSize': 23,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'C',
    'group_id': 'group_7wabbl4bc8f',
  },
}, {
  'type': 'text',
  'object_id': '11',
  'x': 323.4948947279633,
  'y': 301.6062415728611,
  'width': 173.01362560416104,
  'height': 31.643441803884155,
  'angle': -0.144108555710699,
  'props': {
    'fill': 'E',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Facile à utiliser</p>',
    'fontSize': 23,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'C',
    'group_id': 'group_mc9bm9qrgy',
  },
}, {
  'type': 'image',
  'object_id': '12',
  'x': -0.5176876617774155,
  'y': 189.73252804141498,
  'width': 280,
  'height': 283.3116499112951,
  'angle': null,
  'props': {
    'image_id': 'WiXcDlXZQ',
    'filters': {
      'drop_shadow_x': -11,
      'drop_shadow_y': 11,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.48)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': '1',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E', 'opacity': 1 },
}, {
  'type': 'rect',
  'object_id': '2',
  'x': 250,
  'y': 0,
  'width': 250,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1 },
}, {
  'type': 'text',
  'object_id': '3',
  'x': 16.959798994974875,
  'y': 30.527638190954775,
  'width': 207.9145728643216,
  'height': 49.03686370082835,
  'angle': 0,
  'props': {
    'fill': 'E',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Ce produit</p>',
    'fontSize': 36,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'C',
  },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 271.2562814070352,
  'y': 31.055276381909557,
  'width': 207.9145728643216,
  'height': 49.03686370082835,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Concurrents</p>',
    'fontSize': 36,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'E',
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 20,
  'y': 228.60132725394556,
  'width': 207.9145728643216,
  'height': 251.39867274605444,
  'angle': 0,
  'props': {
    'fill': 'E',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>&nbsp;✓ Bon grip</p>\n<p>&nbsp;✓ Facile à tirer</p>\n<p>&nbsp;✓ Rotation de l\'attache</p>\n<p>&nbsp;✓ Plusieurs tailles</p>\n<p>&nbsp;✓ 4 couleurs</p>',
    'fontSize': 28,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'C',
    'lineHeight': 18,
  },
}, {
  'type': 'text',
  'object_id': '6',
  'x': 272.0854271356784,
  'y': 228.60132725394556,
  'width': 207.9145728643216,
  'height': 251.39867274605444,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>&nbsp;✗ Glissant</p>\n<p>&nbsp;✗ Fait mal aux mains</p>\n<p>&nbsp;✗ Crochet fixe</p>\n<p>&nbsp;✗ Trop court</p>\n<p>&nbsp;✗ Peu durable</p>',
    'fontSize': 28,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'background': 'E',
    'lineHeight': 18,
  },
}, {
  'type': 'image',
  'object_id': '7',
  'x': 26.229508196721312,
  'y': 80.99242640732783,
  'width': 182.73284987170013,
  'height': 147.32556783665805,
  'angle': null,
  'props': {
    'image_id': 'KHgmrroe0',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 4,
      'drop_shadow_spread': 5,
      'drop_shadow_color': 'rgba(0,0,0,0.43)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '8',
  'x': 312.6833477135462,
  'y': 84.03796376186367,
  'width': 129.91671203134177,
  'height': 134.63857132778628,
  'angle': null,
  'props': {
    'image_id': 'x75aIlhPe',
    'filters': {
      'grayscale': true,
      'grayscale_value': 1,
      'blur': true,
      'blur_value': 1.2000000000000002,
      'hue-rotate': false,
      'hue-rotate_value': 0,
      'invert': true,
      'invert_value': 0.31,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': 177.2613065326633,
  'y': -366.08040201005025,
  'width': 500,
  'height': 500,
  'angle': 0.2440758319802456,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 121.2562814070352,
  'y': 175.27638190954775,
  'width': 500,
  'height': 500,
  'angle': -0.11888540937410819,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': -339.27135678391954,
  'y': 172.66331658291458,
  'width': 500,
  'height': 500,
  'angle': 1.2066010936966307,
  'props': { 'shape_id': 'triangle.svg', 'fill': 'C' },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 30,
  'y': 21.78391959798995,
  'width': 293.34170854271355,
  'height': 65.99666269580322,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>Témoignages</p>',
    'fontSize': 48,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 1000,
    'lineHeight': 18,
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 30,
  'y': 81.78391959798995,
  'width': 293.34170854271355,
  'height': 80.69515515811477,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>Nos milliers de clients ne peuvent pas se tromper !</p>',
    'fontSize': 36,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'lineHeight': 24,
  },
}, {
  'type': 'rect',
  'object_id': '6',
  'x': -65.20100502512565,
  'y': 177.8894472361809,
  'width': 369.8492462311558,
  'height': 150,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1 },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 22.613065326633176,
  'y': 183.32329586163237,
  'width': 269.47236180904525,
  'height': 133.05025125628137,
  'angle': 0,
  'props': {
    'fill': 'white',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>★★★★★</p>\n<p>"Le produit parfait, achetez les yeux fermés."</p>\n<p>- Jeff D - 6 août 2020 - Amazon.fr</p>',
    'fontSize': 23,
    'fontFamily': 'Lato',
    'fontWeight': 600,
    'lineHeight': 20,
  },
}, {
  'type': 'image',
  'object_id': '8',
  'x': 201.38050043140635,
  'y': 263.9344262295082,
  'width': 280,
  'height': 225.74572127139365,
  'angle': null,
  'props': {
    'image_id': 'KHgmrroe0',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.5)',
      'drop_shadow': true,
    },
  },
}]]
