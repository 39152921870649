import Slider from 'react-input-slider'
import React from 'react'
import { useDesignerContext } from '../designer_context'

export const Numeric = ({ children, field, unit = 'px', decimals = 0, min_value = 0, max_value = 1000, step = 1 }) => {

  const { get_selected_object, update_object_props } = useDesignerContext()

  const object = get_selected_object()

  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-8">
            <input type="number" className='w-100' value={( object.props[ field ] || 0 ).toFixed(decimals)}
                   step={step}
                   onChange={(e) => update_object_props({
                     object,
                     field,
                     value: Math.max(~~e.target.value, min_value),
                   })}/>
          </div>
          <div className="col-4">
            {unit}
          </div>
          <div className="col-12">
            <Slider axis={'x'} x={( object.props[ field ] || 0 ).toFixed(decimals)}
                    xstep={step}
                    onChange={({ x }) => update_object_props({ object, field, value: Math.max(x, min_value) })}
                    xmin={min_value}
                    xmax={max_value}/>
          </div>
        </div>
      </div>
    </>
  )
}
