import React, { useContext } from 'react'
import BackToHome, { BackTo } from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { useEnglish, useFrench, useLang, useTranslate } from '../../context/lang'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Item, { CompactItem } from '../../components/generic/item'
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, IoMdInfinite } from 'react-icons/all'
import { FlatChoices } from '../../components/generic/flat_choice'
import { useAmazonCurrencies, useMarketPlaceDetails, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import Button from '../../components/generic/button'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import UserContext from '../../context/user'
import { Loader } from '../../components/generic/loader'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import throw_confetti from '../../components/generic/confetti'
import { navigate } from '../../absolute_link'
import strategy from '../../images/story/strategy.svg'
import Conrad from '../../components/generic/conrad'
import moment from 'moment'
import CrispButton from '../../components/generic/crisp_button'
import ExternalLink from '../../components/generic/external-link'
import { CountDown } from '../../components/generic/countdown'
import { useCanStartFreeTrial } from '../../components/subscription/start_free_trial'
import { get_client } from '../../graphql/simple-client'
import { free_trial_url_checkout } from '../../graphql/queries/plan'
import { scroll_to_top } from '../product_v2/scroll'
import { format_money } from '../order/format_numbers'
import { useMetaData } from '../../components/user/meta_data'
import OrSeparator from '../../components/generic/or_separator'
import { PauseSubscriptionButton } from './cancel'
import { get_stored_referral } from '../../components/referral/referral'

const ShowNumber = ({ value }) => {
  const french = useFrench()
  if (value === -1) {
    return <><IoMdInfinite/> {french(`Illimité`)}</>
  }
  return <>{value}</>
}


const RefreshUser = () => {
  const user = useContext(UserContext)

  React.useEffect(() => {
    const interval = setInterval(() => {
      console.log('refresh user...')
      user.refresh()
    }, 5000)
    return () => {
      console.log('clear refresh user interval')
      clearInterval(interval)
    }
  }, [])

  React.useEffect(() => {
    throw_confetti({
      x: 0.1,
      y: 0.2,
      angle_min: -30,
      angle_max: 45,
    })
    setTimeout(() => throw_confetti({
      green: true,
      x: 0.2,
      y: 0.2,
      angle_min: -30,
      angle_max: 45,
    }), 250)
    setTimeout(() => throw_confetti({
      red: true,
      x: 0.3,
      y: 0.2,
      angle_min: -30,
      angle_max: 45,
    }), 500)
    setTimeout(() => throw_confetti({
      yellow: true,
      x: 0.4,
      y: 0.2,
      angle_min: -30,
      angle_max: 45,
    }), 750)
  }, [])

  return null
}

export const subscription_tiers = {
  new_customer: 'new_customer',
  free_trial: 'free_trial',
  agency: 'agency',
  single_amazon_account: 'single_amazon_account',
  unlimited_amazon_accounts: 'unlimited_amazon_accounts',
  canceled: 'canceled',
  paused: 'paused',
}

export const subscription_tiers_label_fr = {
  [ subscription_tiers.new_customer ]: '👋 Nouveau client',
  [ subscription_tiers.free_trial ]: `⚡ Période d'essai démarrée`,
  [ subscription_tiers.agency ]: `✅ Agence`,
  [ subscription_tiers.single_amazon_account ]: `✅ Compte Amazon unique`,
  [ subscription_tiers.unlimited_amazon_accounts ]: `✅ Comptes Amazon illimités`,
  [ subscription_tiers.canceled ]: `⚠ Inactif`,
  [ subscription_tiers.paused ]: `⏸ En pause`,
}

const tier_hierarchy = [
  'new_customer',
  'free_trial',
  'canceled',
  'single_amazon_account',
  'unlimited_amazon_accounts',
  'agency',
]

const SwitchPlan = ({ plans, plan_id, plan, label, subscription_frequency, show, close, is_upgrade }) => {
  const french = useFrench()
  const english = useEnglish()
  const company = useCompany()

  const subscription_details = useCachedContext('subscription_details')

  if (subscription_details.loading) {
    return null
  }

  if (subscription_details.error) {
    console.log('what?', subscription_details.error)
    return null
  }


  const current_plan = plans.find((p) => p.subscription_tier === company.subscription_tier) || {
    label_en: 'None',
  }

  // console.log('duh?', subscription_details)

  const date = subscription_details && subscription_details.next_payment && subscription_details.next_payment.date ? subscription_details.next_payment.date : null
  const next_payment = date ? moment(date).format() : 'n/a'

  return <IntellifoxModalComponent show_modal={show} close={close}
                                   title={<>
                                     {is_upgrade ?
                                       <> {french(`Passer à la formule supérieure`)}</>
                                       : <> {french(`Passer à la formule inférieure`)}</>
                                     }
                                   </>}
                                   modal_size={'lg'}
                                   body={<div className="row">
                                     <div className="col-12">
                                       <table className={'table table-bordered'}>
                                         <tbody>
                                         <tr>
                                           <td className={'text-right'}>{french(`Prochain renouvellement`)}</td>
                                           <td className="text-center">
                                             {next_payment}
                                           </td>
                                           <td
                                             className={`${is_upgrade ? 'font-weight-bold' : ''} text-center`}>{french(`Aujourd'hui`)}</td>
                                         </tr>
                                         <tr>
                                           <td className={'text-right'}>{french(`Niveau d'abonnement`)}</td>
                                           <td className="text-center">
                                             <span className="small">{french(`Plan actuel`)}</span>
                                             <br/>
                                             {english(current_plan ? current_plan.label_en : 'None')}</td>
                                           <td className={`${is_upgrade ? 'font-weight-bold' : ''} text-center`}>
                                             <span className="small">{french(`Nouveau plan`)}</span><br/>
                                             {label}
                                           </td>
                                         </tr>
                                         <tr>
                                           <td></td>
                                           <td><PlanCapacity plan={current_plan}/></td>
                                           <td className={is_upgrade ? 'font-weight-bold' : ''}><PlanCapacity
                                             plan={plan}/></td>
                                         </tr>
                                         <tr>
                                           <td className={'text-right'}>{french(`Facturation`)}</td>
                                           <td className={'text-center'}>{
                                             company.subscription_frequency === 'yearly' ?
                                               french(`Annuelle`)
                                               : french(`Mensuelle`)
                                           }</td>
                                           <td
                                             className={`${is_upgrade ? 'font-weight-bold' : ''} text-center`}>{
                                             subscription_frequency === 'yearly' ?
                                               french(`Annuelle`)
                                               : french(`Mensuelle`)
                                           }</td>
                                         </tr>
                                         <tr>
                                           <td></td>
                                           <td><Button outline={true} className={'btn-block'}
                                                       onClick={() => close()}>{french(`Conserver ce plan`)}</Button>
                                           </td>
                                           <td>{is_upgrade ?
                                             <CrispButton className={'btn-block'}
                                                          text={french(`Je souhaite passer à la formule "${label}" - ${subscription_frequency}`)}>
                                               <FaArrowUp/> {french(`Passer à la formule supérieure maintenant`)}
                                             </CrispButton>
                                             : <CrispButton outline={true} className={'btn-block'}
                                                            text={french(`Je souhaite passer à la formule "${label}" - ${subscription_frequency}`)}>
                                               <FaArrowDown/> {french(`Passer à la formule inférieure`)}
                                             </CrispButton>}
                                           </td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                   </div>}
  />
}

const CheckoutButton = ({
                          plans,
                          plan,
                          plan_id,
                          currency_code,
                          can_start_free_trial,
                          subscription_tier,
                          subscription_frequency,
                          label,
                          description,
                          coupon,
                          set_success,
                          currency,
                        }) => {
  const company_id = useCompanyId()
  const company = useCompany()

  const { referral_id } = get_stored_referral()

  if (referral_id === 'Rx6PPB2QPcTCzd3EngYX') {
    coupon = 'PHENIX10'
  }

  const english = useEnglish()
  const translate = useTranslate()

  const user = useContext(UserContext)
  const lang = useLang()

  const [show_switch_plan, set_show_switch_plan] = React.useState(false)

  const [in_progress, set_in_progress] = React.useState(false)
  if (
    company.subscription_tier === 'free_trial'
    || company.subscription_tier === 'canceled'
    || company.subscription_tier === 'new_customer'
  ) {
    return <>
      <Button className="btn-block" onClick={async () => {
        set_in_progress(true)

        let override = {}
        try {
          const client = get_client()
          const response = await client.request(free_trial_url_checkout, {
            company_id,
            plan_id,
            currency_code,
          })

          override = {
            override: response.free_trial_url_checkout,
          }
        } catch (e) {

        }


        window.Paddle.Checkout.open({
          product: plan_id,
          title: `Intellifox - ${label}`,
          message: description,
          email: user.user.email,
          passthrough: company_id,
          locale: lang,
          disableLogout: true,
          ...override,
          coupon,
          successCallback: () => {
            set_in_progress(false)
            set_success(true)

            if (window.gtag) {
              window.gtag('event', 'conversion', { 'send_to': 'AW-10960420121/0VN-CP_t_9EDEJn6quoo' })
            }
          },
          closeCallback: () => {
            set_in_progress(false)
            // set_success(true)
          },
        })
      }
      }>{in_progress ? <Loader size={20}/> : <><FaArrowRight className={"mr-2"}/>
      {can_start_free_trial ? <>
      <span className={'mr-2'}>{translate({
        fr: `${plan.free_trial_days} jours gratuits, puis`,
        en: `${plan.free_trial_days} days for free, and then`,
      })}</span><br/>
      </> : null}
        <span className="font-weight-bold">{
        subscription_frequency === 'yearly' ?
          <>{currency.display(plan.yearly_price, lang)}<span className={'small ml-2'}>{english(`/year`)}</span> <span
            className="small">{english(`+ VAT`)}</span> </> :
          <>{currency.display(plan.monthly_price, lang)}<span className={'small ml-2'}>{english(`/month`)}</span> <span
            className="small">{english(`+ VAT`)}</span> </>

      }</span></>} </Button>
    </>
  }

  const is_same = company.subscription_tier === subscription_tier
  const is_upgrade = tier_hierarchy.indexOf(subscription_tier) > tier_hierarchy.indexOf(company.subscription_tier)

  if (is_same) {
    if (company.subscription_frequency === subscription_frequency) {
      return <IsCurrentChoice/>
    }
    return <>
      <Button onClick={() => set_show_switch_plan(true)} className={'btn-block'}>{
        subscription_frequency === 'yearly' ?
          <><FaArrowUp/> {translate({
            fr: `Mettre à niveau maintenant`,
            en: `Upgrade now`,
          })}
          </>
          : <><FaArrowDown/> {translate({
            fr: `Déclasser`,
            en: `Downgrade`,
          })}
          </>
      }</Button>
      <SwitchPlan show={show_switch_plan} close={() => set_show_switch_plan(false)}
                  is_upgrade={subscription_frequency === 'yearly'}
                  plan_id={plan_id} plan={plan} label={label} subscription_frequency={subscription_frequency}
                  plans={plans}
      />
    </>
  }

  return <>
    <Button onClick={() => set_show_switch_plan(true)} className={'btn-block'}>{is_upgrade ? <>
      <FaArrowUp/> {translate({
      fr: `Mettre à niveau maintenant`,
      en: `Upgrade now`,
    })}
    </> : <>
      <FaArrowDown/> {translate({
      fr: `Déclasser`,
      en: `Downgrade`,
    })}
    </>} </Button>

    <SwitchPlan show={show_switch_plan} close={() => set_show_switch_plan(false)}
                is_upgrade={is_upgrade}
                plans={plans}
                plan_id={plan_id} plan={plan} label={label} subscription_frequency={subscription_frequency}
    />
  </>

}

const IsCurrentChoice = () => {
  const french = useFrench()

  return <Button button={'success'} disabled={true} className="btn-block">{french(`Plan actuel`)}</Button>
}

const PlanCapacity = ({ plan }) => {
  const english = useEnglish()
  const translate = useTranslate()

  if (!plan.products_limit) {
    return null
  }
  return <ul className={'small font-weight-bold'}>
    <li><ShowNumber value={plan.products_limit}/> {translate({
      fr: `Fiches produits optimisées`,
      en: `Optimized product listings`,
    })}</li>
    <li><ShowNumber value={plan.accounts_limit}/> {english('Amazon account(s)')}
    </li>
    <li><ShowNumber
      value={plan.monthly_ai_bg_removal_limit}/> {translate({
      fr: `Suppressions des fonds d'image par intelligence artificielle par mois`,
      en: 'Artificial intelligence image background removals per month',
    })
    }
    </li>
    <li><ShowNumber
      value={plan.managed_ads_limit}/> {translate({
      fr: `Campagnes publicitaires optimisées`,
      en: 'Optimized ad campaigns',
    })
    }
    </li>
  </ul>
}

const ShowPlan = ({ plan, plans, currency, set_success }) => {
  const english = useEnglish()
  const french = useFrench()
  const translate = useTranslate()

  const can_start_free_trial = useCanStartFreeTrial()

  const lang = useLang()

  return <>
    <Item style={{ border: `3px solid var(--color-grey)` }}>
      <div className="row">
        <div className="col-12 font-weight-bold">
          {english(plan.label_en)}
        </div>
        <div className="col-12">
          <hr className="lg"/>
        </div>
        <div className="col-12 small">
          {english(plan.description_en)}
        </div>
        <div className="col-12">
          <hr className="lg"/>
        </div>
        <div className="col-12">
          <PlanCapacity plan={plan}/>
        </div>

        <div className="col-12">
          <CompactItem label={french('Abonnement annuel')}>
            <CheckoutButton plan_id={plan.yearly_plan_id}
                            plan={plan}
                            can_start_free_trial={can_start_free_trial}
                            currency={currency}
                            subscription_tier={plan.subscription_tier}
                            subscription_frequency={'yearly'}
                            currency_code={currency.code}
                            label={english(plan.label_en)}
                            description={english(plan.description_en)}
                            coupon={plan.reduced_coupon}
                            set_success={set_success}
                            plans={plans}
            />


            {can_start_free_trial ? <>
                <h4 className="font-weight-bold text-center mb-1">
                  {french('soit')} {currency.display(plan.yearly_price / 12, lang)}
                  <span className={'small ml-2'}>{english(`/month`)} <span
                    className="small">{english(`+ VAT`)}</span></span>
                </h4>
              </> :
              <h1 className="font-weight-bold d-inline-block">
                {currency.display(plan.yearly_price / 12, lang)}
                <span className={'small ml-2'}>{english(`/month`)} <span
                  className="small">{english(`+ VAT`)}</span> <br/><span
                  className="font-weight-bold">{english('paid annually')}</span></span>
              </h1>
            }


            <div className="row">
              <div className="col-12 text-center">
                <span className="badge badge-success">{french(`Meilleur prix`)}</span>
              </div>
            </div>
          </CompactItem>
        </div>
        <div className="col-12 text-center">
          <OrSeparator/>
        </div>
        <div className="col-12">
          <CompactItem label={french('Abonnement mensuel')}>
            {can_start_free_trial ? <>
              </> :
              <h1 className="font-weight-bold d-inline-block">
                {currency.display(plan.monthly_price, lang)}
                <span className={'small ml-2'}>{english(`/month`)} <span
                  className="small">{english(`+ VAT`)}</span></span>
              </h1>
            }
            <div className="row">
              <div className="col-12">
                <CheckoutButton plan_id={plan.monthly_plan_id}
                                currency={currency}
                                can_start_free_trial={can_start_free_trial}
                                plan={plan}
                                subscription_tier={plan.subscription_tier}
                                subscription_frequency={'monthly'}
                                currency_code={currency.code}
                                label={english(plan.label_en)}
                                description={english(plan.description_en)}
                                coupon={plan.reduced_coupon}
                                set_success={set_success}
                                plans={plans}
                />
              </div>
            </div>
          </CompactItem>
        </div>
      </div>
    </Item>
  </>
}

export const currencies = [
  {
    code: 'USD',
    symbol: '$',
    display: (amount, locale) => format_money(locale, amount, 'USD'),
  },
  {
    code: 'EUR',
    symbol: '€',
    display: (amount, locale) => format_money(locale, amount, 'EUR'),
  },
  {
    code: 'GBP',
    symbol: '£',
    display: (amount, locale) => format_money(locale, amount, 'GBP'),
  },
  {
    code: 'CAD',
    symbol: '$',
    display: (amount, locale) => format_money(locale, amount, 'CAD'),
  },
]
export const all_currencies = [
  {
    code: 'USD',
    symbol: '$',
    display: (amount, locale) => format_money(locale, amount, 'USD'),
  },
  {
    code: 'EUR',
    symbol: '€',
    display: (amount, locale) => format_money(locale, amount, 'EUR'),
  },
  {
    code: 'GBP',
    symbol: '£',
    display: (amount, locale) => format_money(locale, amount, 'GBP'),
  },
  {
    code: 'CAD',
    symbol: '$',
    display: (amount, locale) => format_money(locale, amount, 'CAD'),
  },
]


const ShowPlans = ({ currency, special }) => {
  const plans = useCachedContext('plans', currency.code, special)
  const french = useFrench()
  const user = React.useContext(UserContext)

  const [success, set_success] = React.useState(false)

  const [show_refresh, set_show_refresh] = React.useState(false)

  if (plans.loading) {
    return <DefaultLoading/>
  }

  if (plans.error) {
    return <DefaultError/>
  }

  return <div className="row align-items-end">
    {plans.map((plan, i) => {
      return <div key={i} className="col-12 col-md-6 col-lg-4 mb-2">
        <ShowPlan plan={plan} currency={currency} set_success={set_success} plans={plans}/>
      </div>
    })}

    {show_refresh ? <RefreshUser/> : null}
    {success ? <IntellifoxModalComponent
      show_modal={success} close={() => {
      set_success(false)
      set_show_refresh(true)
    }}
      title={french(`Paiement réussi`)}
      body={<div className="row">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12 col-md-4">
              <img src={strategy}/>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12">
                  <h1>{french(`Félicitations ! Tu as désormais accès à Intellifox.`)}</h1>
                </div>
                <div className="col-12">
                  <p>{french(`Avant que tu ne continues, je tiens vraiment à te remercier de nous accorder ta confiance.`)}</p>
                  <p>{french(`Sache qu'on fait notre maximum tous les jours pour t'aider à réussir sur Amazon. On travaille d'arrache-pieds pour améliorer le logiciel en permanence et pour t'aider à résoudre tes problèmes.`)}</p>
                  <p>{french(`N'hésite pas à nous envoyer un message si tu as besoin d'aide, autant sur Amazon qu'avec Intellifox, nous sommes là pour ça.`)}</p>

                  <p>{french(`Bonne réussite à toi !`)}</p>

                </div>
                <div className="col-12 font-weight-bold text-center">
                  <Conrad/> {french(`Conrad, fondateur d'Intellifox`)}
                </div>
                <div className="col-12 small font-weight-bold text-right mt-3">
                  {french(`À toi de jouer ! `)}<FaArrowDown className={'mr-5'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
      on_click={async () => {
        await user.refresh()
        await navigate('/product')
        setTimeout(() => scroll_to_top(), 200)
      }}
      on_close={async () => {
        await user.refresh()
        await navigate('/product')
        setTimeout(() => scroll_to_top(), 200)
      }}
      action_label={french(`Optimiser mes produits`)}
    /> : null}
  </div>

}


export const UpgradeSelector = ({ special }) => {
  const french = useFrench()

  const default_currency = useUserCurrencyForPricing()

  const [selected_currency, set_selected_currency] = React.useState(default_currency)

  return <>
    <ShowPlans currency={selected_currency} special={special}/>

    <div className="row">
      <div className="col-12 text-center mt-3 mb-3">
        <ExternalLink
          to={'https://intellifox.com/legal/'}>{french(`Voir la politique de remboursement et de résiliation d'Intellifox`)}</ExternalLink>
      </div>
      <div className="col-12 mb-3 text-center">
        <CurrencyPicker selected_currency={selected_currency} set_selected_currency={set_selected_currency}
                        currencies={currencies}/>
      </div>
    </div>

  </>
}

export const useUserCurrency = () => {
  const source_market_place = useSourceMarketPlace()
  const market_place_details = useMarketPlaceDetails(source_market_place)
  const amazon_currencies = useAmazonCurrencies()

  const stored_currency = useMetaData('selected_currency')

  return amazon_currencies.find(({ code }) => {
    if (stored_currency) {
      return code === stored_currency
    }
    return code === market_place_details.currency
  }) || amazon_currencies[ 0 ]
}

export const useUserCurrencyForPricing = () => {
  const source_market_place = useSourceMarketPlace()
  const market_place_details = useMarketPlaceDetails(source_market_place)

  const stored_currency = useMetaData('selected_currency')

  return currencies.find(({ code }) => {
    if (stored_currency) {
      return code === stored_currency
    }
    return code === market_place_details.currency
  }) || currencies[ 0 ]
}

export const CurrencyPicker = ({ selected_currency, set_selected_currency, currencies }) => {
  return <FlatChoices
    items={currencies.map(({ code, symbol }, i) => {
      return {
        value: i,
        label: `${symbol} - ${code}`,
      }
    })}
    on_click={(v) => set_selected_currency(currencies[ v || 0 ])}
    current_value={currencies.indexOf(currencies.find((c) => c.symbol === selected_currency.symbol && c.currency === selected_currency.currency))}
  />
}

export const UpgradeChoices = ({ special }) => {
  const translate = useTranslate()

  return <>
    <div className="row">
      <div className="col-12  col-md-8 offset-md-2 text-center mb-5">
        <div className="h1 font-weight-bold mb-5" dangerouslySetInnerHTML={{
          __html: translate({
            fr: `Et si tu pouvais vendre <u>quelques produits de plus</u> par mois sur Amazon ?`,
            en: `What If You Could Sell <u>A Few More Products</u> A Month On Amazon?`,
          }),
        }}>
        </div>
        <span className="h1 font-weight-bold highlight mb-4">
          {translate({
            fr: `Intellifox se paierait tout seul !`,
            en: `It Would Pay Itself!`,
          })}
        </span>
        <p className="mt-5">
          {translate({
            fr: `Augmente les taux de conversion et la visibilité de tes produits afin de tirer le maximum de ventes avec Intellifox.`,
            en: `Increase your products conversion rates and visibility so you can squeeze every possible sales with Intellifox.`,
          })}
        </p>
      </div>
    </div>
    <UpgradeSelector special={special}/>

  </>
}

export default function Upgrade() {
  const translate = useTranslate()

  const { pause_subscription } = useCompany()


  return (
    <div className="container pb-5">
      <BackToHome/>
      <Title color={section_color('subscription')} style={{ color: 'black' }}><WelcomeIcon
        section={'subscription'}/> {translate({
        fr: `Abonnement`,
        en: `Subscription`,
      })}</Title>

      {pause_subscription ? <PauseSubscriptionButton/> : <UpgradeChoices/>}


    </div>
  )
}
