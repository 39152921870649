import React, { useContext } from 'react'

const UserContext = React.createContext('')

export default UserContext


export const DisconnectContext = React.createContext(() => {})

export const useDisconnect = () => useContext(DisconnectContext)
