import React from 'react'
import UpdateButtonV2 from '../../../components/generic/update_button_v2'
import { FiCalendar, FiEdit, FiPause, FiPlay, FiTrash, FiZapOff } from 'react-icons/all'
import { Checkbox } from '../../../components/generic/checkbox'
import { useFrench, useTranslate } from '../../../context/lang'
import IntellifoxModal from '../../../components/generic/modal'
import TimezoneSelector from '../../setting/timezone_selector'
import { TimePicker } from './time_picker'
import Button from '../../../components/generic/button'
import { Detail } from './campaign_results'
import { NiceConclusion } from '../../../components/generic/item'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { DeleteSchedule, useSetManagedCampaignSchedule } from './set_managed_campaign_schedule'

const update_campaign_mutation = create_mutation({
  mutation: 'update_campaign', params: {
    company_id: 'ID!',
    amazon_ads_account_id: 'String',
    profile_id: 'String',
    region_id: 'String',
    market_place: 'String',
    campaign_id: 'String',
    state: 'String',
    daily_budget: 'Float',
  },
})

export const useCampaignUpdate = ({ campaign, managed_campaign }) => {
  const company_id = useCompanyId()
  const { campaign_id, profile_id, amazon_ads_account_id, region_id, market_place } = managed_campaign

  const campaigns = useCachedContext('campaigns', managed_campaign.amazon_ads_account_id, managed_campaign.profile_id)

  const [update_campaign] = useMutation(update_campaign_mutation)

  const do_update_campaign = async ({ daily_budget = campaign.daily_budget, state = campaign.state }) => {
    await await_mutate(update_campaign, {
      variables: {
        company_id, amazon_ads_account_id, profile_id, region_id, market_place, campaign_id, state, daily_budget: parseFloat(daily_budget),
      },
    })
    await campaigns.refresh()
  }

  return do_update_campaign
}

export default function CampaignStateV2({
                                          campaign,
                                          managed_campaign,
                                        }) {
  const french = useFrench()
  const translate = useTranslate()

  const { campaign_id, state } = campaign

  const do_update_campaign = useCampaignUpdate({ campaign, managed_campaign })

  const has_daily_schedules = managed_campaign && !!managed_campaign.daily_schedules

  const [daily_schedules, set_daily_schedules] = React.useState(managed_campaign ? managed_campaign.daily_schedules || always_on_intervals : null)

  const hours_activated = has_daily_schedules && daily_schedules ? daily_schedules.reduce((hours_activated, { intervals }) => {
    return hours_activated + intervals.filter((i) => i.state === 'enabled').length
  }, 0) : null

  const percentage_enabled = hours_activated ? hours_activated / ( 7 * 24 ) : null

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <CampaignStateBadge
          show_schedule={has_daily_schedules}
          percentage_enabled={percentage_enabled}
          state={state}/>

      </div>
      {!has_daily_schedules ? <div className="col-12 mb-2">
        <UpdateButtonV2 className="btn-block btn-sm" trigger={async () => {
          await do_update_campaign({
            state: state === 'paused' ? 'enabled' : 'paused',
          })
        }}
                        disable_success_state={true}>
          {state === 'paused' ? <>
            <FiPlay/> {translate({
            fr: `Activer`,
            en: `Enable`,
          })}</> : <>
            <FiPause/> {translate({
            fr: `Mettre en pause`,
            en: `Pause`,
          })}</>}
        </UpdateButtonV2>
      </div> : null}
      <div className="col-12">
        <UpdateCampaignSchedule managed_campaign={managed_campaign}
                                daily_schedules={daily_schedules}
                                set_daily_schedules={set_daily_schedules}
                                do_update_campaign={do_update_campaign}/>
      </div>
      <div className="col-12">
        <DeleteSchedule managed_campaign={managed_campaign}/>
      </div>
    </div>
  )
}

const useStateBadgeAndLabel = ({ state }) => {
  const translate = useTranslate()
  let badge = 'secondary'
  let label = translate({
    fr: 'Archivé',
    en: 'Archived',
  })
  if (state === 'paused') {
    badge = 'warning'
    label = translate({
      fr: 'En pause',
      en: 'Paused',
    })
  } else if (state === 'enabled') {
    badge = 'success'
    label = translate({
      fr: 'Actif',
      en: 'Enabled',
    })
  }
  return { badge, label }
}
const CampaignStateBadge = ({ state, show_schedule, percentage_enabled }) => {

  const { badge, label } = useStateBadgeAndLabel({ state })


  return <>
    <NiceConclusion icon={<FiCalendar style={{ opacity: !show_schedule ? 0.3 : 1 }}/>}
                    bg={badge}
                    conclusion={label}/>
  </>
}

export const KeywordOrTargetState = ({ state }) => {
  const { badge, label } = useStateBadgeAndLabel({ state })
  return <Detail bg={badge}>{label}</Detail>
}

const hours = []
for (let j = 0; j < 24; j++) {
  hours.push(j)
}

const always_on_intervals = []
for (let i = 1; i <= 7; i++) {
  always_on_intervals.push({
    day: i, intervals: hours.map((j) => ( {
      start_hour: j, start_minutes: 0, end_hour: j + 1, end_minutes: 0, state: 'enabled',
    } )),
  })
}


const UpdateCampaignSchedule = ({
                                  managed_campaign,
                                  daily_schedules, set_daily_schedules,
                                }) => {

  const french = useFrench()
  const has_daily_schedules = managed_campaign && !!managed_campaign.daily_schedules

  const set_managed_campaign_schedule = useSetManagedCampaignSchedule({ managed_campaign })

  return <IntellifoxModal title={<> <FiCalendar/> {french(`Activer selon les heures ou les jours`)}</>}
                          body={<div className="row">
                            <div className="col-12">
                              <TimezoneSelector/>
                            </div>
                            <div className="col-12">
                              <TimePicker daily_schedules={daily_schedules}
                                          set_daily_schedules={set_daily_schedules}/>
                            </div>
                          </div>}

                          on_click={() => set_managed_campaign_schedule(
                            daily_schedules)}
                          action_in_progress_label={french('Programmation en cours...')}
                          action_label={french('Sauvegarder et appliquer la programmation')}
                          button_props={{
                            className: 'btn-sm btn-block',
                          }}
  >
    <FiCalendar/> {has_daily_schedules ? french(`Modifier la programmation`) : french(`Programmer`)}
  </IntellifoxModal>
}
