import { useDesignerContext } from '../designer_context'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import * as _ from 'lodash'

import { MdExpandLess } from 'react-icons/md'
import { useTranslate } from '../../../context/lang'

export const DistributeDropdown = ({ selected_objects }) => {
  const { update_multiple_objects } = useDesignerContext()
  const translate = useTranslate()

  const handleToggle = (isOpen, event, metadata) => {
    if (isOpen || metadata.source !== 'select') {
      setShow(isOpen)
    }
  }
  const distribute = (x_or_y) => {
    // 1. sort items by x or y
    const sorted_selected_objects = _.cloneDeep(selected_objects).sort((o1, o2) => o1[ x_or_y ] <= o2[ x_or_y ] ? -1 : 1)

    // 2. determine min x or y, max x or y,
    const min_x_or_y = sorted_selected_objects[ 0 ][ x_or_y ]
    const max_x_or_y = sorted_selected_objects[ sorted_selected_objects.length - 1 ][ x_or_y ]
    const step = ( max_x_or_y - min_x_or_y ) / ( selected_objects.length - 1 )
    // 3. Create updates
    const updated_selected_objects = sorted_selected_objects.map((o, i) => {
      return {
        object_id: o.object_id,
        updates: {
          [ x_or_y ]: min_x_or_y + ( i * step ),
        },
      }
    })

    update_multiple_objects(updated_selected_objects)
  }

  const [show, setShow] = React.useState(false)
  return <>
    <Dropdown show={show} onToggle={handleToggle} title="Position">
      <Dropdown.Toggle variant='link'>{translate({
          fr: `Distribuer`,
          en: `Distribute`
      })}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          distribute('x')
        }}><MdExpandLess/> {translate({
            fr: `Distribuer horizontalement`,
            en: `Distribute horizontally`
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          distribute('y')
        }}><MdExpandLess/> {translate({
            fr: `Distribuer verticalement`,
            en: `Distribute vertically`
        })}</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>
  </>

}
