import React from 'react'
import BackToHome from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import BigBox from '../../components/layout/big_box'
import { useFrench } from '../../context/lang'
import { useCompany } from '../../hooks/useCompanyId'
import { CompactItem, ItemTitle } from '../../components/generic/item'
import CopyButton from '../../components/generic/copy-button'
import { HelpModal } from '../../components/generic/help_button'

export const Referral = () => {
  const french = useFrench()

  const company = useCompany()
  const {user_referral_id, referred_users_count} = company

  const user_referral_url = `https://intellifox.com/#?ur=${user_referral_id}`

  return  <div className="container">
    <BackToHome/>
    <Title color={section_color('referral')} style={{ color: 'white' }}><WelcomeIcon
      section={'referral'}/> {french(`Programme de parrainage`)}
    </Title>

    <BigBox title={french(`Lien de parrainage`)}>
      <code>{user_referral_url}</code> <CopyButton text_to_copy={user_referral_url}>{french(`Copier le lien`)}</CopyButton>
    </BigBox>

    <BigBox title={french(`Statistiques`)}>
      <div className="row">
        <div className="col-12 col-md-6">
          <h1>{referred_users_count}</h1>{french(`compte(s) créé(s) avec ton code de parrainage`)}
        </div>
        <div className="col-12 col-md-6">
          <h1>0</h1>{french(`moi(s) gratuit(s) obtenu(s)`)}
        </div>
      </div>
    </BigBox>

    <HelpModal label={french(`Conditions du programme`)}>
      <div className="row">
        <div className="col-12 mb-2">
          {french(`Le programme de parrainage s'adresse aux utilisateurs actifs d'Intellifox.`)}
        </div>
        <div className="col-12 mb-2">
          {french(`Si un nouvel utilisateur découvre le logiciel grâce au lien de parrainage et qu'il souscrit à l'issue de la période d'essai avec une formule payante, alors le parrain bénéficie d'une réduction égale au montant de l'abonnement acheté par le parrainé, sur le ou les prochains mois d'abonnement à Intellifox.`)}
        </div>
        <div className="col-12 mb-2">
          {french(`Il est aussi possible de parrainer des nouveaux utilisateurs en utilisant le partage des résultats dans le suivi des ventes.`)}
        </div>
      </div>
    </HelpModal>
  </div>
}
