import { GraphQLClient } from 'graphql-request'

import { create_query } from './queries/_query_and_mutations_templates'
import useCompanyId from '../hooks/useCompanyId'
import { api_domain } from '../constants'

export function get_client() {
  const endpoint = `${api_domain}/api/graphql`

  let user = localStorage.getItem('user')
  let token = null
  if (user) {
    user = JSON.parse(user)
    token = user.token
  }

  return new GraphQLClient(endpoint, {
    timeout: 180000,
    headers: {
      authorization: token || null,
    },
  })
}


export const useQuery = ({ query, params, output }, transformer = (a) => a) => {
  const company_id = useCompanyId()

  return async (variables) => {
    const client = get_client()

    const response = await client.request(
      create_query({
          query, params: {
            company_id: 'ID!',
            ...params,
          }, output,
        },
      ),
      {
        company_id,
        ...variables
      })
    return transformer(response[ query ])
  }
}
