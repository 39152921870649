import React from 'react'
import Button from './button'
import { useFrench, useTranslate } from '../../context/lang'

export default function CrispButton({ text, children, disabled, className, outline}) {
  const [failed, set_failed] = React.useState(false)
  const translate = useTranslate()
  const french = useFrench()

  return (
    <>
      <Button className={className} outline={outline} onClick={() => {
        if (window.$crisp) {
          window.$crisp.push(['do', 'chat:open'])
          if (text) {
            window.$crisp.push(['do', 'message:send', ['text', text]])
          }
        } else {
          set_failed(true)
        }
      }} disabled={disabled}>{children}</Button>
      {failed ? <div className='alert alert-danger mt-2 mb-2'>
        {french(`Impossible d'ouvrir le support. Actions pour résoudre le problème :`)}
        <ul>
          <li>{french(`Désactiver les bloqueurs de publicités`)}</li>
          <li>{french(`Recharger la page`)}</li>
          <li>{french(`Réessayer`)}</li>
        </ul>
        {french(`Si ça ne marche toujours pas, le support est disponible par email :`)} <a
        href="mailto:support@intellifox.com">support@intellifox.com</a>

      </div> : null}
    </>
  )
}
