import React from 'react'
import TimezoneSelect from 'react-timezone-select'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import { useCachedContext } from '../../hooks/useCachedContext'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import { FaCheck, FiClock, FiEdit, FiGlobe, FiMapPin, FiSave } from 'react-icons/all'
import Button from '../../components/generic/button'
import { useFrench } from '../../context/lang'
import { all_currencies, CurrencyPicker, useUserCurrency } from '../subscription/upgrade'
import { useMetaData, useUpdateMetaData } from '../../components/user/meta_data'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { useAmazonCurrencies } from '../../hooks/useMarketPlace'


export default function CurrencySelector() {
  const french = useFrench()

  const amazon_currencies = useAmazonCurrencies()
  const default_currency = useUserCurrency()

  const [selected_currency, set_selected_currency] = React.useState(default_currency)

  const update_meta_data = useUpdateMetaData({
    meta_data_key: 'selected_currency', value: selected_currency.code,
  })

  React.useEffect(() => {
    update_meta_data()
  }, [selected_currency])

  return <div className="row">
    <div className="col-12">
      <CurrencyPicker selected_currency={selected_currency} set_selected_currency={set_selected_currency} currencies={amazon_currencies}/>
    </div>
  </div>
}
