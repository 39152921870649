import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, { Option_Content_Section, Option_Content_SubTitle } from './select__content'
import { apply_overrides_to_object } from '../override/apply_overrides'
import Button from '../../generic/button'
import { Checkbox } from '../../generic/checkbox'
import Slider from 'react-input-slider'
import { Color, ColorPickerLine } from './select_color'
import { ChromePicker } from 'react-color'
import TextButton from '../../generic/text_button'
import { Browse_Content_SubTitle, Browse_Content_Title } from '../designer/browse__content'
import { useTranslate } from '../../../context/lang'
import TextArea from '../../generic/text-area'
import Field from '../../generic/field'
import { ShapeSelector } from '../designer/browse_shapes'


const Slide = ({ label, value, min, max, setter, default_value, show_slide = false, step }) => {
  const translate = useTranslate()

  return <div className="row mb-1 align-items-center">
    <div className="col-3">
      {label}
    </div>
    {show_slide ? <div className="col-9">
      <Slider axis={'x'} xmin={min} xmax={max} x={value} onChange={({ x }) => setter(x)} xstep={step}/>
    </div> : <div className="col-9">

      <input type={'number'} className={'form-control-sm'} value={value}
             onChange={(e) => setter(parseFloat(e.target.value))} style={{ width: 100 }}/> <TextButton
      className={'btn-sm ml-2'}
      onClick={() => setter(default_value)}>{translate({
      fr: `Réinitialiser`,
      en: `Reset`,
    })}</TextButton>
    </div>}
  </div>
}
const DropShadow = ({ filters, do_update_object }) => {

  const [edit_color, set_edit_color] = React.useState(false)
  const translate = useTranslate()

  const setter = (key) => {
    return (value) => {
      do_update_object({
        [ key ]: value,
      })
    }
  }
  return <Option_Content_Section>
    <Browse_Content_SubTitle>
      {translate({
        fr: `Ombre portée`,
        en: `Drop shadow`,
      })}
    </Browse_Content_SubTitle>
    <div className="row">

      <Checkbox value={filters.drop_shadow} setter={() => do_update_object({
        // defaults values :
        drop_shadow_x: 0,
        drop_shadow_y: 10,
        drop_shadow_spread: 10,
        drop_shadow_color: 'black',

        // then apply values from before
        ...filters,
        drop_shadow: !filters.drop_shadow,
      })}>{translate({
        fr: `Activer`,
        en: `Enable`,
      })}</Checkbox>

      {filters.drop_shadow ? <>
        <div className="col-12">
          <Slide label={translate({
            fr: 'Gauche',
            en: 'Left',
          })} value={filters.drop_shadow_x} min={-100} max={100} default_value={0}
                 setter={setter('drop_shadow_x')}/>
          <Slide label={translate({
            fr: 'Haut',
            en: 'Top',
          })} value={filters.drop_shadow_y} min={-100} max={100} default_value={10}
                 setter={setter('drop_shadow_y')}/>
          <Slide label={translate({
            fr: 'Dispersion',
            en: 'Blur radius',
          })} value={filters.drop_shadow_spread} min={0} max={100} default_value={10}
                 setter={setter('drop_shadow_spread')}/>
          <div className="row">
            <div className="col-3">
              <Color color={filters.drop_shadow_color || '#000'} size={48} is_current={true}
                     onClick={() => set_edit_color(!edit_color)}/>
            </div>
            <div className="col-9">
              <ColorPickerLine color={'000'} current={filters.drop_shadow_color}
                               update_object={setter('drop_shadow_color')} color_picker_size={32} end={100}/>
            </div>
          </div>
        </div>
        {edit_color ? <div className="col-12">
          <ChromePicker color={filters.drop_shadow_color}
                        onChange={(c, e) => {
                          do_update_object({
                            drop_shadow_color: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`,
                          })
                        }}/>
        </div> : null}
      </> : null}

    </div>

  </Option_Content_Section>
}
const Repeat = ({ filters, do_update_object }) => {

  const translate = useTranslate()

  const setter = (key) => {
    return (value) => {
      do_update_object({
        [ key ]: value,
      })
    }
  }
  return <Option_Content_Section>
    <Browse_Content_SubTitle>
      {translate({
        fr: `Répétition`,
        en: `Repeat`,
      })}
    </Browse_Content_SubTitle>
    <div className="row">

      <Checkbox value={filters.repeat} setter={() => do_update_object({
        // defaults values :
        repeat_x: -2,
        repeat_y: 2,

        // then apply values from before
        ...filters,
        repeat: !filters.repeat,
      })}>{translate({
        fr: `Activer`,
        en: `Enable`,
      })}</Checkbox>

      {filters.repeat ? <>
        <div className="col-12">
          <Slide label={translate({
            fr: 'Gauche',
            en: 'Left',
          })} value={filters.repeat_x} min={-100} max={100} default_value={-2} step={1}
                 setter={setter('repeat_x')}/>
          <Slide label={translate({
            fr: 'Haut',
            en: 'Top',
          })} value={filters.repeat_y} min={-100} max={100} default_value={2} step={1}
                 setter={setter('repeat_y')}/>
          <Slide label={translate({
            fr: 'Nombre de répétition',
            en: 'Number of repeat',
          })} value={filters.repeat_value} min={0} max={10} default_value={5} step={1}
                 setter={setter('repeat_value')}/>
        </div>
        <div className="col-12 font-weight-bold">{translate({
          fr: `Attention ! Un grand nombre de répétition peut entraîner des ralentissements.`,
          en: `Beware! If you use a large number for this, you may slow the image editor or crash it.`,
        })}</div>
      </> : null}

    </div>

  </Option_Content_Section>
}

const Border = ({ filters, do_update_object }) => {
  const translate = useTranslate()
  const { product } = useDesignerContext()

  const setter = (key) => {
    return (value) => {
      do_update_object({
        [ key ]: value,
      })
    }
  }
  return <Option_Content_Section>
    <Browse_Content_SubTitle>
      {translate({
        fr: `Bords`,
        en: `Border`,
      })}
    </Browse_Content_SubTitle>
    <div className="row">

      <Checkbox value={filters.border} setter={() => do_update_object({
        // defaults values :
        border_width: 5,
        border_color: 'black',

        // then apply values from before
        ...filters,
        border: !filters.border,
      })}>{translate({
        fr: `Activer`,
        en: `Enable`,
      })}</Checkbox>

      {filters.border ? <>
        <div className="col-12">
          <Slide label={translate({
            fr: 'Épaisseur',
            en: 'Width',
          })} value={filters.border_width} min={0} max={100} default_value={5}
                 setter={setter('border_width')}/>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `Couleurs de la palette`,
                en: `Color palette`,
              })}
            </div>
            <div className="col-12">
              {product.color_palette.colors.map((c) => <Color color={c.color}
                                                              className={'mr-2'}
                                                              is_current={c.color_id === filters.border_color}
                                                              onClick={() => {
                                                                do_update_object({
                                                                  border_color: c.color_id,
                                                                })
                                                              }}/>)}

            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `Couleur personnalisée`,
                en: `Custom colors`,
              })}
            </div>
            <div className="col-12">
              <ChromePicker color={filters.border_color}
                            onChange={(c, e) => {
                              do_update_object({
                                border_color: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`,
                              })
                            }}/>
            </div>
          </div>


        </div>
      </> : null}

    </div>

  </Option_Content_Section>
}


export const FilterSlider = ({ label, min, max, filters, field, default_value, do_update_object, current_option, options = [], step = ( max - min ) / 10 }) => {
  const translate = useTranslate()

  return <div className="row no-gutters">

    {label ? <div className="col-12">
      {label}
    </div> : null}
    <div className="col-auto mr-1">
      <Slider axis={'x'} xmin={min} xmax={max} x={filters[ `${field}_value` ] || default_value}
              onChange={({ x }) => do_update_object({
                [ field ]: true,
                [ `${field}_option` ]: current_option,
                [ `${field}_value` ]: x,
              })} xstep={step}/><br/>
      <TextButton
        className={'btn-sm ml-2'}
        onClick={() => do_update_object({
          [ field ]: false,
          [ `${field}_value` ]: default_value,
        })}>{translate({
        fr: `Réinitialiser`,
        en: `Reset`,
      })}</TextButton>
    </div>
    <div className="col">
      <input type='number' className='w-100' value={filters[ `${field}_value` ] || default_value} step={step}
             onChange={(e) => {
               do_update_object({
                 [ field ]: true,
                 [ `${field}_value` ]: parseFloat(e.target.value),
               })
             }}/>
    </div>
    {options.length > 0 ? <div className="col-12">
      {translate({
        fr: 'Options :',
        en: 'Options:',
      })} {options.map((o) => {
      return <TextButton className={`mr-3 ${current_option === o ? 'font-weight-bold' : ''}`} onClick={() => {
        do_update_object({
          [ `${field}_option` ]: o,
        })
      }}>{o}</TextButton>
    })}
    </div> : null}
  </div>
}

export const FilterSetting = ({ filters, do_update_object, label, field, default_value, min, max, step = ( max - min ) / 100, options = [], default_option }) => {
  const current_option = filters[ `${field}_option` ] || options[ default_option ]


  return <Option_Content_Section>
    <Browse_Content_SubTitle>
      {label}
    </Browse_Content_SubTitle>

    <FilterSlider min={min} max={max} filters={filters} field={field} default_value={default_value}
                  do_update_object={do_update_object} current_option={current_option} options={options} step={step}/>

  </Option_Content_Section>
}


export default function SelectFilters({ get_value, update_object, show_transparent }) {
  const { get_selected_object, update_object_props, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])

  const filters = get_value(selected_object) || {}

  const do_update_object = (update) => update_object_props(update_object(selected_object, { ...filters, ...update }))

  return (
    <Option_Content>
      <Browse_Content_Title>{translate({
        fr: `Filtres`,
        en: `Filters`,
      })}</Browse_Content_Title>
      {/*<Option_Content_Section>*/}
      {/*  {JSON.stringify(filters)}*/}
      {/*</Option_Content_Section>*/}
      <DropShadow do_update_object={do_update_object} filters={filters}/>

      {selected_object.type === 'image' ? <Option_Content_Section>
        <Browse_Content_SubTitle>
          {translate({
            fr: 'Effet réflexion',
            en: 'Reflexion effect',
          })}
        </Browse_Content_SubTitle>
        <div className="row">
          <Checkbox value={filters.image_reflexion} setter={() => do_update_object({
            image_reflexion: !filters.image_reflexion,
          })}>{translate({
              fr: `Activer`,
              en: `Enable`
          })}</Checkbox>
        </div>
        {filters.image_reflexion ? <>
          <FilterSlider filters={filters} do_update_object={do_update_object} field={'image_reflexion'}
                        default_value={30} min={0} max={100} step={1} label={translate({
                            fr: 'Longueur',
                            en: 'Length',
                        })}/>
          <FilterSlider filters={filters} do_update_object={do_update_object} field={'image_reflexion_distance'}
                        default_value={-10} min={-150} max={150} step={1} label={translate({
                            fr: 'Distance',
                            en: 'Distance',
                        })}/>
        </> : null}

      </Option_Content_Section> : null}

      <Border do_update_object={do_update_object} filters={filters}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Bords arrondis',
        en: 'Border radius',
      })}
                     field={'border_radius'}
                     default_value={0} min={0} max={100} options={['%', 'px']} default_option={1}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Contraste',
        en: 'Contrast',
      })} field={'contrast'}
                     default_value={1} min={0} max={2}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Luminosité',
        en: 'Light',
      })} field={'brightness'}
                     default_value={1} min={0} max={2}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Saturation',
        en: 'Saturation',
      })} field={'saturate'}
                     default_value={1} min={0} max={2}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Sépia',
        en: 'Sepia',
      })} field={'sepia'}
                     default_value={0} min={0} max={1}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Niveau de gris',
        en: 'Grey scale',
      })} field={'grayscale'}
                     default_value={0} min={0} max={1}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Couleurs',
        en: 'Couleurs',
      })} field={'hue-rotate'}
                     default_value={0} min={0} max={360}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Inversion couleurs',
        en: 'Color inversion',
      })} field={'invert'}
                     default_value={0} min={0} max={1}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
        fr: 'Flou',
        en: 'Blur',
      })} field={'blur'}
                     default_value={0} min={0} max={10}/>
      {selected_object.type === 'text' ? <><Repeat filters={filters} do_update_object={do_update_object}/></> : null}


      {selected_object.type === 'image' ? <>
        <Browse_Content_Title>{translate({
          fr: `Propriétés de l'image`,
          en: `Image properties`,
        })}</Browse_Content_Title>
        <Option_Content_Section>
          <Browse_Content_SubTitle>
            {translate({
              fr: `Ajuster l'image`,
              en: `Image fit`,
            })}
          </Browse_Content_SubTitle>
          <div className="row">
            <div className="col-12">
              <div className="btn-group">
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_fit',
                  value: 'cover',
                })}
                        button={selected_object.props.image_object_fit === 'cover' ? 'primary' : 'secondary'}><b>{translate({
                  fr: `Couper`,
                  en: `Cut`,
                })}</b></Button>
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_fit',
                  value: 'contain',
                })}
                        button={selected_object.props.image_object_fit === 'contain' ? 'primary' : 'secondary'}>{translate({
                  fr: `Ajuster`,
                  en: `Fit`,
                })}</Button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="btn-group">
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_reverse',
                  value: false,
                })} button={!selected_object.props.image_object_reverse ? 'primary' : 'secondary'}>{translate({
                  fr: `Normal`,
                  en: `Normal`,
                })}</Button>
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_reverse',
                  value: true,
                })}
                        button={selected_object.props.image_object_reverse ? 'primary' : 'secondary'}>{translate({
                  fr: `Miroir`,
                  en: `Mirror`,
                })}</Button>
              </div>
            </div>
          </div>
        </Option_Content_Section>
        <Option_Content_Section>
          <Option_Content_SubTitle>
            {translate({
              fr: `Masque`,
              en: `Mask`,
            })}
          </Option_Content_SubTitle>
          <div className="row">
            <div className="col-12">
              <Button onClick={() => update_object_props({
                object: selected_object,
                field: 'mask_image',
                value: false,
              })} button={!selected_object.props.image_object_reverse ? 'primary' : 'secondary'}>{translate({
                fr: `Pas de masque`,
                en: `No mask`,
              })}</Button>

              <ShapeSelector on_click={({url}) => {
                update_object_props({
                  object: selected_object,
                  field: 'mask_image',
                  value: `url(${url})`,
                })
              }}/>
            </div>
          </div>
        </Option_Content_Section>

      </> : null}

      {selected_object.type === 'shape' ? <>
        <Browse_Content_Title>{translate({
          fr: `Propriétés`,
          en: `Properties`,
        })}</Browse_Content_Title>
        <Option_Content_Section>
          <Option_Content_SubTitle>
            {translate({
              fr: `Miroir à l'horizontal`,
              en: `Horizontal mirror`,
            })}
          </Option_Content_SubTitle>
          <div className="row">
            <div className="col-12">
              <div className="btn-group">
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_reverse',
                  value: false,
                })} button={!selected_object.props.image_object_reverse ? 'primary' : 'secondary'}>{translate({
                  fr: `Normal`,
                  en: `Normal`,
                })}</Button>
                <Button onClick={() => update_object_props({
                  object: selected_object,
                  field: 'image_object_reverse',
                  value: true,
                })}
                        button={selected_object.props.image_object_reverse ? 'primary' : 'secondary'}>{translate({
                  fr: `Miroir`,
                  en: `Mirror`,
                })}</Button>
              </div>
            </div>
          </div>
        </Option_Content_Section>
      </> : null}
    </Option_Content>
  )
}
