import React from 'react'
import IntellifoxModal from '../../generic/modal'
import { GoGear } from 'react-icons/go'
import Button from '../../generic/button'
import { useMutation } from '@apollo/client'
import { UPDATE_RENDERER } from '../../../graphql/queries/visual'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { useDesignerContext } from '../designer_context'
import { useTranslate } from '../../../context/lang'

export default function EditRenderer({ index }) {
  const { product } = useDesignerContext()
  const translate = useTranslate()

  const visual = product.product_info.visuals.find(v => v.index === index)

  const [update_renderer] = useMutation(UPDATE_RENDERER)
  const company_id = useCompanyId()

  const [renderer_choice, set_renderer_choice] = React.useState(visual.renderer || 'intellifox-1')

  return (
    <IntellifoxModal title={translate({
      fr: 'Modifier le moteur de rendu',
      en: 'Update visual renderer',
    })}
                     on_click={async () => {
                       await await_mutate(update_renderer, {
                         variables: {
                           company_id,
                           visual_id: visual.visual_id,
                           renderer: renderer_choice,
                         },
                       })
                       await product.refresh()
                     }}
                     action_label={translate({
                       fr: 'Modifier le moteur de rendu',
                       en: 'Update visual renderer',
                     })}
                     action_in_progress_label={translate({
                       fr: 'En cours...',
                       en: 'In progress...',
                     })}
                     body={<>
                       <div className="row">
                         <div className="col-12">
                           {translate({
                             fr: `Dans le cas où il y a un grand nombre d'éléments sur l'image, le moteur de rendu principal
                           peut créer un mauvais résultat.`,
                             en: `In some cases, a large number of items can induce errors in the renderer.`,
                           })}
                         </div>
                         <div className="col-12">
                           {translate({
                             fr: `Dans de rares cas, voici un bouton qui permet d'utiliser un autre moteur de rendu, qui peut
                           résoudre ce problème.`,
                             en: `For these rare cases, you can use this button to try another renderer, which might solve this issue`,
                           })}
                         </div>
                         <div className="col-12">
                           <Button onClick={() => set_renderer_choice('intellifox-1')} className={'mr-2'}
                                   button={renderer_choice === 'intellifox-1' ? 'primary' : 'secondary'}>
                             Intellifox-1 {translate({
                                 fr: `(par défaut)`,
                                 en: `(default)`
                             })}
                           </Button>
                           <Button onClick={() => set_renderer_choice('intellifox-2')} className={'mr-2'}
                                   button={renderer_choice === 'intellifox-2' ? 'primary' : 'secondary'}>
                             Intellifox-2 {translate({
                                 fr: `(secondaire)`,
                                 en: `(secondary)`
                             })}
                           </Button>
                         </div>
                       </div>
                     </>}

    >
      <GoGear/>
    </IntellifoxModal>
  )
}
