import React from 'react'
import { useDesignerContext } from '../designer_context'
import Button from '../../generic/button'
import { useCachedContext } from '../../../hooks/useCachedContext'
import Draggable from 'react-draggable'
import * as _ from 'lodash'
import { apply_overrides } from '../override/apply_overrides'
import { DefaultError, DefaultLoading } from '../../i18n/translations'
import { useTranslate } from '../../../context/lang'
import IntellifoxModal from '../../generic/modal'
import { useWindowSize } from '../../../hooks/useWindowSize'

const DraggableCropSelector = ({ x, y, on_drag, on_start, on_stop, max_x, max_y, handle }) => {
  return <Draggable
    bounds={{
      left: 0,
      top: 0,
      right: max_x,
      bottom: max_y,
    }}
    defaultPosition={{ x, y }}
    onDrag={on_drag}
    onStart={on_start}
    onStop={on_stop}
    scale={1}>
    <div style={{
      position: 'absolute',
      cursor: 'pointer',
      width: 40,
      height: 40,
    }}>
      {handle}

    </div>
  </Draggable>
}


const ImageCropper = ({ temporary_crop, set_temporary_crop, image_id, crop, set_scale }) => {
  const [image_width, set_image_width] = React.useState(320)
  const window_size = useWindowSize()

  const translate = useTranslate()

  const image_ref = React.useRef(null)

  const image = useCachedContext('image', image_id)

  const [global_drag, set_global_drag] = React.useState(false)
  const [active_corner, set_active_corner] = React.useState(false)

  const refresh_temporary_crop = React.useCallback((crop) => {
    const image_width = image_ref.current ? image_ref.current.getBoundingClientRect().width : 320
    set_image_width(image_width)

    const image_ratio = image.width / image.height
    const scale = image.width / image_width

    const image_height = image_width / image_ratio

    if (crop) {
      set_temporary_crop({
        x: crop.x / scale,
        y: crop.y / scale,
        width: crop.width / scale,
        height: crop.height / scale,
        is_null: false,
      })
    } else {
      set_temporary_crop({
        x: 0,
        y: 0,
        width: image_width,
        height: image_height,
        is_null: true,
      })
    }
  }, [image.height, image.width])

  React.useEffect(() => {
    if (image.loading || image.error) {
      return
    }

    refresh_temporary_crop(crop)

  }, [crop, image, refresh_temporary_crop, window_size])

  React.useEffect(() => {
    const scale = image.width / image_width
    set_scale(scale)
  }, [image_width])

  if (image.loading) {
    return <DefaultLoading/>
  }

  if (image.error) {
    return <DefaultError/>
  }


  const image_ratio = image.width / image.height


  return <div className="row">
    <div className="col-12">
      <Button onClick={() => {
        set_temporary_crop(null)
        setTimeout(() => {
          refresh_temporary_crop(null)
        }, 200)
      }} outline={true}>{translate({
        fr: `Réinitialiser la découpe`,
        en: `Reset crop`,
      })}</Button>
    </div>
    <div className="col-12">
      <div className="position-relative mb-5">

        <img src={image.url} alt="" style={{ width: '100%' }} ref={image_ref}/>


        {temporary_crop ?
          <div className="position-absolute" style={{ top: 0, left: 0, width: '100%', height: '100%' }}>
            <div className="position-absolute" style={{
              left: temporary_crop.x,
              top: temporary_crop.y,
              width: temporary_crop.width,
              height: temporary_crop.height,
              border: '1px solid black',
              background: 'rgba(255,255,255,0.1)',
            }}></div>

            {!active_corner ? <Draggable
              bounds={{
                left: 0,
                top: 0,
                right: image_width - temporary_crop.width,
                bottom: image_width / image_ratio - temporary_crop.height,
              }}
              defaultPosition={{ x: temporary_crop.x, y: temporary_crop.y }}
              onStart={() => {
                set_global_drag(true)
              }}
              onDrag={(e, data) => {
                set_temporary_crop({
                  ...temporary_crop,
                  x: data.x,
                  y: data.y,
                  is_null: false,
                })
              }}

              onStop={() => {
                set_global_drag(false)
              }}
              scale={1}>
              <div style={{
                position: 'absolute',
                cursor: 'move',
                width: temporary_crop.width,
                height: temporary_crop.height,
              }}>

              </div>
            </Draggable> : null}

            {!global_drag ? <>
              <DraggableCropSelector on_start={() => set_active_corner(true)}
                                     on_stop={() => set_active_corner(false)}
                                     on_drag={(e, data) => {
                                       set_temporary_crop({
                                         ...temporary_crop,
                                         x: data.x,
                                         y: data.y,
                                         width: temporary_crop.width - ( data.x - temporary_crop.x ),
                                         height: temporary_crop.height - ( data.y - temporary_crop.y ),
                                         is_null: false,
                                       })
                                     }} x={temporary_crop.x} y={temporary_crop.y} max_x={image_width}
                                     max_y={image_width / image_ratio}
                                     handle={<div className="position-absolute" style={{
                                       width: '100%',
                                       height: '100%',
                                       top: 0, left: 0,
                                       borderLeft: '4px solid black',
                                       borderTop: '4px solid black',
                                     }}/>}/>

              <DraggableCropSelector on_start={() => set_active_corner(true)}
                                     on_stop={() => set_active_corner(false)}
                                     on_drag={(e, data) => {
                                       set_temporary_crop({
                                         ...temporary_crop,
                                         width: data.x - temporary_crop.x,
                                         height: data.y - temporary_crop.y,
                                         is_null: false,
                                       })
                                     }} x={( temporary_crop.x + temporary_crop.width )}
                                     y={( temporary_crop.y + temporary_crop.height )}
                                     max_x={image_width} max_y={image_width / image_ratio}
                                     handle={<div className="position-absolute" style={{
                                       width: '100%',
                                       height: '100%',
                                       top: 0, left: 0,
                                       borderLeft: '4px solid black',
                                       borderTop: '4px solid black',
                                       transformOrigin: '0 0',
                                       transform: 'rotate(180deg)',
                                     }}/>}/>
            </> : null}


          </div> : null}
      </div>
    </div>
  </div>
}


export default function SelectCropImage({button = 'link', buttonClassName = '', product_id, do_edit_overrides, children}) {
  const { get_selected_object, update_object_several_props, update_single_object, current_objects, current_product_id, edit_overrides } = useDesignerContext()
  const translate = useTranslate()

  if (!product_id) {
    product_id = current_product_id
  }

  if (do_edit_overrides !== true && do_edit_overrides !== false) {
    do_edit_overrides = edit_overrides
  }

  const selected_object = apply_overrides([get_selected_object()], current_objects.overrides[ product_id ])[ 0 ]

  const { image_id, crop } = selected_object.props

  const [scale, set_scale] = React.useState(1)

  const [temporary_crop, set_temporary_crop] = React.useState(null)

  return <IntellifoxModal body={<>
    <ImageCropper image_id={image_id} crop={crop} temporary_crop={temporary_crop}
                  set_temporary_crop={set_temporary_crop} set_scale={set_scale}/>
  </>} button_props={{ button, className: buttonClassName }} title={translate({
    fr: `Découper l'image`,
    en: `Crop image`,
  })} action_label={translate({
    fr: `Appliquer la découpe`,
    en: `Apply crop`,
  })} on_click={() => {
    if (temporary_crop && temporary_crop.is_null) {
      update_single_object(selected_object.object_id, {
        props: {
          ...selected_object.props,
          crop: null,
        },
      }, true, product_id, do_edit_overrides)

      return
    }

    const smaller_side = Math.min(selected_object.width, selected_object.height)
    const crop_ratio = temporary_crop.width / temporary_crop.height
    const image_ratio = selected_object.width / selected_object.height

    let dimensions_update = {
      width: crop_ratio > 1 ? smaller_side : smaller_side * crop_ratio,
      height: crop_ratio > 1 ? smaller_side / crop_ratio : smaller_side,
    }

    if (_.inRange(crop_ratio - image_ratio, -0.001, 0.001)) {
      dimensions_update = {}
    }

    update_single_object(selected_object.object_id, {
      ...dimensions_update,
      props: {
        ...selected_object.props,
        crop: temporary_crop ? {
          x: temporary_crop.x * scale,
          y: temporary_crop.y * scale,
          width: temporary_crop.width * scale,
          height: temporary_crop.height * scale,
        } : null,
        image_object_fit: 'cover',
      },
    }, true, product_id, do_edit_overrides)


  }
  }>
    {children ? children : translate({
      fr: `Découper l'image`,
      en: `Crop image`,
    })}
  </IntellifoxModal>
}
