import React from 'react'
import { useDesignerContext } from '../designer_context'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { compute_rect_style } from '../controller/rect_controls'
import SingleObjectOptions from '../options/single_object_options'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'
import { rotate_around } from '../controller/geometry'

export default function Shape({ object_id, onLoad, ...props }) {
  const { shape_id, fill, gradient, gradient_color, gradient_angle, filters, image_object_reverse } = props && props.props ? props.props : null
  const { compute_color } = useDesignerContext()

  const shape = useCachedContext('shape', shape_id)

  React.useEffect(() => {
    if (shape_id === null) {
      onLoad && onLoad()
    }
  }, [])

  React.useEffect(() => {
    if (shape.error || shape.shape_id === null) {
      onLoad && onLoad()
    }
    if (shape.shape_id) {
      onLoad && onLoad()
    }
  }, [shape])

  if (shape.loading) {
    return <div className="position-absolute loading" id={object_id} style={{
      ...compute_rect_style(props),
      background: 'grey',
      opacity: 0.5,
    }}>
    </div>
  }

  if (shape.error || shape.shape_id === null) {
    return <div></div>
  }

  const style = compute_rect_style(props)

  let shape_fill = compute_color ? compute_color(fill) : fill
  let svg_gradient = null
  if (gradient) {

    const center = {
      x: 0.5,
      y: 0.5,
    }

    const gradient_point = { x: 0, y: 1 }

    const p2 = rotate_around(center, gradient_point, ( gradient_angle || 0 ) / 180 * Math.PI)
    const p1 = {
      x: 1 - p2.x,
      y: 1 - p2.y,
    }

    const gradient_id = `gradient_${Math.random().toString(36).substr(2, 16)}` // njsscan-ignore: node_insecure_random_generator

    svg_gradient = <svg width={1} height={1}>
      <linearGradient id={gradient_id}
                      x1={p1.x} y1={p1.y}
                      x2={p2.x} y2={p2.y}>
        <stop offset="0%" stopColor={compute_color ? compute_color(fill) : fill}/>
        <stop offset="100%" stopColor={compute_color ? compute_color(gradient_color) : gradient_color}/>
      </linearGradient>
    </svg>
    shape_fill = `url(#${gradient_id})`
  }

  let shape_stroke = {}
  if (filters && filters.border) {
    const { border_width, border_color } = filters
    shape_stroke = {
      stroke: compute_color ? compute_color(border_color) : border_color,
      strokeWidth: border_width,
      overflow: 'visible',
    }
  }

  const final_scale = image_object_reverse ? -1 : 1

  return (
    <div className="position-absolute svg_overflow_visible" id={object_id} style={{
      ...style,
      border: 'none',
      fill: shape_fill,
      ...shape_stroke,
      transform: `${style.transform} scaleX(${final_scale})`,
      // background,
      //
      // maskImage: `url(${shape.url})`,
      // maskSize: '100% 100%',
    }}>
      <div dangerouslySetInnerHTML={{ __html: shape.svg }} style={{
        transform: `scaleX(${props.width / shape.width}) scaleY(${props.height / shape.height})`,
        transformOrigin: '0 0',
        width: shape.width,
        height: shape.height,
      }}></div>
      {svg_gradient}
    </div>
  )
}


export const ShapeOptions = ({ scale, ...object }) => {
  const { set_current_option, update_single_object, selected_objects, current_option } = useDesignerContext()
  const translate = useTranslate()

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 no-gutters row justify-content-between">
      <div className="col-auto">
        <OptionSelector option={'props.shape_id'}>{translate({
          fr: `Remplacer l'shape`,
          en: `Replace shape`,
        })}</OptionSelector>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <OptionSelector option={'props.crop_shape'}>{translate({
          fr: `Découper l'shape`,
          en: `Crop shape`,
        })}</OptionSelector>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <OptionSelector option={'props.filters'}>{translate({
          fr: `Filtres`,
          en: `Filters`,
        })}</OptionSelector>
      </div>
      {/*<div className="col-auto ml-2">*/}
      {/*  <TextButton onClick={() => set_current_option('props.filters')}>Transparent</TextButton>*/}
      {/*</div>*/}
      <div className="col"></div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>
  </div>
}
