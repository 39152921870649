import { useFrench, useTranslate } from '../../context/lang'
import React from 'react'
import { OpenedSectionContext } from './edit'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useIsMobile } from '../../components/navigation/left-menu'
import StartHere from './start_here'
import ProductPreview from './product_preview/product_preview'
import PublishProductV2, { has_unpublished_changes } from './publish_product_v2'
import TextButton from '../../components/generic/text_button'
import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaHatWizard, FaPaperPlane,
  FiToggleLeft,
  FiToggleRight,
} from 'react-icons/all'
import useLocalStorage from '../../hooks/useLocalStorage'
import Button from '../../components/generic/button'
import { Tooltip } from 'react-tippy'
import { MdClose } from 'react-icons/md'
import { ShowGlobalProgress } from '../product/progress'
import useHashParam from 'use-hash-param'
import { useHotkeys } from 'react-hotkeys-hook'
import SEOSummary from './seo_summary'
import Item from '../../components/generic/item'
import { OnlyPremium } from '../../components/subscription/start_free_trial'


export const useOpenedSection = () => {
  const [opened_section, set_opened_section] = useHashParam('opened_section', '')

  return [opened_section, set_opened_section]
}

export const ProductFooter = ({ product, market_place }) => {
  const translate = useTranslate()
  const french = useFrench()

  const [opened_section, set_opened_section] = useOpenedSection()

  const window_size = useWindowSize()
  const is_mobile = useIsMobile()

  const delta_left = is_mobile ? 0 : 0
  const available_space = window_size.width - delta_left
  const width = available_space * 0.9
  const left = delta_left + 0.05 * available_space
  const [current_view, set_current_view] = React.useState('product')

  const footer_ref = React.useRef()


  const sections = [
    {
      key: 'preview',
      component: <>
        <div className="row">
          <div className="col-12">
            <h2>{french(`Prévisualisation`)}</h2>
          </div>
        </div>
        <ProductPreview product={product} current_view={current_view} show_edit_button={false}/>
      </>,
      control: <>
        <PublishProductV2 product_id={product.product_id}/>
      </>,
    },
  ]

  const current_section = sections.find((section) => section.key === opened_section)

  const has_optimization_not_started = product.product_info.search_terms.length === 0 || !product.product_info.search_terms[ 0 ]

  const [show_click_here_tooltip, set_show_click_here_tooltip] = useLocalStorage(`show_opti_tooltip_${product.product_id}_${market_place}`, has_optimization_not_started)

  const is_not_published = has_unpublished_changes(product)

  useHotkeys('ctrl+p', (e) => {
    e.preventDefault()
    set_opened_section(opened_section === 'preview' ? '' : 'preview')
  }, [opened_section])
  useHotkeys('esc', (e) => {
    e.preventDefault()
    set_opened_section('')
  }, [opened_section])


  return <>
    {sections.map((section, i) => {

      const is_opened = opened_section === section.key

      return <div className="position-fixed" key={i} style={{
        zIndex: 20,
        left: delta_left,
        width: `calc(100% - ${delta_left}px)`,
        height: `100%`,
        top: is_opened ? 0 : window_size.height,
        background: ' var(--color-bg-global-darker)',
        transition: 'top 0.2s',
        overflow: 'auto',
      }}>
        <div className="container pb-5">
            <div className="row mb-3">
              <div className="col text-center pt-2 pb-2">
                <TextButton className={'btn-sm'} style={{
                  opacity: current_view === 'competitors' ? 1 : 0.5,
                }}
                            onClick={() => set_current_view('competitors')}
                >{translate({
                  fr: `Voir parmi les concurrents`,
                  en: `See amongst competition`,
                })}<br/>
                  <span className="small">({translate({
                    fr: `1ère conversion`,
                    en: `1st conversion`,
                  })})</span>
                </TextButton>
                <span className="mr-2 ml-2 clickable" onClick={() => {
                  set_current_view(current_view === 'competitors' ? 'product' : 'competitors')
                }}>
                  {current_view === 'competitors' ? <FiToggleLeft/> : <FiToggleRight/>}
                    </span>
                <TextButton className={'btn-sm'} style={{
                  opacity: current_view === 'product' ? 1 : 0.5,
                }}
                            onClick={() => set_current_view('product')}>
                  {translate({
                    fr: `Aperçu de la fiche produit`,
                    en: `Preview product`,
                  })}<br/>
                  <span className="small">({translate({
                    fr: `2ème conversion`,
                    en: `2nd conversion`,
                  })})</span>
                </TextButton>
              </div>
              <div className="col-auto pt-2 pb-2 text-right">
                <Button onClick={() => set_opened_section('')} className={'mr-2'}>
                  <MdClose/> {french(`Fermer`)}
                </Button>
                <PublishProductV2 product_id={product.product_id}/>
              </div>
              <div className="col-12">
                <hr className={'m-0'}/>
              </div>
            </div>
          <OnlyPremium background={'var(--color-bg-global-darker)'}>
          {section.component}
          </OnlyPremium>
        </div>
      </div>

    })}

  </>
}
