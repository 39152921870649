import React from 'react'
import { useFrench } from '../../../context/lang'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { useCachedContext } from '../../../hooks/useCachedContext'
import IntellifoxModal from '../../../components/generic/modal'
import Field from '../../../components/generic/field'
import { FiEdit, FiTrash } from 'react-icons/all'
import UpdateButtonV2 from '../../../components/generic/update_button_v2'

const set_managed_campaign_target = create_mutation({
  mutation: 'set_managed_campaign_schedule',
  params: {
    company_id: 'ID!',
    campaign_id: 'String',
    daily_schedules: 'String',
  },
})


export const useSetManagedCampaignSchedule = ({ managed_campaign }) => {
  const [set_managed_campaign_target_mutation] = useMutation(set_managed_campaign_target)
  const managed_campaigns = useCachedContext('managed_campaigns')

  const company_id = useCompanyId()
  const { campaign_id } = managed_campaign

  return async (daily_schedules) => {
    await await_mutate(set_managed_campaign_target_mutation, {
      variables: {
        company_id,
        campaign_id,
        daily_schedules: daily_schedules ? JSON.stringify(daily_schedules) : daily_schedules,
      },
    })
    await Promise.all([
      managed_campaigns.refresh(),
    ])
  }
}

export const DeleteSchedule = ({ managed_campaign }) => {
  const has_daily_schedules = managed_campaign && !!managed_campaign.daily_schedules
  const french = useFrench()

  const set_managed_campaign_schedule = useSetManagedCampaignSchedule({ managed_campaign })

  if (!has_daily_schedules) {
    return null
  }

  return <UpdateButtonV2 className="btn-block btn-sm" button={'danger'} trigger={async () => {
    await set_managed_campaign_schedule(null)
  }}><FiTrash/> {french(`Supprimer la programmation`)}</UpdateButtonV2>
}

