import React from 'react'
import Browse_Content, {
  Browse_Content_Picker,
  Browse_Content_Picker_Item,
  Browse_Content_Section,
  Browse_Content_SubTitle,
  Browse_Content_Title,
} from './browse__content'
import { compute_scale, DomRenderer, Scaler } from '../renderer/dom_renderer'
import { DesignerContext, useDesignerContext } from '../designer_context'

import { useCachedContext } from '../../../hooks/useCachedContext'
import useMarketPlace from '../../../hooks/useMarketPlace'
import IntellifoxModal from '../../generic/modal'
import Field from '../../generic/field'
import CopyButton from '../../generic/copy-button'
import { DefaultError, DefaultLoading } from '../../i18n/translations'
import { useFrench, useTranslate } from '../../../context/lang'
import Button from '../../generic/button'
import EditColorPalette, { default_palettes } from '../options/edit_color_palette'
import { ShowCompetitorsImages } from '../../../routes/product_v2/product_preview/competitors'
import { useTemplates } from './models/translate_templates'


const size = 150

const Sample = ({ objects }) => {

  const { update_objects_save_history, width, height } = useDesignerContext()
  return <Browse_Content_Picker_Item onClick={() => update_objects_save_history(objects)}>
    <Scaler scale={compute_scale({ available_width: size, available_height: size, width, height })} width={size}
            height={size}>
      <DomRenderer width={width} height={height} objects={objects}/>
    </Scaler>
  </Browse_Content_Picker_Item>
}

const HaveFunWithModels = () => {
  const translate = useTranslate()
  const french = useFrench()

  const templates = useTemplates()

  const designer_context = useDesignerContext()
  const { width, height, product } = designer_context

  const [current_colors, set_current_colors] = React.useState(product.color_palette.colors)
  const [show_palettes, set_show_palettes] = React.useState(true)

  const compute_color = (c) => {
    const found = current_colors.find(({ color_id }) => color_id === c)
    if (found) {
      return found.color
    }
    return c
  }

  if (templates.loading) {
    return <DefaultLoading/>
  }

  const size = 200
  return <IntellifoxModal title={translate({
    fr: 'Prévisualiser tous les modèles',
    en: 'Preview all templates',
  })}
                          body={<>
                            <div className="row">
                              {show_palettes ? <div className="col-12">
                                {default_palettes.map((palette, i) => <Button className={'mr-2 mb-2'}
                                                                              onClick={() => set_current_colors(palette)}>
                                  {palette.map(({ color }) => <div className="d-inline-block" style={{
                                    width: 40,
                                    height: 20,
                                    background: color,
                                  }}></div>)}
                                </Button>)}
                              </div> : null}
                              <DesignerContext.Provider value={{ ...designer_context, compute_color }}>
                                <div className="col-12">
                                  {templates.map((set) => {
                                    return <div className="row no-gutters">
                                      {set.map((objects) => {

                                        return <div className="col-auto mr-1 mb-1 force-transition-on-stuff">
                                          <Scaler scale={compute_scale({
                                            available_width: size,
                                            available_height: size,
                                            width,
                                            height,
                                          })}
                                                  width={size}
                                                  height={size}>
                                            <DomRenderer width={width} height={height} objects={objects} />
                                          </Scaler>
                                        </div>
                                      })}
                                    </div>
                                  })}
                                </div>
                              </DesignerContext.Provider>
                            </div>
                          </>}>
    {translate({
      fr: `Prévisualiser tous les modèles`,
      en: `Preview all templates`,
    })}
  </IntellifoxModal>

}

const ShowExistingModels = () => {
  const market_place = useMarketPlace()
  const products = useCachedContext('products', market_place)

  if (products.loading) {
    return <DefaultLoading/>
  }

  if (products.error) {
    return <DefaultError/>
  }

  return products.map((product) => {
    return product.product_info.visuals.map((visual) => {
      if (visual.objects.length === 0) {
        return null
      }
      return <Sample key={`${product.product_id}_${visual.index}`} objects={visual.objects}/>
    })
  })
}

const ExistingModels = () => {
  const [show, set_show] = React.useState(false)
  const translate = useTranslate()

  if (!show) {
    return <Button onClick={() => set_show(true)}>{translate({
      fr: `Voir les images des autres produits`,
      en: `See other products images`,
    })}</Button>
  }

  return <ShowExistingModels/>
}

export default function BrowseModels() {
  const translate = useTranslate()
  const { product } = useDesignerContext()

  const templates = useTemplates()

  if(templates.loading) {
    return <DefaultLoading/>
  }

  const show_templates = (set) => {
    return <Browse_Content_Picker>
      {set.map((objects) => <Sample objects={objects}/>)}
    </Browse_Content_Picker>
  }

  return (
    <Browse_Content>
      <Browse_Content_Title>{translate({
        fr: `Modèles`,
        en: `Templates`,
      })}</Browse_Content_Title>

      <Browse_Content_Section>
        <HaveFunWithModels/>
        <EditColorPalette product={product}/>
      </Browse_Content_Section>

      <Browse_Content_Section>
        <Browse_Content_SubTitle>{translate({
          fr: `Ensemble de modèles`,
          en: `Template set`,
        })}</Browse_Content_SubTitle>
        {templates.map((set,i) => {
          return show_templates(set)
        })}
      </Browse_Content_Section>

      <Browse_Content_Section>
        <Browse_Content_SubTitle>{translate({
          fr: `Import/Export manuel`,
          en: `Manual import/export`,
        })}</Browse_Content_SubTitle>
        <Browse_Content_Picker>
          <ExportJson/>
          <ImportJsonModal/>
        </Browse_Content_Picker>
      </Browse_Content_Section>

      <Browse_Content_Section>
        <Browse_Content_SubTitle>{translate({
          fr: `Images d'autres produits`,
          en: `Images from other products`,
        })}</Browse_Content_SubTitle>
        <Browse_Content_Picker>
          <ExistingModels/>
        </Browse_Content_Picker>
      </Browse_Content_Section>
    </Browse_Content>
  )
}

const ExportJson = () => {
  const { current_objects } = useDesignerContext()
  const translate = useTranslate()


  return <div className="row mb-2">
    <div className="col-12">
      <CopyButton text_to_copy={JSON.stringify(current_objects.objects)} outline={false}>{translate({
        fr: `Exporter en .json`,
        en: `Export in .json`,
      })}</CopyButton>

    </div>
  </div>
}

const ImportJsonModal = () => {

  const [imported_json, set_imported_json] = React.useState('')
  const translate = useTranslate()

  const [output, set_output] = React.useState([])

  React.useEffect(() => {
    try {
      set_output(JSON.parse(imported_json))
    } catch (e) {
      set_output({ failed: true })
    }
  }, [imported_json])

  const { update_objects_save_history, width, height } = useDesignerContext()

  const size = 300
  return <IntellifoxModal title={translate({
    fr: 'Importer depuis le presse-papier',
    en: 'Import from clipboard',
  })}
                          body={<>
                            <div className="row">
                              <div className="col-12">
                                {translate({
                                  fr: `Entrer ici le JSON de l'image à copier :`,
                                  en: `Enter here the JSON of the image to copy:`,
                                })}
                              </div>
                              <Field type="text" value={imported_json} setter={set_imported_json}/>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                {translate({
                                  fr: `Résultat :`,
                                  en: `Result:`,
                                })}
                              </div>
                              <div className="col-12">
                                {output && output.map ? <Scaler scale={compute_scale({
                                  available_width: size,
                                  available_height: size,
                                  width,
                                  height,
                                })} width={size}
                                                                height={size}>
                                  <DomRenderer width={width} height={height} objects={output}/>
                                </Scaler> : translate({
                                  fr: `Entrée incorrecte`,
                                  en: <>Incorrect data</>,
                                })}
                              </div>
                            </div>
                          </>}
                          action_label={translate({
                            fr: 'Appliquer ce modèle',
                            en: 'Apply this model',
                          })}
                          disable_action={!( output && output.map )}
                          on_click={() => {
                            if (output && output.map) {
                              update_objects_save_history(output)
                            } else {
                              throw new Error()
                            }
                          }
                          }
  >
    {translate({
      fr: `Importer depuis un .json`,
      en: `Import from a .json`,
    })}
  </IntellifoxModal>
}
