import React, { useState, useLayoutEffect, useRef } from 'react'

import './keyword-highlighter.css'

const get_content_div = () => {
  const div = document.createElement('div')
  div.style.pointerEvents = 'none'
  div.style.padding = '1px'
  div.style.bottom = 0
  div.className = 'position-absolute text_area_clone w-100 keyword_highlighter'
  return div
}
export default function KeywordHighlighter({ element, value, keywords = [] }) {
  const content_element = useRef(get_content_div())
  const container_ref = useRef()

  useLayoutEffect(() => {
    const do_it = () => {
      if (element) {
        let outer_html = element.parentElement.parentElement.parentElement.outerHTML
        content_element.current.innerHTML = outer_html
        const texts = content_element.current.querySelectorAll('[data-text="true"]')
        for (let i = 0; i < texts.length; i++) {
          texts[ i ].innerHTML = texts[ i ].innerHTML.trim()
            .split(' ')
            .map(w => {
              const is_in_keywords = keywords.reduce((is_in_keywords, keyword) => {
                return is_in_keywords || w.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(keyword) !== -1
              }, false)

              if (is_in_keywords) {
                return `<span class="keyword selected">${w}</span>`
              }
              return `<span class="keyword">${w}</span>`
            })
            .join(' ')
        }

        if (!content_element.current.parentElement) {
          container_ref.current.appendChild(content_element.current)
        }
      }
    }
    do_it()

  }, [element, value, keywords])

  if (!element) {
    return null
  }
  return (
    <div className='position-absolute w-100 h-100' style={{ bottom: 0 }} ref={container_ref}>

    </div>
  )
}
