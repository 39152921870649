import React from 'react'

import './flat_choice.scss'

export default function FlatChoice({ on_click, className = '', is_current, children, style = {}, p = 'p-1 px-2' }) {
  return (
    <div className={`d-inline-block clickable flat_choice`} onClick={on_click}>
      <div className={`d-inline-block ${p} ${className} inner ${is_current ? 'is_current' : ''}`} style={{

        ...style,
      }}>

        {children}
      </div>
    </div>
  )
}

export const FlatChoices = ({ items, on_click, className = '', current_value, compact }) => {

  const choice_class_name = compact ? 'small p-1' : 'p-2'

  return <div className={`row ${className}`}>
    <div className="col-12">
      {items.map(({ label, value }, i) => {
        const is_current = current_value === value
        return <FlatChoice key={i} on_click={() => on_click(is_current ? '' : value)}
                           is_current={is_current} className={`mr-2 clickable ${choice_class_name}`}>
          {label}
        </FlatChoice>
      })}
    </div>
  </div>
}
