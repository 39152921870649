import React from 'react'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { fetchEmojis } from 'emojibase'

import Item from './item'
import Field from './field'
import { DefaultLoading } from '../i18n/translations'
import FlatChoice from './flat_choice'
import TextButton from './text_button'
import { FaArrowLeft } from 'react-icons/all'
import Button from './button'
import { MdAddCircle } from 'react-icons/md'

/*

Emoji database document here :
https://emojibase.dev/docs/datasets#fetching-from-a-cdn

 */

const DisplayEmoji = ({ emoji, label, on_click }) => {
  return <>
    <div style={{ fontSize: 24 }} className={'mr-2 clickable d-inline-block'} title={label} onClick={() => {
      console.log('emoji clicked:', emoji)
      on_click(emoji)
    }}>
      {emoji}
    </div>
  </>
}

const DisplayCategory = ({ tag, emojis, on_click }) => {
  return <div className="row mb-2">
    <div className="col-12">
      <span className="field_labelish">
        {tag}
      </span>
    </div>
    <div className="col-12">
      <Item no_padding={true} className="p-1">
        {emojis.map(({ emoji, label }) => {
          return <DisplayEmoji key={emoji} on_click={on_click} emoji={emoji} label={label}/>
        })}
      </Item>
    </div>
  </div>
}
export default function EmojiPicker({ on_click }) {
  const lang = useLang()
  const french = useFrench()

  const [loading, set_loading] = React.useState(true)
  const [emojis, set_emojis] = React.useState([])

  const [search, set_search] = React.useState('')

  React.useEffect(() => {
    const go = async () => {
      set_loading(true)
      const emojis = await fetchEmojis(lang)
      set_emojis(emojis.filter((e) => e.version < 14 && e.version > 0))
      set_loading(false)
      console.log('emojis!', emojis)
    }

    go().catch(e => console.error(e))
  }, [lang])

  if (loading) {
    return <DefaultLoading/>
  }

  const search_regex = new RegExp(search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase(), 'ig')

  const all_tags = emojis.reduce((all_tags, { tags, emoji, label }) => {
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[ i ]

      let tag_count = all_tags.find((t) => t.tag === tag)
      if (!tag_count) {
        tag_count = {
          tag,
          emojis: [],
        }
        all_tags.push(tag_count)
      }
      tag_count.emojis.push({ emoji, label })
    }

    return all_tags
  }, []).sort((t1, t2) => t1.emojis.length < t2.emojis.length ? 1 : -1)
    .filter((t) => t.emojis.length > 1)

  return (
    <div className="row">
      <div className="col-12">
        <Item no_padding={true}>
          <div className="row small">
            <Field type={'text'} value={search} setter={set_search}
                   placeholder={french('Rechercher un emoji ou une catégorie')}/>
            {search ? <div className="col-12 position-relative" style={{ top: -12 }}>
              <TextButton onClick={() => set_search('')} className={'btn-sm font-italic px-2'}>
                <FaArrowLeft/> {french(`Réinitialiser la recherche`)}
              </TextButton>
            </div> : null}
            <div className="col-12 px-3">
              <div style={{ maxHeight: 400, overflow: 'auto' }}>
                {search ? <div className="col-12">
                  {emojis.filter((e) => search_regex.test(e.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())).filter((e, i) => i < 200).map((e) => {
                    return <DisplayEmoji key={e.emoji} on_click={on_click} emoji={e.emoji} label={e.label}/>
                  })}
                  {all_tags.filter((e) => search_regex.test(e.tag.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()))
                    .map(({
                            tag,
                            emojis,
                          }) => {
                      return <DisplayCategory key={tag} tag={tag} emojis={emojis} on_click={on_click}/>
                    })}
                </div> : <div className="col-12">
                  {all_tags.filter((t, i) => i < 15).map(({ tag }) => {
                    return <FlatChoice on_click={() => set_search(tag)}
                                       className={'mr-1 mb-1 text-uppercase small font-weight-bold px-1'}
                                       is_current={search === tag}><span className="small">{tag}</span></FlatChoice>
                  })}
                  {all_tags.filter((e, i) => i > 0 && e.emojis.length > 5).map(({ tag, emojis }) => {
                    return <DisplayCategory key={tag} tag={tag} emojis={emojis} on_click={on_click}/>
                  })}
                </div>}

              </div>
            </div>
          </div>
        </Item>
      </div>
    </div>
  )
}


export const EmojiToggle = ({ additional_warning, on_click }) => {
  const french = useFrench()

  const [open_picker, set_open_picker] = React.useState(false)

  return <div className="row">
    <div className="col-12">
      <Button className="btn-sm" onClick={() => set_open_picker(!open_picker)}>
        {open_picker ? french(`Fermer les émojis`):french(`Insérer un émoji`)}
      </Button>
    </div>

    {open_picker ? <>

      <div className="col-12">
        <EmojiPicker on_click={(emoji) => {
          on_click(emoji)
        }}/>
      </div>

      <div className="col-12">
        <div className="alert alert-warning mt-2 small">
          {additional_warning}
        </div>
      </div>
    </> : null}

  </div>

}
