import React, { useContext } from 'react'
import Item, { CompactItem } from '../generic/item'
import { get_client } from '../../graphql/simple-client'
import { GET_PRODUCTS_HIERARCHY } from '../../graphql/queries/product'
import { create_mutation, create_query } from '../../graphql/queries/_query_and_mutations_templates'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import {
  get_referral_override_with_coupon,
  get_user_referral_id,
  get_stored_referral,
  get_stored_utm,
} from '../referral/referral'
import UserContext from '../../context/user'
import { get_intellifox_url, navigate } from '../../absolute_link'
import useCompanyId from '../../hooks/useCompanyId'
import Button from '../generic/button'
import { FaAmazon, FaCheck, FiArrowRight, FiCheck, FiPlus, FiRefreshCw } from 'react-icons/all'
import { useMutation } from '@apollo/client'
import DeleteButton from '../generic/delete-button'
import UpdateButtonV2 from '../generic/update_button_v2'
import { await_mutate } from '../../graphql/mutate_promise'
import { MdWarning } from 'react-icons/md'
import NavigationButton from '../generic/navigation-button'
import IntellifoxIcon from '../../images/intellifox-icon'
import { displayFields } from '../generic/field'
import PasswordHelper from './password-rule'
import { DefaultErrorResolution, DefaultLoading } from '../i18n/translations'
import { useExternalDependency } from '../../hooks/useExternalDependencies'

import { lwa_return_urls, login_with_amazon_id } from '../../common/global_variables.json'
import CrispButton from '../generic/crisp_button'

// Manage it here:
// https://developer.amazon.com/settings/console/securityprofile/web-settings/view.html?showDeleted=false&identityAppFamilyId=amzn1.application.2118f253b28245e393d9dfc95494949f

// Documentation here:
// https://developer.amazon.com/fr/docs/login-with-amazon/authorization-code-grant.html


const useLoginWithAmazon = () => {
  return useExternalDependency({
    src: 'https://assets.loginwithamazon.com/sdk/na/login1.js',
    after: () => {
      window.amazon.Login.setClientId(login_with_amazon_id)
    },
  })
}

export const AddAmazonAdsAccount = () => {
  useLoginWithAmazon()
  const translate = useTranslate()


  return <div className="row">
    <div className="col-12">
      <Button onClick={() => {
        window.amazon.Login.authorize(
          {
            scope: 'cpc_advertising:campaign_management profile',
            response_type: 'code',
            popup: false,
          },
          lwa_return_urls.add_amazon_ads_account,
        )
      }}
      >
        <FiPlus/> <FaAmazon/> {translate({
        fr: `Ajouter un compte Amazon Ads`,
        en: `Add an Amazon Ads account`,
      })}
      </Button>
    </div>
  </div>
}

export default function LoginWithAmazon({ scope = 'profile', connect_or_create_account }) {
  useLoginWithAmazon()
  const french = useFrench()
  const translate = useTranslate()


  let label
  let url
  if (connect_or_create_account === 'connect') {
    label = translate({
      fr: `Se connecter avec Amazon`,
      en: `Login with Amazon`,
    })
    url = lwa_return_urls.login
  } else {
    label = translate({
      fr: `Créer un compte avec Amazon`,
      en: `Create an account with Amazon`,
    })
    url = lwa_return_urls.create_account
  }
  return (
    <div className="row">
      <div className="col-12">
        <Button className={'btn-lg btn-block'} onClick={() => {
          window.amazon.Login.authorize(
            {
              scope,
              response_type: 'code',
              popup: false,
            },
            url,
          )
        }}
        >
          <FaAmazon/> {label} <FiArrowRight/>
        </Button>
      </div>
    </div>
  )
}

export const get_code_from_url = () => {
  const search = new URLSearchParams(window.location.search)
  const code = search.get('code')

  return code
}


const ConfirmPassword = ({ set_user, email }) => {
  const french = useFrench()
  const user = React.useContext(UserContext)
  const [password, set_password] = React.useState('')
  const [error, set_error] = React.useState(false)
  const [loading, set_loading] = React.useState(false)

  const fields = [
    {
      type: 'email',
      value: email,
      disabled: true,
      setter: () => {
      },
      placeholder: french(`Adresse e-mail`),
    },
    {
      type: 'password',
      value: password,
      setter: set_password,
      placeholder: french('Entre ton mot de passe'),
      helper: <PasswordHelper/>,
    },
  ]

  const [confirm_lwa_with_password] = useMutation(create_mutation({
    mutation: 'confirm_lwa_with_password',
    params: {
      email: 'String!',
      password: 'String!',
      expires: 'Int!',
    },
    output: `{
              token,
              valid_until,
              user {
                user_id
                email
                first_name
                last_name
                amazon_user_id
                amazon_email
              }
              company {
                company_id
                label
              }
            }`,
  }))

  const on_submit = async () => {
    try {
      //await login(email, password)
      set_loading(true)
      set_error(false)
      const response = await await_mutate(confirm_lwa_with_password, {
        variables: {
          email,
          password,
          expires: 8 * 60 * 60 * 24,
        },
      })

      set_user({
        ...response.confirm_lwa_with_password,
      })

      await user.refresh()
      set_loading(false)

      await navigate('/')
    } catch (e) {
      set_loading(false)
      set_error(e)
    }
  }
  return <div className="col-12">
    <div className="row">
      <div className="col-12 font-weight-bold">
        <h1>
          <IntellifoxIcon/> {french(`Première connexion avec Amazon`)}
        </h1>
      </div>
      <div className="col-12 mb-2">
        {french(`Confirme ton mot de passe actuel pour Intellifox :`)}
      </div>

      <div className="col-12">
        {displayFields(fields, on_submit)}
      </div>

      <div className="col-12">
        {loading ? <DefaultLoading/> : null}
        {error ? <><p
          className="font-weight-bold">{french(`Erreur : le mot de passe ne semble pas être correct`)}</p></> : null}
      </div>
      <div className="col-12 text-right" onClick={on_submit}>
        <Button>{french(`Confirmer mon mot de passe`)}</Button>
      </div>
    </div>

  </div>
}


export const LWAReturnLogin = ({ set_user, query }) => {
  const lang = useLang()
  const french = useFrench()
  const user = React.useContext(UserContext)


  const [email, set_email] = React.useState(null)
  const [loading, set_loading] = React.useState(true)
  const [error, set_error] = React.useState(null)

  const code = get_code_from_url()

  React.useEffect(() => {
    const go = async () => {
      const client = get_client()

      try {
        set_loading(true)

        const referral = get_stored_referral() || {}
        const utm = get_stored_utm()

        const referral_id = referral.referral_id

        const user_referral_id = get_user_referral_id()

        let params = {
          code: 'String',
        }
        if (query === 'create_account_with_amazon') {
          params = {
            ...params,
            lang: 'String',
            utm: 'String',
            referral_id: 'String',
            user_referral_id: 'String'
          }
        }

        const response = await client.request(create_query({
          query,
          params,
          output: `{
            success
            email
            error
            token {
              token,
              valid_until,
              user {
                user_id
                email
                first_name
                last_name
                amazon_user_id
                amazon_email
              }
              company {
                company_id
                label
              }
            }
          }`,
        }), {
          code,
          lang,
          utm: JSON.stringify(utm),
          referral_id,
          user_referral_id,
        })

        console.log(response)
        set_loading(false)

        const {
          success,
          error,
          email,
          token,
        } = response[ query ]

        if (success) {
          set_user({
            ...token,
          })

          await user.refresh()
          await navigate('/')
        } else {
          set_error(error)
          set_email(email)
        }
      } catch (e) {
        set_loading(false)

        console.log('error!', e)
        set_error('n/a')
      }
    }

    go().catch(e => console.error(e))
  }, [])


  return <div className="container">
    <div className="row">
      <div className="col-12">
        <CompactItem label={french(`Connexion avec Amazon en cours...`)}>
          <div className="row">
            <div className="col-12">
              {loading ? <DefaultLoading/> : null}

              {error === 'missing-code' ? <>{french(`Le code de connexion n'a pas pu être récupéré`)} "{error}"</> : null}

              {error === 'not-found' ? <>
                {french(`Aucun compte n'a été trouvé en lien avec ce compte Amazon.`)}
                <ul>
                  <li>{french(`Email de connexion Amazon :`)} {email}</li>
                </ul>
                <NavigationButton to={'/'}>{french(`Créer un nouveau compte`)}</NavigationButton>
              </> : null}

              {error === 'found-but-not-linked' ? <>
                {french(`Un compte avec le même email a été trouvé, mais il n'est pas lié au compte Amazon.`)}<br/>
                {french(`Pour des raisons de sécurité, voici la marche à suivre :`)}
                <ol>
                  <li>
                    <NavigationButton
                      to={`/settings#?email=${email}`}>
                      {french(`Se connecter à Intellifox avec l'email :`)} {email}
                    </NavigationButton>
                  </li>
                  <li>{french(`Se rendre dans "Paramètres"`)}</li>
                  <li>{french(`Dans la section "Coordonnées", cliquer sur "Utiliser un compte Amazon pour se connecter à Intellifox"`)}</li>
                </ol>
              </> : null}
              {error === 'account-already-exists' ? <>
                {french(`Un compte Intellifox existe déjà`)}<br/>
                {french(`Pour des raisons de sécurité, voici la marche à suivre :`)}
                <ol>
                  <li>
                    <NavigationButton
                      to={`/settings#?email=${email}`}>
                      {french(`Se connecter à Intellifox avec l'email :`)} {email}
                    </NavigationButton>
                  </li>
                  <li>{french(`Se rendre dans "Paramètres"`)}</li>
                  <li>{french(`Dans la section "Coordonnées", cliquer sur "Utiliser un compte Amazon pour se connecter à Intellifox"`)}</li>
                </ol>
              </> : null}

              {error === 'n/a' ? <DefaultErrorResolution route={'/'}
                                                         support_message={french(`Je rencontre le problème suivant: la connexion à Intellifox avec mon compte Amazon ne fonctionne pas.`)}/> : null}
            </div>
          </div>
        </CompactItem>
      </div>
    </div>
  </div>

}

export const LoginWithAmazonConfirmation = ({ set_user }) => {
  const french = useFrench()
  const user = React.useContext(UserContext)

  const lang = useLang()

  const [email, set_email] = React.useState('')
  const [loading, set_loading] = React.useState(false)
  const [error, set_error] = React.useState(false)

  const code = get_code_from_url()

  React.useEffect(() => {
    const go = async () => {
      const client = get_client()

      try {
        set_loading(true)

        const referral = get_stored_referral() || {}
        const utm = get_stored_utm()

        const referral_id = referral.referral_id

        const response = await client.request(create_query({
          query: 'login_with_amazon',
          params: {
            code: 'String',

            lang: 'String',
            utm: 'String',
            referral_id: 'String',
          },
          output: `{
            email
            needs_password_confirmation
            token {
              token,
              valid_until,
              user {
                user_id
                email
                first_name
                last_name
                amazon_user_id
                amazon_email
              }
              company {
                company_id
                label
              }
            }
          }`,
        }), {
          code,
          lang,
          utm: JSON.stringify(utm),
          referral_id,
        })

        console.log(response)
        set_loading(false)

        const {
          email,
          token,
        } = response.login_with_amazon

        if (token) {
          set_user({
            ...token,
          })

          await user.refresh()
          await navigate('/')
        } else {
          set_email(email)
        }
      } catch (e) {
        set_loading(false)

        console.log('error!', e)
        set_error(true)
      }
    }

    go().catch(e => console.error(e))
  }, [])


  return <div className="container">
    <div className="row">
      <div className="col-12">
        <Item>
          <div className="row">
            <div className="col-12">
              {loading ? french(`Connexion en cours...`) : null}
              {error ? <div className="alert alert-danger">
                <div className="row">
                  <div className="col-12 font-weight-bold mb-2">
                    <MdWarning/> {french(`Erreur lors de la connexion avec Amazon`)}
                  </div>
                  <div className="col-12 mb-2">
                    <p>
                      {french(`Attention, si tu as déjà un compte Intellifox, il faut d'abord faire la démarche en étant connecté avec ton compte Intellifox.`)}
                    </p>
                    <p>
                      {french(`Pour ça, c'est très simple :`)}
                      <ol>
                        <li>{french(`Connecte toi normalement sur Intellifox`)}</li>
                        <li>{french(`Va ensuite dans "Paramètres" et suis les instructions présentes.`)}</li>
                      </ol>
                    </p>
                  </div>

                  <div className="col-12">
                    <NavigationButton
                      to={'/settings'}>{french(`Retourner à l'écran d'identification`)}</NavigationButton>
                  </div>
                </div>

              </div> : null}
            </div>
            {email ? <ConfirmPassword email={email} set_user={set_user}/> : null}
          </div>
        </Item>
      </div>
    </div>
  </div>
}


export const DetachLoginWithAmazon = () => {
  const company_id = useCompanyId()
  const user = useContext(UserContext)
  const french = useFrench()

  const [detach_login_with_amazon] = useMutation(create_mutation({
    mutation: 'detach_login_with_amazon',
    params: {
      company_id: 'ID!',
    },
  }))

  const do_it = async () => {
    await await_mutate(detach_login_with_amazon, {
      variables: {
        company_id,
      },
    })
    await user.refresh()
  }

  return <UpdateButtonV2 trigger={do_it} className={'btn-sm'} button={'danger'}>
    {french(`Supprimer le lien entre ce compte Amazon et Intellifox`)}
  </UpdateButtonV2>
}

export const AttachLoginWithAmazon = () => {
  const user = useContext(UserContext)
  const french = useFrench()
  const translate = useTranslate()
  useLoginWithAmazon()

  const code = get_code_from_url()

  const has_amazon_user_id = user.user.amazon_user_id

  return (
    <div className="row">
      <div className="col-12">
        {has_amazon_user_id ? <>
            <div className="alert alert-success">
              <div className="row">
                <div className="col">
                  <FaCheck/> {french(`Compte Amazon lié :`)} <span
                  className="font-weight-bold small">{user.user.amazon_email}</span>

                </div>
                <div className="col-auto">
                  <DetachLoginWithAmazon/>
                </div>
              </div>
            </div>

          </> :
          <Button onClick={() => {
            window.amazon.Login.authorize(
              {
                scope: 'profile',
                response_type: 'code',
                popup: false,
              },
              lwa_return_urls.link_existing_account,
            )
          }}>
            <FaAmazon/> {translate({
            fr: `Utiliser un compte Amazon pour se connecter à Intellifox`,
            en: `Use your Amazon account to log into Intellifox`,
          })}
          </Button>
        }
      </div>
    </div>
  )
}


export const LWAReturnLinkExistingAccount = () => {
  const french = useFrench()
  const company_id = useCompanyId()
  const user = React.useContext(UserContext)


  const [loading, set_loading] = React.useState(false)
  const [success, set_success] = React.useState(null)
  const [error, set_error] = React.useState(null)

  const code = get_code_from_url()

  React.useEffect(() => {
    const go = async () => {
      const client = get_client()

      try {
        set_loading(true)

        const response = await client.request(create_query({
          query: 'link_existing_account_to_login_with_amazon',
          params: {
            company_id: 'ID!',
            code: 'String',
          },
          output: `{
            success
            error
          }`,
        }), {
          company_id,
          code,
        })
        set_loading(false)

        const { success, error } = response.link_existing_account_to_login_with_amazon

        if (success) {
          await user.refresh()
          set_success(true)
        } else {
          set_error(error)
        }
      } catch (e) {
        set_loading(false)

        console.log('error!', e)
        set_error('n/a')
      }
    }

    go().catch(e => console.error(e))
  }, [])

  return <div className="container pt-5">
    <div className="row">
      <div className="col-12">
        <CompactItem label={french(`Liaison du compte Intellifox avec le compte Amazon`)}>
          <div className="row">
            <div className="col-12">
              {loading ? <DefaultLoading/> : null}

              {error === 'amazon-account-already-linked' ? <div className="row">
                <div className="col-12">
                  {french(`Erreur : ce compte Amazon est déjà lié à un autre compte Intellifox.`)}
                </div>
              </div> : null}

              {error === 'n/a' ? <DefaultErrorResolution route={'/settings'}
                                                         support_message={french(`Je n'arrive pas à lier mon compte Amazon pour pouvoir me connecter à Intellifox.`)}/> : null}

              {success ? <div className="alert alert-success">
                <div className="row">
                  <div className="col-12">

                    <FiCheck/> {french(`Tu peux désormais te connecter à ton compte Intellifox avec le compte Amazon`)} <span
                    className="font-weight-bold small">{user.user.amazon_email}</span>

                  </div>
                  <div className="col-12 mt-2">
                    <NavigationButton to={'/'}>
                      {french(`Retourner à l'accueil`)}
                    </NavigationButton>
                  </div>
                </div>
              </div> : null}
            </div>
          </div>
        </CompactItem>
      </div>
    </div>
  </div>
}
