import React, { useContext } from 'react'
import UserSettings from './user'
import Item from '../../components/generic/item'
import ResetAccount from './reset_account'
import ActivateBetaFeatures from './activate_beta_features'
import useHashParam from 'use-hash-param'
import { useTranslate } from '../../context/lang'
import Maintenance from './maintenance'
import BackToHome from '../../components/navigation/back_to_home'
import BigBox from '../../components/layout/big_box'
import Title from '../../components/layout/title'
import UserContext from '../../context/user'
import { section_color, WelcomeIcon } from '../colors'
import { AmazonAccounts } from './amazon_accounts'
import { scroll_to_top } from '../product_v2/scroll'
import ReferenceMarketPlaceChooser from '../onboarding/ReferenceMarketPlaceChooser'
import { AmazonAdsAccounts } from './amazon_ads_accounts'
import TimezoneAndCurrency from './timezone_and_currency'
import { DataExport } from './data_export'

export default function Settings() {
  const translate = useTranslate()
  const user = useContext(UserContext)

  React.useEffect(() => {
    scroll_to_top()
  }, [])


  const sections = [
    // {
    //   key: 'login_with_amazon',
    //   component: ,
    //   label: translate({
    //     fr: 'Accéder à Intellifox avec son compte Amazon',
    //     en: 'Login to Intellifox with your Amazon Account',
    //   }),
    // },
    {
      key: 'amazon_accounts',
      component: <AmazonAccounts/>,
      label: translate({
        fr: 'Comptes Amazon',
        en: 'Amazon Accounts',
      }),
    },
    {
      key: 'amazon_ads_accounts',
      component: <AmazonAdsAccounts/>,
      label: translate({
        fr: 'Comptes Amazon Ads',
        en: 'Amazon Ads Accounts',
      }),
    },
    {
      key: 'user',
      component: <UserSettings/>,
      label: translate({
        fr: 'Coordonnées',
        en: 'User settings',
      }),
    },
    {
      key: 'source_market_place',
      component: <ReferenceMarketPlaceChooser show_extra={false}/>,
      label: translate({
        fr: 'Marketplace de référence',
        en: 'Reference Marketplace',
      }),
    },
    {
      key: 'currency_timezone',
      component: <TimezoneAndCurrency/>,
      label: translate({
        fr: 'Devise et fuseau horaire',
        en: 'Time zone and currency',
      }),
    },
    {
      key: 'export_data',
      component: <DataExport/>,
      label: translate({
        fr: 'Export des données',
        en: 'Data export',
      }),
    },
    {
      key: 'maintenance',
      component: <Maintenance/>,
      label: translate({
        fr: 'Maintenance',
        en: 'Maintenance',
      }),

    },
    {
      key: 'danger',
      component: <ResetAccount/>,
      label: translate({
        fr: 'Zone de danger',
        en: 'Danger zone',
      }),
    },
    // {
    //   key: 'beta',
    //   component: <ActivateBetaFeatures/>,
    //   label: translate({
    //     fr: 'Bêta',
    //     en: 'Bêta',
    //   }),
    // },
  ]

  return (
    <>
      <div className="container">
        <BackToHome/>
        <Title color={section_color('settings')} style={{ color: 'black' }}><WelcomeIcon
          section={'settings'}/> {translate({
          fr: `Paramètres`,
          en: `Settings`,
        })}
        </Title>

        {sections.map(({ label, key, component, is_active }) => {
          return <BigBox key={key} title={label} is_active={is_active}>
            {component}
          </BigBox>
        })}
      </div>
    </>
  )
}
