import React from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { useFrench } from '../../../context/lang'
import { DisplayMoney, format_kilo } from '../../order/format_numbers'
import { FaArrowRight, FiEye, FiMousePointer, FiShoppingCart } from 'react-icons/all'
import { CompactItem, NiceConclusion } from '../../../components/generic/item'
import { useMarketPlaceDetails } from '../../../hooks/useMarketPlace'


export const Summary = ({
                          managed_campaign,
                          label,
                          reports,
                          on_click,
                          is_selected,
                          action,
                          from, to,
                        }) => {
  const french = useFrench()

  const { market_place } = managed_campaign
  const { currency } = useMarketPlaceDetails(market_place)

  const amazon_ads_cost = _.sumBy(reports, 'cost') // This already contains the VAT if the user needs to pay it
  const units = _.sumBy(reports, 'units')
  const impressions = _.sumBy(reports, 'impressions')
  const clicks = _.sumBy(reports, 'clicks')

  const cost_per_conversion = amazon_ads_cost / units


  let summary_bg
  let summary_label
  if (amazon_ads_cost === 0) {
    summary_bg = 'secondary'
    summary_label = '?'
  } else if (( cost_per_conversion * 0.9 ) <= managed_campaign.target) {
    summary_bg = 'success'
  } else {
    summary_bg = 'danger'
  }


  return <CompactItem label={<span className={"small"}>{from.format('L')} <FaArrowRight/> {to.format('L')}</span>} on_click={on_click} is_selected={is_selected}>
    <div className="row">
      {currency ? <div className="col-12">
        <div className="row no-gutters align-items-center">
          <div className="col-12 mb-2">
            <div className="row small">
              <div className="col-12 text-right">
                <Detail bg={'secondary'}><FiEye/> {format_kilo(impressions)}</Detail>
                <Detail bg={'secondary'}><FiMousePointer/> {format_kilo(clicks)}</Detail>
                <Money value={-amazon_ads_cost} currency={currency}/>
                <Detail bg={summary_bg}><FiShoppingCart/> {units}</Detail>
              </div>
            </div>
          </div>
          <div className="col-12">
            <NiceConclusion label={label} bg={summary_bg} conclusion={<div className={'text-center'}>
              {summary_label} <DisplayMoney value={cost_per_conversion} currency={currency}/> / <FiShoppingCart/>
            </div>} action={action}/>
          </div>
          {action ? <>

            <div className="col-12">
              <hr className={'my-1'}/>
            </div>
            <div className="col-12">
              {action}
            </div>
          </> : null}
        </div>
      </div> : <div className="col-12 font-italic small">
        {french(`Aucune donnée`)}
      </div>}
    </div>
  </CompactItem>
}

export const Money = ({ currency, value, icon, bg = 'secondary' }) => {

  return <Detail bg={bg}>{icon ?
    <span className={'mr-2'}>{icon}</span> : null} <DisplayMoney value={value} currency={currency}/></Detail>
}

export const Detail = ({ bg = 'secondary', children, className = '', style = {}, on_click }) => {
  return <div
    className={`d-inline-block bg-${bg} ${bg !== 'light' ? 'text-white' : ''} font-weight-bold rounded p-1 px-2 mb-1 mr-1 text-nowrap ${className} ${on_click ? 'clickable' : ''}`}
    style={style} onClick={on_click}>
    {children}
  </div>
}


