import React from 'react'
import { get_client } from '../../graphql/simple-client'
import { GET_ORDERS } from '../../graphql/queries/order'
import useCompanyId from '../../hooks/useCompanyId'
import throw_confetti, { throw_confetti_on_mouse_position } from '../../components/generic/confetti'
import { useOrderNotifications } from '../../context/order_notifications'
import useSound from 'use-sound'
import notifications from '../../sounds/notifications.mp3'
import moment from 'moment'
import { Howl, Howler } from 'howler'
import { useTranslate } from '../../context/lang'
import { format_money } from './format_numbers'

const fetch_orders = async (company_id, from, to) => {
  const client = get_client()
  const response = await client.request(GET_ORDERS, {
    company_id, from, to,
  })
  return response.orders
}

const sound = new Howl({
  src: [notifications],
  sprite: {
    cashier: [0, 3050],
    intellifox: [3050, 4100],
    space: [7110, 4500],
  },
})


export const trigger_notification = ({ order_notifications, text, body }) => {
  if (order_notifications.activate) {
    console.log('yo!', order_notifications)

    if (order_notifications.sound) {
      console.log('play sound!', order_notifications.sound)
      sound.play(order_notifications.sound)
    }
    if (order_notifications.confetti) {
      throw_confetti_on_mouse_position(null, 0.5, 0.75)
    }
    if (order_notifications.notification) {
      create_notification(text, {
        body,
        icon: 'https://app.intellifox.com/fox_v2_white_cropped.png',
      })
    }
  }

}

export const create_notification = (title, options) => {

  if (window.Notification && Notification.permission === 'granted') {
    const n = new Notification(title, options)
  } else if (window.Notification && Notification.permission !== 'denied') {
    Notification.requestPermission(function (status) {
      if (Notification.permission !== status) {
        Notification.permission = status
      }

      // Si l'utilisateur est OK
      if (status === 'granted') {
        const n = new Notification(title, options)
      }

      // Sinon, revenons en à un mode d'alerte classique
      else {
        alert(title)
      }
    })
  } else {
    alert(title)
  }
}

export default function OrderNotification({}) {
  const company_id = useCompanyId()

  const translate = useTranslate()

  const [play] = useSound(notifications, {
    sprite: {
      cashier: [0, 3050],
      intellifox: [3050, 4100],
      space: [7110, 4500],
    },
  })
  const { order_notifications } = useOrderNotifications()

  const orders_ref = React.useRef(undefined)

  const check_orders = React.useCallback(() => {
    const do_it = async () => {
      console.log('check orders for the last hour...')
      const new_orders = await fetch_orders(company_id, Date.now() - 60 * 60 * 1000, Date.now())

      console.log(moment().format(), 'new_orders', new_orders)
      const orders = orders_ref.current

      if (orders) {
        let has_new_order
        let newly_found_order
        for (let i = 0; i < new_orders.length; i++) {
          const new_order = new_orders[ i ]
          let found
          for (let j = 0; j < orders.length; j++) {
            const order = orders[ j ]
            if (order.order_id === new_order.order_id) {
              found = true
            }
          }
          if (!found) {
            has_new_order = true
            newly_found_order = new_order
          }
        }

        if (has_new_order) {
          let additional_text = ''
          if (newly_found_order && newly_found_order.items && newly_found_order.items.length > 0) {
            for (let i = 0; i < newly_found_order.items.length; i++) {
              const item = newly_found_order.items[ i ]

              additional_text = `${additional_text}\n${item.quantity}x ${item.title.substring(0, 30)}${item.title.length >= 30 ? '...' : ''}`
            }
          }
          trigger_notification({
            order_notifications,
            play,
            text: translate({
              fr: 'Nouvelle vente',
              en: 'New sale',
            }),
            body: `${translate({
              fr: 'Félicitations !',
              en: 'Congrats!',
            })}${additional_text}`,
          })
        }
      }

      orders_ref.current = new_orders
    }

    do_it().catch(e => console.log(e))
  }, [company_id, order_notifications, play])


  React.useEffect(() => {
    check_orders()
    const checker = setInterval(check_orders, 60 * 1000)

    return () => {
      console.log('clean order notification checker!')
      clearInterval(checker)
    }
  }, [])


  return <div style={{ width: 1, height: 1, display: 'none' }}></div>
}
