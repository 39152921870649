import React from 'react'
import { create_query } from '../../../graphql/queries/_query_and_mutations_templates'
import { useFetchV2, useQuery } from '../../../context/fetch_v2'
import { CachedValues, useCachedContext } from '../../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import { IntellifoxModalComponent } from '../../../components/generic/modal'
import TextButton from '../../../components/generic/text_button'
import { useFrench, useLang } from '../../../context/lang'
import moment from 'moment'
import Item from '../../../components/generic/item'
import { format_money, format_percentage } from '../../order/format_numbers'
import { FiArrowRight, FiChevronRight } from 'react-icons/all'
import { useMarketPlaceDetails } from '../../../hooks/useMarketPlace'

import * as _ from 'lodash'

export const ShowManagedCampaignLogs = ({ managed_campaign }) => {
  const french = useFrench()
  const [show, set_show] = React.useState(false)
  const get_managed_campaign_logs = useQuery({
    query: 'managed_campaign_logs',
    validate_user: true,
    params: {
      campaign_id: 'String',
    },
    output: `{
      date
      logs
    }`,
  })

  return <CachedValues fetch_value={get_managed_campaign_logs} name={'managed_campaign_logs'}>
    <TextButton className={'btn-sm'}
                onClick={() => set_show(true)}>{french(`Voir l'historique des enchères appliquées`)}</TextButton>
    <IntellifoxModalComponent show_modal={show} close={() => set_show(false)}
                              title={french(`Historique des enchères appliquées`)}
                              body={show ?
                                <ManagedCampaignLogs managed_campaign={managed_campaign}/> : null}

    />
  </CachedValues>
}


const ManagedCampaignLogs = ({ managed_campaign }) => {
  const logs = useCachedContext('managed_campaign_logs', managed_campaign.campaign_id)

  if (logs.loading) {
    return <DefaultLoading/>
  }

  if (logs.error) {
    return <DefaultError/>
  }


  return <>{logs.map(({ logs, date }, i) => {

    return <ManagedCampaignLog key={i} managed_campaign={managed_campaign} date={date} logs={JSON.parse(logs)}/>
  })}
  </>

}

const ManagedCampaignLog = ({ managed_campaign, date, logs }) => {
  const french = useFrench()

  if (!logs) {
    return null
  }

  const {
    automated_bids: {
      keywords, keywords_stats,
      targets, targets_stats,
    },
  } = logs
  return <div className="row">
    <div className="col-12 font-weight-bold" onClick={() => console.log(logs)}>
      {moment(date).format('lll')}
    </div>
    <div className="col-12 mb-3">
      <Item>
        <ShowStats managed_campaign={managed_campaign}
                   keywords_or_targets={keywords}
                   keywords_or_targets_stats={keywords_stats}
                   label={french(`mot(s)-clé(s)`)}/>
        <ShowStats managed_campaign={managed_campaign}
                   keywords_or_targets={targets}
                   keywords_or_targets_stats={targets_stats}
                   label={french(`cible(s)`)}/>
      </Item>
    </div>
  </div>
}

const ShowStats = ({ managed_campaign, label, keywords_or_targets, keywords_or_targets_stats }) => {
  const french = useFrench()
  const lang = useLang()
  const { currency } = useMarketPlaceDetails(managed_campaign.market_place)
  const [show_details, set_show_details] = React.useState(false)
  if (keywords_or_targets.length === 0) {
    return null
  }

  const sorted_keywords_or_targets = _.reverse(_.sortBy(keywords_or_targets, 'local_confidence'))

  return <div className="row mb-2">
    <div className="col-12 font-weight-bold small">
      {keywords_or_targets.length} {label} <FiChevronRight/>
      {french(`Taux de conversion :`)} {format_percentage(keywords_or_targets_stats.conversion_rate, 2)}
    </div>
    <div className="col-12">
      <TextButton className={'btn-sm'}
                  onClick={() => set_show_details(!show_details)}>{french(`Voir le détail`)}</TextButton>
    </div>
    {show_details ? <div className="col-12 mb-2">
      <Item no_padding={true}>
        <table className="table table-condensed">
          <thead>
          <tr>
            <th>{label}</th>
            <th>{french(`Indice de confiance`)}</th>
            <th>{french(`Nouvelle enchère`)}</th>
          </tr>
          </thead>
          <tbody>
          {sorted_keywords_or_targets.map(({ keyword_text, global_confidence, local_confidence, new_bid }) => {
            let confidence = 'secondary'
            let french_confidence_label =  `Très faible`
            if (global_confidence + local_confidence > 1.5) {
              confidence = 'success'
              french_confidence_label = `Fort`
            } else if (global_confidence + local_confidence > 1) {
              confidence = 'warning'
              french_confidence_label = `Moyen`
            } else if (global_confidence + local_confidence > 0.5) {
              confidence = 'danger'
              french_confidence_label = `Faible`
            }
            return <tr>
              <td>"{keyword_text}"</td>
              <td><span className={`badge badge-${confidence}`}>{french(french_confidence_label)}</span></td>
              <td>{format_money(lang, new_bid, currency, 2)}</td>
            </tr>
          })}

          </tbody>
        </table>
      </Item>
    </div> : null}
  </div>

}
