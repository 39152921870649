import React from 'react'
import Item, { ItemTitle } from '../../components/generic/item'
import { useCachedContext, useResetCachedValues } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import moment from 'moment'
import TextButton from '../../components/generic/text_button'
import { useFrench, useTranslate } from '../../context/lang'
import InternalLink from '../../components/generic/internal-link'
import Button from '../../components/generic/button'
import * as _ from 'lodash'
import Flag from '../../components/flag/flag'
import { Checkbox } from '../../components/generic/checkbox'
import useLocalStorage from '../../hooks/useLocalStorage'
import { OrdersAutoUpdater } from './orders_auto_updater'
import OrdersGraphCumulated from './orders_graph_cumulated'
import OrdersSummary from './orders_summary'
import { FaArrowLeft, FiCalendar, FiFilter } from 'react-icons/all'
import BackToHome from '../../components/navigation/back_to_home'
import BigBox from '../../components/layout/big_box'
import Title from '../../components/layout/title'
import { FaArrowRight, FaLongArrowAltRight } from 'react-icons/fa'
import { section_color, WelcomeIcon } from '../colors'
import ProductsFromOrders from './products_from_orders'
import NavigationButton from '../../components/generic/navigation-button'
import { OnlyPremium } from '../../components/subscription/start_free_trial'
import { useUserCurrency } from '../subscription/upgrade'
import IntellifoxModal from '../../components/generic/modal'
import { navigate } from '../../absolute_link'
import Field from '../../components/generic/field'
import OrdersGraphV2 from './orders_graph_v2'
import OrdersTableV2 from './orders_table_v2'
import { FiSettings } from 'react-icons/fi'
import { useMetaDataAutoUpdate } from '../../components/user/meta_data'
import { ShareResultsButton } from './results/share_results'

export const status_translations = {
  'Shipped': {
    en: 'Shipped',
    fr: 'Envoyée',
  },
  'Canceled': {
    en: 'Canceled',
    fr: 'Annulée',
  },
  'Returned': {
    en: 'Returned',
    fr: 'Renvoyée',
  },
  'PartiallyReturned': {
    en: 'Partially Returned',
    fr: 'Partiellement renvoyée',
  },
  'Pending': {
    en: 'Pending',
    fr: 'En attente',
  },
  'Unshipped': {
    en: 'Unshipped',
    fr: 'Pas encore envoyée',
  },
  'null': {
    en: 'Unshipped',
    fr: 'Pas encore envoyée',
  },
}


const OrdersLoaded = ({ from, to }) => {

  const translate = useTranslate()
  const french = useFrench()
  const selected_currency = useUserCurrency()

  const reset_products_costs_cache = useResetCachedValues('product_costs')
  React.useEffect(() => {
    reset_products_costs_cache()
  }, [])
  const amazon_accounts = useCachedContext('amazon_accounts')
  const amazon_ads_accounts = useCachedContext('amazon_ads_accounts')
  const optimized_orders = useCachedContext('optimized_orders', from.valueOf(), to.valueOf(), selected_currency.code)
  const product_ads_reports = useCachedContext('all_product_ads_reports', from.valueOf(), to.valueOf(), selected_currency.code)

  const [filter, set_filter] = useLocalStorage('order_filters_v2', {
    merchant_id: [],
    market_place: [],
    status: [],
    order_type: [],
  })
  const has_filter_applied = !( filter.merchant_id.length === 0 && filter.market_place.length === 0 && filter.status.length === 0 && filter.order_type.length === 0 )

  const [show_filters, set_show_filters] = React.useState(has_filter_applied)

  const toggle_filter = (filter_type, value, reset) => {
    const previous_filter = filter[ filter_type ]

    let new_value_for_filter_type
    if (reset) {
      new_value_for_filter_type = previous_filter.length === 0 ? ['none'] : []
    } else {
      new_value_for_filter_type = previous_filter.indexOf(value) === -1 ?
        [...previous_filter, value] : previous_filter.filter(v => v !== value)
    }

    set_filter({
      ...filter,
      [ filter_type ]: new_value_for_filter_type,
    })
    set_show_filters(true)
  }


  const [selected_asins, set_selected_asins] = React.useState([])

  const is_asin_selected = React.useCallback((asin) => {
    return selected_asins.indexOf(asin) !== -1
  }, [selected_asins])

  const toggle_asins = React.useCallback((asins) => {
    let new_selected_asins = [...selected_asins]
    for (let i = 0; i < asins.length; i++) {
      const asin = asins[ i ]

      if (selected_asins.indexOf(asin) !== -1) {
        new_selected_asins = new_selected_asins.filter((a) => a !== asin)
      } else {
        new_selected_asins = [...new_selected_asins, asin]
      }
    }
    set_selected_asins(new_selected_asins)
  }, [selected_asins])

  if (amazon_accounts.loading || amazon_ads_accounts.loading || optimized_orders.loading || product_ads_reports.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error || amazon_ads_accounts.error || optimized_orders.error || product_ads_reports.error) {
    console.log('Error in main order/index', optimized_orders.error)
    return <DefaultError/>
  }

  const { orders, products } = optimized_orders

  if (orders.length === 0) {
    return <BigBox>
      <div className="row">
        <div className="col-12 col-md-6">
          <h3>{translate({
            fr: `Aucune vente pour ce mois-ci`,
            en: `No sales for this month`,
          })} </h3>
        </div>
        <div className="col-12 col-md-6">
          <OrdersAutoUpdater update_orders={() => optimized_orders.refresh()}/>
        </div>
      </div>
    </BigBox>
  }

  let filtered_orders = orders.filter((o) => {
    let is_selected = true
    if (filter.market_place.length !== 0) {
      is_selected = is_selected && filter.market_place.indexOf(o.market_place) !== -1
    }
    if (filter.status.length !== 0) {
      is_selected = is_selected && filter.status.indexOf(o.status) !== -1
    }
    if (filter.order_type.length !== 0) {
      is_selected = is_selected && filter.order_type.indexOf(o.order_type) !== -1
    }

    if (filter.merchant_id.length !== 0) {
      is_selected = is_selected && filter.merchant_id.indexOf(o.merchant_id) !== -1
    }

    return is_selected
  })

  let filtered_products = products
  let filtered_ads = product_ads_reports.filter((p) => {
    let is_selected = true
    if (filter.market_place.length !== 0) {
      is_selected = is_selected && filter.market_place.indexOf(p.market_place) !== -1
    }
    if (selected_asins.length > 0) {
      is_selected = is_selected && selected_asins.indexOf(p.asin) !== -1
    }
    return is_selected
  })

  if (selected_asins.length > 0) {
    // keep only selected items
    filtered_orders = filtered_orders.filter((o) => {
      if (selected_asins.length > 0) {
        let has_asin_in_products = false
        for (let i = 0; i < o.products.length; i++) {
          const { asin } = o.products[ i ]
          if (selected_asins.indexOf(asin) !== -1) {
            has_asin_in_products = true
          }
        }
        return has_asin_in_products
      }
      return true
    }).map((o) => {
      return {
        ...o,
        products: o.products.filter(({ asin }) => selected_asins.indexOf(asin) !== -1),
      }
    })
    filtered_products = products.filter((p) => {
      if (selected_asins.length > 0) {
        return selected_asins.indexOf(p.asin) !== -1
      }
      return true
    })
  }

  const orders_grouped_by_merchant_id = _.groupBy(orders, 'merchant_id')
  const orders_grouped_by_market_place = _.groupBy(orders, 'market_place')
  const orders_grouped_by_status = _.groupBy(orders, 'status')
  const orders_grouped_by_order_type = _.groupBy(orders, 'order_type')


  const filters = <div className="col-12 mt-4 mb-4">
    <Item>
      <div className="row">
        <div className="col-4">
          {Object.keys(orders_grouped_by_merchant_id).length > 1 ? <>
            <h4>{french(`Compte Amazon`)}</h4>
            <div className="row">
              <Checkbox value={filter.merchant_id.length === 0}
                        setter={() => toggle_filter('merchant_id', null, true)}>
                {translate({
                  fr: `Tous`,
                  en: `All`,
                })} <span
                className="small">({orders.filter((o) => {
                return filter.merchant_id.length === 0 || filter.merchant_id.indexOf(o.merchant_id) !== -1
              }).length})</span>
              </Checkbox>
              {Object.keys(orders_grouped_by_merchant_id).map((merchant_id) => {
                const is_selected = filter.merchant_id.indexOf(merchant_id) !== -1

                if (!show_filters) {
                  if (!is_selected) {
                    return null
                  }
                }

                const orders_for_merchant_id_with_market_place_applied = orders_grouped_by_merchant_id[ merchant_id ].filter((o) => {
                  return filter.market_place.length === 0 || filter.market_place.indexOf(o.market_place) !== -1
                })

                const amazon_account = amazon_accounts.find((a) => a.merchant_id === merchant_id)
                const label = amazon_account ? amazon_account.label : ''
                return <Checkbox value={is_selected} setter={() => toggle_filter('merchant_id', merchant_id)}>
                  {label} <span className="small">({merchant_id})</span> <span
                  className="small">({orders_for_merchant_id_with_market_place_applied.length})</span>
                </Checkbox>

              })}

            </div>
          </> : null}

          <h4>{french(`Marketplace`)}</h4>
          <div className="row">
            <Checkbox value={filter.market_place.length === 0}
                      setter={() => toggle_filter('market_place', null, true)}>
              {translate({
                fr: `Toutes`,
                en: `All`,
              })} <span
              className="small">({orders.filter((o) => {
              return filter.status.length === 0 || filter.status.indexOf(o.status) !== -1
            }).length})</span>
            </Checkbox>
            {Object.keys(orders_grouped_by_market_place).map((market_place) => {
              const is_selected = filter.market_place.indexOf(market_place) !== -1
              if (!show_filters) {
                if (!is_selected) {
                  return null
                }
              }
              const orders_for_market_place_with_status_applied = orders_grouped_by_market_place[ market_place ].filter((o) => {
                return filter.status.length === 0 || filter.status.indexOf(o.status) !== -1
              })


              return <Checkbox key={market_place} value={is_selected}
                               setter={() => toggle_filter('market_place', market_place)}>
                <Flag market_place={market_place}/> {market_place.toUpperCase()} <span
                className="small">({orders_for_market_place_with_status_applied.length})</span>
              </Checkbox>
            })}
            {filter.market_place.map((market_place) => {
              const is_present = Object.keys(orders_grouped_by_market_place).indexOf(market_place) !== -1

              if (is_present || market_place === 'none') {
                return null
              }

              return <Checkbox key={market_place} value={true}
                               setter={() => toggle_filter('market_place', market_place)}>
                <Flag market_place={market_place}/> {market_place.toUpperCase()} <span
                className="small">(0)</span>
              </Checkbox>
            })}
          </div>
        </div>
        <div className="col-4">
          <h4>{translate({
            fr: `État de la commande`,
            en: `Order status`,
          })}</h4>
          <div className="row">
            <Checkbox value={filter.status.length === 0}
                      setter={() => toggle_filter('status', null, true)}>
              {translate({
                fr: `Tous`,
                en: `All`,
              })} <span
              className="small">({orders.filter((o) => {
              return filter.market_place.length === 0 || filter.market_place.indexOf(o.market_place) !== -1
            }).length})</span>
            </Checkbox>
            {Object.keys(orders_grouped_by_status).map((status) => {
              const is_selected = filter.status.indexOf(status) !== -1

              if (!show_filters) {
                if (!is_selected) {
                  return null
                }
              }

              const orders_for_status_with_market_place_applied = orders_grouped_by_status[ status ].filter((o) => {
                return filter.market_place.length === 0 || filter.market_place.indexOf(o.market_place) !== -1
              })

              return <Checkbox value={is_selected} setter={() => toggle_filter('status', status)}>
                {translate(status_translations[ status ])} <span
                className="small">({orders_for_status_with_market_place_applied.length})</span>
              </Checkbox>

            })}
          </div>
        </div>
        <div className="col-4">
          <h4>{translate({
            fr: `Type de commande`,
            en: `Order type`,
          })}</h4>
          <div className="row">
            <Checkbox value={filter.order_type.length === 0}
                      setter={() => toggle_filter('order_type', null, true)}>
              {translate({
                fr: `Tous`,
                en: `All`,
              })} <span
              className="small">({orders.filter((o) => {
              return filter.market_place.length === 0 || filter.market_place.indexOf(o.market_place) !== -1
            }).length})</span>
            </Checkbox>
            {Object.keys(orders_grouped_by_order_type).map((order_type) => {
              const is_selected = filter.order_type.indexOf(order_type) !== -1
              if (!show_filters) {
                if (!is_selected) {
                  return null
                }
              }
              const orders_for_status_with_market_place_applied = orders_grouped_by_order_type[ order_type ].filter((o) => {
                return ( filter.market_place.length === 0 || filter.market_place.indexOf(o.market_place) !== -1 ) &&
                  ( filter.status.length === 0 || filter.status.indexOf(o.status) !== -1 )
              })

              return <Checkbox value={is_selected} setter={() => toggle_filter('order_type', order_type)}>
                {order_type} <span
                className="small">({orders_for_status_with_market_place_applied.length})</span>
              </Checkbox>

            })}
          </div>
        </div>
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-auto">
              {show_filters ? <Button onClick={() => {
                set_show_filters(false)
              }}>{translate({
                fr: `Réduire les filtres`,
                en: `Close filters`,
              })}</Button> : <TextButton onClick={() => {
                set_show_filters(true)
              }}>{translate({
                fr: `Filtrer les commandes`,
                en: `Filter orders`,
              })}</TextButton>}
            </div>
            <div className="col-auto">
              <Button button={'secondary'} onClick={() => {
                set_show_filters(false)
                set_filter({
                  merchant_id: [],
                  market_place: [],
                  status: [],
                  order_type: [],
                })
                set_selected_asins([])
              }}>{translate({
                fr: `Remettre à zéro les filtres`,
                en: `Reset filters`,
              })}</Button>
            </div>
          </div>
        </div>
      </div>
    </Item>
  </div>

  return <>
    <div className="row">
      <div className="col-12">
        <ItemTitle action={<>
          <OrderDisplayOptions/>
          <Button className="btn-sm" onClick={() => set_show_filters(!show_filters)}
                  button={show_filters ? 'primary' : 'secondary'}><FiFilter/> {translate({
            fr: `Filtrer`,
            en: `Filter`,
          })}</Button>
        </>}
        >{orders.filter((o) => o.status !== 'Canceled').length} {translate({
          fr: `commande(s)`,
          en: `order(s)`,
        })}</ItemTitle>
      </div>
    </div>

    <div className="row">
      {show_filters ? filters : null}
    </div>

    <div className="row">
      <div className="col-12">
        <ProductsFromOrders products={products}
                            orders={filtered_orders}
                            ads={filtered_ads}
                            toggle_asins={toggle_asins}
                            is_asin_selected={is_asin_selected}
                            from={from} to={to} amazon_ads_accounts={amazon_ads_accounts}
                            amazon_accounts={amazon_accounts} currency={selected_currency.code}

        />
      </div>
    </div>

    {filtered_orders.length === 0 ? null : <div className="row">
      <div className="col-12 text-center">
        {filtered_orders.length !== orders.length ?
          <h4>{filtered_orders.length} {translate({
            fr: `commande(s)`,
            en: `order(s)`,
          })} {translate({
            fr: `pour les filtres sélectionnés`,
            en: `match selected filters`,
          })}</h4> :
          null
        }
      </div>
    </div>}

    <div className="row mt-5">
      <div className="col-12">
        <OrdersSummary orders={filtered_orders} products={filtered_products} ads={filtered_ads} show_break_down={true}
                       from={from} to={to}/>
      </div>
      <div className="col-12 text-center">
        <OrdersAutoUpdater update_orders={() => optimized_orders.refresh()}/>
      </div>
    </div>


    <div className="row mt-5">
      <div className="col-12">
        <OrdersGraphV2 orders={filtered_orders} from={from} to={to} ads={filtered_ads} show_legend={true}/>
      </div>
    </div>
    <div className="row mt-5">
      <div className="col-12">
        <OrdersGraphCumulated orders={filtered_orders} from={from} to={to} ads={filtered_ads}/>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12">
        <Item no_padding={true}>
          <OrdersTableV2 orders={filtered_orders} products={products} currency={selected_currency.code}/>
        </Item>
      </div>
    </div>
  </>

}

export const to_upper_first = (str) => {
  return `${str[ 0 ].toUpperCase()}${str.substring(1).toLowerCase()}`
}


const CustomDate = ({ from, to }) => {
  const french = useFrench()
  const [new_from, set_new_from] = React.useState(new Date(from))
  const [new_to, set_new_to] = React.useState(new Date(to))
  return <IntellifoxModal title={french(`Choisir la période`)}
                          body={<div className="row">
                            <Field col="6" type={'date'} value={new_from} setter={set_new_from}
                                   label={french(`Date de début`)}/>
                            <Field col="6" type={'date'} value={new_to} setter={set_new_to}
                                   label={french(`Date de fin`)}/>
                          </div>}
                          action_label={french(`Sélectionner cette période`)}
                          on_click={() => {
                            navigate(`/order/period/${moment(new_from).format('YYYY-MM-DD')}/${moment(new_to).format('YYYY-MM-DD')}`)
                          }}
                          button_props={{
                            className: 'btn-sm mr-1',
                          }}
  >
    <FiCalendar/>
  </IntellifoxModal>
}

export default function Order({ from, to }) {
  const translate = useTranslate()
  const french = useFrench()

  from = moment(from).startOf('day')
  to = moment(to).endOf('day')

  const one_month_before = moment(from).add(-1, 'month')
  const one_month_after = moment(from).add(1, 'month')

  const is_month = moment(from).isSame(moment(from).startOf('month')) && moment(to).isSame(moment(from).endOf('month'))

  const is_current_month = moment(from).isSame(moment().startOf('month'), 'day') && moment(to).isSame(moment().endOf('month'), 'day')
  const is_last_seven_days = moment(from).isSame(moment().add(-7, 'days'), 'day') && moment(to).isSame(moment(), 'day')
  const is_last_days = moment(from).isSame(moment().add(-1, 'days'), 'day') && moment(to).isSame(moment(), 'day')

  return (
    <>
      <div className={'pb-5 container-fluid'}>
        <div className="container mb-5">
          <BackToHome/>
          <Title margin={'mb-2'} color={section_color('order')} style={{ color: 'white' }}
                 actions={<ShareResultsButton/>}><WelcomeIcon
            section={'order'}/> {translate({
            fr: `Suivi des ventes`,
            en: `Sales`,
          })}</Title>

          <OnlyPremium>
            <div className="row mb-2 mt-5 align-items-center">
              <div className="col-12 col-md-auto font-weight-bold" style={{ width: 200 }}>
                <InternalLink to={`/order/${one_month_before.format('YYYY')}/${one_month_before.format('MM')}`}
                ><FaArrowLeft/> {translate({
                  fr: `Mois précédent`,
                  en: `Previous month`,
                })}</InternalLink>
              </div>
              <div className="col text-center">
                <div className="row mb-1">
                  <div className="col-12">
                    <CustomDate from={from} to={to}/>
                    <NavigationButton className="btn-sm mr-1"
                                      button_props={{ button: is_current_month ? 'primary' : 'secondary' }}
                                      to={`/order/period/${moment().startOf('month').format('YYYY-MM-DD')}/${moment().endOf('month').format('YYYY-MM-DD')}`}>
                      {translate({
                        fr: `Mois en cours`,
                        en: `Current month`,
                      })}
                    </NavigationButton>
                    <NavigationButton className="btn-sm mr-1"
                                      button_props={{ button: is_last_seven_days ? 'primary' : 'secondary' }}
                                      to={`/order/period/${moment().add(-7, 'days').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`}>7 {translate({
                      fr: `derniers jours`,
                      en: `last days`,
                    })}</NavigationButton>
                    <NavigationButton className="btn-sm mr"
                                      button_props={{ button: is_last_days ? 'primary' : 'secondary' }}
                                      to={`/order/period/${moment().add(-1, 'days').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`}>{translate({
                      fr: `Dernières
                    24 heures`,
                      en: `Last 24 hours`,
                    })}</NavigationButton>
                  </div>
                </div>
                {is_month ? <h2>{to_upper_first(from.format('MMMM YYYY'))}</h2> :
                  <h4>{to_upper_first(from.format('dddd D MMMM YYYY'))}
                    <FaLongArrowAltRight/> {to_upper_first(to.format('dddd D MMMM YYYY'))} </h4>}
              </div>
              <div className="col-12 col-md-auto text-right font-weight-bold" style={{ width: 200 }}>
                <InternalLink
                  to={`/order/${one_month_after.format('YYYY')}/${one_month_after.format('MM')}`}>{translate({
                  fr: `Mois suivant`,
                  en: `Next month`,
                })} <FaArrowRight/></InternalLink>
              </div>
            </div>
            <OrdersLoaded from={from} to={to}/>
          </OnlyPremium>
        </div>
      </div>
    </>
  )
}


const OrderDisplayOptions = () => {
  const french = useFrench()
  const [merge_variations, set_merge_variations] = useMetaDataAutoUpdate('sales_dashboard_merge_variations', false)

  return <IntellifoxModal
    title={<><FiSettings/> {french(`Options d'affichage des ventes`)}</>}
    body={<div className="row">
      <Checkbox value={merge_variations}
                setter={set_merge_variations}>{french(`Fusionner l'affichage des variations`)}</Checkbox>
    </div>}
    button_props={{
      className: 'btn-sm mr-1',
      button: 'secondary',
    }}>
    <FiSettings/> {french(`Options`)}

  </IntellifoxModal>
}
