import { useFrench } from '../../context/lang'
import { FiEdit } from 'react-icons/all'
import React from 'react'
import Button from '../generic/button'
import { usePrices } from '../../routes/product_v2/edit_product_global_properties'
import { QuickUpdateModal } from './quick_update_modal'
import { compute_region_id_for_market_place, useRegion } from '../../hooks/useMarketPlace'

export const UpdatePrice = ({ product, parent_product, market_place }) => {
  const french = useFrench()
  const region = compute_region_id_for_market_place(market_place)

  const [show, set_show] = React.useState(false)

  return <>
    <Button className={'btn-sm'} onClick={() => set_show(true)}><FiEdit/> {french(`Modifier`)}</Button>
    <QuickUpdateModal title={french(`Modifier le prix de vente`)}
                      product={product}
                      parent_product={parent_product}
                      market_place={market_place}
                      useFields={usePrices}
                      show={show} close={() => set_show(false)}
                      helper={region === 'eu' ? <div className="row">
                        <div className="col-12">
                          {french(`En Europe, le prix de vente doit être indiqué TTC`)}
                        </div>
                      </div> : null}
    />
  </>
}


