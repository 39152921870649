import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { clean_for_label } from '../product/text-substitution'

import './search_term_tutorial_select_competitors.css'
import Button from '../../components/generic/button'
import { useMutation } from '@apollo/client'
import { ADD_COMPETITOR, REMOVE_COMPETITOR } from '../../graphql/queries/competitors'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import useMarketPlace from '../../hooks/useMarketPlace'
import Item from '../../components/generic/item'
import { FaCheckCircle, FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/all'
import IntellifoxModal from '../../components/generic/modal'
import Stars from '../../components/generic/stars'
import ProgressBar from './progress_bar'
import { useTranslate } from '../../context/lang'

const CompetitorSelector = ({ product, search_term, competitor, competitors_asin }) => {
  const translate = useTranslate()
  const { product_id } = product
  // const [is_selected, set_is_selected] = React.useState(true)
  const company_id = useCompanyId()
  const market_place = useMarketPlace()

  const [is_selected, _set_is_selected] = React.useState(!!competitors_asin.find(({ asin }) => asin === competitor.asin))

  React.useEffect(() => {
    _set_is_selected(!!competitors_asin.find(({ asin }) => asin === competitor.asin))
  }, [competitor.asin, competitors_asin])


  const [add_competitor, add_competitor_mutation] = useMutation(ADD_COMPETITOR, {
    variables: {
      company_id,
      product_id,
      market_place,
      asin: competitor.asin,
      position: competitor.position,
    },
  })

  const [remove_competitor, remove_competitor_mutation] = useMutation(REMOVE_COMPETITOR, {
    variables: {
      company_id,
      product_id,
      market_place,
      asin: competitor.asin,
    },
  })

  const set_is_selected = async (selected) => {
    _set_is_selected(selected)

    try {
      if (selected) {
        await await_mutate(add_competitor)
      } else {
        await await_mutate(remove_competitor)
      }
      await competitors_asin.refresh()
    } catch (e) {
    }
  }

  return <Item className="mb-2 clickable position-relative" active={is_selected}
               onClick={() => set_is_selected(!is_selected)}>
    <div className="row">
      <div className="col-12 small font-weight-bold">
        #{competitor.position} {translate({
            fr: `pour`,
            en: `for`
        })} "{search_term}"
      </div>
    </div>
    <div className="row mb-2">
      <div className="col-auto text-center mb-1 position-relative rounded"
           style={{ height: 120, width: 120, background: 'white' }}>
        <img src={competitor.image} className='position-absolute' style={{
          maxHeight: '100%', maxWidth: '100%',
          margin: 'auto',
          left: 0, top: 0, right: 0, bottom: 0,
        }}/>
      </div>
      <div className="col">
        <div className="row">
          <div className="col-12 font-weight-bold">
            {clean_for_label(competitor.title, 100, [])}
          </div>
          <div className="col-12">
            <Stars rating={competitor.rating} ratings_total={competitor.ratings_total}/>
          </div>
          <div className="col-12">
            {competitor.price} €
          </div>
        </div>
      </div>
      <div className="col text-center">
        <div className="btn-group btn-group-lg">
          <Button className={''} button={'success'} outline={!is_selected}
                  onClick={(e) => {
                    e.stopPropagation()
                    return set_is_selected(true)
                  }}>
            <FaRegThumbsUp/>
          </Button>
          <Button className={''} button={'danger'} outline={true}
                  onClick={(e) => {
                    e.stopPropagation()
                    return set_is_selected(false)
                  }}>
            <FaRegThumbsDown/>
          </Button>
        </div>


      </div>
    </div>
  </Item>
}

const ResetCompetitors = ({ product, market_place }) => {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const competitors_asin = useCachedContext('competitors_asin', product.product_id, market_place)

  const [remove_competitor] = useMutation(REMOVE_COMPETITOR)

  return <IntellifoxModal title={translate({
      fr: "Remettre à zéro les concurrents",
      en: "Reset your competitors"
  })}
                          action_label={translate({
                              fr: 'Remettre à zéro',
                              en: 'Reset'
                          })}
                          action_in_progress_label={translate({
                              fr: 'En cours...',
                              en: 'In progress...',
                          })}
                          on_click={async () => {

                            for (let i = 0; i < competitors_asin.length; i++) {
                              try {
                                await await_mutate(remove_competitor, {
                                  variables: {
                                    company_id,
                                    product_id: product.product_id,
                                    market_place,
                                    asin: competitors_asin[ i ].asin,
                                  },
                                })
                              } catch (e) {
                                throw e
                              }
                            }

                            await competitors_asin.refresh()
                          }
                          }
                          button_props={{
                            outline: true,
                            button: 'danger',
                          }}>
    {translate({
        fr: `Remettre à zéro`,
        en: `Reset`
    })} ({competitors_asin.length})
  </IntellifoxModal>
}
const SelectAllCompetitors = ({ product, market_place, competitors }) => {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const competitors_asin = useCachedContext('competitors_asin', product.product_id, market_place)

  const [add_competitor] = useMutation(ADD_COMPETITOR)

  return <IntellifoxModal title={translate({
      fr: "Sélectionner le TOP 10 ?",
      en: "Select the TOP 10?",
  })}
                          action_label={translate({
                              fr: 'Confirmer',
                              en: 'Confirm',
                          })}
                          action_in_progress_label={translate({
                              fr: 'En cours...',
                              en: 'In progress...',
                          })}
                          on_click={async () => {
                            for (let i = 0; i < 10; i++) {
                              try {
                                await await_mutate(add_competitor, {
                                  variables: {
                                    company_id,
                                    product_id: product.product_id,
                                    market_place,
                                    asin: competitors[ i ].asin,
                                    position: competitors[ i ].position,
                                  },
                                })
                              } catch (e) {
                                throw e
                              }
                            }

                            await competitors_asin.refresh()
                          }
                          }
                          button_props={{
                            outline: true,
                            button: 'success',
                          }}>
    {translate({
        fr: `Sélectionner le TOP 10`,
        en: `Select the TOP 10`
    })}
  </IntellifoxModal>
}

export default function SelectCompetitors({ product, market_place }) {
  const translate = useTranslate()

  const search_term_details = useCachedContext('search_term_details', market_place, product.product_info.search_terms[ 0 ])
  const competitors_asin = useCachedContext('competitors_asin', product.product_id, market_place)

  if (!product.product_info.search_terms || !product.product_info.search_terms[ 0 ]) {
    return <div className="row">
      <div className="col-12 font-weight-bold">
        {translate({
            fr: `Il faut renseigner une recherche dans l'étape précédente pour voir les concurrents.`,
            en: `Define a search query in the previous step to see your competitors.`
        })}
      </div>
    </div>
  }

  if (competitors_asin.loading || search_term_details.loading) {
    return <ProgressBar time={60}/>
  }

  if (competitors_asin.error || search_term_details.error) {
    return <DefaultError/>
  }

  if (search_term_details.search_results.length === 0) {
    return <div className="row">
      <div className="col-12">
        {translate({
            fr: `Aucun concurrent trouvé pour cette recherche.`,
            en: `No competitors were found for this search.`
        })}
      </div>
    </div>
  }

  const competitors = search_term_details.search_results.filter(({ asin }) => asin !== product.asin)

  return (
    <>
      <div className="row">
        <div className="col-12 text-right mb-2">
          <div className="btn-group">
            <ResetCompetitors product={product} market_place={market_place}/>
            <SelectAllCompetitors product={product} market_place={market_place}
                                  competitors={competitors}/>
          </div>

        </div>

        <div className="col-12">
          <div className="row">

            {competitors.map((competitor) =>
              <div className="col-12 col-lg-6 col-xl-4">
                <CompetitorSelector key={competitor.asin} competitor={competitor}
                                    search_term={product.product_info.search_terms[ 0 ]}
                                    product={product}
                                    competitors_asin={competitors_asin}/>
              </div>,
            )}

          </div>
        </div>
      </div>
    </>
  )
}
