import React from 'react'

import './controller.css'
import { useDesignerContext } from '../designer_context'
import { RectControl } from './rect_controls'
import KeyboardController from './keyboard_controller'
import { MultipleSelectionControl } from './multiple_selection_controls'
import ActionController from './action_controller'
import { ArrowControl } from './arrow_controls'

export const ControllerDebugContext = React.createContext({
  debug_info: {}, set_debug_info: () => ( {} ),
  debug_points: {}, set_debug_points: () => ( {} ),
})


const DEBUG_VISUAL_CONTROLLER = true

const ControllerDebug = ({ scale }) => {

  const { debug_info, debug_points } = React.useContext(ControllerDebugContext)

  const { x, y, delta_x, delta_y, whatever } = debug_info


  if (!DEBUG_VISUAL_CONTROLLER) {
    return null
  }


  return <>
    <div className="position-absolute small bazzinga " style={{
      top: 0,
      right: 0,
      border: '1px solid black',
      background: 'white',
      color: 'black',
      zIndex: 4864,
    }}>
      scale : {scale}<br/>
      mouse_x : {x.toFixed(2)}<br/>
      mouse_y : {y.toFixed(2)}<br/>
      delta_x : {delta_x.toFixed(2)}<br/>
      delta_y : {delta_y.toFixed(2)}<br/>
      {whatever ? JSON.stringify(whatever, null, 2) : null}<br/>
    </div>

    {debug_points.map(({ x, y, l }, i) => <div className="position-absolute" style={{
      left: x,
      top: y,
      background: 'red',
      zIndex: 9999,
      width: 6,
      height: 6,
      color: 'black',
    }}>{l}</div>)}
  </>
}

export const Render = ({ ...whatevs }) => {
  const { set_current_option } = useDesignerContext()

  if (whatevs.type === 'rect') {
    return <RectControl {...whatevs} on_double_click={() => set_current_option('props.fill')}/>
  }
  if (whatevs.type === 'image') {
    return <RectControl {...whatevs} keep_ratio={true} on_double_click={() => set_current_option('props.image_id')}/>
  }
  if (whatevs.type === 'text') {
    return <RectControl {...whatevs} on_double_click={() => set_current_option('props.text')}/>
  }
  if (whatevs.type === 'book') {
    return <RectControl {...whatevs} on_double_click={() => set_current_option('props.book')}/>
  }
  if (whatevs.type === 'shape') {
    return <RectControl {...whatevs} keep_ratio={true} on_double_click={() => set_current_option('props.fill')}/>
  }
  if (whatevs.type === 'arrow') {
    return <ArrowControl {...whatevs} on_double_click={() => set_current_option('props.arrow')}/>
  }
  return null
}


export default function Controller({ objects, width, height, scale, index, save, market_place }) {

  const controller_ref = React.useRef(null)

  const { reset_selection, get_selected_objects } = useDesignerContext()

  const selected_objects = get_selected_objects()


  // const [debug_info, set_debug_info] = React.useState({ x: -1, y: -1, delta_x: -1, delta_y: -1 })
  // const [debug_points, set_debug_points] = React.useState([])

  return (
    <div className="position-absolute controller" style={{ width, height, top: 0, left: 0, background: 'transparent' }}
         onClick={reset_selection}
         ref={controller_ref}>
      {/*<ControllerDebugContext.Provider value={{ debug_info, set_debug_info, debug_points, set_debug_points }}>*/}
      {objects.map((whatevs, i) => <Render key={whatevs.object_id}
                                           {...whatevs} controller_ref={controller_ref}
                                           selected_objects={selected_objects}
                                           scale={scale}/>)}

      <KeyboardController index={index} save={save} market_place={market_place}/>
      <MultipleSelectionControl selected_objects={selected_objects} scale={scale} controller_ref={controller_ref}/>

      {/*<ControllerDebug scale={scale}/>*/}
      {/*</ControllerDebugContext.Provider>*/}
      <ActionController scale={scale}/>
    </div>
  )
}

