import React from 'react'

import './button.css'

export default function ExternalLinkButton({ children, className = '', button='primary', to, disabled, outline = false , target="_blank", onClick}) {

  if (to === '') {
    return <button className={`btn mb-1 ${className} btn-${outline?'outline-':''}${button}`}>{children}</button>
  }
  return (
      <a href={to} target={target} className={`btn mb-1 ${className} btn-${outline?'outline-':''}${button}`} disabled={disabled || to === ''} onClick={() => onClick ? onClick() : null}>{children}</a>
  )
}
