import IntellifoxIcon from '../../images/intellifox-icon'
import React from 'react'

export const error_to_i18n = (error) => {
  if (error && error.graphQLErrors && error.graphQLErrors[ 0 ] &&  error.graphQLErrors[ 0 ].message) {
    const code =  error.graphQLErrors[ 0 ].message

    if (code === 'bad-password') {
      return <>Mot de passe incorrect</>
    }

    if (code === 'bad-email') {
      return <>L'email fourni ne correspond à aucun compte.</>
    }

    if (code === 'too_many_publish') {
      return <>Impossible de publier : <ul>
        <li><b>Problème : </b> une publication a été lancée il y a moins d'une minute.</li>
        <li><b>Solution : </b> relancer la publication dans une minute.</li>
        <li> <b>Pour ne plus avoir ce problème : </b> publier tous les produits en même temps.</li>
      </ul>
      </>
    }

    return <>Code erreur inconnu : {code}</>
  }


  console.log('error_to_i18n Code erreur inconnu', error)


  return <>Code erreur inconnu</>
}


export const Error = ({ mutation }) => {
  if (!mutation.error) {
    return null
  }
  return <div className="alert alert-danger mt-2 mb-2">
    <div className="row"><div className="col-12">
      Erreur : {error_to_i18n(mutation.error)}
    </div>
    <div className="col-12">
      <IntellifoxIcon width={60} height={60}/> Toutes nos excuses pour la gène occasionnée.
    </div></div>
    </div>

}
