import React from 'react'
import Button from '../../components/generic/button'
import { useFrench } from '../../context/lang'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { get_client } from '../../graphql/simple-client'
import { create_query } from '../../graphql/queries/_query_and_mutations_templates'
import useCompanyId from '../../hooks/useCompanyId'
import { force_download_json } from './support'
import moment from 'moment'

export const download_file = (object, file_name) => {

  const blob_href = window.URL.createObjectURL(new Blob([object]))
  console.log('force_download success, create link...')
  const link = document.createElement('a')
  link.href = blob_href
  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  console.log('force_download success, click link...')
  link.click()
  console.log('force_download success, success?')
  setTimeout(() => {
    console.log('force_download success, clean link')
    document.body.removeChild(link)
  }, 20000)
}


export const DataExport = () => {
  const french = useFrench()
  const company_id = useCompanyId()

  return <div className="row">
    <div className="col-12">
      <UpdateButtonV2 trigger={async () => {
        const client = get_client()
        const response = await client.request(create_query({
          query: 'export_all_products_as_csv',
          params: {
            company_id: 'ID!',
          },
        }), {
          company_id,
        })

        await download_file(response.export_all_products_as_csv, `intellifox_product_exports_${moment().format('YYYY_MM_DD_HHmm')}.csv`)
      }
      }
                      loadingMessage={french(`Génération en cours...`)}
                      successMessage={french(`L'export a été téléchargé`)}
      >{french(`Télécharger tous les produits en .csv`)}</UpdateButtonV2>
    </div>
  </div>
}
