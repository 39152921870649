import React from 'react'
import useMarketPlace, {
  compute_region_id_for_market_place,
  MarketPlaceContext,
  MarketPlaceSelector,
} from '../../hooks/useMarketPlace'
import { useProduct } from '../../context/products'
import { EditDescription, EditKeyPoints, EditTitle } from './edit_texts'
import Content from './content'
import {
  AddVariation,
  EditDimensions,
  EditGiftProperties,
  EditPrices,
  EditProductGlobalProperties,
} from './edit_product_global_properties'
import { EditVisuals } from './edit_visuals'
import ProductPreview, { ImportFromAmazon } from './product_preview/product_preview'
import SEOSummary from './seo_summary'
import { FaEuroSign, FaSearchDollar } from 'react-icons/fa'
import { MdHelp, MdSettings, MdTextFields } from 'react-icons/md'
import { IoMdEye, IoMdHelp, IoMdImages } from 'react-icons/io'
import Redirect from '../../components/generic/redirect'
import { DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useTranslate } from '../../context/lang'
import { Pricing } from '../../components/pricing/Pricing'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import { FaArrowDown, FaPlay } from 'react-icons/all'
import StartHere from './start_here'
import { navigate } from '../../absolute_link'
import ProductHeader from './product_header'
import TextButton from '../../components/generic/text_button'
import { GoGear } from 'react-icons/go'
import { ProductOverview } from './overview'
import NavigationButton from '../../components/generic/navigation-button'
import EditAmazonAccountForProduct from './edit_amazon_account_for_product'
import { ProblemSolver, ProductHelp } from './problem_solver'
import useHashParam from 'use-hash-param'
import { ShowGlobalProgress } from '../product/progress'
import { CleanHistory } from '../../hooks/useHistory'
import { FullScreenCentered } from '../../components/generic/centered'
import IntellifoxIcon from '../../images/intellifox-icon'
import { IntellifoxLoader } from '../../components/generic/loader'
import { ProductFooter } from './product_footer'
import OptimizationProgress from './optimization_progress'
import { useCachedContext } from '../../hooks/useCachedContext'


export const is_product_menu_displayed = (path) => /\/product\/edit/.test(path)


export const useProductMenu = (product, source_product, hide_linked_amazon_account) => {
  const translate = useTranslate()


  const linked_amazon_account = hide_linked_amazon_account ? [] : [
    {
      label: translate({
        fr: 'Compte Amazon Lié',
        en: 'Linked Amazon Account',
      }),
      key: 'amazon-account',
      content: <EditAmazonAccountForProduct product={product}/>,
      actions: <NavigationButton to={'/settings'}><MdSettings/> {translate({
        fr: `Gérer les comptes Amazon`,
        en: `Manage Amazon accounts`,
      })}</NavigationButton>,
    },
  ]
  const menu = [
    {
      label: translate({
        fr: 'Guide',
        en: 'Guide',
      }),
      background: 'var(--color-menu-9)',
      color: 'white',
      Icon: IoMdEye,
      key: 'preview',
      hide_if_no_subscription: false,
      menu: [
        {
          label: translate({
            fr: `Étape par étape`,
            en: `Step by step`,
          }),
          key: 'preview',
          // progress: product.progress.selected_keywords,
          content: <>
            <CleanHistory/>
            <OptimizationProgress product={product}/>
          </>,
          wrap_in_item: false,
          // count_min: 100,
        },
        {
          label: translate({
            fr: `Accès rapide`,
            en: `Quick access`,
          }),
          key: 'fast',
          // progress: product.progress.selected_keywords,
          content: <>
            <ProductPreview product={product} show_starter_if_necessary={true}/>
          </>,
          wrap_in_item: false,
          // count_min: 100,
        },
      ],
    },
    // {
    //   label: translate({
    //     fr: `Guide`,
    //     en: 'Guide'
    //   }),
    //   background: 'var(--color-menu-1)',
    //   color: 'white',
    //   Icon: FaPlay,
    //   key: 'start',
    //   hide_if_no_subscription: true,
    //   menu: [
    //     {
    //       label: translate({
    //         fr: `Guide d'optimisation`,
    //         en: 'Optimization guide',
    //       }),
    //       wrap_in_item:false,
    //       progress: product.progress.average,
    //       key: 'start_here',
    //       // progress: product.progress.selected_keywords,
    //       content: <StartHere product={product}/>,
    //       // count_min: 100,
    //     },
    //   ],
    //
    // },

    {
      label: translate({
        fr: 'Textes',
        en: 'Texts',
      }),
      background: 'var(--color-menu-2)',
      color: 'white',
      Icon: MdTextFields,
      key: 'texts',
      hide_if_no_subscription: true,
      menu: [
        {
          label: translate({
            fr: `Bilan des mots-clés`,
            en: `Keywords summary`,
          }),
          key: 'summary',
          content: <SEOSummary product={product}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Titre',
            en: 'Title',
          }), content: <EditTitle product={product}/>,
          key: 'title',
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Point fort #1',
            en: 'Bullet point #1',
          }), key: 'key_point_0',
          progress: product.progress.key_points,
          content: <EditKeyPoints product={product} index={0} label={translate({
            fr: 'premier point fort',
            en: 'first bullet point',
          })}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Point fort #2',
            en: 'Bullet point #2',
          }), key: 'key_point_1',
          progress: product.progress.key_points,
          content: <EditKeyPoints product={product} index={1} label={translate({
            fr: `point fort numéro ${1 + 1}`,
            en: `bullet point #${1 + 1}`,
          })}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Point fort #3',
            en: 'Bullet point #3',
          }), key: 'key_point_2',
          progress: product.progress.key_points,
          content: <EditKeyPoints product={product} index={2} label={translate({
            fr: `point fort numéro ${2 + 1}`,
            en: `bullet point #${2 + 1}`,
          })}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Point fort #4',
            en: 'Bullet point #4',
          }), key: 'key_point_3',
          progress: product.progress.key_points,
          content: <EditKeyPoints product={product} index={3} label={translate({
            fr: `point fort numéro ${3 + 1}`,
            en: `bullet point #${3 + 1}`,
          })}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Point fort #5',
            en: 'Bullet point #5',
          }), key: 'key_point_4',
          progress: product.progress.key_points,
          content: <EditKeyPoints product={product} index={4} label={translate({
            fr: `point fort numéro ${4 + 1}`,
            en: `bullet point #${4 + 1}`,
          })}/>,
          wrap_in_item: false,
        },
        {
          label: translate({
            fr: 'Description',
            en: 'Description',
          }),
          key: 'description',
          progress: product.progress.description,
          content: <EditDescription product={product}/>,
          wrap_in_item: false,
        },
      ],
    },


    {
      label: translate({
        fr: 'Images',
        en: 'Images',
      }),
      background: 'var(--color-menu-4)',
      color: 'white',
      Icon: IoMdImages,
      key: 'images',
      show_progress: false,
      hide_if_no_subscription: true,
      menu: [
        {
          label: translate({
            fr: 'Édition des images',
            en: 'Image editing',
          }), key: 'main',
          content: <EditVisuals product={product}/>,
        },
      ],
    },

    {
      label: translate({
        fr: 'Prix',
        en: 'Price',
      }),
      background: 'var(--color-menu-5)',
      color: 'white',
      Icon: FaEuroSign,
      key: 'price',
      hide_if_no_subscription: true,
      menu: [
        {
          label: translate({
            fr: 'Prix',
            en: 'Prices',
          }),
          key: 'calc',
          progress: product.progress.price,
          content: <Pricing product={product}/>
          ,
        },
      ],
    },

    {
      label: translate({
        fr: 'Configuration',
        en: 'Configuration',
      }),
      background: 'var(--color-menu-8)',
      color: 'white',
      Icon: GoGear,
      key: 'properties',
      overflow: true,
      hide_if_no_subscription: true,
      menu: [
        ...linked_amazon_account,
        // {
        //   label: translate({
        //     fr: 'SKU, ASIN, Noms des variations',
        //     en: 'SKU, ASIN, Variations',
        //   }),
        //   key: 'sku-asin-variations',
        //   content: <EditProductGlobalProperties product={product}/>,
        // },
        // {
        //   label: translate({
        //     fr: 'Ajouter une variation',
        //     en: 'Add a variation',
        //   }),
        //   key: 'add-variation',
        //   content: <AddVariation product={product} source_product={source_product}/>,
        // },

        {
          label: translate({
            fr: 'Cadeau',
            en: 'Gift',
          }),
          key: 'gift',
          content: <EditGiftProperties product={product}/>,
        },

        {
          label: translate({
            fr: 'Taille et poids du packaging',
            en: 'Dimensions and weight of packaging',
          }),
          key: 'dimensions',
          progress: product.progress.dimensions,
          content: <EditDimensions product={product}/>,
        },

        {
          label: translate({
            fr: `Effacer les données du produit sur Intellifox`,
            en: `Erase product data on Intellifox`,
          }),
          key: 'overview',
          content: <ProductOverview product={product}/>,
        },
      ],
    },


    {
      label: translate({
        fr: 'Aide',
        en: 'Help',
      }),
      background: 'var(--color-menu-7)',
      color: 'white',
      Icon: MdHelp,
      key: 'help',
      overflow: true,
      menu: [
        {
          label: translate({
            fr: `Besoin d'aide avec ce produit ?`,
            en: 'Need help with this product?',
          }),
          key: 'product-help',
          content: <ProductHelp product={product}/>,
        },
        {
          label: translate({
            fr: 'Communiquer avec le Seller Central',
            en: 'Solve issues with Seller Central',
          }),
          key: 'problem-solving',
          content: <ProblemSolver product={product}/>,
        },

      ],
    },
  ]

  return menu
}

const ProductLoaded = ({ product, source_product, amazon_accounts, is_loading }) => {

  const menu = useProductMenu(product, source_product, amazon_accounts && amazon_accounts.length === 1)
  const market_place = useMarketPlace()

  return <>
    <div className="row">
      <div className="col-12">
        <ProductHeader product={product} selected_product={product}/>
        {is_loading ? null : <Content menu={menu} product={product}/>}
      </div>
    </div>
    <ProductFooter product={product}
                   market_place={market_place}/>
  </>
}

const ProductAutoRefreshOnImport = ({ products }) => {

  const product_refresh_interval_ref = React.useRef(-1)


  React.useEffect(() => {
    const is_import_in_progress = products.reduce((is_import_in_progress, product) => is_import_in_progress || product.import_in_progress, false)
    if (is_import_in_progress) {
      if (product_refresh_interval_ref.current === -1) {
        // launch interval
        product_refresh_interval_ref.current = setInterval(() => {
          console.log('ProductAutoRefreshOnImport refresh...')
          products.forEach(product => product.refresh())
        }, 5000)
      }
    } else if (product_refresh_interval_ref.current !== -1) {
      console.log('ProductAutoRefreshOnImport Done!')

      clearInterval(product_refresh_interval_ref.current)
      product_refresh_interval_ref.current = -1
    }
  }, [products])

  return null
}

const ProductEditV2 = ({ product_id }) => {
  const market_place = useMarketPlace()
  const product = useProduct(product_id, market_place)
  const region_id = compute_region_id_for_market_place(market_place)
  const source_product = useProduct(product.product_id, region_id)
  const amazon_accounts = useCachedContext('amazon_accounts')
  const french = useFrench()

  React.useEffect(() => {
    product.refresh()
    source_product.refresh()
  }, [])

  const product_id_ref = React.useRef(product_id)

  const [_is_loading, set_is_loading] = React.useState(false)
  const is_loading = product_id_ref.current !== product_id || _is_loading
  React.useEffect(() => {
    console.log('product_edit_v2 product_id or market_place have changed', { product_id, market_place })

    set_is_loading(true)
    product_id_ref.current = product_id
    setTimeout(() => {
      set_is_loading(false)
    }, 500)
  }, [product_id, market_place])


  if (product.loading || source_product.loading || amazon_accounts.loading || is_loading) {
    return <IntellifoxLoader/>
  }

  if (product.error || source_product.error) {
    return <Redirect to={'/'}/>
  }

  return <MarketPlaceContext.Provider value={market_place}>
    <ProductAutoRefreshOnImport products={[product, source_product]}/>
    <ProductLoaded product={product} source_product={source_product} amazon_accounts={amazon_accounts}
                   is_loading={is_loading}/>
  </MarketPlaceContext.Provider>
}

export const MarketPlaceChooser = ({ show_modal, close, product_id }) => {
  const translate = useTranslate()
  const current_market_place = useMarketPlace()


  return <IntellifoxModalComponent show_modal={show_modal}
                                   close={close}
                                   title={translate({
                                     fr: 'Modifier le produit sur une autre marketplace',
                                     en: 'Edit the product on another marketplace',
                                   })}
                                   body={<>
                                     <div className="row">
                                       <div className="col-12">
                                         <MarketPlaceSelector current_market_place={current_market_place}
                                                              show_detail={(market_place) => <ShowGlobalProgress
                                                                product_id={product_id} market_place={market_place}
                                                                min_progress={0.1}/>}
                                                              on_click={(market_place) => {
                                                                navigate(`/product/edit/${product_id}/${market_place}${window.location.hash}`)
                                                                close()
                                                              }}/>
                                       </div>
                                     </div>
                                   </>}
  />
}

export default function MarketPlaceSwitcher({ product_id, market_place }) {
  return <>
    <MarketPlaceContext.Provider value={market_place}>
      <div className="container-fluid container-lg">
        <ProductEditV2 product_id={product_id}/>
      </div>
    </MarketPlaceContext.Provider>
  </>
}
