import React from 'react'
import { useDesignerContext } from '../designer_context'
import * as _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import { GiArrowCursor, GiFiles, MdExpandLess } from 'react-icons/all'
import Button from '../../generic/button'
import { useTranslate } from '../../../context/lang'

export default function SelectionDropdown() {
  const { multiple_selection, set_multiple_selection, reset_selection, select_all_objects } = useDesignerContext()
  const translate = useTranslate()

  return <>
    <small>{translate({
      fr: `Sélection`,
      en: `Selection`,
    })}</small> <div className="btn-group">
      <Button className={'btn-sm'} onClick={() => set_multiple_selection(false)}
              button={!multiple_selection ? 'primary' : 'secondary'}>{translate({
        fr: `Simple`,
        en: `Single`,
      })}</Button>
      <Button className={'btn-sm'} onClick={() => set_multiple_selection(true)}
              button={multiple_selection ? 'primary' : 'secondary'}>Multiple</Button>
    </div> <div className="btn-group">
    <Button className={'btn-sm'} onClick={() => reset_selection()} button={'primary'}>{translate({
      fr: `Aucun`,
      en: `None`,
    })}</Button>
    <Button className={'btn-sm'} onClick={() => select_all_objects()} button={'primary'}>{translate({
      fr: `Tout`,
      en: `All`,
    })}</Button>
  </div>
  </>
}
