import React from 'react'
import { format_money } from '../../order/format_numbers'
import Button from '../../../components/generic/button'
import { FiDollarSign, FiEdit, FiRefreshCw, FiSave, GiMoneyStack } from 'react-icons/all'
import { useFrench, useLang } from '../../../context/lang'
import IntellifoxModal from '../../../components/generic/modal'
import Field from '../../../components/generic/field'
import { useMarketPlaceDetails } from '../../../hooks/useMarketPlace'
import { NiceConclusion } from '../../../components/generic/item'
import { useCampaignUpdate } from './campaign_state_v2'

export default function ManageCampaignBudget({ campaign, managed_campaign }) {
  const lang = useLang()
  const { market_place } = managed_campaign
  const market_place_details = useMarketPlaceDetails(market_place)
  const french = useFrench()

  const do_update_campaign = useCampaignUpdate({ campaign, managed_campaign })


  return (
    <div className="row">
      <div className="col-12 mb-2">
        <NiceConclusion bg={'success'} icon={<FiRefreshCw/>} conclusion={<>
          {format_money(lang, campaign.daily_budget, market_place_details.currency, 2)} <span className="ml-2"
                                                                                              style={{ fontSize: '12px' }}>/{french(`jour`)}</span>
        </>} tooltip={<div className="text-left font-weight-bold">{french(`Note : si tu as défini un objectif de coût par conversion, tu peux augmenter le budget drastiquement avec peu de risque.`)}</div>}/>
      </div>
      <div className="col-12 mb-2">
        <UpdateCampaignBudget campaign={campaign} currency={market_place_details.currency_symbol}
                              do_update_campaign={do_update_campaign}/>
      </div>
    </div>
  )
}

const UpdateCampaignBudget = ({ campaign, currency, do_update_campaign }) => {
  const french = useFrench()

  const [daily_budget, set_daily_budget] = React.useState(campaign.daily_budget)

  return <IntellifoxModal title={<><FiEdit/> {french(`Modifier le budget quotidien`)}</>}
                          body={<div className="row">
                            <Field type={'number'} value={daily_budget} setter={set_daily_budget}
                                   label={french(`Budget quotidien`)} unit={currency} step={0.01}/>
                          </div>}
                          action_label={<><FiSave/> {french(`Sauvegarder`)}</>}
                          on_click={() => do_update_campaign({ daily_budget })}
                          button_props={{
                            className: 'btn-block btn-sm',
                          }}
  >
    <FiEdit/> {french(`Modifier`)}
  </IntellifoxModal>
}
