import React from 'react'
import { useCachedContext, useResetCachedValues } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../i18n/translations'
import {
  AiOutlineLoading,
  FaAmazon,
  FaArrowRight,
  MdCheck,
  MdCheckCircle,
  MdRadioButtonUnchecked,
} from 'react-icons/all'
import UpdateButtonV2 from '../generic/update_button_v2'
import { useMutation } from '@apollo/client'
import { start_process } from '../../graphql/queries/import'
import useCompanyId from '../../hooks/useCompanyId'
import { await_mutate } from '../../graphql/mutate_promise'
import { Tooltip } from 'react-tippy'
import { MdHelp, MdWarning } from 'react-icons/md'
import { useFrench } from '../../context/lang'
import moment from 'moment'
import * as _ from 'lodash'
import NavigationButton from '../generic/navigation-button'
import IntellifoxModal from '../generic/modal'
import ExternalLinkButton from '../generic/external-link-button'

const is_in_progress = (process) => {
  if (!process) {
    return false
  }
  const { steps } = process

  for (let i = 0; i < steps.length; i++) {
    const step = steps[ i ]
    if (step.status !== 'done') {
      return true
    }
  }
  return false
}

const step_label = (name) => {
  switch (name) {
    case 'get_existing_skus':
      return `Récupération de l'intégralité du catalogue sur Amazon pour chaque marketplace`
    case 'filter_already_existing_skus':
      return `Filtrage des produits déjà existants sur Intellifox`
    case 'get_product_infos':
      return `Récupération des ASIN des produits non-existants`
    case 'transform_products':
      return `Regroupage des produits par ASIN et par produits parents`
    case 'create_products':
      return `Création des produits sur Intellifox`
    case 'scrap_amazon':
      return `Récupération des données actuelles des fiches produits sur Amazon`
    case 'prepare_products_for_update':
      return `Calcul des valeurs pour chaque produit et variation`
    case 'update_products':
      return `Mise à jour des produits et variations sur Intellifox`
    case 'prepare_products_for_images_import':
      return `Regroupage des images par régions`
    case 'download_images':
      return `Importation des images existantes`
    case 'create_visuals':
      return `Création des images modifiables`
    case 'remove_import_in_progress_flags':
    case 'send_celebration_email':
      return `Validation de l'importation des produits`
    default:
      return name
  }
}

const compute_progress = (process) => {
  let { creation_date, results } = process

  const step_progress = 1 / process.steps.length

  let estimated_start_progress = step_progress * Math.min(60000, Date.now() - creation_date) / 60000

  const progress = process.steps.reduce((progress, step) => {
    return progress + ( step.status === 'done' ? step_progress : 0 )
  }, 0)


  return `${( 100 * Math.max(progress, estimated_start_progress) ).toFixed()} %`
}

const ShowStepDetails = ({ process, show_more_details, french }) => {
  let { steps, creation_date, results } = process

  if (!results) {
    results = {
      products: 0,
      variations: 0,
    }
  }

  const ordered_steps = steps.sort((s1, s2) => s1.index < s2.index ? -1 : 1)

  const is_finished = steps.reduce((is_finished, step) => is_finished && step.status === 'done', true)

  const current_step = ordered_steps.find((step) => step.status === 'in_progress') || ordered_steps[ 0 ]


  return <>
    <div className="row">
      <div className="col-12">
        <h3>{compute_progress(process)} {!is_finished ? <AiOutlineLoading className={'spin'}/> : <MdCheckCircle/>}</h3>
      </div>
      {!is_finished ? <>
        <div className="col-12 font-weight-bold">
          {french(`Étape en cours`)} ({current_step.index + 1}/{ordered_steps.length})
        </div>
        <div className="col-12 font-italic">
          {french(step_label(current_step.name))}...
        </div>
      </> : null}
    </div>
    {show_more_details ? <div className="row mt-1 mb-1">
      <div className="col-12">
        <div className="alert alert-success">
          <div className="row">
            <div className="col-12 small text-left">
              <table className={'w-100'}>
                <tbody>
                <tr>
                  <td className="font-weight-bold">{french(`Début`)}</td>
                  <td className="text-right">{moment(creation_date).format('LLL')}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">{french(`Fin`)}</td>
                  <td className="text-right">{moment(_.last(ordered_steps).end_date).format('LLL')}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">{french(`Produits importés`)}</td>
                  <td className="text-right">{results.products}</td>
                </tr>
                <tr>
                  <td className="font-weight-bold">{french(`Variations importées`)}</td>
                  <td className="text-right">{results.variations}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> : null}


  </>
}

export const ImportProductOrNot = ({ className, on_success, show_only_if_in_progress }) => {
  const amazon_accounts = useCachedContext('amazon_accounts')
  const french = useFrench()

  if (amazon_accounts.loading) {
    return null
  }

  if (amazon_accounts.error) {
    return null
  }

  if (amazon_accounts.length > 0) {
    return <ImportProducts className={className} on_success={on_success}
                           show_only_if_in_progress={show_only_if_in_progress}/>
  }

  return <NavigationButton to={'/settings'} className={className}>{french(`Ajouter un compte Amazon`)}</NavigationButton>

}

export default function ImportProducts({ className, on_success, show_only_if_in_progress }) {
  const company_id = useCompanyId()
  const french = useFrench()

  const amazon_accounts = useCachedContext('amazon_accounts')
  const import_processes = useCachedContext('processes', 'import_products')
  const [start_process_mutation] = useMutation(start_process)

  const auto_refresh_ref = React.useRef(-1)

  React.useEffect(() => {
    const in_progress = is_in_progress(import_processes[ 0 ])
    if (in_progress && auto_refresh_ref.current === -1) {
      clearInterval(auto_refresh_ref.current)
      auto_refresh_ref.current = setInterval(() => {
        console.log('ImportProducts auto_refresh :)...')
        import_processes.refresh()
        on_success && on_success()
      }, 5000)
    } else if (!in_progress && auto_refresh_ref.current !== -1) {
      console.log('ImportProducts Done.')
      clearInterval(auto_refresh_ref.current)
      on_success && on_success()
      auto_refresh_ref.current = -1
    }
  }, [import_processes, on_success])

  if (import_processes.loading || amazon_accounts.loading) {
    return <DefaultLoading className={'d-inline-block'}/>
  }

  if (import_processes.error || amazon_accounts.error) {
    return <DefaultError className={'d-inline-block'}/>
  }

  const do_it = async () => {
    await await_mutate(start_process_mutation, {
      variables: {
        company_id,
        type: 'import_products',
      },
    })
    import_processes.refresh()
  }

  const has_problem_with_amazon_account = amazon_accounts.reduce((has_problem, amazon_account) => {
    return has_problem || (amazon_account.invalid_grant || !amazon_account.market_places || amazon_account.market_places.length === 0)
  }, false)

  const step_labels = import_processes[ 0 ] ? import_processes[ 0 ].steps.reduce((step_labels, { name }) => {
    step_labels[ name ] = french(step_label(name))
    return step_labels
  }, {}) : {}

  let content
  if (has_problem_with_amazon_account) {
    content = <IntellifoxModal title={french(`Une action est requise avant de pouvoir importer`)}
                               body={<div className="row">
                                 <div className="col-12">
                                   <div className="alert alert-danger">
                                     <div className="row">
                                       <div className="col-12 font-weight-bold">
                                         <MdWarning/> {french(`Intellifox ne peut pas importer ou publier des produits avec certains de tes comptes Amazon : une action est nécessaire`)}
                                       </div>
                                       <div className="col-12">
                                         <NavigationButton to={'/settings'}>
                                           <FaArrowRight/> {french(`Résoudre le problème`)}
                                         </NavigationButton>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>}
                               modal_size={'lg'}
    >
      {french(`Importer depuis Amazon`)}
    </IntellifoxModal>
  } else if (is_in_progress(import_processes[ 0 ])) {
    content = <><Tooltip arrow={true} html={<ShowStepDetails process={import_processes[ 0 ]} step_labels={step_labels}
                                                             french={french}/>}>
      <AiOutlineLoading className={'spin'}/> {french(`Synchronisation en cours...`)} <span
      className="small">{compute_progress(import_processes[ 0 ])}</span>
    </Tooltip>
    </>
  } else if (show_only_if_in_progress && import_processes[ 0 ] && !is_in_progress(import_processes[ 0 ])) {
    content = <Tooltip arrow={true} html={<ShowStepDetails process={import_processes[ 0 ]} step_labels={step_labels}
                                                           french={french}
                                                           show_more_details={true}/>}>
      <MdCheckCircle/> {french(`Importation terminée`)}
    </Tooltip>
  } else if (import_processes[ 0 ]) {
    content = <><UpdateButtonV2 trigger={do_it}>
      {french(`Importer depuis Amazon`)} <Tooltip arrow={true}
                                                  html={<ShowStepDetails
                                                    french={french}
                                                    step_labels={step_labels}
                                                    show_more_details={true}
                                                    process={import_processes[ 0 ]}/>}>
      <MdHelp/>
    </Tooltip>
    </UpdateButtonV2></>
  } else {
    content = <UpdateButtonV2 trigger={do_it}>{french(`Importer depuis Amazon`)}</UpdateButtonV2>
  }

  return (
    <span className={className}>{content}</span>
  )
}

