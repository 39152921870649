import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, {
  Option_Content_Picker,
  Option_Content_Picker_Item,
  Option_Content_SubTitle,
  Option_Content_Title,
} from './select__content'
import tinycolor from 'tinycolor2'
import tinygradient from 'tinygradient'
import { ChromePicker } from 'react-color'
import Button from '../../generic/button'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { useTranslate } from '../../../context/lang'
import EditColorPalette from './edit_color_palette'
import { Browse_Content_Title } from '../designer/browse__content'

export const Color = ({ color, onClick, is_current, size = 32, className='' }) => {
  const { compute_color } = useDesignerContext()
  return <div
    className={`d-inline-block rounded clickable color_option ${is_current ? 'is_current' : ''} ${className}`} style={{
    width: size, height: size, background: compute_color(color),
  }} onClick={onClick}></div>
}

export const ColorOptionSelector = ({ color, option }) => {
  const { current_option, set_current_option } = useDesignerContext()
  const is_current = current_option === option

  return <Color color={color} is_current={is_current}
                onClick={() => set_current_option(is_current ? null : option)}/>
}

const color_picker_size = 48
export const ColorPickerLine = ({ color, current, end = 30, update_object, color_picker_size = 48 }) => {
  const steps = 5
  const gradient = tinygradient([color, tinycolor(color).lighten(end)])

  const colors = gradient.rgb(steps).map(c => c.toString())

  return <Option_Content_Picker>
    {colors.map((color, i) => {
      return <Option_Content_Picker_Item key={i}>
        <Color color={color} is_current={current === color} size={color_picker_size} onClick={() => {
          update_object(color)
        }}/>
      </Option_Content_Picker_Item>
    })
    }
  </Option_Content_Picker>

}

export const SelectColorComponent = ({ get_value, update_object, show_transparent }) => {
  const { product, get_selected_object, update_object_props, current_objects, current_product_id, compute_color } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])

  const value = compute_color(get_value(selected_object))


  const do_update_object = (color) => update_object_props(update_object(selected_object, color))

  return (
    <>
      <Browse_Content_Title>{translate({
        fr: `Couleur`,
        en: `Color`,
      })}</Browse_Content_Title>
      <Option_Content_SubTitle>{translate({
        fr: `Palette de couleurs`,
        en: `Color palette`,
      })}</Option_Content_SubTitle>

      <Option_Content_Picker>

        {product.color_palette.colors.map((c) => <Option_Content_Picker_Item>
          <Color color={c.color} is_current={c.color_id === value} onClick={() => do_update_object(c.color_id)}
                 size={color_picker_size}/>
        </Option_Content_Picker_Item>)}

        <EditColorPalette product={product}/>
      </Option_Content_Picker>

      {show_transparent ? <Option_Content_Picker>
        <Option_Content_Picker_Item>
          <Button outline={true} onClick={() => do_update_object('transparent')}>{translate({
            fr: `Transparent`,
            en: `Transparent`,
          })}</Button>
        </Option_Content_Picker_Item>
      </Option_Content_Picker> : null}
      <Option_Content_SubTitle>{translate({
        fr: `Couleur actuelle`,
        en: `Current color`,
      })}</Option_Content_SubTitle>
      <Option_Content_Picker>
        <Option_Content_Picker_Item>
          <Color color={value} is_current={true} size={color_picker_size}/>
        </Option_Content_Picker_Item>
      </Option_Content_Picker>
      <Option_Content_SubTitle>{translate({
        fr: `Couleurs`,
        en: `Colors`,
      })}</Option_Content_SubTitle>
      <ColorPickerLine color={'#000'} current={value} update_object={do_update_object} end={100}/>
      <ColorPickerLine color={'green'} current={value} update_object={do_update_object}/>
      <ColorPickerLine color={'blue'} current={value} update_object={do_update_object}/>
      <ColorPickerLine color={'yellow'} current={value} update_object={do_update_object}/>
      <ColorPickerLine color={'red'} current={value} update_object={do_update_object}/>
      <ColorPickerLine color={'teal'} current={value} update_object={do_update_object}/>
      <Option_Content_SubTitle>{translate({
        fr: `Personnalisée`,
        en: `Custom`,
      })}</Option_Content_SubTitle>
      <ChromePicker color={value}
                    onChange={(c, e) => {
                      do_update_object(`rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`)
                    }}/>
      
      <div className="row">
        <div className="col-12 mb-5">
          
        </div>
      </div>
    </>
  )
}

export default function SelectColor({ get_value, update_object, show_transparent }) {

  return <Option_Content>
    <SelectColorComponent show_transparent={show_transparent} update_object={update_object} get_value={get_value}/>
  </Option_Content>
}
