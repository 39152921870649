import React from 'react'
import Rect from './rect'
import { CenteredAbsolute } from '../../generic/centered'
import Image from './image'
import Text from './text'
import { useWindowSize } from '../../../hooks/useWindowSize'
import Book from './book'
import Shape from './shape'
import Arrow from './arrow'


export const compute_scale = ({ available_width, available_height, width, height }) => {
  return Math.min(available_width / width, available_height / height)
}

export const Scaler = ({ scale, children, center_absolute, width, height, className=''}) => {

  const dimension = ( width && height ) ? {
    width, height, overflow: 'hidden',
  } : {}
  if (center_absolute) {
    return <CenteredAbsolute>
      <div style={dimension} className={className}>
        <div style={{ transform: `scale(${scale})`, transformOrigin: '50% 50%' }}>{children}</div>
      </div>
    </CenteredAbsolute>
  }
  return <div style={dimension} className={className}>
    <div style={{ transform: `scale(${scale})`, transformOrigin: '0 0' }}>{children}</div>
  </div>
}


export const AutomaticScaler = ({ visual, children }) => {
  const [scale, set_scale] = React.useState(1)
  const [height, set_height] = React.useState(100)
  const container_ref = React.useRef(null)

  const window_size = useWindowSize()

  React.useEffect(() => {
    if (container_ref.current) {
      const available_width = container_ref.current.getBoundingClientRect().width
      const new_scale = compute_scale({
        available_width,
        available_height: available_width,
        width: visual.width,
        height: visual.height,
      })

      set_scale(new_scale)
      set_height(available_width)
    }
  }, [window_size, container_ref, visual])

  return <div className="w-100" ref={container_ref} style={{ height }}>
    <div style={{ transform: `scale(${scale})`, transformOrigin: '0 0' }}>{children}</div>
  </div>
}


export const Render = ({ type, reduce_image_size, ...props }) => {

  React.useEffect(() => {
    if (type === null && props.onLoad) {
      props.onLoad()
    }
  }, [])
  if (type === 'rect') {
    return <Rect {...props}/>
  }
  if (type === 'image') {
    return <Image {...props} reduce_image_size={reduce_image_size}/>
  }
  if (type === 'text') {
    return <Text {...props}/>
  }
  if (type === 'book') {
    return <Book {...props}/>
  }
  if (type === 'shape') {
    return <Shape {...props}/>
  }
  if (type === 'arrow') {
    return <Arrow {...props}/>
  }
  return null
}


export const DomRenderer = ({ objects = [], overrides = [], width, height, background = 'white', className = '', style = {}, onClick, on_objects_loaded, reduce_image_size }) => {

  const loaded_ref = React.useRef(0)

  const set_loaded = React.useCallback((i) => {
    loaded_ref.current = loaded_ref.current + 1

    if (on_objects_loaded && loaded_ref.current === objects.length) {
      on_objects_loaded()
    }
  }, [])

  React.useEffect(() => {
    if (on_objects_loaded && objects.length === 0) {
      on_objects_loaded()
    }
  }, [])

  if (!objects.map) {
    return null
  }


  return (
    <div className={`position-relative dom-renderer BLUR_FOR_WEBINAR ${className}`} onClick={onClick}
         style={{ ...style, width, height, background, overflow: 'hidden' }}>
      {objects.map((props, i) => {
        return <Render key={props.object_id} {...props} onLoad={() => set_loaded(i)} reduce_image_size={reduce_image_size}/>
      })}
    </div>
  )
}
