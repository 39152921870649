import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, {
  Option_Content_Picker,
  Option_Content_SubTitle,
  Option_Content_Title,
} from './select__content'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { useTranslate } from '../../../context/lang'
import InputSlider from 'react-input-slider'
import { Checkbox } from '../../generic/checkbox'
import { SelectColorComponent } from './select_color'

export default function SelectGradient({ get_value, update_object }) {
  const { product, get_selected_object, update_object_props, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])

  const value = get_value(selected_object)

  const do_update_object = (angle) => update_object_props(update_object(selected_object, angle))


  return (
    <Option_Content>
      <Option_Content_Title>{translate({
        fr: `Options du dégradé`,
        en: `Gradient options`,
      })}</Option_Content_Title>
      <Option_Content_SubTitle>{translate({
        fr: `Activer`,
        en: `Enable`,
      })}</Option_Content_SubTitle>

      <Option_Content_Picker>
        <div className="row">
          <Checkbox value={selected_object.props.gradient} setter={() => update_object_props({
            object: selected_object,
            field: 'gradient',
            value: !!!selected_object.props.gradient,
          })}>{translate({
            fr: `Activer`,
            en: `Enable`,
          })}</Checkbox>
        </div>
      </Option_Content_Picker>

      {selected_object.props.gradient ? <> <Option_Content_SubTitle>{translate({
        fr: `Angle`,
        en: `Angle`,
      })}</Option_Content_SubTitle>

        <Option_Content_Picker>
          <InputSlider axis={'x'} xmin={0} xmax={360} x={value || 0} onChange={({ x }) => do_update_object(x)}
                       xstep={1}/>
        </Option_Content_Picker>

        <SelectColorComponent get_value={(o) => o.props.gradient_color} show_transparent={true}
                              update_object={(object, value) => ( {
                                object,
                                field: 'gradient_color',
                                value,
                              } )}/>

      </> : null}


    </Option_Content>
  )
}
