import React from 'react'
import { ItemTitle } from '../../components/generic/item'
import { useFrench } from '../../context/lang'
import TimezoneSelector from './timezone_selector'
import { CurrencyPicker } from '../subscription/upgrade'
import CurrencySelector from './currency_selector'

export default function TimezoneAndCurrency() {
  const french = useFrench()
  return (
    <div className="row">
      <div className="col-12">
        <ItemTitle>
          {french(`Fuseau horaire`)}
        </ItemTitle>
      </div>
      <div className="col-12">
        <TimezoneSelector/>
      </div>

      <div className="col-12 mt-3">
        <ItemTitle>
          {french(`Devise`)}
        </ItemTitle>
      </div>
      <div className="col-12">
        <CurrencySelector/>
      </div>
    </div>
  )
}
