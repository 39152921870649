import { gql } from '@apollo/client'
import { create_query } from './_query_and_mutations_templates'

const visual_full_details = `
visual_id
width
height
index
objects {
    type,
    object_id,
    x,
    y,
    width,
    height,
    angle,
    props
}
overrides {
    type,
    object_id,
    x,
    y,
    width,
    height,
    angle,
    props
}
renderer
    `
const product_info_details = `
title
key_points
description
variation
keywords
search_terms
selected_keywords {
    selected
    keyword
    intensity
    category
}
standard_price
min_price
max_price
msrp
sale_price
sale_from_date
sale_end_date
concurrent_url_1
concurrent_url_2
concurrent_url_3
concurrent_url_4
concurrent_url_5
price
last_update
last_publish

asin_override
sku_override

visuals {
    ${visual_full_details}
    is_shared
}

`

const product_details = `
product_id, date_created, state,
import_in_progress,
sku, asin, fnsku,
packaging_length,
packaging_width,
packaging_height,
packaging_weight,
is_gift_wrap_available,
is_gift_message_available,
last_update
rating
ratings_total
market_places
titles {
  market_place
  title
}

product_info (market_place:$market_place) {
    ${product_info_details}
}
progress (market_place:$market_place){
    search_terms
    competitors
    selected_keywords
    applied_keywords
    price
    visuals
    average
}

color_palette {
    colors {
        color_id
        color
    }
}
`

export const product_progress_for_market_place = `
    query get_product_progress($company_id: ID!, $product_id: ID!, $market_place: String!) {
        product(
            company_id: $company_id,
            product_id: $product_id
        ) {
            sku, asin,
            progress (market_place:$market_place) {
                search_terms
                competitors
                selected_keywords
                applied_keywords
                price
                visuals
                average
            }
        }
    }
`

const product_minimal_details = `
product_id,
sku, asin,  state, date_created,
import_in_progress,
titles {
  market_place
  title
}

market_places

rating
ratings_total

product_info (market_place:$market_place) {
    variation
    title
}
progress (market_place:$market_place) {
    search_terms
    competitors
    selected_keywords
    applied_keywords
    price
    visuals
    average
}
color_palette {
    colors {
        color_id
        color
    }
}
`

export const GET_FULL_PRODUCTS_STR = `
    query GetFullProducts($company_id: ID!, $state: String, $market_place: String, $offset: Int, $limit: Int) {
        products(company_id: $company_id, state: $state, limit:$limit, offset:$offset) {
            ${product_details}
            variations {
                ${product_details}
            }
        }
    }
`

export const GET_PRODUCTS_HIERARCHY = `
    query GetProductsHierarchy($company_id: ID!, $state: String, $offset: Int, $limit: Int) {
        products(company_id: $company_id, state: $state, limit:$limit, offset:$offset) {
            product_id
            asin
            color_palette {
                colors {
                    color_id
                    color
                }
            }
            variations {
                product_id
                asin
                color_palette {
                    colors {
                        color_id
                        color
                    }
                }
            }
        }
    }
`
export const GET_PRODUCT_COUNT = `
    query GetProductCount($company_id: ID!, $state: String) {
        product_count(company_id: $company_id, state: $state) { count }
    }
`
export const GET_FULL_PRODUCTS = gql(GET_FULL_PRODUCTS_STR)

export const GET_MINIMAL_PRODUCTS = `
    query GetProducts($company_id: ID!, $state: String, $market_place: String, $offset: Int, $limit: Int) {
        products(company_id: $company_id, state: $state, offset:$offset, limit:$limit) {
            ${product_minimal_details}
            variations {
                ${product_minimal_details}
            }
        }
    }
`

export const market_places_query = {
    query: 'product',
    params: {
        company_id: 'ID!',
        product_id: 'ID!',
    },
    output: `{
        market_places
    }`
}

export const SEARCH_PRODUCTS = `
    query SearchProducts($company_id: ID!, $query: String!, $limit: Int!, $offset: Int!, $market_place: String) {
        searchProducts(company_id: $company_id, query:$query, limit:$limit, offset:$offset) {
            count
            products {
                ${product_minimal_details}
                variations {
                    ${product_minimal_details}
                }
            }
        }
    }
`
export const GET_PRODUCT_TITLE = `
    query GetProducts($company_id: ID!, $product_id: ID!, $market_place: String!) {
        product(
            company_id: $company_id,
            product_id: $product_id
        ) {
            sku, asin,
            product_info (market_place:$market_place) {
                title
                variation
            }
        }
    }
`

export const product_title = `
 query product_title (
    $company_id: ID!,
    $asin: String,
    $market_place: String
) {
    product_title (
        company_id: $company_id
        asin: $asin
        market_place: $market_place
    )
}
`

export const GET_PRODUCT_STR = `
    query GetProduct($company_id: ID!, $product_id: ID!, $market_place: String!) {
        product(
            company_id: $company_id,
            product_id:$product_id
        ) {
            ${product_details}
            parent_product {
                ${product_details}
            }
            variations {
                ${product_details}
            }
        }
    }`
export const GET_PRODUCT = gql(GET_PRODUCT_STR)


const product_details_for_visual_renderer = `
product_id, date_created,
sku, asin, fnsku
color_palette {
    colors {
        color_id
        color
    }
}
`

const product_info_for_visual_renderer = `
    product_info (market_place:$market_place) {
        variation
        visual_by_index(index: $index) {
            ${visual_full_details}
        }
    }
`

export const GET_PRODUCT_WITH_SINGLE_VISUAL = `
    query GetProduct($company_id: ID!, $product_id: ID!, $market_place: String!, $index: Int!) {
        product(
            company_id: $company_id,
            product_id:$product_id
        ) {
            ${product_details_for_visual_renderer}
            ${product_info_for_visual_renderer}
            
            parent_product {
                ${product_details_for_visual_renderer}
            }
        }
    }
`

export const ADD_PRODUCT = gql`
    mutation AddProduct(
        $company_id: ID!,
        $sku: String!
    ) {
        addProduct(
            company_id: $company_id,
            sku: $sku
        ) {
            product_id
        }
    }
`

export const ADD_PRODUCT_VARIATION = gql`
    mutation AddProductVariation(
        $company_id: ID!,
        $parent_product_id: ID!,
        $sku: String!,
    ) {
        addProductVariation(
            company_id: $company_id,
            parent_product_id: $parent_product_id,
            sku: $sku,
        ) {
            product_id
        }
    }
`


export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct(
        $company_id: ID!,
        $product_id:ID!,

        $sku: String,

        $asin: String,
        $fnsku: String,
        $packaging_length: String,
        $packaging_width: String,
        $packaging_height: String,
        $packaging_weight: String,
        $is_gift_wrap_available: Boolean,
        $is_gift_message_available: Boolean,
        $state: String,

        $market_place: String,
        $variation: String,
        $title: String,
        $key_points: [String],
        $description: String,
        $keywords: [String],
        $standard_price: Float,
        $min_price: Float,
        $max_price: Float,
        $msrp: Float,
        $sale_price: Float,
        $sale_from_date: Date,
        $sale_end_date: Date,
        $price: String,
        $sku_override: String,
        $asin_override: String,
    ) {
        updateProduct(
            company_id: $company_id,
            product_id: $product_id,

            sku: $sku,

            asin: $asin,
            fnsku: $fnsku,
            packaging_length: $packaging_length,
            packaging_width: $packaging_width,
            packaging_height: $packaging_height,
            packaging_weight: $packaging_weight,
            is_gift_wrap_available: $is_gift_wrap_available,
            is_gift_message_available: $is_gift_message_available,
            state: $state,

            market_place: $market_place,
            variation: $variation,
            title: $title,
            key_points: $key_points,
            description: $description,
            keywords: $keywords,
            standard_price:$standard_price,
            min_price:$min_price,
            max_price:$max_price,
            msrp:$msrp,
            sale_price:$sale_price,
            sale_from_date:$sale_from_date,
            sale_end_date:$sale_end_date,
            price:$price,
            sku_override:$sku_override,
            asin_override:$asin_override,
        ) {
            product_id
        }
    }
`

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct(
        $company_id: ID!,
        $product_id:ID!,
    ) {
        deleteProduct(
            company_id: $company_id,
            product_id:$product_id,
        )
    }
`


