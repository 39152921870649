import { useFrench } from '../../context/lang'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { await_mutate } from '../../graphql/mutate_promise'
import { IntellifoxModalComponent } from '../generic/modal'
import { FiEdit, FiInfo, FiSave } from 'react-icons/all'
import Field from '../generic/field'
import React from 'react'
import { parse_formula } from '../pricing/pricing_formulas'

export const QuickUpdateModal = ({ product, parent_product, market_place, useFields, show, close, title, helper, update_transformer = (v) => v, on_update }) => {
  const french = useFrench()
  const company_id = useCompanyId()


  const [update_product] = useMutation(UPDATE_PRODUCT)

  const parent_product_fields = useFields({
    product: parent_product,
  })

  const fields = useFields({
    product,
    is_variation: !!parent_product,
    parent_product_fields,
  })

  const do_update = async () => {
    const values = fields.reduce((values, { key, value }) => {
      values[ key ] = value
      return values
    }, {})
    await await_mutate(update_product, {
      variables: {
        company_id,
        market_place,
        product_id: product.product_id,
        ...update_transformer(values),
      },
    })

    if (product.refresh) {
      await product.refresh()
    }
    if (parent_product && parent_product.refresh) {
      await parent_product.refresh()
    }
    if (on_update) {
      on_update()
    }
  }


  return <IntellifoxModalComponent show_modal={show} close={close}
                                   title={<><FiEdit/> {title}</>}
                                   body={<div className="row">
                                     {helper ? <div className="col-12">
                                     <div className="alert alert-success font-weight-bold">
                                       <div className="row">
                                         <div className="col-auto">
                                           <FiInfo/>
                                         </div>
                                         <div className="col">
                                           {helper}
                                         </div>
                                       </div>
                                     </div>
                                   </div> : null}
                                     {fields.map((field) => {
                                       const parsed_value = parse_formula(field.value)

                                       return <Field {...field} help={field.type === 'text' && parsed_value != field.value && !!parsed_value ? <div className="row">
                                         <div className="col-12 small mt-2">
                                           = {parsed_value.toFixed(2)} {french(`par unité`)}
                                         </div>
                                       </div> : null} />
                                     })}
                                   </div>}
                                   action_label={<><FiSave/> {french(`Sauvegarder`)}</>}
                                   on_click={do_update}
  />
}
