import { navigate as reachNavigate } from '@reach/router'

export const absolute = (url) => `${url}`

export const navigate = (url) => reachNavigate(absolute(url))

export const get_intellifox_url = () => {
  // returns something like 'https://intellifox.com' (without trailing /)

  const origin = window.document.location.origin
  if (/localhost/.test(origin)) {
    return 'https://preprod.intellifox.com'
  }
  return origin
}

