import React from 'react'
import useCompanyId from '../../../hooks/useCompanyId'
import useMarketPlace from '../../../hooks/useMarketPlace'
import { get_client } from '../../../graphql/simple-client'
import Button from '../../generic/button'
import { DOWNLOAD_VISUAL } from '../../../graphql/queries/visual'
import { FiDownload } from 'react-icons/all'
import { useTranslate } from '../../../context/lang'
import { useDesignerContext } from '../designer_context'
import { clean_for_label } from '../../../routes/product/text-substitution'
import moment from 'moment'

import axios from 'axios'


export async function force_download(url, file_name) {
  try {
    console.log('force_download', url)
    const response = await axios({
      url: url.replace('http:', 'https:'),
      method: 'GET',
      responseType: 'blob',
    })

    console.log('force_download success, create blob_href...')

    const blob_href = window.URL.createObjectURL(new Blob([response.data]))
    console.log('force_download success, create link...')
    const link = document.createElement('a')
    link.href = blob_href
    link.setAttribute('download', file_name)
    document.body.appendChild(link)
    console.log('force_download success, click link...')
    link.click()
    console.log('force_download success, success?')
    setTimeout(() => {
      console.log('force_download success, clean link')
      document.body.removeChild(link)
    }, 20000)
    console.log('force_download no error found')
  } catch (e) {
    console.log('failed to force_download', e)
    window.open(url)
  }

}

export default function DownloadVisual({ product_id, index, children, has_unsaved_updates, save, region_id }) {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const { product } = useDesignerContext()


  const [in_progress, set_in_progress] = React.useState(false)
  const [error, set_error] = React.useState(false)

  const do_it = async () => {
    const client = get_client()
    set_in_progress(true)
    set_error(false)
    try {
      if (has_unsaved_updates) {
        await save()
      }
      const visual = product.product_info.visuals.find(v => v.index === index)

      const response = await client.request(DOWNLOAD_VISUAL, {
        company_id, market_place: region_id, product_id, index, renderer: visual.renderer,
      })
      set_in_progress(false)
      const asin = product.asin || 'intellifox'
      const product_name = clean_for_label(( product.product_info.title || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 30, [], '').replace(/[\s]+/g, '-')
      const file_name = `${asin}-${product_name}-image-${index + 1}-${moment().format('YYYY-MM-DD-HH-mm-SS')}.jpg`
      await force_download(response.downloadVisual, file_name)
    } catch (e) {
      console.log('failed to download image', e)
      set_error(true)
      set_in_progress(false)
    }
  }

  return (
    <>
      {error ? <Button button={'danger'} onClick={() => set_error(false)}>{translate({
          fr: `Impossible de télécharger l'image`,
          en: `Error while downloading image`,
        })}</Button> :
        <Button onClick={() => do_it()}
                disabled={in_progress}>{in_progress ? translate({
          fr: 'Génération en cours...',
          en: 'Download in progress...',
        }) : ( has_unsaved_updates ? <>{translate({
          fr: `Sauvegarder et télécharger`,
          en: `Save and download`,
        })} <FiDownload/></> : children )} </Button>}

    </>
  )
}
