import { create_query } from './_query_and_mutations_templates'

export const amazon_regions_query = create_query(
  {
    query: 'amazon_regions',
    params: {  },
    output: ` {
  region_id
  label {fr, en}
  market_places {
    market_place
    market_place_id
    amazon_url
    seller_central_url
    vendor_central_url
    label {fr, en}
    lang
    lang_order
    lang_label {fr, en}
    currency
    currency_symbol
    is_managed_by_deepl
    commission_vat
    ads_vat
  }
}
  `,
  },
)
