import React from 'react'
import { get_simple_client } from '../../images/visual-renderer-standalone'
import { create_query } from '../../../graphql/queries/_query_and_mutations_templates'
import moment from 'moment'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import * as _ from 'lodash'

import light from './light.jpg'
import dark from './dark.jpg'
import IntellifoxIcon from '../../../images/intellifox-icon'
import QRCode from 'react-qr-code'
import {
  FiAlertTriangle,
  FiArrowDown,
  FiArrowDownRight,
  FiArrowRight,
  FiArrowUp,
  FiArrowUpRight,
  IoIosWarning,
} from 'react-icons/all'
import { FaAmazon } from 'react-icons/fa'
import { format_money, format_percentage } from '../format_numbers'
import { MarketPlaceDetails } from '../../../hooks/useMarketPlace'
import Flag from '../../../components/flag/flag'
import OrdersGraphV2 from '../orders_graph_v2'
import { useSalesStats } from '../orders_summary'
import { useFrench, useTranslate } from '../../../context/lang'
import LangContext from '../../../context/lang'

// sample_url = http://localhost:3000/sales_result_standalone/tghvgI-6te/pHOl-Qsr81V0TDCkaO7W8HLtkudM0Vx0/2022-03-01/month/en/EUR
// sample_url = http://localhost:3000/sales_result_standalone/GgBytrHITE/pjCKN3IEvpaHnJnxXE8HJNCuXHTvj73i1L4H_aZs19fM9lmW7ktbxDW2EFD_OBCj/2022-03-01/month/en/EUR
// sample_url = http://localhost:3000/sales_result_standalone/W9ec6LNYzN/zPk4af9rWDW0dg58qcdqb3RGdSGPCJcnBUFiJb10_BBsx_O7FYQufY50tVs5d6LX/2022-03-01/month/en/EUR

//http://localhost:9999/settings/spe/zPk4af9rWDW0dg58qcdqb3RGdSGPCJcnBUFiJb10_BBsx_O7FYQufY50tVs5d6LX/R3FuPb93m/W9ec6LNYzN

const useStandaloneSalesData = ({ company_id, token, from, duration, currency }) => {
  const [data, set_data] = React.useState({
    loading: true, error: false,
  })

  const to = moment(from).add(1, duration).add(-1, 'day')

  React.useEffect(() => {

    const go = async () => {
      const client = get_simple_client(token)

      const [optimized_orders, ads] = await Promise.all([await client.request(create_query({

        query: 'optimized_orders', output: `
      {
      
        orders {
          order_id
          order_type
          merchant_id
          market_place
          status
          creation_date
          products {
            asin
            sku
            
            units_sold
            units_returned
            total_sales
            amazon_commissions
            amazon_fba
            amazon_other
            amazon_ads
            total_product_costs
            total_urssaf
            profit
          }
          units_sold
          units_returned
          total_sales
          amazon_commissions
          amazon_fba
          amazon_other
          amazon_ads
          total_product_costs
          total_urssaf
          profit
        }
        products {
          merchant_id
          product_id
          parent_product_id
          asin
          sku
          title
          market_places {
            market_place
          }
          first_visual {
            visual_id
            width
            height
            index
            objects {
                type,
                object_id,
                x,
                y,
                width,
                height,
                angle,
                props
            }
            overrides {
                type,
                object_id,
                x,
                y,
                width,
                height,
                angle,
                props
            }
            renderer
          }
          color_palette {
              colors {
                  color_id
                  color
              }
          }
        }
        
      }`, params: {
          company_id: 'ID!', from: 'Float', to: 'Float', currency: 'String',
        },
      }), {
        company_id, from: moment(from).valueOf(), to: moment(to).endOf('day').valueOf(), currency,
      }), await client.request(create_query({
        query: 'all_product_ads_reports', params: {
          company_id: 'ID!', from: 'Float', to: 'Float', currency: 'String',
        }, output: `{
        amazon_ads_account_id
        market_place
        profile_id

        date
        campaign_id
        ad_group_id
        asin
        sku
        impressions
        clicks
        cost
        currency
        sales
        units
      }`,
      }), {
        company_id, from: moment(from).valueOf(), to: moment(to).endOf('day').valueOf(), currency,
      }),

      ])
      set_data({
        orders: optimized_orders.optimized_orders.orders,
        products: optimized_orders.optimized_orders.products,
        ads: ads.all_product_ads_reports,
      })
    }

    go().catch(e => {
      console.error(e)
      set_data({
        error: true,
      })
    })
  }, [])

  const sales_stats = useSalesStats(data.orders ? data.orders : [], data.ads ? data.ads : [], from, to)

  let market_places = []
  let variation_count = 0
  let shipped
  let returned
  let pending
  let orders_without_product_costs
  let number_of_orders
  if (data.orders) {
    const { orders, products } = data
    market_places = Object.keys(_.groupBy(orders, 'market_place'))
    for (let i = 0; i < products.length; i++) {
      const product = products[ i ]
      if (product.parent_product_id) {
        variation_count = variation_count + 1
      }
    }

    shipped = orders.filter((o) => o.status === 'Shipped')
    returned = orders.filter((o) => o.status === 'Returned')
    pending = orders.filter((o) => o.status === 'Pending')
    orders_without_product_costs = shipped.filter((o) => o.total_product_costs === 0)
    number_of_orders = shipped.length + returned.length + pending.length
  }


  return {
    ...data, ...sales_stats,
    market_places,
    variation_count,
    shipped,
    returned,
    pending,
    orders_without_product_costs,
    number_of_orders,
    to,
  }
}


export const StandaloneSalesResults = ({ company_id, token, from, duration, lang, currency }) => {
  moment.locale(lang)

  return <LangContext.Provider value={{ lang }}>
    <SalesResults company_id={company_id} token={token} from={from} duration={duration} lang={lang}
                  currency={currency}/>
  </LangContext.Provider>
}

const SalesResults = ({ company_id, token, from, duration, currency = 'EUR', lang }) => {

  const french = useFrench()
  const translate = useTranslate()
  const width = 1000
  const height = 1000
  const [big_font_size, _set_big_font_size] = React.useState(90)
  const [sales_display_done, set_sales_display_done] = React.useState(false)
  const [profit_display_done, set_profit_display_done] = React.useState(false)
  const set_big_font_size = (new_font_size) => {
    if (new_font_size < big_font_size) {
      _set_big_font_size(new_font_size)
    }
  }

  const standalone_sales = useStandaloneSalesData({
    company_id, token, from, duration, currency,
  })

  const previous_period_from = moment(from).add(-1, duration)
  const previous_period_to = moment(from).add(-1, 'day')
  const standalone_sales_before = useStandaloneSalesData({
    company_id, token, from: previous_period_from, duration, currency,
  })

  if (standalone_sales.loading || standalone_sales_before.loading) {
    return <DefaultLoading/>
  }

  if (standalone_sales.error || standalone_sales_before.error) {
    return <DefaultError/>
  }

  const url = `https://intellifox.com/discover/ax`
  const margin = 20

  const is_week = duration === 'week'
  const is_month = duration === 'month'


  const {
    orders,
    products,
    ads,
    profit,
    total_sales,
    market_places,
    pending,
    orders_without_product_costs,
    variation_count,
    returned,
    number_of_orders,
    to,
  } = standalone_sales

  const has_pending_orders = pending.length > 0
  const has_pending_orders_indicator = has_pending_orders ? '*' : null

  const has_missing_costs = orders_without_product_costs.length > 0
  const missing_product_cost_indicator = has_missing_costs ? ( has_pending_orders ? '**' : '*' ) : null


  return <div style={{ width, height, fontSize: '24px' }} className={`variable-root dark ${sales_display_done && profit_display_done ? 'ready':''}`}>
    <div className={'position-absolute'} style={{ left: 0, top: 0, width, height }}>
      <img src={dark} style={{ width, height }}/>
    </div>
    <div className={'position-absolute'}
         style={{
           left: margin,
           top: margin,
           width: width - 2 * margin,
           height: height - 2 * margin,
           background: 'rgba(0,0,0,0.3)',
           borderRadius: '10px',
           overflow: 'hidden',
         }}>
      <img src={dark} className={'position-absolute'}
           style={{ left: -margin, top: -margin, width, height, filter: 'blur(100px)' }}/>
    </div>
    <div className="position-absolute p-3"
         style={{
           left: margin,
           top: margin,
           width: width - 2 * margin,
           height: height - 2 * margin,
           color: 'var(--color-text-global)',
         }}>
      <div className="container-fluid pt-2">
        <div className="row align-items-center">
          <div className="col font-weight-bold" style={{ fontSize: 30 }}>
            {moment(from).format('ll')} <FiArrowRight/> {moment(to).format('ll')}
          </div>
          <div className="col-auto text-right">
            <FaAmazon/> {market_places.map((market_place, i) => <span className="mr-2" key={i}><Flag
            market_place={market_place} size={32}/></span>)}
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <BigInfo label={<>{french(`Chiffre d'affaires`)} {has_pending_orders_indicator}</>}
                     trend={<Previously lang={lang} currency={currency} current={total_sales}
                                        previous={standalone_sales_before.total_sales}/>}>
              <FitThisStuff font_size={big_font_size} set_font_size={set_big_font_size} on_done={set_sales_display_done}>
                {format_money(lang, total_sales, currency)}
              </FitThisStuff>
            </BigInfo>

          </div>
          <div className="col-6">
            <BigInfo label={<>{french(`Profits`)} {has_pending_orders_indicator} {missing_product_cost_indicator}</>}
                     background={profit > 0 ? 'rgba(0,64,0,0.3)' : 'rgba(64,0,0,0.3)'}
                     trend={<Previously lang={lang} currency={currency} current={profit}
                                        previous={standalone_sales_before.profit}/>}>
              <FitThisStuff font_size={big_font_size} set_font_size={set_big_font_size} on_done={set_profit_display_done}>
                {format_money(lang, profit, currency)}
              </FitThisStuff>
            </BigInfo>

          </div>
          <div className="col-12">
            <OrdersGraphV2 orders={orders} ads={ads} from={moment(from)} to={moment(to)} height={300}
                           hide_tooltips={true} hide_scale={true} show_legend={true} background_opacity={0.3}/>
          </div>

          <div className="col-6">
            <TransparentItem>
              <CompactInfoLine label={<>{french(`Commande(s)`)}</>}
                               value={number_of_orders}
                               previous_value={standalone_sales_before.number_of_orders}
              />
              <hr className={'my-3 p-0'} style={{ opacity: 0.3 }}/>
              <CompactInfoLine label={<>{french(`Taux de retour`)}</>}
                               value={returned.length / ( number_of_orders )}
                               type={'percentage'}
                               fontSize={30}
                               previous_value={standalone_sales_before.returned.length / standalone_sales_before.number_of_orders}
              />
            </TransparentItem>
          </div>
          <div className="col-6">
            <TransparentItem>
              <CompactInfoLine label={<>{french(`Produit(s)`)}</>}
                               value={products.length}
                               previous_value={standalone_sales_before.products.length}
              />
              {variation_count > 0 ? <>
                <hr className={'my-3 p-0'} style={{ opacity: 0.3 }}/>
                <CompactInfoLine label={<>{translate({
                  fr: `dont variation(s)`,
                  en: 'of which variation(s)',
                })}</>}
                                 value={variation_count}
                                 fontSize={30}
                />
              </> : null}
            </TransparentItem>
          </div>
        </div>

        <div className="row">

          <div className="col-12 small font-italic">
            {french(`Les comparaisons sont effectuées avec la période précédente :`)} {moment(previous_period_from).format('ll')}
            <FiArrowRight/> {moment(previous_period_to).format('ll')}
          </div>

          {pending.length > 0 ? <div className="col-12 font-italic" style={{ fontSize: 18 }}>
            {has_pending_orders_indicator} {french(`Certaines commandes ne sont pas encore expédiées`)}
          </div> : null}
          {orders_without_product_costs.length > 0 ? <div className="col-12 font-italic" style={{ fontSize: 18 }}>
            {missing_product_cost_indicator} {french(`Certains produits n'ont pas leurs coûts renseignés, ce qui fausse le calcul des profits générés`)}
          </div> : null}
        </div>
      </div>

      <div className="position-absolute p-3 w-100" style={{ bottom: 0, right: 0, fontSize: 33 }}>
        <div className="row">
          <div className="col-12 text-right">
            <IntellifoxIcon className={'mr-3'} width={40} height={40}/>
            <span style={{
              fontWeight: 400,
            }}>
              Intellifox
            </span>
          </div>

        </div>
      </div>

    </div>
  </div>
}

const Previously = ({ type = 'money', current, previous, lang, currency, fontSize = 14 }) => {
  const french = useFrench()
  const badge = current >= previous ? 'success' : 'danger'
  const icon = current >= previous ? <FiArrowUp/> : <FiArrowDown/>

  if (!previous) {
    return null
  }

  const percentage = current / previous - 1

  if (type === 'percentage') {
    const is_good = previous > current
    const badge = is_good ? 'success' : 'danger'
    return <TransparentItem padding={'p-2'} margin={'m-0'}>
      <div className="row">
        <div className="col-12 font-weight-bold" style={{ fontSize }}>
           <span className={`bg-${badge} p-1 rounded`}>
            {percentage >= 0 ? '+' : null}{format_percentage(current - previous, 1)}
          </span> {icon}
        </div>
      </div>
    </TransparentItem>
  }

  return <TransparentItem padding={'p-2'} margin={'m-0'}>
    <div className="row">
      <div className="col-12 font-weight-bold" style={{ fontSize }}>
           <span className={`bg-${badge} p-1 rounded`}>
            {percentage > 0 ? '+' : null}{format_percentage(percentage, 1)}
          </span> {icon} {percentage >= 0 ? '+' : null}{type === 'money' ? format_money(lang, current - previous, currency, 0) : null}{type === 'quantity' ? current - previous : null}
      </div>
    </div>
  </TransparentItem>
}
const BigInfo = ({ label, children, background, warning, trend }) => {
  return <TransparentItem background={background}>
    <div className="row">
      <div className="col-12 text-uppercase font-weight-bold">
        {label}
      </div>
      <div className="col-12 font-weight-bold text-right">
        {children}
      </div>
      {trend ? <div className="col-12">
        <div className="row">
          <div className="col">
          </div>
          <div className="col-auto">
            {trend}
          </div>
        </div>
      </div> : null}
      {warning ? <div className="col-12 small font-italic pt-2">
        <IoIosWarning className={'mr-3'}/> {warning}
      </div> : null}
    </div>
  </TransparentItem>
}

const CompactInfoLine = ({ label, value, type = 'quantity', lang, currency, previous_value, fontSize = 50 }) => {
  return <div className="row align-items-center">
    <div className="col-12 text-uppercase small" style={{ lineHeight: 0.8 }}>
      {label}
    </div>
    <div className="col-auto">
      <Previously type={type} currency={currency} lang={lang} previous={previous_value} current={value}/>
    </div>
    <div className="col font-weight-bold text-right" style={{ fontSize, lineHeight: 1 }}>
      {type === 'money' ? format_money(lang, currency, value, 0) : null}
      {type === 'percentage' ? format_percentage(value, 1) : null}
      {type === 'quantity' ? value : null}
    </div>
  </div>
}

const TransparentItem = ({ children, background = 'rgba(0,0,0,0.3)', padding = 'p-4', margin = 'mb-3' }) => {
  return <div className={`${padding} ${margin}`} style={{ background, borderRadius: '10px' }}>
    {children}
  </div>
}

const FitThisStuff = ({ children, font_size, set_font_size, on_done }) => {

  const container_ref = React.useRef(null)

  const check_and_adjust_font_size = React.useCallback(() => {
    if (!container_ref.current) {
      console.log('fix_this_stuff', 'no ref yet')
      return
    }
    const container = container_ref.current.parentElement
    const container_box = container.getBoundingClientRect()
    const content_box = container_ref.current.getBoundingClientRect()

    console.log('fix_this_stuff', content_box.width, '>', container_box.width, '? (font_size = ', font_size, ')')
    if (content_box.width > ( container_box.width - 30 )) {
      console.log('fix_this_stuff', 'yes! set_font_size:', font_size - 2)
      set_font_size(font_size - 2)
      // setTimeout(() => check_and_adjust_font_size(), 10)
    } else {
      console.log('fix_this_stuff', 'no')
      on_done(true)
    }
  }, [font_size, set_font_size])

  setTimeout(() => {
    check_and_adjust_font_size()
  }, 1)

  return <div ref={container_ref} className={'d-inline'} style={{ fontSize: font_size, lineHeight: 1 }}>
    {children}
  </div>

}
