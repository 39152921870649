import React, { useContext } from 'react'
import { useCachedContext } from '../hooks/useCachedContext'
import { get_client } from '../graphql/simple-client'
import { GET_TRANSLATION, translate_interface_query } from '../graphql/queries/translation'
import useCompanyId from '../hooks/useCompanyId'
import * as _ from 'lodash'

const LangContext = React.createContext({
  lang: 'fr',
  set_lang: () => {
  },
})

export const useLangContext = () => {
  return useContext(LangContext)
}

export const InterfaceTranslationsContext = React.createContext({})
export const SetInterfaceTranslationsContext = React.createContext(() => {
})

export const useInterfaceTranslationsContext = () => {
  return useContext(InterfaceTranslationsContext)
}
export const useSetInterfaceTranslationsContext = () => {
  return useContext(SetInterfaceTranslationsContext)
}


const loading = {}
export const useLang = () => {
  const lang_context = useLangContext()
  return lang_context.lang
}

const update_translation = async ({ set_translation, text, source_lang, target_lang }) => {
  if (source_lang ===target_lang) {
    return text
  }

  if (loading[ `${target_lang}-${text}` ]) {
    return (await loading[ `${target_lang}-${text}` ]).translate_interface
  }
  // console.log('Loading translation for', text, '...')
  const client = get_client()

  loading[ `${target_lang}-${text}` ] = client.request(translate_interface_query, {
    source_lang,
    target_lang,
    text,
  })

  const response = await loading[ `${target_lang}-${text}` ]

  set_translation(text, target_lang, response.translate_interface)
  loading[ `${target_lang}-${text}` ] = false
  return response.translate_interface
}

const pattern = '· '
const place_holder = (text) => {
  let result = ''
  for (let i = 0; i < text.length; i++) {
    result = result +  pattern[i%pattern.length]
  }
  return result
}

const useAutoTranslate = () => {
  const interface_translations = useInterfaceTranslationsContext()
  const set_translation = useSetInterfaceTranslationsContext()

  return React.useCallback(({ target_lang, text, source_lang }) => {

    if (!interface_translations[ `${target_lang}-${text}` ]) {
      update_translation({ set_translation, source_lang, target_lang, text })
    }

    return interface_translations[ `${target_lang}-${text}` ] || place_holder(text)
  }, [interface_translations, set_translation])

}

export const useAsyncAutoTranslate = () => {

  const interface_translations = useInterfaceTranslationsContext()
  const set_translation = useSetInterfaceTranslationsContext()

  return React.useCallback(async ({ target_lang, text, source_lang }) => {

    let value = interface_translations[ `${target_lang}-${text}` ]
    if (!value) {
      value = await update_translation({ set_translation, source_lang, target_lang, text })
    }

    return value
  }, [interface_translations, set_translation])
}

export const useTranslate = () => {
  const lang = useLang()

  const auto_translate = useAutoTranslate()

  return React.useCallback((translations) => {
    if (translations[ lang ]) {
      return translations[ lang ]
    }

    const source_lang = Object.keys(translations).indexOf('fr') !== -1 ? 'fr': 'en'
    const text = translations[ source_lang ]
    const target_lang = lang

    if (typeof text !== 'string') {
      return <></>
    }

    return auto_translate({ source_lang, text, target_lang })
  }, [auto_translate, lang])
}

export const useFrench = () => {
  const lang = useLang()

  const auto_translate = useAutoTranslate()

  return React.useCallback((translation, lang_override = null) => {
    if (lang_override === 'fr' || ( !lang_override && lang === 'fr' )) {
      return translation
    }

    const text = translation
    const source_lang = 'fr'
    const target_lang = lang_override || lang

    return auto_translate({ source_lang, text, target_lang })
  }, [auto_translate, lang])
}

export const useEnglish = () => {
  const translate = useTranslate()

  return React.useCallback((text) => {
    return translate({
      en: text
    })
  }, [translate])
}

// const AutoTranslate = ({ source_lang, target_lang, text }) => {
//   const loading_ref = React.useRef('')
//   const [translation, set_translation] = React.useState('')
//
//   const get_translation = async (source_lang, target_lang, text) => {
//     if (!translation_cache[ target_lang ]) {
//       translation_cache[ target_lang ] = {}
//     }
//     if (!loading_ref.current) {
//       loading_ref.current = 'loading'
//       if (!translation_cache[ target_lang ][ text ]) {
//         const client = get_client()
//
//         const response = await client.request(translate_interface_query, {
//           source_lang,
//           target_lang,
//           text,
//         })
//
//         translation_cache[ target_lang ][ text ] = response.translate_interface
//       }
//
//       set_translation(translation_cache[ target_lang ][ text ])
//       loading_ref.current = 'done'
//     }
//   }
//
//   React.useEffect(() => {
//     loading_ref.current = ''
//     if (typeof text === 'string') {
//       get_translation(source_lang, target_lang, text)
//     }
//   }, [target_lang])
//
//   if (typeof text === 'string') {
//     get_translation(source_lang, target_lang, text)
//
//     if (translation) {
//       return <>{translation}</>
//     }
//
//     return <span style={{ opacity: 0.1 }}>{text}</span>
//   }
//
//   return <>{text}</>
// }


export const available_languages = {
  'af': { 'name': 'Afrikaans', 'nativeName': 'Afrikaans', 'dir': 'ltr' },
  'ar': { 'name': 'Arabic', 'nativeName': 'العربية', 'dir': 'rtl' },
  'as': { 'name': 'Assamese', 'nativeName': 'Assamese', 'dir': 'ltr' },
  'bg': { 'name': 'Bulgarian', 'nativeName': 'Български', 'dir': 'ltr' },
  'bn': { 'name': 'Bangla', 'nativeName': 'বাংলা', 'dir': 'ltr' },
  'bs': { 'name': 'Bosnian', 'nativeName': 'bosanski (latinica)', 'dir': 'ltr' },
  'ca': { 'name': 'Catalan', 'nativeName': 'Català', 'dir': 'ltr' },
  'cs': { 'name': 'Czech', 'nativeName': 'Čeština', 'dir': 'ltr' },
  'cy': { 'name': 'Welsh', 'nativeName': 'Welsh', 'dir': 'ltr' },
  'da': { 'name': 'Danish', 'nativeName': 'Dansk', 'dir': 'ltr' },
  'de': { 'name': 'German', 'nativeName': 'Deutsch', 'dir': 'ltr' },
  'el': { 'name': 'Greek', 'nativeName': 'Ελληνικά', 'dir': 'ltr' },
  'en-us': { 'name': 'English', 'nativeName': 'English', 'dir': 'ltr' },
  'es': { 'name': 'Spanish', 'nativeName': 'Español', 'dir': 'ltr' },
  'et': { 'name': 'Estonian', 'nativeName': 'Eesti', 'dir': 'ltr' },
  'fa': { 'name': 'Persian', 'nativeName': 'Persian', 'dir': 'rtl' },
  'fi': { 'name': 'Finnish', 'nativeName': 'Suomi', 'dir': 'ltr' },
  'fil': { 'name': 'Filipino', 'nativeName': 'Filipino', 'dir': 'ltr' },
  'fj': { 'name': 'Fijian', 'nativeName': 'Fijian', 'dir': 'ltr' },
  'fr': { 'name': 'French', 'nativeName': 'Français', 'dir': 'ltr' },
  // 'fr-ca': { 'name': 'French (Canada)', 'nativeName': 'French (Canada)', 'dir': 'ltr' },
  'ga': { 'name': 'Irish', 'nativeName': 'Gaeilge', 'dir': 'ltr' },
  'gu': { 'name': 'Gujarati', 'nativeName': 'ગુજરાતી', 'dir': 'ltr' },
  'he': { 'name': 'Hebrew', 'nativeName': 'עברית', 'dir': 'rtl' },
  'hi': { 'name': 'Hindi', 'nativeName': 'हिंदी', 'dir': 'ltr' },
  'hr': { 'name': 'Croatian', 'nativeName': 'Hrvatski', 'dir': 'ltr' },
  'ht': { 'name': 'Haitian Creole', 'nativeName': 'Haitian Creole', 'dir': 'ltr' },
  'hu': { 'name': 'Hungarian', 'nativeName': 'Magyar', 'dir': 'ltr' },
  'id': { 'name': 'Indonesian', 'nativeName': 'Indonesia', 'dir': 'ltr' },
  'is': { 'name': 'Icelandic', 'nativeName': 'Íslenska', 'dir': 'ltr' },
  'it': { 'name': 'Italian', 'nativeName': 'Italiano', 'dir': 'ltr' },
  'ja': { 'name': 'Japanese', 'nativeName': '日本語', 'dir': 'ltr' },
  'kk': { 'name': 'Kazakh', 'nativeName': 'Kazakh', 'dir': 'ltr' },
  'kmr': { 'name': 'Kurdish (Northern)', 'nativeName': 'Kurdish (Northern)', 'dir': 'ltr' },
  'kn': { 'name': 'Kannada', 'nativeName': 'ಕನ್ನಡ', 'dir': 'ltr' },
  'ko': { 'name': 'Korean', 'nativeName': '한국어', 'dir': 'ltr' },
  'ku': { 'name': 'Kurdish (Central)', 'nativeName': 'Kurdish (Central)', 'dir': 'rtl' },
  'lt': { 'name': 'Lithuanian', 'nativeName': 'Lietuvių', 'dir': 'ltr' },
  'lv': { 'name': 'Latvian', 'nativeName': 'Latviešu', 'dir': 'ltr' },
  'mg': { 'name': 'Malagasy', 'nativeName': 'Malagasy', 'dir': 'ltr' },
  'mi': { 'name': 'Maori', 'nativeName': 'Māori', 'dir': 'ltr' },
  'ml': { 'name': 'Malayalam', 'nativeName': 'മലയാളം', 'dir': 'ltr' },
  'mr': { 'name': 'Marathi', 'nativeName': 'मराठी', 'dir': 'ltr' },
  'ms': { 'name': 'Malay', 'nativeName': 'Melayu', 'dir': 'ltr' },
  'mt': { 'name': 'Maltese', 'nativeName': 'Il-Malti', 'dir': 'ltr' },
  'mww': { 'name': 'Hmong Daw', 'nativeName': 'Hmong Daw', 'dir': 'ltr' },
  'nb': { 'name': 'Norwegian', 'nativeName': 'Norsk', 'dir': 'ltr' },
  'nl': { 'name': 'Dutch', 'nativeName': 'Nederlands', 'dir': 'ltr' },
  'or': { 'name': 'Odia', 'nativeName': 'Odia', 'dir': 'ltr' },
  'otq': { 'name': 'Querétaro Otomi', 'nativeName': 'Querétaro Otomi', 'dir': 'ltr' },
  'pa': { 'name': 'Punjabi', 'nativeName': 'ਪੰਜਾਬੀ', 'dir': 'ltr' },
  'pl': { 'name': 'Polish', 'nativeName': 'Polski', 'dir': 'ltr' },
  'prs': { 'name': 'Dari', 'nativeName': 'Dari', 'dir': 'rtl' },
  'ps': { 'name': 'Pashto', 'nativeName': 'Pashto', 'dir': 'rtl' },
  'pt': { 'name': 'Portuguese (Brazil)', 'nativeName': 'Português (Brasil)', 'dir': 'ltr' },
  'pt-pt': { 'name': 'Portuguese (Portugal)', 'nativeName': 'Português (Portugal)', 'dir': 'ltr' },
  'ro': { 'name': 'Romanian', 'nativeName': 'Română', 'dir': 'ltr' },
  'ru': { 'name': 'Russian', 'nativeName': 'Русский', 'dir': 'ltr' },
  'sk': { 'name': 'Slovak', 'nativeName': 'Slovenčina', 'dir': 'ltr' },
  'sl': { 'name': 'Slovenian', 'nativeName': 'Slovenščina', 'dir': 'ltr' },
  'sm': { 'name': 'Samoan', 'nativeName': 'Samoan', 'dir': 'ltr' },
  'sr-Cyrl': { 'name': 'Serbian (Cyrillic)', 'nativeName': 'srpski (ćirilica)', 'dir': 'ltr' },
  'sr-Latn': { 'name': 'Serbian (Latin)', 'nativeName': 'srpski (latinica)', 'dir': 'ltr' },
  'sv': { 'name': 'Swedish', 'nativeName': 'Svenska', 'dir': 'ltr' },
  'sw': { 'name': 'Swahili', 'nativeName': 'Kiswahili', 'dir': 'ltr' },
  'ta': { 'name': 'Tamil', 'nativeName': 'தமிழ்', 'dir': 'ltr' },
  'te': { 'name': 'Telugu', 'nativeName': 'తెలుగు', 'dir': 'ltr' },
  'th': { 'name': 'Thai', 'nativeName': 'ไทย', 'dir': 'ltr' },
  'tlh-Latn': { 'name': 'Klingon (Latin)', 'nativeName': 'Klingon (Latin)', 'dir': 'ltr' },
  'tlh-Piqd': { 'name': 'Klingon (pIqaD)', 'nativeName': 'Klingon (pIqaD)', 'dir': 'ltr' },
  'to': { 'name': 'Tongan', 'nativeName': 'lea fakatonga', 'dir': 'ltr' },
  'tr': { 'name': 'Turkish', 'nativeName': 'Türkçe', 'dir': 'ltr' },
  'ty': { 'name': 'Tahitian', 'nativeName': 'Tahitian', 'dir': 'ltr' },
  'uk': { 'name': 'Ukrainian', 'nativeName': 'Українська', 'dir': 'ltr' },
  'ur': { 'name': 'Urdu', 'nativeName': 'اردو', 'dir': 'rtl' },
  'vi': { 'name': 'Vietnamese', 'nativeName': 'Tiếng Việt', 'dir': 'ltr' },
  'yua': { 'name': 'Yucatec Maya', 'nativeName': 'Yucatec Maya', 'dir': 'ltr' },
  'yue': { 'name': 'Cantonese (Traditional)', 'nativeName': '粵語 (繁體中文)', 'dir': 'ltr' },
  'zh-Hans': { 'name': 'Chinese Simplified', 'nativeName': '简体中文', 'dir': 'ltr' },
  'zh-Hant': { 'name': 'Chinese Traditional', 'nativeName': '繁體中文', 'dir': 'ltr' },
}

export const get_browser_lang = () => {

  const language = navigator.language.toLowerCase()
  const [lang] = language.split('-')

  if (available_languages[ language ]) {
    return language
  }

  if (available_languages[ lang ]) {
    return lang
  }

  return 'en'
}

export default LangContext
