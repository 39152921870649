import React from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'

export const compute_layout = ({ rows, available_width, available_height, top = 0, left = 0 }) => {
  const layout = []

  const auto_rows = rows.reduce((auto_rows, { height }) => ( height === 'auto' ? auto_rows + 1 : auto_rows ), 0)

  const auto_height = rows.reduce((auto_height, { height }) => ( height === 'auto' ? auto_height : auto_height - height ), available_height) / auto_rows


  for (let i = 0; i < rows.length; i++) {
    const row = rows[ i ]
    // height + content or cols

    const height = row.height === 'auto' ? auto_height : row.height

    const row_dimension = {
      ...row,
      width: available_width,
      height, top, left,
    }

    if (row.content) {
      layout.push({
        ...row_dimension,
        content: row.content,
      })
    } else {

      const auto_cols = row.cols.reduce((auto_cols, { width }) => ( width === 'auto' ? auto_cols + 1 : auto_cols ), 0)

      const auto_width = row.cols.reduce((auto_width, { width }) => ( width === 'auto' ? auto_width : auto_width - width ), available_width) / auto_cols

      let current_left = left
      for (let j = 0; j < row.cols.length; j++) {
        const col = row.cols[ j ]
        // width + content or rows

        const col_dimension = {
          ...col,
          top, left: current_left, height,
          width: col.width === 'auto' ? auto_width : col.width,
        }

        if (col.content) {
          layout.push({
            ...col_dimension,
            content: col.content,
          })
        } else {
          layout.push(...compute_layout({
            rows: col.rows,
            available_height: height,
            available_width: col_dimension.width,
            left: current_left,
            top,
          }))
        }

        current_left += col_dimension.width
      }

    }

    top += row_dimension.height

  }

  return layout
}

export default function FullScreenLayout({ rows }) {
  const window_size = useWindowSize()

  const show_debug = false

  const layout = compute_layout({ rows, available_width: window_size.width, available_height: window_size.height })

  return (
    <div style={{ position: 'fixed', left: 0, top: 0, width: window_size.width, height: window_size.height }}>
      {layout.map(({ left, top, width, height, content, dynamic_content, className = '', id }, i) => {
        return <div className={`d-inline-block position-absolute ${className}`} key={id || i} style={{
          left, top, width, height,
        }}>
          <div className="h-100 w-100 position-relative" style={{
            border: show_debug ? '1px solid white' : '',
          }}>
            {dynamic_content ? dynamic_content({ width, height }) : content}
          </div>

        </div>
      })
      }
    </div>
  )
}
