import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content from './select__content'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { FilterSlider } from './select_filters'
import { Browse_Content_SubTitle, Browse_Content_Title } from '../designer/browse__content'
import { useTranslate } from '../../../context/lang'
import { TextExclusiveOptions } from './select_text'

const marker_types = [
  {
    label_translations: {
      fr: 'Flèche',
      end: 'Arrow',
    }, value: 'arrow1',
  },
  {
    label_translations: {
      fr: 'Point',
      end: 'Point',
    }, value: 'point',
  },
  {
    label_translations: {
      fr: 'Mesure',
      end: 'Measure',
    }, value: 'measure',
  },
  {
    label_translations: {
      fr: 'Aucun',
      end: 'None',
    }, value: 'none',
  },
]

export default function SelectArrowProperties() {
  const { get_selected_object, update_object_several_props, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])

  return (
    <Option_Content>
      <Browse_Content_Title>{translate({
        fr: `Propriétés de la flèche`,
        en: `Arrow properties`,
      })}</Browse_Content_Title>

      <Browse_Content_SubTitle>
        Largeur du trait
      </Browse_Content_SubTitle>
      <FilterSlider filters={selected_object.props} step={0.1} default_value={5} max={20}
                    field={'stroke_width'} do_update_object={(update) => {
        // {stroke_width && stroke_width_value }
        update_object_several_props({
          object: selected_object,
          props: [
            {
              field: 'stroke_width_value',
              value: update.stroke_width_value,
            },
          ],
        })
      }}/>


      <Browse_Content_SubTitle>
        Type de terminaisons
      </Browse_Content_SubTitle>

      <TextExclusiveOptions label={translate({
        fr: 'Début',
        en: 'Start'
      })}options={marker_types} field={'start_type'}/>
      <TextExclusiveOptions label={translate({
        fr: 'Fin',
        en: 'End'
      })}options={marker_types} field={'end_type'}/>

    </Option_Content>
  )
}
