import React, { useContext } from 'react'

import './left-menu.scss'
import IntellifoxIcon from '../../images/intellifox-icon'
import { navigate } from '../../absolute_link'
import { useTheme } from '../../context/theme'
import { settings_section } from './sections'
import UserContext from '../../context/user'
import { WiDaySunny } from 'react-icons/wi'
import { TiWeatherNight } from 'react-icons/ti'
import { MdClose, MdMenu } from 'react-icons/md'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useHasActiveSubscription } from '../../hooks/useCompanyId'
import ExternalLink from '../generic/external-link'
import { available_languages, useFrench, useLang, useLangContext, useTranslate } from '../../context/lang'
import { FaLock, FaMinus, FaPlus } from 'react-icons/all'
import OrderNotification from '../../routes/order/order_notification'
import { useOrderNotifications } from '../../context/order_notifications'
import { LeftMenuIcon, section_color } from '../../routes/colors'
import { scroll_to_top } from '../../routes/product_v2/scroll'
import { IntellifoxModalComponent } from '../generic/modal'
import Button from '../generic/button'
import Flag from '../flag/flag'
import { amazon_regions } from '../../hooks/useMarketPlace'
import { update_user_lang } from '../../graphql/queries/user'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import TextButton from '../generic/text_button'
import InternalLink from '../generic/internal-link'
import { AutoRedirectToSellerSuite } from "../../routes/welcome";

const I18nMenu = ({ label, icon }) => {
  const { lang, set_lang } = useLangContext()

  const user = useContext(UserContext)

  const translate = useTranslate()
  const french = useFrench()

  const [show, set_show] = React.useState(false)
  const [show_more, set_show_more] = React.useState(false)

  const available_langs_from_amazon_market_places = amazon_regions.reduce((available_langs_from_amazon_market_places, { market_places }) => {
    for (let i = 0; i < market_places.length; i++) {
      const market_place_details = market_places[ i ]

      let market_place_lang = market_place_details.lang
      let lang_label = market_place_details.lang_label
      if (/^en/.test(market_place_lang)) {
        market_place_lang = 'en'
        lang_label = {
          fr: 'Anglais',
          en: 'English',
        }
      }

      let available_lang = available_langs_from_amazon_market_places.find((l) => l.lang === market_place_lang)

      if (available_lang) {
        available_lang.market_places.push(market_place_details.market_place)
      } else {
        available_lang = {
          lang: market_place_lang,
          lang_label: lang_label,
          market_places: [market_place_details.market_place],
        }
        available_langs_from_amazon_market_places.push(available_lang)
      }

      if (market_place_details.lang_order) {
        available_lang.lang_order = market_place_details.lang_order
      }

      if (available_lang.lang === lang) {
        available_lang.lang_order = -1
        available_lang.is_current = true
      }
    }
    return available_langs_from_amazon_market_places
  }, [])

  available_langs_from_amazon_market_places.push({
      lang: 'zh-Hans',
      lang_label: {
        fr: 'Chinois',
      },
      market_places: ['cn'],
    },
  )

  available_langs_from_amazon_market_places.sort((l1, l2) => {
    if (l1.lang_order && l2.lang_order) {
      return l1.lang_order < l2.lang_order ? -1 : 1
    }

    if (l1.lang_order) {
      return -1
    }
    if (l2.lang_order) {
      return 1
    }

    return 0

  })

  const [update_user_lang_mutation] = useMutation(update_user_lang)

  const do_update_user_lang = async (lang) => {
    set_lang(lang)
    if (user.user) {
      await await_mutate(update_user_lang_mutation, {
        variables: {
          user_id: user.user.user_id,
          lang,
        },
      })
    }
  }

  return <>
    <MenuTitle className={'mb-5'} onClick={() => set_show(true)}
               label={translate({
                 fr: 'Langue',
               })}
               icon={<LeftMenuIcon section={'lang'}/>}/>
    <IntellifoxModalComponent show_modal={show} close={() => set_show(false)}
                              title={french(`Changer la langue de l'interface`)}
                              body={<div className="row">
                                <div className="col-12">
                                  {available_langs_from_amazon_market_places.map(({
                                                                                    lang,
                                                                                    lang_label,
                                                                                    market_places,
                                                                                    is_current,
                                                                                  }) => {
                                    return <Button className={'mr-2'}
                                                   button={is_current ? 'primary' : 'secondary'}
                                                   onClick={() => do_update_user_lang(lang)}
                                                   key={lang}>{french(lang_label[ 'fr' ], lang)} <Flag market_place={market_places[0]} key={market_places[0]}/></Button>
                                  })}
                                </div>
                                <div className="col-12 mt-2">
                                  <Button onClick={() => set_show_more(!show_more)} outline={true}>
                                    {show_more ? <FaMinus/> :
                                      <FaPlus/>} {french(show_more ? 'Voir moins' : 'Voir plus')}</Button>
                                </div>
                                {show_more ? Object.keys(available_languages).map((lang) => {
                                  const { nativeName } = available_languages[ lang ]
                                  return <div className="col-12" key={lang}>
                                    <TextButton onClick={() => do_update_user_lang(lang)}>{nativeName}
                                      <small>({lang})</small></TextButton>
                                  </div>
                                }) : null}
                              </div>}
                              on_click={() => do_update_user_lang(lang)}
                              action_label={french('Sauvegarder')}
    />
  </>
}

export const MenuTitle = ({
                            label,
                            label_className = '',
                            is_active = false,
                            active_color,
                            onClick,
                            description,
                            icon,
                            link,
                            current_path,
                            className = '',
                            label_font_weight = 400,
                            label_font_size,
                            external_link,
                            color,
                            locked,
                          }) => {
  const path_tester = new RegExp(link)

  const is_current_path = link && path_tester.test(current_path)

  const compute_color = () => {
    if (is_current_path) {
      return active_color
    }
    return null
  }
  const content = (
    <>
      <div className="position-relative">
        {/*{is_current_path ? <RoundDecorationRight size={10}/> : null}*/}
        {locked ? <div className="position-absolute small" style={{ right: 10, top: 8, opacity: 0.7 }}>
          <FaLock/>
        </div> : null}
        <div
          className={`position-relative item-container left-menu-opened-width pt-2 pb-2 clickable ${className} ${is_current_path || is_active ? 'is-active' : ''}`}
          style={{
            marginBottom: 10,
            opacity: locked ? 0.3 : 1,
          }}
          onClick={(e) => {
            if (!locked && onClick) {
              onClick(e)
            }
          }}>


          <div className="position-absolute" style={{
            width: is_current_path ? 264 : 12,
            transition: 'width 0.2s',
            height: 46,
            // borderRadius: '4px',
            left: 0,
            top: 0,
            background: color,
          }}></div>

          <div className="row no-gutters align-items-center">
            {icon ? <div className="left-menu-fixed-width d-inline-block text-center"
                         style={{
                           perspective: '100px',
                           color: compute_color(),
                         }}

            >
              <div>{icon}</div>
            </div> : null}
            <div className="col item-label text-ellipsis">
              <div className="row">
                <div className={`col-12 pr-5 text-ellipsis ${label_className}`}
                     style={{
                       lineHeight: '18px',
                       fontWeight: label_font_weight,
                       fontSize: label_font_size,
                       color: compute_color(),
                     }}>
                  {label}
                </div>
                <div className="col-12 text-ellipsis" style={{ lineHeight: '14px', fontWeight: 300 }}>
                  <small style={{ lineHeight: '10px', fontWeight: 200 }}>{description}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
  if (locked) {
    return content
  }

  if (onClick) {
    return content
  }

  if (external_link) {
    return <ExternalLink to={external_link} style={{ textDecoration: 'none', color: 'inherit' }}>
      {content}
    </ExternalLink>
  }
  return (
    <InternalLink to={link} onClick={() => {
      setTimeout(() => scroll_to_top(), 100)
    }}>
      {content}
    </InternalLink>
  )
}


export const useIsMobile = () => {
  const window_size = useWindowSize()

  return window_size.width <= 1080
}

export const useIsVerySmall = () => {
  const window_size = useWindowSize()

  return window_size.width < 768 || window_size.height < 512
}

export default function LeftMenu({ location, is_logged_in, disconnect }) {
  const { theme, set_theme } = useTheme()
  const user = useContext(UserContext)
  const lang = useLang()

  const { order_notifications } = useOrderNotifications()

  const translate = useTranslate()

  // const is_path_beyond_second_level = location.pathname.split('/').length > 3

  const has_active_subscription = useHasActiveSubscription()

  const is_mobile = useIsMobile()

  const [is_open_on_mobile, set_is_open_on_mobile] = React.useState(false)
  React.useEffect(() => {
    if (!is_mobile) {
      set_is_open_on_mobile(false)
    }
  }, [is_mobile])

  const is_product_route = false///product/.test(location.pathname)

  const collapse_left_menu = is_product_route && !is_mobile //&& is_path_beyond_second_level


  const ThemeIcon = theme === 'dark' ? WiDaySunny : TiWeatherNight

  if (!is_open_on_mobile && is_mobile) {
    return <div className={'min-vh-100 '} style={{ left: 0, top: 0, width: 0, height: '100%', zIndex: 13 }}>
      <div className="h1 clickable position-fixed" style={{ zIndex: 13 }} onClick={() => set_is_open_on_mobile(true)}>
        <MdMenu/></div>
    </div>
  }


  return (
    <div
      className={`left-menu-container min-vh-100  ${collapse_left_menu ? '' : 'force-full-width'}  ${is_mobile ? 'position-fixed' : ''}`}
      style={{ zIndex: is_mobile ? 13 : null }}>
      {is_mobile ? <>
        <div className="position-fixed h1 clickable w-100 h-100 text-right"
             style={{ left: 0, top: 0, background: 'rgba(0,0,0,0.8)' }}
             onClick={() => set_is_open_on_mobile(false)}><MdClose/></div>
      </> : null}
      <div className="row no-gutters">
        <div className="left-menu left-menu-fixed-width position-fixed col ">
          <div
            className={`item-container left-menu-opened-width  clickable`}
            onClick={() => {
              if (is_logged_in) {
                if (is_mobile) {
                  set_is_open_on_mobile(false)
                } else {
                  navigate('/welcome')
                }
              } else {
                window.location = 'https://intellifox.com/'
              }
            }} style={{
            color: 'white',
            height: 50,
          }}>
            <div className="row no-gutters align-items-center">
              <div className="left-menu-fixed-width d-inline-block text-center"
                   style={{
                     perspective: '100px',
                   }}
              >
                <IntellifoxIcon className={''} width={40} height={40}/>
              </div>
              <div className="col item-label" style={{
                fontSize: 33,
                fontWeight: 300,
              }}>
                <b>Intellifox</b>
              </div>
            </div>
          </div>

          <div className="scrollable-menu" style={{ height: `calc(100% - ${50}px)` }}>

            {has_active_subscription || is_logged_in ?
              <>
                <AutoRedirectToSellerSuite/>
                <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'welcome'}/>}
                           label={translate({
                             fr: 'Accueil',
                             en: 'Welcome',
                           })} link={'/welcome'} color={section_color('welcome')}/>
                <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'product'}/>}
                           label={translate({
                             fr: 'Produits',
                             en: 'Products',
                           })} link={'/product'} color={section_color('product')}
                />

                <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'ppc'}/>}
                           label={translate({
                             fr: 'Publicités',
                             en: 'Ads',
                           })}
                           link={'/ppc'} color={section_color('ppc')}/>
                <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'order'}/>}
                           label={translate({
                             fr: 'Ventes',
                             en: 'Sales',
                           })} link={'/order'} color={section_color('order')}/>
                <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'comments'}/>}
                           label={translate({
                             fr: 'Commentaires',
                             en: 'Comments',
                           })} link={'/comments'} color={section_color('comments')}/>
                <MenuTitle current_path={location.pathname}
                           icon={<LeftMenuIcon section={'alerts'}/>}
                           label={translate({
                             fr: 'Alertes',
                             en: 'Alerts',
                           })} link={'/alerts'} color={section_color('alerts')}

                />
                <MenuTitle link={'/help'} current_path={location.pathname} label={translate({
                  fr: 'Aide',
                  en: 'Help',
                })}
                           color={section_color('help')}
                           icon={<LeftMenuIcon section={'help'}/>}
                />
                <MenuTitle
                  external_link={lang === 'fr' ? 'https://community.intellifox.com/c/francais/16' : 'https://community.intellifox.com/c/english/17'}
                  current_path={location.pathname}
                  label={translate({
                    fr: 'Communauté',
                    en: 'Community',
                  })}
                  color={section_color('mastermind')}
                  icon={<LeftMenuIcon section={'mastermind'}/>}


                />

                <MenuTitle link={'/settings'} current_path={location.pathname}
                           label={translate(settings_section.label_translations)}
                           color={section_color('settings')}
                           active_color={'black'}
                           icon={<LeftMenuIcon section={'settings'}/>}
                />
              </> : null}
            {is_logged_in ?
              <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'subscription'}/>}
                         color={section_color('subscription')}
                         active_color={'black'}
                         label={translate({
                           fr: 'Abonnement',
                           en: 'Subscription',
                         })} link={'/subscription'}/> : null}
            {is_logged_in ?
              <MenuTitle current_path={location.pathname} icon={<LeftMenuIcon section={'referral'}/>}
                         color={section_color('referral')}
                         active_color={'white'}
                         label={<>{translate({
                           fr: 'Parrainages',
                           en: 'Referrals',
                         })}</>} link={'/referral'}/> : null}

            {is_logged_in ?
              <MenuTitle className={'mt-5'} onClick={disconnect} label={translate({
                fr: 'Déconnecter',
                en: 'Log out',
              })}
                         icon={<LeftMenuIcon section={'logout'}/>}
              /> : null}

            <MenuTitle className={'mt-5'} onClick={() => set_theme(theme === 'dark' ? 'light' : 'dark')}
                       label={theme === 'dark' ? translate({
                         fr: 'Mode jour',
                         en: 'Light theme',
                       }) : translate({
                         fr: 'Mode nuit',
                         en: 'Dark theme',
                       })}
                       icon={<LeftMenuIcon section={'theme'}/>}/>

            <I18nMenu/>


          </div>
        </div>
      </div>

      {is_logged_in && has_active_subscription ? <OrderNotification/> : null}


    </div>
  )
}
