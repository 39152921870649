import React from 'react'
import { IntellifoxModalComponent } from '../../generic/modal'
import { useDesignerContext } from '../designer_context'
import Field from '../../generic/field'
import { useTranslate } from '../../../context/lang'

export default function SetDimensionsModal({ show, close }) {
  const { get_selected_object, update_single_object } = useDesignerContext()

  const translate = useTranslate()

  const selected_object = get_selected_object()

  const [width, set_width] = React.useState(selected_object.width)
  const [height, set_height] = React.useState(selected_object.height)
  const [x, set_x] = React.useState(selected_object.x)
  const [y, set_y] = React.useState(selected_object.y)

  const update = (label, value, setter) => {
    return <div className="row mb-2">
      <div className="col-12 font-weight-bold">
        {label}
      </div>
      <Field type="number" value={value} setter={(n) => setter(parseFloat(n))}/>
    </div>
  }

  return (
    <IntellifoxModalComponent show_modal={show} close={close}
                              title={translate({
                                  fr: "Régler précisément la taille de l'objet sélectionné",
                                  en: "Set dimensions for the selected object",
                              })}
                              body={<>
                                {update(translate({
                                    fr: 'Largeur',
                                    en: 'Width',
                                }), width, set_width)}
                                {update(translate({
                                    fr: 'Hauteur',
                                    en: 'Height',
                                }), height, set_height)}
                                {update(translate({
                                    fr: 'Éloignement de la gauche',
                                    en: 'Left',
                                }), x, set_x)}
                                {update(translate({
                                    fr: 'Éloignement du haut',
                                    en: 'Top',
                                }), y, set_y)}
                                <div className="row">
                                  <div className="col-12 font-weight-bold">
                                    {translate({
                                        fr: `Pour référence :`,
                                        en: `For reference:`
                                    })}
                                  </div>
                                  <div className="col-12">
                                    {translate({
                                        fr: `Largeur totale de l'image`,
                                        en: `Total image width`
                                    })} = 500
                                  </div>
                                  <div className="col-12">
                                    {translate({
                                        fr: `Hauteur totale de l'image`,
                                        en: `Total image height`
                                    })} = 500
                                  </div>
                                </div>
                              </>}
                              action_label={translate({
                                  fr: 'Mettre à jour',
                                  en: 'Update',
                              })}
                              on_click={() => {
                                update_single_object(selected_object.object_id, {
                                  width,
                                  height,
                                  x,
                                  y
                                })
                              }}
    />
  )
}
