import {
  FaBell,
  FaChartBar, FaCrosshairs,
  FaFileInvoice,
  FaStar, FiBarChart2, FiBell,
  FiBellOff,
  FiBox,
  FiCompass, FiCrosshair, FiDollarSign, FiLogOut, FiPieChart, FiRefreshCw, FiShare2,
  FiStar, FiUsers, GiMoneyStack,
  GiReceiveMoney, GoTasklist, MdRateReview,
  MdTranslate, TiChartPie,
} from 'react-icons/all'
import { useOrderNotifications } from '../context/order_notifications'
import { GiExitDoor, GiPayMoney } from 'react-icons/gi'
import { MdHelpOutline, MdNotificationsActive, MdNotificationsNone } from 'react-icons/md'
import { useTheme } from '../context/theme'
import { WiDaySunny } from 'react-icons/wi'
import { TiWeatherNight } from 'react-icons/ti'
import IntellifoxIcon from '../images/intellifox-icon'
import { FiSettings } from 'react-icons/fi'
import React from 'react'

const sections = [
  'welcome',
  'product',
  'ppc',
  'order',
  'comments',
  'alerts',
  'help',
  'mastermind',
  'settings',
  'subscription',
  'referral'
]
export const section_color = (section) => {
  return `var(--color-menu-${Math.min(sections.indexOf(section), 10) + 1})`
}

export const colors = {
  welcome: 'var(--color-menu-1)',
  product: 'var(--color-menu-2)',
  ppc: 'var(--color-menu-3)',
  order: 'var(--color-menu-4)',
  comments: 'var(--color-menu-5)',
  alerts: 'var(--color-menu-6)',
  help: 'var(--color-menu-7)',
  mastermind: 'var(--color-menu-8)',
  settings: 'var(--color-menu-9)',
  subscription: 'var(--color-menu-9)',
}

export const useSectionIcon = (section) => {
  const { order_notifications } = useOrderNotifications()
  const { theme, set_theme } = useTheme()

  const icons = {
    welcome: FiCompass,
    product: GoTasklist,
    ppc: FaCrosshairs,
    order: FaChartBar,
    comments: MdRateReview,
    alerts: FaBell,
    help: MdHelpOutline,
    mastermind: FiUsers,
    settings: FiSettings,
    subscription: FiRefreshCw,
    logout: FiLogOut,
    theme: theme === 'dark' ? WiDaySunny : TiWeatherNight,
    lang: MdTranslate,
    share: FiShare2,
    referral: GiMoneyStack
  }

  return icons[ section ]
}

export const LeftMenuIcon = ({ section }) => {
  const Icon = useSectionIcon(section)
  return <Icon className="item-icon" style={{ width: 24, height: 24 }}/>
}

export const WelcomeIcon = ({ section, margin = 'mr-3', style={} }) => {
  const Icon = useSectionIcon(section)
  return <Icon style={{ fontSize: '150%', ...style }} className={`${margin}`}/>
}

export const SectionIcon = ({ section }) => {
  const Icon = useSectionIcon(section)
  return <Icon />
}
