import React, { useCallback } from 'react'
import { Bar, BarStack, Line } from '@visx/shape'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import moment from 'moment'
import { format_big_number, sum_fees, sum_cash_flow, sum_profits } from './format_numbers'

import * as _ from 'lodash'
import { useWindowSize } from '../../hooks/useWindowSize'
import { GradientDarkgreenGreen, GradientPurpleRed, LinearGradient } from '@visx/gradient'
import {
  accentColor,
  accentColorDark,
  amazonColor,
  background,
  background2, lossColor,
  profitColor,
} from './orders_graph_cumulated'
import { defaultStyles, Tooltip, TooltipWithBounds, withTooltip } from '@visx/tooltip'
import OrdersSummary, { useOrdersSummary } from './orders_summary'
import Item from '../../components/generic/item'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { is_ready } from '../../hooks/useCachedContext'
import { useTranslate } from '../../context/lang'

const tooltipStyles = {
  position: 'absolute',
  zIndex: 42,
}

// const data = cityTemperature.slice(0, 12)
// const keys = Object.keys(data[ 0 ]).filter(d => d !== 'date')


// accessors
const getDate = (d) => d.day
const getProfits = (d) => d.profits
const getFees = (d) => -d.fees


export const OrdersGraphAxis = ({ height, width, moneyScale, dateScale }) => {


  const money_ticks = moneyScale.ticks()
  const filtered_money_ticks = money_ticks.filter((t, i) => i !== 0 && i !== money_ticks.length - 1)


  return <>
    <svg width={100} height={height + 20} className="position-absolute" style={{ left: -99, top: -10 }}>
      <AxisLeft scale={moneyScale} tickValues={filtered_money_ticks} left={100} top={10} stroke={'transparent'}
                tickStroke={'var(--color-text-global)'}
                tickFormat={(v) => {
                  return `${format_big_number(v, 0)}`
                }}
                tickComponent={({ x, y, formattedValue }) => {
                  return <text transform="" x={x - 4} y={y + 4} fill="var(--color-text-global)" fontSize="10"
                               textAnchor="end">{formattedValue}</text>
                }}
      />
    </svg>
    <svg width={width} height={30} className="position-relative"
         style={{ borderRadius: '30px', overflow: 'hidden', top: -1 }}>
      <AxisBottom scale={dateScale} stroke={'transparent'} tickStroke={'var(--color-text-global)'}
                  tickLabelProps={() => ( {
                    fill: 'var(--color-text-global)',
                    fontSize: 10,
                    textAnchor: 'middle',
                  } )}/>
    </svg>
  </>
}


export default function OrdersGraphV2({
                                        orders,
                                        ads,
                                        from,
                                        to,
                                        height,
                                        hide_tooltips,
                                        hide_scale,
                                        show_legend,
                                        background_opacity,
                                      }) {
  const window_size = useWindowSize()
  const row_ref = React.useRef(null)
  const [computed_width, set_computed_width] = React.useState(500)

  React.useEffect(() => {
    set_computed_width(row_ref.current.getBoundingClientRect().width - 30)
  }, [window_size])


  return <div className="row" ref={row_ref}>
    <div className="col-12">
      <DisplayOrdersGraph width={computed_width} orders={orders} from={from} to={to}
                          ads={ads} height={height}
                          hide_tooltips={hide_tooltips}
                          hide_scale={hide_scale}
                          show_legend={show_legend}
                          background_opacity={background_opacity}
      />
    </div>
  </div>
}

const DisplayOrdersGraph = withTooltip(
  ({
     orders,
     width = 500,
     height = 500,
     margin = { top: 0, right: 0, bottom: 0, left: 0 },
     showTooltip,
     hideTooltip,
     tooltipData,
     tooltipTop = 0,
     tooltipLeft = 0,
     from, to,
     ads,
     hide_tooltips,
     hide_scale,
     show_legend,
     background_opacity = 1,
   }) => {

    // bounds
    const xMax = width
    const yMax = height - margin.top

    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom


    const data = React.useMemo(() => {
      const orders_grouped_by_days = _.groupBy(orders.map((o) => {
        return {
          ...o,
          day: moment(o.creation_date).format('YYYYMMDD'),
        }
      }), 'day')

      const summaries = []

      for (let i = moment(from).startOf('day'); i.valueOf() <= to.valueOf(); i = moment(i).add(1, 'day')) {

        const day = i.format('YYYYMMDD')

        const orders = orders_grouped_by_days[ day ] ? orders_grouped_by_days[ day ] : []

        const ads_for_day = ads.filter((a) => a.date === day) || []

        let amazon_fees =
          _.sumBy(orders, 'amazon_commissions')
          + _.sumBy(orders, 'amazon_fba')
          + _.sumBy(orders, 'amazon_other')
          + _.sumBy(ads_for_day, 'cost')

        let product_and_other =
          _.sumBy(orders, 'total_product_costs')
          + _.sumBy(orders, 'total_urssaf')


        const profit = _.sumBy(orders, 'profit') + _.sumBy(ads_for_day, 'cost')

        // if (profit < 0) {
        //   if (product_and_other < profit) {
        //     product_and_other = 0
        //     amazon_fees = amazon_fees - profit
        //   } else {
        //     product_and_other = product_and_other - profit
        //   }
        // }


        summaries.push({
          day: parseInt(day),
          date: i.valueOf(),
          amazon_fees: -amazon_fees,
          product_and_other: -product_and_other,
          profit: profit > 0 ? profit : 0,
          loss: profit < 0 ? -profit : 0,
          orders,
        })
      }
      return summaries
    }, [ads, from, orders, to])

    const keys = ['amazon_fees', 'product_and_other', 'profit', 'loss']

    const [dateScale, moneyScale, colorScale] = React.useMemo(() => {
      const dateScale = scaleBand({
        domain: data.map((d) => d.day),
        padding: 0.2,
      })

      const moneyScale = scaleLinear({
        domain: [
          Math.min(...( data.map((d) => d.amazon_fees + d.product_and_other + d.profit) ), 0),
          Math.max(...( data.map((d) => d.amazon_fees + d.product_and_other + d.profit) )),
        ],
        nice: true,
      })
      const colorScale = scaleOrdinal({
        domain: keys,
        range: [`url('#area-gradient2')`, `url('#area-gradient')`, `url('#area-gradient3')`, `url('#area-gradient4')`],
      })
      dateScale.rangeRound([0, xMax])
      moneyScale.range([yMax, 0])

      return [dateScale, moneyScale, colorScale]
    }, [data, xMax, yMax])


    // tooltip handler
    const handleTooltip = useCallback(
      (event, bar) => {
        const d = data[ bar.index ]
        const x = bar.x + bar.width / 2
        const top = bar.y

        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: top,
        })
      },
      [dateScale, data, showTooltip, moneyScale],
    )


    return <div style={{ position: 'relative' }}>
      <svg width={width} height={height} className="">
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="url(#area-background-gradient)"
          rx={14}
        />
        <LinearGradient id="area-background-gradient" from={background} to={background2}
                        fromOpacity={background_opacity} toOpacity={background_opacity}/>

        <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={1}/>
        <LinearGradient id="area-gradient2" from={amazonColor} to={amazonColor} toOpacity={1}/>
        <LinearGradient id="area-gradient3" from={profitColor} to={profitColor} toOpacity={1}/>
        <LinearGradient id="area-gradient4" from={lossColor} to={lossColor} toOpacity={1}/>
        <GradientPurpleRed id="gradient1"/>
        <GradientDarkgreenGreen id="gradient2"/>
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={moneyScale}
          width={xMax}
          height={yMax}
          stroke="black"
          strokeOpacity={0.1}
          xOffset={dateScale.bandwidth() / 2}
        />
        <Group top={margin.top}>
          <BarStack
            data={data}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={moneyScale}
            color={colorScale}
          >
            {barStacks =>
              barStacks.map(barStack =>
                barStack.bars.map(bar => (
                  <Bar
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    rx={2}
                    onTouchStart={(e) => handleTooltip(e, bar)}
                    onTouchMove={(e) => handleTooltip(e, bar)}
                    onMouseMove={(e) => handleTooltip(e, bar)}
                    onMouseLeave={() => hideTooltip()}
                    onClick={(e) => {
                      console.log('click on bar', { bar, e, data })
                    }}
                  />
                )),
              )
            }
          </BarStack>
        </Group>
        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: margin.top }}
              to={{ x: tooltipLeft, y: innerHeight + margin.top }}
              stroke={accentColorDark}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              r={4}
              fill="black"
              fillOpacity={0.1}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              pointerEvents="none"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill={accentColorDark}
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {!hide_scale ?
        <OrdersGraphAxis width={width} height={height} dateScale={dateScale} moneyScale={moneyScale}/> : null}
      {!hide_tooltips && tooltipData && (
        <div>
          <TooltipWithBounds
            top={-10}
            left={-10}
            style={tooltipStyles}
          >
            <Item><h3>{moment(tooltipData.date).format('dddd DD/MM/YYYY')}</h3>
              <OrdersSummary orders={tooltipData.orders}
                             ads={ads}
                             from={moment(tooltipData.date)}
                             to={moment(tooltipData.date).endOf('day')}/>
            </Item>
          </TooltipWithBounds>
          <Tooltip
            top={innerHeight + margin.top - 14}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 72,
              textAlign: 'center',
              transform: 'translateX(-50%)',
            }}
          >
            {getDate(tooltipData)}
          </Tooltip>
        </div>
      )}

      {show_legend ? <div className={'mt-2'}>
        <InlineGraphLegend/>
      </div> : null}
    </div>
  })


const LegendItem = ({ bg, children }) => {
  return <div className="d-inline-block mr-3">
    <div className="row no-gutters align-items-center">
      <div className="col-auto mr-3">
        <div className={`d-inline-block rounded-sm ${bg}`} style={{ height: 20, width: 20 }}>
        </div>
      </div>
      <div className="col text-uppercase font-weight-bold" style={{ fontSize: 18, lineHeight:1 }}>
        {children}
      </div>
    </div>
  </div>
}
const InlineGraphLegend = () => {
  const translate = useTranslate()

  return <div className={'text-center mb-3'}>
    <LegendItem bg={'bg-gradient_bg_amazon-flat'}>
      {translate({
        fr: `AMAZON`,
        en: `AMAZON`,
      })}
    </LegendItem>
    <LegendItem bg={'bg-gradient_bg_profit-flat'}>
      {translate({
        fr: `PRODUITS`,
        en: `PRODUCTS`,
      })}
    </LegendItem>
    <LegendItem bg={'bg-gradient_bg_real_profit-flat'}>
      {translate({
        fr: `Profits`,
        en: `Profits`,
      })}
    </LegendItem>
    <LegendItem bg={'bg-gradient_bg_loss-flat'}>
      {translate({
        fr: `Pertes`,
        en: `Loss`,
      })}
    </LegendItem>
  </div>
}
