import React from 'react'
import Item, { CompactItem, NiceConclusion } from '../../components/generic/item'
import { format_big_number, format_money, format_percentage, format_price, sum } from './format_numbers'
import Flag from '../../components/flag/flag'
import ExternalLink from '../../components/generic/external-link'
import {
  amazon_public_url_for_asin,
  amazon_seller_central_for_order,
} from '../product_v2/product_preview/product_preview'
import moment from 'moment'
import Button from '../../components/generic/button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { status_translations } from './index'
import { ProductFirstVisualByASIN, ProductStatsForOrders, ProductTitleByASIN } from './products_from_orders'
import useMarketPlace, { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { Checkbox } from '../../components/generic/checkbox'
import { ProductPresentation, ProductPresentationComponent } from '../product_v2/product_header'
import { useProduct } from '../../context/products'
import { is_ready } from '../../hooks/useCachedContext'
import { DefaultLoading } from '../../components/i18n/translations'
import {
  FaAmazon,
  FiArrowDown,
  FiBox,
  FiDollarSign,
  FiEdit,
  FiMinus,
  FiPieChart, GiMoneyStack,
  GiReceiveMoney,
} from 'react-icons/all'
import { NumbersSummary, useProfitOrLoss } from '../../components/pricing/margin_details'
import { GiPayMoney } from 'react-icons/gi'
import InternalLink from '../../components/generic/internal-link'
import { get_edit_product_route } from '../routes'
import { useUserCurrency } from '../subscription/upgrade'

const get_class_name = (status) => {
  let tr_class_name = ''
  if (status === 'Canceled') {
    tr_class_name = 'dark'
  }
  if (status === 'Returned') {
    tr_class_name = 'danger'
  }
  if (status === 'PartiallyReturned') {
    tr_class_name = 'danger'
  }
  if (status === 'Shipped') {
    tr_class_name = 'success'
  }
  if (status === 'Pending') {
    tr_class_name = 'info'
  }
  if (status === 'Unshipped') {
    tr_class_name = 'warning'
  }
  return tr_class_name
}


export default function ProductsTable({ products, sales_stats, toggle_asins, is_asin_selected }) {
  const translate = useTranslate()
  const [limit, set_limit] = React.useState(10)


  const return_average = sales_stats.units_returned / sales_stats.units_sold


  return (
    <div className="row">
      <div className="col-12">
        <div className="row">

          {products
            .filter((o, i) => i < limit).map((product) => {


              return <ProductSalesSummary key={product.product_id} is_asin_selected={is_asin_selected}
                                          sales_stats={sales_stats}
                                          toggle_asins={toggle_asins} return_average={return_average}
                                          product={product}/>
            })}
        </div>
      </div>


      {limit < products.length ? <div className="col-12 text-center">
        <div className="font-italic">{products.length - limit} {translate({
          fr: `produit(s)`,
          en: `product(s)`,
        })}</div>
        <div className="btn-group">
          <Button onClick={() => set_limit(limit + 20)}>{translate({
            fr: `Voir plus de produits`,
            en: `Show more products`,
          })}</Button>
        </div>
      </div> : null}
    </div>
  )
}

const ProductSalesSummary = ({
                               product,
                               is_asin_selected,
                               toggle_asins,
                               return_average,
                               sales_stats,
                             }) => {
  const french = useFrench()
  const lang = useLang()
  const currency = useUserCurrency().code
  const source_market_place = useSourceMarketPlace()

  const { product_id, asin, sku, title, rank, stats } = product
  const {
    units_returned,
    units_sold,
    total_sales,
    amazon_commissions,
    amazon_fba,
    amazon_other,
    amazon_ads,
    total_product_costs,
    total_urssaf,
    profit,
  } = stats

  const {
    summary_bg,
    summary_emoji,
    summary_label,
  } = useProfitOrLoss(profit)

  if (product.loading) {
    return <DefaultLoading/>
  }

  const return_percentage = units_returned / units_sold
  const return_label = ( 100 * return_percentage ).toFixed(1)

  let badge = 'success'
  if (return_percentage > return_average) {
    if (return_percentage > ( return_average * 1.5 )) {
      badge = 'danger'
    } else {
      badge = 'warning'
    }
  }

  const values = [
    {

    }
  ]

  return <div className="col-12 col-lg-6">
    <Item className={'mb-2'}>
      <ProductPresentationComponent product={product} variations={product.variations} hide_edit={true}
                                    checkbox={<div className="row">
                                      <Checkbox value={is_asin_selected(asin)} setter={() => toggle_asins([asin])}/>
                                    </div>}
                                    action={<>
                                      <h3>
                                        <span className="badge badge-secondary">#{rank}</span>
                                      </h3>
                                    </>}
      />
      <div className="row">

        <div className="col-12">
          <Money label={<>{units_sold} {french(`vente(s)`)}
            <div className="small">
              <span className={`badge badge-${badge}`}>{units_returned} {french(`renvoyée(s)`)}
                <FiMinus/> {return_label}%</span></div>
          </>}
                 value={total_sales} percentage={1} currency={currency} bold={true} small={false}
                 bg={'gradient_bg-flat'} icon={<GiMoneyStack/>}/>
        </div>
        <div className="col-12">
          <hr className="my-1"/>
        </div>
        <div className="col-12">
          <Money label={french(`Amazon : commissions`)} value={amazon_commissions} percentage={-amazon_commissions/total_sales} bg={'gradient_bg_amazon-flat'}
                 currency={currency} icon={<FaAmazon/>}/>
        </div>
        <div className="col-12">
          <Money label={french(`Amazon : expédition`)} value={amazon_fba} percentage={-amazon_fba/total_sales} bg={'gradient_bg_amazon-flat'}
                 currency={currency} icon={<FaAmazon/>}/>
        </div>
        <div className="col-12">
          <Money label={french(`Amazon : publicités`)} value={amazon_ads} percentage={-amazon_ads/total_sales} bg={'gradient_bg_amazon-flat'}
                 currency={currency}
                 icon={<GiPayMoney/>}/>
        </div>
        <div className="col-12">
          <Money label={french(`Amazon : autre`)} value={amazon_other} percentage={-amazon_other/total_sales} bg={'gradient_bg_amazon-flat'}
                 currency={currency}
                 icon={<GiPayMoney/>}/>
        </div>
        <div className="col-12">
          <Money label={<>{french(`Coûts du produit`)} <InternalLink
            to={get_edit_product_route(product_id, source_market_place, 'price')}><span
            className="ml-2 badge badge-light"><FiEdit/> {french(`Modifier`)}</span></InternalLink></>}
                 value={total_product_costs} percentage={-total_product_costs/total_sales} currency={currency} bg={'gradient_bg_profit-flat'}
                 icon={<FiBox/>}/>
        </div>
        {total_urssaf ? <div className="col-12">
          <Money label={<>{french(`Urssaf`)} <InternalLink to={'/settings'}><span
            className="ml-2 badge badge-light"><FiEdit/> {french(`Modifier`)}</span></InternalLink></>}
                 value={total_urssaf} percentage={-total_urssaf/total_sales}
                 currency={currency} bg={'gradient_bg_profit-flat'}
                 icon={<FiPieChart/>}/>
        </div> : null}
        <div className="col-12">
          <hr className="my-1"/>
        </div>
        <div className="col-12">
          <Money bg={summary_bg} currency={currency} icon={summary_emoji}
                 label={summary_label} value={profit} bold={true} small={false}
                 percentage={profit / total_sales}
          />
        </div>

      </div>
    </Item>
  </div>
}


const Money = ({ bg, icon, label, value, currency, bold, small = true, percentage }) => {
  const lang = useLang()

  return <>
    <div className={`row ${small ? 'small' : ''} mb-1`}>
      <div className="col-12">
        <NiceConclusion bg={bg} icon={icon} conclusion={<>
          <div className="row align-items-center">
            <div className="col">
              {label}
            </div>
            <div className={`col-auto ${bold ? 'font-weight-bold' : 'font-weight-normal'} text-right`}>
              {format_money(lang, value, currency, 2)}{percentage ? <span className="small ml-2">({format_percentage(percentage,1)})</span> : null}

            </div>
          </div>
        </>}/>
      </div>
    </div>
  </>
}
