import React from 'react'
import IntellifoxIcon from '../../images/intellifox-icon'
import { useMutation } from '@apollo/client'
import { SELECT_KEYWORD } from '../../graphql/queries/keyword'
import useMarketPlace from '../../hooks/useMarketPlace'
import useCompanyId from '../../hooks/useCompanyId'
import { MdDeleteForever } from 'react-icons/md'
import Button from '../../components/generic/button'
import HelpButton from '../../components/generic/help_button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { keyword_category_to_button } from '../../components/generic/keyword-chooser'
import { clean_for_keyword_analysis, compute_variables, escapeRegExp } from '../product/text-substitution'
import useHashParam from 'use-hash-param'
import { useOpenedSection } from './product_footer'
import { useAddWordsToTranslate, useKeywordTranslate } from '../../context/translations'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import TextButton from '../../components/generic/text_button'
import Item from '../../components/generic/item'
import ProgressBar from '../../components/generic/progress_bar'
import FlatChoice from '../../components/generic/flat_choice'
import { badge, Count } from '../../components/generic/text-area'
import { ProgressBadge } from '../product/progress'
import { scroll_to_node } from './scroll'
import SearchTermInit from './search_term_init'
import { FiZap } from 'react-icons/all'

export const compute_unused_keywords = (unused_keywords, field_limit = 250) => {
  return unused_keywords.reduce(({ length, in_back_office, unused }, keyword) => {

    if (length >= field_limit || ( length + keyword.keyword.length > field_limit )) {
      unused.push(keyword)
    } else {
      in_back_office.push(keyword)
      length += keyword.keyword.length + 1
    }
    return { length, in_back_office, unused }
  }, { length: 0, in_back_office: [], unused: [] })

}

const DeleteKeywordFromProduct = ({ product, intensity, keyword, category }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const french = useFrench()

  const [selectKeyword, selectKeywordMutation] = useMutation(SELECT_KEYWORD)

  const delete_keyword = () => {
    selectKeyword({
      variables: {
        company_id, product_id: product.product_id,
        market_place,
        keyword, intensity,
        selected: false,
        category,
      },
      update: async () => {
        await product.refresh()
      },
    }).catch(e => console.log(e))
  }
  return <><Button onClick={() => delete_keyword()} button={'danger'}
                   className="btn-sm ml-3"><MdDeleteForever/> {french(`Enlever ce mot clé`)}</Button></>
}

const DisplayKeywords = ({ product, keyword, intensity, is_used, category }) => {

  const [show_delete_button, set_show_delete_button] = React.useState(false)
  return <>
    <span key={keyword}
          className={`keyword badge clickable  badge-${keyword_category_to_button(category)} mr-1 ${is_used ? 'used' : 'unused'}`}
          onClick={() => set_show_delete_button(!show_delete_button)}>{keyword}
      {show_delete_button ? <DeleteKeywordFromProduct product={product} keyword={keyword}
                                                      intensity={intensity}
                                                      category={category}/> : null}</span><span> </span>
  </>
}

export default function SEOSummary({ product }) {
  const snippets = useCachedContext('snippets')

  const safe_selected_keywords = product.product_info.selected_keywords || []

  if (snippets.loading) {
    return <DefaultLoading/>
  }
  if (safe_selected_keywords.length === 0) {
    return <SelectSomeKeywordsWarning product={product}/>
  }
  return (
    <>
      <KeywordsSummary product={product} snippets={snippets}/>
    </>
  )
}


export const useKeywordsStat = (product, snippets, attribute, value) => {

  const french = useFrench()
  const translate = useTranslate()

  if (snippets.loading) {
    return {}
  }


  const used_keywords_for_title = filter_keywords({
    snippets,
    product,
    is_used: true,
    title: attribute === 'title' ? value : product.product_info.title,
  })
  const used_keywords_for_key_points = filter_keywords({
    snippets,
    product,
    is_used: true,
    key_points_0: attribute === 'key_points_0' ? value : product.product_info.key_points[ 0 ],
    key_points_1: attribute === 'key_points_1' ? value : product.product_info.key_points[ 1 ],
    key_points_2: attribute === 'key_points_2' ? value : product.product_info.key_points[ 2 ],
    key_points_3: attribute === 'key_points_3' ? value : product.product_info.key_points[ 3 ],
    key_points_4: attribute === 'key_points_4' ? value : product.product_info.key_points[ 4 ],
  })

  const used_keywords_for_description = filter_keywords({
    snippets,
    product,
    is_used: true,
    description: attribute === 'description' ? value : product.product_info.description,
  })

  const unused_keywords = compute_unused_keywords(filter_keywords({
    snippets,
    product,
    is_used: false,
    title: product.product_info.title,
    description: product.product_info.description,
    key_points_0: product.product_info.key_points[ 0 ],
    key_points_1: product.product_info.key_points[ 1 ],
    key_points_2: product.product_info.key_points[ 2 ],
    key_points_3: product.product_info.key_points[ 3 ],
    key_points_4: product.product_info.key_points[ 4 ],
    [ attribute ]: value,
  }))

  const used_keywords_in_back_office = unused_keywords.in_back_office
  const really_unused_keywords = unused_keywords.unused

  const used_keywords_total = filter_keywords({
    snippets,
    product,
    is_used: true,
    title: product.product_info.title,
    description: product.product_info.description,
    key_points_0: product.product_info.key_points[ 0 ],
    key_points_1: product.product_info.key_points[ 1 ],
    key_points_2: product.product_info.key_points[ 2 ],
    key_points_3: product.product_info.key_points[ 3 ],
    key_points_4: product.product_info.key_points[ 4 ],
    [ attribute ]: value,
  })


  const stats = {
    title: {
      key: 'title',
      count: used_keywords_for_title.length,
      keywords: used_keywords_for_title,
      label: french(`dans le titre`),
      goal: 10,
    },
    key_points: {
      key: 'key_points',
      count: used_keywords_for_key_points.length,
      keywords: used_keywords_for_key_points,
      label: translate({
        fr: `dans les points forts`,
        en: `in the bullet points`,
      }),
      goal: 40,
    },
    description: {
      key: 'description',
      count: used_keywords_for_description.length,
      keywords: used_keywords_for_description,
      label: french(`dans la description`),
      goal: 40,
    },
    back_office: {
      key: 'back_office',
      count: used_keywords_in_back_office.length,
      keywords: used_keywords_in_back_office,
      label: french(`ajouté(s) automatiquement`),
      max_characters: 250,
    },
    unused: {
      key: 'unused',
      count: really_unused_keywords.length,
      keywords: really_unused_keywords,
      label: french(`non utilisé(s)`),
      is_unused: true,
    },
    total: {
      key: 'total',
      count: used_keywords_total.length
        + used_keywords_in_back_office.length,
      keywords: [],
      label: french(`total`),
    },
  }

  return stats
}


export const KeywordsSummary = ({
                                  snippets,
                                  product,
                                }) => {

  const french = useFrench()
  const translate = useTranslate()

  const stats = useKeywordsStat(product, snippets)

  const { total, title, key_points, description, back_office, unused } = stats

  const [keywords_to_show, set_keywords_to_show] = React.useState('')
  return <div className="row align-items-center">
    <div className="col">
      <Item>
        <div className="row">
          {( [title, key_points, description, back_office, unused] ).map(({
                                                                            key,
                                                                            count,
                                                                            label,
                                                                          }) => {
            return <div className="col-auto" key={key}>
              <FlatChoice on_click={() => set_keywords_to_show(key === keywords_to_show ? '' : key)}
                          is_current={key === keywords_to_show}>
                <span className="font-weight-bold">{count}</span> <span className="small">{label}</span>
              </FlatChoice>
            </div>
          })}

          {keywords_to_show ? <div className="col-12 mt-2">
            {stats[ keywords_to_show ].keywords.map(({ keyword, intensity, category }) => {
              return <DisplayKeywords key={keyword} keyword={keyword} intensity={intensity} category={category}
                                      product={product} is_used={stats[ keywords_to_show ].key !== 'unused'}/>
            })}
          </div> : null}


        </div>
      </Item>
    </div>
    <div className="col-auto">
      <div>
          <span className="mr-2 badge badge-success">{translate({
            fr: `Objectif :`,
            en: `Goal:`,
          })} 100</span>
      </div>
      <div>
            <span
              className={`badge badge-${badge(total.count, 100, 999)}`}>{total.count}/100 {french(`mots-clés`)}</span>
      </div>

    </div>
  </div>
}


export const filter_keywords = ({
                                  snippets,
                                  product,
                                  is_used, sort,
                                  title = '',
                                  key_points_0 = '',
                                  key_points_1 = '',
                                  key_points_2 = '',
                                  key_points_3 = '',
                                  key_points_4 = '',
                                  description = '',
                                }) => {
  const selected_keywords = product.product_info.selected_keywords || []

  const variables = compute_variables(product, snippets)

  title = clean_for_keyword_analysis({
    text: title,
    limit: 200,
    variables,
    is_html_authorized: false,
  })
  description = clean_for_keyword_analysis({
    text: description,
    limit: 2000,
    variables,
    is_html_authorized: true,
  })

  const key_points = ( [
    key_points_0,
    key_points_1,
    key_points_2,
    key_points_3,
    key_points_4,
  ] ).map((key_point) => {
    return clean_for_keyword_analysis({
      text: key_point,
      limit: 500,
      variables,
      is_html_authorized: false,
    })
  })

  return selected_keywords.filter(({ selected, keyword }) => selected && is_keyword_used_in(keyword, {
    title,
    description,
    key_points,
  }) === is_used)
    .sort((k1, k2) => sort ? ( k1.keyword < k2.keyword ? -1 : 1 ) : 0)
}


export const is_keyword_used_in_text = ({ keyword, text }) => {
  const tester = new RegExp(`\\b${escapeRegExp(keyword)}\\b`, 'i')
  return tester.test(( text || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
}

export const is_keyword_used_in = (keyword, { title, description, key_points }) => {
  const tester = new RegExp(`\\b${escapeRegExp(keyword)}\\b`, 'i')
  return tester.test(( title || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    || tester.test(( description || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    || ( key_points || [] ).reduce(
      (is_keyword_used, key_point) => is_keyword_used
        || tester.test(key_point.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        ),
      false)
}
export const SelectSomeKeywordsWarning = ({ product, hide = false }) => {
  const translate = useTranslate()

  const [show_search_term_init, set_show_search_term_init] = React.useState(false)

  return <>
    {hide ? null : <div className="row">
      <div className="col-12">
        <div className="alert alert-info">
          <div className="row">

            <div className="col-12 small font-weight-bold">
              {translate({
                fr: `Avant de composer le texte de l'annonce, il est conseillé de suivre les étapes de démarrage :`,
                en: `Before working on your texts, you should select relevant keywords by clicking on this button:`,
              })}
            </div>
            <div className="col-12">
              <Button onClick={() => set_show_search_term_init(true)} className="mt-2 btn-sm"><FiZap/> {translate({
                fr: `Démarrer l'optimisation`,
                en: `Select keywords`,
              })}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>}
    {show_search_term_init ? <SearchTermInit product={product} close={() => set_show_search_term_init(false)}
                                             on_finish={() => product.refresh()}/> : null}
  </>
}

const ShowKeyword = ({ keyword, category, on_click, would_automatically_be_added }) => {
  const translate = useKeywordTranslate(keyword)

  return <div className={`col-auto mr-1 ${on_click ? 'clickable' : ''}`} key={keyword}
              onClick={() => on_click ? on_click(`${keyword} `) : null}>
            <span
              className={`keyword badge  badge-${keyword_category_to_button(category)} ${would_automatically_be_added ? 'automatically_added' : 'unused'}`}>{keyword}
              {would_automatically_be_added ? '*' : ''}
              {translate && translate !== keyword ? <>
                <br/><span className="small">{translate}</span></> : null}</span>
  </div>
}


const display_keywords = (keywords, is_used, start, limit, on_click, used_keywords_in_back_office) => {
  const keywords_used_in_back = used_keywords_in_back_office.reduce((keywords_used_in_back, { keyword }) => {
    keywords_used_in_back[ keyword ] = true
    return keywords_used_in_back
  }, {})
  return keywords.filter((_, i) => i >= start && ( !limit || i < limit )).map(({ keyword, category }) => {
    return <ShowKeyword key={keyword} keyword={keyword} category={category} on_click={on_click}
                        would_automatically_be_added={keywords_used_in_back[ keyword ]}/>
  })
}


export const SelectedKeywords = ({
                                   product,
                                   title,
                                   description,
                                   key_points_0 = '',
                                   key_points_1 = '',
                                   key_points_2 = '',
                                   key_points_3 = '',
                                   key_points_4 = '',
                                   attribute, value,
                                   limit = 60, on_click,
                                 }) => {
  const selected_keywords = product.product_info.selected_keywords || []

  const french = useFrench()
  const translate = useTranslate()

  const add_words_to_translate = useAddWordsToTranslate()
  const [current_menu, set_current_menu] = useHashParam('menu', 'preview')

  const snippets = useCachedContext('snippets')

  const [current_limit, set_current_limit] = React.useState(limit)
  const [show_search_term_init, set_show_search_term_init] = React.useState(false)

  const stats = useKeywordsStat(product, snippets, attribute, value)

  React.useEffect(() => {
    add_words_to_translate(selected_keywords
      .filter(({ selected }) => selected)
      .map(({ keyword }) => keyword),
    )
  }, [add_words_to_translate, selected_keywords])

  if (snippets.loading) {
    return <DefaultLoading/>
  }

  if (snippets.error) {
    return <DefaultError/>
  }

  const unused_keywords = filter_keywords({
    snippets,
    product,
    is_used: false,
    title,
    description,
    key_points_0,
    key_points_1,
    key_points_2,
    key_points_3,
    key_points_4,
    [ attribute ]: value,
  })


  const used_keywords_in_back_office = compute_unused_keywords(filter_keywords({
    snippets,
    product,
    is_used: false,
    title: product.product_info.title,
    description: product.product_info.description,
    key_points_0: product.product_info.key_points[ 0 ],
    key_points_1: product.product_info.key_points[ 1 ],
    key_points_2: product.product_info.key_points[ 2 ],
    key_points_3: product.product_info.key_points[ 3 ],
    key_points_4: product.product_info.key_points[ 4 ],
    [ attribute ]: value,
  })).in_back_office

  const stat_key = /key_point/.test(attribute) ? 'key_points' : attribute


  return <div className="row">
    <div className="col-12">
      <SelectSomeKeywordsWarning product={product} hide={selected_keywords.length > 0}/>
      {selected_keywords.length === 0 ? null : <div className="row">
        <div className="col">
          <div className="row mb-1">
            <div className="col-6">
                    <span
                      className={`badge badge-${badge(stats[ stat_key ].count, stats[ stat_key ].goal, 999)} w-100`}>
                      {stats[ stat_key ].count} {stats[ stat_key ].label}
                    </span>
            </div>
            <div className="col-6">
            <span
              className={`badge badge-${badge(stats.total.count, 100, 999)} w-100`}>{stats.total.count}/100 {stats.total.label}</span>
            </div>
          </div>
          <div className="row no-gutters">
            {display_keywords(unused_keywords, false, 0, current_limit, on_click, used_keywords_in_back_office)}
          </div>
          <div className="row align-items-center">
            {current_limit < unused_keywords.length ? <div className="col-auto mr-1">
              <TextButton className="btn-sm mr-1"
                          onClick={() => set_current_limit(999)}>{french(`Voir tous les mots-clés à utiliser`)} ({unused_keywords.length - current_limit})</TextButton>
            </div> : <div className="col-auto mr-1">
              <TextButton className="btn-sm mr-1"
                          onClick={() => set_current_limit(limit)}>{french(`Voir moins`)}</TextButton>
            </div>}
            <div className="col-12">
              <TextButton className="btn-sm"
                          onClick={() => {
                            set_show_search_term_init(true)
                          }}>{french(`Gérer les mots-clés`)}</TextButton>
            </div>
            <div className="col-12 small">
            <span
              className="font-italic small">* {french(`Les mots-clés marqués avec une étoile sont automatiquement ajoutés aux termes de recherche`)}</span>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {show_search_term_init ? <SearchTermInit product={product} close={() => set_show_search_term_init(false)}
                                             on_finish={() => product.refresh()} current_step={4}/> : null}

    {/*<div className="col-auto">*/}
    {/*  <Item no_padding={true} className={"p-1"}>*/}
    {/*    <span className="small font-weight-bold">{french(`Bilan SEO`)}</span>*/}

    {/*    <KeywordsSummary used_keywords_for_current_attribute={used_keywords_for_current_attribute}*/}
    {/*                     used_keywords_in_texts={used_keywords_in_texts}*/}
    {/*                     used_keywords_in_back_office={used_keywords_in_back_office}/>*/}

    {/*  </Item>*/}

    {/*</div>*/}
  </div>
}
