import React from 'react'
import { ColorOptionSelector } from '../options/select_color'
import SingleObjectOptions from '../options/single_object_options'
import { compute_rect_style } from '../controller/rect_controls'
import { useDesignerContext } from '../designer_context'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'

export const compute_background = (compute_color, props) => {
  const { fill, gradient, gradient_color, gradient_angle } = props.props

  let background = compute_color ? compute_color(fill) : fill
  if (gradient) {
    background = `linear-gradient(${props.props.gradient_angle || 0}deg, ${background} 0%, ${compute_color ? compute_color(gradient_color) : gradient_color} 100%)`
  }
  return background
}

export default function Rect({ object_id, onLoad, ...props }) {
  const { compute_color } = useDesignerContext()

  React.useEffect(() => {
    onLoad && onLoad()
  }, [])

  const background = compute_background(compute_color, props)

  return (
    <div className="position-absolute" id={object_id} style={{
      ...compute_rect_style(props, compute_color),
      background,
    }}>
    </div>
  )
}

export const RectOptions = ({ scale, ...object }) => {
  const { set_current_option } = useDesignerContext()
  const translate = useTranslate()

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 justify-content-between">
      <div className="col-auto ">
        <div className="row align-items-center no-gutters">
          <div className="col-auto" style={{ height: 32 }}>
            <ColorOptionSelector color={object.props.fill} option={'props.fill'}/>
          </div>
          <div className="col-auto" style={{ height: 32 }}>
            <ColorOptionSelector color={object.props.gradient_color} option={'props.gradient'}/>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <OptionSelector option={'props.filters'}>{translate({
              fr: `Filtres`,
              en: `Filters`,
            })}</OptionSelector>
          </div>
        </div>
      </div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>


  </div>
}
