import React from 'react'
import { useFetchV2 } from '../../context/fetch_v2'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import Button from '../../components/generic/button'
import Field from '../../components/generic/field'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import useMarketPlace, { useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import { useFrench } from '../../context/lang'
import { FiRefreshCw } from 'react-icons/all'
import IntellifoxModal from '../../components/generic/modal'
import { FiSettings } from 'react-icons/fi'

export const Suggestion = ({ product, attribute, description, mapper, loading_message = '', keywords_override }) => {
  const selected_keywords = keywords_override ? keywords_override : ( product.product_info.selected_keywords || [] )

  const market_place = useMarketPlace()

  const french = useFrench()

  const [refreshing, set_refreshing] = React.useState(false)

  let prompt_type
  if (attribute === 'title') {
    prompt_type = 'title_helper'
  } else if (attribute === 'description') {
    prompt_type = 'description_helper'
  } else if (/key_points/.test(attribute)) {
    prompt_type = 'key_points_helper'
  } else if (attribute === 'search_request') {
    prompt_type = 'search_request'
  } else if (attribute === 'images') {
    prompt_type = 'images'
  }

  const suggestion = useCachedContext('suggestion', prompt_type, description, market_place, selected_keywords.map(({ keyword }) => keyword))

  if (suggestion.loading || refreshing) {
    return <>{loading_message ? <span className={'mr-2 small'}>{loading_message}...</span> : null}<DefaultLoading/></>
  }

  const error_component = <><DefaultError/> <Button className={'btn-sm'} onClick={async () => {
    set_refreshing(true)
    await suggestion.refresh()
    set_refreshing(false)
  }}><FiRefreshCw/> {french(`Réessayer`)}</Button>
    <div className="row">
      <div className="col-12">
        <div className="alert alert-warning small">
          {french(`L'IA de génération de texte est peut-être actuellement surchargée.`)}
        </div>
      </div>
    </div>
  </>

  if (suggestion.error) {
    return error_component
  }

  let objects = []
  try {
    objects = JSON.parse(suggestion.answer)
  } catch (e) {
    return error_component
  }

  return <div className="row">
    <div className="col-12 text-right mb-2">
      <Button className={'btn-sm'} onClick={async () => {
        set_refreshing(true)
        await suggestion.refresh()
        set_refreshing(false)
      }}><FiRefreshCw/> {french(`Générer de nouvelles valeurs`)}</Button>
    </div>
    <div className="col-12">
      {objects.map((line) => mapper(line))}
    </div>
  </div>
}


export const AdvancedSuggestions = ({ className, product }) => {
  const french = useFrench()
  const [selected_choice, set_selected_choice] = React.useState('')
  const choices = [
    {
      label: french(`générer des titres`),
      attribute: 'title',
    },
    {
      label: french(`générer des points forts`),
      attribute: 'key_points',
    },
    {
      label: french(`générer une description détaillée`),
      attribute: 'description',
    },
  ]

  return <IntellifoxModal button_props={{ className }}
                          body={<div className="row">
                            <div className="col-12">
                              {french(`Je veux...`)}
                            </div>
                            <div className="col-12">
                              {
                                choices.map(({ label, attribute }) => {
                                  return <Button onClick={() => set_selected_choice(attribute)}
                                                 outline={selected_choice && selected_choice !== attribute}>{label}</Button>
                                })
                              }
                            </div>
                          </div>}>
    <FiSettings/> {french(`Options avancées`)}
  </IntellifoxModal>
}
