import React from 'react'
import { useTranslate } from '../../context/lang'
import { FaArrowDown, FaArrowUp, FaCheck } from 'react-icons/all'

export default function GlobalProgressBar({ step }) {
  const translate = useTranslate()
  const steps = [
    {
      label: translate({
        fr: 'Connexion',
      }),
      duration: '1 minute'
    },
    {
      label: translate({
        fr: 'Import',
      }),
      duration: '1 minute'
    },
    {
      label: translate({
        fr: 'Optimisation',
      }),
    },
  ]
  return (
    <>
    <div className="row">
      {steps.map(({ label, duration }, i) => {
        return <div className="col-4 text-center " key={i} style={{ opacity: i === step ? 1 : 0.3 }}>
          <span className="step-number small">{i + 1}</span>
          <div className={' font-weight-bold small'} >{label}</div>
          <div className={'smaller font-italic'}>{i < step ? <>
            <FaCheck className={"mr-1"}/>
          {
            translate({
              fr: 'Terminé',
              en: 'Done'
            })
          }
          </>:duration}</div>
        </div>
      })}

    </div>
      <hr/>
    </>
  )
}
