import React from 'react'
import conrad from '../../images/conrad.jpg'

export default function Conrad({size=64}) {
  return (
    <span><img src={conrad} alt="" style={{
      borderRadius: '100%',
      width: size,
      height: size,
    }}/></span>
  )
}
