import React, { useState, useEffect, useCallback } from 'react'

const getInitialState = (key, initial_value) => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : initial_value
  } catch (error) {
    return initial_value
  }
}

export default function useLocalStorage(key, initial_value) {

  const [storedValue, setStoredValue] = useState(getInitialState(key, initial_value))

  useEffect(() => {
    setStoredValue(getInitialState(key, initial_value))
  }, [key])

  const setValue = useCallback(value => {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    try {
      // From Conrad : the order is important, if the stored value is used directly from the localStorage elswhere ; keeps the data in sync to do this before
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
      setStoredValue(valueToStore)
    } catch (error) {
      setStoredValue(valueToStore)
      console.log('useLocalStorage error', error)
    }
  }, [key])
  return [storedValue, setValue]
}
