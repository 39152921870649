import React from 'react'

import es_flag from '../../images/countries/spain.svg'
import fr_flag from '../../images/countries/france.svg'
import de_flag from '../../images/countries/germany.svg'
import uk_flag from '../../images/countries/united-kingdom.svg'
import it_flag from '../../images/countries/italy.svg'
import nl_flag from '../../images/countries/netherlands.svg'
import se_flag from '../../images/countries/sweden.svg'
import us_flag from '../../images/countries/united-states-of-america.svg'
import ca_flag from '../../images/countries/canada.svg'
import pl_flag from '../../images/countries/poland.svg'
import eg_flag from '../../images/countries/egypt.svg'
import tr_flag from '../../images/countries/turkey.svg'
import sa_flag from '../../images/countries/saudi-arabia.svg'
import ae_flag from '../../images/countries/united-arab-emirates.svg'
import in_flag from '../../images/countries/india.svg'
import mx_flag from '../../images/countries/mexico.svg'
import br_flag from '../../images/countries/brazil.svg'
import sg_flag from '../../images/countries/singapore.svg'
import au_flag from '../../images/countries/australia.svg'
import jp_flag from '../../images/countries/japan.svg'
import cn_flag from '../../images/countries/china.svg'
import bg_flag from '../../images/countries/belgium.svg'

import eu_flag from '../../images/countries/european-union.svg'
import { amazon_regions } from '../../hooks/useMarketPlace'

const flags = {
  es: es_flag,
  fr: fr_flag,
  de: de_flag,
  uk: uk_flag,
  it: it_flag,
  nl: nl_flag,
  se: se_flag,
  us: us_flag,
  ca: ca_flag,
  pl: pl_flag,
  eg: eg_flag,
  tr: tr_flag,
  sa: sa_flag,
  ae: ae_flag,
  in: in_flag,
  mx: mx_flag,
  br: br_flag,
  sg: sg_flag,
  au: au_flag,
  jp: jp_flag,
  cn: cn_flag,
  eu: eu_flag,
  be: bg_flag,
}


const flag = (market_place, size = 24) => {
  let flag = flags[ market_place ]

  if (flag) {
    return <img src={flag} alt={market_place} style={{ width: size, height: size }}/>
  }

  return market_place
}

export default function Flag({ market_place, size }) {
  return (
    <>
      <span className={'p-1'}>{flag(market_place, size)}</span>
    </>
  )
}

export const FlagsForRegion = ({ region_id }) => {
  const market_places_for_region = amazon_regions.find((region) => region.region_id === region_id).market_places.map((market_place) => market_place.market_place)

  return <>
    {market_places_for_region.map((market_place) => <Flag key={market_place} market_place={market_place} size={24}/>)}
  </>
}
