import React from 'react'
import { useDesignerContext } from '../designer_context'
import Browse_Content, {
  Browse_Content_Picker, Browse_Content_Picker_Item,
  Browse_Content_Section,
  Browse_Content_SubTitle,
  Browse_Content_Title,
} from './browse__content'
import { DomRenderer } from '../renderer/dom_renderer'
import PixabayResults from './pictures/pixabay_results'
import { useTranslate } from '../../../context/lang'

export default function BrowseBackgrounds() {
  const { update_or_create_background } = useDesignerContext()
  const translate = useTranslate()

  const base_background = {
    object_id: 'background',
    x: 0,
    y: 0,
    width: 500,
    height: 500,
    angle: 0,
  }


  const fixed_colors = [
    'white',
    'black',
    'grey',
    'blue',
    'red',
    'yellow',
    'green',
    'teal',
    'purple',
  ]


  const dimension = 95
  return (
    <Browse_Content>
      <Browse_Content_Title>{translate({
          fr: `Arrière-plan`,
          en: `Backgrounds`
      })}</Browse_Content_Title>

      <Browse_Content_Section>
        <Browse_Content_Picker>
          {fixed_colors.map((color) => {
            return <Browse_Content_Picker_Item
              onClick={() => update_or_create_background({ type: 'rect', props: { fill: color } })}>
              <DomRenderer width={dimension} height={dimension}
                           objects={[{ ...base_background, type: 'rect', props: { fill: color } }]}/>
            </Browse_Content_Picker_Item>
          })}
        </Browse_Content_Picker>

        <Browse_Content_Picker>
          <PixabayResults query="textures" lang="en" per_page={50} size={dimension} can_load_more={true} on_click={({image_id}) => update_or_create_background({ type: 'image', props: { image_id } })} square={true}/>
        </Browse_Content_Picker>
      </Browse_Content_Section>
    </Browse_Content>
  )
}
