import React from 'react'

export const FullScreenCentered = ({ children }) => {
  return (
    <Centered>{children}</Centered>
  )
}

export const CenteredAbsolute = ({ children }) => {
  return (
    <div className='position-absolute'
         style={{ top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
      {children}
    </div>
  )
}

export default function Centered({ children }) {
  return (
    <div className='position-fixed'
         style={{
           top: '50%',
           left: '50%',
           transform: 'translateX(-50%) translateY(-50%)',
           zIndex: 25000,
           maxHeight: '100vh',
           padding: "20px",
           overflowY: 'auto',
         }}>
      {children}
    </div>
  )
}
