import { useFrench, useTranslate } from '../../context/lang'
import React from 'react'
import { FiLoader, FiRefreshCw } from 'react-icons/all'
import NavigationButton from '../generic/navigation-button'
import CrispButton from '../generic/crisp_button'
import IntellifoxIcon from '../../images/intellifox-icon'

export const default_loading_translations = {
  fr: 'Chargement en cours...',
  en: 'Loading...',
}

export const default_error_translations = {
  fr: 'Erreur lors du chargement',
  en: 'Error while loading data',
}

export const DefaultLoading = ({className}) => {
  const translate = useTranslate()
  return <span className={`mr-1 mb-1 ${className}`}><FiLoader className={"spin"}/></span>
}
export const DefaultError = ({className}) => {
  const translate = useTranslate()
  return <div className={className}>{translate(default_error_translations)}</div>
}

export const DefaultErrorResolution = ({route='/', support_message}) => {
  const french = useFrench()

  return <div className={'row'}>
    <div className="col-12">
      {french(`Toutes nos excuses, une erreur inattendue s'est produite.`)}
    </div>
    <div className="col-12">
      <ol>
        <li><NavigationButton to={route}>
          <FiRefreshCw/> {french(`Essayer à nouveau`)}</NavigationButton>
        </li>
        <li>
          <CrispButton
            text={support_message}
            outline={true}><IntellifoxIcon/> {french(`Contacter le support d'Intellifox`)}</CrispButton>
        </li>
      </ol>
    </div>
  </div>
}
