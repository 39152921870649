import { gql } from '@apollo/client'
import { create_mutation } from './_query_and_mutations_templates'


export const ADD_USER = gql`mutation AddUser($email: String!, $lang: String!, $first_name: String!, $last_name: String!, $password_hash: String!, $accept_intellifox_news: Boolean!, $referral_id:String, $utm:String, $user_referral_id: String) {
    addUser(
        email:$email, lang:$lang, first_name:$first_name, last_name:$last_name,
        password_hash:$password_hash,  accept_intellifox_news:$accept_intellifox_news,
        referral_id: $referral_id,
        utm: $utm,
        user_referral_id: $user_referral_id,
    ) {
        success
        error
    }
}
`
export const GET_ME_STR = `
    query GetMe($user_id: ID!) {
        me(user_id: $user_id) {
            token
            valid_until
            user {
                user_id
                amazon_user_id
                amazon_email
                first_name
                last_name
                email
                onboarding_done
                mastermind_name
                accept_intellifox_news
                accept_comments_notification
                accept_weekly_summary
                accept_monthly_summary
                lang
            }
            company {
                company_id
                label
                url
                merchant_id
                has_active_subscription
                source_market_place
                activate_comment_automatic_request
                comment_automatic_request_delay
                comment_automatic_request_minutes
                ask_for_check_france_num
                free_trial_end
                subscription_tier
                subscription_frequency
                timezone
                meta_data
                user_referral_id
                referred_users_count
                pause_subscription
                pause_subscription_date
                data_deletion_date
                
                subscription {
                    subscription_id
                    cancel_url
                    update_url
                    next_bill_date
                    status
                    subscription_plan_id
                    email
                    user_id
                    cancellation_effective_date
                    next_payment_amount
                    currency
                    sale_gross
                }
            }
        }
    }
`

export const GET_ME = gql(GET_ME_STR)

export const UPDATE_USER = gql`
    mutation UpdateUser($user_id: ID!, $lang: String, $email: String, $first_name: String, $last_name: String, $accept_intellifox_news: Boolean, $onboarding_done: Boolean,
        $accept_comments_notification: Boolean,
        $accept_weekly_summary: Boolean,
        $accept_monthly_summary: Boolean,

    ) {
        updateUser(
            user_id: $user_id,
            lang: $lang,
            email: $email,
            first_name: $first_name,
            last_name: $last_name,
            accept_intellifox_news: $accept_intellifox_news,
            onboarding_done: $onboarding_done,
            accept_comments_notification: $accept_comments_notification,
            accept_weekly_summary: $accept_weekly_summary,
            accept_monthly_summary: $accept_monthly_summary,
        ) {
            user_id
        }
    }
`

export const UPDATE_PASSWORD = gql`
    mutation UpdatePassword($user_id: ID!, $current_password: String!, $new_password_hash: String!) {
        updatePassword(
            user_id: $user_id,
            current_password: $current_password,
            new_password_hash: $new_password_hash
        )
    }
`

export const UPDATE_MASTERMIND_NAME = gql`
    mutation UpdateMastermindName($user_id: ID!, $mastermind_name: String!) {
        updateMastermindName(
            user_id: $user_id,
            mastermind_name:$mastermind_name
        )
    }
`

export const SET_CANCELLATION_REASON = gql`
    mutation SetCancellationReason($user_id: ID!, $reason: String!) {
        set_cancellation_reason(
            user_id: $user_id,
            reason:$reason
        )
    }
`


export const RESET_PASSWORD = gql`
    mutation ResetPassword($email: String!) {
        resetPassword(
            email: $email
        )
    }
`

export const UPDATE_RESET_PASSWORD = gql`
    mutation UpdateResetPassword($reset_id: ID!, $new_password_hash: String! ) {
        updateResetPassword(
            reset_id: $reset_id,
            new_password_hash: $new_password_hash,
        )
    }
`

export const update_user_lang = create_mutation({
  mutation: 'update_user_lang',
  params: {
    user_id: 'ID!',
    lang: 'String',
  },
})
