import React from 'react'
import Item from '../../components/generic/item'
import { format_money, sum, convert, DisplayMoney } from './format_numbers'
import Flag from '../../components/flag/flag'
import ExternalLink from '../../components/generic/external-link'
import moment from 'moment'
import Button from '../../components/generic/button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { status_translations } from './index'
import { ProductFirstVisualByASIN, ProductTitleByASIN, ProductVariationByASIN } from './products_from_orders'
import { useAmazonUrls, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import { is_ready, useCachedContext, useMultiCachedContext } from '../../hooks/useCachedContext'
import { Loader } from '../../components/generic/loader'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { get_urssaf_rate } from '../../components/pricing/pricing_formulas'
import { useOrdersSummary } from './orders_summary'

const get_class_name = (status) => {
  let tr_class_name = ''
  if (status === 'Canceled') {
    tr_class_name = 'dark'
  }
  if (status === 'Returned') {
    tr_class_name = 'danger'
  }
  if (status === 'PartiallyReturned') {
    tr_class_name = 'danger'
  }
  if (status === 'Shipped') {
    tr_class_name = 'success'
  }
  if (status === 'Pending') {
    tr_class_name = 'info'
  }
  if (status === 'Unshipped') {
    tr_class_name = 'warning'
  }
  return tr_class_name
}

const AmazonAccountBadge = ({ merchant_id }) => {
  const amazon_accounts = useCachedContext('amazon_accounts')

  let label
  if (amazon_accounts.loading || amazon_accounts.error) {
    label = <Loader size={16}/>
  } else {
    const amazon_account = amazon_accounts.find((a) => a.merchant_id === merchant_id)
    if (amazon_account) {
      label = <>{amazon_account.label}<br/><span className="small">({merchant_id})</span></>
    } else {
      label = <>{merchant_id}</>
    }
  }

  return <span className="badge badge-secondary small mt-1 mb-1">{label}</span>

}

export default function OrdersTable({
                                      orders,
                                       override_limit,
                                    }) {
  const translate = useTranslate()
  const french = useFrench()


  const [limit, set_limit] = React.useState(10)


  React.useEffect(() => {
    if (override_limit) {
      set_limit(override_limit)
    }
  }, [override_limit])


  return (
    <div className="row no-gutters">
      <div className="col-12">
        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">{translate({
              fr: `État`,
              en: `State`,
            })}</th>
            <th scope="col">{translate({
              fr: `Date`,
              en: `Date`,
            })}</th>
            <th scope="col">{translate({
              fr: `Contenu`,
              en: `Content`,
            })}</th>
            <th scope="col" className={'small font-weight-bold bg-gradient_bg-flat rounded-left'}>{translate({
              fr: `CA`,
              en: `Revenue`,
            })}</th>
          </tr>
          </thead>
          <tbody>
          {orders
            .filter((o, i) => i < limit).map(({
                                                merchant_id,
                                                order_id,
                                                order_type,
                                                market_place,
                                                status,
                                                creation_date,
                                                comment_request_date,
                                                items,
                                              }) => {
              return <OrderRow key={order_id} market_place={market_place} merchant_id={merchant_id}
                               comment_request_date={comment_request_date} creation_date={creation_date}
                               items={items}
                               order_id={order_id} order_type={order_type} status={status}
              />
            })}
          </tbody>
        </table>
      </div>

      {orders.length === 0 ? <div className="col-12 text-center font-italic">
        {translate({
          fr: `Aucune vente ne correspond à ces filtres`,
          en: `No sales match these filters`,
        })}
      </div> : <div className="col-12 text-right p-2">
        {translate({
          fr: `Total : ${orders.length}`,
          en: `Total: ${orders.length}`,
        })}
      </div>}

      {limit < orders.length ? <div className="col-12 text-center">
        <div className="font-italic">{translate({
          fr: `Il reste ${orders.length - limit} commandes à afficher`,
          en: `There is ${orders.length - limit} more orders to display`,
        })}</div>
        <div className="btn-group">
          <Button onClick={() => set_limit(limit + 100)}>{translate({
            fr: `Voir plus de commandes`,
            en: `Show more orders`,
          })}</Button>
          <Button onClick={() => set_limit(999999)}>{translate({
            fr: `Voir toutes les commandes`,
            en: `Show all orders`,
          })}</Button>
        </div>
      </div> : null}
    </div>
  )
}

const OrderRow = ({
                    status,
                    items,
                    order_id,
                    market_place,
                    order_type,
                    merchant_id,
                    creation_date,
                    comment_request_date,
                  }) => {
  const lang = useLang()
  const french = useFrench()
  const translate = useTranslate()

  const amazon_urls = useAmazonUrls(market_place)
  const {currency} = useMarketPlaceDetails(market_place)

  if (!status_translations[ status ]) {
    return <tr>
      <td>Missing {status} translation</td>
    </tr>
  }

  const tr_class_name = get_class_name(status)

  const earnings = sum({ order: { items }, type: 'earnings' })

  return <tr key={order_id} className={`table-${tr_class_name} border-bottom`}
             onClick={() => console.log('order_details debug', order_id, items)}>
    <td className={"BLUR_FOR_WEBINAR"}>
      <div className="font-weight-bold">{translate(status_translations[ status ])}</div>
      <div className="small"><Flag market_place={market_place}/> <span
        className="badge badge-dark ml-2">{order_type}</span></div>
      <ExternalLink to={amazon_urls[ market_place ].order(order_id)}><span
        className="small BLUR_FOR_WEBINAR">{order_id}</span></ExternalLink>
      <AmazonAccountBadge merchant_id={merchant_id}/>
    </td>
    <td>
        <div className="row small">
          <div className="col-12 font-weight-bold">
            {french(`Date de la commande`)}
          </div>
          <div className="col-12">
            {moment(creation_date).format('LLLL')}<br/>
          </div>
          <div className="col-12 font-weight-bold mt-2">
            {french(`Date de la demande de commentaire`)}
          </div>
          <div className="col-12">
            {moment(comment_request_date).format('LLLL')}<br/>
          </div>
        </div>
    </td>
    <td>{items.map(({ title, sku, asin, quantity }, i) => {
      return <div className="BLUR_FOR_WEBINAR" key={i}><ExternalLink
        to={amazon_urls[ market_place ].public(asin)}>
        <div className="row no-gutters">
          <div className="col-auto mr-1">
            <div className="row no-gutters">
              <div className="col-auto small mr-1">
                {quantity}x
              </div>
              <div className="col">
                <ProductFirstVisualByASIN asin={asin} width={50}/>
              </div>
            </div>
          </div>
          <div className="col small">
            <div className="row">
              <div className="col-12 text-ellipsis" style={{ maxWidth: 350 }}>
                <ProductTitleByASIN asin={asin} market_place={market_place}/>
              </div>
              <div className="col-12">
                <ProductVariationByASIN asin={asin} market_place={market_place}/>
              </div>
              <div className="col-12 small">
                <span className="badge badge-secondary mr-1">{sku}</span>
                <span className="badge badge-secondary">{asin}</span>
              </div>
            </div>
          </div>
        </div>

      </ExternalLink></div>
    })}</td>
    {earnings !== 0 ? <>
      <td className={'text-nowrap small '}>
        <DisplayMoney value={earnings} currency={currency} />
      </td>
    </> : <td className="text-center">
      <span className="small font-italic">-</span>
    </td>}
  </tr>

}
