import React from 'react'
import { useTranslate } from '../../context/lang'
import Button from '../../components/generic/button'

export default function Maintenance() {
  const translate = useTranslate()

  return (
    <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `Ce bouton permet de réinitialiser le cache. Cela permet de résoudre certains problèmes de l'interface. Le cache va être remis à zéro et la page rechargée.`,
                en: `This button allows you to reset the cache. This can solve some issues. After clicking this button, the cache will be reset and the page reloaded.`,
              })}
            </div>
            <div className="col-12">
              <Button onClick={() => {
                window.localStorage.clear()
                window.location.reload()
              }}>{translate({
                fr: `Vider le cache et recharger la page`,
                en: `Reset cache and reload page`,
              })}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
