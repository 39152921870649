import React from 'react'
import Login from '../user/login'

import LeftMenu from '../navigation/left-menu'
import { useTheme } from '../../context/theme'
import Redirect from '../generic/redirect'
import { Router } from '@reach/router'
import { routes } from '../../routes/routes'
import { useActiveFreeTrial, useHasActiveSubscription, useSubscriptionStatus } from '../../hooks/useCompanyId'
import SelectPlan, { ShowOptions } from '../user/select-plan'
import useLocalStorage from '../../hooks/useLocalStorage'
import ResetPassword from '../user/reset_password'
import Settings from '../../routes/setting'
import { DefaultLoading } from '../i18n/translations'
import Button from '../generic/button'
import IntellifoxIcon from '../../images/intellifox-icon'

import stuck_fox from '../../images/stuck_fox_transparent.png'
import { navigate } from '../../absolute_link'
import Item from '../generic/item'
import CrispButton from '../generic/crisp_button'
import { useFrench, useTranslate } from '../../context/lang'

import DocumentTitle from 'react-document-title'
import { LoginWithAmazonConfirmation, LWAReturnLinkExistingAccount, LWAReturnLogin } from '../user/login_with_amazon'
import { useExternalDependencies } from '../../hooks/useExternalDependencies'
import { ActiveFreeTrial } from '../subscription/start_free_trial'

import { lwa_return_urls } from '../../common/global_variables.json'
import { DataDeletionWarning } from '../user/data_deletion_warning'
import useHashParam from 'use-hash-param'

// test url = localhost:3000/settings/spe/Kdf1p0lNo4SDyO7VF1jrHxpayVO02TL9/af1e9746-321f-48cb-af26-22ce54eb7ec9/b77ba9a3-a001-41ee-b363-b068c018e94b#?r=%2Fproduct
//
const SettingsSpe = ({ token, user_id, company_id }) => {

  const [route] = useHashParam('r', '/')
  const [_, set_user] = useLocalStorage('user', '')

  console.log(route)
  React.useEffect(() => {
    set_user({
      token,
      user: {
        user_id,
      },
      company: {
        company_id,
      },
    })
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }, [])

  return <Redirect to={route}/>
}

export class SmallErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError(error) {

    return {
      error,
      url: window.location.toString(),
    }
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return null
    }
    return this.props.children
  }
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError(error) {

    return {
      error,
      url: window.location.toString(),
    }
  }

  componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error && this.state.url === window.location.toString()) {
      let details = ''
      try {

        details = `**URL**:
"${this.state.url}"`
        details += `
        
**Erreur**:
"${this.state.error.toString()}"`
        details += `
        
**Détails techniques**:
${this.state.error.stack.split('\n').filter((k, i) => i < 4).join('\n')}`
      } catch (e) {

      }

      const translate = this.props.translate


      return <div className="container pt-5 mt-5">
        <div className="alert alert-danger">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 pt-4">
                      <h1><IntellifoxIcon/> {translate({
                        fr: `Une erreur s'est produite`,
                      })} 😭</h1>
                    </div>
                    <div className="col-12">
                      {translate({
                        fr: `Toutes nos excuses pour cette erreur...`,
                      })}
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-3">
                  <Item>
                    <div className="col-12 font-weight-bold">
                      {translate({
                        fr: `Solution possible :`,
                      })}
                    </div>
                    <div className="col-12">
                      <Button onClick={() => window.location.reload()}>{translate({
                        fr: `Cliquez ici pour recharger la page`,
                      })}</Button>
                    </div>
                    <div className="col-12 mt-5">
                      {translate({
                        fr: `L'erreur apparaît toujours ? Deuxième solution :`,
                      })}
                    </div>
                    <div className="col-12">
                      <Button className="btn-sm" onClick={() => {
                        window.localStorage.clear()
                        window.location.reload()
                      }
                      }>{translate({
                        fr: `Vider mon cache et me reconnecter à Intellifox`,
                      })}</Button>
                    </div>
                  </Item>
                </div>


                <div className="col-12 mt-5">
                  {translate({
                    fr: `Aucune solution trouvée ?`,
                  })}
                </div>
                <div className="col-12">
                  <CrispButton outline={true} className="btn-sm"
                               text={`Bonjour,\nJe rencontre un bug\n\n${details}\n\nPouvez-vous m'aider à le résoudre ?`}>{translate({
                    fr: `Envoyer
                    un message au support`,
                  })}</CrispButton>
                </div>
                <div className="col-12">
                  <Button outline={true} className="btn-sm" onClick={async () => {
                    await navigate('/')
                    this.setState({ error: false })
                  }}>{translate({
                    fr: `Retourner sur la page d'accueil`,
                  })}</Button>
                </div>

                <div className="col-12  mt-3">
                  {translate({
                    fr: `Détails de l'erreur`,
                  })}

                  <Item>
                    <div className="row small">
                      <div className="col-12">
                        <code>{details.split('\n').map((detail) => <>{detail}<br/></>)}</code>
                      </div>
                    </div>
                  </Item>
                </div>
              </div>
            </div>


            <div className="col-12 col-md-4">
              <img src={stuck_fox} alt="" className="w-100"/>
            </div>

          </div>
        </div>

      </div>
    }
    return this.props.children
  }
}

export const ShowProperStuff = () => {
  const subscription_status = useSubscriptionStatus()

  if (subscription_status === 'paused') {
    return <ShowOptions subscription_status={subscription_status}/>
  }

  return <SelectPlan is_from_canceled_plan={subscription_status === 'canceled' || subscription_status === 'deleted'}/>
}


export default function MainContent({ is_logged_in, disconnect, set_user }) {
  const { theme } = useTheme()
  const french = useFrench()
  const [discreet_mode] = useLocalStorage('intellifox_discreet_mode', false)
  const [overflow_hidden, set_overflow_hidden] = React.useState(false)

  const translate = useTranslate()

  useExternalDependencies()

  return (
    <>
      <div
        className={`container-fluid variable-root global-colors ${theme} min-vh-100 ${discreet_mode ? 'discreet_mode' : ''}`}
        style={{
          overflow: overflow_hidden ? 'hidden' : '',
          maxHeight: overflow_hidden ? '100vh' : '',
        }}>
        <div className="row">
          <Router basepath={'/'} primary={false}>
            <LeftMenu is_logged_in={is_logged_in} disconnect={disconnect} path={'*'}/>
          </Router>
          {
            is_logged_in ?
              <Router basepath={'/'}>
                {routes.map(({ Menu, path }, i) => Menu ? <Menu key={i} path={path} disconnect={disconnect}
                                                                set_overflow_hidden={set_overflow_hidden}/> : null)}
              </Router> : null
          }
          <div className="col" style={{ height: '100vh', overflow: 'hidden' }}>
            <div className="row" style={{ height: '100%' }}>
              <div className="col-12 pt-0 main-content-scrollable"
                   style={{ height: '100%', overflow: 'auto' }}>

                <DataDeletionWarning/>
                {is_logged_in ?
                  <React.Suspense fallback={<DefaultLoading/>}>
                    <ErrorBoundary translate={translate}>
                      <Router basepath={'/'}>
                        {routes.map(({ Component, path, title }, i) => {

                          return <DocumentTitle key={i} path={path} title={`${french(title || '')} - Intellifox`}/>
                        })}
                      </Router>
                      <Router basepath={'/'}>
                        {routes.map(({ Component, path, title }, i) => {
                          return <Component key={i} path={path} disconnect={disconnect}
                                            set_overflow_hidden={set_overflow_hidden}/>
                        })}
                        <LWAReturnLinkExistingAccount path={lwa_return_urls.link_existing_account_route}/>

                        <Redirect path={'/reset_password/:reset_id'} to={'/'}/>

                        <SettingsSpe path={'/settings/spe/:token/:user_id/:company_id'}/>
                      </Router>
                    </ErrorBoundary>
                  </React.Suspense> :
                  <>
                    <div className={'pt-5'}></div>
                    <Router basepath={'/'} primary={false}>
                      <LWAReturnLogin path={lwa_return_urls.login_route} set_user={set_user}
                                      query={'login_with_amazon'}/>
                      <LWAReturnLogin path={lwa_return_urls.create_account_route} set_user={set_user}
                                      query={'create_account_with_amazon'}/>

                      <LoginWithAmazonConfirmation path={'/lwa'} set_user={set_user}/>
                      <SettingsSpe path={'/settings/spe/:token/:user_id/:company_id'}/>
                      <ResetPassword set_user={set_user} path={'/reset_password/:reset_id'}/>
                      <Login set_user={set_user} path={'*'}/>
                    </Router>
                    <div className={'pt-5'}></div>

                  </>
                }
              </div>
            </div>
          </div>
        </div>


      </div>

    </>
  )
}

