import React from 'react'
import BackToHome, { BackTo } from '../../../components/navigation/back_to_home'
import Title from '../../../components/layout/title'
import { section_color, WelcomeIcon } from '../../colors'
import { ManagedCampaignsLimit } from '../../../components/subscription/subscription_options'
import { OnlyPremium } from '../../../components/subscription/start_free_trial'
import BigBox from '../../../components/layout/big_box'
import { BigItemMenu } from '../../welcome'
import {
  FaArrowDown,
  FaMagic,
  FiArrowRight,
  FiCalendar,
  FiCheck,
  FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight,
  FiEdit, FiInfo,
  FiPlus,
  FiRefreshCw,
  FiX,
} from 'react-icons/all'
import { useFrench, useLang, useTranslate } from '../../../context/lang'
import Item, { CompactItem, Conclusion, NiceNumbers } from '../../../components/generic/item'
import Flag from '../../../components/flag/flag'
import Button from '../../../components/generic/button'
import TextButton from '../../../components/generic/text_button'
import moment from 'moment'
import { ProductFirstVisualByASIN } from '../../order/products_from_orders'
import { NumbersSummary } from '../../../components/pricing/margin_details'
import Field from '../../../components/generic/field'
import { is_ready, useCachedContext } from '../../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import IntellifoxModal from '../../../components/generic/modal'
import * as _ from 'lodash'
import { MarketPlaceDetails, useMarketPlaceDetails, useSourceMarketPlace } from '../../../hooks/useMarketPlace'
import InlineToggle, { Toggle } from '../../../components/generic/inline-toggle'
import UpdateButtonV2 from '../../../components/generic/update_button_v2'
import { await_mutate } from '../../../graphql/mutate_promise'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import useCompanyId from '../../../hooks/useCompanyId'
import { AddManagedCampaign } from './add_managed_campaign'
import { RemoveManagedCampaign } from './remove_managed_campaign'
import { Tooltip } from 'react-tippy'
import { format_money } from '../../order/format_numbers'
import { SetManagedCampaignTarget } from './set_managed_campaign_target'
import CampaignStateV2 from './campaign_state_v2'
import ManageCampaignBudget from './manage_campaign_budget'
import { Summary } from './campaign_results'
import { log } from 'three'
import InternalLink from '../../../components/generic/internal-link'
import { OnboardingVideo } from '../../onboarding/onboarding_videos'
import { ShowManagedCampaignLogs } from './managed_campaign_logs'
import useHashParam from 'use-hash-param'
import { AddAmazonAdsAccount } from '../../../components/user/login_with_amazon'

export const Conversion = () => {
  const translate = useTranslate()
  const french = useFrench()
  const [search, set_search] = React.useState('')

  return <div className="container">
    <BackToHome/>
    <Title color={section_color('ppc')} style={{ color: 'white' }} details={<ManagedCampaignsLimit/>}><WelcomeIcon
      section={'ppc'}/> {translate({
      fr: `Optimisation des publicités`,
    })}</Title>

    <OnboardingVideo video_key={'ppc_conversion'}/>

    <OnlyPremium>
      <BigBox title={french(`Optimisation des campagnes`)}
              actions={<AddManagedCampaign/>}>
        <AddAmazonAdsAccountWarning>
          <div className="row">
            <Field type="text" placeholder={french('Recherche rapide : nom de campagne, ASIN ou SKU')} value={search}
                   setter={set_search}/>
            <div className="col-12">
              <ManagedCampaigns search={search}/>
            </div>
          </div>
        </AddAmazonAdsAccountWarning>
      </BigBox>

    </OnlyPremium>
  </div>
}

const ManagedCampaigns = ({ search }) => {
  const managed_campaigns = useCachedContext('managed_campaigns')
  const french = useFrench()

  const [_page, set_page] = useHashParam('page', 0)
  const page = parseInt(_page)
  const [campaigns_by_page, set_campaigns_by_page] = React.useState(10)


  const [campaigns_data, _set_campaigns_data] = React.useState({})

  const set_campaigns_data = (campaign_id, key, value) => {
    _set_campaigns_data((data) => {
      if (!data[ campaign_id ]) {
        data[ campaign_id ] = {}
      }
      data[ campaign_id ][ key ] = value
      return data
    })
  }

  if (managed_campaigns.loading) {
    return <DefaultLoading/>
  }

  if (managed_campaigns.error) {
    return <DefaultError/>
  }

  const filtered_managed_campaigns = !search ? managed_campaigns : managed_campaigns.filter(({ campaign_id }) => {
    const matcher = new RegExp(search.trim(), 'i')

    const campaign_data = campaigns_data[ campaign_id ] || {}
    const { name, products = [] } = campaign_data

    if (matcher.test(name)) {
      return true
    }

    for (let i = 0; i < products.length; i++) {
      const product = products[ i ]
      const { asin, sku } = product
      if (matcher.test(asin) || matcher.test(sku)) {
        return true
      }
    }

    return false
  })

  const max_page = Math.floor(filtered_managed_campaigns.length / campaigns_by_page)

  const has_pagination = max_page >= 1

  const paginator = has_pagination ? <CompactItem className={'font-weight-bold small'}>
    <div className="row align-items-center">
      <div className="col-12 col-lg-4">
        {french(`Campagne(s) de publicité gérée(s) :`)} {filtered_managed_campaigns.length}
      </div>
      <div className="col-12 col-lg-8 text-right">
        <TextButton disabled={page === 0} onClick={() => set_page(0)}><FiChevronsLeft /></TextButton>
        <TextButton disabled={page === 0} onClick={() => set_page(page-1)}><FiChevronLeft /></TextButton>
        {page+1}/{max_page+1}
        <TextButton disabled={page === max_page} onClick={() => set_page(page+1)}><FiChevronRight/></TextButton>
        <TextButton disabled={page === max_page} onClick={() => set_page(max_page)}><FiChevronsRight/></TextButton>
      </div>
    </div>
  </CompactItem> : null

  return <div className="row">
    <div className="col-12">
      {paginator}
    </div>
    <div className="col-12">
      {filtered_managed_campaigns.filter((mc, i) => !has_pagination ||  (i >= ( page * campaigns_by_page ) && i < ( ( page + 1 ) * campaigns_by_page ))).map((managed_campaign) => {
        return <ManagedCampaign key={managed_campaign.campaign_id}
                                managed_campaign={managed_campaign}
                                set_campaigns_data={set_campaigns_data}
        />
      })}
    </div>
    <div className="col-12">
      {paginator}
    </div>
    {filtered_managed_campaigns.length === 0 && managed_campaigns.length > 0 ?
      <div className="col-12 small font-weight-bold mt-2">
        {french(`Aucune campagne ne correspond à la recherche :`)} "{search}"
      </div> : null}
    {managed_campaigns.length === 0 ? <div className="col-12 small font-weight-bold mt-2">
      {french(`Il n'y a encore aucune campagne gérée. Cliquer sur "Gérer une campagne" ci-dessus pour commencer.`)}
    </div> : null}
  </div>
}

const ManagedCampaign = ({ managed_campaign, set_campaigns_data }) => {

  const campaigns = useCachedContext('campaigns', managed_campaign.amazon_ads_account_id, managed_campaign.profile_id)

  if (campaigns.loading) {
    return <DefaultLoading/>
  }

  if (campaigns.error) {
    return <DefaultError/>
  }

  const campaign = campaigns.find((c) => c.campaign_id === managed_campaign.campaign_id)


  return <ManagedCampaignDetails campaign={campaign}
                                 managed_campaign={managed_campaign}
                                 set_campaigns_data={set_campaigns_data}
  />

}

const ManagedCampaignDetails = ({ campaign, managed_campaign, set_campaigns_data }) => {
  const french = useFrench()
  const lang = useLang()
  const { amazon_ads_account_id, profile_id, region_id, campaign_id, market_place } = managed_campaign

  const { currency } = useMarketPlaceDetails(market_place)

  const ad_groups = useCachedContext('ad_groups', amazon_ads_account_id, profile_id, region_id, campaign_id)

  React.useEffect(() => {
    if (campaign) {
      set_campaigns_data(campaign.campaign_id, 'name', campaign.name)
    }

    if (is_ready(ad_groups) && campaign) {
      set_campaigns_data(campaign.campaign_id, 'products', _.flatten(ad_groups.map(({ product_ads }) => product_ads)))

    }
  }, [campaign, ad_groups])


  return <CompactItem className={'mb-4'} label={<div className="row align-items-center" onClick={() => console.log({
    managed_campaign,
    campaign,
    ad_groups,
  })}>
    <div className="col-auto">
      <Flag market_place={market_place}/>
    </div>
    <div className="col">
      {campaign ?
        <div className="row">
          <div className="col-12 BLUR_FOR_WEBINAR" dangerouslySetInnerHTML={{ __html: campaign.name }}>

          </div>
          <div className="col-12 small">
            {french(`Date de création :`)} {moment(campaign.start_date, 'YYYYMMDD').format('L')}
          </div>
        </div> :
        <>{french(`Campagne non trouvée :`)} {managed_campaign.campaign_id}</>
      }
    </div>
    <div className="col-auto">
      <CampaignProducts ad_groups={ad_groups} managed_campaign={managed_campaign}/>
    </div>
  </div>}
                      action={<RemoveManagedCampaign amazon_ads_account_id={managed_campaign.amazon_ads_account_id}
                                                     campaign_id={managed_campaign.campaign_id}/>}
  >
    {campaign ?
      <div className="row align-items-center">
        <div className="col-12 col-lg-3">
          <CompactItem label={french(`État et budget`)}>
            <CampaignStateV2 managed_campaign={managed_campaign} campaign={campaign}/>
            <hr className="my-2"/>
            <ManageCampaignBudget campaign={campaign} managed_campaign={managed_campaign} market_place={market_place}
            />
          </CompactItem>
        </div>
        <div className="col-12 col-lg-3">
          <CompactItem label={<><FaMagic/> {french(`Objectif par conversion`)}</>}
                       is_selected={managed_campaign.target && managed_campaign.target > 0}>
            <div className="row">
              {!managed_campaign.target ? <div className="col-12">
                <div className="alert alert-info small font-weight-bold">
                  <FaArrowDown/> {french(`Objectif à définir`)}
                </div>
              </div> : null}
              <div className="col-12 font-weight-bold text-center h1 ">
                {format_money(lang, managed_campaign.target, currency, 2)}
              </div>
              <div className="col-12 text-center">
                <SetManagedCampaignTarget managed_campaign={managed_campaign} ad_groups={ad_groups}/>
              </div>
              <div className="col-12">
                <ShowManagedCampaignLogs managed_campaign={managed_campaign}/>
              </div>
            </div>
          </CompactItem>
        </div>
        <div className="col-12 col-lg-6">
          <CompactItem label={french(`Résultats`)}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <CampaignStatistics managed_campaign={managed_campaign}
                                    label={french(`Mois précédent`)}
                                    from={moment().add(-60, 'days').startOf('day')}
                                    to={moment().add(-31, 'days').endOf('day')}
                />
              </div>
              <div className="col-12 col-lg-6">
                <CampaignStatistics managed_campaign={managed_campaign}
                                    label={french(`30 derniers jours`)}
                                    from={moment().add(-30, 'days').startOf('day')}
                                    to={moment().add(0, 'days').endOf('day')}
                />
              </div>
            </div>
          </CompactItem>
        </div>
        {/*{managed_campaign.last_applied_strategy_date ? <div className="col-12 smaller text-center">*/}
        {/*  Dernière mise à jour automatique : {moment(managed_campaign.last_applied_strategy_date).format('L')}*/}
        {/*</div> : null}*/}
      </div>
      : <div className="alert alert-warning">
        <div className="row">
          <div className="col-12">

            {french(`Impossible de retrouver cette campagne.`)}
          </div>
          <div className="col-12 small">
            {french(`Cliquez sur "arrêter de gérer cette campagne" et ajouter la à nouveau pour résoudre le problème.`)}
          </div>
        </div>
      </div>}
  </CompactItem>
}

const CampaignProducts = ({ ad_groups, managed_campaign }) => {
  const { market_place } = managed_campaign
  if (ad_groups.loading) {
    return <DefaultLoading/>
  }

  if (ad_groups.error) {
    return null
  }

  if (ad_groups.length === 0) {
    return null
  }

  const products = _.uniqBy(_.flatten(ad_groups.map(({ product_ads }) => product_ads)).filter((p) => p.state === 'enabled'), 'asin')

  if (products.length === 0) {
    return null
  }

  const first_product = products[ 0 ]

  return <>
    <Tooltip html={<div className={'BLUR_FOR_WEBINAR'}>{first_product.asin}<br/>{first_product.sku}</div>}>
      <InternalLink to={`/product/redirect/${first_product.asin}/${market_place}`} className={'BLUR_FOR_WEBINAR'}>
        <ProductFirstVisualByASIN asin={first_product.asin} width={70}/>
      </InternalLink>
    </Tooltip>

    {products.length > 1 ? <Tooltip html={<>
      {products.map((p, i) => {
        if (i === 0) {
          return null
        }
        return <div className="row mb-2">
          <div className="col-12">
            {p.asin}
          </div>
          <div className="col-12">
            {p.sku}
          </div>
        </div>
      })}
    </>}>
      <div className="row">
        <div className="col-12 small text-center">
          (+{products.length - 1})
        </div>
      </div>
    </Tooltip> : null}
  </>
}

const CampaignStatistics = ({ managed_campaign, label, from, to }) => {
  const { campaign_id, profile_id } = managed_campaign
  const ad_groups_reports = useCachedContext('ad_groups_reports', profile_id, from.valueOf(), to.valueOf())

  if (ad_groups_reports.loading) {
    return <DefaultLoading/>
  }

  if (ad_groups_reports.error) {
    return <DefaultError/>
  }

  const reports = ad_groups_reports.filter((r) => r.campaign_id === campaign_id)

  return <Summary managed_campaign={managed_campaign} from={from} to={to} label={label} reports={reports}/>
}

export const AddAmazonAdsAccountWarning = ({ children }) => {
  const translate = useTranslate()
  const amazon_ads_accounts = useCachedContext('amazon_ads_accounts')

  if (amazon_ads_accounts.loading) {
    return null
  }

  if (amazon_ads_accounts.error) {
    return <DefaultError/>
  }

  if (amazon_ads_accounts.length === 0) {
    return <div className="row">
      <div className="col-12">
        {translate({
          fr: `Il faut ajouter un compte Amazon Ads`,
          en: `You need to add an Amazon Ads account first`,
        })}
      </div>
      <div className="col-12">
        <AddAmazonAdsAccount/>
      </div>
    </div>
  }
  return children
}
