import React, { useContext } from 'react'
import UserContext from '../context/user'
import { subscription_tiers } from '../routes/subscription/upgrade'

export default () => {
  const user = useContext(UserContext)
  const { company_id } = user.company
  return company_id
}

export const useCompany = () => {
  const user = useContext(UserContext)
  if (!user.company) {
    return {}
  }
  return user.company
}

export const useMerchantId = () => {
  const user = useContext(UserContext)
  const { merchant_id } = user.company
  return { merchant_id }
}


export const useHasActiveSubscription = () => {
  const user = useContext(UserContext)
  if (!user.company) {
    return false
  }

  const { has_active_subscription } = user.company

  return has_active_subscription
}

export const useActiveFreeTrial = () => {
  const company = useCompany()

  return company.subscription_tier === subscription_tiers.free_trial
}
export const useIsCanceled = () => {
  const company = useCompany()

  return company.subscription_tier === subscription_tiers.canceled
}
export const useIsPaused = () => {
  const company = useCompany()

  return company.subscription_tier === subscription_tiers.paused
}

export const useSubscriptionStatus = () => {
  const user = useContext(UserContext)
  if (!user.company) {
    return 'n/a'
  }

  return user.company.subscription ? user.company.subscription.status : 'n/a'
}

