export const set_1_castor_oil = [[{
  'type': 'shape',
  'object_id': '1',
  'x': 74.03258655804484,
  'y': 20.570264765784145,
  'width': 340.49536635766816,
  'height': 452.9904071727053,
  'angle': 0.982913701083267,
  'props': { 'shape_id': 'set_2_dead_body.svg', 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 69.59266802443994,
  'y': 26.31364562118125,
  'width': 340.49536635766816,
  'height': 452.9904071727053,
  'angle': 1.112430063949803,
  'props': {
    'shape_id': 'set_2_dead_body.svg',
    'fill': 'rgba(0,0,0,0)',
    'filters': { 'border_width': 2, 'border_color': 'black', 'border': true },
  },
}, {
  'type': 'image',
  'object_id': '2',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': null,
  'props': {
    'image_id': 'YEfTdo6su',
    'image_object_fit': 'contain',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.57)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -10.488798370672072,
  'y': -10.99796334012219,
  'width': 388.22605168384916,
  'height': 208.67182475205283,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_1.svg', 'fill': 'A' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': 167.43295540242906,
  'y': 304.19524753904705,
  'width': 360.7747839051066,
  'height': 216.43382684490848,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_2.svg', 'fill': 'A' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': 277.58537694593366,
  'y': 92.97094627006125,
  'width': 156.43292633296005,
  'height': 208.11623885756143,
  'angle': -1.4412673674503138,
  'props': { 'shape_id': 'set_2_dead_body.svg', 'fill': 'C' },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 20.366598778004047,
  'y': 238.96808973883415,
  'width': 187.88187372708757,
  'height': 197.55600814663953,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>Offre limitée</p>\n<p><b>14,99€</b></p>',
    'fontSize': 56,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 42,
  },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': -46.13034623217919,
  'y': -9.365905148636124,
  'width': 434.98860832199153,
  'height': 218.73497865355338,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_3_stroke.svg',
    'fill': '#ffffff',
    'filters': { 'border_width': 4, 'border_color': 'white', 'border': true },
  },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': 178.8163326048611,
  'y': 304.78164506451213,
  'width': 324.95148816906965,
  'height': 204.3414813051748,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_4_stroke.svg',
    'fill': 'A',
    'filters': { 'border': true, 'border_width': 6, 'border_color': 'white' },
  },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 277.5322361769613,
  'y': 11.445523853187979,
  'width': 152.36593082914868,
  'height': 359.27360081306955,
  'angle': 0.3726359061475945,
  'props': {
    'image_id': 'YEfTdo6su',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.51)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': 124.94908350305502,
  'y': -6.415478615071272,
  'width': 388.22605168384916,
  'height': 208.67182475205283,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_1.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -28.59555783586012,
  'y': 302.6677526306967,
  'width': 360.7747839051066,
  'height': 216.43382684490848,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_2.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 7.128309572301404,
  'y': 108.62185755960809,
  'width': 212.32179226069258,
  'height': 75.35641547861508,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>Matériaux</p>\n<p><b>RECYCLÉS</b></p>',
    'fontSize': 35,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 20,
    'group_id': 'group_4y6fpl7v7pg',
  },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 98.98167006109983,
  'y': -10.89340005698643,
  'width': 434.98860832199153,
  'height': 218.73497865355338,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_3_stroke.svg',
    'fill': '#ffffff',
    'filters': { 'border_width': 4, 'border_color': 'white', 'border': true },
    'image_object_reverse': true,
  },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': -11.102201000026866,
  'y': 301.2174902783614,
  'width': 324.95148816906965,
  'height': 204.3414813051748,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_4_stroke.svg',
    'fill': 'A',
    'filters': { 'border': true, 'border_width': 6, 'border_color': 'white' },
    'image_object_reverse': true,
  },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 173.81703458542566,
  'y': 70.36319959346523,
  'width': 152.36593082914868,
  'height': 359.27360081306955,
  'angle': 0,
  'props': {
    'image_id': 'YEfTdo6su',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.51)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': -3.859269426110785,
  'y': 171.89986790920065,
  'width': 248.9712857193898,
  'height': 34.44617168126683,
  'angle': 0,
  'props': { 'shape_id': 'set_2_line.svg', 'fill': 'black', 'group_id': 'group_4y6fpl7v7pg' },
}, {
  'type': 'text',
  'object_id': '9',
  'x': 319.24643584521385,
  'y': 291.04548281419056,
  'width': 162.5661914460286,
  'height': 75.35641547861508,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>Ingrédients</p>\n<p><b>NATURELS</b></p>',
    'fontSize': 35,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 20,
    'group_id': 'group_fmqd25ce0l5',
  },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': 246.2832967653352,
  'y': 351.77766831653264,
  'width': 248.9712857193898,
  'height': 34.44617168126683,
  'angle': 0,
  'props': { 'shape_id': 'set_2_line.svg', 'fill': 'black', 'group_id': 'group_fmqd25ce0l5' },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': 294.0043580101023,
  'y': -7.401482095426971,
  'width': 211.02413766560028,
  'height': 113.42564900633928,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_1.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -196.61999775439372,
  'y': 104.09341454515703,
  'width': 749.9244420691317,
  'height': 449.8901366794158,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_2.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 267.93580914803806,
  'y': -12.323584024172888,
  'width': 296.0752024326093,
  'height': 148.8820668977256,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_3_stroke.svg',
    'fill': '#ffffff',
    'filters': { 'border_width': 4, 'border_color': 'white', 'border': true },
    'image_object_reverse': true,
  },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': -128.21014397355023,
  'y': 91.95068783436963,
  'width': 700.3045011709542,
  'height': 440.3773004403041,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_4_stroke.svg',
    'fill': 'A',
    'filters': { 'border': true, 'border_width': 2, 'border_color': 'white' },
    'image_object_reverse': true,
  },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 9.865914422492857,
  'y': 12.827558045603723,
  'width': 95.06736312811174,
  'height': 224.1655577790505,
  'angle': 0,
  'props': {
    'image_id': 'YEfTdo6su',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.51)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 124.23625254582487,
  'y': 54.1412058284472,
  'width': 376.7820773930753,
  'height': 353.36048879837074,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p><b>NOTRE PRODUIT</b></p>\n<p>&nbsp;✓ VEGAN</p>\n<p>&nbsp;✓ CALMANT</p>\n<p>&nbsp;✓ HYPOALLERGÉNIQUE</p>\n<p>&nbsp;✓ HYDRATANT</p>\n<p><br></p>\n<p><b>AUTRES PRODUITS</b></p>\n<p>&nbsp;✗ NON CONTRÔLÉ</p>\n<p>&nbsp;✗ IRRITE</p>\n<p>&nbsp;✗ PAS RECYCLÉ</p>\n<p><br></p>\n<p><br></p>',
    'fontSize': 29,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 18,
  },
}, {
  'type': 'image',
  'object_id': '8',
  'x': 409.7283755314031,
  'y': 252.53739239556324,
  'width': 73.14787554262307,
  'height': 172.48016334762457,
  'angle': 0,
  'props': {
    'image_id': 'YEfTdo6su',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.51)',
      'drop_shadow': true,
      'grayscale': true,
      'grayscale_value': 1,
      'brightness': false,
      'brightness_value': 1,
      'contrast': true,
      'contrast_value': 0,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': -0.5091649694501115,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'E' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': 148.1494927687865,
  'y': -49.01502985132919,
  'width': 526.6678274712941,
  'height': 283.0843940533172,
  'angle': 0.5323615398868187,
  'props': { 'shape_id': 'set_2_wave_1.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -157.923460076186,
  'y': 227.6534476385002,
  'width': 560.0873861762099,
  'height': 336.00423800562453,
  'angle': 0,
  'props': { 'shape_id': 'set_2_wave_2.svg', 'fill': 'A', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '6',
  'x': 157.87941033220886,
  'y': 5.671936626778248,
  'width': 489.1254912688051,
  'height': 245.95782934249746,
  'angle': 0.352194623262087,
  'props': {
    'shape_id': 'set_2_wave_3_stroke.svg',
    'fill': '#ffffff',
    'filters': { 'border_width': 2, 'border_color': 'white', 'border': true },
    'image_object_reverse': true,
  },
}, {
  'type': 'shape',
  'object_id': '7',
  'x': -61.50953297558692,
  'y': 234.47198494045062,
  'width': 475.28117517948334,
  'height': 298.87433327312334,
  'angle': 0,
  'props': {
    'shape_id': 'set_2_wave_4_stroke.svg',
    'fill': 'A',
    'filters': { 'border': true, 'border_width': 2, 'border_color': 'white' },
    'image_object_reverse': true,
  },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 334.7131649316578,
  'y': 55.13233838371514,
  'width': 126.14316523295429,
  'height': 297.44122550611223,
  'angle': 0,
  'props': {
    'image_id': 'YEfTdo6su',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.51)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 106.41547861507127,
  'y': 73.48947466755106,
  'width': 247.96334012219967,
  'height': 81.4663951120163,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>FABRIQUÉ EN</p>\n<p>FRANCE</p>\n<p><br></p>',
    'fontSize': 36,
    'fontFamily': 'KoHo',
    'fontWeight': 900,
    'lineHeight': 26,
  },
}, {
  'type': 'image',
  'object_id': '8',
  'x': 6.313645621181266,
  'y': 81.67006109979634,
  'width': 94.01170921623992,
  'height': 62.674472810826614,
  'angle': 0,
  'props': {
    'image_id': 'pixabay:28463',
    'filters': {
      'border_width': 5,
      'border_color': 'black',
      'border': false,
      'border_radius': true,
      'border_radius_option': 'px',
      'border_radius_value': 14,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.46)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'rect',
  'object_id': '9',
  'x': -19.59421786244974,
  'y': 290.94555102933896,
  'width': 341.8508416360318,
  'height': 150,
  'angle': -0.10404878065701903,
  'props': { 'fill': 'C', 'opacity': 1, 'group_id': 'group_6rebuhv5itp' },
}, {
  'type': 'rect',
  'object_id': '10',
  'x': -12.297655374864007,
  'y': 290.74555319479003,
  'width': 337.29431727860674,
  'height': 150,
  'angle': -0.07649767651276707,
  'props': {
    'fill': 'rgba(0,0,0,0)',
    'opacity': 1,
    'gradient_color': 'transparent',
    'filters': { 'border_width': 3, 'border_color': 'white', 'border': true },
    'group_id': 'group_6rebuhv5itp',
  },
}, {
  'type': 'text',
  'object_id': '11',
  'x': 104.52138492871691,
  'y': 163.1350958488138,
  'width': 247.96334012219967,
  'height': 124.09368635437883,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>Des centaines de clients <b>HEUREUX</b></p>',
    'fontSize': 36,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '12',
  'x': 7.882836798118234,
  'y': 297.96623803321467,
  'width': 314.0858584582068,
  'height': 124.09368635437883,
  'angle': -0.08763982243379477,
  'props': {
    'fill': 'white',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>★★★★★<br>\n"Le produit parfait, achetez les yeux fermés."<br>\n- Jeff D - 6 août 2020 - Amazon.fr</p>',
    'fontSize': 20,
    'fontFamily': 'KoHo',
    'fontWeight': 400,
    'lineHeight': 32,
  },
}]]
