import React, { useEffect } from 'react'
import Button from '../../components/generic/button'
import { FaCheck, FaPaperPlane, IoMdWarning, TiWeatherPartlySunny } from 'react-icons/all'
import { useProduct } from '../../context/products'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import PublishProduct from './publish_product'
import HelpButton from '../../components/generic/help_button'
import useMarketPlace, { MarketPlaceDetails, useAmazonUrls, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import { useMutation } from '@apollo/client'
import { GET_UPDATES_SINGLE_PRODUCT, PUBLISH } from '../../graphql/queries/publish'
import { await_mutate } from '../../graphql/mutate_promise'
import { single_product_update_selector } from '../publish/single_product_update_selector'
import useCompanyId, { useHasActiveSubscription } from '../../hooks/useCompanyId'
import { get_client } from '../../graphql/simple-client'
import { clean_for_label } from '../product/text-substitution'
import ExternalLinkButton from '../../components/generic/external-link-button'
import useHashParam from 'use-hash-param'
import { scroll_to_node } from './scroll'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import ValidatePrices from './validate_prices'
import { useCachedContext } from '../../hooks/useCachedContext'
import { MdWarning } from 'react-icons/md'
import { EditProductGlobalProperties } from './edit_product_global_properties'
import Item from '../../components/generic/item'
import IntellifoxIcon from '../../images/intellifox-icon'
import { OpenedSectionContext } from './edit'
import { useOpenedSection } from './product_footer'


const has_unpublished_change_locally = (product) => {

  if (!product.product_info.last_publish && !product.last_update && !product.product_info.last_update) {
    return false
  }

  if (!product.product_info.last_publish && ( product.last_update || product.product_info.last_update )) {
    return true
  }

  if (
    ( product.last_update && product.last_update > product.product_info.last_publish ) ||
    ( product.product_info.last_update && product.product_info.last_update > product.product_info.last_publish )
  ) {
    return true
  }
  return false
}


export const has_unpublished_changes = (product) => {

  const variations_have_changes = product.variations && product.variations.reduce((variations_have_changes, variation) => {
    return variations_have_changes || has_unpublished_change_locally(variation)
  }, false)

  return variations_have_changes || has_unpublished_change_locally(product)
}

export const is_publishable = (product) => {
  return !!product.asin
}
const count_publishable_product = (product) => {
  let total = is_publishable(product) ? 1 : 0

  for (let i = 0; i < product.variations.length; i++) {
    const variation = product.variations[ i ]
    total = total + ( is_publishable(variation) ? 1 : 0 )
  }

  return total
}

export default function PublishProductV2({ product_id }) {

  const market_place = useMarketPlace()
  const has_active_subscription = useHasActiveSubscription()

  const product = useProduct(product_id, market_place)
  const french = useFrench()

  const [show_publish_modal, set_show_publish_modal] = React.useState(false)

  const is_published = !has_unpublished_changes(product)

  if (product.loading) {
    return null
  }

  if (product.error) {
    return null
  }

  const icon = is_published ? <FaCheck/> : <FaPaperPlane/>
  const button = is_published ? 'success' : 'primary'
  const label = is_published ? french(`Publié`) : french(`Publier`)

  return <>
    <Button onClick={() => set_show_publish_modal(true)} button={button}>
      {icon} {label}
    </Button>

    {has_active_subscription ? <PublishModal show={show_publish_modal} close={() => set_show_publish_modal(false)} product={product}/> : null}
  </>
}

const MissingSKUorASIN = ({
                            product,
                            show_modal, close,
                          }) => {
  const french = useFrench()
  const translate = useTranslate()
  const lang = useLang()
  const market_place = useMarketPlace()
  const market_place_details = useMarketPlaceDetails(market_place)
  const amazon_urls = useAmazonUrls()


  return <IntellifoxModalComponent title={translate({
    fr: `Il manque l'ASIN et/ou le SKU du produit`,
  })}
                                   modal_size={'xl'}
                                   show_modal={show_modal} close={close}
                                   body={<div className="row">
                                     <div className="col-12">
                                       <h4><MdWarning/> 1. {french(`Le produit doit déjà exister sur Amazon`)}</h4>
                                     </div>
                                     <div className="col-12 mb-4">
                                       <Item>
                                         <div className="row">
                                           <div className="col-12">
                                             <ExternalLinkButton
                                               to={`${market_place_details.seller_central_url}/productclassify`}>{french(`Ajouter un produit sur le Seller Central`)}
                                               <MarketPlaceDetails market_place={market_place}/> </ExternalLinkButton>
                                           </div>
                                           <div className="col-12">
                                             {lang === 'fr' ?
                                               <HelpButton
                                                 slug={'intellifoxr-permet-il-de-creer-automatiquement-des-produits-et-des-variations-sur-amazon-fsdhas'}>
                                                 Intellifox permet-il de créer automatiquement des produits sur Amazon ?
                                               </HelpButton> :
                                               <HelpButton
                                                 slug={'is-intellifox-capable-of-automatically-creating-products-and-variations-on-amazon-16d4tpe'}
                                                 lang_override={'en'}>
                                                 {french(`Intellifox permet-il de créer automatiquement des produits sur Amazon ?`)}
                                               </HelpButton>
                                             }
                                           </div>
                                         </div>
                                       </Item>
                                     </div>
                                     <div className="col-12">
                                       <h4>
                                         <IntellifoxIcon/> 2. {french(`L'ASIN et le SKU du produit doivent être renseignés ici :`)}
                                       </h4>
                                       <p>{french(`Puis cliquer sur "Sauvegarder" en bas à droite`)}</p>
                                     </div>
                                     <div className="col-12">
                                       <EditProductGlobalProperties product={product} show_auto_save={false}/>
                                     </div>
                                   </div>}
  >
    <FaPaperPlane/> {translate({
    fr: `Publier`,
    en: `Publish`,
  })}
  </IntellifoxModalComponent>
}


const PublishResultSingleProduct = ({ product_id, publish_result, loading }) => {
  const market_place = useMarketPlace()

  const product = useProduct(product_id, market_place)

  if (product.loading || loading) {
    return <DefaultLoading/>
  }

  if (product.error) {
    return <DefaultError/>
  }

  const success = publish_result.is_published

  return <li style={{ color: success ? 'green' : 'red' }} className="mb-2"><span
    className="font-weight-bold mr-2">{success ? <><FaCheck/></> : <><IoMdWarning/></>}</span>
    <span
      className="font-weight-bold">{clean_for_label(product.product_info.title || product.product_info.variation, 50, [])}</span>
    <ul>
      {success ? <li><b>SKU</b> : {product.product_info.sku_override || product.sku}</li> :
        <li className="font-weight-bold"><IoMdWarning/>
          <b>SKU</b> : {product.product_info.sku_override || product.sku} non trouvé sur la market
          place {market_place.toUpperCase()}</li>}
      <li><b>ASIN</b> : {product.product_info.asin_override || product.asin}</li>
    </ul>

  </li>
}

const PublishResult = ({ selected_products, publish_results, close }) => {
  const market_place = useMarketPlace()
  const [current_menu, set_current_menu] = useHashParam('menu')
  const translate = useTranslate()
  const french = useFrench()
  const amazon_urls = useAmazonUrls()

  const successes = publish_results.reduce((successes, { is_published }) => {
    return successes + ( is_published ? 1 : 0 )
  }, 0)

  const failures = publish_results.length - successes

  return <div className="row">
    <div className="col-12 font-weight-bold">
      {translate({
        fr: `Résultats de la publication`,
        en: `Results of publication`,
      })}
    </div>
    {selected_products.length === 0 ? <div className="col-12">
      <DefaultLoading/>
    </div> : <>
      <div className="col-12">
        <ul>
          {selected_products.map((product, i) => {
            return <PublishResultSingleProduct product_id={product.product_id} loading={!publish_results[ i ]}
                                               publish_result={publish_results[ i ]}/>
          })}
        </ul>
      </div>
      {successes > 0 ? <div className="col-12 mt-3 font-weight-bold" style={{ color: 'green' }}>
        <FaCheck/> {translate({
        fr: `Les modifications seront visibles après 15 minutes à 24 heures (ça dépend de la météo
        d'Amazon`,
        en: `Updates will be visible after 15 minutes to up to 24 hours (it depends on Amazon weather`,
      })} <TiWeatherPartlySunny/>)
      </div> : null}
      {failures > 0 ? <div className="col-12 mt-3 font-weight-bold" style={{ color: 'red' }}>
        <IoMdWarning/>
        {french(`Certains des SKU ne correspondent pas à des produits sur
        Amazon`)} {market_place.toUpperCase()}. {french(`Pour résoudre ce problème :`)}

        <ol>
          <li className={'mb-2'}><ExternalLinkButton to={amazon_urls[ market_place ].inventory}>
            {french(`Aller sur le Seller Central`)}
          </ExternalLinkButton>
          </li>
          <li>
            {french(`Basculer sur le pays actuel grâce au menu situé tout en haut du Seller Central`)} ({market_place.toUpperCase()})
          </li>
          <li>{french(`Vérifier la présence du produit et son SKU sur la plateforme`)} {market_place.toUpperCase()}</li>
          <li><Button onClick={() => {
            close()
            set_current_menu('properties')
            setTimeout(() => {
              scroll_to_node(`properties-sku-asin-variations`)
            }, 250)
          }}>{french(`Corriger le SKU sur Intellifox`)}</Button></li>
        </ol>
      </div> : null}
    </>}
  </div>

}

const fetch_product_with_updates = async (company_id, market_place, product_id) => {
  const client = get_client()
  const response = await client.request(GET_UPDATES_SINGLE_PRODUCT, {
    company_id, market_place, product_id,
  })
  return response.product
}


const PublishConfirmation = ({ product, selected_updates, on_close }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const sold_by = useCachedContext('sold_by', product.product_id)

  const [opened_section, set_opened_section] = useOpenedSection()

  const [is_open, set_is_open] = React.useState(true)

  const [publish_results, set_publish_results] = React.useState([])
  const [selected_products, set_selected_products] = React.useState([])
  const translate = useTranslate()


  const [publish, publish_mutation] = useMutation(PUBLISH, {})
  useEffect(() => {
    if (publish_mutation.data) {
      product.refresh()
    }

  }, [publish_mutation.data])


  React.useEffect(() => {
    const go = async () => {
      const product_with_updates = await fetch_product_with_updates(company_id, market_place, product.product_id)
      const selected_products = single_product_update_selector(product_with_updates, selected_updates)
      set_selected_products(selected_products)

      const returned_publish_results = await await_mutate(publish, {
        variables: {
          products: selected_products.map(({ updates, ...props }) => {
            return {
              ...props,
              sku: props.sku ? props.sku.trim() : props.sku,
              asin: props.asin ? props.asin.trim() : props.asin,
              updates: updates.map(({ field, value }) => ( { field, value } )),
            }
          }),
          company_id,
          market_place,
          merchant_id,
        },
      })

      set_publish_results(returned_publish_results.publish)

      await product.refresh()
    }


    go().catch(e => console.log(e))
  }, [])

  if (sold_by.loading) {
    return null
  }

  if (sold_by.error) {
    return <DefaultError/>
  }

  const amazon_account = sold_by.find((a) => a.market_place === market_place)
  let merchant_id
  if (amazon_account) {
    merchant_id = amazon_account.merchant_id
  } else {
    return <DefaultError/>
  }


  const close = () => {
    on_close()
    set_opened_section('')
    set_is_open(false)
  }
  return <IntellifoxModalComponent show_modal={is_open} close={close}
                                   title={<>{translate({
                                     fr: 'Lancer la publication',
                                     en: 'Launch publication',
                                   })} <MarketPlaceDetails market_place={market_place} show_domain={true}/> </>}
                                   body={<>
                                     <PublishResult selected_products={selected_products}
                                                    publish_results={publish_results} close={close}/>
                                   </>}/>
}

const PublishModal = ({ product, show, close }) => {
  const translate = useTranslate()
  const market_place = useMarketPlace()

  const [selected_updates, set_selected_updates] = React.useState([])

  const [launch, set_launch] = React.useState(false)
  const product_count = count_publishable_product(product)

  if (product_count === 0) {
    return <MissingSKUorASIN product={product} show_modal={show} close={close}/>
  }

  return <><IntellifoxModalComponent title={<>
    {translate({
      fr: 'Publier',
      en: 'Publish',
    })} <MarketPlaceDetails market_place={market_place} show_domain={true}/>
  </>}
                                     show_modal={show}
                                     close={() => {
                                       close()
                                       // set_launch(true)
                                     }}
                                     on_open={() => {
                                       set_launch(false)
                                     }}
                                     body={show ? <div>
                                       <ValidatePrices product={product}/>
                                       <PublishProduct update_selected_updates={set_selected_updates} selected_updates={selected_updates}
                                                       product_id={product.product_id} close={close}/>
                                     </div> : null}
                                     action_label={translate({
                                       fr: 'Lancer la publication',
                                       en: 'Publish',
                                     })}
                                     disable_action={selected_updates.length === 0}
                                     on_click={() => {
                                       set_launch(true)
                                     }}

  >

  </IntellifoxModalComponent>

    {launch ? <PublishConfirmation product={product} selected_updates={selected_updates}
                                   on_close={() => set_launch(false)}/> : null}
  </>
}

