import React, { useContext } from 'react'
import UserContext from '../context/user'
import { useCachedContext } from './useCachedContext'
import NavigationButton from '../components/generic/navigation-button'
import Flag from '../components/flag/flag'
import { DefaultError, DefaultLoading } from '../components/i18n/translations'
import { useFrench, useTranslate } from '../context/lang'
import Button from '../components/generic/button'
import Item from '../components/generic/item'
import { format_money, format_percentage } from '../routes/order/format_numbers'

export const MarketPlaceContext = React.createContext('fr')

export default function useMarketPlace() {
  return useContext(MarketPlaceContext)
}

export let amazon_regions = []
export const set_amazon_regions = (regions) => amazon_regions = regions

export const useRegion = () => {
  const market_place = useMarketPlace()

  for (let i = 0; i < amazon_regions.length; i++) {
    const { market_places } = amazon_regions[ i ]

    for (let j = 0; j < market_places.length; j++) {
      const market_place_details = market_places[ j ]

      if (market_place_details.market_place === market_place) {
        return amazon_regions[ i ]
      }
    }
  }

  throw new Error('Could not find region for marketplace ' + market_place)
}

export const useAmazonCurrencies = () => {
  return amazon_regions.reduce((all_currencies, region) => {
    return region.market_places.reduce((all_currencies, market_place) => {
      const { currency, currency_symbol } = market_place
      if (!all_currencies.find((i) => i.code === currency && i.symbol === currency_symbol)) {
        all_currencies.push({
          code: currency,
          symbol: currency_symbol,
          display: (amount, locale) => format_money(locale, amount, currency),
        })
      }
      return all_currencies
    }, all_currencies)
  }, [])
}

export const compute_region_id_for_market_place = (market_place_or_region_id) => {
  for (let i = 0; i < amazon_regions.length; i++) {
    const { market_places, region_id } = amazon_regions[ i ]
    if (region_id === market_place_or_region_id) {
      return region_id
    }

    for (let j = 0; j < market_places.length; j++) {
      const market_place_details = market_places[ j ]

      if (market_place_details.market_place === market_place_or_region_id) {
        return amazon_regions[ i ].region_id
      }
    }
  }

  throw new Error('Could not find region for market_place_or_region_id ' + market_place_or_region_id)
}

export const find_market_place = (market_place) => {
  for (let i = 0; i < amazon_regions.length; i++) {
    const amazon_region = amazon_regions[ i ]
    for (let j = 0; j < amazon_region.market_places.length; j++) {
      const market_place_details = amazon_region.market_places[ j ]
      if (market_place_details.market_place === market_place) {
        return market_place_details
      }
    }
  }
}

export const useSourceMarketPlace = () => {
  const user = useContext(UserContext)
  const { source_market_place } = user.company
  return source_market_place || 'fr'
}

export const useRegionForProduct = (product) => {
  const source_market_place = useSourceMarketPlace()

  if (!product || product.loading || product.error) {
    return null
  }

  let market_places = product.market_places

  if (!market_places) {
    market_places = [source_market_place]
  }


  const { region_id } = compute_most_likely_region_and_market_place(market_places, source_market_place)

  return region_id
}

export const compute_most_likely_region_and_market_place = (market_places, source_market_place) => {
  if (market_places.indexOf(source_market_place) !== -1 || market_places.length === 0) {
    return {
      market_place: source_market_place,
      region_id: compute_region_id_for_market_place(source_market_place),
    }
  }

  return {
    market_place: market_places[ 0 ],
    region_id: compute_region_id_for_market_place(market_places[ 0 ]),
  }
}


export const useMarketPlaceDetails = (marketplace) => {
  for (let i = 0; i < amazon_regions.length; i++) {
    for (let j = 0; j < amazon_regions[ i ].market_places.length; j++) {
      const market_place = amazon_regions[ i ].market_places[ j ]
      if (market_place.market_place === marketplace) {
        return market_place
      }
    }
  }

  return {
    market_place: '',
    label: {
      fr: '',
      en: '',
    },
  }
}

export const MarketPlaceSelector = ({ current_market_place, on_click, show_lang = false, show_detail = false }) => {

  const translate = useTranslate()


  return <>
    {amazon_regions.map(({ label, market_places }) => {
      return <div className="row mb-2">
        <div className="col-12 small">
          {translate(label)}
        </div>
        <div className="col-12">
          {market_places.map(({ market_place, label, lang_label }) => {

            return <Button
              className="mr-1"
              onClick={() => on_click(market_place)}

              button={market_place === current_market_place ? 'primary' : 'secondary'}>
              <Flag
                market_place={market_place}/> {translate(label)} {show_lang ? <>
              ({translate(lang_label)})
            </> : null} {show_detail ? show_detail(market_place) : null}
            </Button>

          })}
        </div>
      </div>
    })}
  </>
}


export const MarketPlaceDetails = ({ market_place, highlight, show_domain = false, flag_size }) => {
  const market_place_details = useMarketPlaceDetails(market_place)
  const translate = useTranslate()

  return <span className={`${highlight ? 'font-weight-bold green' : ''}`}>
    <Flag market_place={market_place}
          size={flag_size}/>&nbsp;{translate(market_place_details.label)} {show_domain ? <>| <span
    className="font-weight-bold small">{market_place_details.amazon_url}</span></> : null}
  </span>
}

export const useAmazonUrls = (selected_market_place) => {

  const amazon_urls = {}

  for (let i = 0; i < amazon_regions.length; i++) {
    const { market_places } = amazon_regions[ i ]
    for (let j = 0; j < market_places.length; j++) {
      const { market_place, seller_central_url, market_place_id, amazon_url } = market_places[ j ]

      if (selected_market_place && market_place !== selected_market_place) {
        continue
      }

      amazon_urls[ market_place ] = {
        edit: (asin, sku) => asin && sku ? `${seller_central_url}/abis/product/DisplayEditProduct?marketplaceID=${market_place_id}&sku=${encodeURIComponent(sku.trim())}&asin=${asin.trim()}` : '',
        public: (asin) => asin ? `https://www.${amazon_url}/dp/${asin.trim()}` : '',
        reviews: (asin, stars) => {
          // https://www.amazon.fr/dp/product-reviews/${asin}/?filterByStar=${star_map[i]}
          if (!asin) {
            return ''
          }
          let filter = ''
          if (typeof stars !== 'undefined') {
            const star_map = {
              0: 'one_star',
              1: 'two_star',
              2: 'three_star',
              3: 'four_star',
              4: 'five_star',
            }

            filter = `?filterByStar=${star_map[ stars ]}`
          }
          return `https://www.${amazon_url}/dp/product-reviews/${asin.trim()}/${filter}`
        },
        inventory: `${seller_central_url}/inventory/`,
        order: (order_id) => `${seller_central_url}/orders-v3/order/${order_id}`,
        search: (query) => `https://www.${amazon_url}/s?k=${encodeURI(query)}`,
      }
    }
  }

  return amazon_urls

}


export const ShowRegion = ({ region_id }) => {
  const french = useFrench()

  const region = amazon_regions.find((region) => region.region_id === region_id)

  if (!region) {
    return null
  }

  const { label, market_places } = region

  return <div className="row mb-2">
    <div className="col-12 font-weight-bold">
      <h4>{french(label.fr)}</h4>
    </div>
    <div className="col-12">
      {market_places.map(({ market_place }) => {
        return <MarketPlaceDetails key={market_place} market_place={market_place}/>
      })}
    </div>
  </div>
}


export const MarketPlacesTable = ({ market_places, show_vats = false }) => {
  const translate = useTranslate()

  return <Item no_padding={true} className={'mb-3'}>
    <table className="table table-sm small">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{translate({
          fr: `Pays`,
          en: `Country`,
        })}</th>
        <th scope="col">{translate({
          fr: `Site Amazon`,
          en: `Amazon Site`,
        })}</th>
        <th scope="col">{translate({
          fr: `Langue`,
          en: `Language`,
        })}</th>
        <th scope="col">{translate({
          fr: `Monnaie`,
          en: `Currency`,
        })}</th>
        {show_vats ? <>
          <th scope="col">{translate({
            fr: `TVA frais de vente`,
            en: `VAT. on selling fees`,
          })}</th>
          <th scope="col">{translate({
            fr: `TVA frais de publicités`,
            en: `VAT. on ads fees`,
          })}</th>
        </> : null}
      </tr>
      </thead>
      <tbody>
      {market_places.map((m) => {
        const market_place = find_market_place(m)

        return <tr>
          <td><Flag market_place={m}/></td>
          <td>{translate(market_place.label)}</td>
          <td>{market_place.amazon_url}</td>
          <td>{translate(market_place.lang_label)}</td>
          <td>{market_place.currency} - {market_place.currency_symbol}</td>
          {show_vats ? <>
            <td>{format_percentage(market_place.commission_vat, 1)}</td>
            <td>{format_percentage(market_place.ads_vat, 1)}</td>
          </> : null}
        </tr>
      })}

      </tbody>
    </table>
  </Item>
}
