import React from 'react'
import { FiRefreshCw, FiX } from 'react-icons/all'
import { useMutation } from '@apollo/client'
import { refresh_amazon_ads_account } from '../../../graphql/queries/amazon_ads_account'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { useCachedContext } from '../../../hooks/useCachedContext'

export const RefreshAmazonAdsAccount = ({ region_id, amazon_ads_account_id }) => {
  const company_id = useCompanyId()
  const amazon_ads_accounts = useCachedContext('amazon_ads_accounts')

  const [in_progress, set_in_progress] = React.useState(false)
  const [error, set_error] = React.useState(false)

  const [refresh_amazon_ads_account_mutation] = useMutation(refresh_amazon_ads_account)

  const do_it = async () => {
    try {
      set_error(false)
      set_in_progress(true)
      await await_mutate(refresh_amazon_ads_account_mutation, {
        variables: {
          company_id,
          region_id,
          amazon_ads_account_id,
        },
      })
      await amazon_ads_accounts.refresh()
      set_in_progress(false)

    } catch (e) {
      set_in_progress(false)
      set_error(true)
    }
  }

  return <div className="row">
    <div className="col-12 clickable" onClick={() => do_it()}>
      {error ? <FiX/> : <FiRefreshCw className={`${in_progress ? 'spin' : ''}`}/>}
    </div>
  </div>
}
