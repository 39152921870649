import React from 'react'
import { useFrench, useLang } from '../../../context/lang'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { is_ready, useCachedContext } from '../../../hooks/useCachedContext'
import IntellifoxModal from '../../../components/generic/modal'
import Field from '../../../components/generic/field'
import { FiAlertTriangle, FiEdit, FiMinus, FiPlus } from 'react-icons/all'
import Button from '../../../components/generic/button'
import { useMarketPlaceDetails } from '../../../hooks/useMarketPlace'
import { format_money } from '../../order/format_numbers'
import * as _ from 'lodash'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import { useProduct } from '../../../context/products'
import { Pricing, PricingModule } from '../../../components/pricing/Pricing'
import { compute_current_price, useProductPricingDetails } from '../../../components/pricing/pricing_formulas'
import { HelpModal } from '../../../components/generic/help_button'
import { IntellifoxLoader } from '../../../components/generic/loader'

const set_managed_campaign_target = create_mutation({
  mutation: 'set_managed_campaign_target',
  params: {
    company_id: 'ID!',
    campaign_id: 'String',
    target: 'Float',
  },
})

const steps = [-10, -1, -0.1, 0.1, 1, 10]

export const SetManagedCampaignTarget = ({ managed_campaign, ad_groups }) => {
  const french = useFrench()
  const lang = useLang()
  const company_id = useCompanyId()
  const { campaign_id, market_place } = managed_campaign
  const { currency, currency_symbol } = useMarketPlaceDetails(market_place)
  const managed_campaigns = useCachedContext('managed_campaigns')

  const [target, set_target] = React.useState(managed_campaign.target || 0)
  const [set_managed_campaign_target_mutation] = useMutation(set_managed_campaign_target)

  const products = is_ready(ad_groups) ? _.uniqBy(_.flatten(ad_groups.map(({ product_ads }) => product_ads)).filter((p) => p.state === 'enabled'), 'asin')
    : []


  if (! is_ready(ad_groups)) {
    return <DefaultLoading/>
  }

  if (products.length === 0) {
    return <div className="row">
      <div className="col-12 small font-weight-bold">
        <FiAlertTriangle /> {french(`Aucun produit n'est rattaché à la campagne`)}
      </div>
      <div className="col-12">
        <HelpModal label={french(`Comment résoudre ?`)} small={true}>
          <div className="row">
            <div className="col-12">
              {french(`1. Retrouver la campagne dans le Seller Central`)}
            </div>
            <div className="col-12">
              {french(`2. Ajouter au moins un produit dans la campagne`)}
            </div>
            <div className="col-12">
              {french(`3. Actualiser Intellifox`)}
            </div>
          </div>
        </HelpModal>
      </div>
    </div>
  }

  return <IntellifoxModal title={french(`Modifier l'objectif de coût par conversion`)}
                          body={<>
                            <div className="row align-items-center">
                              <div className="col-12 col-lg-6">
                                <div className="row mb-3">
                                  <Field type={'number'} step={0.01} value={target} setter={set_target}
                                         placeholder={french(`Coût par conversion`)} unit={<>{currency_symbol} <span
                                    className="small">{french(`/conversion`)}</span></>}/>
                                  <div className="col-12">
                                    {steps.map((step, i) => {
                                      return <Button onClick={() => set_target((target) => parseFloat(target) + step)}
                                                     className={'btn-sm mr-2'} key={i}>
                                        {step > 0 ? <FiPlus/> :
                                          <FiMinus/>} {format_money(lang, Math.abs(step), currency, 2)}
                                      </Button>
                                    })}
                                  </div>
                                  <div className="col-12 small mt-2 font-weight-bold">
                                    {target < 0 ? <div className="alert alert-warning"><FiAlertTriangle/> {french(`Le coût par conversion doit être supérieur à 0`)}</div> : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6">
                                <div className="alert alert-info">
                                  <div className="row">
                                    <div className="col-12 font-weight-bold">
                                      {french(`Que représente l'objectif par conversion ?`)}
                                    </div>
                                    <div className="col-12">
                                      {french(`C'est le budget alloué pour faire une vente grâce à cette campagne.`)}
                                    </div>
                                    <div className="col-12 font-weight-bold">
                                      {french(`Comment choisir l'objectif de coût par conversion ?`)}
                                    </div>
                                    <div className="col-12">
                                      {french(`La recommendation est de ne pas dépasser la marge réalisée lors d'une vente. Mais cela peut être utile au lancement d'un produit d'aller au-delà de cette valeur.`)}
                                    </div>
                                    <div className="col-12 font-weight-bold">
                                      {french(`Comment Intellifox va utiliser cette valeur ?`)}
                                    </div>
                                    <div className="col-12">
                                      {french(`Intellifox va continuellement analyser la performance de la campagne, pour chaque mot-clé, et ajuster les enchères en fonction des taux de conversion.`)}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            {products.length > 0 ? <div className="row">
                              <div className="col-12">
                                {products.map(({ asin }) => {
                                  return <ProductCosts key={asin} asin={asin} market_place={market_place}/>
                                })}
                              </div>
                            </div> : null}
                          </>}

                          action_label={french(`Modifier l'objectif de coût par conversion`)}
                          on_click={async () => {
                            await await_mutate(set_managed_campaign_target_mutation, {
                              variables: {
                                company_id,
                                campaign_id,
                                target: parseFloat(target),
                              },
                            })
                            await Promise.all([
                              managed_campaigns.refresh(),
                            ])
                          }}
                          button_props={{
                            className: 'btn-sm',
                          }}>
    <FiEdit/> {french(`Modifier`)}
  </IntellifoxModal>
}


const ProductCosts = ({ asin, market_place }) => {
  const product_id_by_asin = useCachedContext('product_id_by_asin', asin)

  const product = useProduct(product_id_by_asin ? product_id_by_asin.product_id : null, market_place)
  const parent_product = useProduct(product_id_by_asin ? product_id_by_asin.parent_product_id : null, market_place)

  if (product_id_by_asin.loading || product.loading || parent_product.loading) {
    return <DefaultLoading/>
  }

  if (product_id_by_asin.error || product.error || parent_product.error) {
    return <DefaultError/>
  }

  return <div className="row">
    <div className="col-12">
      <Pricing product={product} parent_product={parent_product.product_id ? parent_product : null}
               market_place={market_place} show_only_pricing={true}/>
    </div>
  </div>
}
