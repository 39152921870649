import React from 'react'
import { useProduct } from '../../../context/products'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { FaArrowRight } from 'react-icons/fa'
import DeeplLogo from './deepl_logo'
import ExternalLink from '../../../components/generic/external-link'
import useMarketPlace, {
  get_marketplace, MarketPlaceDetails,
  useMarketPlaceDetails,
  useSourceMarketPlace,
} from '../../../hooks/useMarketPlace'
import IntellifoxModal, { IntellifoxModalComponent } from '../../../components/generic/modal'
import Button from '../../../components/generic/button'
import { useKeep } from './product_translater_modal'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import { useFrench, useTranslate } from '../../../context/lang'
import Field from '../../../components/generic/field'
import Item from '../../../components/generic/item'
import useDebounce from '../../../hooks/useDebounced'

const Show = ({ label, text, translation }) => {
  if (!text || !translation) {
    return null
  }
  return <div className="row mb-2">
    <div className="col-12 font-weight-bold">
      {label}
    </div>
    <div className="col-12">
      <div className="row">
        <div className="col">
          <div className="rounded shadow black_and_white p-1" dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
        <div className="col-auto">
          <FaArrowRight/>
        </div>
        <div className="col">
          <div className="rounded shadow black_and_white p-1"
               dangerouslySetInnerHTML={{ __html: translation }}></div>
        </div>
      </div>
    </div>

  </div>
}

const TranslateStuff = ({ label, text, source_lang, target_lang, keep }) => {


  const translation = useCachedContext('translation', source_lang, target_lang, text, keep)

  if (translation.loading) {
    return <DefaultLoading/>
  }

  if (translation.error) {
    return <DefaultError/>
  }

  if (!translation.translated_text) {
    return null
  }


  return <Show label={label} text={text} translation={translation.translated_text}/>
}


const lang_labels = {
  'fr': {
    'fr': 'Français',
    'en': 'French',
  },
  'en': {
    'fr': 'Anglais',
    'en': 'English',
  },
  'de': {
    'fr': 'Allemand',
    'en': 'German',
  },
  'es': {
    'fr': 'Espagnol',
    'en': 'Spanish',
  },
  'it': {
    'fr': 'Italien',
    'en': 'Italian',
  },
  'nl': {
    'fr': 'Néerlandais',
    'en': 'Dutch',
  },
  'sv': {
    'fr': 'Suédois',
    'en': 'Swedish',
  },
}


export const TranslateAndApply = ({
                                    children,
                                    initial_value,
                                    source_lang_label,
                                    source_lang,
                                    target_lang_label,
                                    target_lang,
                                    on_translate,
                                  }) => {
  const french = useFrench()

  const [text, _set_text] = React.useState(initial_value)

  const [text_to_translate, _set_text_to_translate] = React.useState(initial_value)

  const set_text_to_translate = useDebounce((text) => {
    _set_text_to_translate(text)
  }, 1000, [_set_text_to_translate], [])

  const set_text = (text) => {
    _set_text(text)
    set_text_to_translate(text)
  }

  const translation2 = useCachedContext('translations', source_lang, target_lang, [text_to_translate])

  return <IntellifoxModal title={french('Traduction automatique')}
                          body={<>
                            <div className="row">
                              <div className="col-12 font-weight-bold">
                                {french(source_lang_label.fr)}
                              </div>
                              <Field type={'text'} value={text} setter={set_text}/>
                              <div className="col-12 mt-3 font-weight-bold">
                                {french(`Traduction automatique vers`)} {french(target_lang_label.fr)}
                              </div>
                              <div className="col-12">
                                <Item>
                                  {translation2 && translation2[ 0 ] ? translation2[ 0 ] : french('Traduction en cours...')}
                                </Item>
                              </div>
                            </div>
                          </>}

                          action_label={'Traduire'}
                          on_click={async () => {
                            await on_translate(translation2[ 0 ])
                          }}

  >
    {children}
  </IntellifoxModal>
}


const TranslateModalBody = ({
                              show,
                              close,
                              value,
                              setter,
                              button_props,
                              keep,
                              source_market_place,
                              target_market_place,
                            }) => {
  const translate = useTranslate()

  const translation = useCachedContext('translation', source_market_place.lang, target_market_place.lang, value, keep)

  return <IntellifoxModalComponent button_props={button_props}
                                   show_modal={show}
                                   close={close}
                                   title={translate({
                                     fr: 'Traduction',
                                     en: 'Translation',
                                   })}
                                   body={<>
                                     <div className="row">
                                       <div className="col-12">
                                         <TranslateStuff text={value} source_lang={source_market_place.lang}
                                                         target_lang={target_market_place.lang} keep={keep}/>
                                       </div>
                                     </div>
                                   </>}
                                   action_label={translate({
                                     fr: 'Appliquer',
                                     en: 'Apply',
                                   })}
                                   on_click={() => setter(translation.translated_text)}/>

}

export const LoadSourceProduct = ({ product, source_market_place, access_value, ...props }) => {
  const source_product = useProduct(product.product_id, source_market_place.market_place)

  if (source_product.loading) {
    return null
  }

  if (source_product.error) {
    return <DefaultError/>
  }

  return <TranslateModalBody {...props}
                             value={access_value(source_product)}
                             source_market_place={source_market_place}/>
}

export const TranslateModal = ({ access_value, setter, button_props = {}, product }) => {

  const [show, set_show] = React.useState(false)
  const keep = useKeep(product)
  const translate = useTranslate()

  const target_market_place_code = useMarketPlace()
  const source_market_place_code = useSourceMarketPlace()
  const target_market_place = useMarketPlaceDetails(target_market_place_code)
  const source_market_place = useMarketPlaceDetails(source_market_place_code)

  if (target_market_place_code === source_market_place_code) {
    return null
  }

  return <>
    <Button className={'btn-sm mr-2'} {...button_props} onClick={() => set_show(true)}>
      {translate({
        fr: `Traduire depuis`,
        en: `Translate from`,
      })} <MarketPlaceDetails market_place={source_market_place.market_place} flag_size={16}/>
    </Button>
    {show ? <LoadSourceProduct product={product} show={show} close={() => set_show(false)} setter={setter}
                               access_value={access_value}
                               keep={keep}
                               target_market_place={target_market_place}
                               source_market_place={source_market_place}/> : null}
  </>
}

export const TranslateModalComponent = ({ show_modal, close, access_value, setter, product }) => {

  const keep = useKeep(product)

  const target_market_place_code = useMarketPlace()
  const source_market_place_code = useSourceMarketPlace()
  const target_market_place = useMarketPlaceDetails(target_market_place_code)
  const source_market_place = useMarketPlaceDetails(source_market_place_code)


  return <>
    {show_modal ?
      <LoadSourceProduct product={product} show={show_modal} close={close} setter={setter}
                         access_value={access_value}
                         keep={keep}
                         target_market_place={target_market_place}
                         source_market_place={source_market_place}/> : null
    }
  </>
}

export default function TranslateProduct() {
  const french = useFrench()

  return (
    <div className="row">
      <div className="col-12 font-weight-bold">
        <div className="alert alert-success">
          {french(`Tu bénéficies de`)}
          <DeeplLogo/>, {french(`le service de traduction automatisée le plus fiable au monde.`)}<br/>
          <ExternalLink to={'https://www.deepl.com/quality.html'}>
            {french(`Regarde comment Deepl Pro surpasse les traductions automatiques d'Amazon, Google et Microsoft !`)}
          </ExternalLink>
        </div>
      </div>
    </div>

  )
}
