import { gql } from '@apollo/client'
import { create_query } from './_query_and_mutations_templates'


export const UPDATE_COMPANY_SOURCE_MARKET_PLACE = gql`
    mutation UpdateCompanySourceMarketPlace($company_id: ID!, $source_market_place: String!) {
        updateCompanySourceMarketPlace(
            company_id: $company_id,
            source_market_place: $source_market_place
        )
    }
`

export const UPDATE_COMPANY_SETTINGS = gql`
    mutation UpdateCompanySettings(
        $company_id: ID!,
        $activate_comment_automatic_request:Boolean,
        $comment_automatic_request_delay: Float
        $comment_automatic_request_minutes: Float
    ) {
        update_company_settings(
            company_id: $company_id,
            activate_comment_automatic_request: $activate_comment_automatic_request,
            comment_automatic_request_delay: $comment_automatic_request_delay,
            comment_automatic_request_minutes: $comment_automatic_request_minutes,
        )
    }
`

export const GET_BILL_AS_PDF = `
    query GetBillAsPdf(
        $company_id: ID!,
        $bill_url: String!
    ) {
        get_bill_as_pdf(
            company_id: $company_id,
            bill_url: $bill_url,
        )
    }
`
export const UPDATE_SPAPI_OAUTH_CODE = gql`
    mutation UupdateSPAPIOauthCode(
        $company_id: ID!,
        $merchant_id: String,
        $spapi_oauth_code: String,
    ){
        update_spapi_oauth_code(
            company_id: $company_id,
            merchant_id:$merchant_id,
            spapi_oauth_code:$spapi_oauth_code
        )
    }
`



export const remove_amazon_account = gql`
    mutation remove_amazon_account(
        $company_id: ID!,
        $merchant_id: String
    ){
        remove_amazon_account(
            company_id: $company_id,
            merchant_id: $merchant_id,
        )
    }
`



export const feature_usage_query = `
query feature_usage($company_id:ID!) {
  feature_usage(company_id:$company_id) {
    transparent_images
    publish
    comment_requested
  }
}`
