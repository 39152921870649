import React from 'react'
import { useDesignerContext } from '../designer_context'

export default function ActionController() {
  const { action, width, height } = useDesignerContext()

  const [click, set_click] = React.useState({ x: 0, y: 0 })
  const action_controller_ref = React.useRef(null)


  if (!action) {
    return null
  }
  return (
    <div onClick={(e) => {
      const action_controller_box = action_controller_ref.current.getBoundingClientRect()
      // {x,y,width,height}
      const horizontal_ratio = action_controller_box.width / width
      const x = ( e.clientX - action_controller_box.x ) / horizontal_ratio

      const vertical_ratio = action_controller_box.height / height
      const y = ( e.clientY - action_controller_box.y ) / vertical_ratio

      set_click({ x, y })
      action({x,y})
      e.stopPropagation()
    }} style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 999878,
      background: 'rgba(0,0,240,0.3)',
    }} ref={action_controller_ref}>
      {/*x:{click.x}<br/>*/}
      {/*y:{click.y}<br/>*/}
    </div>
  )
}
