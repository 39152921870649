import React from 'react'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../i18n/translations'
import { ObjectSelector, ObjectSelectorNeutral } from './browse_elements'

export const ShapeSelector = ({ on_click }) => {
  const shapes = useCachedContext('shapes')

  if (shapes.loading) {
    return <DefaultLoading/>
  }

  if (shapes.error) {
    return <DefaultError/>
  }

  return (
    <div>{shapes.map((shape) => {
      const { shape_id, width, height } = shape
      const ratio = Math.max(width, height) / 500
      const is_wider = width > height

      const is_stroke = /stroke/.test(shape_id)

      let stroke = {}
      if (is_stroke) {
        stroke = {
          filters: {
            border: true,
            border_width: 3,
            border_color: 'black',
          },
        }
      }

      const object = {
        type: 'shape',
        x: 0,
        y: 0,
        width: is_wider ? 500 : width / ratio,
        height: is_wider ? height / ratio : 500,
        angle: 0,
        props: { shape_id, fill: 'A', ...stroke },
      }

      return <ObjectSelectorNeutral object={object} on_click={() => on_click(shape)}/>
    })}</div>
  )
}

export default function BrowseShapes() {
  const shapes = useCachedContext('shapes')

  if (shapes.loading) {
    return <DefaultLoading/>
  }

  if (shapes.error) {
    return <DefaultError/>
  }


  return (
    <div>{shapes.map(({ shape_id, width, height }) => {
      const ratio = Math.max(width, height) / 500
      const is_wider = width > height

      const is_stroke = /stroke/.test(shape_id)

      let stroke = {}
      if (is_stroke) {
        stroke = {
          filters: {
            border: true,
            border_width: 3,
            border_color: 'black',
          },
        }
      }

      const object = {
        type: 'shape',
        x: 0,
        y: 0,
        width: is_wider ? 500 : width / ratio,
        height: is_wider ? height / ratio : 500,
        angle: 0,
        props: { shape_id, fill: 'A', ...stroke },
      }

      return <ObjectSelector object={object}/>
    })}</div>
  )
}
