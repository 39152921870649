import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useDesignerContext } from '../designer_context'
import { apply_overrides } from '../override/apply_overrides'
import copy from 'copy-to-clipboard'
import { DOWNLOAD_VISUAL } from '../../../graphql/queries/visual'
import { clean_for_label } from '../../../routes/product/text-substitution'
import moment from 'moment'
import { force_download } from '../header/download_visual'
import { get_client } from '../../../graphql/simple-client'
import useCompanyId from '../../../hooks/useCompanyId'
import { useRegion, useSourceMarketPlace } from '../../../hooks/useMarketPlace'
import copy_to_clipboard from 'copy-to-clipboard'

const useControl = (key, transform) => {
  const {
    selected_objects,
    update_single_object,
    update_multiple_objects,
    current_objects,
    current_product_id,
  } = useDesignerContext()


  const update_selected_objects = React.useCallback((transform, delta) => {
    const selected_object_ids = Object.keys(selected_objects).filter((object_id) => selected_objects[ object_id ])

    const objects = apply_overrides(current_objects.objects, current_objects.overrides[ current_product_id ])


    const updates = []
    for (let i = 0; i < selected_object_ids.length; i++) {
      const object_id = selected_object_ids[ i ]
      const object = objects.find(o => o.object_id === object_id)
      updates.push({
        object_id,
        updates: transform(object, delta),
      })
      // update_single_object(selected_object_ids[ i ], transform(object, delta), false)
    }
    update_multiple_objects(updates, false)
    return false
  }, [update_single_object, current_objects])


  useHotkeys(key, () => update_selected_objects(transform, 1), [update_selected_objects])
  useHotkeys(`shift+${key}`, () => update_selected_objects(transform, 10), [update_selected_objects])
  useHotkeys(`ctrl+${key}`, () => update_selected_objects(transform, 50), [update_selected_objects])
}
export default function KeyboardController({ index, save, market_place }) {

  const {
    delete_selected_objects,
    select_next_object,
    set_multiple_selection,
    multiple_selection,
    duplicate_object,
    are_all_selected_objects_grouped,
    group_selected_objects,
    ungroup_selected_objects,
    copy,
    paste,
    select_all_objects,
    product,
  } = useDesignerContext()

  const company_id = useCompanyId()

  useHotkeys('del', () => {
    delete_selected_objects()
    return false
  }, [delete_selected_objects])

  useHotkeys('tab', () => {
    select_next_object()
    return false
  }, [select_next_object])

  useControl('left', (o, delta) => ( { x: o.x - delta } ))
  useControl('right', (o, delta) => ( { x: o.x + delta } ))
  useControl('up', (o, delta) => ( { y: o.y - delta } ))
  useControl('down', (o, delta) => ( { y: o.y + delta } ))

  useHotkeys('s', () => {
    set_multiple_selection(!multiple_selection)
  }, [multiple_selection])
  useHotkeys('d', () => {
    duplicate_object()
  }, [duplicate_object])

  useHotkeys('g', () => {
    if (are_all_selected_objects_grouped()) {
      ungroup_selected_objects()
    } else {
      group_selected_objects()
    }
  }, [are_all_selected_objects_grouped, group_selected_objects, ungroup_selected_objects])

  useHotkeys(`ctrl+a`, (e) => {
    e.preventDefault()
    select_all_objects()
  }, [select_all_objects])

  useHotkeys(`ctrl+c`, () => {
    copy()
  }, [copy])

  useHotkeys(`ctrl+v`, (e) => {
    paste()
  }, [paste])


  useHotkeys(`ctrl+y`, async (e) => {
    try {
      const client = get_client()

      await save()

      const product_id = product.product_id
      const visual = product.product_info.visuals.find(v => v.index === index)

      const response = await client.request(DOWNLOAD_VISUAL, {
        company_id, market_place, product_id, index, renderer: visual.renderer,
      })
      // window.open(response.downloadVisual)
      const asin = product.asin || 'intellifox'
      const product_name = clean_for_label(( product.product_info.title || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''), 30, [], '').replace(/[\s]+/g, '-')
      const file_name = `cover-${asin}-${product_name}-image-${index + 1}-${moment().format('YYYY-MM-DD-HH-mm-SS')}.jpg`
      await force_download(response.downloadVisual.replace('original', 'resize_1920x1080'), file_name)
    } catch (e) {
      console.log('failed to ctrl+y', e)
    }

  }, [paste, product, index])

  useHotkeys('ctrl+s', async (e) => {
    e.preventDefault()
    await save()
  }, [save])

  const { current_objects } = useDesignerContext()

  useHotkeys('ctrl+e', async (e) => {
    e.preventDefault()
    const exported_visual = JSON.stringify(current_objects.objects)

    copy_to_clipboard(exported_visual)
  }, [current_objects])


  return null
}
