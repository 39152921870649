import React from 'react'

import './update_selector.css'


export const visual_updates = [
  'visual_0',
  'visual_1',
  'visual_2',
  'visual_3',
  'visual_4',
  'visual_5',
  'visual_6',
  'visual_7',
  'visual_8',
]

export const product_updates = [
  'title',
  'bullet_point1',
  'bullet_point2',
  'bullet_point3',
  'bullet_point4',
  'bullet_point5',
  'description',
  'additional_keywords',
  'packaging_length',
  'packaging_width',
  'packaging_height',
  'packaging_weight',
  'msrp',
  'is_gift_wrap_available',
  'is_gift_message_available',
]

export const price_updates = [
  'standard_price',
  'sale_price',
  'sale_from_date',
  'sale_end_date',
  'min_price',
  'max_price',
]

export const all_fields = [
  ...visual_updates,
  ...product_updates,
  ...price_updates,
]

export const updates_labels_fr = {
  'title': 'Titre',
  'bullet_point1': 'Point fort 1',
  'bullet_point2': 'Point fort 2',
  'bullet_point3': 'Point fort 3',
  'bullet_point4': 'Point fort 4',
  'bullet_point5': 'Point fort 5',
  'description': 'Description',
  'additional_keywords': 'Termes de recherche',
  'packaging_length': 'Longueur du packaging',
  'packaging_width': 'Largeur du packaging',
  'packaging_height': 'Hauteur du packaging',
  'packaging_weight': 'Poids du packaging',
  'msrp': 'Prix conseillé par le fabricant',
  'is_gift_wrap_available': 'Disponibilité emballage cadeau',
  'is_gift_message_available': 'Disponibilité message cadeau',

  'visual_0': 'Image 1',
  'visual_1': 'Image 2',
  'visual_2': 'Image 3',
  'visual_3': 'Image 4',
  'visual_4': 'Image 5',
  'visual_5': 'Image 6',
  'visual_6': 'Image 7',
  'visual_7': 'Image 8',
  'visual_8': 'Image 9',

  'standard_price': 'Prix normal',
  'sale_price': 'Prix réduit',
  'sale_from_date': 'Début prix réduit',
  'sale_end_date': 'Fin prix réduit',
  'min_price': 'Prix minimum',
  'max_price': 'Prix maximum',
}

export const updates_labels_en = {
  'title': 'Title',
  'bullet_point1': 'Bullet point 1',
  'bullet_point2': 'Bullet point 2',
  'bullet_point3': 'Bullet point 3',
  'bullet_point4': 'Bullet point 4',
  'bullet_point5': 'Bullet point 5',
  'description': 'Description',
  'additional_keywords': 'Search terms',
  'packaging_length': 'Packaging length',
  'packaging_width': 'Packaging width',
  'packaging_height': 'Packaging height',
  'packaging_weight': 'Packaging weight',
  'msrp': 'MSRP',
  'is_gift_wrap_available': 'Gift wrap availability',
  'is_gift_message_available': 'Gift message availability',

  'visual_0': 'Main image',
  'visual_1': 'Image 2',
  'visual_2': 'Image 3',
  'visual_3': 'Image 4',
  'visual_4': 'Image 5',
  'visual_5': 'Image 6',
  'visual_6': 'Image 7',
  'visual_7': 'Image 8',
  'visual_8': 'Image 9',

  'standard_price': 'Regular price',
  'sale_price': 'Sale price',
  'sale_from_date': 'Sale price from date',
  'sale_end_date': 'Sale price end date',
  'min_price': 'Minimum price',
  'max_price': 'Maximum price',
}



