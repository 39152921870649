import React from 'react';
import OnboardingMaster from '../onboarding/onboarding_master';
import UserContext from '../../context/user';
import useLocalStorage from '../../hooks/useLocalStorage';
import { DisplayUserFirstName } from '../../components/user/display_name';
import moment from 'moment';
import { useFrench, useLang, useTranslate } from '../../context/lang';
import help from '../../images/story/help.svg';
import alerts from '../../images/story/alerts.svg';
import subscription from '../../images/story/subscription.svg';
import Item from '../../components/generic/item';
import { navigate } from '../../absolute_link';
import { FaArrowRight, IoIosWarning } from 'react-icons/all';
import { to_upper_first } from '../order';
import { section_color, WelcomeIcon } from '../colors';
import Title from '../../components/layout/title';
import useHashParam from 'use-hash-param';
import { useCompany } from '../../hooks/useCompanyId';
import { subscription_tiers_label_fr } from '../subscription/upgrade';
import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../graphql/queries/user';
import { await_mutate } from '../../graphql/mutate_promise';
import Subscription from '../subscription';
import InternalLink from '../../components/generic/internal-link';
import BigBox from '../../components/layout/big_box';
import { OnboardingVideo } from '../onboarding/onboarding_videos';
import ExternalLinkButton from '../../components/generic/external-link-button';

import seller_suite_0 from '../../images/seller_suite/seller_suite-0.jpg';
import seller_suite_1 from '../../images/seller_suite/seller_suite-1.jpg';
import seller_suite_2 from '../../images/seller_suite/seller_suite-2.jpg';
import { useCachedContext } from "../../hooks/useCachedContext";


export const useSellerSuiteLink = () => {
  const me = useCachedContext('me');
  const user = React.useContext(UserContext);

  const is_logged_in = me && me.token;

  const seller_suite_domain = `https://app.sellersuite.com`;
  if (is_logged_in) {
    const { company_id } = user.company;
    const { user_id } = user.user;
    const { token } = user;
    return `${seller_suite_domain}/settings/spe/${token}/${user_id}/${company_id}`;
  }

  return seller_suite_domain;

};

const Clock = () => {
  const translate = useTranslate();

  const [now, set_now] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => {
      set_now(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <>
    {to_upper_first(moment(now).format('LLLL'))}
  </>;

};


const SubscriptionState = () => {
  const company = useCompany();
  const french = useFrench();

  const { subscription_tier } = company;

  return <>{french(subscription_tiers_label_fr[subscription_tier])}</>;
};


export const BigItemMenu = ({ section, label, route, Icon }) => {
  return <InternalLink to={route}>
    <div className="col-auto" style={{ width: 150 }}>
      <div className="row justify-content-center">
        <div className="col-auto">
          <Item style={{
            background: `${section_color(section)}`,
            color: 'white',
          }}
                className={'mb-2'}>
            {Icon ? <Icon margin={'mr-0'} style={{ fontSize: 50 }} /> :
              <WelcomeIcon section={section} margin={'mr-0'} style={{ fontSize: 50 }} />}
          </Item>
        </div>
        <div className="col-12 text-center small">
          {label}
        </div>
      </div>
    </div>
  </InternalLink>;
};

const is_dev = process.env.NODE_ENV === 'development';

export const AutoRedirectToSellerSuite = () => {
  const seller_suite_link = useSellerSuiteLink();

  React.useEffect(() => {
    // Don't redirect if the path starts with /sso_mastermind
    if (window.location.pathname.startsWith('/sso_mastermind')) {
      return;
    }
    if (moment().isAfter('2024-05-31')) {
      window.location = seller_suite_link;
    }
  }, []);

  return null;

};

export default function Welcome({ disconnect, set_overflow_hidden }) {
  const user = React.useContext(UserContext);
  const translate = useTranslate();
  const lang = useLang();
  const french = useFrench();

  const seller_suite_link = useSellerSuiteLink();

  const [onboarding_done, set_onboarding_done] = useLocalStorage(`show_onboarding_${user.user.user_id}`, user.user.onboarding_done);

  const [spapi_oauth_code] = useHashParam('spapi_oauth_code');


  const [update_user] = useMutation(UPDATE_USER, {
    variables: {
      user_id: user.user.user_id,
      onboarding_done: true,
    },
  });

  return <>
    <div className={'pt-5 pb-5 container-fluid'}>
      <div className="container mb-5">

        <OnboardingMaster disconnect={disconnect} onboarding_done={onboarding_done}
                          set_onboarding_done={set_onboarding_done} spapi_oauth_code={spapi_oauth_code}
                          on_finish={() => {
                            const go = async () => {
                              await await_mutate(update_user);
                              await user.refresh();
                            };

                            go().catch(e => console.error(e));
                            set_onboarding_done(true);
                            navigate('/product');
                          }}>
          <Title color={section_color('welcome')} style={{ color: 'white' }}
                 details={<div className={'font-weight-bold'}>
                   <span className="small mr-2 text-uppercase ">{french(`Abonnement`)}</span> <SubscriptionState />
                 </div>}
          ><WelcomeIcon section={'welcome'} /> {translate({
            fr: 'Bienvenue',
            en: 'Welcome',
          })}, <DisplayUserFirstName /> !</Title>


          {/*<BlackFridayAd/>*/}
          {/*<Polls/>*/}

          <div className="row">
            <div className="col-12">
              <div className="alert alert-success mb-5">
                <div className="row">
                  <div className="col-12 h1 font-weight-bold">
                    {french(`Intellifox devient Seller Suite`)}
                  </div>
                  <div className="col-12">
                    {french(`C'est parti pour la nouvelle identité d'Intellifox, qui devient définitivement Seller Suite !`)}
                  </div>
                  <div className="col-12 mb-3">
                    {french(`À partir du 31 mai 2024, Intellifox ne sera plus accessible. La transition est hyper facile, il suffit de cliquer sur le lien ci-dessous :`)}
                  </div>
                  <div className="col-12">
                    <ExternalLinkButton target={''} to={seller_suite_link}>
                      <FaArrowRight /> {french(`Se connecter à Seller Suite en un clic`)}
                    </ExternalLinkButton>
                  </div>
                  <div className="col-12 font-weight-bold small mt-3">
                    {french(`PS : Toutes tes données et tes paramètres ont été transférés sur Seller Suite, tu n'as rien à faire ! Si tu rencontres le moindre souci sur Seller Suite, on est là pour le résoudre le plus rapidement possible.`)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="row mb-5">*/}
          {/*  <div className="col-12">*/}
          {/*    <hr/>*/}
          {/*  </div>*/}
          {/*</div>*/}


        </OnboardingMaster>


      </div>
    </div>

  </>;
}
