import React from 'react'
import Field, { OnSubmitField } from '../../components/generic/field'
import Button from '../../components/generic/button'
import { FaArrowRight, FaSearch, FiSave, MdAssistant, MdTranslate } from 'react-icons/all'
import UpdateButton from '../../components/generic/update-button'
import { useMutation } from '@apollo/client'
import { SET_SEARCH_TERMS } from '../../graphql/queries/search_term'
import useCompanyId from '../../hooks/useCompanyId'
import { await_mutate } from '../../graphql/mutate_promise'
import ExternalLinkButton from '../../components/generic/external-link-button'
import useMarketPlace, {
  MarketPlaceDetails,
  useMarketPlaceDetails,
  useSourceMarketPlace,
} from '../../hooks/useMarketPlace'
import { TranslateAndApply, TranslateModal } from './i18n/translate_product'
import { useProduct } from '../../context/products'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useTranslate } from '../../context/lang'
import { useCachedContext } from '../../hooks/useCachedContext'
import { KeywordsSearchLimit } from '../../components/subscription/subscription_options'
import { Suggestion, SuggestionModal } from './suggestion'
import { CompactItem } from '../../components/generic/item'


export default function SearchTermSetter({ product, market_place, next_step }) {
  const translate = useTranslate()
  const french = useFrench()
  const limits = useCachedContext('limits')

  const source_market_place = useSourceMarketPlace()
  const source_product = useProduct(product.product_id, source_market_place)

  const company_id = useCompanyId()

  const [search_term_0, set_search_term_0] = React.useState(product.product_info.search_terms[ 0 ])
  const [show_suggestions, set_show_suggestions] = React.useState(false)

  const [set_search_terms, set_search_terms_mutation] = useMutation(SET_SEARCH_TERMS, {
    variables: {
      company_id,
      product_id: product.product_id,
      market_place,
      search_terms: [search_term_0],
    },
  })

  const source_market_place_details = useMarketPlaceDetails(source_market_place)
  const market_place_details = useMarketPlaceDetails(market_place)

  if (source_product.loading || limits.loading) {
    return <DefaultLoading/>
  }

  if (source_product.error || limits.error) {
    return <DefaultError/>
  }


  const { keywords_search, keywords_search_limit } = limits

  const can_do_keywords_search = keywords_search_limit === -1 || keywords_search < keywords_search_limit

  const submit = async (e) => {
    if (!can_do_keywords_search) {
      throw new Error('no usage left')
    }
    try {
      await await_mutate(set_search_terms)
      await product.refresh()
      await limits.refresh()
      next_step()
    } catch (e) {
    }
  }


  return (
    <div className="row">
      <div className="col-12">
        <MarketPlaceDetails market_place={market_place} show_domain={true}/>
      </div>
      <div className="col-12 mb-5">
        <h2>{translate({
          fr: `Quelle est la recherche que tu ferais sur Amazon pour trouver ton produit ?`,
          en: `How would you look for your product on Amazon?`,
        })}</h2>
      </div>
      {source_market_place_details.lang !== market_place_details.lang ?
        <div className="col-12 mb-4">
          <div className="alert alert-success">
            <div className="row">
              <div className="col-12 font-weight-bold">
                {french(`Tu ne parles pas la langue de cette marketplace?`)}
              </div>
              <div className="col-12 mb-2">
                <TranslateAndApply
                  source_lang_label={source_market_place_details.lang_label}
                  source_lang={source_market_place_details.lang}
                  target_lang_label={market_place_details.lang_label}
                  target_lang={market_place_details.lang}
                  initial_value={source_product.product_info.search_terms[ 0 ]}
                  on_translate={(translation) => {
                    set_search_term_0(translation)
                  }}>
                  <MdTranslate/> {translate({
                  fr: `Traduire automatiquement depuis ta marketplace de référence :`,
                  en: `Automatically translate your reference marketplace:`,
                })} <MarketPlaceDetails market_place={source_market_place} show_domain={true}/>
                </TranslateAndApply>
              </div>
            </div>
          </div>
        </div>
        : null}

      <div className="col-12">
        <OnSubmitField on_submit={submit}>
          <Field type="text" value={search_term_0} setter={set_search_term_0}
                 placeholder={translate({
                   fr: `Entre ici une requête qu'un client d'Amazon devrait faire pour trouver ton produit.`,
                   en: `Type here the request a client from ${market_place_details.amazon_url} should type to find your product. Example : "protein bar"`,
                 })}/>
        </OnSubmitField>
      </div>
      <div className="col-12 small">
        {french(`Besoin d'aide ?`)} <Button
        className={'btn-sm ml-2'}
        onClick={() => set_show_suggestions(true)}><MdAssistant/> {french(`Générer des requêtes cibles automatiquement`)}
      </Button>
        {show_suggestions ? <div className="row mt-3">
          <div className="col-12">
            <SearchTermSuggestions product={product} set_search_term={set_search_term_0}/>
          </div>
        </div> : null}
      </div>

      <div className="col-12 mt-5 text-center">
        <div className="btn-group">
          <ExternalLinkButton className=" " outline={true}
                              to={`https://www.${market_place_details.amazon_url}/s?k=${search_term_0 ? search_term_0 : ''}`}>
            {translate({
              fr: `Voir les résultats sur`,
              en: `See results on`,
            })} {market_place_details.amazon_url}
          </ExternalLinkButton>
          <UpdateButton disabled={!can_do_keywords_search} onClick={submit} {...set_search_terms_mutation}>
            <FiSave/> {translate({
            fr: `Enregistrer et passer à l'étape suivante`,
            en: `Save and go to next step`,
          })} <FaSearch/>
          </UpdateButton>
        </div>
      </div>
      <div className="col-12 text-center">
        <KeywordsSearchLimit/>
      </div>
    </div>
  )
}


const SearchTermSuggestions = ({ product, set_search_term }) => {
  const market_place = useMarketPlace()
  const market_place_details = useMarketPlaceDetails(market_place)
  const translate = useTranslate()
  const french = useFrench()

  return <>
    <div className="row">
      <div className="col-12">
        <div className="alert alert-warning small">
          <div className="row">
            <div className="col-12">
              {french(`La description du produit qui est utilisée pour générer des suggestion est le titre actuel :`)} "{product.product_info.title}"
            </div>
            <div className="col-12">
              {french(`Si les résultats ne sont pas cohérents, il faut modifier le titre puis revenir ici.`)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Suggestion product={product} description={product.product_info.title} attribute={'search_request'}
                       mapper={(line) =>
                         <div className="row mb-2">
                           <div className="col-12">
                             <CompactItem className={'font-weight-bold'}>
                               <div className="row">
                                 <div className="col">
                                   "{line}"
                                 </div>
                                 <div className="col-auto">
                                   <SearchTermVolume search_term={line}/>
                                 </div>
                                 <div className="col-auto">
                                   <ExternalLinkButton className="btn-sm" outline={true}
                                                       to={`https://www.${market_place_details.amazon_url}/s?k=${line ? line : ''}`}>
                                     {translate({
                                       fr: `Voir sur`,
                                       en: `See on`,
                                     })} {market_place_details.amazon_url}
                                   </ExternalLinkButton>
                                 </div>
                                 <div className="col-auto">
                                   <Button className={'btn-sm'}
                                           onClick={() => set_search_term(line)}>{french(`Sélectionner cette requête`)}</Button>
                                 </div>
                               </div>
                             </CompactItem>
                           </div>
                         </div>}/>
  </>
}


const SearchTermVolume = ({ search_term }) => {
  const market_place = useMarketPlace()
  const search_term_volume = useCachedContext('get_search_term_volume', market_place, search_term)
  const french = useFrench()

  if (search_term_volume.loading) {
    return <DefaultLoading/>
  }

  if (search_term_volume.error) {
    return <span className="badge badge-danger">{french(`n/a`)}</span>
  }

  let badge = 'success'
  if (search_term_volume.count < 500) {
    badge = 'danger'
  }
  if (search_term_volume.count < 1000) {
    badge = 'warning'
  }

  return <span className={`badge badge-${badge}`}>{search_term_volume.count} {french(`résultats de recherche`)}</span>
}
