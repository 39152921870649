import { gql } from '@apollo/client'
import { create_mutation, create_query } from './_query_and_mutations_templates'



export const get_processes = create_query({
  query: 'processes',
  params: {
    company_id: 'ID!',
    type: 'String',
  },
  output: `{
    process_id
    type
    creation_date
    results
    steps {
      name
      status
      index
      start_date
      end_date
    }
  }
  `,
})

export const start_process = create_mutation({
  mutation : 'start_process',
  params: {
    company_id: 'ID!',
    type: 'String',
    initial_data: 'String',
  },
})

export const RESET_IMPORT_DELETE_EVERYTHING = gql`
    mutation ResetImportDeleteEverything($company_id:ID!) {
        resetImportDeleteEverything(company_id:$company_id)
    }
`

