import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, {
  Option_Content_Picker,
  Option_Content_Section,
  Option_Content_SubTitle,
  Option_Content_Title,
} from './select__content'
import Button from '../../generic/button'
import TextArea from '../../generic/text-area'
import SelectFont from './select_font'
import { ColorOptionSelector } from './select_color'
import { useTranslate } from '../../../context/lang'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { EmojiToggle } from '../../generic/emoji_picker'
import { TextAreaV2 } from '../../generic/text-area-v2'
import { useIsMobile } from '../../navigation/left-menu'

export const TextExclusiveOptions = ({ label,  options, field }) => {
  const { get_selected_object, update_object_props } = useDesignerContext()
  const translate = useTranslate()

  const object = get_selected_object()

  return <Option_Content_Picker>
    <div className="col-12">
      {label}
    </div>
    <div className="col-12">
        {options.map(({ value, label_translations }, i) => {
          return <Button key={i} onClick={() => {
            return update_object_props({
              object,
              field,
              value,
            })
          }} button={object.props[ field ] !== value ? 'secondary' : 'primary'} className="btn-sm mr-1 mb-1"
          >{translate(label_translations)}</Button>
        })}
    </div>
  </Option_Content_Picker>
}

const align_options = [
  {
    value: 'left', label: 'Gauche', label_translations: {
      fr: 'Gauche',
      en: 'Left',
    },
  },
  {
    value: 'center', label: 'Milieu', label_translations: {
      fr: 'Milieu',
      en: 'Center',
    },
  },
  {
    value: 'right', label: 'Droite', label_translations: {
      fr: 'Droite',
      en: 'Right',
    },
  },
  {
    value: 'justify', label: 'Justifié', label_translations: {
      fr: 'Justifié',
      en: 'Justified',
    },
  },
]
const vertical_align_options = [
  {
    value: 'baseline', label: 'Haut', label_translations: {
      fr: 'Haut',
      en: 'Top',
    },
  },
  {
    value: 'center', label: 'Milieu', label_translations: {
      fr: 'Milieu',
      en: 'Center',
    },
  },
  {
    value: 'end', label: 'Bas', label_translations: {
      fr: 'Bas',
      en: 'Bottom',
    },
  },
]

export default function SelectText({}) {
  const { get_selected_object, update_object_props, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const is_mobile = useIsMobile()

  const object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])
  const editor_ref = React.useRef()

  const insert_text = (e) => {
    const { editor } = editor_ref.current.current.refs
    let cd = new DataTransfer()
    cd.setData('text/plain', e)
    editor._onPaste({
      preventDefault: () => {
      },
      clipboardData: cd,
    })
  }

  const TextComponent = is_mobile ? TextAreaV2 : TextArea


  return (
    <Option_Content>

      <Option_Content_Title>{translate({
        fr: `Texte`,
        en: `Text`,
      })}</Option_Content_Title>

      <Option_Content_Section>
        <div className="row">
          <div className="col-auto mr-2">
            <ColorOptionSelector color={object.props.fill} option={'props.fill'}/>
          </div>
          <div className="col-auto mr-2">
            <ColorOptionSelector color={object.props.background} option={'props.background'}/>
          </div>
        </div>
      </Option_Content_Section>

      <Option_Content_Section>
        <Option_Content_SubTitle>{translate({
          fr: `Contenu`,
          en: `Content`,
        })}</Option_Content_SubTitle>
        <Option_Content_Picker>
          <TextComponent show_html={true} focus_on_launch={true} value={object.props.content} setter={(content) => {
            return update_object_props({
              object,
              field: 'content',
              value: content,
            })
          }}
                    get_editor_ref={(ref) => editor_ref.current = ref}/>
        </Option_Content_Picker>
      </Option_Content_Section>

      <Option_Content_Section>
        <EmojiToggle
          additional_warning={<>{translate({
            fr: `Vérifiez le rendu des emojis dans vos images en cliquant
            sur "Télécharger" en haut à droite, afin d'éviter les mauvaises surprises.`,
            en: `Check how the emojis will appear by clicking on "Download" if you want to avoid bad surprises.`,
          })}</>}
        on_click={(e) => insert_text(e)}/>
      </Option_Content_Section>


      <Option_Content_Section>
        <Option_Content_SubTitle>{translate({
          fr: `Alignement horizontal`,
          en: `Horizontal alignment`,
        })}</Option_Content_SubTitle>
        <TextExclusiveOptions object={object} options={align_options} field={'textAlign'}/>
        <Option_Content_SubTitle>{translate({
          fr: `Alignement vertical`,
          en: `Vertical alignment`,
        })}</Option_Content_SubTitle>
        <TextExclusiveOptions object={object} options={vertical_align_options} field={'verticalAlign'}/>
      </Option_Content_Section>

      <SelectFont/>

    </Option_Content>
  )
}
