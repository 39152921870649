import React from 'react'
import { useMutation } from '@apollo/client'
import DeleteButton from '../../components/generic/delete-button'
import { DELETE_PRODUCT, UPDATE_PRODUCT } from '../../graphql/queries/product'
import { MdDelete, MdWarning } from 'react-icons/md'
import { FaTrashRestore } from 'react-icons/fa'
import useCompanyId from '../../hooks/useCompanyId'
import UpdateButton from '../../components/generic/update-button'
import { useTranslate } from '../../context/lang'
import IntellifoxModal from '../../components/generic/modal'
import { useCachedContext, useResetCachedValues } from '../../hooks/useCachedContext'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { await_mutate } from '../../graphql/mutate_promise'
import { FiTrash } from 'react-icons/all'

export default function ProductDeleteButton({
                                              product_id, className = '', classNameGroup = '', onDelete = () => {
  },
                                            }) {
  const company_id = useCompanyId()
  const translate = useTranslate()


  const [update_product, update_product_mutation] = useMutation(UPDATE_PRODUCT, {
    variables: { company_id, product_id, state: 'deleted' },
    update: () => onDelete(),
  })

  return (
    <DeleteButton className={className} onClick={async () => {
      await update_product()
    }} mutation={update_product_mutation} classNameGroup={classNameGroup}><FiTrash/> {translate({
      fr: `Mettre à la corbeille`,
      en: `Move to trash`,
    })}</DeleteButton>
  )
}

export function ProductRestoreButton({
                                       product_id, className = '', classNameGroup = '', onDelete = () => {
  },
                                     }) {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const [update_product, update_product_mutation] = useMutation(UPDATE_PRODUCT, {
    variables: { company_id, product_id, state: 'active' },
    update: () => onDelete(),
  })

  return (
    <UpdateButton className={className} onClick={async () => {
      await update_product()
    }} mutation={update_product_mutation} classNameGroup={classNameGroup}><FaTrashRestore/> {translate({
      fr: `Restaurer`,
      en: `Restore`,
    })}</UpdateButton>
  )
}


export function ProductDefinitiveDeleteButton({
                                                product_id, className = '', classNameGroup = '', onDelete = () => {
  },
                                              }) {
  const company_id = useCompanyId()
  const translate = useTranslate()


  const [deleteProduct, deleteMutation] = useMutation(DELETE_PRODUCT, {
    variables: { company_id, product_id },
    update: () => onDelete(),
  })

  return (
    <DeleteButton className={className} onClick={async () => {
      await deleteProduct()
    }} mutation={deleteMutation} classNameGroup={classNameGroup}><FiTrash/> {translate({
      fr: `Supprimer définitivement`,
      en: `Delete permanently`,
    })}</DeleteButton>
  )
}

export const EmptyTrashBin = ({ count, refresh_all}) => {
  const translate = useTranslate()
  const company_id = useCompanyId()

  const market_place = useSourceMarketPlace()


  const deleted_products = useCachedContext('minimal_products', market_place, 0, count, 'deleted')

  const [deleteProduct] = useMutation(DELETE_PRODUCT)

  const empty_trash_bin = async () => {
    await Promise.all(deleted_products.map(async (product) => {
      await await_mutate(deleteProduct, {
        variables: {
          company_id,
          product_id: product.product_id,
        },
      })
    }))
    refresh_all()
  }

  return <IntellifoxModal button_props={{ outline: true, button: 'danger' }}
                          title={translate({
                            fr: `Vider la corbeille`,
                            en: `Empty the trash bin`,
                          })}
                          body={<div className="row">
                            <div className="col-12">
                              <div className="alert alert-danger">
                                <MdWarning/> {translate({
                                fr: `Ceci va supprimer les produits sélectionnés définitivement`,
                              })}
                              </div>
                            </div>
                          </div>}
                          on_click={empty_trash_bin}
                          action_label={translate({
                            fr: `Vider la corbeille et supprimer définitivement tous les produits`,
                          })}>
    {translate({
      fr: `Vider la corbeille`,
      en: `Empty the trash bin`,
    })} ({count})
  </IntellifoxModal>
}
