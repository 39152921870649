import React from 'react'


import PageTitle from '../generic/page-title'
import { CreateUserFields } from './user-fields'
import IntellifoxIcon from '../../images/intellifox-icon'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useTranslate } from '../../context/lang'
import { FaAmazon } from 'react-icons/all'
import Button from '../generic/button'
import Item from '../generic/item'


/*
<div id="amazon-root"></div>
 <script type="text/javascript">

    window.onAmazonLoginReady = function() {
      amazon.Login.setClientId('YOUR-CLIENT-ID');
    };
    (function(d) {
      var a = d.createElement('script'); a.type = 'text/javascript';
      a.async = true; a.id = 'amazon-login-sdk';
      a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
      d.getElementById('amazon-root').appendChild(a);
    })(document);

</script>
 */

export default function CreateAccount({ set_user, email, set_email, }) {
  const translate = useTranslate()


  return (
    <>
      <div className="row align-items-center">
        <div className="col-12">
          <CreateUserFields set_user={set_user}  email={email} set_email={set_email}/>
        </div>
      </div>
    </>
  )
}
