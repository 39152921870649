import React from 'react'
import { useTranslate } from '../../context/lang'

export default function ProgressBar({ time = 30 }) {
  const translate = useTranslate()

  const [current, set_current] = React.useState(0)

  const counter_ref = React.useRef(0)

  React.useEffect(() => {
    const progress_interval = setInterval(() => {
      counter_ref.current = counter_ref.current + 1
      set_current(counter_ref.current)
      if (counter_ref.current === time) {
        clearInterval(progress_interval)
      }
    }, 1000)
  }, [])

  const progress = Math.min(100, 100 * current / time)

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <div className="progress" style={{ height: 40 }}>
          <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
               aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
      {progress >= 100 ? <div className="col-12 mb-2 font-italic">
        {translate({
          fr: `Cette opération prend ${time} secondes en général. Le système peut être ralenti en cas de charge
            importante. Ne pas hésiter à recharger la page.`,
          en: <>This operation usually takes about {time} seconds. This can be slowed done due to heavy load. Do not
            hesitate to reload the page.</>,
        })}
      </div> : null}
    </div>
  )
}
