import React, { useCallback, useEffect, useRef } from 'react'
import useLocalStorage from './useLocalStorage'

export const clean_history = () => {
  const keys_to_clean = Object.keys(window.localStorage).filter(k => /history/.test(k))
  console.log('Clean history...', keys_to_clean.length)
  keys_to_clean.forEach((k) => window.localStorage.removeItem(k))
}

export const CleanHistory = () => {
  React.useEffect(() => {
    clean_history()
  }, [])
  return null
}
export default function useHistory(initial_state, update_state, history_key, initial = 0, history_limit = 50) {

  const [history, set_history] = useLocalStorage(history_key + '_history', [initial_state])
  const history_ref = useRef(history)

  const [history_current_pointer, set_history_current_pointer] = useLocalStorage(history_key + '_history_current_pointer', initial)

  useEffect(() => {
    if (history[ history_current_pointer ]) {
      update_state(history[ history_current_pointer ])
    } else {
      console.log('could not find history for history[history_current_pointer], what is the fuck?', history_current_pointer, history)
      reset_history()
    }
  }, [history_current_pointer, history])

  const save_history = useCallback((head) => {
    const new_history = history.reduce((new_history, h, i) => {
      if (i >= history.length - history_limit && i <= history_current_pointer) {
        new_history.push(h)
      }
      return new_history
    }, [])

    if (head) {
      new_history.push(head)
    }

    set_history(new_history)
    history_ref.current = new_history
    set_history_current_pointer(new_history.length - 1)
  }, [history, history_current_pointer, history_limit, set_history, set_history_current_pointer])

  const update_head = useCallback((head) => {
    set_history([
      ...history_ref.current,
      head,
    ])
    set_history_current_pointer(history_ref.current.length)
  }, [set_history, set_history_current_pointer])

  const navigation = {
    backward: () => set_history_current_pointer(Math.max(0, history_current_pointer - 1)),
    forward: () => set_history_current_pointer(Math.min(history.length - 1, history_current_pointer + 1)),
    can_go_backward: history_current_pointer > 0,
    can_go_forward: ( history.length - history_current_pointer - 1 ) > 0,
  }

  const reset_history = () => {
    set_history([initial_state])
    set_history_current_pointer(0)
  }

  if (!history[ history_current_pointer ]) {
    return [initial_state, save_history, update_head, navigation, reset_history]
  }

  return [history[ history_current_pointer ], save_history, update_head, navigation, reset_history]
}
