import React from 'react'
import Button from './button'
import { useTranslate } from '../../context/lang'

export default function CreateButton(
  {
    children,
    disabled = false,
    loading, loadingMessage,
    error, errorMessage,
    data, successMessage,
    onClick = () => {
    },
  },
) {

  const translate = useTranslate()

  if (!loadingMessage) {
    loadingMessage = translate({
      fr: 'Création en cours',
      en: 'Creation in progress',
    })
  }
  if (!errorMessage) {
    errorMessage = translate({
      fr: 'Erreur pendant la création',
      en: 'An error happened',
    })
  }
  if (!successMessage) {
    successMessage = translate({
      fr: 'Création réussie',
      en: 'Successfully created',
    })
  }

  return (
    <Button disabled={disabled || loading} onClick={onClick}>
      {loading ? loadingMessage : null}
      {error ? errorMessage : null}
      {data ? successMessage : null}
      {( !loading && !error && !data ) ? children : null}
    </Button>
  )
}
