import React from 'react'

import './button.css'
import { Link } from '@reach/router'
import { absolute, navigate } from '../../absolute_link'
import InternalLink from './internal-link'
import Button from './button'
import { scroll_to_top } from '../../routes/product_v2/scroll'

export default function NavigationButton({
                                           children,
                                           className = '',
                                           to,
                                           disabled,
                                           outline = false,
                                           button_props = {},
                                           button,
                                           on_click,
                                         }) {
  return (
    <Button outline={outline} disabled={disabled} className={className} button={button} {...button_props}

            onClick={() => {
              navigate(to)
              scroll_to_top()
              if (on_click) {
                on_click()
              }
            }}>{children}</Button>
  )
}
