import React, { useState } from 'react'

import './button.css'
import copy from 'copy-to-clipboard'
import { IoMdClipboard } from 'react-icons/io'
import { useFrench, useTranslate } from '../../context/lang'
import { Tooltip } from 'react-tippy'

const CopyButton = ({
                      className = '', children, text_to_copy, outline = true,
                      btn_type = `btn-${outline ? 'outline-' : ''}primary`,
                      btn_type_on_success = 'btn-success',
                      hover, copied_label,
                    }) => {
  const translate = useTranslate()
  const french = useFrench()

  if (!copied_label) {
    copied_label = translate({
      fr: '✔ Copié !',
      en: '✔ Copied!'
    })
  }

  const [is_copied, set_is_copied] = useState(false)

  return (
    <Tooltip html={<>{french(`Cliquer pour copier`)}</>} arrow={true} distance={20}>

    <button className={`btn ${className} ${is_copied ? btn_type_on_success : btn_type} position-relative`}
            onClick={() => {
              set_is_copied(true)
              setTimeout(() => set_is_copied(false), 1000)
              return copy(text_to_copy)
            }}>{is_copied ? copied_label : children}{hover ?
      <div className={`hover position-absolute ${is_copied ? 'copied' : ''}`}
           style={{ right: 0, top: 0 }}>{hover}</div> : null}</button>
    </Tooltip>
  )
}

export const TextCopyButton = ({ children, text_to_copy, className }) => {
  const french = useFrench()
  return <CopyButton btn_type={'btn-link'} btn_type_on_success={'btn-link'} className={`m-0 p-0 text-left ${className}`}
                text_to_copy={text_to_copy} copied_label={children}
                hover={<div className="hidden">✔ {french(`Texte copié dans le presse-papier`)}</div>}>{children}</CopyButton>
}
export const DarkSmallCopyButton = ({ children, text_to_copy, className }) => {
  const translate = useTranslate()
  return <CopyButton btn_type={'btn-outline-dark'} btn_type_on_success={'btn-outline-dark'} className={`${className}`}
                text_to_copy={text_to_copy} copied_label={children}
                hover={<div className="hidden">✔ {translate({
                  fr: 'Copié !',
                  en: 'Copied!'
                })}</div>}>{children}</CopyButton>
}

export default CopyButton
