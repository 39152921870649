import React from 'react'
import useHashParam from 'use-hash-param'
import Redirect from '../../components/generic/redirect'

export const BackFromSellerSuite = () => {

  const [link] = useHashParam('link')
  React.useEffect(() => {
    window.is_back_from_sellersuite = true
  })

  return <Redirect to={link}/>
}
