import React from 'react'
import { Checkbox } from '../../components/generic/checkbox'
import {
  price_updates,
  product_updates,
  updates_labels_en,
  updates_labels_fr,
  visual_updates,
} from '../publish/update_selector'
import useMarketPlace, { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import InlineToggle from '../../components/generic/inline-toggle'
import TextButton from '../../components/generic/text_button'
import useLocalStorage from '../../hooks/useLocalStorage'
import _ from 'lodash'
import { useMerchantId } from '../../hooks/useCompanyId'
import ConnectIntellifoxToAmazonButton, { AddDeveloperMWSFields } from '../../components/user/add-developper-mws'
import { useFrench, useTranslate } from '../../context/lang'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import NavigationButton from '../../components/generic/navigation-button'
import useHashParam from 'use-hash-param'
import Button from '../../components/generic/button'
import { scroll_to_node } from './scroll'
import { MdInfo } from 'react-icons/all'
import { MdWarning } from 'react-icons/md'
import { Tooltip } from 'react-tippy'

export default function PublishProduct({ update_selected_updates, selected_updates, product_id, close}) {

  const market_place = useMarketPlace()
  const translate = useTranslate()
  const french = useFrench()

  const sold_by = useCachedContext('sold_by', product_id)

  const [publish_all_updates, set_publish_all_updates] = useLocalStorage('publish_all_updates', true)

  const all_updates = []

  all_updates.push(...product_updates)
  all_updates.push(...visual_updates)
  all_updates.push(...price_updates)


  const [updates_to_publish, set_updates_to_publish] = useLocalStorage('updates_to_publish', [])

  const default_menu = 'start'
  const [current_menu, set_current_menu] = useHashParam('menu', default_menu)

  React.useEffect(() => {
    // if updates_to_publish has one of "product_updates", then force title if not selected
    let selected_updates = publish_all_updates ? all_updates : updates_to_publish

    const has_product_update = selected_updates.reduce((has_product_update, update) => {
      return has_product_update || ( product_updates.indexOf(update) !== -1 )
    }, false)
    const has_bullet_point = selected_updates.reduce((has_bullet_point, update) => {
      return has_bullet_point || /bullet_point/.test(update)
    }, false)

    const has_reduced_price = selected_updates.reduce((has_reduced_price, update) => {
      //   'sale_price',
      //   'sale_from_date',
      //   'sale_end_date',
      return has_reduced_price || /sale_price/.test(update) || /sale_from_date/.test(update) || /sale_end_date/.test(update)
    }, false)

    if (has_product_update) {
      selected_updates = _.uniq([
        'title',
        ...selected_updates,
      ])
    }

    if (has_bullet_point) {
      selected_updates = _.uniq([
        ...selected_updates,
        'bullet_point1',
        'bullet_point2',
        'bullet_point3',
        'bullet_point4',
        'bullet_point5',
      ])
    }

    if (has_reduced_price) {
      selected_updates = _.uniq([
        ...selected_updates,
        ...price_updates,
      ])
    }

    update_selected_updates(selected_updates)
  }, [publish_all_updates, updates_to_publish])


  if (sold_by.loading) {
    return <DefaultLoading/>
  }

  if (sold_by.error) {
    return <DefaultError/>
  }



  const SelectUpdates = ({ updates, label }) => {
    return <div className="row">
      <div className="col-12 font-weight-bold mb-2">
        {label}
        <TextButton onClick={() => {
          set_updates_to_publish([
            ...updates_to_publish,
            ...updates,
          ])
        }} className={'ml-2 btm-sm mr-2 small'}><small>{translate({
          fr: `Tout sélectionner`,
          en: `Select all`,
        })}</small></TextButton>
        <TextButton onClick={() => {
          set_updates_to_publish(updates_to_publish.filter(u => updates.indexOf(u) === -1))
        }} className={'btm-sm small'}><small>{translate({
          fr: `Remettre à zéro`,
          en: `Reset`,
        })}</small></TextButton>
      </div>

      {updates.map((update) => {
        const is_selected = updates_to_publish.indexOf(update) !== -1
        const is_forced = !is_selected && selected_updates.indexOf(update) !== -1
        return <Checkbox value={is_forced ? 'forced':is_selected} setter={() => {
          if (is_selected) {
            set_updates_to_publish(updates_to_publish.filter((u) => u !== update))
          } else {
            set_updates_to_publish([
              ...updates_to_publish,
              update,
            ])
          }
        }
        }>{translate({
          fr: updates_labels_fr[ update ],
          en: updates_labels_en[ update ],
        })} {is_forced ? <Tooltip arrow={true} html={<>{french(`Cet élément doit obligatoirement être publié pour pouvoir appliquer les changements demandés.`)} {french(`Cette contrainte est liée au format d'Amazon et ne dépend pas d'Intellifox.`)}</>}><MdWarning/></Tooltip> :null}</Checkbox>
      })}
    </div>
  }

  const amazon_account_for_market_place = sold_by.find((s) => s.market_place === market_place)

  if (!amazon_account_for_market_place) {
    return <div className="row">
      <div className="col-12 h2">
        {translate({
          fr: `Ce produit n'est lié à aucun compte Amazon`,
          en: `This product is not attached to an Amazon account`,
        })}
      </div>
      <div className="col-12">
        <Button onClick={() => {
          set_current_menu('properties')
          setTimeout(() => {
            close()
            scroll_to_node(`properties-amazon-account`)
          }, 250)
        }
        }>{translate({
            fr: `Corriger ce problème`,
            en: `Fix this issue`
        })}</Button>
      </div>
    </div>
  }


  return (
    <div className="row">
      <div className="col-12">
        <InlineToggle is_on={publish_all_updates} on_toggle={() => set_publish_all_updates(!publish_all_updates)}>
          {translate({
            fr: `Tout publier`,
            en: `Publish everything`,
          })}
        </InlineToggle>
      </div>

      {!publish_all_updates ? <div className="col-12 mt-2">
        <div className="row">
          <div className="col-12">
            {translate({
              fr: `Sélectionner les éléments à publier :`,
              en: `Select what you wish to publish:`,
            })}
          </div>
          <div className="col-12 col-md-4">
            <SelectUpdates updates={product_updates} label={translate({
              fr: 'Textes',
              en: 'Texts',
            })}/>
          </div>
          <div className="col-12 col-md-4">
            <SelectUpdates updates={visual_updates} label={translate({
              fr: 'Images',
              en: 'Images',
            })}/>
          </div>
          <div className="col-12 col-md-4">
            <SelectUpdates updates={price_updates} label={translate({
              fr: 'Prix',
              en: 'Price',
            })}/>
          </div>
        </div>

      </div> : null}
    </div>
  )
}
