import React, { useState } from 'react'

import './field.css'
import TextArea from './text-area'
import { apply_variables, remove_html } from '../../routes/product/text-substitution'
import { useFrench } from '../../context/lang'

let global_field_id = 0

const Texts = (props) => {
  const {
    value, setter, help, placeholder, label, lines,
    count_min, count_max, count,
    variables = [],
    parent_value, keywords,
  } = props
  const french = useFrench()

  const [field_id] = useState(global_field_id)
  global_field_id = global_field_id + 1


  const update = (index, new_value) => {
    const new_values = [
      ...value,
    ]
    new_values[ index ] = new_value

    setter(new_values)
  }

  const lines_to_display = []
  for (let i = 0; i < lines; i++) {
    lines_to_display.push(i)
  }

  const badge = (length) => {
    if (length > count_max) return 'danger'
    if (length < count_min) return 'warning'
    return 'success'
  }
  return (
    <>
      <div className="col-12 mb-3">
        {label ? <label htmlFor={`texts_${field_id}`}>{label}</label> : null}
        {lines_to_display.map((i) => {
          const length = value[ i ] ? remove_html(apply_variables(value[ i ], variables)).length : 0

          return <div className="row mb-2">
            <div className="col-9">
              <div className="row">
                <TextArea key={`texts_${field_id}_${i}`}
                          value={value[ i ] || ''}
                          placeholder={placeholder}
                          variables={variables}
                          keywords={keywords}
                          setter={(e) => update(i, e)}/>
              </div>
            </div>

            {count ?
              <div className="col-3">
                <span className={`badge badge-${badge(length)}`}>{length} {french('caractère(s)')}</span>
              </div> : null}

            {parent_value && parent_value[ i ] ? (
              <div className="col-12 small">
                <b>{french(`Valeur du parent :`)}</b> {parent_value[ i ]}
              </div>
            ) : null}
          </div>
        })}

      </div>
      <div className="col-12">
        {help}
      </div>
    </>
  )
}


export default Texts
