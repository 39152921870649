import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Redirect from '../../components/generic/redirect'

export const ProductFinder = ({asin, market_place}) => {

  const product_id_by_asin = useCachedContext('product_id_by_asin', asin)

  if (product_id_by_asin.loading) {
    return <DefaultLoading/>
  }

  if (product_id_by_asin.error) {
    return <DefaultError/>
  }


  return <Redirect to={`/product/edit/${product_id_by_asin.parent_product_id ? product_id_by_asin.parent_product_id : product_id_by_asin.product_id}/${market_place}`}/>
}
