import React, { useState, useEffect, useCallback, useRef } from 'react'
import useLocalStorage from '../../hooks/useLocalStorage'
import Button from './button'
import { useHotkeys } from 'react-hotkeys-hook'
import { Checkbox } from './checkbox'
import { logger } from '../../debug/log'
import { FaCheckCircle } from 'react-icons/fa'
import { useTranslate } from '../../context/lang'

const log = logger('auto_save')

export const useHasUnsavedUpdates = (current_value, new_value) => {
  const [has_unsaved_updates, set_has_unsaved_updates] = React.useState(current_value !== new_value)

  React.useEffect(() => {
    set_has_unsaved_updates(current_value !== new_value)
  }, [current_value, new_value])

  return has_unsaved_updates
}

export default function AutoSave({ save, name, watch = [], has_unsaved_updates, className = '' }) {
  const [auto_save, set_auto_save] = useLocalStorage(name, true)


  const [save_in_progress, set_save_in_progress] = useState(false)


  const do_save = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    if (!has_unsaved_updates) {
      log('3 END !has_unsaved_updates')
      return
    }
    set_save_in_progress(true)
    save(() => {
      setTimeout(() => {
        log('5 END of auto_save')
        set_save_in_progress(false)
      }, 500)
    })
  }, [save, has_unsaved_updates])

  const timeout = useRef(-1)

  useEffect(() => {
    clearTimeout(timeout.current)
    if (auto_save) {
      log('1 IN Change detected and auto_save = true...')
      timeout.current = setTimeout(() => {
        log('2 Trigger do_save()')
        do_save()
      }, 1000)
    }
  }, [...watch, has_unsaved_updates, auto_save])


  useHotkeys('ctrl+s', do_save, [do_save])


  /*

     {save_in_progress || (has_unsaved_updates && auto_save) ? <>Sauvegarde en
            cours...</> : ( !has_unsaved_updates ? 'Enregistrer' : 'Enregistrer (CTRL+S)' )}
   */

  let label = ''
  if (save_in_progress) {
    label = 'Sauvegarde en cours...'
  } else if (auto_save) {
    if (has_unsaved_updates) {
      label = 'Sauvegarde automatique'
    } else {
      label = 'Enregistré'
    }
  } else {
    if (has_unsaved_updates) {
      label = 'Enregistrer (CTRL+S)'
    } else {
      label = 'Enregistré'
    }
  }

  return (
    <div className={`row ${className}`}>
      <div className='col-12'>
        <Button disabled={save_in_progress || ( has_unsaved_updates && auto_save )}
                button={( !has_unsaved_updates && !save_in_progress ) ? 'success' : 'success'}
                onClick={do_save} className={'w-100 h-100 btn-sm'}>
          {label}

          <Checkbox value={auto_save} setter={set_auto_save}>
            Automatique
          </Checkbox>
        </Button>

      </div>
    </div>
  )
}

export function SingleValueAutoSave(
  {
    save, name, className = '', saved_value, current_value,
    button = ({ save_in_progress, has_unsaved_updates, auto_save, do_save, label }) => <>{label}</>,
  }) {
  const translate = useTranslate()

  const auto_save = true


  const [save_in_progress, set_save_in_progress] = useState(false)


  const do_save = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    if (saved_value === current_value) {
      log('3 END saved_value === current_value')
      return
    }
    set_save_in_progress(true)
    save(() => {
      setTimeout(() => {
        log('5 END of auto_save')
        set_save_in_progress(false)
      }, 5)
    })
  }, [save, saved_value, current_value])

  const timeout = useRef(-1)

  useEffect(() => {
    clearTimeout(timeout.current)
    if (auto_save) {
      log('1 IN Change detected and auto_save = true...')
      timeout.current = setTimeout(() => {
        log('2 Trigger do_save()')
        do_save()
      }, 1000)
    }
  }, [saved_value, current_value, auto_save])


  useHotkeys('ctrl+s', do_save, [do_save])


  /*

     {save_in_progress || (has_unsaved_updates && auto_save) ? <>Sauvegarde en
            cours...</> : ( !has_unsaved_updates ? 'Enregistrer' : 'Enregistrer (CTRL+S)' )}
   */

  const has_unsaved_updates = current_value !== saved_value

  let label = ''
  if (save_in_progress) {
    label = translate({
        fr: 'Sauvegarde en cours...',
        en: 'Save in progress...',
    })
  } else if (auto_save) {
    if (has_unsaved_updates) {
      label = translate({
        fr: 'Sauvegarde en cours...',
        en: 'Save in progress...',
      })
    } else {
      label = <>{translate({
          fr: `Sauvegardé automatiquement`,
          en: `Automatically saved`
      })}<FaCheckCircle className='ml-2'/></>
    }
  } else {
    if (has_unsaved_updates) {
      label = translate({
          fr: 'Enregistrer (CTRL+S)',
          en: 'Save (CTRL+S)',
      })
    } else {
      label = translate({
          fr: 'Enregistré',
          en: 'Saved',
      })
    }
  }

  return (
      <div className={`d-inline-block small ${className} ${has_unsaved_updates ? 'font-italic' : 'font-weight-bold'}}`}>
        {label}
      </div>
  )
}
