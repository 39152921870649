import React from 'react'

import star_full from '../../images/stars/star_full.png'
import star_empty from '../../images/stars/star_empty.png'
import star_half_full from '../../images/stars/start_half_full.png'
import ExternalLink from './external-link'
import { useAmazonUrls } from '../../hooks/useMarketPlace'


const Star = ({ src, asin, market_place, stars }) => {
  const amazon_urls = useAmazonUrls(market_place)
  const star = <img src={src} alt=""/>
  if (!asin || !market_place) {
    return star
  }
  return <ExternalLink to={amazon_urls[ market_place ].reviews(asin, stars)}>
    {star}
  </ExternalLink>
}
export default function Stars({ rating = 0, ratings_total = 0, asin, market_place }) {
  if (isNaN(rating) || isNaN(ratings_total)) {
    rating = 0
    ratings_total = 0
  }

  const round_double = Math.round(rating * 2)

  const number_of_full_stars = Math.trunc(round_double / 2)
  const needs_half_star = round_double % 2 === 1
  const number_of_empty_stars = 5 - number_of_full_stars - ( needs_half_star ? 1 : 0 )

  const stars = [...Array(number_of_full_stars)]
  const empty_stars = [...Array(number_of_empty_stars)]

  return (
    <div className="row">
      <div className="col-12">
        {stars.map((_, i) => <Star key={i} src={star_full} stars={i} market_place={market_place} asin={asin}/>)}
        {needs_half_star ?
          <Star src={star_half_full} stars={stars.length} market_place={market_place} asin={asin}/> : null}
        {empty_stars.map((_, i) => <Star key={i} src={star_empty} stars={stars.length + i + ( needs_half_star ? 1 : 0 )}
                                         market_place={market_place} asin={asin}/>)}
        {ratings_total ? <span className={'ml-1'} style={{ fontSize: 12 }}>{ratings_total} ({rating}/5)</span> : null}
      </div>
    </div>
  )
}
