import React, { useRef } from 'react'

import {
  apply_variables,
  apply_variables_to_parsed_text, clean_for_keyword_analysis,
  clean_for_label,
  compute_variables, escapeRegExp,
  over_text,
  parse_text,
  remove_html,
} from '../product/text-substitution'
import Item, { CompactItem } from '../../components/generic/item'
import { Count, CountLike } from '../../components/generic/text-area'
import useMarketPlace, {
  MarketPlaceDetails,
  useMarketPlaceDetails,
  useSourceMarketPlace,
} from '../../hooks/useMarketPlace'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import Field from '../../components/generic/field'
import Button from '../../components/generic/button'
import CopyButton, { TextCopyButton } from '../../components/generic/copy-button'
import TextButton from '../../components/generic/text_button'
import { FaEye, FaSmile } from 'react-icons/fa'
import { TiClipboard, TiHtml5 } from 'react-icons/ti'
import { SingleValueAutoSave } from '../../components/generic/auto-save'
import { useCachedContext } from '../../hooks/useCachedContext'
import { snippet_section } from '../../components/navigation/sections'
import safe from '../product/safe'
import useHashParam from 'use-hash-param'

import './edit_texts.scss'
import DropdownD, {
  DropdownDivider,
  DropdownHeader,
  DropdownItem, DropDownModal,
  DropdownSplit,
} from '../../components/generic/dropdown'
import { MdAddCircle, MdClose, MdDelete, MdPlayArrow, MdTextFields } from 'react-icons/md'
import SnippetAdd from '../snippet/add'
import { SnippetDeleteModal } from '../snippet/snippet-delete-button'
import SnippetEdit from '../snippet/edit'
import HelpButton from '../../components/generic/help_button'
import IntellifoxModal, { IntellifoxModalComponent } from '../../components/generic/modal'
import { Checkbox } from '../../components/generic/checkbox'
import useLocalStorage from '../../hooks/useLocalStorage'
import { TranslateModal, TranslateModalComponent } from './i18n/translate_product'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { ShowCompetitorsAttribute, ShowCompetitorsValues } from './product_preview/competitors'
import { keyword_category_to_button } from '../../components/generic/keyword-chooser'
import { useAddWordsToTranslate, useKeywordTranslate } from '../../context/translations'
import { OpenedSectionContext } from './edit'
import EmojiPicker, { EmojiToggle } from '../../components/generic/emoji_picker'
import {
  FaAmazon, FiArrowRight, FiCopy,
  FiCornerUpLeft,
  FiEdit,
  FiTrash,
  GoTextSize,
  IoMdCopy,
  MdAdd,
  MdEdit,
  MdTranslate,
} from 'react-icons/all'
import { Tooltip } from 'react-tippy'
import { compute_unused_keywords, SelectedKeywords, useKeywordsStat } from './seo_summary'
import { useOpenedSection } from './product_footer'
import FlatChoice, { FlatChoices } from '../../components/generic/flat_choice'
import { DisplayVariations, ProductPresentation } from './product_header'
import { Suggestion } from './suggestion'
import ExternalLinkButton from '../../components/generic/external-link-button'
import { flatten_nested_p_tags } from './product_preview/product_preview'


const capitalize_first_letters = str => str.split('\n').map(str => str.split(' ').map(word => word ? word[ 0 ].toUpperCase() + word.slice(1, word.length) : '').join(' ')).join('\n')

const remove_double_spaces = str => str.replace(/&nbsp;/g, ' ').replace(/  /g, ' ').replace(/   /g, ' ').replace(/    /g, ' ').replace(/     /g, ' ').replace(/     /g, ' ').replace(/      /g, ' ')


const highlight_keywords = (text, selected_keywords) => {
  return text.split('\n')
    .map((line) => line
      .split(' ')
      .reduce((highlighted_keywords, word) => {
        const { highlighted_keyword } = selected_keywords.reduce(({
                                                                    highlighted_keyword,
                                                                    is_highlighted,
                                                                  }, selected_keyword) => {
          if (is_highlighted) {
            return { highlighted_keyword, is_highlighted }
          }

          const { keyword, category } = selected_keyword

          const tester = new RegExp(`\\b${escapeRegExp(keyword)}\\b`, 'i')

          if (tester.test(( highlighted_keyword || '' ).normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
            const starts_with_p = /^<p>/.test(highlighted_keyword)
            const ends_with_p = /<\/p>$/.test(highlighted_keyword)
            if (starts_with_p) {
              highlighted_keyword = highlighted_keyword.replace(/^<p>/, '')
            }
            if (ends_with_p) {
              highlighted_keyword = highlighted_keyword.replace(/<\/p>$/, '')
            }

            return {
              highlighted_keyword: `${starts_with_p ? '<p>' : ''}<span class="highlight-keyword highlight-keyword-${keyword_category_to_button(category)}" >${highlighted_keyword}</span>${ends_with_p ? '</p>' : ''}`,
              is_highlighted: true,
            }
          }

          return { highlighted_keyword, is_highlighted }
        }, {
          highlighted_keyword: word,
        })

        return highlighted_keywords + highlighted_keyword + ' '
      }, '')).join('\n')
}

const ShowText = ({ text, variable, index, count_max, preserve_html, selected_keywords }) => {
  const over = over_text(text, index, count_max)
  if (over) {
    // need to split the text
    // if (variable) {
    //   return <span style={{ background: 'grey', padding: 4, borderRadius: 4 }}>{text}</span>
    // }

    return <>
      {over.ok ?
        <span dangerouslySetInnerHTML={{ __html: highlight_keywords(over.ok, selected_keywords) }}></span> : null}
      {over.over ? <span style={{ background: 'rgba(255,0,0,0.5)', opacity: 0.5 }}
                         dangerouslySetInnerHTML={{ __html: highlight_keywords(over.over, selected_keywords) }}></span> : null}
    </>
  }

  if (variable && text) {
    return <span className="highlight-keyword highlight-keyword-secondary"
                 dangerouslySetInnerHTML={{ __html: text.replace('<p>','').replace('</p>','') }}></span>
  }

  const lolz = highlight_keywords(flatten_nested_p_tags(text), selected_keywords)
  return <span dangerouslySetInnerHTML={{ __html: lolz }}></span>
}


const ResetAttribute = ({ product, create_update_payload, on_success, label }) => {
  const market_place = useMarketPlace()
  const company_id = useCompanyId()
  const { product_id } = product

  const [is_loading, set_is_loading] = React.useState(false)

  const [updateProduct, mutation] = useMutation(UPDATE_PRODUCT, {
    variables: {
      company_id,
      product_id,
      market_place,
    },
  })

  return <Button className={'btn-sm'} outline={true} onClick={() => {
    set_is_loading(true)
    updateProduct({
      variables: {
        ...create_update_payload(product, ''),
      },
      update: async () => {
        await on_success()
        set_is_loading(false)
      },
    }).catch(e => console.log(e))
  }}><FiCornerUpLeft/> {is_loading ? <DefaultLoading/> : label}</Button>
}


const ShowResult = (
  {
    text, product, count_max, count_min, label, is_variation, variation_value,
    parent_product, attribute, reset_attribute_label, update_attribute_label, field_placeholder, is_html_authorized,
    access_value, create_update_payload, preview_classname = '',
    edition_label, show_copy_button, selected_keywords, wrap_in_item = true,
  }) => {

  const market_place = useMarketPlace()
  const company_id = useCompanyId()
  const translate = useTranslate()

  const { product_id } = product
  const snippets = useCachedContext('snippets')

  const [show_edit, set_show_edit] = React.useState(!!variation_value)

  const [is_loading, set_is_loading] = React.useState(false)

  const [value, set_value] = React.useState(variation_value || text)
  React.useEffect(() => {
    set_value(variation_value || text)
    set_show_edit(!!variation_value)
  }, [variation_value, text])

  const [updateProduct, mutation] = useMutation(UPDATE_PRODUCT, {
    variables: {
      company_id,
      product_id,
      market_place,
    },
  })


  const is_same_as_parent_product = is_variation && !variation_value && !( /%VARIATION%/.test(text) || /%VARIATION_UPPER_CASE%/.test(text) )


  if (snippets.loading) {
    return <DefaultLoading/>
  }

  if (snippets.error) {
    return <DefaultError/>
  }


  const variables = compute_variables(product, snippets)

  const applied_variables = apply_variables_to_parsed_text(parse_text(value), variables, !is_html_authorized)

  const total = applied_variables.reduce((total, { text }) => total + text.length, 0)

  const is_key_point = /key_point/.test(attribute)

  const content = applied_variables.map(({ text, variable, index }, i) => <ShowText text={text} variable={variable}
                                                                                    index={index}
                                                                                    count_max={count_max}
                                                                                    key={i}
                                                                                    preserve_html={is_html_authorized}
                                                                                    selected_keywords={selected_keywords}/>)

  const show_result = total > 0 && ( attribute !== 'description' || text !== '<p><br></p>' )


  const result = <>

    <ProductPresentation product={product} parent_product={parent_product} action={<div>
      <div>
        {!is_variation ? count_min && count_max ? <>
                    <span className="mr-2 badge badge-success">{translate({
                      fr: `Objectif :`,
                      en: `Goal:`,
                    })} {count_min} - {count_max}</span>
        </> : ( count_min ? <>
                    <span className="mr-2 badge badge-success">{translate({
                      fr: `Objectif :`,
                      en: `Goal:`,
                    })} {count_min}</span>
        </> : null ) : null}
      </div>
      <div>
        <Count total={total} count_max={count_max} count_min={count_min}/>
      </div>

    </div>}/>
    <div className="row amazon_preview">
      <div className={`col ${preview_classname}`}>
        <Item style={{ background: 'var(--color-white)' }}>
          <div className="row align-items-center">
            <div className="col-auto" style={{ borderRight: '1px solid rgba(0,0,0,0.2)' }}>
              <h2><FaAmazon/></h2>
            </div>
            <div className="col">
              {is_key_point ? <ul>
                <li>{content}</li>
              </ul> : content}
            </div>
            {show_copy_button ? <div className="col-auto" style={{ borderLeft: '1px solid rgba(0,0,0,0.2)' }}>
              <CopyButton className="btn-sm"
                          text_to_copy={apply_variables(is_html_authorized ? value : remove_html(value), variables)}><IoMdCopy/> {translate({
                fr: `Copier`,
                en: `Copy`,
              })}</CopyButton>
            </div> : null}

          </div>
        </Item>
      </div>
    </div>
    <div className="row">
      <div className="col-12">

        {is_variation ? <div className="alert alert-info small mt-2">
          <div className="row">
            {is_variation && ( variation_value === null || variation_value === '' ) ?
              <>
                <div className="col-12 mb-2 font-weight-bold">
                  {translate({
                    fr: `Les valeurs du produit parent sont automatiquement appliquées aux variations.`,
                    en: `The values from the parent product are automatically applied to the variations.`,
                  })}
                </div>
                <div className="col-12">
                  <Button className={'btn-sm'} onClick={() => {
                    set_is_loading(true)
                    updateProduct({
                      variables: {
                        ...create_update_payload(product, text),
                      },
                      update: async () => {
                        await parent_product.refresh()
                        set_is_loading(false)
                      },
                    }).catch(e => console.log(e))
                  }}><FiEdit/> {is_loading ? <DefaultLoading/> : update_attribute_label}</Button>
                </div>
              </>
              : null}

            {!!variation_value ?
              <div className="col-12">
                <ResetAttribute product={product} attribute={attribute} create_update_payload={create_update_payload}
                                label={reset_attribute_label} on_success={() => {
                  set_value(text)
                  return parent_product.refresh()
                }}/>
              </div> : null}
          </div>
        </div> : null}

      </div>

    </div>

    {
      show_edit ? <>
        <EditProductInfoAttribute product={product} is_variation={true} set_value={set_value}
                                  attribute={attribute}
                                  field_placeholder={field_placeholder} is_html_authorized={is_html_authorized}
                                  access_value={access_value} create_update_payload={create_update_payload}
                                  edition_label={edition_label} show_copy_button={show_copy_button}/>
      </> : null
    }

  </>

  return <>
    <div className="row align-items-center mb-3">
      <div className="col-12">
        {wrap_in_item ? <Item>
          {result}
        </Item> : result}
      </div>
    </div>


  </>
}


export const Toggle = ({
                         open_label,
                         close_label = open_label,
                         children,
                         is_open_by_default = false,
                         toggled_content = null,
                         on_toggle = () => {
                         },
                       }) => {
  const [is_open, set_is_open] = React.useState(is_open_by_default)
  React.useEffect(() => {
    set_is_open(is_open_by_default)
    on_toggle(is_open_by_default)
  }, [is_open_by_default])
  return <>
    <div className={`row ${is_open ? 'mb-3' : ''}`}>
      <div className={`col-12`}>
        <TextButton onClick={() => {
          set_is_open(!is_open)
          on_toggle(!is_open)
        }}>
          {is_open ? open_label : close_label}
          <div className="d-inline-block ml-2" style={{
            transition: 'transform 0.5s',
            transform: is_open ? 'rotate(90deg)' : '',
          }}><MdPlayArrow/>
          </div>
        </TextButton>
      </div>
      {is_open ? children : toggled_content}
    </div>
  </>
}

const SnippetsPicker = ({ on_click, variables }) => {
  const translate = useTranslate()
  const french = useFrench()

  const lang = useLang()

  return <div className="row">
    <div className="col-12 small">
      {variables.map((snippet, i) => {
          const { key, value, help, snippet_id } = snippet
          return <>
            <div className="row">
              <div className="col">
                {key}
              </div>
              <div className="col-auto">
                {snippet_id ? <DropdownD label={french(`Options`)} className={'btn-sm mr-2'}>
                  <DropDownModal label={french(`Modifier`)} icon={<MdEdit/>}
                                 Modal={SnippetEdit}
                                 modal_props={{
                                   snippet: {
                                     code: key.replace(/%/g, ''), content_fr: value, snippet_id,
                                   },
                                 }}
                  />
                  <DropDownModal label={french(`Supprimer`)} icon={<FiTrash/>}
                                 Modal={SnippetDeleteModal}
                                 modal_props={{
                                   snippet_id,
                                 }}
                  />
                </DropdownD> : null}
                <Button className={'btn-sm'} onClick={() => on_click(` ${key} `)}>{french(`Insérer`)}</Button>
              </div>
              <div className="col-12 small">
                {snippet_id ? <>"{clean_for_label(value, 100, [])}"</> : help}
              </div>
              <div className="col-12">
                <hr/>
              </div>
            </div>
          </>
        },
      )}
    </div>
    <div className="col-12 mt-3">
      <SnippetAdd/> {lang === 'fr' ?
      <HelpButton slug="variables-dans-les-textes-a-quoi-ca-sert-q2g12n" className={'ml-2 btn-sm'}>
        Article d'aide : les variables, à quoi ça sert ?
      </HelpButton> :
      <HelpButton slug="how-to-use-variables-inside-your-texts-jt95t7" className={'ml-2 btn-sm'}
                  lang_override={'en'}>
        {french(`Article d'aide : les variables, à quoi ça sert ?`)}
      </HelpButton>}
    </div>
  </div>
}


/*
https://keywordsadvantage.com/emoji/

var sections = []
$('section').map((_,section) => {
const emojis = []

$('.copy', section).map((_,b) => emojis.push(b.getAttribute('data-clipboard-text')))

sections.push({
id: section.getAttribute('id'),
emojis
})
})


*/


const ShowValue = ({ value, is_key_point = false }) => {
  const content = <span dangerouslySetInnerHTML={{ __html: value }}></span>
  return <Item className="compact mb-1  shadow amazon_preview" style={{ background: 'var(--color-white)' }}>
    <div className="row">
      <div className={`col-12`}>
        {is_key_point ? <ul>
          <li>{content}</li>
        </ul> : content}
      </div>
    </div>
  </Item>
}

const FormatModal = ({ show_modal, close, value, setter, is_html_authorized }) => {
  const [new_value, set_new_value] = React.useState(value)
  const translate = useTranslate()

  const [transform, set_transform] = useLocalStorage('transform_text_pref', 'none')

  const [remove_double, set_remove_double] = React.useState(true)

  React.useEffect(() => {
    let new_new_value = is_html_authorized ? value : remove_html(value)

    if (transform === 'capitalize_first_letters') {
      new_new_value = capitalize_first_letters(new_new_value)
    } else if (transform === 'to_upper_case') {
      new_new_value = new_new_value.toUpperCase()
    } else if (transform === 'to_lower_case') {
      new_new_value = new_new_value.toLowerCase()
    }

    if (remove_double) {
      new_new_value = remove_double_spaces(new_new_value)
    }

    set_new_value(new_new_value)

  }, [value, transform, remove_double])


  return <IntellifoxModalComponent
    show_modal={show_modal}
    close={close}
    button_props={{
      outline: true,
      className: 'btn-sm',
    }}
    title={translate({
      fr: 'Formater le texte',
      en: 'Format text',
    })}
    body={<>
      <div className="row">
        <div className="col-12 font-weight-bold">
          {translate({
            fr: `Avant`,
            en: `Before`,
          })}
        </div>
        <div className="col-12 mb-3">
          <ShowValue value={value}/>
        </div>


        <div className="col-12 font-weight-bold">
          {translate({
            fr: `Transformations`,
            en: `Transformations`,
          })}
        </div>

        <div className="col-12 mb-2">
          <Button className="mr-2 btn-sm" onClick={() => set_transform('none')}
                  button={transform === 'none' ? 'primary' : 'secondary'}>
            {translate({
              fr: `Aucune transformation`,
              en: `No transformation`,
            })}
          </Button>
          <Button className="mr-2 btn-sm" onClick={() => set_transform('to_upper_case')}
                  button={transform === 'to_upper_case' ? 'primary' : 'secondary'}>
            {translate({
              fr: `Tout en majuscule`,
              en: `Full upper case`,
            })}
          </Button>
          <Button className="mr-2 btn-sm"
                  onClick={() => set_transform('capitalize_first_letters')}
                  button={transform === 'capitalize_first_letters' ? 'primary' : 'secondary'}>
            {translate({
              fr: `Majuscule sur les débuts de mots`,
              en: `Upper case on first letters`,
            })}
          </Button>
          <Button className="mr-2 btn-sm" onClick={() => set_transform('to_lower_case')}
                  button={transform === 'to_lower_case' ? 'primary' : 'secondary'}>
            {translate({
              fr: `Tout en minuscule`,
              en: `Full lower case`,
            })}
          </Button>
        </div>

        <Checkbox value={remove_double} setter={set_remove_double} className={'small'}>
          {translate({
            fr: `Supprimer les espaces inutiles`,
            en: `Remove unnecessary spaces`,
          })}
        </Checkbox>

        <div className="col-12 font-weight-bold mt-2">
          {translate({
            fr: `Aperçu du résultat après transformation`,
            en: `Preview of the result after transformation`,
          })}
        </div>
        <div className="col-12">
          <ShowValue value={new_value}/>
        </div>
      </div>
    </>}
    action_label={translate({
      fr: `Appliquer`,
      en: <>Apply</>,
    })}
    on_click={() => setter(new_value)}
  />
}


const EditProductInfoAttribute = (
  {
    product, attribute, reset_attribute_label, update_attribute_label, field_placeholder,
    count_min, count_max, on_save, is_variation, set_value, is_html_authorized,
    type = 'textarea', preview_classname,
    hide_emojis = false, show_copy_button = false,
    access_value = (product) => product.product_info[ attribute ] || '',
    create_update_payload = (product, new_value) => ( { [ attribute ]: new_value } ),
    edition_label = 'Édition', className = '',
    show_competitors_params = null,
  }) => {
  const market_place = useMarketPlace()
  const company_id = useCompanyId()
  const translate = useTranslate()
  const french = useFrench()

  const target_market_place_code = useMarketPlace()
  const source_market_place_code = useSourceMarketPlace()
  const target_market_place = useMarketPlaceDetails(target_market_place_code)
  const source_market_place = useMarketPlaceDetails(source_market_place_code)


  const editor_ref = React.useRef()
  const [selected_sub_menu, set_selected_sub_menu] = React.useState(is_variation ? '' : 'keywords')

  const { product_id } = product

  const [raw_html, set_raw_html] = useLocalStorage(`raw_html_${attribute}`, false)

  const [value_for_attribute, set_value_for_attribute] = React.useState(access_value(product))
  const [saved_value_for_attribute, set_saved_value_for_attribute] = React.useState(access_value(product))

  const product_id_ref = React.useRef(product_id)

  React.useEffect(() => {
    // recycle component
    if (product.product_id !== product_id_ref.current) {
      set_value_for_attribute(access_value(product))
      set_saved_value_for_attribute(access_value(product))
      product_id_ref.current = product.product_id
    }
  }, [attribute, product])

  const [updateProduct, mutation] = useMutation(UPDATE_PRODUCT, {
    variables: {
      company_id,
      product_id,
      market_place,
    },
  })

  const latest_update_id = useRef(0)
  const save = React.useCallback((onUpdate) => {
    latest_update_id.current = latest_update_id.current + 1
    const save_id = latest_update_id.current
    const value_to_be_saved = value_for_attribute
    console.log('3 Update product...', value_to_be_saved)
    updateProduct({
      variables: {
        // [ attribute ]: value_for_attribute,
        ...create_update_payload(product, value_for_attribute),
      },
      update: () => {
        if (latest_update_id.current === save_id) {
          console.log('4 Update product done !')
          // refresh product for the rest of the world
          set_saved_value_for_attribute(value_to_be_saved)
          product.refresh && product.refresh()
          on_save && on_save()
          onUpdate()
        }
      },
    }).catch(e => console.log(e))
  }, [value_for_attribute, updateProduct])

  const snippets = useCachedContext('snippets')
  if (product.loading || snippets.loading) {
    return <DefaultLoading/>
  }


  const variables = compute_variables(product, snippets)

  const set_value_and_propagate = (value) => {
    set_value_for_attribute(value)
    set_value && set_value(value)
  }

  const insert_text = (e) => {
    if (type === 'textarea') {
      const { editor } = editor_ref.current.current.refs
      let cd = new DataTransfer()
      cd.setData('text/plain', e)
      editor._onPaste({
        preventDefault: () => {
        },
        clipboardData: cd,
      })
    } else if (type === 'text' || type === 'textarea-v2') {
      set_value_and_propagate(value_for_attribute + e)
    }

  }

  const available_sub_menus = raw_html ? [] : [
    {
      key: 'keywords',
      label: french(`Mots-clés`),
      component: <SelectedKeywords product={product}
                                   title={product.product_info.title}
                                   description={product.product_info.description}
                                   key_points={product.product_info.key_points}
                                   key_points_0={product.product_info.key_points[ 0 ]}
                                   key_points_1={product.product_info.key_points[ 1 ]}
                                   key_points_2={product.product_info.key_points[ 2 ]}
                                   key_points_3={product.product_info.key_points[ 3 ]}
                                   key_points_4={product.product_info.key_points[ 4 ]}
                                   attribute={attribute}
                                   value={value_for_attribute}
                                   on_click={insert_text}
      />,
      hide: is_variation,
    },
    {
      key: 'suggestion',
      label: french(`Générer automatiquement`),
      component: <TextSuggestion product={product} attribute={attribute}
                                 set_value_for_attribute={set_value_for_attribute}/>,
    },
    {
      key: 'competitors',
      label: french(`Concurrents`),
      component: <ShowCompetitorsValues product={product} {...show_competitors_params} on_click={insert_text}/>,
      hide: is_variation,
    },
    {
      key: 'emojis',
      label: french(`Emojis`),
      component: <>
        <EmojiPicker on_click={insert_text}/>
        <div className="row">
          <div className="col-12">
            <div className="alert alert-warning mt-2 small">
              {translate({
                fr: `Attention, sur Amazon, l'emoji peut s'afficher différemment`,
                en: `Beware, on Amazon, emojis may look different`,
              })}
            </div>
          </div>
        </div>
      </>,
      hide: hide_emojis,
    },
    {
      key: 'variables',
      label: french(`Variables`),
      component: <SnippetsPicker variables={variables} on_click={insert_text}/>,
    },
  ]

  const current_sub_menu = available_sub_menus.find((s) => s.key === selected_sub_menu)

  const editor_control = <DropdownD label={french(`Outils`)} className={'btn-sm'}>
    {target_market_place.lang !== source_market_place.lang ? <DropDownModal
      label={<>
        {translate({
          fr: `Traduire depuis`,
          en: `Translate from`,
        })} <MarketPlaceDetails market_place={source_market_place.market_place} flag_size={16}/>
      </>}
      icon={<MdTranslate/>}
      Modal={TranslateModalComponent}
      modal_props={{
        product,
        access_value,
        value: value_for_attribute,
        setter: set_value_for_attribute,
      }}
    /> : null}

    <DropDownModal
      label={french(`Formater le texte`)}
      icon={<GoTextSize/>}
      Modal={FormatModal}
      modal_props={{
        product,
        value: value_for_attribute,
        setter: set_value_for_attribute,
        is_html_authorized,
      }}
    />

    {is_html_authorized ?
      <DropdownItem on_click={() => set_raw_html(!raw_html)}
                    label={raw_html ? translate({
                      fr: `Retourner à l'éditeur visuel`,
                      en: <>Go back to the visual editor</>,
                    }) : translate({
                      fr: `Éditer le code HTML brut`,
                      en: <>Edit raw HTML</>,
                    })}
                    icon={<TiHtml5/>}
      >
      </DropdownItem> : null}

  </DropdownD>


  const options_control = <div className="row mb-2">
    <div className="col-auto">
      {available_sub_menus.map(({ key, label, hide }) => {
        if (hide) {
          return null
        }
        return <FlatChoice key={key}
                           on_click={() => {
                             if (is_variation) {
                               set_selected_sub_menu(
                                 selected_sub_menu === key ?
                                   '' : key,
                               )
                             } else {
                               set_selected_sub_menu(
                                 selected_sub_menu === key ?
                                   available_sub_menus
                                     .filter((s) => !s.hide)[ 0 ].key
                                   : key,
                               )
                             }
                           }}
                           is_current={!selected_sub_menu || selected_sub_menu === key}
                           className="p-1 small font-weight-light mr-2">
          {label}
        </FlatChoice>
      })}
    </div>
    <div className="col text-center">

    </div>
    <div className="col-auto text-right ">
      {editor_control}
    </div>
    <div className="col-12">
      <hr className={'m-1'}/>
    </div>
  </div>


  const edit = <div className="row">
    <div className="col-12">
      {options_control}
    </div>
    <div className="col-12 col-md-6 order-2 order-md-1" style={{ borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}>

      <div className="row">
        {current_sub_menu ?
          <div className="col-12">
            {current_sub_menu.component}
          </div> : null}
      </div>
    </div>
    <div className={`col-12 ${current_sub_menu ? 'col-md-6' : ''} order-1 order-md-2`}>
      <div className="row">
        <Field type={type} value={value_for_attribute} setter={set_value_and_propagate}
               placeholder={field_placeholder}
               need_to_be_filled={true}
               variables={variables} margin_bottom={'mb-0'} show_html={is_html_authorized}
               is_html_authorized={is_html_authorized} raw_html={raw_html}
               get_editor_ref={(ref) => editor_ref.current = ref}
        />

        <div className="col-12 text-right">
          <SingleValueAutoSave save={save} name={'product_title_autosave'} className={' '}
                               saved_value={saved_value_for_attribute}
                               current_value={value_for_attribute}/>
        </div>
      </div>
    </div>
  </div>

  if (is_variation) {
    return edit
  }


  return (
    <>
      <div className="row">
        <div className="col-12">
          <Item>
            <div className="row">
              <div className="col-12">
                <ShowResult text={value_for_attribute} product={product} count_min={count_min} count_max={count_max}
                            label={' '} attribute={attribute}
                            wrap_in_item={false}
                            reset_attribute_label={reset_attribute_label}
                            show_copy_button={show_copy_button}
                            update_attribute_label={update_attribute_label} field_placeholder={field_placeholder}
                            is_html_authorized={is_html_authorized} access_value={access_value}
                            create_update_payload={create_update_payload} preview_classname={preview_classname}
                            selected_keywords={product.product_info.selected_keywords || []}/>
              </div>
            </div>
            {edit}

            {!is_variation ? <DisplayVariations product={product} variation_mapper={(variation, parent_product) => {
              return <div className="row">
                <div className="col-12 mb-3"
                     key={variation.product_id}>
                  <ShowResult text={value_for_attribute} product={variation} count_min={count_min}
                              count_max={count_max}
                              is_variation={true} variation_value={access_value(variation)}
                              parent_product={parent_product} attribute={attribute}
                              reset_attribute_label={reset_attribute_label}
                              update_attribute_label={update_attribute_label} field_placeholder={field_placeholder}
                              is_html_authorized={is_html_authorized} access_value={access_value}
                              edition_label={edition_label} show_copy_button={show_copy_button}
                              create_update_payload={create_update_payload} preview_classname={preview_classname}
                              selected_keywords={parent_product.product_info.selected_keywords || []}/>
                </div>
              </div>
            }}/> : null}

          </Item>
        </div>
      </div>

    </>
  )


}

const TextSuggestion = ({ product, attribute, set_value_for_attribute }) => {
  const french = useFrench()

  const [choice, set_choice] = React.useState('auto')

  return <>

    <div className="row">
      <div className="col-12 mb-2">
        <FlatChoices current_value={choice} on_click={(choice) => set_choice(choice)} items={[
          {
            value: 'auto',
            label: french(`Suggestions automatiques`),
          },
          {
            value: 'advanced',
            label: french(`Options avancées`),
          },
        ]} compact={true}/>
      </div>
    </div>

    {choice === 'auto' ?
      <Suggestion product={product} attribute={attribute} description={product.product_info.title}
                  loading_message={french(`Génération en cours avec intégration automatique des mots-clés sélectionnés`)}
                  mapper={(line) => {
                    return <SuggestionLine line={line} set_value_for_attribute={set_value_for_attribute}
                                           product={product}/>
                  }}
      /> : null}

    {choice === 'advanced' ? <AdvancedTextSuggestions product={product} attribute={attribute}
                                                      set_value_for_attribute={set_value_for_attribute}/> : null}
  </>
}

const AdvancedTextSuggestions = ({ product, attribute, set_value_for_attribute }) => {
  const french = useFrench()

  const snippets = useCachedContext('snippets')

  const [keep_only_unused_keywords, set_keep_only_unused_keywords] = React.useState(false)
  const [launch, set_launch] = React.useState(false)

  const keywords = useKeywordsStat(product, snippets)

  let all_unused_keywords = []
  if (keywords.back_office && keywords.unused) {
    all_unused_keywords = [
      ...keywords.back_office.keywords,
      ...keywords.unused.keywords,
    ]
  }

  return <>
    <div className="row small">
      <Checkbox value={keep_only_unused_keywords}
                setter={set_keep_only_unused_keywords}>{french(`Sélectionner uniquement les mots-clés non utilisés`)}</Checkbox>
      <div className="col-12">
        <Button className={'btn-sm'} onClick={() => set_launch(true)}>{french(`Lancer la génération`)}</Button>
      </div>

      {launch ? <div className="col-12">
        <Suggestion product={product} attribute={attribute} description={product.product_info.title}
                    keywords_override={keep_only_unused_keywords ? all_unused_keywords : null}
                    loading_message={french(`Génération en cours avec intégration automatique des mots-clés sélectionnés`)}
                    mapper={(line) => {
                      return <SuggestionLine line={line} set_value_for_attribute={set_value_for_attribute}
                                             product={product}/>
                    }}
        />
      </div> : null}
    </div>
  </>
}

const SuggestionLine = ({ line, product, set_value_for_attribute }) => {
  const french = useFrench()

  return <div className="row mb-2 small">
    <div className="col-12">
      <CompactItem className={'font-weight-bold'}>
        <div className="row">
          <div className="col"
               dangerouslySetInnerHTML={{ __html: highlight_keywords(line, product.product_info.selected_keywords || []) }}></div>
          <div className="col-auto">
            <div className="row text-right">
              <div className="col-12">

                <Button className={'btn-sm'}
                        onClick={() => set_value_for_attribute(line)}><FiArrowRight/> {french(`Sélectionner`)}
                </Button>
              </div>
              <div className="col-12">
                <CopyButton text_to_copy={line}
                            className={'btn-sm'}><FiCopy/> {french(`Copier`)}
                </CopyButton>
              </div>
            </div>
          </div>
        </div>
      </CompactItem>
    </div>
  </div>
}

export const EditTitle = ({ product }) => {
  const translate = useTranslate()

  // const product_title = useCachedContext('product_title', product_id, market_place)

  const on_save = React.useCallback(() => {
    // product_title.refresh()
  }, [product])

  return <EditProductInfoAttribute product={product} attribute={'title'} on_save={on_save}
                                   count_min={180} count_max={200}
                                   type={'textarea-v2'}
                                   hide_emojis={true}
                                   preview_classname={'title'}
                                   edition_label={translate({
                                     fr: 'Éditer le titre :',
                                     en: 'Edit title:',
                                   })}
                                   reset_attribute_label={translate({
                                     fr: 'Réinitialiser le titre',
                                     en: 'Reset title',
                                   })}
                                   update_attribute_label={translate({
                                     fr: 'Je souhaite modifier le titre pour cette variation',
                                     en: 'Set title for this variation',
                                   })}
                                   field_placeholder={translate({
                                     fr: 'Entrer le titre',
                                     en: 'Enter title',
                                   })}
                                   show_copy_button={true}
                                   show_competitors_params={{
                                     field: 'title',
                                   }}
  />
}

export const EditDescription = ({ product }) => {
  const translate = useTranslate()

  return <EditProductInfoAttribute product={product} attribute={'description'}
                                   count_min={1800} count_max={2000}
                                   reset_attribute_label={translate({
                                     fr: 'Réinitialiser la description',
                                     en: 'Reset description',
                                   })}
                                   update_attribute_label={translate({
                                     fr: 'Je souhaite modifier la description pour cette variation',
                                     en: 'Set description for this variation',
                                   })}
                                   field_placeholder={translate({
                                     fr: 'Entrer la description',
                                     en: 'Enter description',
                                   })}
                                   is_html_authorized={true}
                                   show_copy_button={true}
                                   show_competitors_params={{
                                     field: 'description',
                                   }}
                                   field={'description'}/>
}
export const EditKeyPoints = ({ product, index, label }) => {
  const translate = useTranslate()

  const key_point_accessors_for_index = (key_points_index) => {

    return {
      access_value: (product) => safe(product, 'product_info', 'key_points', key_points_index) || '',

      create_update_payload: (product, new_value) => {

        const key_points = []
        for (let i = 0; i < 5; i++) {
          if (i === key_points_index) {
            key_points.push(new_value.replace(/\n/g, ' '))
          } else {
            key_points.push(product.product_info.key_points[ i ])
          }
        }
        return { key_points }
      },
    }
  }

  return <>
    <EditProductInfoAttribute product={product} attribute={`key_points_${index}`}
                              count_min={200} count_max={500}
                              type={'textarea-v2'}
                              edition_label={translate({
                                fr: `Édition du ${label}`,
                                en: `Edit ${label}`,
                              })}
                              reset_attribute_label={translate({
                                fr: `Réinitialiser le ${label}`,
                                en: `Reset ${label}`,
                              })}
                              update_attribute_label={translate({
                                fr: `Je souhaite modifier le ${label} pour cette variation`,
                                en: `Set ${label} for this variation`,
                              })}
                              field_placeholder={translate({
                                fr: `Entrer le ${label}`,
                                en: `Enter ${label}`,
                              })}
                              show_copy_button={true}

                              {...key_point_accessors_for_index(index)}
                              className={'mb-5'}
                              show_competitors_params={{
                                field: 'key_points',
                                type: '[String]',
                              }}
    />
  </>

}


export const EditTextModal = ({ Component, product, children }) => {
  return <IntellifoxModal title={children} body={<Component product={product}/>}>
    {children}
  </IntellifoxModal>
}
