import React, { useMemo } from 'react'
import Item, { CompactItem } from '../../components/generic/item'
import * as _ from 'lodash'
import Flag from '../../components/flag/flag'
import {
  format_money,
  sum_earnings,
  sum_fees,
  sum_cash_flow,
  sum_profits, sum_amazon_fees, sum_ads_fees, sum_product_costs, sum_urssaf, format_percentage,
} from './format_numbers'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { useCachedContext, useMultiCachedContext, useResetCachedValues } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useMarketPlaceDetails, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { get_urssaf_rates_map } from '../../components/pricing/pricing_formulas'
import { NumbersBreakdownModal } from '../../components/pricing/margin_details'
import { FiAlertTriangle, FiDollarSign, FiEye, FiHelpCircle, FiPlus, FiX } from 'react-icons/all'
import { Detail } from '../ppc/ppc_v2/campaign_results'
import { ProductPresentationByProductIdMarketPlace } from '../product_v2/product_header'
import { UpdateCostV2 } from '../../components/pricing/update_cost'
import { useUserCurrency } from '../subscription/upgrade'
import moment from 'moment'
import { HelpModal } from '../../components/generic/help_button'

const DetailsPerMarketPlace = ({
                                 orders,
                                 formula,
                                 total,
                                 initial_market_places = [],
                                 debug,
                               }) => {
  const lang = useLang()

  const currency = useUserCurrency().code
  if (debug) {
    debugger
  }
  const market_places = orders.reduce((all_market_places, order) => {
    const { market_place } = order

    let stats = all_market_places.find((s) => s.market_place === market_place)
    if (!stats) {
      stats = {
        market_place,
        total_for_market_place: 0,
      }
      all_market_places.push(stats)
    }
    stats.total_for_market_place += formula(order)
    return all_market_places
  }, initial_market_places)
    .sort((a, b) => Math.abs(a.total_for_market_place) < Math.abs(b.total_for_market_place) ? 1 : -1)


  return <div className="row small">
    <div className="col-12">
      <table className="w-100 text-center">
        <tbody>
        {market_places.map(({ market_place, total_for_market_place }) => {

          const percentage = ( 100 * total_for_market_place / total )

          return <tr className="col-12" key={market_place}>
            <td><Flag market_place={market_place}/></td>
            <td className="text-right blur_on_discreet">{format_money(lang, total_for_market_place, currency, 2)}</td>
            <td>{isNaN(percentage) ? '-' : `${percentage.toFixed(1)}%`}</td>
          </tr>
        })}
        </tbody>
      </table>
    </div>


  </div>
}


export const useOrdersSummary = ({ orders, from, to, amazon_ads_accounts, amazon_accounts, currency }) => {
  return {}
}

export const ProductsWithoutCostsWarning = ({ products }) => {
  const french = useFrench()
  const product_costs_with_zero = products.filter((c) => c.stats.total_product_costs.units_sold > 0 && c.stats.total_product_costs === 0)

  const reset_optimized_orders = useResetCachedValues('optimized_orders')

  return <>
    {product_costs_with_zero.length > 0 ? <div className="col-12">
      <div className="alert alert-warning">
        <div className="row">
          <div className="col-auto">
            <FiX/>
          </div>
          <div className="col font-weight-bold">
            {french(`Certains produits n'ont pas leurs coûts renseignés :`)}
          </div>
          <div className="col-12">
            <hr/>
          </div>
          <div className="col-12">
            {product_costs_with_zero.filter((_, i) => i < 6).map((p) => <div className="row">
              <div className="col-12">
                <ProductPresentationByProductIdMarketPlace
                  product_id={p.product_id}
                  parent_product_id={p.parent_product_id} market_place={p.market_places[ 0 ].market_place}
                  action={(product, parent_product, market_place) => <UpdateCostV2 product={product}
                                                                                   parent_product={parent_product}
                                                                                   market_place={market_place}
                                                                                   on_update={() => reset_optimized_orders()}
                  />}/>
              </div>
            </div>)}
          </div>
          {product_costs_with_zero.length > 6 ? <div className="col-12">
            <FiPlus/> {product_costs_with_zero.length - 6} {french(`produit(s)...`)}
          </div> : null}
        </div>
      </div>
    </div> : null}
  </>
}

const sum_orders = (orders, key) => orders.reduce((total, order) => total + _.sumBy(order.products, key), 0)

export const useSalesStats = (orders, ads, from, to) => {
  return useMemo(() => {
    const filtered_ads = ads.filter((a) => {
      return moment(a.date, 'YYYYMMDD').isSameOrAfter(moment(from), 'day')
        && moment(a.date, 'YYYYMMDD').isSameOrBefore(moment(to), 'day')
    })
    const amazon_ads = _.sumBy(filtered_ads, 'cost')

    const total_sales = sum_orders(orders, 'total_sales')
    const amazon_commissions = sum_orders(orders, 'amazon_commissions')
    const amazon_fba = sum_orders(orders, 'amazon_fba')
    const amazon_other = sum_orders(orders, 'amazon_other')
    const total_product_costs = sum_orders(orders, 'total_product_costs')
    const total_urssaf = sum_orders(orders, 'total_urssaf')
    const profit = sum_orders(orders, 'profit') + amazon_ads

    return {
      total_sales,
      amazon_commissions,
      amazon_fba,
      amazon_other,
      amazon_ads,
      total_product_costs,
      total_urssaf,
      profit,
      filtered_ads,
    }

  }, [orders, ads, from, to])


}

export default function OrdersSummary({
                                        orders,
                                        ads,
                                        show_break_down = false,
                                        from, to,
                                      }) {
  const translate = useTranslate()
  const lang = useLang()
  const french = useFrench()

  const currency = useUserCurrency().code

  const {
    total_sales,
    amazon_commissions,
    amazon_fba,
    amazon_other,
    amazon_ads,
    total_product_costs,
    total_urssaf,
    profit,
    filtered_ads,
  } = useSalesStats(orders, ads, from, to)


  const break_down = show_break_down ? <NumbersBreakdownModal currency={currency} details={[
    {
      value: total_sales,
      description: translate({
        fr: `Ventes`,
        en: `Sales`,
      }),
      bg: 'gradient_bg',
    },
    {
      value: amazon_commissions,
      description: translate({
        fr: `Frais Amazon : commissions`,
        en: `Amazon fees: commissions`,
      }),
      bg: 'gradient_bg_amazon',
    },
    {
      value: amazon_fba,
      description: translate({
        fr: `Frais Amazon : expédition par Amazon`,
        en: `Amazon fees: fulfilment by Amazon`,
      }),
      bg: 'gradient_bg_amazon',
    },
    {
      value: amazon_other,
      description: translate({
        fr: `Frais Amazon : autre`,
        en: `Amazon fees: autre`,
      }),
      bg: 'gradient_bg_amazon',
    },
    {
      value: amazon_ads,
      description: translate({
        fr: `Frais Amazon : publicités`,
        en: `Amazon fees: ads`,
      }),
      bg: 'gradient_bg_amazon',
    },
    {
      value: total_product_costs,
      description: translate({
        fr: `Coûts des produits`,
        en: `Product costs`,
      }),
      bg: 'gradient_bg_profit',
    },
    {
      value: total_urssaf,
      description: 'URSSAF',
      bg: 'gradient_bg_profit',
    },
  ]}>
    <FiHelpCircle/>
  </NumbersBreakdownModal> : null


  const ads_grouped_by_market_place = _.groupBy(filtered_ads, 'market_place')
  const get_ads_per_market_place = () => Object.keys(ads_grouped_by_market_place).map((market_place) => {
    return {
      market_place,
      total_for_market_place: _.sumBy(ads_grouped_by_market_place[ market_place ], 'cost'),
    }
  })


  return (
    <div className="row">
      <div className="col-12 col-md-6 col-xl-3 mb-2">
        <CompactItem className="bg-gradient_bg-flat" label={translate({
          fr: `Chiffre d'affaires`,
          en: `Revenue`,
        })} action={break_down}
        >
          <div className="row text-right">
            <div className="col-12 blur_on_discreet">
              <h2 className={'text-nowrap'}>{format_money(lang, total_sales, currency, 2)}</h2>
            </div>
            <div className="col-12">
              <DetailsPerMarketPlace orders={orders} formula={(o) => _.sumBy(o.products, 'total_sales')}
                                     total={total_sales}/>
            </div>
          </div>
        </CompactItem>
      </div>
      <div className="col-12 col-md-6 col-xl-3 mb-2">
        <CompactItem className="bg-gradient_bg_amazon-flat" label={translate({
          fr: `Frais Amazon et publicités`,
          en: `Amazon Fees & Ads`,
        })} action={break_down}
        >
          <div className="row text-right">
            <div className="col-12 blur_on_discreet">
              <h2
                className={'text-nowrap'}>{format_money(lang, amazon_commissions + amazon_fba + amazon_other + amazon_ads, currency, 2)}</h2>
            </div>
            <div className="col-12">
              <DetailsPerMarketPlace orders={orders}
                                     formula={(o) => {
                                       return _.sumBy(o.products, 'amazon_commissions')
                                         + _.sumBy(o.products, 'amazon_fba')
                                         + _.sumBy(o.products, 'amazon_other')
                                     }}
                                     initial_market_places={get_ads_per_market_place()}
                                     total={amazon_commissions + amazon_fba + amazon_other + amazon_ads}/>
            </div>
          </div>
        </CompactItem>
      </div>
      <div className="col-12 col-md-6 col-xl-3 mb-2">
        <CompactItem className="bg-gradient_bg_profit-flat" label={translate({
          fr: `Produit et coûts annexes`,
          en: `Product & other costs`,
        })} action={break_down}
        >
          <div className="row text-right">
            <div className="col-12 blur_on_discreet">
              <h2
                className={'text-nowrap'}>{format_money(lang, total_product_costs + total_urssaf, currency, 2)}</h2>
            </div>
            <div className="col-12">
              <DetailsPerMarketPlace orders={orders}
                                     formula={(o) => {
                                       return _.sumBy(o.products, 'total_product_costs')
                                         + _.sumBy(o.products, 'total_urssaf')
                                     }}
                                     total={total_product_costs + total_urssaf}
              />
            </div>
          </div>
        </CompactItem>
      </div>
      <div className="col-12 col-md-6 col-xl-3 mb-2">
        {profit > 0 ? <CompactItem className="bg-gradient_bg_real_profit-flat" label={<>{translate({
            fr: `Profits`,
            en: `Profits`,
          })}<span className="small ml-3">({format_percentage(profit / total_sales)})</span></>}
                                   action={break_down}
          >
            <div className="row text-right align-items-center">
              <div className="col blur_on_discreet">
                <h2 className={'text-nowrap'}>
                  {format_money(lang, profit, currency, 2)}
                </h2>
              </div>
              <div className="col-12">
                <DetailsPerMarketPlace orders={orders} formula={(o) => _.sumBy(o.products, 'profit')} total={profit}
                                       initial_market_places={get_ads_per_market_place()}
                />
              </div>
            </div>
          </CompactItem>
          :
          <CompactItem className="bg-gradient_bg_loss-flat" label={<>{translate({
            fr: `Pertes`,
            en: `Loss`,
          })}</>}
                       action={break_down}
          >
            <div className="row text-right align-items-center">
              <div className="col blur_on_discreet">
                <h2 className={'text-nowrap'}>
                  {format_money(lang, profit, currency, 2)}
                </h2>
              </div>
              <div className="col-12">
                <DetailsPerMarketPlace orders={orders} formula={(o) => _.sumBy(o.products, 'profit')} total={profit}
                                       initial_market_places={get_ads_per_market_place()}
                />
              </div>
            </div>
          </CompactItem>
        }
          </div>
      <div className="col-12 small font-italic text-right">

        <HelpModal title={french(`Quels frais ne sont pas pris en compte ?`)}
                   small={true}
                   label={french(`Certains frais ne sont pas pris en compte`)}
                   icon={<FiAlertTriangle/>}
        >
          <div className="row">
            <div className="col-12">
              <ul>
                <li>{french(`Les frais d'abonnement à Amazon Professionnel`)}</li>
                <li>{french(`Les frais de stockage`)}</li>
                <li>{french(`Les publicités autres que "Sponsored Products"`)}</li>
              </ul>
            </div>
          </div>
        </HelpModal>
      </div>
    </div>
  )
}



