import React, { useContext } from 'react'
import useMarketPlace, {
  compute_region_id_for_market_place,
  MarketPlaceDetails,
  useAmazonUrls,
} from '../../hooks/useMarketPlace'
import { is_ready, useCachedContext } from '../../hooks/useCachedContext'
import { section_color } from '../colors'
import { scroll_to_node } from './scroll'
import ProductPreview, { SquareVisual } from './product_preview/product_preview'
import { clean_for_label, compute_variables } from '../product/text-substitution'
import { MarketPlaceChooser, OpenedSectionContext, useProductMenu } from './edit'
import useHashParam from 'use-hash-param'
import { useHotkeys } from 'react-hotkeys-hook'

import {
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaCheck,
  FaChevronDown,
  FaChevronUp,
  FaEye,
  FaEyeSlash,
  FaHatWizard,
  FaPaperPlane,
  FaPlay, FiEdit, FiEye, FiEyeOff,
  FiToggleLeft,
  FiToggleRight,
  GiWorld, MdAdd, MdClearAll,
  MdContentCopy,
  MdImportExport,
  MdMoreHoriz,
  MdPlaylistAddCheck,
  MdSearch,
  MdTranslate,
} from 'react-icons/all'
import {
  GlobalProgressButton,
  ProgressBadge,
  ShowGlobalProgress,
  ShowGlobalProgressWithProductLoaded,
} from '../product/progress'
import PublishProductV2, { has_unpublished_changes } from './publish_product_v2'
import DropdownD, {
  DropdownDivider, DropdownHeader,
  DropdownItem,
  DropdownItemExternalLink,
  DropDownModal, DropdownProductSelector,
} from '../../components/generic/dropdown'
import ProductTranslater from './i18n/product_translater_modal'
import { FaAmazon, FaSearchDollar } from 'react-icons/fa'
import { useFrench, useTranslate } from '../../context/lang'
import ExternalLink from '../../components/generic/external-link'
import { BackTo } from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import FlatChoice from '../../components/generic/flat_choice'
import { MdClose, MdHelp, MdSettings, MdWarning } from 'react-icons/md'
import { Tooltip } from 'react-tippy'
import { useWindowSize } from '../../hooks/useWindowSize'
import Button from '../../components/generic/button'
import TextButton from '../../components/generic/text_button'
import { IoMdAddCircleOutline, IoMdEye } from 'react-icons/io'
import { useIsMobile, useIsVerySmall } from '../../components/navigation/left-menu'
import { fixed } from 'lodash/fp/_falseOptions'
import StartHere from './start_here'
import useLocalStorage from '../../hooks/useLocalStorage'
import CopyButton, { DarkSmallCopyButton, TextCopyButton } from '../../components/generic/copy-button'
import MagicButton from './magic_button'
import { ProductFooter, useOpenedSection } from './product_footer'
import { useLocation } from '@reach/router'
import UserContext from '../../context/user'
import { extract_params } from '../../components/layout/tabs_util'
import { SecondaryMenuItem } from './product_menu'
import { navigate } from '../../absolute_link'
import { designer_context_for_product, DesignerContext } from '../../components/visual/designer_context'
import { ActiveFreeTrial } from '../../components/subscription/start_free_trial'
import { get_edit_product_route, navigate_to_product } from '../routes'
import safe from '../product/safe'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { AddVariation } from './edit_product_global_properties'
import { Detail } from '../ppc/ppc_v2/campaign_results'
import { UpdatePrice } from '../../components/product/update_price'
import { UpdateSKUAsinVariation } from '../../components/product/update_sku_asin_variation'
import { ItemTitle } from '../../components/generic/item'
import { useProduct } from '../../context/products'
import InternalLink from '../../components/generic/internal-link'
import NavigationButton from '../../components/generic/navigation-button'
import { UpdateCostV2 } from '../../components/pricing/update_cost'


const LatestProductsDropdown = () => {
  const user = useContext(UserContext)
  const french = useFrench()
  const location = useLocation()

  const path = location.pathname

  const [latest_products, set_latest_products] = useLocalStorage(`latest_products_${user.user.user_id}`, [])

  const { product_id } = extract_params(path, '/product/edit/:product_id', '')

  React.useEffect(() => {
    const is_product_path = /\/product\/edit\//.test(path)
    if (product_id && is_product_path) {
      set_latest_products([product_id, ...latest_products.filter((id) => id !== product_id)])
    }
  }, [product_id, path, set_latest_products])

  const on_error = (product_id, error) => {
    console.log('on_error from LatestProductsDropdown', { product_id, error })
    if (product_id) {
      set_latest_products([...latest_products.filter((id) => id !== product_id)])
    }
  }

  if (latest_products.length === 0) {
    return null
  }

  return <>
    <DropdownHeader>{french(`Produits récents`)}</DropdownHeader>
    <DropdownDivider/>
    {latest_products.filter((p, i) => i < 8 && p !== product_id).map((product_id) => {
      return <DropdownProductSelector key={product_id} product_id={product_id} on_error={on_error}/>
    })}
    <DropdownDivider/>
    <DropdownItem on_click={() => {
      set_latest_products([])
    }} icon={<MdClearAll/>} label={french(`Vider la liste`)}/>
  </>
}

export const ProductPresentationByProductIdMarketPlace = ({ product_id, parent_product_id, market_place, action }) => {
  const product = useProduct(product_id, market_place)
  const parent_product = useProduct(parent_product_id, market_place)

  if (product.loading || parent_product.loading) {
    return <DefaultLoading/>
  }

  if (product.error || parent_product.error) {
    return <DefaultError/>
  }

  return <ProductPresentation product={product} parent_product={parent_product_id ? parent_product : null}
                              action={action ? action(product, parent_product_id ? parent_product : null, market_place) : null}/>
}

export const ProductPresentation = ({ product, parent_product, action, checkbox, hide_edit }) => {
  const french = useFrench()

  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)
  const first_visual = useCachedContext('product_with_single_visual', is_ready(product) ? product.product_id : null, region_id, 0)

  const snippets = useCachedContext('snippets')

  const has_variations = product.variations && product.variations.length > 0

  if (snippets.loading || product.loading) {
    return <DefaultLoading/>
  }

  if (snippets.error || product.error) {
    return <DefaultError/>
  }

  if (!is_ready(product)) {
    return <DefaultLoading/>
  }

  return <div className="row align-items-center no-gutters">
    {checkbox ? <div className="col-auto">
      {checkbox}
    </div> : null}
    <div className="col-auto mr-2">
      <div style={{
        background: 'white',
        overflow: 'hidden',
        borderRadius: '5px',
        width: 42,
        height: 42,
      }} className={'rounded shadow-sm BLUR_FOR_WEBINAR'}>
        <DesignerContext.Provider value={designer_context_for_product(product)}>
          <SquareVisual visual={first_visual} width={42} reduce_image_size={true}/>
        </DesignerContext.Provider>
      </div>
    </div>
    <div className="col BLUR_FOR_WEBINAR">
      <div
        className={'font-weight-bold'}>{clean_for_label(safe(product, 'product_info', 'title') || safe(parent_product, 'product_info', 'title'), 80, compute_variables(product, snippets))}</div>
      <div className={'small'}>
        {has_variations ? <span className="badge badge-success mr-2">{french(`Produit parent`)}</span> : null}
        {parent_product ? <span className="badge badge-info mr-2">{french(`Variation`)}</span> : null} <span
        className="badge badge-secondary mr-2">{product.product_info && product.product_info.sku_override ? product.product_info.sku_override : product.sku}
        </span> <span
        className="badge badge-secondary mr-2">{product.product_info && product.product_info.asin_override ? product.product_info.asin_override : product.asin}</span>
        {product.product_info && product.product_info.variation && product.product_info.variation !== 'n/a' ?
          <span className="badge badge-secondary mr-2">{clean_for_label(product.product_info.variation)}</span> : null}
        {hide_edit ? null :
          <UpdateSKUAsinVariation product={product} parent_product={parent_product} market_place={market_place}/>}
      </div>
    </div>
    {action ? <div className="col-auto">
      {action}
    </div> : null}
    <div className="col-12 mb-2">
      <hr className={'my-1'}/>
    </div>
  </div>
}

export const ProductPresentationComponent = ({
                                               product,
                                               variations,
                                               action,
                                               checkbox,
                                               hide_edit,
                                             }) => {
  const french = useFrench()

  const { first_visual, title, asin,  sku } = product

  return <div className="row align-items-center no-gutters">
    {checkbox ? <div className="col-auto">
      {checkbox}
    </div> : null}
    <div className="col-auto mr-2">
      <div style={{
        background: 'white',
        overflow: 'hidden',
        borderRadius: '5px',
        width: 42,
        height: 42,
      }} className={'rounded shadow-sm BLUR_FOR_WEBINAR'}>
        <DesignerContext.Provider value={designer_context_for_product(product)}>
          <SquareVisual visual={first_visual} width={42} reduce_image_size={true}/>
        </DesignerContext.Provider>
      </div>
    </div>
    <div className="col BLUR_FOR_WEBINAR">

      <div className={'small'}>
        {variations && variations.length > 1 ? <>
          <span className="badge badge-info">{variations.length} {french(`variation(s)`)}</span>
          {variations.map(({ title, sku, asin }, i) => {
            return <div className="small font-weight-bold">
              <span className="badge badge-secondary mr-2">{asin}</span> {clean_for_label(title, 40)}
            </div>
          })}
        </> : <>
          <div
            className={'font-weight-bold'}>{clean_for_label(title, 80)}</div>
          <span className="badge badge-secondary mr-2">{sku}</span>
          <span className="badge badge-secondary mr-2">{asin}</span>
        </>}
      </div>
    </div>
    {action ? <div className="col-auto">
      {action}
    </div> : null}
    <div className="col-12 mb-2">
      <hr className={'my-1'}/>
    </div>
  </div>
}

export const DisplayVariations = ({ product, variation_mapper, inherit, initial_limit = 3, hide_add_variation = false }) => {
  const translate = useTranslate()
  const french = useFrench()

  const [limit, set_limit] = React.useState(inherit ? 0 : initial_limit)
  const has_variations = product.variations && product.variations.length > 0

  return <div className="row  mt-5">
    <div className="col-12">
      <ItemTitle action={!hide_add_variation ? <AddVariation product={product}/> : null}>
        {translate({
          fr: `Variations`,
          en: `Variations`,
        })} <span className="small">({product.variations.length})</span>
      </ItemTitle>
    </div>

    {inherit && has_variations ? <div className="col-12">
      <div className="alert alert-info small">
        <div className="row">
          <div className="col-12 mb-2 font-weight-bold">
            {translate({
              fr: `Les valeurs du produit parent sont automatiquement appliquées aux variations.`,
              en: `The values from the parent product are automatically applied to the variations.`,
            })}
          </div>
          {limit === 0 ? <div className="col-12">
            <Button className="btn-sm" onClick={() => set_limit(initial_limit)}>
              <FiEdit/> {french(`Je veux modifier les valeurs individuellement pour les variations`)}
            </Button>
          </div> : null}
        </div>
      </div>
    </div> : null}

    {has_variations ? <div className="col-12">
      {product.variations.filter((_, i) => i < limit)
        .map((variation) => <div className="row mb-2"
                                 key={variation.product_id}>
          <div className="col-12">
            {variation_mapper(variation, product)}
          </div>
        </div>)}

      {!( inherit && limit === 0 ) && ( limit < product.variations.length ) ? <Button onClick={() => set_limit(999)}>
        <FiEye/> {french(`Voir toutes les variations`)}
      </Button> : null}
      {limit > initial_limit ? <Button className="btn-sm" onClick={() => set_limit(initial_limit)}>
        <FiEyeOff/> {french(`Voir moins de variations`)}
      </Button> : null}
    </div> : <div className="col-12 small font-italic">
      {french(`Ce produit n'a aucune variation pour le moment`)}
    </div>}
  </div>
}


export default function ProductHeader({ product, selected_product }) {
  const market_place = useMarketPlace()
  const region_id = compute_region_id_for_market_place(market_place)
  const first_visual = useCachedContext('product_with_single_visual', product.product_id, region_id, 0)
  const amazon_accounts = useCachedContext('amazon_accounts')
  const translate = useTranslate()
  const french = useFrench()

  const [current_menu, set_current_menu] = useHashParam('menu', 'preview')
  const is_not_published = has_unpublished_changes(product)

  const [opened_section, set_opened_section] = useOpenedSection()

  const amazon_urls = useAmazonUrls()

  const menu = useProductMenu(product, null, amazon_accounts && amazon_accounts.length === 1)
  const current_menu_item = menu.find((menu_item) => menu_item.key === current_menu)

  const is_very_small = useIsVerySmall()
  const [show_if_very_small, set_show_if_very_small] = React.useState(false)

  const more = <DropdownD label={french(`...`)}>
    {menu.map(({ label, background, color, Icon, key, menu, tooltip, on_click, overflow }, i) => {

      if (!overflow) {
        return null
      }

      return <DropdownItem icon={<Icon/>} label={label} on_click={() => {
        set_current_menu(key)

        set_show_if_very_small(false)
        setTimeout(() => {
          scroll_to_node(`product_header_nav`)
        }, 100)
      }
      }/>
    })
    }

    <DropdownDivider/>
    <DropDownModal
      label={french('Traduire depuis une autre marketplace')}
      icon={<MdTranslate/>}
      Modal={ProductTranslater}
      modal_props={{
        product,
        title: french('Traduire depuis une autre marketplace'),
        initial_choice: 'translate_other',
      }}/>
    <DropDownModal
      label={french('Démarrer depuis un autre produit')}
      icon={<MdContentCopy/>}
      Modal={MagicButton}
      modal_props={{
        product,
      }}/>
    <DropDownModal
      label={french('Importer depuis Amazon')}
      icon={<MdImportExport/>}
      Modal={ProductTranslater}
      modal_props={{
        product,
        title: french('Importer depuis Amazon'),
        initial_choice: 'import',
      }}/>

    {selected_product.asin ? <>

      <DropdownDivider/>

      <DropdownItemExternalLink
        to={amazon_urls[ market_place ].edit(selected_product.product_info.asin_override || selected_product.asin, selected_product.product_info.sku_override || selected_product.sku)}
        icon={<FaAmazon/>}
        label={translate({
          fr: `Éditer sur le Seller Central`,
          en: `Edit on Seller Central`,
        })}
      />
      <DropdownItemExternalLink
        to={amazon_urls[ market_place ].public(selected_product.product_info.asin_override || selected_product.asin)}
        icon={<FaAmazon/>}
        label={translate({
          fr: `Voir sur Amazon`,
          en: `See on Amazon`,
        })}
      />
    </> : null}
  </DropdownD>
  const top_menu_bar = <div className="row no-gutters align-items-center">
    {menu.map(({ label, background, color, Icon, key, menu, tooltip, on_click, overflow }, i) => {

      if (overflow) {
        return null
      }

      const { total, has_progress } = menu.reduce(({ total, has_progress }, { progress }) => {
        if (typeof progress === 'number') {
          return {
            total: total + progress,
            has_progress: true,
          }
        }
        return { total, has_progress }
      }, { total: 0, has_progress: false })

      let average
      if (has_progress) {
        average = total / menu.length
      }

      const is_current = key === current_menu
      const show_tooltip = !is_current && !!tooltip && current_menu === 'preview'
      return <>
        <div className={`col-auto mr-2`} key={i}>
          <FlatChoice is_current={is_current} on_click={() => {
            set_current_menu(key)
            set_show_if_very_small(false)

            setTimeout(() => {
              scroll_to_node(`product_header_nav`)
            }, 100)
          }} className={'mb-1 mt-1'}>
            {/*<div className="d-inline-block mr-1"*/}
            {/*     style={{ background, width: 10, height: 10, borderRadius: '100%', content: '' }}></div>*/}
            {show_tooltip ?
              <Tooltip arrow={true} theme={'light'} position={'top-start'} html={tooltip}
                       open={true}>{label}</Tooltip> : label}
          </FlatChoice>
        </div>
      </>
    })}
    <div className="col-auto">
      <FlatChoice>{more}</FlatChoice>
    </div>
  </div>


  return (
    <>
      <BackTo to={'/product'} label={<>
        {french('Retour à la liste des produits')}
      </>}
      />
      <Title color={section_color('product')} style={{ color: 'white' }} h={'h2'}
             margin={'mb-3'}
             details={<ActiveFreeTrial/>}
        // details={is_published ?
        //   <span className="badge" style={{ color: 'white' }}><FaCheck/> {french(`Publié`)}</span> :
        //   <span className="badge"
        //         style={{ color: 'white' }}><MdWarning/> {french(`Tu as des changements non publiés`)}</span>}
      >
        <div className="row align-items-center no-gutters BLUR_FOR_WEBINAR">
          <div className="col-auto mr-2">
            <div style={{
              background: 'white',
              overflow: 'hidden',
              borderRadius: '5px',
              width: 75,
              height: 75,
            }}>
              <DesignerContext.Provider value={designer_context_for_product(product)}>
                <SquareVisual visual={first_visual} width={75} reduce_image_size={true}/>
              </DesignerContext.Provider>
            </div>
          </div>
          <div className="col">
            {clean_for_label(product.product_info.title, 80, [])}
          </div>
        </div>
      </Title>
      <a id={'product_header_nav'} name={'product_header_nav'}></a>
      {!is_very_small || show_if_very_small ?
        <div className="row" id={'sticky_product_header'}
             style={{ position: 'sticky', top: 0, background: 'var(--color-bg-global)', zIndex: 11 }}>
          <div className="col-12">
            <div className="row pt-2 pb-2 align-items-center">
              <div className="col-12 col-md">
                <div className="row no-gutters align-items-center">
                  <div className="col-12 col-md-auto">
                    <DropdownD
                      label={<><MarketPlaceDetails market_place={market_place}/><ShowGlobalProgressWithProductLoaded
                        product={product} show_tooltip={false}/></>} className={'mr-2'}>
                      {product.market_places.map((other_market_place) => {
                        if (other_market_place === market_place) {
                          return null
                        }
                        return <>
                          <DropdownItem on_click={() => navigate_to_product(product.product_id, other_market_place)}
                                        label={<>
                                          <MarketPlaceDetails market_place={other_market_place}/> <ShowGlobalProgress
                                          product_id={product.product_id} market_place={other_market_place}
                                          show_tooltip={false}/>
                                        </>}></DropdownItem>
                          <DropdownDivider/>
                        </>
                      })}
                      <DropDownModal label={french('Modifier le produit sur une autre marketplace')}
                                     icon={<IoMdAddCircleOutline/>}
                                     Modal={MarketPlaceChooser}
                                     modal_props={{ product_id: product.product_id }}/>
                      <DropdownDivider/>
                      <LatestProductsDropdown/>
                      <DropdownDivider/>
                      <DropdownItem icon={<FaArrowLeft/>} label={french(`Retour à la liste des produits`)}
                                    on_click={() => navigate('/product')}/>

                    </DropdownD>
                  </div>
                  <div className="col-12 col-md-auto">
                    {top_menu_bar}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-auto text-right">
                <Button onClick={() => set_opened_section('preview')}
                        outline={!is_not_published}><FaPaperPlane/> {french(`Prévisualiser / publier`)}
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <hr className={'m-0'}/>
          </div>
          <div className="col-12">
            <FastAccessForMenu menu_item={current_menu_item} onClick={() => set_show_if_very_small(false)}/>
          </div>
          {is_very_small ? <div className="col-12 text-center py-1" style={{ height: '100vh' }}
                                onClick={() => set_show_if_very_small(false)}>
            <TextButton onClick={() => set_show_if_very_small(false)}><FaChevronUp/> {french(`Fermer le menu`)}
            </TextButton>
          </div> : null}
        </div> :
        <div className="row" id={'sticky_product_header'}
             style={{ position: 'sticky', top: 0, background: 'var(--color-bg-global)', zIndex: 11 }}>
          <div className="col-12 text-center py-1">
            <TextButton onClick={() => set_show_if_very_small(true)}><FaChevronDown/> {french(`Ouvrir le menu`)}
            </TextButton>
          </div>
          <div className="col-12">
            <hr className={'m-0'}/>
          </div>
        </div>
      }
    </>
  )
}
const FastAccessForMenu = ({ menu_item, onClick }) => {

  const [closest_sub_menu, set_closest_sub_menu] = React.useState(0)

  const on_scroll = React.useCallback(() => {
    const elements = document.querySelectorAll('.section_anchor')

    const { closest } = ( [...elements] ).reduce(({ closest, value }, sub_menu_element, i) => {
      if (sub_menu_element) {
        const sub_menu_position = sub_menu_element.getBoundingClientRect()
        if (Math.abs(sub_menu_position.y) < Math.abs(value)) {
          return {
            closest: i,
            value: sub_menu_position.y,
          }
        }
      }
      return { closest, value }
    }, { closest: -1, value: 99999 })

    set_closest_sub_menu(closest)

  }, [])

  React.useEffect(() => {
    const element = document.querySelector('.main-content-scrollable')

    element.addEventListener('scroll', on_scroll)

    return () => {
      if (element) {
        element.removeEventListener('scroll', on_scroll)
      }
    }
  }, [])
  if (!menu_item || menu_item.menu.length < 2) {
    return null
  }
  return <div className="row no-gutters">
    {menu_item.menu.map(({ label, key, progress }, i) => {
      return <div className={`col-auto mr-1 py-1`} key={i}>
        <FlatChoice on_click={() => {
          setTimeout(() => scroll_to_node(`${menu_item.key}-${key}`), 250)
          if (onClick) {
            onClick()
          }
        }
        } is_current={i === closest_sub_menu} className={'p-1 font-weight-light small'}>
          {label}
        </FlatChoice>
      </div>
    })}
    <div className="col-12">
      <hr className={'m-0'}/>
    </div>

  </div>


}



