import { useDesignerContext } from '../designer_context'
import React from 'react'
import { compute_rect_style, GlobalControl } from './rect_controls'

const compute_object_dimensions = (object) => {
  if (object.type === 'arrow') {
    const x = Math.min(object.props.end_x, object.props.start_x)
    const y = Math.min(object.props.end_y, object.props.start_y)
    const height = Math.abs(object.props.start_y - object.props.end_y)
    const width = Math.abs(object.props.start_x - object.props.end_x)
    return {
      x, y, width, height,
    }
  }
  return object
}

const compute_box = (selected_objects) => {
  const first_object = compute_object_dimensions(selected_objects[0])
  const box = selected_objects.reduce((box, object) => {
    object = compute_object_dimensions(object)
    if (object.x < box.x) {
      box.x = object.x
    }
    if (object.y < box.y) {
      box.y = object.y
    }
    if (object.x + object.width > box.max_x) {
      box.max_x = object.x + object.width
    }
    if (object.y + object.height > box.max_y) {
      box.max_y = object.y + object.height
    }
    return box
  }, {
    x: first_object.x,
    y: first_object.y,

    max_x: first_object.x + first_object.width,
    max_y: first_object.y + first_object.height,
  })

  return {
    x: box.x,
    y: box.y,
    width: box.max_x - box.x,
    height: box.max_y - box.y,
  }
}
export const MultipleSelectionControl = ({ scale, selected_objects, controller_ref }) => {

  const { are_all_selected_objects_grouped } = useDesignerContext()

  if (selected_objects.length < 2 || !are_all_selected_objects_grouped()) {
    return null
  }

  const computed_rectangle = compute_box(selected_objects)

  const reversed_scale = 1 / scale
  const px = (p) => `${( reversed_scale * p )}px`

  return (
    <div className={`position-absolute group_control  is_selected`}
         style={{
           ...compute_rect_style(computed_rectangle),
           background: 'transparent',
           filter: '',
           opacity: 1,
           overflow: 'visible',
           border: `${px(4)} dotted blue`,
         }}
    >
      <GlobalControl scale={scale} controller_ref={controller_ref}/>
    </div>
  )
}
