import { wrap_snippet } from '../../components/snippet/format'

export const apply_variables = (text, variables) => {
  if (!text || !text.replace) {
    return text || ''
  }
  return variables.reduce((text, { key, value }) => {
    const regexp = new RegExp(key, 'g')
    return text.replace(regexp, value || key)
  }, text)
}

export const parse_text = (text) => {
  let rest = text
  const output = []
  while (rest) {
    const next_var = rest.match(/%[^\s^%]+%/)
    if (!next_var) {
      output.push({ text: rest })
      rest = ''
      continue
    }

    const variable = next_var[ 0 ]
    const variable_index = rest.indexOf(variable)
    const text = rest.substring(0, variable_index)
    if (text) {
      output.push({ text })
    }
    output.push({ variable })
    rest = rest.substring(variable_index + variable.length)
  }

  return output
}

export const apply_variables_to_parsed_text = (parsed_text, variables, remove_html, selected_keywords) => {

  let current_index = 0
  return parsed_text.map(({ text, variable }) => {
    let returned_text = ''

    if (!variable) {
      returned_text = text
    } else {
      const found_value = variables.find(({ key }) => key === variable)
      if (found_value) {
        returned_text = found_value.value || ''
      }
    }

    if (remove_html) {
      /*

    ' is replaced with &apos;
    " is replaced with &quot;
    & is replaced with &amp;
    < is replaced with &lt;
    > is replaced with &gt;

       */
      returned_text = returned_text.replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    }

    const returned = {
      text: returned_text,
      index: current_index,
    }
    if (variable) {
      returned.variable = variable
    }

    current_index += returned.text.length
    return returned
  })
}

export const over_text = (text, index, limit) => {
  if (index + text.length <= limit) {
    return false
  }

  const threshold = limit - index
  const ok = text.substring(0, threshold)
  const over = text.substring(threshold)

  return { ok, over }
}

export const remove_html = (text) => {
  const returned = text && text.replace ? text.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ') : text || ''

  if (returned.replace) {
    return returned
      .replace(/&amp;/g,'&')
      .replace(/&lt;/g,'<')
      .replace(/&gt;/g,'>')
  }

  return returned
}

export const clean_for_label = (text, limit, variables = [], suffix = '...') => {
  text = apply_variables(text, variables).replace(/%[^\s^%]+%/g, '')
  text = remove_html(text)

  if (text.length > limit) {
    return text.substring(0, limit) + suffix
  }

  return text
}

export const clean_for_keyword_analysis = ({ text, limit, variables, is_html_authorized }) => {
  text = apply_variables(text, variables).replace(/%[^\s^%]+%/g, '')
  text = is_html_authorized ? text : remove_html(text)

  return text.substring(0, limit)
}

export const compute_variables = (product, snippets) => {
  if ((product.product_info && product.product_info.variation) || (product.variations && product.variations.length > 0)) {
    return [{
      key: '%VARIATION%',
      value: product.product_info ? product.product_info.variation : ' ',
      help: 'Nom de la variation'
    }, {
      key: '%VARIATION_UPPER_CASE%',
      value: product.product_info && product.product_info.variation ? product.product_info.variation.toUpperCase() : '',
      help: 'Nom de la variation en majuscule'
    },
      ...snippets.map(({ code, content_fr, snippet_id }) => ( {
        key: wrap_snippet(code),
        value: content_fr.content,
        help: `Variable personnalisée`,
        snippet_id
      } )),
    ]
  }

  return [
    ...snippets.map(({ code, content_fr, snippet_id }) => ( {
      key: wrap_snippet(code),
      value: content_fr.content,
      help: `Variable personnalisée`,
      snippet_id
    } )),
  ]

}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
