import React from 'react'
import BackToHome from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import BigBox from '../../components/layout/big_box'
import ExternalLinkButton from '../../components/generic/external-link-button'
import Button, { IconLabel } from '../../components/generic/button'
import { section_color, WelcomeIcon } from '../colors'
import { useFrench, useLang, useTranslate } from '../../context/lang'

import build_product from '../../images/story/build_product.svg'
import { FaAmazon, FaArrowRight, FiHelpCircle, FiMessageCircle, FiUsers } from 'react-icons/all'
import IntellifoxIcon from '../../images/intellifox-icon'
import Item, { CompactItem } from '../../components/generic/item'
import { useMarketPlaceDetails, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { FrenchContent, YouTube } from '../onboarding/onboarding_master'
import { onboarding_videos_fr, useOnboardingVideos } from '../onboarding/onboarding_videos'


const Video = ({ title, youtube_id }) => {

  return <CompactItem label={title}>
    <YouTube youtubeId={youtube_id}/>
  </CompactItem>
}


export default function Help() {
  const translate = useTranslate()
  const french = useFrench()

  const source_market_place = useSourceMarketPlace()
  const market_place_details = useMarketPlaceDetails(source_market_place)
  const lang = useLang()

  const onboarding_videos = useOnboardingVideos()

  return (
    <div className="container">
      <BackToHome/>
      <Title color={section_color('help')} style={{ color: 'white' }}><WelcomeIcon section={'help'}/> {translate({
        fr: `Aide`,
        en: `Help`,
      })}</Title>

      {/*<div className="row mb-3">*/}
      {/*  <div className="col-12">*/}
      {/*    <div className="alert alert-success">*/}
      {/*      <div className="row align-items-center">*/}
      {/*        <div className="col-12 col-md-9">*/}
      {/*          <h1>Besoin d'aide pour démarrer ?</h1>*/}
      {/*          <ExternalLinkButton to={'https://support.intellifox.com'} className={"btn-lg"}><FaArrowRight/> {translate({*/}
      {/*            fr: `Voir les vidéos de démarrage`,*/}
      {/*            en: `Browse documentation`,*/}
      {/*          })}</ExternalLinkButton>*/}
      {/*        </div>*/}
      {/*        <div className="col-12 col-md-3">*/}
      {/*          <img src={build_product} alt=""/>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}


      <BigBox title={translate({
        fr: `Besoin d'aide avec Amazon ?`,
        en: 'Need help with Amazon?',
      })}>
        <div className="row">
          <div className="col-12 col-md-6">
            <ExternalLinkButton to={`${market_place_details.seller_central_url}/help/hub/support/describe`}
                                className="btn-lg">
              <IconLabel icon={<FaAmazon/>}>{translate({
                fr: `Créer un cas sur le Seller Central`,
                en: `Create a case on Seller Central`,
              })}
              </IconLabel>
            </ExternalLinkButton>
          </div>
        </div>
      </BigBox>

      <BigBox title={french(`Prise en main du logiciel`)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Video youtube_id={onboarding_videos.welcome} title={french(`Bienvenue !`)}/>
          </div>
          <div className="col-12 col-md-6">
            <Video youtube_id={onboarding_videos.product_optimization}
                   title={french(`Comment optimiser une fiche produit ?`)}/>
          </div>
          <div className="col-12 col-md-6">
            <Video youtube_id={onboarding_videos.image_editor}
                   title={french(`Comment utiliser l'éditeur d'image ?`)}/>
          </div>
          <div className="col-12 col-md-6">
            <Video youtube_id={onboarding_videos.profit_calculator} title={french(`Comment calculer sa marge ?`)}/>
          </div>
          <div className="col-12 col-md-6">
            <Video youtube_id={onboarding_videos.ppc_conversion} title={french(`Comment optimiser ses campagnes manuelles Amazon Ads ?`)}/>
          </div>
        </div>
      </BigBox>

      <BigBox title={translate({
        fr: `Besoin d'aide avec Intellifox ?`,
        en: 'Need help with Intellifox?',
      })}>
        <div className="row">
          <div className="col-12">
            <h4>{translate({
              fr: `3 façons de trouver la solution`,
              en: `3 ways to solve you problem`,
            })}</h4>
          </div>
        </div>

        <div className="row">

          <div className="col-12 col-md-4 mb-2">
            <div>
              <div className="row">

                <div className="col-12">
                  <ExternalLinkButton to={'https://support.intellifox.com'}>
                    <IconLabel icon={<FiHelpCircle/>}>
                      {translate({
                        fr: `Trouver la solution dans le centre d'aide`,
                        en: `Find the solution in the help center`,
                      })}
                    </IconLabel>
                  </ExternalLinkButton>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-2">
            <div>
              <div className="row">

                <div className="col-12">
                  <ExternalLinkButton
                    to={lang === 'fr' ? 'https://community.intellifox.com/c/francais/16' : 'https://community.intellifox.com/c/english/17'}>
                    <IconLabel icon={<FiUsers/>}>
                      {translate({
                        fr: `Demander à la communauté des Intellinautes`,
                        en: `Ask the Intellinaut's community`,
                      })}
                    </IconLabel>
                  </ExternalLinkButton>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-2">
            <div>
              <div className="row">

                <div className="col-12">
                  <Button onClick={() => window.$crisp.push(['do', 'chat:open'])}>
                    <IconLabel icon={<FiMessageCircle/>}>
                      {translate({
                        fr: `Démarrer une conversation avec nous`,
                        en: `Start a conversation with us`,
                      })}
                    </IconLabel>
                  </Button>
                </div>
                <div className="col-12">
                  {translate({
                    fr: `ou par email à `,
                    en: `or send us an email at`,
                  })} <a href="mailto:support@intellifox.com">support@intellifox.com</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </BigBox>


    </div>
  )
}
