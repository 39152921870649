import React from 'react'
import ExternalLink from '../../../generic/external-link'
import { useFrench } from '../../../../context/lang'

export default function PixabaySearchBar({ update_query, current_query = '' }) {
  const [query, set_query] = React.useState(current_query)
  const french = useFrench()

  const timeout_ref = React.useRef(-1)
  React.useEffect(() => {
    clearTimeout(timeout_ref.current)
    timeout_ref.current = setTimeout(() => {
      update_query(query)
    }, 500)
  }, [query])

  return (
    <div className="row pt-2 mb-2">
      <div className="col-12">
        <input type="text" className="w-100" placeholder={french('Rechercher une image')} value={query}
               onChange={(e) => set_query(e.target.value)}/>
      </div>
      {query ? <>
        <div className="col-12 text-center mt-2">
          <div className="alert alert-success small">
            {french(`Les images ci-dessous sont utilisables sur Amazon, sauf si une marque existante est présente sur l'image. Résultats fournis par Pixabay.`)}
          </div>
        </div>
      </> : null}
    </div>
  )
}
