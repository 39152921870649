import { create_query } from './_query_and_mutations_templates'

export const GET_TRANSLATION = `
  query Translation($company_id: ID!, $source_lang: String, $target_lang: String, $text: String, $keep: [String]) {
    translation(
      company_id:$company_id,
      source_lang:$source_lang,
      target_lang:$target_lang,
      text:$text,
      keep:$keep,
) {
      translated_text
    }
  }
`


export const translate_interface_query = create_query({
  query: 'translate_interface',
  params: {
    source_lang: 'String',
    target_lang: 'String',
    text: 'String',
  },
})


export const GET_TRANSLATIONS = `
  query Translations($company_id: ID!, $source_lang: String, $target_lang: String, $texts: [String]) {
      translations(
        company_id:$company_id,
        source_lang:$source_lang,
        target_lang:$target_lang,
        texts:$texts,
  )
}
`

export const GET_EXCHANGE_RATE = `
  query ExchangeRate($company_id: ID!, $from_currency: String, $to_currency : String) {
    exchange_rate(
      company_id:$company_id,
      from_currency:$from_currency,
      to_currency:$to_currency
)

}
`
