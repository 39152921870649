import React from 'react'
import UserContext from '../context/user'

export const useExternalDependencies = () => {
  /*
  CRISP

      <script type="text/javascript">

      (function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
      </script>
   */

  useExternalDependency({
    before: () => {
      window.$crisp = []
      window.CRISP_WEBSITE_ID = '1749a2cd-031f-41e1-97c3-4c1f1d788bbf'
    },
    src: 'https://client.crisp.chat/l.js',
  })

  /*
  GOOGLE
  <script async src="https://www.googletagmanager.com/gtag/js?id=UA-157726851-1"></script>
    <script>
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'UA-157726851-1');
    </script>
   */

  useExternalDependency({
    before: () => {
      window.dataLayer = window.dataLayer || []

      window.gtag = function() {
        window.dataLayer.push(arguments)
      }

      window.gtag('js', new Date())

      window.gtag('config', 'AW-10960420121')
    },
    src: 'https://www.googletagmanager.com/gtag/js?id=AW-10960420121',
  })

  /*
  PADDLE
      <script src="https://cdn.paddle.com/paddle/paddle.js"></script>
    <script type="text/javascript">
      Paddle.Setup({ vendor: 111024 });
    </script>

   */

  // const user = React.useContext(UserContext)

  useExternalDependency({
    src: 'https://cdn.paddle.com/paddle/paddle.js',
    after: () => {
      window.Paddle.Setup({
        vendor: 111024,
        // eventCallback: function(data) {
        //   // user.refresh()
        //
        // }
      })
    },
  })

  /*
  FACEBOOK
      <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '470873107208694');
      fbq('track', 'PageView');
    </script>
    <noscript>
      <img height="1" width="1"
           src="https://www.facebook.com/tr?id=470873107208694&ev=PageView
&noscript=1"/>
    </noscript>
   */


  /*
  Intellifox


    <script>
      window.intellifox_utm_override = {
        utm_source: 'studio_direct_access'
      }
    </script>
    <script src="%PUBLIC_URL%/u_u_v1.js"></script>

    <!-- https://intellifox.net/studio/u_u_v1.js-->
    <!-- https://intellifox.net/studio/u_r_v2.js-->
<!--
<script>
  window.override_referral_id = 'Rx6PPB2QPcTCzd3EngYX'
</>script>
<script src="https://intellifox.net/studio/u_r_v2.js"></script>
-->
    <script src="%PUBLIC_URL%/u_r_v2.js"></script>
   */

  useExternalDependency({
    before: () => {
      window.intellifox_utm_override = {
        utm_source: 'studio_direct_access',
      }
    },
    src: 'https://app.intellifox.com/u_u_v1.js',
  })
  useExternalDependency({
    src: 'https://app.intellifox.com/u_r_v2.js',
  })
}

export const useExternalDependency = ({ before, src, after }) => {
  React.useEffect(() => {
    const script = document.createElement('script')

    if (before) {
      before()
    }

    script.src = src
    script.async = true
    script.addEventListener('load', () => {
      if (after) {
        after()
      }
    })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
}
