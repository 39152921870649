import React from 'react'

import './button.css'

export default function ExternalLink({ children, className = '', to, style = {}, open_in_new_window = true }) {
  if (!to) {
    return children
  }
  return (
      <a href={to} target={open_in_new_window ? '_blank':''} className={`external_link ${className}`} style={style}>{children}</a>
  )
}
