import moment from 'moment'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../i18n/translations'
import React from 'react'
import { useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import safe from '../../routes/product/safe'


export const useProductPricingDetails = ({ product, market_place, price, parent_product }) => {
  const sold_by = useCachedContext('sold_by', parent_product ? parent_product.product_id : product.product_id)
  const amazon_accounts = useCachedContext('amazon_accounts')
  const { commission_vat, currency, currency_symbol } = useMarketPlaceDetails(market_place)

  if (!product) {
    return {
      error: true,
    }
  }

  if (sold_by.loading || amazon_accounts.loading) {
    return {
      loading: true,
    }
  }

  if (sold_by.error) {
    return {
      error: true,
    }
  }

  const sold_by_for_this_market_place = sold_by.find((s) => s.market_place === market_place)
  const amazon_account_for_sold_by = sold_by_for_this_market_place ? amazon_accounts.find((a) => a.merchant_id === sold_by_for_this_market_place.merchant_id) : null

  const {
    is_auto_entrepreneur,
    cfp,
    tfc,
    is_versement_liberatoire,
    taux_cotisation,
  } = amazon_account_for_sold_by && amazon_account_for_sold_by.auto_entrepreneur_config ? amazon_account_for_sold_by.auto_entrepreneur_config : {}

  const is_not_vat_registered = amazon_account_for_sold_by ? amazon_account_for_sold_by.is_not_vat_registered : false


  const price_config = {
    achat: 0,
    import: 0,
    expedition: 0,
    commission: 0,
    stockage: 0,
    fourniture: 0,
  }

  const parent_price_config = parent_product ? Object.keys(parent_product.product_info.price || {}) : []
  const product_price_config = Object.keys(product.product_info.price || {})

  for (let i = 0; i < parent_price_config.length; i++) {
    const k = parent_price_config[ i ]
    if (parent_product.product_info.price[ k ]) {
      price_config[ k ] = parent_product.product_info.price[ k ]
    }
  }
  for (let i = 0; i < product_price_config.length; i++) {
    const k = product_price_config[ i ]
    if (product.product_info.price[ k ]) {
      price_config[ k ] = product.product_info.price[ k ]
    }
  }

  const fixed_cost = compute_product_costs(product, price_config)

  const compute_vat = () => {
    return parse_nice_float(price_config.tva) / 100
  }
  const apply_vat_on_fees = (fees) => fees * ( 1 + commission_vat )
  const remove_vat = (selling_price) => selling_price / ( 1 + compute_vat() )
  const commission = (selling_price) => {

    const commision_excl_vat = ( parse_nice_float(price_config.commission) / 100 ) * selling_price

    if (is_auto_entrepreneur || is_not_vat_registered) {
      return apply_vat_on_fees(commision_excl_vat)
    }

    return commision_excl_vat
  }
  const expedition = () => {
    if (is_auto_entrepreneur || is_not_vat_registered) {
      return apply_vat_on_fees(parse_nice_float(price_config.expedition))
    }
    return parse_nice_float(price_config.expedition)
  }
  const compute_fba_fee = () => {

    if (is_auto_entrepreneur || is_not_vat_registered) {
      return apply_vat_on_fees(parse_nice_float(price_config.expedition))
    }

    return parse_nice_float(price_config.expedition)
  }

  const compute_amazon_fees = (price) => {
    const fba_fee = compute_fba_fee()
    const current_commission = commission(price)
    return fba_fee + current_commission
  }
  const amazon_fees = compute_amazon_fees(price)

  const compute_auto_entrepreneur_rate = () => {
    return parse_and_add([
      taux_cotisation,
      cfp,
      tfc,
      is_versement_liberatoire ? 1 : 0,
    ]) / 100
  }
  const compute_auto_entrepreneur_taxes = (price) => {

    return price * compute_auto_entrepreneur_rate()
  }


  const margin = (selling_price) => {
    const margin = remove_vat(selling_price) - fixed_cost - compute_amazon_fees(selling_price)
    if (is_auto_entrepreneur) {
      return margin - compute_auto_entrepreneur_taxes(selling_price)
    }
    return margin
  }
  const marginPercentage = (selling_price) => {
    return margin(selling_price) / remove_vat(selling_price)
  }


  return {
    amazon_account: amazon_account_for_sold_by || {},
    currency,
    currency_symbol,

    price,
    price_excl_vat: remove_vat(price),
    vat: compute_vat(),
    margin: margin(price),

    margin_percentage: marginPercentage(price),
    fixed_cost,
    commission: ( is_auto_entrepreneur || is_not_vat_registered ) ? apply_vat_on_fees(parse_nice_float(price_config.commission) / 100) : parse_nice_float(price_config.commission) / 100,
    expedition: expedition(),
    amazon_fees,
    amazon_fees_commission: commission(price),
    auto_entrepreneur_rate: compute_auto_entrepreneur_rate(),
    auto_entrepreneur_taxes: compute_auto_entrepreneur_taxes(price),
    is_auto_entrepreneur,
    is_not_vat_registered,
  }
}


export const parse_formula = (value) => {
  if (!value || !value.replace) {
    return 0
  }
  value = value.replace(/,/g, '.').replace(/=/g, '')
  try {
    const returned = window.eval(value)

    if (typeof returned !== 'number') {
      return 0
    }

    return returned
  } catch (e) {
    const simple_float = parseFloat(value)
    if (isNaN(simple_float)) {
      return 0
    }
    return simple_float
  }
}

export const margin_color = (true_margin_percentage) => {
  let color = 'black'
  if (true_margin_percentage < 0.05) {
    color = '#b23210'
  } else if (true_margin_percentage < 0.15) {
    color = '#b27d18'
  } else if (true_margin_percentage < 0.25) {
    color = '#687307'
  } else if (true_margin_percentage >= 0.25) {
    color = '#1e7306'
  }
  return color
}

export const compute_margin_bg = (true_margin_percentage) => {
  let bg = 'secondary'
  if (true_margin_percentage < 0.05) {
    bg = 'danger'
  } else if (true_margin_percentage < 0.15) {
    bg = 'warning-danger'
  } else if (true_margin_percentage < 0.25) {
    bg = 'warning'
  } else if (true_margin_percentage >= 0.25) {
    bg = 'success'
  }
  return bg
}

export const compute_current_price = (product, parent_product) => {

  if (!product || !product.product_info) {
    return 0
  }

  let sale_price = product.product_info.sale_price
  let active_price = product.product_info.standard_price
  if (sale_price) {
    const is_active = moment().isBetween(
      moment(safe(product, 'product_info', 'sale_from_date') || safe(parent_product, 'product_info', 'sale_from_date')),
      moment(safe(product, 'product_info', 'sale_end_date') || safe(parent_product, 'product_info', 'sale_end_date')),
    )

    if (is_active) {
      active_price = sale_price
    }
  }

  if (parent_product && !active_price) {
    active_price = compute_current_price(parent_product)
  }

  return active_price || 0
}

export const parse_nice_float = (what = '0') => {
  if (!what.replace) {
    // not a string
    return what
  }

  return parse_formula(what)//parser.parse(what.replace(/,/g, '.')).result

}
export const parse_and_add = (prices) => prices.reduce((total, price) => {
  return parse_nice_float(price) + total
}, 0)

export const compute_product_costs = (product, price_config) => {

  return parse_and_add([
    price_config.achat,
    price_config.cost_1,
    price_config.cost_2,
    price_config.cost_3,
    price_config.cost_4,
    price_config.cost_5,
    price_config.cost_6,
    price_config.import,
    price_config.stockage,
    price_config.fourniture,
  ])

}


export const get_urssaf_rate = (amazon_account) => {
  const {
    is_auto_entrepreneur,
    cfp,
    tfc,
    is_versement_liberatoire,
    taux_cotisation,
  } = amazon_account && amazon_account.auto_entrepreneur_config ? amazon_account.auto_entrepreneur_config : {}

  return is_auto_entrepreneur ? ( parse_and_add([
    taux_cotisation,
    cfp,
    tfc,
    is_versement_liberatoire ? 1 : 0,
  ]) / 100 ) : 0
}

export const get_urssaf_rates_map = (amazon_accounts) => {
  return amazon_accounts.reduce((urssaf_rate_map, amazon_account) => {
    urssaf_rate_map[ amazon_account.merchant_id ] = get_urssaf_rate(amazon_account)
    return urssaf_rate_map
  }, {})
}
