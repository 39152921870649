import React from 'react'
import './item.scss'
import { FaCheckCircle, FiHelpCircle } from 'react-icons/all'
import { Tooltip } from 'react-tippy'

export default function Item({
                               children,
                               onClick,
                               active,
                               active_message,
                               className = '',
                               style = {},
                               image,
                               color,
                               no_padding = false,
                               text_color,
                               no_border = false,
                               hide_activer_marker = false,
                             }) {
  return (
    <div
      className={`list-item position-relative ${onClick ? 'clickable' : ''} ${active ? 'active' : ''} ${no_padding ? 'p-0' : ''} ${no_border ? 'no-border' : ''} ${className}`}
      onClick={onClick}
      style={{
        ...style,
      }}>
      <div className={'h-100'} style={{

        background: color,
        color: text_color,
        borderRadius: '5px',
        padding: no_padding ? 0 : 10,
        marginBottom: active_message ? 40 : 0,
      }}>{children} </div>

      {image ? <div className="row mb-2">
        <div className="col-12">
          <img src={image} alt="" className="w-100"/>
        </div>
      </div> : null}
      {active && !hide_activer_marker ?
        <div className="position-absolute text-right" style={{ right: 10, bottom: 10, lineHeight: '14px' }}>
          <span className="small mr-2">{active_message}</span>
          <span style={{ fontSize: '125%', color: 'var(--color-green)' }}>
            <FaCheckCircle/>
          </span>
        </div> : null}
    </div>
  )
}


export const CompactItem = ({ label, className, children, on_click, is_selected, style, action, bottom_action }) => {
  return <Item no_padding={true} className={`p-2 mb-2 ${className}`} onClick={on_click} active={is_selected}
               hide_activer_marker={true} style={style}>
    <div className="row">
      {label ? <>
        <div className="col font-weight-bold small">
          {label}
        </div>
        {action ? <div className="col-auto small">
          {action}
        </div> : null}
        <div className="col-12">
          <hr className={'mt-1 mb-2'}/>
        </div>
      </> : null}
      <div className="col-12">
        {children}
      </div>
      {bottom_action ? <>
        <div className="col-12">
          <hr className={'mt-1 mb-2'}/>
        </div>
        <div className="col-12 text-right">
          {bottom_action}
        </div>
      </> : null}
    </div>
  </Item>
}


export const NiceNumbers = ({ label, icon, detail, tooltip, conclusion, bg, action, on_click }) => {

  return <CompactItem label={label}>
    <div className="row">
      <div className="col-12">
        <NiceConclusion conclusion={conclusion} detail={detail} icon={icon} bg={bg} tooltip={tooltip}/>
      </div>

      {action ? <>
        <div className="col-12">
          <hr className="my-1"/>
        </div>
        <div className="col-12">
          {action}
        </div>
      </> : null}
    </div>
  </CompactItem>
}

export const NiceConclusion = ({ bg, icon, conclusion, detail, tooltip, right, className }) => {
  return <Conclusion bg={bg} className={className}>
    <div className="row align-items-center no-gutters">
      <div className="col-auto h-100 mx-2 pr-2" style={{ borderRight: `1px solid rgba(0,0,0,0.1)` }}>
        {icon}
      </div>
      <div className="col text-left">
        {conclusion}
      </div>
      {right? <div className="col-auto">
        {right}
      </div> : null}
      {detail ? <div className="col-auto smaller">
        <span className="badge badge-secondary">{detail}</span>
      </div> : null}
      {tooltip ? <div className="col-auto smaller">
        <Tooltip arrow={true} html={tooltip}>
          <FiHelpCircle/>
        </Tooltip>
      </div> : null}
    </div>
  </Conclusion>
}

export const Conclusion = ({ bg = 'secondary', children, className }) => {
  return <div className={`text-center bg-${bg} rounded font-weight-bold text-white p-2 shadow ${className}`}>
    {children}
  </div>
}

export const ItemTitle = ({ children, action }) => {

  return <div className="row">
    <div className="col">
      <h4 className={'font-weight-bold'}>
        {children}
      </h4>
    </div>
    <div className="col-auto">
      {action}
    </div>
    <div className="col-12">
      <hr className="mt-0 mb-2"/>
    </div>
  </div>


}
