import React, { Fragment } from 'react'

import './field.css'

const Select = ({ value, setter, help, options, label }) => {
  React.useEffect(() => {
    if (!value && options.length > 0) {
      setter(options[ 0 ].value)
    }
  }, [])

  return (
    <Fragment>
      <div className="col-12 mb-3">
        <label>{label}</label>
        <select className="w-100 mt-2" value={value} onChange={(e) => setter(e.target.value)}>
          {options.map(({ value, label, disabled }) => <option key={value} value={value}
                                                               disabled={disabled}>{label}</option>)}
        </select>
      </div>
      <div className="col-12">
        {help}
      </div>
    </Fragment>
  )
}


export default Select
