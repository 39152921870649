import React from 'react'
import { useDesignerContext } from '../designer_context'
import { useCachedContext } from '../../../hooks/useCachedContext'
import { compute_rect_style } from '../controller/rect_controls'
import SingleObjectOptions from '../options/single_object_options'
import { OptionSelector, OptionSeparator } from '../options/options'
import { useTranslate } from '../../../context/lang'
import IntellifoxModal from '../../generic/modal'
import SelectCropImage from '../options/select_crop_image'
import SelectVariationImages from '../options/select_variation_images'
import { ColorOptionSelector } from '../options/select_color'

export default function Image({ object_id, onLoad, reduce_image_size, ...props }) {
  const { image_id, image_object_fit = 'cover', image_object_reverse = false, crop, mask_image, filters, fill } = props && props.props ? props.props : null

  let { compute_color } = useDesignerContext()
  if (!compute_color) {
    compute_color = (c) => c
  }

  const image = useCachedContext('image', image_id)

  React.useEffect(() => {
    if (image_id === null) {
      onLoad && onLoad()
    }
  }, [])

  React.useEffect(() => {
    if (image.error || image.image_id === null) {
      onLoad && onLoad()
    }
  }, [image])

  if (image.loading) {
    return <div className="position-absolute loading" id={object_id} style={{
      ...compute_rect_style(props),
    }}>
    </div>
  }

  if (image.error || image.image_id === null) {
    return <div></div>
  }

  const image_url = reduce_image_size && image.url && image.url.replace ? image.url.replace('/original/', '/resize_140/') : image.url

  const style = compute_rect_style(props, compute_color)
  const mask_image_style = mask_image ? {
    WebkitMaskImage: mask_image,
    maskImage: mask_image,
    WebkitMaskSize: '100% 100%',
    maskSize: '100% 100%',
  } : {}

  if (props && props.angle === null) {
    props.angle = 0
  }

  if (crop) {
    const scale = Math.min(props.width / crop.width, props.height / crop.height)

    let image_reflexion = null
    if (filters && filters.image_reflexion) {

      const reflexion_style = compute_rect_style({
        ...props,
        angle: props.angle,
      })

      const image_reflexion_value = filters.image_reflexion_value || 30
      const image_reflexion_distance = filters.image_reflexion_distance_value || 30

      const top = style.top + style.height + parseFloat(image_reflexion_distance)
      image_reflexion = <div className="position-absolute" id={`${object_id}_reflexion`} style={{
        ...reflexion_style,
        filter: style.filter.replace(/drop-shadow\(.+\)?/, ''),
        top,
        overflow: 'hidden',
        transform: `${image_object_reverse ? 'scaleX(-1)' : ''} scaleY(-1) rotate(${props.angle || 0}rad) translateZ(0)`,
        maskImage: `linear-gradient(${-props.angle || 0}rad, rgba(0,0,0,1) 0%, rgba(0,0,0,0) ${image_reflexion_value}%)`,
        WebkitMaskImage: `linear-gradient(${-props.angle || 0}rad, rgba(0,0,0,1) 0%, rgba(0,0,0,0) ${image_reflexion_value}%)`,
        opacity: ( style.opacity || 1 ) / 2,
      }}>
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: crop.width,
          height: crop.height,
          transform: `scale(${scale}) translate(-50%, -50%)`,
          transformOrigin: '0 0',
        }}>
          <img src={image_url} onLoad={() => onLoad && onLoad()}
               style={{
                 width: image.width,
                 height: image.height,
                 transform: `translate(-${crop.x}px, -${crop.y}px)`,
               }}/>
        </div>
      </div>
    }


    return <>

      <div className="position-absolute" style={{
        ...style,
        background: compute_color(fill),
      }}></div>
      {image_reflexion}
      <div className="position-absolute" id={object_id} style={{
        ...style,
        overflow: 'hidden',
        transform: `${style.transform ? style.transform : ''} ${image_object_reverse ? 'scaleX(-1)' : ''}`,
      }}>
        <div style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          width: crop.width,
          height: crop.height,
          transform: `scale(${scale}) translate(-50%, -50%) `,
          transformOrigin: '0 0',
          ...mask_image_style,
        }}>
          <img src={image_url} onLoad={() => onLoad && onLoad()}
               style={{
                 width: image.width,
                 height: image.height,
                 transform: `translate(-${crop.x}px, -${crop.y}px)`,
               }}/>
        </div>
      </div>
    </>
  }

  let image_reflexion = null
  if (filters && filters.image_reflexion) {
    const image_reflexion_value = filters.image_reflexion_value || 30
    const image_reflexion_distance = filters.image_reflexion_distance_value || -10

    const top = style.top + style.height + parseFloat(image_reflexion_distance)
    image_reflexion = <div className="position-absolute" id={`${object_id}_reflexion`} style={{
      ...style,
      filter: style.filter.replace(/drop-shadow\(.+\)?/, ''),
      top,
      transform: `${image_object_reverse ? 'scaleX(-1)' : ''} scaleY(-1) rotate(${props.angle || 0}rad) translateZ(0)`,
      maskImage: `linear-gradient(${-props.angle || 0}rad, rgba(0,0,0,1) 0%, rgba(0,0,0,0) ${image_reflexion_value}%)`,
      WebkitMaskImage: `linear-gradient(${-props.angle || 0}rad, rgba(0,0,0,1) 0%, rgba(0,0,0,0) ${image_reflexion_value}%)`,
    }}>
      <img src={image_url}
           style={{
             position: 'absolute',
             top: 0,
             left: 0,
             width: '100%',
             height: '100%',
             objectFit: image_object_fit,
             opacity: 0.5,
           }}/>
    </div>
  }
  return (
    <>
      <div className="position-absolute" style={{
        ...style,
        background: compute_color(fill),
      }}></div>
      {image_reflexion}
      <div className="position-absolute" id={object_id} style={{
        ...style,
      }}>
        <img src={image_url} onLoad={() => onLoad && onLoad()}
             style={{
               width: '100%',
               height: '100%',
               objectFit: image_object_fit,
               transform: image_object_reverse ? 'scaleX(-1)' : '',
               ...mask_image_style,
             }}/>
      </div>

    </>
  )
}

export const ImageOptions = ({ scale, ...object }) => {
  const { product } = useDesignerContext()
  const translate = useTranslate()

  const has_variations = product.variations && product.variations.length > 0

  return <div className="container-fluid h-100">
    <div className="row align-items-center h-100 no-gutters row justify-content-between">
      <div className="col-auto" style={{ height: 32 }}>
        <ColorOptionSelector color={object.props.fill} option={'props.fill'}/>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <OptionSelector option={'props.image_id'}>{translate({
          fr: `Remplacer l'image`,
          en: `Replace image`,
        })}</OptionSelector>
      </div>
      <OptionSeparator/>
      <div className="col-auto">
        <SelectCropImage/>
      </div>
      <OptionSeparator/>
      {has_variations ? <>
        <div className="col-auto">
          <SelectVariationImages/>
        </div>
        <OptionSeparator/>
      </> : null}
      <div className="col-auto">
        <OptionSelector option={'props.filters'}>{translate({
          fr: `Filtres`,
          en: `Filters`,
        })}</OptionSelector>
      </div>
      {/*<div className="col-auto ml-2">*/}
      {/*  <TextButton onClick={() => set_current_option('props.filters')}>Transparent</TextButton>*/}
      {/*</div>*/}
      <div className="col"></div>

      <div className="col-auto">
        <SingleObjectOptions object={object}/>
      </div>
    </div>
  </div>
}
