import React from 'react'
import History from './history'
import FullScreenLayout from '../layout/full_screen_layout'
import Button from '../generic/button'
import MenuCategories, { menu_categories } from './designer/menu_categories'
import { compute_scale, DomRenderer, Scaler } from './renderer/dom_renderer'
import { DesignerContext, useDesignerContextInit } from './designer_context'
import Controller from './controller/controller'
import Options, { option_selectors, OptionSeparator } from './options/options'
import { apply_overrides } from './override/apply_overrides'
import CopyButton from '../generic/copy-button'
import ModelAndVariations from './designer/model_and_variations'
import VariationLabel, { ProductLabel } from './override/variation_label'
import TextButton from '../generic/text_button'
import { CopyFromOtherRegion, indices } from '../../routes/product_v2/edit_visual'
import { SquareVisual } from '../../routes/product_v2/product_preview/product_preview'
import UpdateButton from '../generic/update-button'
import DownloadVisual from './header/download_visual'
import HelpButton from '../generic/help_button'
import { FaArrowLeft, FiDownload, IoMdCheckmark } from 'react-icons/all'
import { navigate } from '../../absolute_link'
import SelectionDropdown from './options/selection_dropdown'

import './designer.css'
import { useFrench, useTranslate } from '../../context/lang'
import EditColorPalette from './options/edit_color_palette'
import CopyPasteMenu from './options/copy_paste_menu'
import { useWindowSize } from '../../hooks/useWindowSize'
import Grid, { RatioGrid } from './grid/grid'
import useLocalStorage from '../../hooks/useLocalStorage'
import { ShowCompetitorsImages } from '../../routes/product_v2/product_preview/competitors'
import { Browse_Content_Section } from './designer/browse__content'
import { GoGear } from 'react-icons/go'
import EditRenderer from './header/edit_renderer'
import Flag, { FlagsForRegion } from '../flag/flag'
import { amazon_regions, ShowRegion } from '../../hooks/useMarketPlace'
import { Tooltip } from 'react-tippy'
import IntellifoxModal from '../generic/modal'
import Item from '../generic/item'
import NavigationButton from '../generic/navigation-button'


export default function Designer(
  {
    product,
    product_for_market_place,
    visual,
    width,
    height,
    objects,
    initial_objects,
    initial_overrides,
    update_objects,
    overrides,
    update_overrides,
    save,
    save_mutation,
    has_unsaved_updates,
    history_id = 'designer_dev',
    close,
    index,
    market_place,
    region_id,
    product_id,
  },
) {
  const translate = useTranslate()
  const french = useFrench()

  const designer_context = useDesignerContextInit({
    product,
    width,
    height,
    objects,
    update_objects,
    overrides,
    update_overrides,
    history_id,
    initial_objects, initial_overrides,
  })

  React.useEffect(() => {
    product.refresh()
    product_for_market_place.refresh()
  }, [])

  const {
    history,
    current_objects,

    current_menu,
    set_current_menu,
    current_option,
    set_current_option,
    get_selected_object,


    edit_overrides,
    set_edit_overrides,
    current_product_id,
    set_current_product_id,
    reset_history,

  } = designer_context

  const header = {
    id: 'header',
    height: 52,
    content: <div className="row align-items-center h-100 w-100 no-gutters menu-colors"
                  style={{ paddingLeft: 5, paddingRight: 5 }}>
      <div className="col-auto mr-1">
        <TextButton onClick={() => {
          if (has_unsaved_updates) {
            if (window.confirm(translate({
              fr: 'Vous avez des changements non enregistré, en quittant vous allez perdre vos modifications en cours.',
              en: 'You have unsaved changes. By leaving your will loose your pending changes.',
            }))) {
              reset_history()
              close()
            }
          } else {
            close()
          }
        }}><FaArrowLeft/></TextButton>
      </div>
      <div className="col-auto ml-2">
        <Button button={has_unsaved_updates ? 'primary' : 'link'} onClick={() => save()}>
          {translate({
            fr: `Sauvegarder`,
            en: `Save`,
          })} <IoMdCheckmark/>{save_mutation.loading ? <small> {translate({
          fr: `En cours...`,
          en: `In progress...`,
        })}</small> : null}
        </Button>
      </div>
      <div className="col-auto ml-5">
        <History history={history}/>
      </div>
      {has_unsaved_updates ? <div className="col-auto ml-2">
        <TextButton onClick={() => reset_history()}>
          {translate({
            fr: `Annuler les modifications`,
            en: `Cancel changes`,
          })}
        </TextButton>
      </div> : null}

      <div className="col font-weight-bold text-center h5 text-ellipsis">
        {translate(indices.find((i) => i.index === index).label_translations)}
      </div>

      <div className="col-auto mr-2">
        <ShowCompetitorsImages product={product} market_place={market_place}/>
      </div>
      <div className="col-auto mr-2">
        <EditColorPalette product={product}/>
      </div>
      <div className="col-auto mr-2">
        <EditRenderer index={index}/>
      </div>
      <div className="col-auto">
        <DownloadVisual product_id={current_product_id} index={index} has_unsaved_updates={has_unsaved_updates}
                        region_id={region_id}
                        save={save}>{translate({
          fr: `Télécharger`,
          en: `Download`,
        })} <FiDownload/></DownloadVisual>
      </div>
    </div>,
  }

  const index_selector = {
    id: 'left_menu_images',
    width: 73, content: <div className="h-100 w-100 menu-colors">
      <div className="row text-center">
        <div className="col-12 mb-2">
          <IntellifoxModal title={french(`Autres regions`)}
                           dynamic_body={(close) => <div className="row">
                             <div className="col-12">
                               {amazon_regions.map((region) => {
                                 return <Item key={region_id} className={'mb-2'} active={region.region_id === region_id}>
                                   <div className="row">
                                     <div className="col-12">
                                       <ShowRegion region_id={region.region_id}/>
                                     </div>
                                     <div className="col-12">
                                       <NavigationButton
                                         on_click={close}
                                         to={`/product/edit/${product_id}/${market_place}/visual/${region.region_id}/${visual.index}`}>
                                         {french(`Éditer les images de cette région`)}
                                       </NavigationButton>
                                     </div>
                                   </div>
                                 </Item>
                               })}
                             </div>
                           </div>}
                           button_props={{ outline: true, className: 'btn-sm' }}
          >
            <Tooltip arrow={true} html={<FlagsForRegion region_id={region_id}/>}>
              {region_id.toUpperCase()}
            </Tooltip>
          </IntellifoxModal>
        </div>
      </div>

      {indices.map((i) => {
        const visual = product.product_info.visuals.find(v => v.index === i.index)
        const is_current = i.index === index

        if (visual) {
          return <div key={i.index} className={'clickable mb-2'} style={{ border: is_current ? '5px solid grey' : '' }}>
            <SquareVisual visual={visual} width={is_current ? 63 : 73} open={() => {

              if (!is_current) {
                // set_index(i.index)
                navigate(`/product/edit/${product_id}/${market_place}/visual/${region_id}/${i.index}`)
              }
            }}

                          reduce_image_size={true}/>
          </div>
        }

        return null


      })}
    </div>,
  }
  const left_menu = {
    id: 'left_menu',
    width: 80, content: <div className="h-100 w-100 menu-colors">
      <MenuCategories current_menu={current_menu} set_current_menu={set_current_menu}/>
    </div>,
  }

  const menu_content_for_current_menu = menu_categories.find(({ key }) => key === current_menu)
  const menu_content = menu_content_for_current_menu && !edit_overrides ? {
    id: 'menu_content',
    width: 350,
    content: <>
      <div className="position-absolute w-100 h-100" style={{
        left: 0, top: 0,
        opacity: 0.2,
        background: menu_content_for_current_menu.color,
        zIndex: 0,
      }}></div>
      <div className="h-100 w-100 position-relative" style={{
        overflowY: 'auto',
        zIndex: 1,
      }}>
        {menu_content_for_current_menu.content}
      </div>
    </>,
  } : null


  const option_content_for_current_menu = option_selectors.find(({ key }) => key === current_option)
  const option_content = option_content_for_current_menu && get_selected_object() ? {
    id: 'option_content',
    width: 350, content: <div className="h-100 w-100" style={{
      overflowY: 'auto',
    }}>
      {option_content_for_current_menu.content}
    </div>,
  } : null


  const [option_height, set_option_height] = React.useState(45)
  const [zoom_height, set_zoom_height] = React.useState(45)

  const options = {
    id: 'options',
    height: Math.max(option_height, 10),
    content: <div className="w-100 global-colors options" id="options_bar"
                  style={{ background: 'var(--color-bg-global-darker)' }}>
      <Options set_option_height={set_option_height} set_zoom_height={set_zoom_height}/>
    </div>,
  }
  const [zoom, set_zoom] = React.useState(1)
  const [show_grid, set_show_grid] = useLocalStorage('image_editor_grid', false)
  const has_variations = product.variations && product.variations.length > 0
  const [show_variations, set_show_variations] = React.useState(has_variations)

  const zoom_bar = {
    id: 'zoom_bar',
    height: Math.max(zoom_height, 10),
    content: <div className="w-100 global-colors" id="zoom_bar">
      <div className="container-fluid">
        <div className="row align-items-center no-gutters mt-1">
          <div className="col">

          </div>
          <div className="col-auto">
            <SelectionDropdown/>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <CopyPasteMenu/>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <small>{translate({
              fr: `Zoom`,
              en: `Scale`,
            })}</small>
            <div className="btn-group">
              <Button className="btn-sm" onClick={() => set_zoom(0.5)}
                      button={zoom === 0.5 ? 'primary' : 'secondary'}>x0.5</Button>
              <Button className="btn-sm" onClick={() => set_zoom(1)}
                      button={zoom === 1 ? 'primary' : 'secondary'}>x1</Button>
              <Button className="btn-sm" onClick={() => set_zoom(2)}
                      button={zoom === 2 ? 'primary' : 'secondary'}>x2</Button>
              <Button className="btn-sm" onClick={() => set_zoom(4)}
                      button={zoom === 4 ? 'primary' : 'secondary'}>x4</Button>
            </div>
          </div>
          <OptionSeparator/>
          <div className="col-auto">
            <Button className="btn-sm mr-2" button={show_grid ? 'primary' : 'secondary'}
                    onClick={() => set_show_grid(!show_grid)}>{translate({
              fr: `Grille`,
              en: `Grid`,
            })}</Button>
          </div>
        </div>
      </div>

    </div>,
  }

  const designer_padding = 15
  const designer = {
    id: 'designer',
    height: 'auto', content: true, className: 'overflow-hidden', dynamic_content: (dimensions) => {
      const scale = compute_scale({
        available_width: dimensions.width - designer_padding * 2,
        available_height: dimensions.height - designer_padding * 2,
        width,
        height,
      }) * ( zoom )

      const has_zoom = zoom !== 1

      const objects = apply_overrides(current_objects.objects, current_objects.overrides[ current_product_id ])

      const dimension = Math.min(dimensions.width * zoom, dimensions.height * zoom)
      return <>
        <div className="h-100 w-100 global-colors" style={{ overflow: zoom > 1 ? 'scroll' : 'hidden' }}
             onClick={() => designer_context.reset_selection()}>
          <Scaler scale={scale} center_absolute={zoom > 1 ? false : true}
                  width={zoom > 1 ? dimension : null}
                  height={zoom > 1 ? dimension : null} className={zoom > 1 ? 'p-5' : null}>
            <DomRenderer objects={objects} width={width} className="d-inline-block"
                         height={height}/>
            <Controller objects={objects} width={width} save={save}
                        height={height} scale={scale} index={index} market_place={market_place}/>
            {show_grid ? <><Grid width={width}
                                 cols={3}
                                 lines={3}
                                 height={height}
                                 scale={scale}/>
              <RatioGrid width={width} height={height} ratio={16 / 9}/>
            </> : null}
          </Scaler>
        </div>
      </>
    },
  }
  const variations = product.variations && product.variations.length > 0 ? {
    id: 'variations',
    height: show_variations ? 140 : 20, content: true, dynamic_content: (dimensions) => {
      return <>
        <div className="position-absolute" style={{
          top: show_variations ? 20 : -20, left: 20,
        }}>
          <Button className="btn-sm" onClick={() => set_show_variations(!show_variations)}
                  button={show_variations ? 'secondary' : 'primary'}>{show_variations ? translate({
            fr: `Cacher les variations`,
            en: `Hide variations`,
          }) : translate({
            fr: `Voir les variations`,
            en: `See variations`,
          })}</Button>
        </div>
        {show_variations ? <ModelAndVariations product={product_for_market_place} dimensions={dimensions} paddingTop={10} paddingBottom={10}/> : null}
      </>
    },
  } : null

  const editor = {
    id: 'editor',
    width: 'auto',
    rows: [
      options,
      zoom_bar,
      designer,
    ],
  }
  if (variations) {
    editor.rows = [
      options,
      zoom_bar,
      designer,
      variations,
    ]
  }

  const content = {
    id: 'content',
    height: 'auto',
    cols: [
      index_selector,
      left_menu,
      editor,
    ],
  }

  if (menu_content || option_content) {
    content.cols = [
      index_selector,
      left_menu,
      option_content || menu_content,
      editor,
    ]
  }

  const rows = [
    header,
    content,
  ]

  return (
    <>
      <DesignerContext.Provider value={designer_context}>
        <FullScreenLayout rows={rows}/>

        <CopyFromOtherRegion region_id={region_id}
                             product={product}
                             visual={visual}
                             set_objects={(objects) => {
                               designer_context.update_objects_save_history(objects)
                               save(objects)
                             }}/>
      </DesignerContext.Provider>
    </>
  )
}
