export const distance = (x1, y1, x2, y2) => {
  let xs = x2 - x1,
    ys = y2 - y1

  xs *= xs
  ys *= ys

  return Math.sqrt(xs + ys)
}

export const center = ({ x, y, width, height }) => {
  return {
    x: x + width / 2,
    y: y + height / 2,
  }
}

export const rotate_around = (center, point, angle) => {
  // const x = 10000 * ( Math.cos(angle) * ( point.x - center.x ) - Math.sin(angle) * ( point.y - center.y ) + center.x )
  // const y = 10000 * ( Math.sin(angle) * ( point.x - center.x ) + Math.cos(angle) * ( point.y - center.y ) + center.y )
  // return { x: Math.round(x) / 10000, y: Math.round(y) / 10000 }


  const x = ( Math.cos(angle) * ( point.x - center.x ) - Math.sin(angle) * ( point.y - center.y ) + center.x )

  const y = ( Math.sin(angle) * ( point.x - center.x ) + Math.cos(angle) * ( point.y - center.y ) + center.y )

  return { x, y }
}

export const project = (point, angle, length) => {
  return rotate_around(point, { x: point.x + length, y: point.y }, angle)
}

export const get_line_intersection = (line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) => {
  // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
  const denominator = ( ( line2EndY - line2StartY ) * ( line1EndX - line1StartX ) ) - ( ( line2EndX - line2StartX ) * ( line1EndY - line1StartY ) )
  if (denominator === 0) {
    return false
  }
  let a = line1StartY - line2StartY
  let b = line1StartX - line2StartX
  const numerator1 = ( ( line2EndX - line2StartX ) * a ) - ( ( line2EndY - line2StartY ) * b )
  const numerator2 = ( ( line1EndX - line1StartX ) * a ) - ( ( line1EndY - line1StartY ) * b )
  a = numerator1 / denominator

  const result = {}
  result.x = line1StartX + ( a * ( line1EndX - line1StartX ) )
  result.y = line1StartY + ( a * ( line1EndY - line1StartY ) )
  return result
}

export const intersect_perpendicular = (point1, point2, angle) => {
  const project_point1 = project(point1, angle, 100)
  const project_point2 = project(point2, angle - Math.PI / 2, 100)

  return get_line_intersection(
    point1.x, point1.y, project_point1.x, project_point1.y,
    point2.x, point2.y, project_point2.x, project_point2.y,
  )
}

export const complete_rectangle = (point1, point2, angle) => {
  return {
    p1: intersect_perpendicular(point1, point2, angle),
    p2: intersect_perpendicular(point1, point2, angle + Math.PI / 2),
  }
}

export const rotate_rectangle = ({ x, y, width, height }, angle = 0) => {
  const object_center = center({ x, y, width, height })
  return {
    top_left: rotate_around(object_center, { x: x, y: y }, angle),
    top_right: rotate_around(object_center, { x: x + width, y: y }, angle),
    bottom_left: rotate_around(object_center, { x: x, y: y + height }, angle),
    bottom_right: rotate_around(object_center, { x: x + width, y: y + height }, angle),
  }
}


export const compute_rectangle = (rectangle, angle = 0, fixed_point, m) => {
  const { p1, p2 } = complete_rectangle(rectangle[ fixed_point ], m, angle)

  let top_left
  let top_right
  let bottom_right
  let bottom_left

  if (fixed_point === 'top_left') {
    top_left = rectangle[ fixed_point ]
    top_right = p1
    bottom_right = m
    bottom_left = p2
  } else if (fixed_point === 'top_right') {
    top_left = p1
    top_right = rectangle[ fixed_point ]
    bottom_right = p2
    bottom_left = m
  } else if (fixed_point === 'bottom_left') {
    top_left = p2
    top_right = m
    bottom_right = p1
    bottom_left = rectangle[ fixed_point ]
  } else if (fixed_point === 'bottom_right') {
    top_left = m
    top_right = p2
    bottom_right = rectangle[ fixed_point ]
    bottom_left = p1
  }

  const center = get_line_intersection(
    top_left.x, top_left.y, bottom_right.x, bottom_right.y,
    top_right.x, top_right.y, bottom_left.x, bottom_left.y,
  )

  const new_top_left = rotate_around(center, top_left, -angle)

  return {
    ...new_top_left,
    width: distance(top_left.x, top_left.y, top_right.x, top_right.y),
    height: distance(top_left.x, top_left.y, bottom_left.x, bottom_left.y),

    debug_points: [
      { ...top_left, l: 'top_left' },
      { ...new_top_left, l: 'new_top_left' },
      { ...bottom_right, l: 'bottom_right' },
      { ...top_right, l: 'top_right' },
      { ...bottom_left, l: 'bottom_left' },
    ],
  }
}

export const project_point = (p1, p2, length) => {

  const current_length = distance(p1.x, p1.y, p2.x, p2.y)

  const zeroed_p2 = {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  }

  return {
    x : p1.x + zeroed_p2.x * length/current_length,
    y : p1.y + zeroed_p2.y * length/current_length,
  }
}
