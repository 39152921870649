import { gql } from '@apollo/client'
import { create_query } from './_query_and_mutations_templates'


export const get_visuals_query = create_query({
  query: 'get_visuals',
  params: {
    product_id: 'ID',
    market_place: 'String',
  },
  output: `{
    visual_id
    last_modified
    width
    height
    index
    is_shared
    objects {
      type
      object_id
      x
      angle
      props
    }
    overrides {
      type
      object_id
      x
      angle
      props
    }
    renderer
  }`,
})

export const ADD_VISUAL = gql`
    mutation AddVisual(
        $company_id: ID!, $product_id: ID!, $market_place: String!,
        $width: Float!, $height: Float!, $index: Int!,

    ) {
        addVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            width:$width,
            height:$height,
            index:$index,
        ) {
            visual_id
        }
    }
`
export const ADD_SHARED_VISUAL = gql`
    mutation AddSharedVisual(
        $company_id: ID!, $product_id: ID!, $market_place: String!, $index: Int!,
        $visual_id: ID!
    ) {
        addSharedVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            index:$index,
            visual_id: $visual_id,
        ) {
            visual_id
        }
    }
`
export const UNSHARE_VISUAL = gql`
    mutation UnshareVisual(
        $company_id: ID!, $product_id: ID!, $market_place: String!, $index: Int!,
        $visual_id: ID!
    ) {
        unshareVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            index:$index,
            visual_id: $visual_id,
        )
    }
`

export const UPDATE_VISUAL = gql`
    mutation UpdateVisual(
        $company_id: ID!, $product_id: ID!, $market_place: String!,
        $visual_id: ID!,
        $visual: InputVisual

    ) {
        updateVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            visual_id:$visual_id,
            visual:$visual
        )
    }
`

export const DELETE_VISUAL = gql`
    mutation DeleteVisual(
        $company_id: ID!, $product_id: ID!,
        $market_place: String!,
        $visual_id: ID!,
        $index: Int!

    ) {
        deleteVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            index: $index,
            visual_id:$visual_id,
        )
    }
`

export const SWITCH_VISUALS = gql`
    mutation SwitchVisuals(
        $company_id: ID!, $product_id: ID!,
        $market_place: String!,
        $index_1: Int!,
        $index_2: Int!,

    ) {
        switchVisuals(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            index_1: $index_1,
            index_2: $index_2,
        )
    }
`

export const UPDATE_RENDERER = gql`
    mutation UpdateRenderer(
        $company_id: ID!,
        $visual_id: ID!,
        $renderer: String!,

    ) {
        update_renderer(
            company_id:$company_id,
            visual_id:$visual_id,
            renderer: $renderer,
        )
    }
`

export const DOWNLOAD_VISUAL = `
    query BuildVisual(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $index: Int!,
        $renderer: String

    ) {
        downloadVisual(
            company_id:$company_id,
            product_id:$product_id,
            market_place:$market_place,
            index: $index,
            renderer: $renderer,
        )
    }
`
