import React from 'react'
import { ProgressBadge } from '../product/progress'
import useHashParam from 'use-hash-param'
import { useTranslate } from '../../context/lang'
import BigBox from '../../components/layout/big_box'
import { OnlyPremium } from '../../components/subscription/start_free_trial'

export const DisplayMenuItem = ({
                                  menu,
                                  set_current_menu,
                                  menu_item,
                                  current_sub_menu,
                                  set_current_sub_menu,
                                  product,
                                }) => {
  const translate = useTranslate()

  return <div className="row mb-5 align-items-start pt-3 ">
    <div className="col-12 align-self-start">
      {menu_item ? menu_item.menu.map(({
                                         label,
                                         key: sub_key,
                                         content,
                                         count_min,
                                         count_max,
                                         progress,
                                         actions,
                                         wrap_in_item,
                                         show_progress = true,
                                       }, i) => {
        return <div className="row" key={i}>
          <a name={`${menu_item.key}-${sub_key}`} id={`${menu_item.key}-${sub_key}`}
             className="section_anchor position-relative"
             style={{ top: -120 }}></a>
          <div className="col-12">
            <BigBox title={<>{label} <ProgressBadge val={progress} className="h4"/></>} actions={<div className="row">
              <div className="col-auto">
                {/*<span className={'h2'}>{label}</span>*/}

              </div>
              {actions ? <div className="col-auto">
                {actions}
              </div> : null}

            </div>}
                    wrap_in_item={wrap_in_item}>
              {content}
            </BigBox>
          </div>
        </div>
      }) : null}
    </div>
  </div>
}

export default function Content({ menu, product }) {
  // React.useEffect(() => {
  //   setTimeout(() => {
  //     scroll_to_node(document.location.hash.substring(1))
  //   }, 500)
  // }, [])
  const translate = useTranslate()

  const [current_menu, set_current_menu] = useHashParam('menu', 'preview')
  const [current_sub_menu, set_current_sub_menu] = useHashParam('sub_menu')
  const [show_menu, set_show_menu] = useHashParam('show_menu', 'yes')

  // React.useEffect(() => {
  //   if (current_menu) {
  //     // scroll_to_node('current_menu')
  //   }
  // }, [current_menu])


  const current_menu_item = menu.find((menu_item) => menu_item.key === current_menu)


  const { hide_if_no_subscription } = current_menu_item


  return <>
    <div className="row pb-5">
      <a name="current_menu" id="current_menu" className="position-relative" style={{ top: -120 }}></a>
      <div className="col-12">
        <OnlyPremium is_active={hide_if_no_subscription}>
          {current_menu ?
            <DisplayMenuItem menu={menu} set_current_menu={set_current_menu} menu_item={current_menu_item}
                             current_sub_menu={current_sub_menu}
                             set_current_sub_menu={set_current_sub_menu} product={product}/> :
            null}
        </OnlyPremium>
      </div>
    </div>
  </>
}
