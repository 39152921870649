import React from 'react'
import { useCachedContext } from '../../../hooks/useCachedContext'
import useMarketPlace, { useRegion, useSourceMarketPlace } from '../../../hooks/useMarketPlace'
import { DefaultError, DefaultLoading } from '../../../components/i18n/translations'
import SquareSearchResult from './square_search_result'
import { apply_overrides } from '../../../components/visual/override/apply_overrides'
import InternalLink from '../../../components/generic/internal-link'
import { AutomaticScaler, DomRenderer } from '../../../components/visual/renderer/dom_renderer'
import useHashParam from 'use-hash-param'
import { useFrench, useTranslate } from '../../../context/lang'
import Button from '../../../components/generic/button'
import { scroll_to_node } from '../scroll'
import { FaAmazon, FaArrowDown, FaArrowRight, FaEye, FaRegEdit, FiZap } from 'react-icons/all'
import { DesignerContext } from '../../../components/visual/designer_context'
import IntellifoxModal from '../../../components/generic/modal'
import Stars from '../../../components/generic/stars'
import Item from '../../../components/generic/item'
import ExternalLinkButton from '../../../components/generic/external-link-button'
import { useProduct } from '../../../context/products'
import { OpenedSectionContext } from '../edit'
import { navigate } from '../../../absolute_link'
import ExternalLink from '../../../components/generic/external-link'
import { useOpenedSection } from '../product_footer'
import SearchTermInit from '../search_term_init'

const ProductVisual = ({ product, parent_product, visual, index }) => {

  const objects = apply_overrides(visual.objects, visual.overrides)
  const market_place = useMarketPlace()

  return <>
    <div className="row">
      <div className="col-12 clickable">
        <InternalLink to={`/product/edit/${( parent_product || product ).product_id}/${market_place}/visual/${index}`}>
          <AutomaticScaler visual={visual}>
            <DesignerContext.Provider value={{
              compute_color: (value) => {
                if (!product || !product.color_palette) {
                  return value
                }

                // first find if value is a color id in product palette
                const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
                if (palette_color) {
                  return palette_color.color
                }

                return value
              },
            }}>
              <DomRenderer {...visual} objects={objects}/>
            </DesignerContext.Provider>
          </AutomaticScaler>
        </InternalLink>
      </div>
    </div>
  </>
}


const EditButton = ({ hash, sub_menu, children, className = '' }) => {

  const [_, set_current_menu] = useHashParam('menu')
  const translate = useTranslate()


  return <Button onClick={() => {
    set_current_menu(hash)
    setTimeout(() => {
      scroll_to_node(sub_menu ? `${hash}-${sub_menu}` : 'current_menu')
    }, 500)
  }} className={`${className}  `}
  > {children ? children : <>{translate({
    fr: `Modifier`,
    en: `Edit`,
  })} <FaRegEdit/></>}</Button>
}


export const ShowMeAsCompetitor = ({ product }) => {

  const region = useRegion()

  const source_product = useProduct(product.product_id, region.region_id)

  if (source_product.loading || source_product.error) {
    return null
  }

  const price = product.product_info.sale_price || product.product_info.standard_price

  return <SquareSearchResult title={product.product_info.title} price={price} rating={product.rating}
                             ratings_total={product.ratings_total}
                             image_element={<ProductVisual product={product}
                                                           visual={source_product.product_info.visuals[ 0 ]}
                                                           index={0}/>}/>
}


const ShowCompetitorStuff = ({ competitor, children }) => {
  const french = useFrench()
  const market_place = useMarketPlace()
  return <div className="row mb-2" style={{ minHeight: '80px' }}>
    <div className="col-auto text-center">
      <img src={competitor.image} alt="" style={{ maxWidth: 80, maxHeight: 80 }}/>
      <Stars ratings_total={competitor.ratings_total} rating={competitor.rating} asin={competitor.asin} market_place={market_place}/>
      <ExternalLink className="small" to={competitor.url}>
        {french(`Voir sur Amazon`)} <FaAmazon/>
      </ExternalLink>
    </div>
    <div className="col small">
      {children}
    </div>
    <div className="col-12">
      <hr/>
    </div>
  </div>

}

const ShowCompetitorDetail = ({ competitor, field, type = 'String', on_click }) => {
  if (type === 'String') {
    const value = ( competitor[ field ] || '' ).replace(/\n/g, '<br/><br/>')
    return <ShowCompetitorStuff competitor={competitor}>
      <div dangerouslySetInnerHTML={{ __html: value }} className={'clickable'} onClick={() => on_click(value)}>
      </div>
    </ShowCompetitorStuff>
  }

  if (type === '[String]') {
    const values = ( competitor[ field ] || [] )
    return <ShowCompetitorStuff competitor={competitor}>
      <ul>
        {values.map((line) => <li dangerouslySetInnerHTML={{ __html: line }} className={'clickable'}
                                  onClick={() => on_click(line)}></li>)}
      </ul>
    </ShowCompetitorStuff>
  }

  return null
}

const ShowCompetitorImage = ({ competitor }) => {
  return <ShowCompetitorStuff competitor={competitor}>
    {( competitor.images || [] ).map((image) => <img src={image} alt="" style={{ maxWidth: 250 }}
                                                     className={'mr-2 mb-2'}/>)}
  </ShowCompetitorStuff>
}

export const ShowCompetitorsAttribute = ({ product, field, type }) => {
  const translate = useTranslate()

  const market_place = useMarketPlace()

  const competitors = useCachedContext('competitors', product.product_id, market_place)

  if (competitors.loading) {
    return <DefaultLoading/>
  }

  if (competitors.error) {
    return <DefaultError/>
  }

  return <IntellifoxModal title={translate({
    fr: 'La concurrence',
    en: 'Competitors',
  })}
                          dynamic_body={(close) => <>

                            <div className="row">
                              <div className="col-12">
                                {competitors.map((competitor) => <ShowCompetitorDetail competitor={competitor}
                                                                                       type={type}
                                                                                       field={field}/>)}
                              </div>
                            </div>
                            <NoCompetitorsWarning on_click={close} product={product} hide={competitors && competitors.length > 0}/>

                          </>}
                          button_props={{
                            className: 'btn-sm',
                          }}
  >
    {translate({
      fr: `Voir ce que font les concurrents`,
      en: `See what the competitors are doing`,
    })} <FaEye/>
  </IntellifoxModal>
}

export const ShowCompetitorsValues = ({ product, field, type, on_click }) => {
  const translate = useTranslate()

  const market_place = useMarketPlace()

  const competitors = useCachedContext('competitors', product.product_id, market_place)

  if (competitors.loading) {
    return <DefaultLoading/>
  }

  if (competitors.error) {
    return <DefaultError/>
  }

  return <>

    <div className="row">
      {competitors.map((competitor) => <div className="col-12">
        <ShowCompetitorDetail competitor={competitor}
                              type={type}
                              field={field}
                              on_click={on_click}
        />
      </div>)}
    </div>
    <NoCompetitorsWarning product={product} hide={competitors && competitors.length > 0}/>

  </>
}


export const ShowCompetitorsImages = ({ product, market_place }) => {
  const translate = useTranslate()

  const competitors = useCachedContext('competitors', product.product_id, market_place)

  if (competitors.loading) {
    return <DefaultLoading/>
  }

  if (competitors.error) {
    return <DefaultError/>
  }

  return <IntellifoxModal title={translate({
    fr: 'La concurrence',
    en: 'Competitors',
  })}
                          dynamic_body={(close) => <>
                            <NoCompetitorsWarning product={product} hide={competitors && competitors.length > 0}/>
                            <div className="row">
                              <div className="col-12">
                                {competitors.map((competitor) => <ShowCompetitorImage key={competitor.asin}
                                                                                      competitor={competitor}/>)}
                              </div>
                            </div>

                          </>}
  >
    {translate({
      fr: `Concurrents`,
      en: `Competitors`,
    })} <FaEye/>
  </IntellifoxModal>
}

export const NoCompetitorsWarning = ({ product, hide=false }) => {
  const translate = useTranslate()

  const [show_search_term_init, set_show_search_term_init] = React.useState(false)

  return <>{hide ? null:<div className="row">
    <div className="col-12">
      <div className="alert alert-info">
        <div className="row">

          <div className="col-12 small font-weight-bold">
            {translate({
              fr: `Pour voir les concurrents, il faut d'abord suivre les étapes de démarrage :`,
              en: `To be able to see your competitors, you need to select them:`,
            })}
          </div>
          <div className="col-12">
            <Button onClick={() => {
              set_show_search_term_init(true)
            }} className="mt-2 btn-sm"><FiZap/> {translate({
              fr: `Démarrer l'optimisation`,
              en: `Select keywords`,
            })}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>}
    {show_search_term_init ? <SearchTermInit product={product} close={() => set_show_search_term_init(false)} on_finish={() => product.refresh()}/> : null }
    </>
}

export default function ShowCompetitors({ product }) {

  const market_place = useMarketPlace()

  const competitors = useCachedContext('competitors', product.product_id, market_place)

  React.useEffect(() => {
    competitors.refresh()
  }, [product])

  if (competitors.loading) {
    return <DefaultLoading/>
  }

  if (competitors.error) {
    return <DefaultError/>
  }

  let helper = <NoCompetitorsWarning product={product} hide={competitors && competitors.length > 0}/>

  const first_four_competitors = competitors.filter((_, i) => i < 4)
  const other_competitors = competitors.filter((_, i) => i >= 4)


  return (
    <>
      <div className="row">
        {first_four_competitors.map(({ image, title, price, url, rating, ratings_total }) => {
          return <SquareSearchResult title={title} price={price} url={url} image={image}
                                     rating={rating} ratings_total={ratings_total}/>
        })}

        <ShowMeAsCompetitor product={product}/>

        {other_competitors.map(({ image, title, price, url, rating, ratings_total }) => {
          return <SquareSearchResult title={title} price={price} url={url} image={image}
                                     rating={rating} ratings_total={ratings_total}/>
        })}

      </div>
      {helper}
    </>
  )
}
