import React from 'react'
import * as _ from 'lodash'
import { useAsyncAutoTranslate, useFrench, useLang } from '../../../../context/lang'

import { set_4_water_hose } from './set_4_water_hose'
import { set_1_castor_oil } from './set_1_castor_oil'
import { set_2_leash } from './set_2_leash'
import { set_3_cooking_spatula } from './set_3_cooking_spatula'
import { set_5_pacifier } from './set_5_pacifier'


export const translate_all_templates = async (auto_translate, source_lang, target_lang, templates) => {
  return Promise.all(templates.map(async (set) => {
    return await Promise.all(set.map(async (objects) => {
      objects = _.cloneDeep(objects)

      return await Promise.all(objects.map(async (object) => {
        if (object.type !== 'text') {
          return object
        }

        const content = await auto_translate({
          source_lang,
          text: object.props.content,
          target_lang,
        })

        return {
          ...object,
          props: {
            ...object.props,
            content,
          },
        }
      }))
    }))
  }))
}

export const useTemplates = () => {
  const french = useFrench()
  const auto_translate = useAsyncAutoTranslate()
  const lang = useLang()

  const french_templates = [
    set_4_water_hose,
    set_1_castor_oil,
    set_2_leash,
    set_3_cooking_spatula,
    set_5_pacifier,
  ]

  const [translated_templates, set_translated_templates] = React.useState(french_templates)

  const [is_loading, set_is_loading] = React.useState(true)
  const [error, set_error] = React.useState(false)

  React.useEffect(() => {
    const go = async () => {
      set_is_loading(true)
      try {
        const new_translated_templates = await translate_all_templates(auto_translate, 'fr', lang, french_templates)
        set_translated_templates(new_translated_templates)
        set_is_loading(false)
      } catch (e) {
        set_is_loading(false)
        set_error(true)
      }
    }

    go().catch(e => console.error(e))

  }, [lang])

  if (is_loading) {
    return {
      loading: true,
    }
  }

  if (error) {
    return {
      error: true,
    }
  }


  return translated_templates
}
