import React, { useCallback, useRef, useEffect } from 'react'

export default function useDebounce(fn_to_watch, ms, additional_deps = [], reset_timeout_deps) {

  const fn = useCallback(fn_to_watch, [
    ...additional_deps,
  ])

  const timeout = useRef()
  const debounced_fn = (...args) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      fn(...args)
    }, ms)
  }

  debounced_fn.force_now = (...args) => {
    clearTimeout(timeout.current)
    fn(...args)
  }

  useEffect(() => {
    clearTimeout(timeout.current)
  }, [...reset_timeout_deps])

  return debounced_fn
}
