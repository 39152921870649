import React, { useContext } from 'react'
import UserContext from '../../context/user'
import { useMutation } from '@apollo/client'
import { UPDATE_SNIPPET } from '../../graphql/queries/snippet'
import { useCachedContext } from '../../hooks/useCachedContext'
import IntellifoxModal, { IntellifoxModalComponent } from '../../components/generic/modal'
import { displayFields } from '../../components/generic/field'
import { snippet_fields } from './add'
import { await_mutate } from '../../graphql/mutate_promise'
import { useTranslate } from '../../context/lang'

export default function SnippetEdit({ show_modal, close, snippet }) {
  const user = useContext(UserContext)
  const snippets = useCachedContext('snippets')
  const translate = useTranslate()

  const { company_id } = user.company

  const [code, set_code] = React.useState('')
  const [content_fr, set_content_fr] = React.useState('')

  React.useEffect(() => {
    set_code(snippet.code)
    set_content_fr(snippet.content_fr)
  }, [snippet])

  const fields = snippet_fields({ translate, code, set_code, content_fr, set_content_fr })

  const [updateSnippet] = useMutation(UPDATE_SNIPPET)

  const onSubmit = async () => {
    await await_mutate(updateSnippet, {
      variables: {
        company_id,
        snippet_id: snippet.snippet_id,
        code,
        lang: 'fr',
        content: content_fr,
      },
    })

    await snippets.refresh()

  }

  return (
    <IntellifoxModalComponent
      show_modal={show_modal}
      close={close}
      body={<>
        {displayFields(fields, onSubmit)}
      </>} title={translate({
      fr: 'Modifier une variable',
      en: 'Edit the variable',
    })} action_in_progress_label={translate({
      fr: 'Modification en cours...',
      en: 'Update in progress...',
    })}
      action_label={translate({
        fr: 'Modifier une variable',
        en: 'Update the variable',
      })} on_click={onSubmit}/>
  )
}
