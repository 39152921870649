import React from 'react'
import { useFrench, useTranslate } from '../../context/lang'
import ResetPassword from '../user/reset_password'
import { LostPasswordModal } from '../user/login'

//Erreur {error.extensions.code} {error.message} (friendly user erreurs TODO)
export const DisplayErrors = ({ graphQLErrors, email, set_email }) => {
  const translate = useTranslate()
  const french = useFrench()


  return <ul>
    {graphQLErrors.map(({ message }) => {
        if (message === 'email_already_used') {
          return <li>{french(`<>Cet email est déjà utilisé. Clique sur ce bouton pour remettre à zéro ton mot de passe :`)}
            <LostPasswordModal email={email} set_email={set_email}/></li>
        }


        return (
          <li>{translate({
            fr: `Une erreur s'est produite`,
            en: `An error happened`,
          })}</li>
        )
      },
    )}
  </ul>
}
