import React from 'react'
import useLocalStorage from '../../hooks/useLocalStorage'
import UserContext from '../../context/user'
import Button from '../../components/generic/button'
import Onboarding from './onboarding'
import { FaCheck, FaLongArrowAltRight } from 'react-icons/fa'
import TextButton from '../../components/generic/text_button'
import Item from '../../components/generic/item'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { ProgressBadge } from '../product/progress'
import { FaArrowRight, FiExternalLink, FiHelpCircle, IoIosFlash } from 'react-icons/all'
import GlobalProgressBar from '../../components/user/global_progress_bar'
import { MdClose } from 'react-icons/md'
import ExternalLink from '../../components/generic/external-link'
import { OnboardingVideo } from './onboarding_videos'


export const FrenchContent = ({ children }) => {
  const lang = useLang()
  if (lang === 'fr') {
    return children
  }
  return null
}

export const InternationalContent = ({ children }) => {
  const lang = useLang()
  if (lang !== 'fr') {
    return children
  }
  return null
}

export const YouTube = ({ youtubeId, className = '', show_link = true }) => {
  const user_lang = useLang()
  const french = useFrench()

  return (
    <>
      <div
        className={`video rounded ${className}`}
        style={{
          position: 'relative',
          paddingBottom: '56.25%' /* 16:9 */,
          // paddingTop: 25,
          height: 0,
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          allowFullScreen="allowfullscreen"
          src={`https://www.youtube-nocookie.com/embed/${youtubeId}?rel=0`}
          frameBorder="0"
        />
      </div>
      {show_link ? <div className={'small text-right mt-2'}>
        <ExternalLink
          to={`https://youtu.be/${youtubeId}`}><FiExternalLink/> {french(`Ouvrir la vidéo dans un nouvel onglet`)}
        </ExternalLink>
      </div> : null}

    </>
  )
}

const Step = ({ step, index, current_step, set_done }) => {
  const { title, content, show_set_done, progress, do_not_close = false } = step
  const translate = useTranslate()

  const [force_open, set_force_open] = useLocalStorage(`intellifox_force_open_${index}`, false)

  const is_step_before = index < current_step
  const is_step_after = index > current_step
  const is_current = index === current_step

  const step_text = <>
    {index + 1}
  </>

  return <div className={`row align-items-center mb-4`} key={index}>
    <div className="col-auto font-weight-bold">
      <span style={{ fontSize: '36px' }}>{step_text}</span>
    </div>
    <div className="col">
      <Item className={'w-100'} active={is_step_before}>
        <div className="row clickable" onClick={() => set_force_open(!force_open)}>
          <div className="col-12">

            <div className="row">
              <div className="col-12">
                <div className="text-uppercase small font-weight-bold mr-2 d-inline-block">
                  {is_step_before ? <del><FaCheck/> {title}</del> : null}
                  {is_current ? <><FaArrowRight/> {title}</> : null}
                  {is_step_after ? <span style={{ opacity: 1 }}>{title}</span> : null}
                </div>
                {progress !== null ? <ProgressBadge val={progress}/> : null}
              </div>
            </div>

            {( do_not_close && content ) || ( force_open && content ) || is_current ?
              <div className="row" style={{ opacity: is_step_after ? 1 : 1 }}>
                <div className="col-12 mt-3">
                  {content ? content({ set_done, is_done: is_step_before }) : null}
                </div>
              </div> : null}


            {is_current && show_set_done ? <div className="row text-right">
              <div className="col-12 mt-3">
                <Button outline={true} className="btn-sm" onClick={() => {
                  set_force_open(false)
                  set_done()
                }}><FaCheck/> {translate({
                  fr: `Marquer comme terminé`,
                  en: `Mark as done`,
                })}</Button>
              </div>
            </div> : null}
          </div>
        </div>


      </Item>
    </div>
  </div>
}

export const Stepper = ({ id, steps, initial_current_step = 0, on_complete, on_reset }) => {
  if (!id) {
    throw new Error('need to identify stepper')
  }
  const [current_step, set_current_step] = useLocalStorage(`intellifox_stepper_${id}_current_step`, initial_current_step)

  const translate = useTranslate()

  return <div className="row">
    <div className="col-12">
      {steps.map((step, i) =>
        <Step step={step} key={i} index={i}
              current_step={current_step} set_done={() => {
          if (step.block_done) {
            return
          }
          if (current_step === i) {
            set_current_step(current_step + 1)
          }
          if (current_step + 1 >= steps.length) {
            on_complete && on_complete()
          }
        }}/>)}
    </div>
    <div className="col-12 text-right">
      <TextButton onClick={() => {
        set_current_step(initial_current_step)
        if (on_reset) {
          on_reset()
        }
      }}>{translate({
        fr: `Recommencer`,
        en: `Start again`,
      })}</TextButton>
    </div>
  </div>
}


export default function OnboardingMaster({
                                           children,
                                           disconnect,
                                           onboarding_done,
                                           set_onboarding_done,
                                           spapi_oauth_code,
                                           on_finish,
                                         }) {
  const user = React.useContext(UserContext)

  const translate = useTranslate()
  const french = useFrench()

  const [onboarding_current_step, _set_onboarding_current_step] = useLocalStorage(`intellifox_onboarding_current_step_${user.user.user_id}`, 1)

  const force_open_to_second_step = !!spapi_oauth_code

  const [show_onboarding, set_show_onboarding] = React.useState(force_open_to_second_step)

  const [step_override, set_step_override] = React.useState(force_open_to_second_step ? 2 : null)

  const set_onboarding_current_step = (step) => {
    if (step_override) {
      set_step_override(null)
    }
    _set_onboarding_current_step(step)
  }


  if (onboarding_done) {
    return <>
      <div className="row mb-4">
        <div className="col-12 text-right">
          <Button outline={true} className={'btn-sm'} onClick={() => set_onboarding_done(false)}>
            {translate({
              fr: `Ouvrir l'assistant de démarrage`,
              en: `Re-open onboarding assistant`,
            })}
          </Button>
        </div>
      </div>
      {children}
    </>
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-right">
          <TextButton onClick={() => set_onboarding_done(true)}>{translate({
            fr: `Passer l'assistant de démarrage`,
            en: `Skip onboarding assistant`,
          })} <span className="h2"><MdClose/> </span></TextButton>
        </div>
        <div className="col-12 pt-5 text-center">
          <h1 dangerouslySetInnerHTML={{
            __html: translate({
              fr: `Tu es à <u>1 minute</u> de pouvoir optimiser tes fiches produits`,
              en: `You're <u>one minute away</u> from optimizing your product listings`,
            }),
          }}></h1>
        </div>
        <div className="col-12 col-md-8 offset-md-2 pt-5">
          <GlobalProgressBar step={1}/>
          <Item>
            <div className="row align-items-center">
              <div className="col-auto">
                <Button onClick={(e) => {
                  set_show_onboarding(true)
                  e.stopPropagation()
                }}>
                  <FaArrowRight/> {translate({
                  fr: `Importer mes produits depuis le Seller Central d'Amazon`,
                })}
                </Button>
              </div>
              <div className="col-auto">
                {show_onboarding ? <Onboarding on_finish={() => {
                  set_show_onboarding(false)
                  on_finish && on_finish()
                }}
                                               close={() => set_show_onboarding(false)}
                                               current_step={step_override || onboarding_current_step}
                                               set_current_step={set_onboarding_current_step}
                                               spapi_oauth_code={spapi_oauth_code}
                                               disconnect={disconnect}/> : null}
              </div>
            </div>

          </Item>
        </div>

        <div className="col-12 mt-5">
          <OnboardingVideo video_key={'welcome'}/>
        </div>
      </div>
    </>

  )
}
