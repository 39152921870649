import React from 'react'
import useMarketPlace, { amazon_regions, MarketPlaceDetails, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Item from '../../components/generic/item'
import { useTranslate } from '../../context/lang'
import Flag from '../../components/flag/flag'
import Button from '../../components/generic/button'
import { useMutation } from '@apollo/client'
import { delete_sold_by, update_sold_by } from '../../graphql/queries/sold_by'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import IntellifoxModal from '../../components/generic/modal'
import NavigationButton from '../../components/generic/navigation-button'
import { FaAmazon, FiTrash, MdCancel, MdCheck } from 'react-icons/all'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDelete } from 'react-icons/md'
import TextButton from '../../components/generic/text_button'

export default function EditAmazonAccountForProduct({ product }) {
  const sold_by = useCachedContext('sold_by', product.product_id)
  const amazon_accounts = useCachedContext('amazon_accounts')
  const translate = useTranslate()

  if (sold_by.loading || amazon_accounts.loading) {
    return <DefaultLoading/>
  }

  if (sold_by.error || amazon_accounts.error) {
    return <DefaultError/>
  }

  return (
    <div className="row">
      <div className="col-12">
        <Item no_padding={true}>
          <table className="table table-sm small table-striped">
            <thead>
            <tr>
              <th scope="col">{translate({
                fr: `Pays`,
                en: `Country`,
              })}</th>
              <th scope="col">{translate({
                fr: `Site Amazon`,
                en: `Amazon Site`,
              })}</th>
              <th scope="col">{translate({
                fr: `Compte Amazon lié`,
                en: `Linked Amazon account`,
              })}</th>
              <th scope="col">{translate({
                fr: `Actions`,
                en: `Actions`,
              })}</th>
            </tr>
            </thead>
            <tbody>
            {amazon_regions.map(({ region_id, market_places }) => {
              return <>
                {market_places.map(({ market_place, label, amazon_url }) => {

                  return <tr>
                    <td><Flag market_place={market_place}/> {translate(label)}</td>
                    <td>{amazon_url}</td>
                    <ManageSoldBy market_place={market_place} sold_by={sold_by}
                                  amazon_accounts={amazon_accounts}
                                  product_id={product.product_id}/>
                  </tr>
                })}
              </>
            })}
            </tbody>
          </table>

        </Item>
      </div>
    </div>
  )
}



const ManageSoldBy = ({ amazon_accounts, sold_by, product_id, market_place }) => {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const sold_by_details = sold_by.find((s) => s.market_place === market_place)
  const amazon_account = sold_by_details ? amazon_accounts.find((a) => a.merchant_id === sold_by_details.merchant_id) : null


  const [delete_sold_by_mutation] = useMutation(delete_sold_by)
  const [update_sold_by_mutation] = useMutation(update_sold_by)

  const [update_status, set_update_status] = React.useState('')
  const [delete_status, set_delete_status] = React.useState('')


  const do_delete_sold_by = async () => {
    set_delete_status('loading')
    try {
      await await_mutate(delete_sold_by_mutation, {
        variables: {
          company_id,
          product_id,
          market_place,
        },
      })
      sold_by.refresh()
      set_delete_status('')
    } catch (e) {
      set_delete_status('error')
    }
  }

  const do_update_sold_by = async (merchant_id, on_success, market_places) => {
    set_update_status('loading')
    try {
      await Promise.all(market_places.map(async (market_place) => {
        await await_mutate(update_sold_by_mutation, {
          variables: {
            company_id,
            product_id,
            market_place,
            merchant_id,
          },
        })
      }))

      sold_by.refresh()
      set_update_status('')
      on_success && on_success()
    } catch (e) {
      set_update_status('error')
    }

  }

  const amazon_accounts_capable_for_this_market_place = amazon_accounts.filter(({ market_places }) => market_places.indexOf(market_place) !== -1)
  const amazon_accounts_not_capable_for_this_market_place = amazon_accounts.filter(({ market_places }) => market_places.indexOf(market_place) === -1)

  return <>
    <td>{amazon_account ? <span className="font-weight-bold"><MdCheckBox/> {amazon_account.label} <span
        className="badge badge-secondary">{amazon_account.merchant_id}</span></span> :
      <MdCheckBoxOutlineBlank/>}</td>

    <td>
      <IntellifoxModal button_props={{ className: 'btn-sm' }}
                       title={<>{translate({
                         fr: `Sélectionner un compte pour ce produit sur`,
                         en: `Select an account for this product on`,
                       })} <MarketPlaceDetails
                         market_place={market_place}/></>}
                       dynamic_body={(close) => <div className="row">
                         <div className="col-12">
                           {amazon_accounts_capable_for_this_market_place.map(({
                                                                                 label,
                                                                                 merchant_id,
                                                                                 market_places,
                                                                               }) => {
                             return <Item className={'mb-2'}
                                          active={amazon_account && amazon_account.merchant_id === merchant_id}
                                          onClick={() => do_update_sold_by(merchant_id, close, [market_place])}>
                               <div className="row">
                                 <div className="col-12 font-weight-bold">
                                   {label} <span className="badge badge-secondary">{merchant_id}</span>
                                 </div>

                                 <div className="col-12 small">
                                   <Item>
                                     <div className="row">
                                       <div className="col-12 font-weight-bold mb-2">
                                         {translate({
                                           fr: `Ce compte est actif sur ${market_places.length} marketplaces :`,
                                           en: `This account is active on ${market_places.length} marketplaces:`,
                                         })}
                                       </div>
                                       <div className="col-12">
                                         {market_places.map((active_on_market_place) => {
                                           return <MarketPlaceDetails market_place={active_on_market_place}
                                                                      highlight={active_on_market_place === market_place}/>
                                         })}
                                       </div>
                                     </div>
                                   </Item>
                                 </div>
                               </div>
                               <div className="row">

                                 <div className="col-12 text-right mt-2">
                                   <Button>{translate({
                                     fr: `Sélectionner ce compte pour`,
                                     en: `Select this account for`,
                                   })}
                                     <MarketPlaceDetails market_place={market_place}/>
                                   </Button>
                                   {update_status === 'loading' ? <DefaultLoading/> : null}
                                   {update_status === 'error' ? <DefaultError/> : null}
                                 </div>
                                 <div className="col-12 text-right small mb-4">
                                   or <TextButton onClick={(e) => {
                                   e.stopPropagation()
                                   do_update_sold_by(merchant_id, close, market_places)
                                 }}>{translate({
                                   fr: `Sélectionner ce compte pour les ${market_places.length} marketplaces`,
                                   en: `Select this account for all ${market_places.length} marketplaces`,
                                 })}

                                 </TextButton>
                                 </div>
                               </div>
                             </Item>
                           })}
                         </div>

                         {amazon_accounts_capable_for_this_market_place.length === 0 ? <div className="col-12">
                           <div className="alert alert-warning">
                             <h4>{translate({
                               fr: `Vous n'avez aucun compte Amazon actif sur cette marketplace pour le moment`,
                               en: `You don't have any Amazon account active on this marketplace for the moment`,
                             })}</h4>
                             <NavigationButton to={'/settings'}><FaAmazon/> {translate({
                               fr: `Gérer mes comptes Amazon`,
                               en: `Manage my Amazon accounts`,
                             })}</NavigationButton>
                           </div>
                         </div> : null}
                       </div>
                       }


      >
        <FaAmazon/> {translate({
        fr: `Sélectionner un compte Amazon`,
        en: `Select an Amazon account`,
      })}
      </IntellifoxModal>
      <br/>
      {amazon_account ?
        <Button className="btn-sm" button="danger" outline={true} onClick={do_delete_sold_by}>
          <FiTrash/> {translate({
          fr: `Détacher le compte Amazon`,
          en: `Detach Amazon account`,
        })}


          {delete_status === 'loading' ? <DefaultLoading/> : null}
          {delete_status === 'error' ? <DefaultError/> : null}
        </Button> : null}
    </td>
  </>
}
