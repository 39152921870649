import React from 'react'
import { useEnglish } from '../../context/lang'

export default function OrSeparator() {
  const english = useEnglish()
  return (
    <div className="row align-items-center my-2">
      <div className="col">
        <hr className="lg"/>
      </div>
      <div className="col-auto font-weight-bold small">
        {english(`or`)}
      </div>
      <div className="col">
        <hr className="lg"/>
      </div>
    </div>
  )
}
