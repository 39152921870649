import React, { useState, useRef, useEffect } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
import { useProduct } from '../../context/products'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { useFrench } from '../../context/lang'
import { clean_for_label } from '../../routes/product/text-substitution'
import { navigate_to_product } from '../../routes/routes'
import Button from './button'
import { useCachedContext } from '../../hooks/useCachedContext'
import { useProductTitle } from '../../routes/product_v2'

export default function DropdownD({ children, label_style = {}, className = '', label = '' }) {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="link" className={className} style={label_style}>
          {label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {children}
        </Dropdown.Menu>
      </Dropdown>

    </>
  )
}

export const DropdownSplit = ({ button = 'primary', label, className = '', on_click, children }) => {
  return <Dropdown className={`btn-group btn-group-sm ${className}`}>
    <Button button={button} onClick={on_click} className={'btn-sm'} mb={'mb-0'}>{label}</Button>
    <Dropdown.Toggle variant={button} split={true} className={'btn-sm'}/>

    <Dropdown.Menu>
      {children}
    </Dropdown.Menu>
  </Dropdown>

}


export const DropDownContent = ({ icon, label }) => {
  return <div>
    {icon} {label}
  </div>
}

export const DropDownModal = ({ icon, label, Modal, modal_props }) => {
  const [show_modal, set_show_modal] = React.useState(false)

  return <>
    <a href="#" className="dropdown-item" onClick={(e) => {
      set_show_modal(true)
      e.preventDefault()
    }}>
      <DropDownContent icon={icon} label={label}/>
    </a>
    <Modal show_modal={show_modal} close={() => set_show_modal(false)} {...modal_props}/>
  </>
}

export const DropdownItemExternalLink = ({ to, icon, label }) => {
  return <a className="dropdown-item" href={to} target={'_blank'} rel='noreferrer noopener'>
    <DropDownContent icon={icon} label={label}/>
  </a>
}

export const DropdownDivider = () => {
  return <div className="dropdown-divider" role="separator"></div>
}

export const DropdownHeader = ({ children }) => {
  return <div className="dropdown-header">{children}</div>
}


export const DropdownItem = ({ on_click, icon, label }) => {
  return <a className="dropdown-item" href={'#'} onClick={(e) => {
    if (on_click) {
      on_click()
    }
    e.preventDefault()
  }
  }>
    <DropDownContent icon={icon} label={label}/>
  </a>
}


export const DropdownProductSelector = ({ product_id, on_error }) => {
  const source_market_place = useSourceMarketPlace()
  const french = useFrench()

  const product = useProduct(product_id, source_market_place)

  const snippets = useCachedContext('snippets')
  const { title, market_place } = useProductTitle(product, snippets)

  if (product.loading) {
    return <DropdownItem label={french('Chargement en cours...')}/>
  }

  if (product.error) {
    if (on_error) {
      on_error(product_id, product.error)
    }
    return null
  }

  return <DropdownItem label={clean_for_label(title, 60)}
                       on_click={() => navigate_to_product(product_id, market_place)}
  />
}
