import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { UPDATE_COMPANY_SOURCE_MARKET_PLACE } from '../../graphql/queries/company'
import { useMutation } from '@apollo/client'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import { MarketPlaceSelector, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { useTranslate } from '../../context/lang'
import { MdCheck } from 'react-icons/all'

export default function ReferenceMarketPlaceChooser({show_extra = true}) {
  const me = useCachedContext('me')
  const company_id = useCompanyId()
  const source_market_place = useSourceMarketPlace()
  const translate = useTranslate()

  const [update_source_market_place] = useMutation(UPDATE_COMPANY_SOURCE_MARKET_PLACE)

  const do_it = async (source_market_place) => {
    await await_mutate(update_source_market_place, {
      variables: {
        company_id,
        source_market_place,
      },
    })
    me.refresh()
  }


  return (
    <div className="row">
      <div className="col-12">
        <MarketPlaceSelector current_market_place={source_market_place} on_click={do_it} show_lang={true}/>
      </div>
      {show_extra?<div className="col-12 font-weight-bold mt-3"><MdCheck/> {translate({
        fr: `Tu pourras toujours changer cela plus tard`,
        en: `You can always change that later`,
      })}
      </div>:null}
    </div>
  )
}

