import React from 'react'
import { Browse_Content_Section, Browse_Content_Title } from '../browse__content'
import { ProductImages } from './product_images'

export default function ProductPictureSelector({on_click}) {
  return (
    <>
      <Browse_Content_Section>
        <ProductImages onClick={on_click}/>
      </Browse_Content_Section>
    </>
  )
}
