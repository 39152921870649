import React from 'react'
import Button from '../../components/generic/button'
import WtfButton from '../../components/generic/WtfButton'
import SearchTermTutorial from './search_term_tutorial'

export default function SearchTermInit({
                                         product,
                                         on_finish, close,
                                         current_step,
                                         set_current_step

}) {

  const [default_current_step, set_default_current_step] = React.useState(1)

  return (
    <SearchTermTutorial product={product} close={close} on_finish={on_finish} current_step={current_step ? current_step : default_current_step} set_current_step={set_current_step ? set_current_step : set_default_current_step}/>
  )
}

