import { create_mutation } from './_query_and_mutations_templates'

export const sold_by = {
  query: 'sold_by',
  params: {
    company_id: 'ID!',
    product_id: 'ID',
  },
  output: `{
          merchant_id
          market_place
        }`,
}


export const update_sold_by = create_mutation({
  mutation: 'update_sold_by',
  params: {
    company_id: 'ID!',
    product_id: 'ID',
    market_place: 'String',
    merchant_id: 'String',
  },
})

export const delete_sold_by = create_mutation({
  mutation: 'delete_sold_by',
  params: {
    company_id: 'ID!',
    product_id: 'ID',
    market_place: 'String',
  },
})
