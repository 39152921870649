import React, { Fragment } from 'react'

import './field.css'

const PhoneField = (props) => {
  const { prefix_value, prefix_setter, number_value, number_setter, help, validate = () => true, label } = props

  return (
    <Fragment>
      <div className="col-12 mb-3">
        <label>{label}</label>
        <input type='text' className={'w-100 ' + ( validate(prefix_value) ? 'valid' : 'invalid' )} value={prefix_value}
               placeholder={'Préfixe (ex: +33 pour la France)'}
               onChange={(e) => prefix_setter(e.target.value)}/>
        <input type='text' className={'w-100 ' + ( validate(number_value) ? 'valid' : 'invalid' )} value={number_value}
               placeholder={'Numéro de téléphone (ex : 6 12 34 56 78 '}
               onChange={(e) => number_setter(e.target.value)}/>
      </div>
      <div className="col-12">
        {help}
      </div>
    </Fragment>
  )
}


export default PhoneField
