import React from 'react'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { ProductFirstVisual } from '../product_v2'
import Button from '../../components/generic/button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import {
  format_big_number, format_money, format_percentage,
  sum_ads_fees,
  sum_amazon_fees,
  sum_earnings,
  sum_fees,
  sum_product_costs,
  sum_profits,
  sum_urssaf,
} from './format_numbers'
import { clean_for_label } from '../product/text-substitution'
import ProductsTable from './products_table'
import { FaList, IoMdPodium } from 'react-icons/all'
import { ProductsWithoutCostsWarning, useOrdersSummary, useSalesStats } from './orders_summary'
import { DesignerContext } from '../../components/visual/designer_context'
import { AutomaticScaler } from '../../components/visual/renderer/dom_renderer'
import { SquareVisual } from '../product_v2/product_preview/product_preview'
import * as _ from 'lodash'
import { useMetaData, useMetaDataAutoUpdate } from '../../components/user/meta_data'
import useLocalStorage from '../../hooks/useLocalStorage'
import { useProfitOrLoss } from '../../components/pricing/margin_details'
import { useUserCurrency } from '../subscription/upgrade'
import { Tooltip } from 'react-tippy'


const merge_variations = (products) => {
  return products.reduce((products, product) => {
    const { parent_product_id, title } = product
    if (parent_product_id) {
      let parent_product = products.find((p) => p.product_id === parent_product_id)
      if (!parent_product) {
        parent_product = {
          ...product,
          product_id: parent_product_id,
          stats: {
            units_sold: 0,
            units_returned: 0,
            total_sales: 0,
            amazon_commissions: 0,
            amazon_fba: 0,
            amazon_other: 0,
            amazon_ads: 0,
            total_product_costs: 0,
            total_urssaf: 0,
            profit: 0,
          }

        }
        products.push(parent_product)
      }
      if (!parent_product.variations) {
        parent_product.variations = []
      }

      parent_product.variations.push(product)

      parent_product.stats.units_sold += product.stats.units_sold
      parent_product.stats.units_returned += product.stats.units_returned
      parent_product.stats.total_sales += product.stats.total_sales
      parent_product.stats.amazon_commissions += product.stats.amazon_commissions
      parent_product.stats.amazon_fba += product.stats.amazon_fba
      parent_product.stats.amazon_other += product.stats.amazon_other
      parent_product.stats.amazon_ads += product.stats.amazon_ads
      parent_product.stats.total_product_costs += product.stats.total_product_costs
      parent_product.stats.total_urssaf += product.stats.total_urssaf
      parent_product.stats.profit += product.stats.profit


    } else {
      products.push(product)
    }

    return products
  }, [])
}

export default function ProductsFromOrders({
                                             products,
                                             orders,
                                             ads,
                                             toggle_asins,
                                             is_asin_selected,
                                             from, to,
                                           }) {

  const translate = useTranslate()
  const french = useFrench()

  const [see_more, set_see_more] = useMetaDataAutoUpdate('sales_dashboard_see_more', false)

  const sales_dashboard_merge_variations = useMetaData('sales_dashboard_merge_variations')

  const sales_stats = useSalesStats(orders, ads, from, to)

  products = _.uniqBy(products, 'asin')

  let products_with_stats = products.map((product) => {
    const { asin} = product

    const stats = {
      units_sold: 0,
      units_returned: 0,
      total_sales: 0,
      amazon_commissions: 0,
      amazon_fba: 0,
      amazon_other: 0,
      amazon_ads: 0,
      total_product_costs: 0,
      total_urssaf: 0,
      profit: 0,
    }

    // go through each order and look for product with asin
    for (let i = 0; i < orders.length; i++) {
      const order = orders[ i ]
      const { products } = order
      for (let j = 0; j < products.length; j++) {
        const product = products[ j ]
        if (product.asin === asin) {
          stats.units_sold += product.units_sold
          stats.units_returned += product.units_returned
          stats.total_sales += product.total_sales
          stats.amazon_commissions += product.amazon_commissions
          stats.amazon_fba += product.amazon_fba
          stats.amazon_other += product.amazon_other
          stats.amazon_ads += product.amazon_ads
          stats.total_product_costs += product.total_product_costs
          stats.total_urssaf += product.total_urssaf
          stats.profit += product.profit
        }
      }
    }

    // go through each ads and look for product with asin
    stats.amazon_ads = _.sumBy(ads.filter((a) => a.asin === asin), 'cost')

    // don't forget to add ads to profit to correct it from orders
    stats.profit += stats.amazon_ads
    return {
      ...product,
      stats,
    }
  })

  if (sales_dashboard_merge_variations) {
    products_with_stats = merge_variations(products_with_stats)
  }

  products_with_stats = products_with_stats
    .sort((p1, p2) => p1.stats.total_sales > p2.stats.total_sales ? -1 : 1)
    .map((p, i) => ( { ...p, rank: i + 1 } ))


  return (
    <div className="row mt-4 align-items-center">
      <div className="col-12 ">
        <div className="row">
          <div className="col-12 text-center mb-2">
            <div className="btn-group">
              <Button className="btn-sm" onClick={() => set_see_more(false)}
                      button={see_more === false ? 'primary' : 'secondary'}><IoMdPodium/> {french(`Top 10`)}</Button>
              <Button
                className="btn-sm" onClick={() => set_see_more(true)}
                button={see_more === true ? 'primary' : 'secondary'}><FaList/> {french(`Vue détaillée`)}</Button>
            </div>
          </div>
        </div>


        {see_more ? <div className="row mt-5">
          <div className="col-12">
            <ProductsTable products={products_with_stats}
                           sales_stats={sales_stats}
                           toggle_asins={toggle_asins}
                           is_asin_selected={is_asin_selected}/>
          </div>
        </div> : <>
          <div className="row justify-content-center no-gutters">
            {products_with_stats.filter((a, i) => i < 5).map((product) => {
              return <ProductStatsForOrders key={product.product_id} product={product}
                                            is_asin_selected={is_asin_selected} toggle_asins={toggle_asins}/>
            })}
          </div>
          <div className="row justify-content-center no-gutters">
            {products_with_stats.filter((a, i) => i >= 5 && i < 10).map((product) => {
              return <ProductStatsForOrders key={product.product_id} product={product}
                                            is_asin_selected={is_asin_selected} toggle_asins={toggle_asins}/>
            })}
          </div>
        </>}
      </div>


      <div className="col-12">
        <ProductsWithoutCostsWarning products={products_with_stats}/>
      </div>
    </div>
  )
}

export const ProductStatsForOrders = ({
                                        product,
                                        is_asin_selected,
                                        toggle_asins,
                                      }) => {
  const { asin, rank, stats, variations } = product
  const { units_sold } = stats


  return <div className="col-auto  mr-2 mb-2 position-relative">
    <div className="row">
      <div className="col text-center small">
        <span className="badge  mr-1">#{rank} </span>
        <span className="badge badge-secondary gradient_bg">x{format_big_number(units_sold, 0)}</span>
      </div>
    </div>
    <div className="row">
      <div className="col clickable position-relative BLUR_FOR_WEBINAR STRONG" onClick={() => toggle_asins([asin])}>

        {!variations || variations.length < 2 ?
          <PreloadedFirstVisual product={product} className={'list-item-shadow'}
                                size={100}
                                style={{
                                  borderRadius: '10px',
                                  overflow: 'hidden',
                                  border: is_asin_selected(asin) ? '2px solid green' : '',
                                }}/>

          : <>
            <div className="position-relative" style={{ width: 100, height: 100 }}>
              <PreloadedFirstVisual product={variations[ 2 ]} size={60}
                                    className="position-absolute"
                                    style={{
                                      left: 0,
                                      top: 0,
                                      borderRadius: '10px',
                                      overflow: 'hidden',
                                      border: is_asin_selected(asin) ? '2px solid green' : '',
                                    }}/>
              <PreloadedFirstVisual product={variations[ 1 ]} size={70}
                                    className="position-absolute"
                                    style={{
                                      left: 10,
                                      top: 10,
                                      borderRadius: '10px',
                                      overflow: 'hidden',
                                      border: is_asin_selected(asin) ? '2px solid green' : '',
                                    }}/>
              <PreloadedFirstVisual product={variations[ 0 ]} size={80}
                                    className={'list-item-shadow position-relative'}
                                    style={{
                                      left: 20,
                                      top: 20,
                                      borderRadius: '10px',
                                      overflow: 'hidden',
                                      border: is_asin_selected(asin) ? '2px solid green' : '',
                                    }}/>
              <div className="position-absolute small font-weight-bold" style={{ bottom: 0, left: 0 }}>
                <div className="small">
                  <span className="badge badge-secondary">({variations.length})</span>
                </div>
              </div>
            </div>
          </>}
      </div>
    </div>
  </div>
}

export const PreloadedFirstVisual = ({ product, style, className, size }) => {
  if (!product) {
    return null
  }

  const { product_id, first_visual, color_palette } = product
  const designer_context = {
    compute_color: (value) => {
      if (!color_palette) {
        return value
      }

      // first find if value is a color id in product palette
      const palette_color = color_palette.colors.find((c) => c.color_id === value)
      if (palette_color) {
        return palette_color.color
      }

      return value
    },

    product: {
      color_palette,
    },
    current_product_id: product_id,
  }
  return <DesignerContext.Provider value={designer_context}>
    <SquareVisual visual={first_visual} width={size} reduce_image_size={true} style={style}
                  className={className}/>

  </DesignerContext.Provider>
}


export const ProductFirstVisualByASIN = ({ asin, width }) => {

  const product_id = useCachedContext('product_id_by_asin', asin)

  if (product_id.loading) {
    return <DefaultLoading/>
  }

  if (product_id.error) {
    return <span className={"small"}>{asin}</span>
  }

  if (!product_id.product_id) {
    return null
  }

  return <>
  <ProductFirstVisual product={{ product_id: product_id.product_id }} width={width} className={'list-item-shadow'}
                      style={{ borderRadius: '10px', overflow: 'hidden' }}/>
  </>

}

export const ProductTitleByASIN = ({ asin, market_place }) => {
  const product_title = useCachedContext('product_title_by_asin', asin, market_place)

  if (product_title.loading) {
    return <DefaultLoading/>
  }

  if (product_title.error) {
    return <DefaultError/>
  }

  return <>
    {clean_for_label(product_title.title, 200, [])}
  </>
}

export const ProductVariationByASIN = ({ asin, market_place }) => {

  const product_id = useCachedContext('product_id_by_asin', asin)

  if (product_id.loading) {
    return <DefaultLoading/>
  }

  if (product_id.error) {
    return null
  }


  return <>
    <ProductVariation product_id={product_id.product_id} market_place={market_place}/>
  </>
}

const ProductVariation = ({ product_id, market_place }) => {
  const product_title = useCachedContext('product_title', product_id, market_place)

  if (product_title.loading) {
    return <DefaultLoading/>
  }

  if (product_title.error) {
    return null
  }

  return <div className="row">
    {product_title.product_info.variation ? <div className="col-12 small">
      <b>Variation:</b> {clean_for_label(product_title.product_info.variation, 200, [])}
    </div> : null}
  </div>
}
