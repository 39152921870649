import React, { useContext } from 'react'
import UserContext from '../../context/user'
import { useMutation } from '@apollo/client'
import { UPDATE_USER } from '../../graphql/queries/user'
import UserFields from '../../components/user/user-fields'
import { useLang, useTranslate } from '../../context/lang'
import { AttachLoginWithAmazon } from '../../components/user/login_with_amazon'
import Item, { ItemTitle } from '../../components/generic/item'

export default function UserSettings() {
  const user = useContext(UserContext)
  const { user_id } = user.user
  const translate = useTranslate()
  const lang = useLang()


  const [updateUser, mutation] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetMe'],
  })

  const updateUserForRealz = async ({
                                      email,
                                      first_name,
                                      last_name,
                                      accept_intellifox_news,
                                      accept_weekly_summary,
                                      accept_monthly_summary,
                                    }) => {


    await updateUser({
      variables: {
        user_id, email, first_name, last_name, lang, accept_intellifox_news, accept_weekly_summary,
        accept_monthly_summary,
      },
      update: () => {
        user.refresh()
      },

    })
  }


  return (
    <div className="row BLUR_FOR_WEBINAR">
      <UserFields onSubmit={updateUserForRealz}
                  mutation={mutation}
                  type={'update'}
      >
        {translate({
          fr: `Mettre à jour mon compte`,
          en: `Update my account`,
        })}
      </UserFields>

      <div className="col-12 mt-5">
        <div className="row">
          <div className="col-12 font-weight-bold">
            {translate({
              fr: 'Accéder à Intellifox avec son compte Amazon',
              en: 'Login to Intellifox with your Amazon Account',
            })}
          </div>
        </div>
        <AttachLoginWithAmazon/>
      </div>
    </div>
  )
}
