import React, { useContext } from 'react'
import BackToHome from '../../components/navigation/back_to_home'
import Item from '../../components/generic/item'
import { useFrench, useTranslate } from '../../context/lang'
import UserContext from '../../context/user'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import { useMutation, useQuery } from '@apollo/client'
import { GET_BILLS } from '../../graphql/queries/bills'
import moment from 'moment'
import ExternalLinkButton from '../../components/generic/external-link-button'
import CrispButton from '../../components/generic/crisp_button'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Button from '../../components/generic/button'
import Field from '../../components/generic/field'
import { SET_CANCELLATION_REASON } from '../../graphql/queries/user'
import { await_mutate } from '../../graphql/mutate_promise'
import { FaArrowRight, FaArrowUp, FaCheck, FaDownload, FaExternalLinkAlt, FiPause, IoMdInfinite } from 'react-icons/all'
import { FaEdit } from 'react-icons/fa'
import { get_client } from '../../graphql/simple-client'
import { GET_BILL_AS_PDF } from '../../graphql/queries/company'
import { force_download } from '../../components/visual/header/download_visual'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { MdPlayArrow } from 'react-icons/md'
import { GET_PLANS, plan } from '../../graphql/queries/plan'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import BigBox from '../../components/layout/big_box'
import { subscription_details_query } from '../../graphql/queries/subscription'

import visa from '../../images/payment_types/visa.png'
import paypal from '../../images/payment_types/paypal.png'
import mastercard from '../../images/payment_types/mastercard.png'
import amex from '../../images/payment_types/amex.png'
import strategy from '../../images/story/strategy.svg'
import NavigationButton from '../../components/generic/navigation-button'
import useHashParam from 'use-hash-param'
import TextButton from '../../components/generic/text_button'
import Redirect from '../../components/generic/redirect'
import { to_upper_first } from '../order'
import { CancelPause } from './cancel'

export default function Subscription() {
  const translate = useTranslate()
  const french = useFrench()
  const me = useCachedContext('me')


  const [cancel, set_cancel] = useHashParam('cancel', null)

  const company = useCompany()


  return <>
    <div className="container">
      <BackToHome/>
      <Title color={section_color('subscription')} style={{ color: 'black' }}><WelcomeIcon
        section={'subscription'}/> {translate({
        fr: `Abonnement`,
        en: `Subscription`,
      })}</Title>

      {cancel === 'y' ? <div className="row">
        <div className="col-12">
          <div className="alert alert-primary">
            <h1>{to_upper_first(me.user.first_name)}, {french(`on est désolés de te voir partir...`)}<br/>
              {french(`Tu vas nous manquer 💔🦊`)}
            </h1>
            <div>
              <FaCheck/> {french(`L'annulation de l'abonnement a bien été prise en compte`)}
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <TextButton onClick={() => set_cancel('k')}>{translate({
                  fr: `Fermer`,
                  en: `Close`,
                })}</TextButton>
              </div>
            </div>
          </div>
        </div>
      </div> : null}

      {company && company.subscription ? <>
        <div className="row mb-3">
          <div className="col-12">
            <BillsV2/>
          </div>
        </div>
        <BigBox title={translate({
          fr: 'Derniers paiements',
          en: 'Last payments',
        })}>
          <Bills/>
        </BigBox>
      </> : <Redirect to={'/subscription/upgrade'}/>}


    </div>
  </>
}

const Toggle = ({ children, content }) => {
  const [is_open, set_is_open] = React.useState(false)

  return <div className="row">
    <div className="col-12">
      <a href="javascript:" onClick={() => set_is_open(!is_open)}>{children}
        <div className="d-inline-block ml-2" style={{
          transition: 'transform 0.2s',
          transform: is_open ? 'rotate(-90deg)' : 'rotate(90deg)',
        }}><MdPlayArrow/>
        </div>
      </a>
    </div>
    {is_open ? <div className="col-12 mt-2">
      {content}
    </div> : null}
  </div>
}

const BillItem = ({ label, content, details, toggle_label, toggle_content }) => {
  return <Item className={'mb-3'}>
    <div className="row">
      <div className="col-12 small font-weight-bold">
        {label}
      </div>
      <div className="col-12">
        <h3>{content}</h3>
      </div>

      {details ? <div className="col-12 small">
        {details}
      </div> : null}

      {toggle_label ?
        <div className="col-12 small">
          <Toggle content={toggle_content}>{toggle_label}</Toggle>
        </div> : null}
    </div>
  </Item>
}


const PlanLabel = ({}) => {
  const french = useFrench()
  const company = useCompany()
  const { subscription_plan_id } = company.subscription
  const { subscription_tier, subscription_frequency } = company
  const subscription_details = useCachedContext('subscription_details')

  if (subscription_details.loading) {
    return <DefaultLoading/>
  }

  if (subscription_details.loading) {
    return <DefaultError/>
  }

  const is_cheque_france_num = subscription_plan_id === 'cheque_france_num'

  const last_payment = subscription_details.last_payment || {}

  const currency_amount = subscription_details.next_payment ?
    `${subscription_details.next_payment.currency} ${parseFloat(subscription_details.next_payment.amount).toFixed(2)}` :
    `${last_payment.currency || 'EUR'} ${parseFloat(last_payment ? last_payment.amount : '999.9').toFixed(2)}`

  if (is_cheque_france_num) {
    return <>Chèque France Num {currency_amount}</>
  }

  if (subscription_frequency === 'yearly') {
    return <>{french(`Annuel`)} {currency_amount}</>
  }

  if (subscription_frequency === 'monthly') {
    return <>{french(`Mensuel`)} {currency_amount}</>
  }

  return <>Intellifox {currency_amount}</>
}

const NextPaymentOrEnd = () => {

  const subscription_details = useCachedContext('subscription_details')
  const { subscription, pause_subscription, pause_subscription_date } = useCompany()
  const translate = useTranslate()
  const french = useFrench()

  React.useEffect(() => {
    // several refreshes are necessary because an invalid date is returned first, since the system doesn't know directly when is the end date
    // for the cancelation, since it needs to handle the hooks first.
    subscription_details.refresh()

    setTimeout(() => {
      subscription_details.refresh()

    }, 1000)
    setTimeout(() => {
      subscription_details.refresh()

    }, 4000)
  }, [])


  if (subscription_details.loading) {
    return <BillItem label={translate({
      fr: 'Date de renouvellement',
      en: 'Renewal date',
    })}
                     content={translate({
                       fr: 'Chargement...',
                       en: 'Loading...',
                     })}/>
  }

  if (subscription_details.error) {
    return <DefaultError/>
  }


  if (pause_subscription) {
    return <BillItem label={translate({
      fr: 'Date de début de la pause',
      en: 'Start date of pause',
    })}
                     content={moment(pause_subscription_date).format('ll')}
                     details={<><FiPause/> {translate({
                       fr: `Pause prise en compte`,
                       en: `Pause taken into account`,
                     })} <CancelPause/></>}
    />
  }
  if (subscription_details.state === 'deleted' && subscription.subscription_plan_id !== 'cheque_france_num' && subscription.subscription_plan_id !== 'appsumo') {
    return <BillItem label={translate({
      fr: 'Date de fin',
      en: 'End date',
    })}
                     content={moment(parseInt(subscription.cancellation_effective_date)).format('ll')}
                     details={<><FaCheck/> {translate({
                       fr: `Annulation prise en compte`,
                       en: `Cancellation taken into account`,
                     })}</>}
    />
  }

  if (subscription.subscription_plan_id === 'cheque_france_num' || subscription.subscription_plan_id === 'appsumo') {
    return <BillItem label={translate({
      fr: 'Date de fin',
      en: 'End date',
    })}
                     content={moment(parseInt(subscription.cancellation_effective_date)).format('ll')}
    />
  }

  return <BillItem label={translate({
    fr: 'Date de renouvellement',
    en: 'Renewal date',
  })}
                   content={subscription_details.next_payment ? moment(subscription_details.next_payment.date).format('ll') : french(`Abonnement en pause`)}
                   toggle_label={translate({
                     fr: 'Modifier le moyen de paiement',
                     en: 'Update payment method',
                   })}
                   toggle_content={
                     <div className="row">
                       <div className="col-12 small font-weight-bold">
                         {translate({
                           fr: `Dernier paiement`,
                           en: `Last payment`,
                         })}
                       </div>
                       <div className="col-12">
                         <h4>{moment(subscription_details.last_payment.date).format('ll')}</h4>
                       </div>
                       <div className="col-12">
                         <hr/>
                       </div>
                       <div className="col-12 small font-weight-bold">
                         {translate({
                           fr: `Mode de paiement utilisé`,
                           en: `Active payment method`,
                         })}
                       </div>
                       <div className="col-12">
                         <DisplayPaymentInformation subscription_details={subscription_details}/>
                       </div>
                       <div className="col-12">
                         <a href={subscription_details.update_url}>{translate({
                           fr: `Modifier le mode de paiement`,
                           en: `Update payment method`,
                         })} <FaExternalLinkAlt/></a>
                       </div>
                     </div>
                   }
  />
}

const DisplayPaymentInformation = ({ subscription_details }) => {
  const { payment_method, card_type, last_four_digits, expiry_date } = subscription_details.payment_information
  const translate = useTranslate()

  let image
  let text
  if (payment_method === 'card') {
    if (card_type === 'visa') {
      image = visa
      text = `Visa ***-${last_four_digits}`
    } else if (card_type === 'master') {
      image = mastercard
      text = `Mastercard ***-${last_four_digits}`
    } else if (card_type === 'american_express') {
      image = amex
      text = `American Express ***-${last_four_digits}`
    } else {
      text = `${card_type} ***-${last_four_digits}`
    }
  } else if (payment_method === 'paypal') {
    image = paypal
    text = `PayPal`
  }


  return <div className="row">
    <div className="col-12">
      <h4><img src={image} style={{ width: 30 }}/> {text}</h4>
    </div>
    {expiry_date ? <div className="col-12 small">
      {translate({
        fr: `Expire`,
        en: `Expiration date`,
      })} {expiry_date}
    </div> : null}
  </div>
}

const DurationSwitch = () => {
  const french = useFrench()

  return <div className="row">
    <div className="col-12">
      <NavigationButton to={'/subscription/upgrade'}><FaArrowRight/> {french(`Voir les options`)}</NavigationButton>
    </div>
  </div>
}

const ManageSubscription = () => {
  const subscription_details = useCachedContext('subscription_details')
  const translate = useTranslate()

  const company = useCompany()

  React.useEffect(() => {
    subscription_details.refresh()
  }, [])


  const is_cancellable = subscription_details && subscription_details.state !== 'deleted'

  const is_cheque_france_num = company.subscription.subscription_plan_id === 'cheque_france_num'

  return <BillItem label={translate({
    fr: 'Gérer',
    en: 'Manage',
  })}
                   content={translate({
                     fr: 'Abonnement',
                     en: 'Subscription',
                   })}
                   toggle_label={translate({
                     fr: 'Mettre à jour, annuler et plus',
                     en: 'Update, cancel and more',
                   })}
                   toggle_content={<div className="row">
                     <div className="col-12">
                       <hr/>
                     </div>
                     {is_cheque_france_num ? null : <div className="col-12">
                       <h4>{translate({
                         fr: `Mettre fin à l'abonnement`,
                         en: `Cancel subscription`,
                       })}</h4>
                     </div>}
                     {is_cancellable ? <>
                       <div className="col-12">
                         {translate({
                           fr: `En mettant fin à ton abonnement, tu perdras l'accès aux fonctionnalités d'Intellifox.`,
                           en: `By cancelling your subscription, you will lose access to all Intellifox functions.`,
                         })}
                       </div>
                       <div className="col-12">

                         <NavigationButton className="btn-sm" button={'secondary'} outline={true}
                                           to={'/subscription/cancel'}>{translate({
                           fr: `Mettre fin à l'abonnement et à mon accès à Intellifox`,
                           en: `End subscription and revoke my access to Intellifox`,
                         })}</NavigationButton></div>
                     </> : <div className="col-12">
                       <>
                         <FaCheck/> {is_cheque_france_num ? `L'abonnement pourra être prolongé une fois la date de fin atteinte, en formule annuelle ou mensuelle.` : translate({
                         fr: `Ton abonnement est déjà annulé`,
                         en: `Your subscription is already canceled`,
                       })}</>
                     </div>}
                   </div>}
  />
}

const BillsV2 = () => {

  const translate = useTranslate()
  const company = useCompany()
  const is_cheque_france_num = company.subscription.subscription_plan_id === 'cheque_france_num'

  return <>
    <div className="row">
      <div className="col-12 col-lg-4 mb-2 mb-lg-0">
        <BillItem label={translate({
          fr: 'Abonnement',
          en: 'Subscription',
        })}
                  content={<PlanLabel/>}
                  toggle_label={is_cheque_france_num ? null : translate({
                    fr: `Changer l'abonnement`,
                    en: `Change subscription`,
                  })}
                  toggle_content={
                    <DurationSwitch/>
                  }
        />
      </div>
      <div className="col-12 col-lg-4 mb-2 mb-lg-0">
        <NextPaymentOrEnd/>
      </div>
      <div className="col-12 col-lg-4 mb-2 mb-lg-0">
        <ManageSubscription/>
      </div>
    </div>
  </>
}

const display_currency_like_a_human = (currency) => {
  if (currency === 'EUR') {
    return '€'
  }
  return currency
}


const download_bill = async ({ company_id, bill_url, output_file_name }) => {
  try {
    const client = get_client()
    const response = await client.request(GET_BILL_AS_PDF, {
      company_id,
      bill_url,
    })
    const url = response.get_bill_as_pdf

    await force_download(url, output_file_name)
  } catch (e) {
    console.log(e)
  }
}
export const DownloadBill = ({ bill_url, output_file_name }) => {

  const company_id = useCompanyId()
  const translate = useTranslate()

  const [download_in_progress, set_download_in_progress] = React.useState(false)

  if (download_in_progress) {
    return <>
      Téléchargement...
    </>
  }

  return <><Button onClick={async () => {

    set_download_in_progress(true)
    await download_bill({
      company_id,
      bill_url,
      output_file_name,
    }).catch(e => console.log(e))
    set_download_in_progress(false)

  }} className={'btn-sm'}><FaDownload/> {translate({
    fr: `Télécharger la facture en PDF`,
    en: `Download as PDF`,
  })}</Button>
  </>
}
const Bills = ({ page = 1 }) => {
  const user = useContext(UserContext)
  const company_id = useCompanyId()
  const translate = useTranslate()

  const bills = useQuery(GET_BILLS, {
    variables: {
      company_id,
      page,
    },
  })

  if (bills.loading) {
    return <DefaultLoading/>
  }

  if (bills.error) {
    return <DefaultError/>
  }


  const all_bills = bills.data.bills || []

  return <>
    {all_bills.map(({ receipt_url, date, amount, currency }) => {

      return <BillItem content={moment(date).format('D MMMM YYYY')}
                       label={<>{amount.toFixed(2)} {display_currency_like_a_human(currency)}</>}
                       toggle_label={receipt_url ? translate({
                         fr: `Télécharger la facture, modifier l'adresse, ajouter numéro de TVA`,
                         en: `Download as PDF, edit address or add VAT number`,
                       }) : null}
                       toggle_content={receipt_url ? <div className="row">
                         <div className="col-12 mb-1">
                           <ExternalLinkButton button="secondary" to={receipt_url}
                                               className={'btn-sm'}><FaEdit/> {translate({
                             fr: `Modifier l'adresse ou ajouter numéro de TVA sur la facture`,
                             en: `Edit address or add VAT number on the bill`,
                           })}</ExternalLinkButton>
                         </div>
                         <div className="col-12 font-weight-bold">
                           <DownloadBill bill_url={receipt_url}
                                         output_file_name={`Intellifox-${moment(date).format('YYYY-MMMM-D')}-${amount.toFixed(2)}-${currency}.pdf`}/>
                         </div>
                       </div> : null}
      />
    })}

    {all_bills.length >= 15 ? <Bills page={page + 1}/> : null}
  </>
}


const ShowCurrentTier = ({ company }) => {
  const { subscription_tier } = company

  return <div className="row align-items-center">
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <h1>Formule actuelle : {subscription_tier}</h1>
        </div>
        <div className="col-12">
          <ShowPlans subscription_tier={subscription_tier}/>
        </div>
      </div>

    </div>
  </div>
}

const ShowPlans = ({ subscription_tier }) => {
  const limits = useCachedContext('limits')
  const limits_single_amazon_account = useCachedContext('limits', 'single_amazon_account')
  const limits_unlimited_amazon_accounts = useCachedContext('limits', 'unlimited_amazon_accounts')
  const limits_agency = useCachedContext('limits', 'agency')

  if (
    limits.loading
    || limits_single_amazon_account.loading
    || limits_unlimited_amazon_accounts.loading
    || limits_agency.loading
  ) {
    return <DefaultLoading/>
  }

  if (
    limits.error
    || limits_single_amazon_account.error
    || limits_unlimited_amazon_accounts.error
    || limits_agency.error
  ) {
    return <DefaultError/>
  }

  /*
products
products_limit
ai_bg_removal
ai_bg_removal_limit
amazon_accounts
amazon_accounts_limit
publish
publish_limit
   */


  return <div className="row">
    <div className="col-12">
      <table className="table">
        <thead>
        <tr>
          <th></th>
          <th>Essai gratuit</th>
          <th>Un seul compte Amazon</th>
          <th>Comptes Amazon illimités</th>
          <th>Agence</th>
        </tr>
        </thead>
        <tbody>
        <Limit subscription_tier={subscription_tier} count={limits.products} limit={limits.products_limit}
               single_amazon_account={limits_single_amazon_account.products_limit}
               unlimited_amazon_accounts={limits_unlimited_amazon_accounts.products_limit}
               agency={limits_agency.products_limit}
        >
          Nombre de produits gérés
        </Limit>
        <Limit subscription_tier={subscription_tier} count={limits.ai_bg_removal} limit={limits.ai_bg_removal_limit}
               single_amazon_account={limits_single_amazon_account.ai_bg_removal_limit}
               unlimited_amazon_accounts={limits_unlimited_amazon_accounts.ai_bg_removal_limit}
               agency={limits_agency.ai_bg_removal_limit}
        >
          IA de suppression d'arrière-plan par mois
        </Limit>
        <Limit subscription_tier={subscription_tier} count={limits.amazon_accounts} limit={limits.amazon_accounts_limit}
               single_amazon_account={limits_single_amazon_account.amazon_accounts_limit}
               unlimited_amazon_accounts={limits_unlimited_amazon_accounts.amazon_accounts_limit}
               agency={limits_agency.amazon_accounts_limit}
        >
          Comptes Amazon
        </Limit>
        <Limit subscription_tier={subscription_tier} count={limits.publish} limit={limits.publish_limit}
               single_amazon_account={limits_single_amazon_account.publish_limit}
               unlimited_amazon_accounts={limits_unlimited_amazon_accounts.publish_limit}
               agency={limits_agency.publish_limit}
        >
          Publications de produits
        </Limit>
        </tbody>
        <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td><Button><FaArrowUp/> Mettre à niveau</Button>
          </td>
          <td><Button><FaArrowUp/> Mettre à niveau</Button>
          </td>
          <td><Button><FaArrowUp/> Mettre à niveau</Button>
          </td>
        </tr>
        </tfoot>
      </table>

    </div>
  </div>
}

const Limit = ({
                 children, count, limit,
                 single_amazon_account,
                 unlimited_amazon_accounts,
                 agency,
               }) => {
  return <tr>
    <td className="font-weight-bold small text-uppercase">
      {children}
    </td>
    <td>{limit === -1 ? <IoMdInfinite/> : limit}</td>
    <td>{single_amazon_account === -1 ? <IoMdInfinite/> : single_amazon_account}</td>
    <td>{unlimited_amazon_accounts === -1 ? <IoMdInfinite/> : unlimited_amazon_accounts}</td>
    <td>{agency === -1 ? <IoMdInfinite/> : agency}</td>
  </tr>
}
