import { create_query } from './_query_and_mutations_templates'

export const plans = create_query({
  query: 'plans',
  params: {
    currency: 'String',
    special: 'String',
  },
  output: `
  {
    subscription_tier
    label_en
    description_en
    products_limit
    accounts_limit
    monthly_ai_bg_removal_limit
    managed_ads_limit
    monthly_plan_id
    yearly_plan_id
    yearly_price
    monthly_price
    monthly_price_reduced
    yearly_price_reduced
    reduced_coupon
    free_trial_days
  }
  `
})
export const plan = `
  query plan($subscription_plan_id: String) {
    plan(subscription_plan_id:$subscription_plan_id) {
      billing_interval
    }
  }
`


export const free_trial_url_checkout = create_query({
  query: 'free_trial_url_checkout',
  params: {
    company_id: 'ID!',
    plan_id: 'Int',
    currency_code: 'String',
  },
})
