export const set_4_water_hose = [[{
  'type': 'shape',
  'object_id': '1',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'shape_id': 'set_4_splash_1.svg', 'fill': '#ffffff' },
}, {
  'type': 'image',
  'object_id': '2',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': null,
  'props': {
    'image_id': 'Nj9M9Ndog',
    'image_object_fit': 'contain',
    'filters': {
      'border_width': 5,
      'border_color': 'black',
      'border': false,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.5)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '11',
  'x': 57.029622807162696,
  'y': 285.7817794404134,
  'width': 140.8782604852624,
  'height': 135.20948807455983,
  'angle': 0,
  'props': { 'shape_id': 'set_4_splash_3.svg', 'fill': 'white', 'group_id': 'group_ws6r8rfgflh' },
}, {
  'type': 'shape',
  'object_id': '12',
  'x': 41.78144498785646,
  'y': 145.29959916291588,
  'width': 169.28760274796707,
  'height': 134.4495402399248,
  'angle': 0,
  'props': { 'shape_id': 'set_4_splash_2.svg', 'fill': 'white', 'group_id': 'group_uxb7moo5fok' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': -82.7083073857267,
  'y': 310.17864313378476,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -73.84418981193176,
  'y': 321.67384801608637,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -78.27624859882923,
  'y': 333.169052898388,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': -104.98613309754722,
  'y': 2.5962445142852175,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': -96.12201552375228,
  'y': -13.908550603413175,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': -100.55407431064975,
  'y': -22.41334572111157,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 58.68129080664738,
  'y': 34.61078853393802,
  'width': 299.0633900490248,
  'height': 86.67617219152882,
  'angle': -0.12469074003000613,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>EXTENSION ARROSAGE</i></p>',
    'fontSize': 53,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 52,
    'filters': { 'repeat_x': -2, 'repeat_y': 2, 'repeat': true, 'repeat_value': 4 },
  },
}, {
  'type': 'image',
  'object_id': '13',
  'x': 221.22519413287318,
  'y': 77.13546160483176,
  'width': 280,
  'height': 310.7522567703109,
  'angle': null,
  'props': {
    'image_id': 'Nj9M9Ndog',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.57)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '14',
  'x': 74.41760138050043,
  'y': 163.25855666548145,
  'width': 99.65487489214838,
  'height': 78.51596203623814,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>TROIS LONGUEURS DISPONIBLES</i></p>',
    'fontSize': 17,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_uxb7moo5fok',
  },
}, {
  'type': 'text',
  'object_id': '15',
  'x': 72.69197584124244,
  'y': 330.2128275886911,
  'width': 104.55565142364107,
  'height': 42.4331320103538,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>20,99 €</p>',
    'fontSize': 24,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_ws6r8rfgflh',
  },
}, {
  'type': 'text',
  'object_id': '16',
  'x': 74.72821397756687,
  'y': 311.817659340201,
  'width': 99.65487489214838,
  'height': 19.689387402933562,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>à partir de</p>',
    'fontSize': 13,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_ws6r8rfgflh',
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'C' },
}, {
  'type': 'image',
  'object_id': '17',
  'x': 176.2259245694372,
  'y': 140.3516304813919,
  'width': 147.54815086112555,
  'height': 221.3222262916883,
  'angle': null,
  'props': { 'image_id': 'CcaS3U1bo' },
}, {
  'type': 'shape',
  'object_id': '11',
  'x': 19.49726732830159,
  'y': 225.81629195119854,
  'width': 140.8782604852624,
  'height': 135.20948807455983,
  'angle': 0,
  'props': { 'shape_id': 'set_4_splash_3.svg', 'fill': 'white', 'group_id': 'group_ws6r8rfgflh' },
}, {
  'type': 'shape',
  'object_id': '12',
  'x': 335.5691930465277,
  'y': 194.9113334165828,
  'width': 169.28760274796707,
  'height': 134.4495402399248,
  'angle': -0.7103310460865826,
  'props': { 'shape_id': 'set_4_splash_2.svg', 'fill': 'white', 'group_id': 'group_uxb7moo5fok' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': -82.7083073857267,
  'y': 310.17864313378476,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -73.84418981193176,
  'y': 321.67384801608637,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -78.27624859882923,
  'y': 333.169052898388,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': -35.96111152722801,
  'y': -30.622047116430917,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_q5md8l722mh', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': -27.09699395343307,
  'y': -47.12684223412931,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_q5md8l722mh', 'image_object_reverse': true },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': -31.529052740330542,
  'y': -55.631637351827706,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_q5md8l722mh', 'image_object_reverse': true },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 105.70458675142734,
  'y': 364.6366729170269,
  'width': 299.0633900490248,
  'height': 86.67617219152882,
  'angle': -0.12469074003000613,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>EXTENSION ARROSAGE</i></p>',
    'fontSize': 53,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 52,
    'filters': { 'repeat_x': -2, 'repeat_y': 2, 'repeat': true, 'repeat_value': 4 },
  },
}, {
  'type': 'text',
  'object_id': '14',
  'x': 364.7540983606557,
  'y': 214.59591645840635,
  'width': 99.65487489214838,
  'height': 78.51596203623814,
  'angle': -0.34542144242021056,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>TROIS LONGUEURS DISPONIBLES</i></p>',
    'fontSize': 17,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_uxb7moo5fok',
  },
}, {
  'type': 'text',
  'object_id': '15',
  'x': 35.15962036238133,
  'y': 270.2473400994762,
  'width': 104.55565142364107,
  'height': 42.4331320103538,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>20,99 €</p>',
    'fontSize': 24,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_ws6r8rfgflh',
  },
}, {
  'type': 'text',
  'object_id': '16',
  'x': 37.19585849870576,
  'y': 251.8521718509861,
  'width': 99.65487489214838,
  'height': 19.689387402933562,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>à partir de</p>',
    'fontSize': 13,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_ws6r8rfgflh',
  },
}, {
  'type': 'shape',
  'object_id': '18',
  'x': 123.47255801632923,
  'y': 22.50408578931794,
  'width': 169.28760274796707,
  'height': 134.4495402399248,
  'angle': 0,
  'props': { 'shape_id': 'set_4_splash_2.svg', 'fill': 'white', 'group_id': 'group_oi7r8fdeed' },
}, {
  'type': 'text',
  'object_id': '19',
  'x': 156.10871440897319,
  'y': 40.46304329188351,
  'width': 99.65487489214838,
  'height': 78.51596203623814,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>TROIS LONGUEURS DISPONIBLES</i></p>',
    'fontSize': 17,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_oi7r8fdeed',
  },
}, {
  'type': 'image',
  'object_id': '20',
  'x': 376.96289905090595,
  'y': 149.88495873623205,
  'width': 83.50690484095233,
  'height': 68.3507976117952,
  'angle': null,
  'props': {
    'image_id': 'mpPk3JCmu',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 3,
      'border_color': 'black',
      'border': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '21',
  'x': 256.60051768766175,
  'y': 7.5208517474486385,
  'width': 108.5849870578084,
  'height': 81.57718445223367,
  'angle': null,
  'props': {
    'image_id': 'YSH26jlHn',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 3,
      'border_color': 'black',
      'border': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '22',
  'x': 25.090595340811035,
  'y': 171.61058427549003,
  'width': 108.5849870578084,
  'height': 81.57718445223367,
  'angle': null,
  'props': {
    'image_id': 'nGOm_FY7b',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 3,
      'border_color': 'black',
      'border': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': -436.5470930326691,
  'y': -120.35985132939467,
  'width': 1812.8894231967913,
  'height': 620.8574071320631,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_ezgclfzfz2' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': -419.8775503234371,
  'y': -111.88702993163795,
  'width': 1812.8894231967913,
  'height': 620.8574071320631,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_ezgclfzfz2' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -415.2873863890108,
  'y': -96.08029999203484,
  'width': 1812.8894231967913,
  'height': 620.8574071320631,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_ezgclfzfz2' },
}, {
  'type': 'text',
  'object_id': '7',
  'x': -28.894205310695156,
  'y': 5.706560751366823,
  'width': 299.0633900490248,
  'height': 86.67617219152882,
  'angle': -0.12469074003000613,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>EXTENSION ARROSAGE</i></p>',
    'fontSize': 30,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 30,
    'filters': {
      'repeat_x': -2,
      'repeat_y': 2,
      'repeat': true,
      'repeat_value': 4,
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': '#ffffff',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '10',
  'x': 280.19844693701464,
  'y': 15.717573058924046,
  'width': 160.48317515099222,
  'height': 248.0586712683348,
  'angle': 0,
  'props': {
    'fill': 'white',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>+ Matériaux solides</p>\n<p>+ Résiste à l\'épreuve du temps</p>\n<p>+ Technologie anti-fuite</p>\n<p>+ Satisfait ou remboursé</p>',
    'fontSize': 24,
    'fontFamily': 'Tomorrow',
    'fontWeight': 300,
    'lineHeight': 20,
    'filters': { 'repeat_x': -2, 'repeat_y': 2, 'repeat': false, 'repeat_value': 5 },
  },
}, {
  'type': 'image',
  'object_id': '11',
  'x': 22.778257118205346,
  'y': 65.4874892148404,
  'width': 201.61622152076848,
  'height': 223.7596279967205,
  'angle': null,
  'props': { 'image_id': 'Nj9M9Ndog' },
}, {
  'type': 'text',
  'object_id': '12',
  'x': 2.15703192407247,
  'y': 277.78257118205346,
  'width': 500,
  'height': 50,
  'angle': -0.06463724442456341,
  'props': {
    'fill': 'black',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>RESSENTEZ LA DIFFÉRENCE !</p>',
    'fontSize': 21,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
  },
}, {
  'type': 'image',
  'object_id': '13',
  'x': 49.24935289042277,
  'y': 316.32854834770154,
  'width': 130.0580056971947,
  'height': 144.3422099337472,
  'angle': null,
  'props': {
    'image_id': 'Nj9M9Ndog',
    'filters': { 'contrast': true, 'contrast_value': 0, 'blur': true, 'blur_value': 0.4 },
    'image_object_reverse': true,
  },
}, {
  'type': 'text',
  'object_id': '14',
  'x': -3.0592487689661993,
  'y': 389.1186855438766,
  'width': 250.86699873064399,
  'height': 22.55881311873547,
  'angle': -0.06463724442456341,
  'props': {
    'fill': 'black',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>PRODUIT GÉNÉRIQUE</p>',
    'fontSize': 14,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'background': 'F',
  },
}, {
  'type': 'text',
  'object_id': '15',
  'x': 210.31061259706644,
  'y': 320.2904807379577,
  'width': 286.4538395168248,
  'height': 172.28645383951687,
  'angle': 0,
  'props': {
    'fill': 'black',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>- Faible pression</p>\n<p>- Qualité inférieure</p>\n<p>- Fuite assurée dans les deux ans</p>\n<p>- Connecteurs en plastique</p>\n<p><br></p>',
    'fontSize': 24,
    'fontFamily': 'Tomorrow',
    'fontWeight': 300,
    'lineHeight': 14,
    'filters': { 'repeat_x': -2, 'repeat_y': 2, 'repeat': false, 'repeat_value': 5 },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'C' },
}, {
  'type': 'shape',
  'object_id': '3',
  'x': -33.50353601395733,
  'y': 349.94007454531555,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '2',
  'x': -24.639418440162387,
  'y': 361.43527942761716,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '1',
  'x': -29.07147722705986,
  'y': 372.93048430991877,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 0,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_wyw8sar9ss' },
}, {
  'type': 'shape',
  'object_id': '8',
  'x': -104.98613309754722,
  'y': 2.5962445142852175,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'D', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'shape',
  'object_id': '9',
  'x': -96.12201552375228,
  'y': -13.908550603413175,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'E', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'shape',
  'object_id': '10',
  'x': -100.55407431064975,
  'y': -22.41334572111157,
  'width': 701.935231049405,
  'height': 172.04247987492897,
  'angle': 3.141592653589793,
  'props': { 'shape_id': 'wave.svg', 'fill': 'F', 'group_id': 'group_q5md8l722mh' },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 15.76113808249221,
  'y': 12.397532387954456,
  'width': 288.83465895775976,
  'height': 83.19744462112335,
  'angle': -0.12469074003000613,
  'props': {
    'fill': 'A',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p><i>EXTENSION ARROSAGE</i></p>',
    'fontSize': 40,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 38,
    'filters': { 'repeat_x': -2, 'repeat_y': 2, 'repeat': true, 'repeat_value': 4 },
  },
}, {
  'type': 'rect',
  'object_id': '17',
  'x': 18.489065606361805,
  'y': 219.28429423459247,
  'width': 285.6858846918489,
  'height': 95.60139316687761,
  'angle': -0.0576511294183595,
  'props': {
    'fill': '#ffffff',
    'opacity': 1,
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 0,
      'drop_shadow_spread': 1,
      'drop_shadow_color': 'black',
      'drop_shadow': true,
    },
    'group_id': 'group_kviz8a3maw',
  },
}, {
  'type': 'text',
  'object_id': '15',
  'x': 24.978258147405427,
  'y': 216.89274806582827,
  'width': 111.20464443708761,
  'height': 31.39254172132769,
  'angle': -0.07783110197081308,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>5 sur 5 ★</p>',
    'fontSize': 20,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_kviz8a3maw',
  },
}, {
  'type': 'text',
  'object_id': '18',
  'x': 28.33001988071564,
  'y': 240.3330035048498,
  'width': 262.92246520874755,
  'height': 72.56461232604372,
  'angle': -0.06060530132968451,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>"C\'est la première extension de ce type que j\'achète et je ne pourrais plus m\'en passer [...]"</p>\n<p>John - Amazon.fr</p>',
    'fontSize': 16,
    'fontFamily': 'Lato',
    'fontWeight': 400,
    'lineHeight': 14,
    'group_id': 'group_kviz8a3maw',
  },
}, {
  'type': 'rect',
  'object_id': '19',
  'x': 156.28230616302184,
  'y': 308.8667992047714,
  'width': 285.6858846918489,
  'height': 95.60139316687761,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'opacity': 1,
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 0,
      'drop_shadow_spread': 1,
      'drop_shadow_color': 'black',
      'drop_shadow': true,
    },
    'group_id': 'group_q0oi2jigol',
  },
}, {
  'type': 'text',
  'object_id': '20',
  'x': 166.74764184521854,
  'y': 304.4871814654307,
  'width': 111.20464443708761,
  'height': 31.39254172132769,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>5 sur 5 ★</p>',
    'fontSize': 20,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_q0oi2jigol',
  },
}, {
  'type': 'text',
  'object_id': '21',
  'x': 166.12326043737568,
  'y': 329.9155084750287,
  'width': 262.92246520874755,
  'height': 72.56461232604372,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>"C\'est la première extension de ce type que j\'achète et je ne pourrais plus m\'en passer [...]"</p>\n<p>John - Amazon.fr</p>',
    'fontSize': 16,
    'fontFamily': 'Lato',
    'fontWeight': 400,
    'lineHeight': 14,
    'group_id': 'group_q0oi2jigol',
  },
}, {
  'type': 'rect',
  'object_id': '22',
  'x': 97.7534791252485,
  'y': 125.08946322067595,
  'width': 285.6858846918489,
  'height': 95.60139316687761,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'opacity': 1,
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 0,
      'drop_shadow_spread': 1,
      'drop_shadow_color': 'black',
      'drop_shadow': true,
    },
    'group_id': 'group_n94a43fv0p',
  },
}, {
  'type': 'text',
  'object_id': '23',
  'x': 108.2188148074452,
  'y': 120.70984548133526,
  'width': 111.20464443708761,
  'height': 31.39254172132769,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>5 sur 5 ★</p>',
    'fontSize': 20,
    'fontFamily': 'Tomorrow',
    'fontWeight': 600,
    'lineHeight': 16,
    'group_id': 'group_n94a43fv0p',
  },
}, {
  'type': 'text',
  'object_id': '24',
  'x': 107.59443339960234,
  'y': 146.13817249093324,
  'width': 262.92246520874755,
  'height': 72.56461232604372,
  'angle': 0,
  'props': {
    'fill': 'A',
    'textAlign': 'left',
    'verticalAlign': 'baseline',
    'content': '<p>"C\'est la première extension de ce type que j\'achète et je ne pourrais plus m\'en passer [...]"</p>\n<p>John - Amazon.fr</p>',
    'fontSize': 16,
    'fontFamily': 'Lato',
    'fontWeight': 400,
    'lineHeight': 14,
    'group_id': 'group_n94a43fv0p',
  },
}, {
  'type': 'image',
  'object_id': '13',
  'x': 350.5966763646402,
  'y': 47.31438804618364,
  'width': 134.22692731097652,
  'height': 148.96900207582294,
  'angle': null,
  'props': {
    'image_id': 'Nj9M9Ndog',
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.57)',
      'drop_shadow': true,
    },
  },
}]]
