import React, { useEffect, useRef, useState } from 'react'

import './text-area.css'
import RichTextEditor from 'react-rte'
import { apply_variables, clean_for_label } from '../../routes/product/text-substitution'
import KeywordHighlighter from './keyword-highlighter'
import { useFrench } from '../../context/lang'

let global_textarea_id = 0

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS',/* 'BLOCK_TYPE_DROPDOWN',*/ 'BLOCK_TYPE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    // { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    // { label: 'Normal', style: 'unstyled' },
    // { label: 'Heading Large', style: 'header-one' },
    //   { label: 'Heading Medium', style: 'header-two' },
    //   { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

const empty_config = {
  display: ['INLINE_STYLE_BUTTONS'],
  INLINE_STYLE_BUTTONS: [],
}

export const badge = (length, count_min, count_max) => {
  if (length > count_max) return 'danger'
  if (length < count_min) return 'warning'
  return 'success'
}

export const Count = ({ total, count_min, count_max, label= 'caractère(s)' }) => {
  const french = useFrench()

  return <span
      className={`badge badge-${badge(total, count_min, count_max)}`}>{total}/{count_max} {french(label)}</span>

}
export const CountLike = ({ total }) => {
  return <div className="col-auto">
    <h4><span
      className={`badge badge-success`}>{total}</span>
    </h4>
  </div>
}

const TextArea = ({
                    value, setter, help, placeholder, label, validate = () => true,
                    count, count_min, count_max, count_html,
                    show_count_objective = true,
                    show_html,
                    variables = [],
                    keywords,
                    edit = true,
                    margin_bottom = 'mb-3',
                    col = '12',
                    need_to_be_filled,
                    focus_on_launch = false,
                    raw_html = false,

                    get_editor_ref = () => {
                    },
                  }) => {

  const [focus, set_focus] = React.useState(false)
  const textarea = useRef(null)
  const value_set_from_within = useRef(false)

  const editor_state_tmp = RichTextEditor.createEmptyValue()
  const [editor_state, set_editor_state] = useState(editor_state_tmp.setContentFromString(value, 'html'))
  const [textarea_id] = useState(global_textarea_id)
  global_textarea_id = global_textarea_id + 1

  const applied_variables = apply_variables(value, variables)
  const number_of_char_html = applied_variables.length
  const number_of_char_no_html = applied_variables.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').length


  const correctString = (e) => {
    // DEV! IF YOU UPDATE THIS, THINK OF UPDATING api/core/type/Product/product_info_helper.js AS WELL FOR AUTOMATIC APPLY DURING PUBLISH
    const html = e.toString('html')
      .replace(/<strong>/g, '<b>')
      .replace(/<\/strong>/g, '</b>')
      .replace(/<em>/g, '<i>')
      .replace(/<\/em>/g, '</i>')
      .replace(/<img.+?\/>/g, '') // remove imgs
      .replace(/<div.+?<\/div>/g, '') // remove divs
      .replace(/<u>/g, '') // remove u
      .replace(/<\/u>/g, '') // remove /u
      .replace(/<h[1-5][^>]*?>/g, '<b>') // remove /u
      .replace(/<\/h[1-5]>/g, '</b>') // remove /u

      .replace(/<span[^>]*?>/g, '') // remove span
      .replace(/<\/span>/g, '') // remove span


    return html
  }

  useEffect(() => {
    if (!value_set_from_within.current) {
      set_editor_state(editor_state.setContentFromString(value, 'html'))
    }
    value_set_from_within.current = false
  }, [value])
  const editor_ref = useRef(null)

  const was_focused_called_ref = React.useRef(false)

  React.useEffect(() => {
    if (focus_on_launch && !was_focused_called_ref.current) {
      editor_ref.current._focus()
      was_focused_called_ref.current = true
    }
    get_editor_ref(editor_ref)

  }, [editor_ref])


  const total_char = count_html ? number_of_char_html : number_of_char_no_html


  return (
    <>
      <div className={`col-12 col-md-${col} field ${margin_bottom}`}>
        {label ? <label htmlFor={`textarea_${textarea_id}`}>{label}</label> : null}
        {edit ?
          <div className="row">
            <div className="col">
              {!raw_html ?
                <div className={`texteditor-container ${show_html ? '' : 'no-control'} w-100 position-relative`}>
                  {keywords ?
                    <KeywordHighlighter element={editor_ref.current ? editor_ref.current.refs.editor.editor : null}
                                        value={value} keywords={keywords}/> : null}
                  <RichTextEditor
                    className={`force-global-colors-transparent-bg input-global-colors p-0 w-100 ${focus ? 'focus' : ''} ${need_to_be_filled && total_char === 0 ? 'need_to_be_filled' : ''}`}
                    value={editor_state}
                    toolbarConfig={show_html ? toolbarConfig : empty_config}
                    placeholder={placeholder}
                    ref={editor_ref}
                    onChange={(e) => {
                      set_editor_state(e)
                      if (value !== e.toString('html')) {
                        value_set_from_within.current = true
                        setter(correctString(e.toString('html')))
                      }
                    }}
                    onBlur={() => set_focus(false)}
                    onFocus={() => set_focus(true)}
                  />
                </div> :
                <div className={`texteditor-container ${show_html ? '' : 'no-control'} w-100 position-relative`}>
                <textarea
                  className={`force-global-colors-transparent-bg input-global-colors w-100 ${focus ? 'focus' : ''} ${need_to_be_filled && total_char === 0 ? 'need_to_be_filled' : ''}`}
                  value={value} onChange={(e) => setter(e.target.value)} placeholder={placeholder}>

                </textarea>
                </div>}
            </div>

            {count ? <Count count_min={count_min} count_max={count_max} total={total_char}/> : null}

          </div> : <div className="row">
            <div className="col-12">
              <div style={{ padding: 10, paddingLeft: 0 }} className="font-weight-bold">
                {clean_for_label(value, 200, [])}
              </div>
            </div>
          </div>}
        <div className="row">

          <div className="col-12 mt-1">
            {help}
          </div>
        </div>

      </div>


    </>
  )
}


export default TextArea
