import React from 'react'
import { get_client } from '../../graphql/simple-client'
import { GET_USAGE } from '../../graphql/queries/usage'

import query_string from 'query-string'

export default function SsoMastermind() {

  const { sso, sig } = query_string.parse(window.location.search)

  const fetch_sso_mastermind = async () => {
    const client = get_client()
    const response = await client.request(`
      query GetMastermindSSO($sso: String!, $sig:String!) {
        mastermind_sso(sso: $sso, sig:$sig)
      }
    `, {
      sso, sig,
    })
    return response.mastermind_sso
  }

  React.useEffect(() => {
    const go = async () => {
      const url = await fetch_sso_mastermind()

      if (url) {
        window.location = url
      }
    }
    go().catch(e => console.log(e))
  }, [])


  return (
    <div className="row">
      <div className="col-12">
        <h1>Connexion au Mastermind en cours...</h1>
      </div>
      <div className="col-12">
        <small>sso : {sso}</small><br/><small>sig : {sig}</small>
      </div>
    </div>
  )
}
