import { gql } from '@apollo/client'
import { create_mutation } from './_query_and_mutations_templates'

const updates = `
product_id
asin
sku
product_info(market_place: $market_place) {
    sku_override
    asin_override
    updates {
        field
        value
        warning
    }
}`
export const GET_UPDATES = `
    query GetUpdates($company_id: ID!, $state: String!, $market_place: String!) {
        products(company_id: $company_id, state: $state) {
            ${updates}
            variations {
                ${updates}
            }
        }
    }
`
export const GET_UPDATES_SINGLE_PRODUCT = `
    query GetUpdates($company_id: ID!, $product_id: ID!, $market_place: String!) {
        product(company_id: $company_id, product_id: $product_id) {
            ${updates}
            variations {
                ${updates}
            }
        }
    }
`

/*
publish(company_id: ID!, market_place: String!, products: [ProductWithUpdates]!)
 */
export const PUBLISH = create_mutation({
  mutation: 'publish',
  params: {
    company_id: 'ID!',
    merchant_id: 'String',
    market_place: 'String',
    products: '[ProductWithUpdates]',
  },
  output: `{
    product_id
    is_published
  }`,
})

