import useLocalStorage from '../../hooks/useLocalStorage'

import React from 'react'
import { useFrench, useLang } from '../../context/lang'
import Item from '../../components/generic/item'
import { YouTube } from './onboarding_master'
import Button from '../../components/generic/button'
import { FiCheck, FiExternalLink, FiX, GiResize } from 'react-icons/all'
import { useMetaDataAutoUpdate } from '../../components/user/meta_data'
import ExternalLink from '../../components/generic/external-link'
import ExternalLinkButton from '../../components/generic/external-link-button'

export const onboarding_videos_fr = {
  welcome: 'z8nM_xJHdms',
  product_optimization: 'UyNry11gUU4',
  image_editor: 'DtHADb98j28',
  image_editor_variations: '7oUulzAN730',
  profit_calculator: 'u1YprtoN29I',
  ppc_conversion: 'fXgIUHbj3Lc',
}

export const onboarding_videos_en = {
  welcome: 'e6R2zaHsnHo',
  product_optimization: 'zefKOHHqdL4',
  image_editor: 'yHwr-kqhNGU',
  image_editor_variations: '7oUulzAN730',
  profit_calculator: '_a8c8pd_XKA',
  ppc_conversion: 'en9GOp-oZo4',
}

export const useOnboardingVideos = () => {
  const lang = useLang()

  if (lang === 'fr') {
    return onboarding_videos_fr
  }

  return onboarding_videos_en
}

export const OnboardingVideo = ({ video_key }) => {
  const french = useFrench()
  const onboarding_videos = useOnboardingVideos()

  const [is_hidden, set_is_hidden] = useMetaDataAutoUpdate(`onboarding_video_done_${video_key}`, false)

  const [started_hidden, set_started_hidden] = React.useState(is_hidden)

  const [show_big_video, set_show_big_video] = React.useState(false)


  if (is_hidden && started_hidden) {
    return null
  }


  if (is_hidden) {
    return <div className="row">
      <div className="col-12">
        <div className="alert alert-success small mb-5 font-weight-bold">
          <FiCheck/> {french(`Les vidéos de démarrage sont toujours disponibles dans l'onglet "Aide" accessible depuis l'accueil ou avec le menu principal à gauche de l'écran.`)}
        </div>
      </div>
    </div>
  }


  return <div className="row mb-5">
    <div className="col-12">
      <Item>
        <div className="row">
          <div className="col font-weight-bold">
            {french(`Vidéo de prise en main`)}
          </div>
          <div className="col-auto">
            <ExternalLinkButton className={'btn-sm mr-2'} outline={true}
                                to={`https://youtu.be/${onboarding_videos[ video_key ]}`}><FiExternalLink/> {french(`Ouvrir la vidéo dans un nouvel onglet`)}
            </ExternalLinkButton>

            <Button onClick={() => set_show_big_video(!show_big_video)} outline={true} className={'btn-sm mr-2'}>
              <GiResize/> {french(`Agrandir la vidéo`)}</Button>

            <Button onClick={() => {
              set_is_hidden(true)
              // setTimeout(() => {
              //   set_started_hidden(true)
              // }, 20000)
            }} className={'btn-sm'}><FiX/> {french(`Fermer`)}</Button>
          </div>
          <div className="col-12">
            <hr/>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className={`col-12 ${show_big_video ? '' : 'col-md-6'}`}>
            <YouTube youtubeId={onboarding_videos[ video_key ]} show_link={false}/>
          </div>
        </div>
      </Item>
    </div>
  </div>
}
