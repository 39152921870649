import React from 'react'
import { KeywordSelector } from '../../components/generic/keyword-chooser'
import { useMutation } from '@apollo/client'
import { SELECT_KEYWORD, SELECT_KEYWORDS } from '../../graphql/queries/keyword'
import useCompanyId from '../../hooks/useCompanyId'
import useMarketPlace from '../../hooks/useMarketPlace'
import Field from '../../components/generic/field'
import IntellifoxModal from '../../components/generic/modal'
import { remove_html } from '../product/text-substitution'
import * as _ from 'lodash'
import { await_mutate } from '../../graphql/mutate_promise'
import { useTranslate } from '../../context/lang'
import CopyButton from '../../components/generic/copy-button'

export const AddKeywords = ({ product }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const translate = useTranslate()

  const [keywords_to_add, set_keywords_to_add] = React.useState('')

  const [select_keywords, selectKeywordMutation] = useMutation(SELECT_KEYWORDS)

  const words = _.uniq(remove_html(keywords_to_add).replace(/\n/g, ' ').replace(/[,.;:()]/g, ' ').split(' ').map((w) => w.toLowerCase()).filter(w => !!w).map((w) => /* remove accents*/w.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))

  const body = <div className="container">
    <div className="row">
      <Field type='textarea' value={keywords_to_add} setter={set_keywords_to_add}
             placeholder={translate({
               fr: 'Entrez les mots clés en vrac ici',
               en: 'Enter keywords here',
             })}/>
    </div>
    {words.length > 0 ? <div className="row mt-2">
      <div className="col-12 font-weight-bold">
        {words.length} {translate({
          fr: `mots clés vont être ajoutés :`,
          en: <>keywords will be added:</>,
        })}
      </div>
      <div className="col-12">
        {words.map((word, i) => {
          return <div className="d-inline-block mr-2 badge badge-dark" key={i}>{word}</div>
        })}
      </div>
    </div> : null}
  </div>

  const add_all_keywords = async () => {
    const maximum_intensity = product.product_info.selected_keywords.reduce((max_intensity, { intensity }) => {
      return Math.max(max_intensity, intensity)
    }, 0)

    try {

      await await_mutate(select_keywords, {
        variables: {
          company_id, product_id:
          product.product_id,
          market_place,
          keywords: words.map((keyword, i) => {
            return {
              keyword,
              intensity: maximum_intensity + words.length - i + 10,
              selected: true,
              category: 'black',
            }
          }),
        },
      })
      await product.refresh()
      set_keywords_to_add('')
    } catch (e) {
      console.log('error while adding all keywords', e)
    }

  }

  return <>
    <IntellifoxModal title={translate({
      fr: 'Importer des mots clés',
      en: 'Import keywords',
    })}
                     body={body}
                     action_label={translate({
                       fr: 'Importer les mots clés',
                       en: 'Import these keywords',
                     })}
                     on_click={add_all_keywords}
                     button_props={{ className: 'mb-2' }}>
      {translate({
        fr: `Ajouter des mots clés manuellement`,
        en: `Add keywords manually`,
      })}
    </IntellifoxModal>
  </>
}

export default function EditKeywords_V2({ product }) {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const translate = useTranslate()

  const selected_keywords = product.product_info.selected_keywords
  const really_selected_keywords = selected_keywords.filter(({ selected }) => selected)

  const [selectKeyword, selectKeywordMutation] = useMutation(SELECT_KEYWORD)

  const select = (keyword, intensity, selected, on_update) => {
    selectKeyword({
      variables: {
        company_id, product_id: product.product_id,
        market_place,
        keyword, intensity, selected,
      },
      update: async () => {
        await product.refresh()
        on_update && on_update()
      },
    }).catch(e => console.log(e))
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="row mb-5">
          <div className="col-12 font-weight-bold">
            {translate({
              fr: `Importer des mots clés`,
              en: `Import keywords`,
            })}
          </div>
          <div className="col-12">
            <AddKeywords product={product}/>
          </div>
        </div>
        <div className="row">
          <div className="col font-weight-bold">
            {really_selected_keywords.length} {really_selected_keywords.length > 1 ? translate({
            fr: `mots clés sélectionnés`,
            en: <>selected keywords</>,
          }) : translate({
            fr: `mot clé sélectionné`,
            en: <>selected keyword</>,
          })}
          </div>
          <div className="col-auto mb-2">
            <CopyButton text_to_copy={really_selected_keywords.map(({ keyword }) => keyword).join(' ')}>{translate({
              fr: `Exporter`,
              en: `Export`,
            })}</CopyButton>
          </div>
        </div>
        <div className="row no-gutters">
          {really_selected_keywords.map(({ keyword, selected, intensity }) => {
            return <KeywordSelector key={`${keyword}_${intensity}`} keyword={keyword} selected={true} select={select}
                                    intensity={intensity}/>
          })}
        </div>
      </div>
    </div>
  )
}
