import React, { useEffect } from 'react'
import { ApolloClient, ApolloLink, ApolloProvider, concat, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { GET_IMAGE } from '../../graphql/queries/images'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'

import { GraphQLClient } from 'graphql-request'
import { GET_PRODUCT_WITH_SINGLE_VISUAL } from '../../graphql/queries/product'

import './visual-renderer-standalone.css'
import { DomRenderer, Scaler } from '../../components/visual/renderer/dom_renderer'
import { apply_overrides } from '../../components/visual/override/apply_overrides'
import { DefaultLoading } from '../../components/i18n/translations'
import { DesignerContext } from '../../components/visual/designer_context'
import { GET_SHAPE } from '../../graphql/queries/shape'
import { api_domain } from '../../constants'

export function get_simple_client(token) {
  const endpoint = `${api_domain}/api/graphql`

  return new GraphQLClient(endpoint, {
    headers: {
      authorization: token || null,
    },
  })
}


const get_client = (token) => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: token,
      },
    })

    return forward(operation)
  })

  const httpLink = createUploadLink({
    uri: `${api_domain}/api/graphql`,
  })

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
  })
}


function VisualRendererStandalone({ company_id, market_place = 'fr', product_id, index, width, height }) {
  // const snippets = useQuery(GET_SNIPPETS, {
  //   skip: !company_id,
  //   variables: {
  //     company_id,
  //   },
  // })

  console.log('OK', width, height)

  if (market_place === 'null') {
    market_place = 'fr'
  }

  const [objects_loaded, set_objects_loaded] = React.useState(false)

  const visual = useCachedContext('product_with_single_visual', product_id, market_place, ~~index)

  useEffect(() => {
    document.body.style.width = `${width}px`
    document.body.style.height = `${height}px`
    document.body.style.overflow = 'hidden'
    document.body.className = document.body.className + ' visual_renderer_standalone'
  }, [])

  // http://localhost:3000/studio/visual_renderer_standalone/b77ba9a3-a001-41ee-b363-b068c018e94b/73c62165-4cb3-478c-9ba9-d6d6d9db294d/5f9321e1-22d8-421b-badb-ec921c19529a/3
  if (visual.is_null) {
    return <div style={{
      margin: 0,
      padding: 0,
      left: 0,
      top: 0,
      overflow: 'hidden',
      width,
      height,
      position: 'absolute',
    }} className={`${objects_loaded ? 'objects_loaded' : ''}`}>

      <Scaler scale={width/500}>
        <DomRenderer objects={[]} width={500}
                     height={500} on_objects_loaded={() => {
          set_objects_loaded(true)
        }}/>
      </Scaler>
    </div>
  }

  if (visual.loading || visual.error) {
    return <DefaultLoading/>
  }

  const product = visual.product

  console.log(product, product_id)

  const designer_context = {
    compute_color: (value) => {
      if (!product || !product.color_palette) {
        return value
      }

      // first find if value is a color id in product palette
      const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
      if (palette_color) {
        return palette_color.color
      }

      return value
    },

    product,

    current_product_id: product_id

  }
  return (
    <DesignerContext.Provider value={designer_context}>
      <div style={{
        margin: 0,
        padding: 0,
        left: 0,
        top: 0,
        overflow: 'hidden',
        width,
        height,
        position: 'absolute',
      }} className={`${objects_loaded ? 'objects_loaded' : ''}`}>

        <Scaler scale={width/500}>
          <DomRenderer objects={apply_overrides(visual.objects, visual.overrides)} width={visual.width}
                       height={visual.height} on_objects_loaded={() => {
            set_objects_loaded(true)
          }}/>
        </Scaler>
      </div>
    </DesignerContext.Provider>
  )
}

export default ({ company_id, token, product_id, index, market_place, width = 2500, height = 2500 }) => {
  width = parseInt(width)
  height = parseInt(height)

  const fetch_product_with_single_visual = async (product_id, market_place, index) => {
    const client = get_simple_client(token)

    const response = await client.request(GET_PRODUCT_WITH_SINGLE_VISUAL, {
      company_id, market_place, product_id, index,
    })

    const visual = response.product.product_info.visual_by_index

    return {
      ...visual,
      objects: visual.objects ? visual.objects.map((object) => {
        return {
          ...object,
          props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
        }
      }) : [],
      overrides: visual.overrides ? visual.overrides.map((object) => {
        return {
          ...object,
          props: ( object.props === null || !object.props ) ? {} : JSON.parse(object.props),
        }
      }) : [],
      product: response.product,

    }
  }

  const fetch_image = async (image_id) => {
    if (image_id === null) {
      return {}
    }

    const client = get_simple_client(token)
    const response = await client.request(GET_IMAGE, {
      company_id, image_id,
    })
    return response.image
  }
  const fetch_shape = async (shape_id) => {
    const client = get_simple_client(token)
    const response = await client.request(GET_SHAPE, {
      company_id, shape_id,
    })
    return response.shape
  }

  return <ApolloProvider client={get_client(token)}>
    <CachedValues name={'image'} fetch_value={fetch_image}>
    <CachedValues name={'shape'} fetch_value={fetch_shape}>
      <CachedValues name={'product_with_single_visual'} fetch_value={fetch_product_with_single_visual}>
        <VisualRendererStandalone product_id={product_id} company_id={company_id} index={index}
                                  market_place={market_place} width={width} height={height}/>
      </CachedValues>
    </CachedValues>
    </CachedValues>

  </ApolloProvider>

}
