import React, { useCallback } from 'react'
import { Bar, BarStack, Line } from '@visx/shape'
import { Group } from '@visx/group'
import { Grid } from '@visx/grid'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import moment from 'moment'
import { format_big_number, sum_fees, sum_cash_flow, sum_profits } from './format_numbers'

import * as _ from 'lodash'
import { useWindowSize } from '../../hooks/useWindowSize'
import { GradientDarkgreenGreen, GradientPurpleRed, LinearGradient } from '@visx/gradient'
import {
  accentColor,
  accentColorDark,
  amazonColor,
  background,
  background2,
  profitColor,
} from './orders_graph_cumulated'
import { defaultStyles, Tooltip, TooltipWithBounds, withTooltip } from '@visx/tooltip'
import OrdersSummary, { useOrdersSummary } from './orders_summary'
import Item from '../../components/generic/item'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { is_ready } from '../../hooks/useCachedContext'

const tooltipStyles = {
  position: 'absolute',
  zIndex: 42,
}

// const data = cityTemperature.slice(0, 12)
// const keys = Object.keys(data[ 0 ]).filter(d => d !== 'date')


// accessors
const getDate = (d) => d.day
const getProfits = (d) => d.profits
const getFees = (d) => -d.fees


export const OrdersGraphAxis = ({ height, width, moneyScale, dateScale }) => {


  const money_ticks = moneyScale.ticks()
  const filtered_money_ticks = money_ticks.filter((t, i) => i !== 0 && i !== money_ticks.length - 1)


  return <>
    <svg width={100} height={height + 20} className="position-absolute" style={{ left: -99, top: -10 }}>
      <AxisLeft scale={moneyScale} tickValues={filtered_money_ticks} left={100} top={10} stroke={'transparent'}
                tickStroke={'var(--color-text-global)'}
                tickFormat={(v) => {
                  return `${format_big_number(v, 0)}`
                }}
                tickComponent={({ x, y, formattedValue }) => {
                  return <text transform="" x={x - 4} y={y + 4} fill="var(--color-text-global)" fontSize="10"
                               textAnchor="end">{formattedValue}</text>
                }}
      />
    </svg>
    <svg width={width} height={30} className="position-relative"
         style={{ borderRadius: '30px', overflow: 'hidden', top: -1 }}>
      <AxisBottom scale={dateScale} stroke={'transparent'} tickStroke={'var(--color-text-global)'}
                  tickLabelProps={() => ( {
                    fill: 'var(--color-text-global)',
                    fontSize: 10,
                    textAnchor: 'middle',
                  } )}/>
    </svg>
  </>
}


export default function OrdersGraph({ orders, from, to, amazon_ads_accounts, amazon_accounts, currency }) {
  const window_size = useWindowSize()
  const row_ref = React.useRef(null)
  const [computed_width, set_computed_width] = React.useState(500)

  React.useEffect(() => {
    set_computed_width(row_ref.current.getBoundingClientRect().width - 30)
  }, [window_size])


  return <div className="row" ref={row_ref}>
    <div className="col-12">
      <DisplayOrdersGraph width={computed_width} orders={orders} from={from} to={to}
                          amazon_ads_accounts={amazon_ads_accounts}
                          amazon_accounts={amazon_accounts}
                          currency={currency}
      />
    </div>
  </div>
}

const DisplayOrdersGraph = withTooltip(
  ({
     orders,
     width = 500,
     height = 500,
     margin = { top: 0, right: 0, bottom: 0, left: 0 },
     showTooltip,
     hideTooltip,
     tooltipData,
     tooltipTop = 0,
     tooltipLeft = 0,
     from, to,
     amazon_ads_accounts, amazon_accounts, currency
   }) => {


    // bounds
    const xMax = width
    const yMax = height - margin.top

    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    const orders_summary = useOrdersSummary({ orders, from, to, amazon_ads_accounts, amazon_accounts, currency })


    const data = React.useMemo(() => {
      if (!is_ready(orders_summary)) {
        return []
      }
      const orders_grouped_by_days = _.groupBy(orders.map((o) => {
        return {
          ...o,
          day: moment(o.creation_date).format('YYYYMMDD'),
        }
      }), 'day')

      const summaries = []

      for (let i = moment(from); i.valueOf() <= to.valueOf(); i = moment(i).add(1, 'day')) {

        const day = i.format('YYYYMMDD')

        const orders = orders_grouped_by_days[ day ] ? orders_grouped_by_days[ day ] : []

        const profits = sum_profits({
          ...orders_summary,
          orders,
          filter_ads:(r) => r.date === day
        })

        summaries.push({
          day: parseInt(day),
          date: i.valueOf(),
          cash_flow: sum_cash_flow({
            ...orders_summary,
            orders,
            filter_ads:(r) => r.date === day
          }) - profits,
          fees: -sum_fees({
            ...orders_summary,
            orders,
            filter_ads:(r) => r.date === day
          }),
          profits,
          orders,
        })
      }
      return summaries
    }, [orders, orders_summary])

    const keys = ['fees', 'cash_flow', 'profits']

// scales
    const [dateScale, moneyScale, colorScale] = React.useMemo(() => {
      const dateScale = scaleBand({
        domain: data.map((d) => d.day),
        padding: 0.2,
      })
      const moneyScale = scaleLinear({
        domain: [0, Math.max(...( data.map((d) => d.cash_flow + d.fees + d.profits) ))],
        nice: true,
      })
      const colorScale = scaleOrdinal({
        domain: keys,
        range: [`url('#area-gradient2')`, `url('#area-gradient')`, `url('#area-gradient3')`],
      })
      dateScale.rangeRound([0, xMax])
      moneyScale.range([yMax, 0])

      return [dateScale, moneyScale, colorScale]
    }, [data, xMax, yMax, orders_summary])


    // tooltip handler
    const handleTooltip = useCallback(
      (event, bar) => {
        const d = data[ bar.index ]
        const x = bar.x + bar.width / 2
        const top = bar.y

        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: top,
        })
      },
      [dateScale, data, showTooltip, moneyScale, orders_summary],
    )


    return <div style={{ position: 'relative' }}>
      <svg width={width} height={height} className="list-item-shadow">
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="url(#area-background-gradient)"
          rx={14}
        />
        <LinearGradient id="area-background-gradient" from={background} to={background2}/>

        <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={1}/>
        <LinearGradient id="area-gradient2" from={amazonColor} to={amazonColor} toOpacity={1}/>
        <LinearGradient id="area-gradient3" from={profitColor} to={profitColor} toOpacity={1}/>
        <GradientPurpleRed id="gradient1"/>
        <GradientDarkgreenGreen id="gradient2"/>
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={moneyScale}
          width={xMax}
          height={yMax}
          stroke="black"
          strokeOpacity={0.1}
          xOffset={dateScale.bandwidth() / 2}
        />
        <Group top={margin.top}>
          <BarStack
            data={data}
            keys={keys}
            x={getDate}
            xScale={dateScale}
            yScale={moneyScale}
            color={colorScale}
          >
            {barStacks =>
              barStacks.map(barStack =>
                barStack.bars.map(bar => (
                  <Bar
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    rx={2}
                    onTouchStart={(e) => handleTooltip(e, bar)}
                    onTouchMove={(e) => handleTooltip(e, bar)}
                    onMouseMove={(e) => handleTooltip(e, bar)}
                    onMouseLeave={() => hideTooltip()}
                  />
                )),
              )
            }
          </BarStack>
        </Group>
        {tooltipData && (
          <g>
            <Line
              from={{ x: tooltipLeft, y: margin.top }}
              to={{ x: tooltipLeft, y: innerHeight + margin.top }}
              stroke={accentColorDark}
              strokeWidth={2}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop + 1}
              r={4}
              fill="black"
              fillOpacity={0.1}
              stroke="black"
              strokeOpacity={0.1}
              strokeWidth={2}
              pointerEvents="none"
            />
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={4}
              fill={accentColorDark}
              stroke="white"
              strokeWidth={2}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      <OrdersGraphAxis width={width} height={height} dateScale={dateScale} moneyScale={moneyScale}/>
      {tooltipData && (
        <div>
          <TooltipWithBounds
            top={-10}
            left={-10}
            style={tooltipStyles}
          >
            <Item><h3>{moment(tooltipData.date).format('dddd DD/MM/YYYY')}</h3>
              <OrdersSummary orders={tooltipData.orders} from={moment(tooltipData.date)} to={moment(tooltipData.date).add(1, 'day')}
                             amazon_ads_accounts={amazon_ads_accounts}
                             amazon_accounts={amazon_accounts}
                             currency={currency} />
            </Item>
          </TooltipWithBounds>
          <Tooltip
            top={innerHeight + margin.top - 14}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              minWidth: 72,
              textAlign: 'center',
              transform: 'translateX(-50%)',
            }}
          >
            {getDate(tooltipData)}
          </Tooltip>
        </div>
      )}
    </div>
  })


