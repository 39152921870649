import React from 'react'
import { useFrench } from '../../context/lang'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Item from '../../components/generic/item'
import { FiCheck } from 'react-icons/all'
import { useCachedContext } from '../../hooks/useCachedContext'
import { find_market_place, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import { intellifox_sp_api_url } from '../setting/amazon_accounts'

export default function AmazonConfirmation() {
  const search = new URLSearchParams(window.location.search)

  const spapi_oauth_code = search.get('spapi_oauth_code')
  const merchant_id = search.get('selling_partner_id')
  const state = search.get('state') || ','
  const [origin, market_place] = state.split(',')

  window.location = `${window.location.origin}/${origin}#?merchant_id=${merchant_id}&market_place=${market_place}&spapi_oauth_code=${spapi_oauth_code}&origin=${origin}`

  return null
}


export const AmazonConnectFromSellerCentral = () => {
  const amazon_accounts = useCachedContext('amazon_accounts')
  if (amazon_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error) {
    return <DefaultError/>
  }

  const search = new URLSearchParams(window.location.search)

  const amazon_callback_uri = search.get('amazon_callback_uri')
  const amazon_state = search.get('amazon_state')
  const selling_partner_id = search.get('selling_partner_id')

  const amazon_account = amazon_accounts.find((a) => a.merchant_id === selling_partner_id)
  const market_place = amazon_account && amazon_account.market_places ? amazon_account.market_places[ 0 ] : 'fr'
  const market_place_details = find_market_place(market_place)

  const state = `settings,${market_place || 'fr'}`

  const amazon_url = intellifox_sp_api_url(market_place_details, `settings,${market_place}`)

  console.log('amazon_connect_from_seller_central', {
    amazon_callback_uri,
    amazon_state,
    selling_partner_id,
    amazon_account,
    market_place,
    state,
    amazon_url,
  })
  window.location = amazon_url
  return null

  // return <div className="container">
  //   <div className="row">
  //     <div className="col-12">
  //       <Item>
  //         <ul>
  //           <li>amazon_callback_uri: ${amazon_callback_uri}</li>
  //           <li>amazon_state: ${amazon_state}</li>
  //           <li>selling_partner_id: ${selling_partner_id}</li>
  //         </ul>
  //         <a href={amazon_url}>{amazon_url}</a>
  //       </Item>
  //     </div>
  //   </div>
  // </div>
}
