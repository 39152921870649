import { useDesignerContext } from '../designer_context'
import React from 'react'
import { Dropdown } from 'react-bootstrap'

import { MdVerticalAlignTop } from 'react-icons/md'
import { useTranslate } from '../../../context/lang'
import { move_down, move_to_the_bottom, move_to_the_top, move_up } from './order_objects'
import { FaArrowsAltH, FiMinimize2, MdArrowDownward, MdArrowUpward, MdExpandLess, MdExpandMore } from 'react-icons/all'

export const AlignDropdown = ({ selected_objects }) => {
  const { edit_overrides, queue_action, get_selected_objects, update_multiple_objects, update_objects_save_history, current_objects} = useDesignerContext()
  const translate = useTranslate()

  const handleToggle = (isOpen, event, metadata) => {
    if (isOpen || metadata.source !== 'select') {
      setShow(isOpen)
    }
  }

  const [show, setShow] = React.useState(false)
  return <>
    <Dropdown show={show} onToggle={handleToggle} title="Position">
      <Dropdown.Toggle variant='link'>{translate({
        fr: `Position`,
        en: `Position`,
      })}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_leftest_than_end = props.start_x <= props.end_x
                let arrow_props_update = {}

                if (start_leftest_than_end) {
                  arrow_props_update = {
                    start_x: x,
                    end_x: props.end_x + ( x - props.start_x ),
                  }
                } else {
                  arrow_props_update = {
                    start_x: props.start_x + ( x - props.end_x ),
                    end_x: x,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  x,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <MdVerticalAlignTop style={{ transform: 'rotate(-90deg)' }}/> {translate({
          fr: `Aligner à gauche`,
          en: `Align left`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props, width, height }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_leftest_than_end = props.start_x <= props.end_x
                let arrow_props_update = {}

                const distance_between_start_and_end = Math.abs(props.start_x - props.end_x)

                if (start_leftest_than_end) {
                  arrow_props_update = {
                    start_x: x - distance_between_start_and_end,
                    end_x: props.end_x + ( x - props.start_x ) - distance_between_start_and_end,
                  }
                } else {
                  arrow_props_update = {
                    start_x: props.start_x + ( x - props.end_x ) - distance_between_start_and_end,
                    end_x: x - distance_between_start_and_end,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  x : x - width/2,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <FaArrowsAltH style={{ transform: 'rotate(0deg)' }}/> {translate({
          fr: `Aligner horizontalement`,
          en: `Align horizontally`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props, width, height }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_leftest_than_end = props.start_x <= props.end_x
                let arrow_props_update = {}

                const distance_between_start_and_end = Math.abs(props.start_x - props.end_x)

                if (start_leftest_than_end) {
                  arrow_props_update = {
                    start_x: x - distance_between_start_and_end,
                    end_x: props.end_x + ( x - props.start_x ) - distance_between_start_and_end,
                  }
                } else {
                  arrow_props_update = {
                    start_x: props.start_x + ( x - props.end_x ) - distance_between_start_and_end,
                    end_x: x - distance_between_start_and_end,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  x : x - width,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <MdVerticalAlignTop style={{ transform: 'rotate(90deg)' }}/> {translate({
          fr: `Aligner à droite`,
          en: `Align right`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_leftest_than_end = props.start_y <= props.end_y
                let arrow_props_update = {}

                if (start_leftest_than_end) {
                  arrow_props_update = {
                    start_y: y,
                    end_y: props.end_y + ( y - props.start_y ),
                  }
                } else {
                  arrow_props_update = {
                    start_y: props.start_y + ( y - props.end_y ),
                    end_y: y,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  y,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <MdVerticalAlignTop style={{ transform: 'rotate(0deg)' }}/> {translate({
          fr: `Aligner en haut`,
          en: `Align top`,
        })}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props, width, height }) => {

              return {
                object_id,
                updates: {
                  y : y - height/2,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <FaArrowsAltH style={{ transform: 'rotate(-90deg)' }}/> {translate({
          fr: `Aligner verticalement`,
          en: `Align vertically`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props, width, height }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_higher_than_end = props.start_y <= props.end_y
                let arrow_props_update = {}

                const distance_between_start_and_end = Math.abs(props.start_y - props.end_y)

                if (start_higher_than_end) {
                  arrow_props_update = {
                    start_y: y - distance_between_start_and_end,
                    end_y: props.end_y + ( y - props.start_y ) - distance_between_start_and_end,
                  }
                } else {
                  arrow_props_update = {
                    start_y: props.start_y + ( y - props.end_y ) - distance_between_start_and_end,
                    end_y: y - distance_between_start_and_end,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  y : y - height,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <MdVerticalAlignTop style={{ transform: 'rotate(-180deg)' }}/> {translate({
          fr: `Aligner en bas`,
          en: `Align bottom`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {
          // update_objects_save_history(move_up(current_objects.objects, object))
          queue_action(({ x, y }) => {
            const objects = get_selected_objects()

            update_multiple_objects(objects.map(({ object_id, type, props, width, height }) => {
              if (type === 'arrow') {
                // determine which is the leftest and use it as reference for the other point
                // boy that sucks
                const start_leftest_than_end = props.start_x <= props.end_x
                let arrow_props_update = {}

                const distance_between_start_and_end = Math.abs(props.start_x - props.end_x)

                if (start_leftest_than_end) {
                  arrow_props_update = {
                    start_x: x - distance_between_start_and_end,
                    end_x: props.end_x + ( x - props.start_x ) - distance_between_start_and_end,
                  }
                } else {
                  arrow_props_update = {
                    start_x: props.start_x + ( x - props.end_x ) - distance_between_start_and_end,
                    end_x: x - distance_between_start_and_end,
                  }
                }

                return {
                  object_id,
                  updates: {
                    props: {
                      ...props,
                      ...arrow_props_update,
                    },
                  },
                }
              }

              return {
                object_id,
                updates: {
                  x : x - width/2,
                  y: y - height/2,
                },
              }
            }))
          })
        }} disabled={edit_overrides}>
          <FiMinimize2 style={{ transform: 'rotate(0deg)' }}/> {translate({
          fr: `Aligner les centres des objets`,
          en: `Align objects centers`,
        })}
        </Dropdown.Item>

        <Dropdown.Item onClick={() => {

          let objects = current_objects.objects
          for (let i = 0; i < selected_objects.length; i++) {
            objects = move_up(objects, selected_objects)
          }
          update_objects_save_history(objects)
        }} disabled={edit_overrides}><MdExpandLess/> {translate({
          fr: `Remonter`,
          en: `Up`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_down(current_objects.objects, selected_objects))
        }} disabled={edit_overrides}><MdExpandMore/> {translate({
          fr: `Descendre`,
          en: `Down`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_to_the_top(current_objects.objects, selected_objects))
        }} disabled={edit_overrides}><MdArrowUpward/> {translate({
          fr: `Passer tout devant`,
          en: `To the foreground`,
        })}</Dropdown.Item>
        <Dropdown.Item onClick={() => {
          update_objects_save_history(move_to_the_bottom(current_objects.objects, selected_objects))
        }} disabled={edit_overrides}><MdArrowDownward/> {translate({
          fr: `Passer tout derrière`,
          en: `To the background`,
        })}</Dropdown.Item>


      </Dropdown.Menu>
    </Dropdown>
  </>

}
