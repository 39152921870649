import React from 'react'
import useMarketPlace, { amazon_regions, ShowRegion, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { UPDATE_VISUAL } from '../../graphql/queries/visual'
import Designer from '../../components/visual/designer'

import './edit_visual.css'
import { await_mutate } from '../../graphql/mutate_promise'
import { FullScreenCentered } from '../../components/generic/centered'
import { useProduct, useProductSeveralMarketplaces } from '../../context/products'
import { navigate } from '../../absolute_link'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { IntellifoxModalComponent } from '../../components/generic/modal'
import { useFrench } from '../../context/lang'
import Button from '../../components/generic/button'
import { compute_scale, DomRenderer, Scaler } from '../../components/visual/renderer/dom_renderer'
import Item from '../../components/generic/item'
import { OnlyPremium } from '../../components/subscription/start_free_trial'

export const compute_overrides = (product, visual) => {
  const all_overrides = {
    [ product.product_id ]: visual.overrides,
  }

  for (let i = 0; i < product.variations.length; i++) {
    const variation = product.variations[ i ]
    for (let j = 0; j < variation.product_info.visuals.length; j++) {
      const variation_visual = variation.product_info.visuals[ j ]
      if (variation_visual.visual_id === visual.visual_id) {
        all_overrides[ variation.product_id ] = variation_visual.overrides || []
      }
    }
  }

  return all_overrides
}

export const map_objects = (objects) => {
  if (!objects) {
    return []
  }
  return objects.map(({
                        type,
                        object_id,
                        x,
                        y,
                        width,
                        height,
                        angle,
                        props,
                      }) => ( {
    type,
    object_id,
    x,
    y,
    width,
    height,
    angle,
    props: JSON.stringify(props),
  } ))
}

export const are_the_same = (o1, o2, keys_to_ignore = []) => {
  let are_the_same = true
  if (o1 && o2 && ( o1.length !== o2.length )) {
    return false
  }
  if (( o1 && !o2 ) || ( !o1 && o2 )) {
    return false
  }

  for (let i = 0; i < o1.length; i++) {
    const a = o1[ i ]
    const b = o2[ i ]

    const all_keys = Object.keys({ ...a, ...b }).filter(k => keys_to_ignore.indexOf(k) === -1)

    are_the_same = are_the_same && all_keys.reduce((are_the_same, key) => {
      const has_same_value_for_key = ( a[ key ] === b[ key ] || ( !a[ key ] && !b[ key ] ) ) || ( JSON.stringify(a[ key ]) === JSON.stringify(b[ key ]) )
      if (!has_same_value_for_key) {
        // console.log(a, 'differs from ', b, 'on', key, a[ key ], b[ key ])
      }
      return are_the_same && has_same_value_for_key
    }, true)
  }
  return are_the_same
}

export const are_the_same_objects = (o1, o2) => {
  const keys = Object.keys({ ...o1, ...o2 })

  for (let i = 0; i < keys.length; i++) {
    const key = keys[ i ]
    const a = o1[ key ]
    const b = o2[ key ]

    if (!are_the_same(a, b)) {
      return false
    }
  }

  return true
}

export const indices = [
  {
    index: 0, label: 'Principale', label_translations: {
      fr: 'Principale',
      en: 'Main',
    },
  },
  {
    index: 1, label: 'Image 2', label_translations: {
      fr: 'Image 2',
      en: 'Image 2',
    },
  },
  {
    index: 2, label: 'Image 3', label_translations: {
      fr: 'Image 3',
      en: 'Image 3',
    },
  },
  {
    index: 3, label: 'Image 4', label_translations: {
      fr: 'Image 4',
      en: 'Image 4',
    },
  },
  {
    index: 4, label: 'Image 5', label_translations: {
      fr: 'Image 5',
      en: 'Image 5',
    },
  },
  {
    index: 5, label: 'Image 6', label_translations: {
      fr: 'Image 6',
      en: 'Image 6',
    },
  },
  {
    index: 6, label: 'Image 7', label_translations: {
      fr: 'Image 7',
      en: 'Image 7',
    },
  },
  {
    index: 7, label: 'Image 8', label_translations: {
      fr: 'Image 8',
      en: 'Image 8',
    }, grey: true,
  },
  {
    index: 8, label: 'Image 9', label_translations: {
      fr: 'Image 9',
      en: 'Image 9',
    }, grey: true,
  },
]


export default function EditVisual({ product_id, market_place, region_id, index }) {
  const product = useProduct(product_id, region_id)
  const product_for_market_place = useProduct(product_id, market_place)

  if (product.loading || product_for_market_place.loading) {
    return <DefaultLoading/>
  }

  if (product.error || product_for_market_place.loading) {
    return <DefaultError/>
  }


  return <EditVisualStartWithLoadedProduct product={product} index={~~index}
                                           product_for_market_place={product_for_market_place}
                                           market_place={market_place}
                                           region_id={region_id}
                                           close={() => navigate(`/product/edit/${product_id}/${market_place}/start#?menu=images`)}/>
}
const EditVisualStartWithLoadedProduct = ({ product, close, index, market_place, region_id, product_for_market_place}) => {

  const [current_index, _set_current_index] = React.useState(index)


  React.useEffect(() => {
    _set_current_index(-1)
    setTimeout(() => {
      _set_current_index(index)
    }, 50)
  }, [index])

  React.useEffect(() => {
    setTimeout(() => {
      if (window.$crisp) {
        window.$crisp.push(['set', 'session:event', ['is_editing_a_visual']])
      }
    }, 250)
  }, [])

  if (current_index === -1) {
    return <div className="position-fixed w-100 h-100 edit_visual"
                style={{
                  left: 0,
                  top: 0,
                  background: 'var(--color-bg-global)',
                  zIndex: 1000,
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                }}>
      <FullScreenCentered>
        <h1><DefaultLoading/></h1>
      </FullScreenCentered>
    </div>
  }

  return <Edit__Visual index={current_index} product={product} close={close}
                       product_for_market_place={product_for_market_place}
                       market_place={market_place}
                       region_id={region_id}
  />

}

export const CopyFromOtherRegion = ({ product, region_id, visual, set_objects }) => {
  const french = useFrench()


  const [force_close, set_force_close] = React.useState(false)
  const regions = amazon_regions.reduce((regions, region) => {
    if (product.market_places.indexOf(region.region_id) !== -1 && region.region_id !== region_id) {
      regions.push(region.region_id)
    }
    return regions
  }, [])
  const product_for_regions = useProductSeveralMarketplaces(product.product_id, regions)

  React.useEffect(() => {
    set_force_close(false)
  }, [visual])

  if (product_for_regions.loading || product_for_regions.error) {
    return null
  }

  const available_regions = product_for_regions.reduce((available_regions, product, i) => {
    if (product.product_info && product.product_info.visuals) {
      const visuals = product.product_info.visuals
      const same_index_visual = visuals.find((v) => v.index === visual.index)
      if (same_index_visual && same_index_visual.objects.length > 0) {
        available_regions.push({
          region_id: regions[ i ],
          product,
        })
      }
    }
    return available_regions
  }, [])

  return <IntellifoxModalComponent
    show_modal={visual.objects.length === 0 && available_regions.length > 0 && !force_close}
    close={() => set_force_close(true)}
    title={french(`Démarrer avec l'image d'une autre région`)}
    body={<div className="row">
      <div className="col-12">
        {french(`L'image pour la région actuelle est vide. Tu peux démarrer en copiant directement l'image de ces autres régions où tu as déjà des images :`)}
      </div>
      <div className="col-12">
        {available_regions.map(({ region_id, product }) => {
          const { width, height, objects } = product.product_info.visuals.find((v) => v.index === visual.index)
          const size = 250
          return <Item className={'mb-2'} key={region_id}>
            <div className="row">
              <div className="col-12">
                <ShowRegion region_id={region_id}/>
              </div>
              <div className="col-12">
                <Scaler scale={compute_scale({
                  available_width: size,
                  available_height: size,
                  width,
                  height,
                })} width={size}
                        height={size}>
                  <DomRenderer width={width} height={height} objects={objects}/>
                </Scaler>
              </div>
              <div className="col-12">
                <Button onClick={() => {
                  set_objects(objects)
                  set_force_close(true)
                }}>{french(`Démarrer avec cette image`)}</Button>
              </div>
            </div>
          </Item>
        })}
      </div>
    </div>}
  >
  </IntellifoxModalComponent>

}

function Edit__Visual(
  {
    index, product, close,
    market_place,
    region_id,
    product_for_market_place,
  }) {

  const { product_id, sku } = product

  const company_id = useCompanyId()

  const visual = product.product_info.visuals.find(( (v) => v.index === index )) || {}

  const all_overrides = compute_overrides(product, visual)
  const [objects, set_objects] = React.useState(visual.objects)
  const [overrides, _set_overrides] = React.useState(all_overrides)
  const set_overrides = (whatevs) => {
    // console.log('set_overrides', whatevs)
    const product_ids = [product.product_id]
    for (let i = 0; i < product.variations.length; i++) {
      product_ids.push(product.variations[ i ].product_id)
    }

    const cleaned_stuff = Object.keys(whatevs).reduce((cleaned, product_id) => {
      if (product_ids.indexOf(product_id) !== -1) {
        cleaned[ product_id ] = whatevs[ product_id ]
      }
      return cleaned
    }, {})

    _set_overrides(cleaned_stuff)
  }

  React.useEffect(() => {
    set_objects(visual.objects)
    set_overrides(compute_overrides(product, visual))
  }, [visual, product])

  const [updateVisual, save_mutation] = useMutation(UPDATE_VISUAL)

  const save_visual_for_product = React.useCallback(async (product_id, objects_overrides) => {
    console.log('save_visual_for_product', {product_id, objects_overrides, objects})
    await await_mutate(updateVisual, {
      variables: {
        company_id,
        market_place: region_id,
        product_id,
        visual_id: visual.visual_id,
        visual: {
          width: visual.width,
          height: visual.height,
          objects: map_objects(objects_overrides || objects),
          overrides: map_objects(overrides[ product_id ]),
        },
      },
    })
  }, [updateVisual, company_id, region_id, visual.visual_id, visual.width, visual.height, objects, overrides])

  const save = React.useCallback(async (objects_override) => {
    await save_visual_for_product(product.product_id, objects_override)

    const product_ids = Object.keys(overrides)
    await Promise.all(product_ids.map((product_id) => {
      return save_visual_for_product(product_id, objects_override)
    }))

    await product.refresh()
  }, [save_visual_for_product, product, overrides])

  const has_unsaved_updates = !are_the_same(visual.objects, objects, ['ratio']) || !are_the_same_objects(overrides, compute_overrides(product, visual))


  return (
    <div className="position-fixed w-100 h-100 edit_visual"
         style={{
           left: 0,
           top: 0,
           background: 'var(--color-bg-global)',
           zIndex: 1000,
           overflowX: 'hidden',
           overflowY: 'hidden',
         }}>
      <OnlyPremium fixed={true}>
      <Designer export_label={`${sku}_${visual.index + 1}`}
                product={product}
                product_for_market_place={product_for_market_place}
                product_id={product_id}
                market_place={market_place}
                region_id={region_id}
                close={close}
                visual={visual}
                height={visual.height} width={visual.width}
                objects={objects} update_objects={set_objects}
                initial_objects={visual.objects}
                initial_overrides={all_overrides}
                overrides={overrides} update_overrides={set_overrides}
                history_id={`v8_${visual.visual_id}`}
                save={save}
                save_mutation={save_mutation}
                has_unsaved_updates={has_unsaved_updates}
                index={index}
      />
      </OnlyPremium>
    </div>
  )
}
