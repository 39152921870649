import React from 'react'
import IntellifoxIcon from '../../images/intellifox-icon'
import Button from '../../components/generic/button'
import TextButton from '../../components/generic/text_button'
import { FaArrowRight, GiMineExplosion, MdClose } from 'react-icons/all'
import { useFrench, useTranslate } from '../../context/lang'
import { ShowProgress, TutorialStep } from '../onboarding/onboarding'

import strategy from '../../images/story/strategy.svg'
import search from '../../images/story/search.svg'
import competitors from '../../images/story/competitors.svg'
import keywords from '../../images/story/keywords.svg'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import SearchTermSetter from './search_term_setter'
import SelectCompetitors from './search_term_tutorial_select_competitors'
import SearchTermTutorialSelectKeywords from './search_term_tutorial_select_keywords'
import { useCachedContext } from '../../hooks/useCachedContext'
import useMarketPlace from '../../hooks/useMarketPlace'
import { clean_for_label } from '../product/text-substitution'
import Item from '../../components/generic/item'
import { ProductFirstVisual } from './index'
import { OnlyPremium } from '../../components/subscription/start_free_trial'

const ShowImage = ({ image }) => <div className="col-12 col-lg-6 offset-lg-3">
  <img src={image} className="w-100"/>
</div>

export default function SearchTermTutorial({ product, close, on_finish, current_step, set_current_step }) {
  const translate = useTranslate()
  const market_place = useMarketPlace()
  const french = useFrench()

  const competitors_asin = useCachedContext('competitors_asin', product.product_id, market_place)

  const set_current_step_and_handle_on_finish = (step) => {
    if (step === null) {
      on_finish && on_finish()
      return close()
    }

    set_current_step(step)
  }


  if (competitors_asin.loading) {
    return <DefaultLoading/>
  }

  if (competitors_asin.error) {
    return <DefaultError/>
  }

  const selected_keywords = product.product_info.selected_keywords
  const really_selected_keywords = selected_keywords.filter(({ selected }) => selected)


  const steps = [
    {
      index: 1, title: translate({
        fr: 'La méthode - présentation',
        en: 'The method - summary',
      }),
    },
    {
      index: 2, title: translate({
        fr: `Si tu étais un client...`,
        en: 'If you were a customer...',
      }),
    },
    {
      index: 3, title: translate({
        fr: `Qui sont tes concurrents ?`,
        en: 'Who are your competitors?',
      }),
    },
    {
      index: 4, title: translate({
        fr: `Quels sont les mots clés pertinents pour ton produit ?`,
        en: 'What keywords match your product?',
      }),
    },
  ]

  const step = (step) => steps.find(({ index }) => index === step)


  return <div className="position-fixed w-100 h-100"
              style={{
                left: 0,
                top: 0,
                background: 'var(--color-bg-global)',
                zIndex: 20000,
                overflowX: 'hidden',
                overflowY: 'hidden',
                cursor: 'default',
              }}>

    <div className="w-100 h-100 container-fluid" style={{ perspective: '1000px', perspectiveOrigin: '50% 50%' }}>
      <TutorialStep step={1} current_step={current_step} set_current_step={set_current_step_and_handle_on_finish}
                    next_step={step(2)}
                    title={<><IntellifoxIcon width="2em" height="2em"/><br/>{translate({
                      fr: `C'est parti !`,
                      en: `Let's go!`,
                    })}</>}>
        <div className="col-12">
          <h2>{translate({
            fr: `Les 3 étapes de la méthode`,
            en: `The 3 steps of the method`,
          })}</h2>
        </div>
        <div className="col-12 mb-4">
          <div className="font-weight-bold">{translate({
            fr: `1. Si tu étais un client...`,
            en: `1. If you were a customer...`,
          })}</div>
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `Pour démarrer, tu vas définir la manière dont les clients vont trouver ton produit. C'est une
              étape essentielle qui va te permettre de trouver les concurrents et les bons mots clés pour augmenter le
              nombre de visite sur ta fiche produit.`,
                en: `The first step is to define the search terms that your customers are using to search for your product.`,
              })}
            </div>
          </div>

        </div>
        <div className="col-12 mb-4">
          <div className="font-weight-bold">{translate({
            fr: `2. Qui sont tes concurrents ?`,
            en: `2. Who are your competitors ?`,
          })}</div>
          <p>{french(`Quand un client d'Amazon recherche un produit, ton produit sera forcément noyé parmi les
            concurrents.`)}</p>
          <p>{french(`Sélectionne tes concurrents direct pour :`)}
            <ul>
              <li>{french(`Voir ton produit en situation face à tes concurrents`)}</li>
              <li>{french(`Voir les mots-clés utilisés par tes concurrents`)}</li>
              <li>{french(`Voir les textes (titre, points forts, description) de tes concurrents`)}</li>
              <li>{french(`Voir toutes les images de tes concurrents`)}</li>
            </ul>
          </p>
        </div>
        <div className="col-12 mb-4">
          <div className="font-weight-bold">{translate({
            fr: `3. Quels sont les mots clés pertinents pour ton produit ?`,
            en: `3. What keywords match your product?`,
          })}</div>
          <p>
            {french(`Objectif :`)}
            <ul>
              <li>{french(`Intégrer ces mots-clés à ta fiche produit`)}</li>
              <li>{french(`Augmenter le nombre de recherches qui mènent à ton produit`)}</li>
            </ul>
          </p>
        </div>
        <div className="col-12 mt-5 text-center">
          <Button onClick={(e) => {
            set_current_step_and_handle_on_finish(2)
          }}>{translate({
            fr: `C'est parti !`,
            en: `Let's go!`,
          })} <FaArrowRight/></Button>
        </div>
        <ShowImage image={strategy}/>
      </TutorialStep>


      <TutorialStep step={2} current_step={current_step} set_current_step={set_current_step_and_handle_on_finish}
                    progress_label={translate({
                      fr: 'Étape 1',
                      en: 'Step 1',
                    })}
                    previous_step={step(1)}
                    next_step={step(3)}
                    title={<><IntellifoxIcon width="2em" height="2em"/> {translate({
                      fr: `Si tu étais un client...`,
                      en: `If you were a customer...`,
                    })}</>}>
        <div className="col-12">
          <div className="row">
            <div className="col-12 mb-5">
              <Item>
                <div className="row">
                  <div className="col-auto">
                    <ProductFirstVisual product={product}/>
                  </div>
                  <div className="col">
                    <h4>{clean_for_label(product.product_info.title, 200, [])}</h4>
                  </div>
                </div>

              </Item>
            </div>

          </div>
          <OnlyPremium background={'var(--color-white)'}>

            <div className="col">
              <h5 className="text-uppercase">{translate({
                fr: `Étape 1`,
                en: `Step 1`,
              })}</h5>
            </div>
            <div className="col-12">
              <SearchTermSetter product={product} market_place={market_place}
                                next_step={() => set_current_step_and_handle_on_finish(current_step + 1)}/>
            </div>
            <ShowImage image={search}/>
          </OnlyPremium>
        </div>

      </TutorialStep>


      <TutorialStep step={3} current_step={current_step} set_current_step={set_current_step_and_handle_on_finish}
                    progress_label={translate({
                      fr: 'Étape 2',
                      en: 'Step 2',
                    })}
                    previous_step={step(2)}
                    next_step={step(4)}
                    goal={10}
                    progress={competitors_asin.length}
                    show_progress={true}
                    title={<><IntellifoxIcon width="2em" height="2em"/>{french(`La concurrence`)}</>}>
        <div className="col-12 mb-3">
          <h5 className="text-uppercase">{translate({
            fr: `Étape 2`,
            en: `Step 2`,
          })}</h5>
          <h2>{translate({
            fr: `Qui sont tes concurrents ?`,
            en: `Who are your competitors?`,
          })}</h2>
          <p>{translate({
            fr: `Sélectionne tes 10 plus gros concurrents`,
            en: `Select your 10 biggest competitors`,
          })}</p>
        </div>
        <div className="col-12">
          <OnlyPremium background={'var(--color-white)'}>
            <div className="row">
              <div className="col-12">
                <SelectCompetitors product={product} market_place={market_place}/>
              </div>
              <ShowImage image={competitors}/>
            </div>
          </OnlyPremium>
        </div>

      </TutorialStep>


      <TutorialStep step={4} current_step={current_step} set_current_step={set_current_step_and_handle_on_finish}
                    progress_label={translate({
                      fr: 'Étape 3',
                      en: 'Step 3',
                    })}
                    previous_step={step(3)}
                    next_step={step(5)}
                    goal={100}
                    progress={really_selected_keywords.length}
                    show_progress={true}
                    title={<><IntellifoxIcon width="2em" height="2em"/> {translate({
                      fr: `Les mots-clés`,
                      en: `Keywords`,
                    })}</>}>
        <div className="col-12">
          <OnlyPremium  background={'var(--color-white)'}>
            <SearchTermTutorialSelectKeywords product={product} market_place={market_place}/>
            <div className="row">
              <ShowImage image={keywords}/>
            </div>
          </OnlyPremium>
        </div>
      </TutorialStep>

      <div className="position-absolute text-center p-1 "
           style={{ right: 20, top: 0, zIndex: 9999 }}>
        <TextButton onClick={() => {
          on_finish && on_finish()
          return close()
        }}><h1><MdClose/></h1></TextButton>
      </div>
    </div>
  </div>
}
