import React, { useContext } from 'react'

const ThemeContext = React.createContext({
  theme: 'light',
  set_theme : () => {}
})

export const useTheme = () => useContext(ThemeContext)

export default ThemeContext
