import React, { useCallback, useState } from 'react'
import { useDesignerContext } from '../../designer_context'
import './product_images.css'
import { useCachedContext } from '../../../../hooks/useCachedContext'
import { useMutation } from '@apollo/client'
import {
  ADD_IMAGE,
  CREATE_TRANSPARENT_IMAGE,
  DELETE_IMAGE,
  MAKE_IMAGE_PUBLIC,
} from '../../../../graphql/queries/images'
import { useDropzone } from 'react-dropzone'
import Button from '../../../generic/button'
import imageCompression from 'browser-image-compression'
import { await_mutate } from '../../../../graphql/mutate_promise'
import useCompanyId from '../../../../hooks/useCompanyId'
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import VariationLabel from '../../override/variation_label'
import { Browse_Content_SubTitle } from '../browse__content'
import { DefaultError, DefaultLoading } from '../../../i18n/translations'
import { useFrench, useTranslate } from '../../../../context/lang'
import { IntellifoxModalComponent } from '../../../generic/modal'
import { Checkbox } from '../../../generic/checkbox'
import { IoMdCloudUpload } from 'react-icons/all'
import useLocalStorage from '../../../../hooks/useLocalStorage'
import Item from '../../../generic/item'
import { AIBackgroundRemovalLimit } from '../../../subscription/subscription_options'
import axios from 'axios'
import { get_token } from '../../../../graphql/client'
import { api_domain } from '../../../../constants'

const add_image = async ({ company_id, tags, image, image_name }) => {

  const token = get_token()

  const formData = new FormData()
  formData.append('company_id', company_id)
  formData.append('tags', tags.join(';'))
  formData.append('image', image)
  formData.append('image_name', image_name)

  try {
    const res = await axios.post(
      `${api_domain}/api/upload_api/add_image`,
      formData,
      {
        headers: {
          authorization: token,
        },
      },
    )
    console.log(res)
  } catch (ex) {
    console.log(ex)
  }
}


export const AddImageDropzone = ({ tags = ['GLOBAL'], on_add, label = '' }) => {
  const company_id = useCompanyId()

  const translate = useTranslate()


  const [todo, set_todo] = useState(0)
  const [done, set_done] = useState(0)
  const [error, set_error] = React.useState('')

  const [activate_recompress] = useLocalStorage('activate_image_compression', 'true')
  const [recompress, set_recompress] = useState(activate_recompress === 'true')

  const [files, set_files] = useState([])
  const onDrop = async (acceptedFiles) => {
    const filtered_files = acceptedFiles.filter(file =>
      /image\/jpeg/.test(file.type)
      || /image\/png/.test(file.type)
      || /image\/heif/.test(file.type),
    )
    set_files((files) => ( [...files, ...filtered_files] ))
    set_done(0)
    set_error('')

    set_todo(filtered_files.length)


    for (let i = 0; i < filtered_files.length; i++) {
      const file = filtered_files[ i ]

      try {
        let image = file
        const image_name = file.name

        if (recompress && file.type === 'image/jpeg') {
          image = await imageCompression(file, {
            maxSizeMB: 4,
            useWebWorker: false,
            maxIteration: 20,
          })
        }
        console.log('upload in progress...')

        await add_image({
          company_id,
          tags,
          image,
          image_name,
        })
      } catch (e) {
        set_error(translate({
          fr: `Il y a eu un problème lors de l'ajout des photos.`,
          en: `Something went wrong while the image was uploading.`,
        }))
      } finally {
        set_done(done => done + 1)
        on_add(Date.now())
      }
    }

    set_files([])
    if (done === todo) {
      on_add(Date.now())
    }
  }

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({ onDrop })

  return (
    <div className="row" {...getRootProps()}>
      <div className={'rounded clickable p-4 mb-2'} style={{
        width: '100%',
        transition: 'box-shadow 0.3s, background 0.3s',
        boxShadow: isDragActive ? '0 0 100px black' : '0 0 3px black',
        background: isDragActive ? 'var(--color-white)' : 'transparent',
      }}>
        <Button className={'btn-block'}> <span
          style={{ zIndex: -1 }}><IoMdCloudUpload/> {translate({
          fr: `Ajouter une photo`,
          en: `Upload a picture`,
        })} {label}</span></Button>
        <input {...getInputProps()} accept=".jpg, .png, .gif, .jpeg"/>
      </div>
      <div className="row">
        <div className="col-12">
          {error ? <div className="alert alert-danger font-weight-bold">{error}</div> : null}
        </div>
      </div>

      <div className="row">
        <div className={`col-12`}>
          {files.map((file, i) => i < done ? null :
            <img className={`rounded m-2 ${i === done && todo > 0 ? 'loading' : ''}`} key={i}
                 src={window.URL.createObjectURL(file)} alt=""
                 style={{
                   maxWidth: '100%',
                   maxHeight: files.length > 1 ? 50 : 100,
                 }}/>)}
        </div>
      </div>

      <div className="row small">
      </div>
    </div>
  )
}

const DeleteImageModal = ({ show, handleClose, image_id, url, onSuccess }) => {
  const company_id = useCompanyId()
  const translate = useTranslate()

  const [deleteImage, mutation] = useMutation(DELETE_IMAGE, {
    variables: {
      company_id,
      image_id,
    },
  })

  return <IntellifoxModalComponent show_modal={show} close={handleClose}
                                   title={translate({
                                     fr: `Supprimer l'image`,
                                     en: `Delete picture`,
                                   })}
                                   body={<div className="container">
                                     <div className="row">
                                       <div className="col-12 mb-2">
                                         {translate({
                                           fr: `Êtes-vous sûr de supprimer cette image ?`,
                                           en: `Are you sure you want to delete this picture?`,
                                         })}
                                       </div>

                                       <img src={url} style={{
                                         width: '100%',
                                       }}/>

                                     </div>
                                   </div>}
                                   action_label={translate({
                                     fr: `Supprimer`,
                                     en: `Delete`,
                                   })}
                                   on_click={async () => {
                                     await await_mutate(deleteImage)
                                     onSuccess && onSuccess()
                                   }
                                   }
  />
}
export const CreateTransparentImageModal = ({ show, handleClose, image_id, url, images, on_success }) => {
  const company_id = useCompanyId()
  const translate = useTranslate()
  const french = useFrench()


  const [failure, set_failure] = React.useState(false)

  const [createTransparentImage, mutation] = useMutation(CREATE_TRANSPARENT_IMAGE, {
    variables: {
      company_id,
      image_id,
    },
  })

  const limits = useCachedContext('limits')

  React.useEffect(() => {
    limits.refresh()
  }, [])


  if (limits.loading) {
    return <DefaultLoading/>
  }

  if (limits.error) {
    return <DefaultError/>
  }

  const { ai_bg_removal, ai_bg_removal_limit } = limits

  return <IntellifoxModalComponent
    show_modal={show}
    close={handleClose}
    modal_size={'lg'}
    title={translate({
      fr: `Rendre l'image transparent`,
      en: `Remove background`,
    })}
    body={
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 mb-3">
            <Item no_padding={true}>
              {url ? <img src={url} style={{
                width: '100%',
              }}/> : null}
            </Item>
          </div>
          <div className="col-12 col-lg-4">
            <AIBackgroundRemovalLimit/>
          </div>
        </div>
      </div>
    }
    action_label={translate({
      fr: `Rendre l'image transparente`,
      en: `Remove background`,
    })}
    action_in_progress_label={translate({
      fr: `En cours...`,
      en: `In progress...`,
    })}
    disable_action={ai_bg_removal >= ai_bg_removal_limit}
    on_click={async () => {
      set_failure(false)
      const result = await await_mutate(createTransparentImage)
      if (result.createTransparentImage) {
        images.refresh()
        if (on_success) {
          on_success()
        }
        handleClose()
      } else {
        set_failure(true)
      }
    }}
  />

}

const MakePublicModal = ({ show_modal, close, image_id }) => {
  const [is_public, set_is_public] = React.useState(false)
  const company_id = useCompanyId()
  const translate = useTranslate()

  const [make_image_public, mutation] = useMutation(MAKE_IMAGE_PUBLIC)

  return <IntellifoxModalComponent show_modal={show_modal} close={close} action_label={translate({
    fr: 'Rendre publique',
    en: 'Make it publicly available',
  })}
                                   title={translate({
                                     fr: 'Rendre publique',
                                     en: 'Make it publicly available',
                                   })}
                                   on_click={async () => {
                                     await await_mutate(make_image_public, {
                                       variables: {
                                         company_id,
                                         image_id,
                                         is_public,
                                       },
                                     })
                                   }}
                                   body={<>
                                     <div className="row">
                                       <Checkbox value={is_public} setter={set_is_public}>{translate({
                                         fr: `Publique`,
                                         en: `Public`,
                                       })}</Checkbox>
                                     </div>
                                   </>}
  >

  </IntellifoxModalComponent>
}

const ImageSelector = ({ col_width, url, width, height, image_id, onClick, onDelete, images }) => {

  const [show_delete_modal, set_show_delete_modal] = React.useState(false)
  const [show_transparent_modal, set_show_transparent_modal] = React.useState(false)
  const [show_make_public_modal, set_show_make_public_modal] = React.useState(false)
  const handleClose = () => {
    set_show_delete_modal(false)
    set_show_transparent_modal(false)
    set_show_make_public_modal(false)
  }
  const translate = useTranslate()


  const image_ratio = height / width

  const is_cloudinary = /cloudinary.com/.test(url)
  const is_intellifox = /images.intellifox.net/.test(url)


  return <>
    <div style={{
      width: col_width,
      height: col_width,
    }} className="clickable d-inline-block mr-1 mb-1 position-relative rounded" onClick={() => {
      onClick({
        url,
        width: 2 * col_width,
        height: 2 * col_width * image_ratio,
        image_id,
      })
    }}>
      <img
        src={is_intellifox ? url.replace('original', `resize_${col_width}x${col_width}`) : ( is_cloudinary ? url.replace('upload', `upload/w_${col_width}`) : url )}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}/>
      <div className="position-absolute w-100 text-right pr-2" style={{ bottom: 5, left: 0 }}>
        <Dropdown onSelect={(eventKey) => {
          if (eventKey === 'add') {
            onClick({
              url,
              width: 2 * col_width,
              height: 2 * col_width * image_ratio,
              image_id,
            })
          } else if (eventKey === 'delete') {
            set_show_delete_modal(true)
          } else if (eventKey === 'transparent') {
            set_show_transparent_modal(true)
          } else if (eventKey === 'make_public') {
            set_show_make_public_modal(true)
          } else if (eventKey === 'download') {
            window.open(url, '_blank')
          }
        }}>
          <DropdownButton
            size={'sm'}
            variant={'primary'}
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown.Item eventKey="add">{translate({
              fr: `Insérer`,
              en: `Insert`,
            })}</Dropdown.Item>
            <Dropdown.Item eventKey="download">{translate({
              fr: `Télécharger`,
              en: `Download`,
            })}</Dropdown.Item>
            <Dropdown.Item eventKey="delete">{translate({
              fr: `Supprimer`,
              en: `Delete`,
            })}</Dropdown.Item>
            <Dropdown.Item eventKey="make_public">{translate({
              fr: `Rendre publique`,
              en: `Make it public`,
            })}</Dropdown.Item>
            {/transparent/.test(image_id) ? null :
              <Dropdown.Item eventKey="transparent">{translate({
                fr: `Rendre transparent`,
                en: `Remove background`,
              })}</Dropdown.Item>}
          </DropdownButton>
        </Dropdown>

      </div>
    </div>

    {show_delete_modal ? <DeleteImageModal show={show_delete_modal} onSuccess={onDelete} image_id={image_id} url={url}
                                           handleClose={handleClose}/> : null}
    {show_transparent_modal ?
      <CreateTransparentImageModal show={show_transparent_modal} images={images}
                                   image_id={image_id}
                                   url={url} handleClose={handleClose}/> : null}

    <MakePublicModal show_modal={show_make_public_modal} close={handleClose} image_id={image_id}/>
  </>
}
const col_width = 140

export const ShowAllImagesWithTags = ({ tags, onClick, limit }) => {
  const images = useCachedContext('images', tags)

  if (images.loading) {
    return <DefaultLoading/>
  }

  if (images.error) {
    return <DefaultError/>
  }


  return <>
    <div className="row">

      <div className="col-12 p-4">
        <AddImageDropzone tags={tags} on_add={() => images.refresh()}/>
      </div>
      <div className="col-12">
        {images.filter((_, i) => limit ? i < limit : true).map((image, i) => {
          return <ImageSelector key={image.url} {...image} col_width={col_width} onClick={onClick}
                                onDelete={() => images.refresh()} images={images}/>
        })}
      </div>
    </div>
  </>
}

export const ProductImages = ({
                                onClick = ({ url, width, height, image_id }) => {
                                },
                                variation,
                                initial_force_show_all = false,
                              }) => {
  let { product, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const [force_show_all, set_force_show_all] = React.useState(initial_force_show_all)

  const is_focused_on_variation = current_product_id !== product.product_id

  if (variation) {
    product = variation
  }

  const product_image_tag = `product_id:${product.product_id}`
  const current_product_image_tag = `product_id:${current_product_id}`
  const images = useCachedContext('images', [product_image_tag])
  const current_images = useCachedContext('images', [current_product_image_tag])
  const [reload_loading, set_reload_loading] = React.useState(false)
  if (images.loading || current_images.loading) {
    return <DefaultLoading/>
  }

  if (images.error || current_images.error) {
    return <DefaultError/>
  }


  const reload = <Button onClick={async () => {
    set_reload_loading(true)
    await images.refresh()
    set_reload_loading(false)
  }} className={'btn-sm'} disabled={reload_loading}>Refresh</Button>


  const has_variations = product.variations && product.variations.length > 0

  if (is_focused_on_variation && !force_show_all) {
    // this means we are focused on a variation
    return <>
      <Browse_Content_SubTitle>{translate({
        fr: `Photos de la variation`,
        en: `Variation pictures`,
      })} <VariationLabel
        product_id={current_product_id}/></Browse_Content_SubTitle>

      <div className="col-12">
        <AddImageDropzone tags={[current_product_image_tag]} on_add={() => images.refresh()} label={'de la variation'}/>
      </div>
      {current_images.map((image, i) => {
        return <ImageSelector key={i} {...image} col_width={col_width} onClick={onClick}
                              onDelete={() => images.refresh()} images={images}/>
      })}
      <div className="col-12">
        <Button onClick={() => set_force_show_all(true)}>Voir toutes les photos liées au produit</Button>
      </div>
    </>
  }

  return (
    <>
      {has_variations ? <Browse_Content_SubTitle>{translate({
        fr: `Photos du produit`,
        en: `Product pictures`,
      })}</Browse_Content_SubTitle> : null}
      {variation ? <Browse_Content_SubTitle>{translate({
        fr: `Photos de la variation`,
        en: `Pictures of variation`,
      })} <VariationLabel
        product_id={product.product_id}/></Browse_Content_SubTitle> : null}
      <div className="col-12 ">
        <AddImageDropzone tags={[product_image_tag]} on_add={() => images.refresh()}
                          label={variation ? <>{translate({
                            fr: `pour la variation`,
                            en: `for variation`,
                          })} <VariationLabel
                            product_id={product.product_id}/></> : null}/>
      </div>

      {images.map((image, i) => {
        return <ImageSelector key={i} {...image} col_width={col_width} onClick={onClick}
                              onDelete={() => images.refresh()} images={images}/>
      })}
      <div className="col-12 mb-5">

      </div>
      {has_variations ? <>
        {product.variations.map((variation) => {
          return <>
            <ProductImages variation={variation} onClick={onClick} initial_force_show_all={force_show_all}/>
          </>
        })}
      </> : null}
    </>
  )
}
