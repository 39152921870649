import React, { useContext } from 'react'
import Button from '../../components/generic/button'
import Field from '../../components/generic/field'
import { useMutation } from '@apollo/client'
import UserContext from '../../context/user'
import { ADD_PRODUCT, ADD_PRODUCT_VARIATION, UPDATE_PRODUCT } from '../../graphql/queries/product'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { ADD_VISUAL } from '../../graphql/queries/visual'
import IntellifoxModal from '../../components/generic/modal'
import { get_client } from '../../graphql/simple-client'
import useCompanyId  from '../../hooks/useCompanyId'
import { await_mutate } from '../../graphql/mutate_promise'
import { useCachedContext } from '../../hooks/useCachedContext'
import NavigationButton from '../../components/generic/navigation-button'
import HelpButton from '../../components/generic/help_button'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import { navigate } from '../../absolute_link'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { update_sold_by } from '../../graphql/queries/sold_by'
import { FaAmazon } from 'react-icons/all'


export const AddProductButton = ({ label, className = '', on_new_product }) => {
  const market_place = useSourceMarketPlace()
  const translate = useTranslate()
  const company_id = useCompanyId()

  const [add_product_status, set_add_product_status] = React.useState('')

  const amazon_accounts = useCachedContext('amazon_accounts')

  const [add_product] = useMutation(ADD_PRODUCT)
  const [update_product] = useMutation(UPDATE_PRODUCT)
  const [update_sold_by_mutation] = useMutation(update_sold_by)


  const do_add_product = async () => {
    try {
      set_add_product_status('loading')

      const { addProduct: { product_id } } = await await_mutate(add_product, {
        variables: {
          company_id,
          sku: 'n/a',
        },
      })

      await Promise.all([
        await_mutate(update_product, {
          variables: {
            company_id,
            product_id,
            market_place,
            title: translate({
              fr: 'Nouveau produit',
              en: 'New product',
            }),
            variation: '',
          },
        }),
      ])

      let market_places_updated = {}
      for (let i = 0; i < amazon_accounts.length; i++) {
        const { market_places, merchant_id } = amazon_accounts[ i ]
        for (let j = 0; j < market_places.length; j++) {
          const active_market_place = market_places[ j ]

          if (!market_places_updated[ active_market_place ]) {
            await await_mutate(update_sold_by_mutation, {
              variables: {
                company_id,
                product_id,
                market_place: active_market_place,
                merchant_id,
              }
            })
            market_places_updated[ active_market_place ] = true
          }
        }
      }

      navigate(`/product/edit/${product_id}`)

      set_add_product_status('')
    } catch (e) {
      set_add_product_status('error')
    }
  }

  if (amazon_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error) {
    return <DefaultError/>
  }

  label = label ? label : translate({
    fr: `Ajouter un produit vierge`,
    en: `Add an empty product`,
  })

  return <Button onClick={() => do_add_product()} className={className}>
    {!add_product_status ? label : null}
    {add_product_status === 'loading' ? <DefaultLoading/> : null}
    {add_product_status === 'error' ? <DefaultError/> : null}

  </Button>
}


const ProductAlreadyCreatedOnAmazon = () => {

  const amazon_accounts = useCachedContext('amazon_accounts')
  const translate = useTranslate()

  if (amazon_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error) {
    return <DefaultError/>
  }


  if (!amazon_accounts.length === 0) {
    return <div className="row">
      <div className="col-12">
        <div className="alert alert-warning">
          <div className="row">
            <div className="col-12">
              {translate({
                fr: `Afin d'importer les produits automatiquement, il faut d'abord ajouter un compte Amazon`,
                en: `In order to import your products automatically, you first need to add an Amazon account`,
              })}
            </div>
            <div className="col-12">
              <NavigationButton to={'/settings'}><FaAmazon/> {translate({
                fr: 'Ajouter un compte Amazon',
                en: 'Add Amazon account',
              })}</NavigationButton>
            </div>
          </div>
        </div>

      </div>
    </div>
  }

  return <>
    <div className="row">

      <div className="col-12">
        ImportLauncher/>
      </div>

      <div className="col-12">
        FollowLatestImport display_result_if_finished={true}/>
      </div>
    </div>
  </>

}

const AddProductModalBody = () => {
  const translate = useTranslate()
  const french = useFrench()
  const lang = useLang()

  const [answer, set_answer] = React.useState(null)

  return <>
    <div className="row">
      <div className="col-12 font-weight-bold mb-3">
        {translate({
          fr: `Le produit est-il déjà créé sur Amazon ?`,
          en: `Does the product already exist on Amazon?`,
        })}
      </div>
      <div className="col-12 mb-3">
        <Button button={!answer || answer === 'yes' ? 'primary' : 'secondary'} className={'mr-2'}
                onClick={() => set_answer('yes')}>{translate({
          fr: `Oui`,
          en: `Yes`,
        })}</Button>
        <Button button={!answer || answer === 'no' ? 'primary' : 'secondary'} className={'mr-2'}
                onClick={() => set_answer('no')}>{translate({
          fr: `Non`,
          en: `No`,
        })}</Button>
        <Button button={!answer || answer === 'no_account' ? 'primary' : 'secondary'} className={'mr-2'}
                onClick={() => set_answer('no_account')}>{translate({
          fr: `Je n'ai pas encore de compte`,
          en: `I don't have an Amazon seller account yet`,
        })}</Button>
      </div>

      {answer === 'yes' ? <div className="col-12">
        <ProductAlreadyCreatedOnAmazon/>
      </div> : null}

      {answer === 'no' ? <>
        <div className="col-12">
          {french(`Tu dois d'abord créer le produit sur Amazon avant de pouvoir l'éditer dans Intellifox.`)}
          {lang === 'fr' ? <HelpButton
              slug={'intellifoxr-permet-il-de-creer-automatiquement-des-produits-et-des-variations-sur-amazon-fsdhas'}
              className={'mr-5'}>Voir l'article d'aide à ce sujet</HelpButton> :
            <HelpButton
              slug={'is-intellifoxr-capable-of-automatically-creating-products-and-variations-on-amazon-16d4tpe'} lang_override={'en'}
              className={'mr-5'}>{french(`Voir l'article d'aide à ce sujet`)}</HelpButton>
          }
        </div>
        <div className="col-12 mt-5">
          {translate({
            fr: `Vous pouvez aussi choisir de créer un produit manuellement en cliquant ici :`,
            en: `However, you can also choose to start working on a product before it exists by clicking here:`,
          })} <AddProductButton/>
        </div>
      </> : null}
      {answer === 'no_account' ? <>
        <div className="col-12">
          {translate({
            fr: `Commencez en cliquant ici :`,
            en: `Start by clicking here:`,
          })} <AddProductButton/>
        </div>
      </> : null}
    </div>
  </>
}

export const AddProductModal = ({ className }) => {
  const translate = useTranslate()

  return <IntellifoxModal title={translate({
    fr: `Ajouter un nouveau produit`,
    en: <>Add a product</>,
  })} body={<AddProductModalBody/>} button_props={{ className }}>
    {translate({
      fr: `Ajouter un nouveau produit`,
      en: `Add a product`,
    })}
  </IntellifoxModal>
}
