import React from 'react'
import { useMutation } from '@apollo/client'
import { create_mutation } from '../../../graphql/queries/_query_and_mutations_templates'
import UpdateButtonV2 from '../../../components/generic/update_button_v2'
import { FiX } from 'react-icons/all'
import { useFrench } from '../../../context/lang'
import { await_mutate } from '../../../graphql/mutate_promise'
import useCompanyId from '../../../hooks/useCompanyId'
import { useCachedContext } from '../../../hooks/useCachedContext'

const remove_managed_campaign_mutation = create_mutation({
  mutation: 'remove_managed_campaign', params: {
    company_id: 'ID!', amazon_ads_account_id: 'String', campaign_id: 'String',
  },
})

export const RemoveManagedCampaign = ({
                                        amazon_ads_account_id,
                                        campaign_id
                                      }) => {
  const french = useFrench()

  const company_id = useCompanyId()

  const [remove_managed_campaign] = useMutation(remove_managed_campaign_mutation)
  const managed_campaigns = useCachedContext('managed_campaigns')
  const limits = useCachedContext('limits')


  return <UpdateButtonV2 button={'danger'} outline={true} className={"btn-sm"} trigger={async() => {
    await await_mutate(remove_managed_campaign, {
      variables: {
        company_id, amazon_ads_account_id, campaign_id,
      },
    })
    await managed_campaigns.refresh()
    await limits.refresh()
  }
  }>
    <FiX/> {french(`Arrêter de gérer cette campagne`)}
  </UpdateButtonV2>
}
