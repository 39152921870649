import React from 'react'
import { FaToggleOff, FaToggleOn, FiChevronRight } from 'react-icons/all'

export default function InlineToggle({is_on, on_toggle, children}) {
  return (
    <div className="row">
      <div className="col-12 font-weight-bold clickable" onClick={() => on_toggle()}>
        <span className="h3 mr-2">{is_on ? <FaToggleOn/> : <FaToggleOff/>}</span> {children}
      </div>
    </div>
  )
}

export const Toggle = ({label, children}) => {
  const [is_open, set_is_open] = React.useState(false)
  return (
    <div className="row">
      <div className={`col-12 font-weight-bold clickable`} onClick={() => set_is_open(!is_open)}>
        <span className="h3 mr-2"><FiChevronRight style={{transition:'transform 0.3s', transform:`rotate(${is_open ? '90deg':'0deg'})`}}/></span> {label}
      </div>
      {is_open ? <div className="col-12">
        {children}
      </div> : null}
    </div>
  )
}
