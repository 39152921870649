import React from 'react'
import Item, { CompactItem } from '../../components/generic/item'
import { useFrench } from '../../context/lang'
import { ProgressBadge, useProgressDetails } from '../product/progress'
import { Tooltip } from 'react-tippy'
import { FaArrowDown, FaArrowRight, FaCheck } from 'react-icons/all'
import { get_edit_product_route, navigate_to_product } from '../routes'
import useMarketPlace from '../../hooks/useMarketPlace'
import InternalLink from '../../components/generic/internal-link'
import useHashParam from 'use-hash-param'
import { scroll_to_node } from './scroll'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import Button from '../../components/generic/button'
import SearchTermInit from './search_term_init'
import Field from '../../components/generic/field'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import IntellifoxIcon from '../../images/intellifox-icon'
import { Suggestion } from './suggestion'
import { useCachedContext } from '../../hooks/useCachedContext'

export default function OptimizationProgress({ product, on_click }) {

  const french = useFrench()
  const [average, details] = useProgressDetails(product)
  const market_place = useMarketPlace()
  const [current_menu, set_current_menu] = useHashParam('menu', 'preview')

  const [show_search_term_init, set_show_search_term_init] = React.useState(false)
  const [search_term_init_current_step, set_search_term_init_current_step] = React.useState(1)

  return (
    <>
      <div className="row mb-3">

        <div className="col-12">
          <Item>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {details.map(({ label, average, progresses, menu, component, start_here, outline }, i) => {
                    return <div className="col-12 col-sm-3 mb-2" key={i}>
                      <CompactItem label={<>{i + 1}. {label}</>} style={{ opacity: outline ? 0.3 : 1 }}
                                   on_click={() => {
                                     if (menu) {
                                       set_current_menu(menu)

                                       setTimeout(() => {
                                         scroll_to_node(`product_header_nav`)
                                       }, 100)
                                     }

                                     if (component === 'search_term_init') {
                                       set_show_search_term_init(true)
                                     }
                                   }}>
                        <div className="row h-100">
                          {start_here ? <div className="col-12">
                            <div className="alert alert-success font-weight-bold smaller">
                              {french(`Démarrer ici`)} <FaArrowDown/>
                            </div>
                          </div> : null}
                          <div className="col-12">
                            <table className={'table table-sm m-0 small'}>
                              <tbody>
                              {progresses.map(({ progress, label, search_term_init_step }, i) => {
                                const is_done = progress > 90

                                return <tr key={i}
                                           onClick={() => set_search_term_init_current_step(search_term_init_step)}>
                                  <td>{progress > 90 ? <MdCheckBox/> : <MdCheckBoxOutlineBlank/>}</td>
                                  <td className={'col'}>{is_done ? <del>{label}</del> : label}</td>
                                  <td><ProgressBadge val={progress}/></td>
                                </tr>
                              })}

                              </tbody>
                            </table>
                          </div>
                          <div className={'col-12 align-self-end text-right'}>
                            <hr className={'my-1'}/>
                            <Button className={'btn-sm'} onClick={() => set_search_term_init_current_step(1)}
                                    outline={outline}>{label} <FaArrowRight/></Button>
                          </div>
                        </div>
                      </CompactItem>
                    </div>
                  })}

                </div>
              </div>

              <div className="col text-right">
                <hr className="my-2"/>
                <h3 className={'font-weight-bold'}>{french(`Score d'optimisation`)} <ProgressBadge val={average}/></h3>
              </div>
            </div>
          </Item>
        </div>
      </div>
      {show_search_term_init ? <SearchTermInit product={product}
                                               on_finish={() => product.refresh()}
                                               close={() => set_show_search_term_init(false)}
                                               current_step={search_term_init_current_step}
                                               set_current_step={set_search_term_init_current_step}/> : null}
    </>
  )
}

