import confetti from 'canvas-confetti'

function r(min, max) {
  return Math.random() * ( max - min ) + min // njsscan-ignore: node_insecure_random_generator
}

export default function throw_confetti({
                                         green,
                                         red,
                                         yellow,
                                         parts = 100,
                                         x = 0.9,
                                         y = 0.6,
                                         angle_min = 110,
                                         angle_max = 150,
                                         spread_min = 50,
                                         spread_max = 70,
                                         ticks = 400,
                                       } = {}) {
  const colors = []
  if (green) {
    colors.push('#28a745')
  }

  if (red) {
    colors.push('#dc3545')
  }

  if (yellow) {
    colors.push('#ffc107')
  }

  confetti({
    angle: r(angle_min, angle_max),
    spread: r(spread_min, spread_max),
    colors,
    particleCount: parts,
    ticks,
    zIndex: 30000,
    origin: {
      x,
      y,
    },
  })
}


export const throw_confetti_on_mouse_position = (e, x, y) => {
  if (!x) {
    x = e.clientX / window.innerWidth
  }
  if (!y) {
    y = e.clientY / window.innerHeight
  }
  const conf_1 = {
    green: true,
    x: Math.random() * 0.5, // njsscan-ignore: node_insecure_random_generator
    y: Math.random() * 0.5, // njsscan-ignore: node_insecure_random_generator
    angle_min: 0,
    angle_max: 90,
  }

  const conf_2 = { red: true, x: Math.random() * 0.5 + 0.5, y: Math.random() * 0.5 + 0.2 } // njsscan-ignore: node_insecure_random_generator
  const conf_3 = { yellow: true, x: Math.random() * 0.5 + 0.5, y: Math.random() * 0.5 + 0.2 } // njsscan-ignore: node_insecure_random_generator
  const conf_4 = {
    x: Math.random() * 0.5, y: Math.random() * 0.5, // njsscan-ignore: node_insecure_random_generator
    angle_min: 0,
    angle_max: 90,
  }

  setTimeout(() => throw_confetti({ ...conf_1, x, y }), 200)
  setTimeout(() => throw_confetti({ ...conf_2, x, y }), 400)
  setTimeout(() => throw_confetti({ ...conf_3, x, y }), 600)
  setTimeout(() => throw_confetti({ ...conf_4, x, y }), 800)
}
