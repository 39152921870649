import React from 'react'
import { clean_for_label, compute_variables } from '../product/text-substitution'
import useMarketPlace from '../../hooks/useMarketPlace'
import Item from '../../components/generic/item'
import { TextCopyButton } from '../../components/generic/copy-button'
import ProductDeleteButton, { ProductDefinitiveDeleteButton } from '../product/product-delete-button'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench } from '../../context/lang'
import { DisplayVariations, ProductPresentation } from './product_header'


export const ProductOverview = ({ product, definitive_delete, on_delete, is_variation, parent_product }) => {
  return (
    <>
      <Item>
        <ProductPresentation product={product} on_delete={() => null} parent_product={parent_product}/>
        {definitive_delete ? <ProductDefinitiveDeleteButton product_id={product.product_id} onDelete={on_delete}
                                                            className="btn-sm"/> :
          <ProductDeleteButton product_id={product.product_id} onDelete={on_delete}
                               className="btn-sm"/>}
      </Item>
      {!is_variation ? <DisplayVariations product={product} variation_mapper={(variation, parent_product) => {
        return (
          <ProductOverview product={variation} parent_product={parent_product}
                           on_delete={() => parent_product.refresh()} definitive_delete={true} is_variation={true} />
        )

      }}/> : null}
    </>
  )
}
