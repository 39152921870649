import React from 'react'
import { clean_for_label } from '../../product/text-substitution'
import Stars from '../../../components/generic/stars'
import useMarketPlace, { useMarketPlaceDetails } from '../../../hooks/useMarketPlace'

export default function SquareSearchResult({ image, image_element, title, price, url, rating, ratings_total }) {
  const market_place = useMarketPlace()
  const market_place_currency = useMarketPlaceDetails(market_place).currency_symbol

  return (
    <div className="col-12 col-xs-6 col-md-6 col-lg-4">
      <div className="row">
        <div className="col-12 mb-2 text-center">
          {image ? <div className="position-relative" style={{ paddingTop: '100%' }}>
            <img src={image.replace('http://','https://')} alt="" style={{
              position: 'absolute',
              margin: 'auto',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              maxWidth: '100%',
              maxHeight: '100%',
            }}/>
          </div> : <div>
            {image_element}
          </div>
          }
        </div>
        <div className="col-12 square_title">
          <a href={url} target='_blank' rel='noreferrer noopener'>{clean_for_label(title, 140, [])}
          </a>
        </div>
        <div className="col-12">
          <Stars rating={rating} ratings_total={ratings_total}/>
        </div>
        {price && price !== -1 ? <div className="col-12">
          <span className="square_price">{( price || 0 ).toFixed(2)} {market_place_currency}</span>
        </div> : null}
      </div>
    </div>
  )
}
