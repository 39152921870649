import React, { useMemo, useCallback } from 'react'
import { AreaClosed, Line, Bar } from '@visx/shape'
import appleStock, { AppleStock } from '@visx/mock-data/lib/mocks/appleStock'
import { curveMonotoneX } from '@visx/curve'
import { GridRows, GridColumns } from '@visx/grid'
import { scaleTime, scaleLinear, scaleBand } from '@visx/scale'
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip'
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { localPoint } from '@visx/event'
import { LinearGradient } from '@visx/gradient'
import { max, extent, bisector } from 'd3-array'
import { timeFormat } from 'd3-time-format'
import { useWindowSize } from '../../hooks/useWindowSize'
import * as _ from 'lodash'
import moment from 'moment'
import { format_big_number, sum_earnings, sum_fees, sum_product_costs, sum_profits, sum_urssaf } from './format_numbers'
import OrdersSummary, { useOrdersSummary } from './orders_summary'
import Item from '../../components/generic/item'
import OrdersGraph, { OrdersGraphAxis } from './orders_graph'
import { is_ready } from '../../hooks/useCachedContext'


export const background = '#3b6978'
export const background2 = '#204051'
export const accentColor = '#edffea'
export const amazonColor = '#e88a39'
export const productColor = '#49007A'
export const urssafColor = '#006EB2'
export const profitColor = '#85bb65'
export const lossColor = '#bb6565'

export const accentColorDark = '#75daad'
const tooltipStyles = {
  position: 'absolute',
  zIndex: 42,
}

// util

// accessors
const getDate = (d) => d.day
const getEarnings = (d) => d.earnings
const getProfits = (d) => d.profits
const getFees = (d) => d.fees
const getUrssaf = (d) => d.urssaf
const getProductCosts = (d) => d.product_costs


export default function OrdersGraphCumulated({ orders, from, to, ads }) {
  const window_size = useWindowSize()
  const row_ref = React.useRef(null)
  const [computed_width, set_computed_width] = React.useState(500)

  React.useEffect(() => {
    set_computed_width(row_ref.current.getBoundingClientRect().width - 30)
  }, [window_size])


  return <div className="row" ref={row_ref}>
    <div className="col-12">
      <DopeGraph width={computed_width} orders={orders} from={from} to={to} ads={ads}
      />
    </div>
  </div>
}

const DopeGraph = withTooltip(
  ({
     orders,
     ads,
     width = 500,
     height = 500,
     margin = { top: 0, right: 0, bottom: 0, left: 0 },
     showTooltip,
     hideTooltip,
     tooltipData,
     tooltipTop = 0,
     tooltipLeft = 0,
     from, to,
   }) => {
    // bounds
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    const data = React.useMemo(() => {

      const orders_grouped_by_days = _.groupBy(orders.map((o) => {
        return {
          ...o,
          day: moment(o.creation_date).diff(moment(from), 'days'),
        }
      }), 'day')

      const summaries = [
        {
          day: 0,
          earnings: 0,
          urssaf: 0,
          fees: 0,
          profits: 0,
          product_costs: 0,
          orders: [],
        },
      ]

      let i = 0
      for (i = 0; moment(from).add(i, 'day').isSameOrBefore(to, 'day'); i++) {
        const day = i

        const orders = orders_grouped_by_days[ day ] ? orders_grouped_by_days[ day ] : []

        const ads_for_day = ads.filter((a) => a.date === day) || []

        const earnings = _.sumBy(orders, 'total_sales')
        const profits = _.sumBy(orders, 'profit')

        const urssaf = _.sumBy(orders, 'total_urssaf')
        const product_costs = _.sumBy(orders, 'total_product_costs')

        const fees = _.sumBy(orders, 'amazon_commissions')
          + _.sumBy(orders, 'amazon_fba')
          + _.sumBy(orders, 'amazon_other')
          + _.sumBy(ads_for_day, 'cost')


        summaries.push({
          day,
          date: moment(from).add(i, 'day').valueOf(),
          profits: -fees - product_costs - urssaf + profits + ( summaries[ i ] ? summaries[ i ].profits : 0 ),
          earnings: earnings + ( summaries[ i ] ? summaries[ i ].earnings : 0 ),
          urssaf: -fees - product_costs - urssaf + ( summaries[ i ] ? summaries[ i ].urssaf : 0 ),
          product_costs: -fees - product_costs + ( summaries[ i ] ? summaries[ i ].product_costs : 0 ),
          fees: -fees + ( summaries[ i ] ? summaries[ i ].fees : 0 ),
          orders: [
            ...( summaries[ i ] ? summaries[ i ].orders : [] ),
            ...orders,
          ],
        })
      }

      summaries.push({
        ...summaries[ summaries.length - 1 ],
        day: i,
      })
      return summaries
    }, [ads, from, orders, to])

    // scales
    const [dateScale, normalized_date_scale] = useMemo(
      () => {
        const dateScale = scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate),
        })

        const normalized_date_scale = scaleBand({
          domain: data.filter((d, i) => !!d.day && i < data.length - 1).map((d) => d.day),
        })

        normalized_date_scale.rangeRound([1, width])

        return [dateScale, normalized_date_scale]
      },
      [margin.left, innerWidth, data, width, ads],
    )
    const stockValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, ( Math.max(max(data, getEarnings), max(data, getFees)) || 0 ) + innerHeight / 3],
          nice: true,
        }),
      [orders, margin.top, innerHeight, ads],
    )

    // tooltip handler
    const handleTooltip = useCallback(
      (event, d) => {
        showTooltip({
          tooltipData: d,
          tooltipLeft: dateScale(getDate(d)),
          tooltipTop: stockValueScale(getEarnings(d)),
        })
      },
      [dateScale, data, showTooltip, stockValueScale, ads],
    )

    return (
      <div>
        <svg width={width} height={height} className="list-item-shadow" onMouseLeave={() => hideTooltip()}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="url(#area-background-gradient)"
            rx={14}
          />
          <LinearGradient id="area-background-gradient" from={background} to={background2}/>
          <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={1}/>
          <LinearGradient id="area-gradient-amazon" from={amazonColor} to={amazonColor} toOpacity={1}/>
          <LinearGradient id="area-gradient-urssaf" from={urssafColor} to={urssafColor} toOpacity={1}/>
          <LinearGradient id="area-gradient-product" from={productColor} to={productColor} toOpacity={1}/>
          <LinearGradient id="area-gradient-profits" from={profitColor} to={profitColor} toOpacity={1}/>
          <GridRows
            left={margin.left}
            scale={stockValueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          <AreaClosed
            data={data}
            x={d => dateScale(getDate(d)) ?? 0}
            y={d => stockValueScale(getProfits(d)) ?? 0}
            yScale={stockValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient-profits)"
            fill="url(#area-gradient-profits)"
            curve={curveMonotoneX}
            pointerEvents="none"
          />
          <AreaClosed
            data={data}
            x={d => dateScale(getDate(d)) ?? 0}
            y={d => stockValueScale(getUrssaf(d)) ?? 0}
            yScale={stockValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
            pointerEvents="none"
          />
          <AreaClosed
            data={data}
            x={d => dateScale(getDate(d)) ?? 0}
            y={d => stockValueScale(getFees(d)) ?? 0}
            yScale={stockValueScale}
            strokeWidth={1}
            stroke="url(#area-gradient-amazon)"
            fill="url(#area-gradient-amazon)"
            curve={curveMonotoneX}
            pointerEvents="none"
          />
          {data.map((d, i) => {
            if (i === 0 || i === data.length - 1) {
              return null
            }
            return <Bar
              key={`event_${i}`}
              x={dateScale(getDate(d)) - ( width / data.length ) / 2}
              y={margin.top}
              width={width / data.length}
              height={height}
              fill="transparent"
              opacity={0.2}
              onTouchStart={(e) => handleTooltip(e, d)}
              onTouchMove={(e) => handleTooltip(e, d)}
              onMouseMove={(e) => handleTooltip(e, d)}
            />
          })}

          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColorDark}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColorDark}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        <OrdersGraphAxis width={width} height={height} dateScale={normalized_date_scale} moneyScale={stockValueScale}/>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              top={-10}
              left={-10}
              style={tooltipStyles}
            >
              <Item><h3>
                {moment(from).format('LL')} -> {moment(tooltipData.date).format('LL')}
              </h3>
                <OrdersSummary orders={tooltipData.orders} from={from} to={moment(tooltipData.date).add(1, 'day')}
                              ads={ads}/>
              </Item>
            </TooltipWithBounds>
          </div>
        )}
      </div>
    )
  },
)
