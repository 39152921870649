import React from 'react'
import TextButton from './text_button'
import { FaQuestionCircle } from 'react-icons/fa'
import { useLang, useTranslate } from '../../context/lang'
import IntellifoxModal from './modal'
import { FiHelpCircle } from 'react-icons/all'

export default function HelpButton({ children, lang_override, slug, ...props }) {
  const lang = useLang()
  const translate = useTranslate()

  return (
    <TextButton {...props} onClick={() => {
      console.log('Open help article...', { lang, slug })
      return window.$crisp.push(['do', 'helpdesk:article:open', [lang_override || lang, slug]])
    }}>
      {translate({
        fr: `Aide`,
        en: `Help`,
      })} <FaQuestionCircle className="mr-2"/>{children}
    </TextButton>
  )
}


export const HelpModal = ({ label, title, children, button_props = {}, small, icon = <FiHelpCircle/> }) => {


  return <IntellifoxModal title={<><FiHelpCircle/> {title || label}</>}
                          body={children}
                          modal_size={'lg'}
                          button_props={{
                            // className: 'btn-sm',
                            button: 'link',
                            ...button_props,
                            className: small ? 'btn-sm' : '',
                          }}>
    {icon} {label}
  </IntellifoxModal>
}
