import React from 'react'
import { useDesignerContext } from '../designer_context'
import { RectOptions } from '../renderer/rect'

import './options.css'
import SelectColor from './select_color'
import { ImageOptions } from '../renderer/image'
import SelectImage from './select_image'
import { TextOptions } from '../renderer/text'
import SelectText from './select_text'
import { apply_overrides_to_object } from '../override/apply_overrides'
import SelectFilters from './select_filters'
import { BookOptions } from '../renderer/book'
import SelectBookProperties from './select_book_properties'
import MultipleSelectionOptions from './multiple_selection_options'
import TextButton from '../../generic/text_button'
import SelectCropImage from './select_crop_image'
import { ShapeOptions } from '../renderer/shape'
import SelectGradient, { ActivateGradient } from './select_gradient'
import SelectArrowProperties from './select_arrow_properties'
import { ArrowOptions } from '../renderer/arrow'
import { useWindowSize } from '../../../hooks/useWindowSize'

export const OptionSeparator = () => {
  return <div className="col-auto mr-2 ml-2" style={{ opacity: 0.5 }}>|</div>
}

export const option_selectors = [
  {
    key: 'props.fill', content: <SelectColor show_transparent={true} get_value={(o) => o.props.fill} update_object={(object, value) => ( {
      object,
      field: 'fill',
      value,
    } )}/>,
  },
  {
    key: 'props.gradient', content: <>
      <SelectGradient get_value={(o) => o.props.gradient_angle} update_object={(object, value) => ( {
        object,
        field: 'gradient_angle',
        value,
      } )} />
    </>,
  },
  {
    key: 'props.background',
    content: <SelectColor show_transparent={true} get_value={(o) => o.props.background}
                          update_object={(object, value) => ( {
                            object,
                            field: 'background',
                            value,
                          } )}/>,
  },
  {
    key: 'props.filters',
    content: <SelectFilters get_value={(o) => o.props.filters}
                            update_object={(object, value) => ( {
                              object,
                              field: 'filters',
                              value,
                            } )}/>,
  },
  {
    key: 'props.image_id', content: <SelectImage/>,
  },
  {
    key: 'props.crop_image', content: <SelectCropImage/>,
  },
  {
    key: 'props.text', content: <SelectText/>,
  },
  {
    key: 'props.book',
    content: <SelectBookProperties get_value={(o) => o.props.book_properties}
                                   update_object={(object, value) => ( {
                                     object,
                                     field: 'book_properties',
                                     value,
                                   } )}/>,
  },
  {
    key: 'props.arrow',
    content: <SelectArrowProperties />,
  },
]

export const OptionSelector = ({ option, children }) => {
  const { set_current_option, current_option } = useDesignerContext()

  return <TextButton onClick={() => current_option === option ? set_current_option(null) : set_current_option(option)}
                     className={current_option === option ? 'font-weight-bold' : ''}>{children}</TextButton>
}

export default function Options({set_option_height, set_zoom_height}) {
  const { get_selected_objects, current_objects, current_product_id } = useDesignerContext()

  const window_size = useWindowSize()

  const objects = get_selected_objects().map((obj) => apply_overrides_to_object(obj, current_objects.overrides[ current_product_id ]))

  React.useEffect(() => {
    setTimeout(() => {
      try {
        set_option_height(document.getElementById('options_bar').getBoundingClientRect().height)
        set_zoom_height(document.getElementById('zoom_bar').getBoundingClientRect().height)
      } catch(e) {
        set_option_height(45)
        set_zoom_height(38)
      }
    }, 50)
  }, [objects, window_size])

  if (objects.length === 1 && objects[ 0 ].type === 'rect') {
    return <RectOptions {...objects[ 0 ]}/>
  }
  if (objects.length === 1 && objects[ 0 ].type === 'image') {
    return <ImageOptions {...objects[ 0 ]}/>
  }
  if (objects.length === 1 && objects[ 0 ].type === 'text') {
    return <TextOptions {...objects[ 0 ]}/>
  }
  if (objects.length === 1 && objects[ 0 ].type === 'book') {
    return <BookOptions {...objects[ 0 ]}/>
  }
  if (objects.length === 1 && objects[ 0 ].type === 'shape') {
    return <RectOptions {...objects[ 0 ]}/>
  }
  if (objects.length === 1 && objects[ 0 ].type === 'arrow') {
    return <ArrowOptions {...objects[ 0 ]}/>
  }

  if (objects.length > 1) {
    return <MultipleSelectionOptions selected_objects={objects}/>
  }

  return null
}
