import React from 'react'

import strategy from '../../images/story/strategy.svg'
import './index.css'
import { clean_for_label, compute_variables } from '../product/text-substitution'
import NavigationButton from '../../components/generic/navigation-button'
import ProductDeleteButton, {
  EmptyTrashBin,
  ProductDefinitiveDeleteButton,
  ProductRestoreButton,
} from '../product/product-delete-button'
import { navigate } from '../../absolute_link'
import Item from '../../components/generic/item'
import { Toggle } from './edit_texts'
import InternalLink from '../../components/generic/internal-link'
import { useCachedContext } from '../../hooks/useCachedContext'
import useHashParam from 'use-hash-param'
import { AddProductButton } from '../product/add'
import TextButton from '../../components/generic/text_button'
import Field from '../../components/generic/field'
import { useAmazonUrls, useRegionForProduct, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import Button from '../../components/generic/button'
import { ProductAsinAndSku, SquareVisual } from './product_preview/product_preview'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { useFrench, useTranslate } from '../../context/lang'
import { DesignerContext } from '../../components/visual/designer_context'
import Stars from '../../components/generic/stars'
import ExternalLink from '../../components/generic/external-link'
import { ShowGlobalProgress } from '../product/progress'
import { FaArrowRight, FiArrowRight, FiZap } from 'react-icons/all'
import BackToHome from '../../components/navigation/back_to_home'
import BigBox from '../../components/layout/big_box'
import Title from '../../components/layout/title'
import { section_color, WelcomeIcon } from '../colors'
import { Checkbox } from '../../components/generic/checkbox'
import MergeProducts from './merge_products'
import DeleteProducts from './delete_products'
import Flag from '../../components/flag/flag'
import { AutomaticScaler } from '../../components/visual/renderer/dom_renderer'
import { TiFlash } from 'react-icons/ti'
import { ImportProductOrNot } from '../../components/import/import'
import { Loader } from '../../components/generic/loader'
import { ProductsLimit } from '../../components/subscription/subscription_options'
import { OnlyPremium } from '../../components/subscription/start_free_trial'
import { OnboardingVideo } from '../onboarding/onboarding_videos'
import * as _ from 'lodash'

export const ProductFirstVisual = ({ product, width = 200, style = {}, className, auto_scale = false }) => {
  const region_id = useRegionForProduct(product)

  const designer_context = {
    compute_color: (value) => {
      if (!product || !product.color_palette) {
        return value
      }

      // first find if value is a color id in product palette
      const palette_color = product.color_palette.colors.find((c) => c.color_id === value)
      if (palette_color) {
        return palette_color.color
      }

      return value
    },

    product,
    current_product_id: product.product_id,
  }

  const first_visual = useCachedContext('product_with_single_visual', product.product_id, region_id, 0)

  if (!first_visual) {
    return null
  }

  if (first_visual.loading) {
    return <DefaultLoading/>
  }

  if (first_visual.error) {
    return <DefaultError/>
  }

  return <DesignerContext.Provider value={designer_context}>
    {
      auto_scale ?
        <AutomaticScaler visual={first_visual}>
          <SquareVisual visual={first_visual} reduce_image_size={false} style={style}
                        className={className} auto_scale={true}/>
        </AutomaticScaler> :
        <SquareVisual visual={first_visual} width={width} reduce_image_size={true} style={style}
                      className={className}/>

    }
  </DesignerContext.Provider>
}

export const useProductTitle = (product, snippets) => {
  const source_market_place = useSourceMarketPlace()

  if (product.loading || snippets.loading) {
    return {}
  }

  if (product.error || snippets.error) {
    return {}
  }

  let market_place = source_market_place
  let title = product.titles.find((t) => t.market_place === source_market_place)

  if (!title) {
    title = product.titles[ 0 ]
    if (title) {
      market_place = title.market_place
    }
  }


  if (!title) {
    title = product.product_info
  }

  return {
    title: clean_for_label(title.title || '', 100, compute_variables(product, snippets)),
    market_place,
  }
}
const ProductTitle = ({ product, snippets }) => {

  const { title } = useProductTitle(product, snippets)

  return <>
    {title}
  </>
}
const ProductRow = ({ product, snippets }) => {
  const source_market_place = useSourceMarketPlace()
  const translate = useTranslate()
  const amazon_urls = useAmazonUrls()
  const french = useFrench()

  const is_recent = ( Date.now() - product.date_created ) < ( 24 * 60 * 60 * 1000 )

  let selected_market_place = product.titles && product.titles[ 0 ] ? product.titles[ 0 ].market_place : source_market_place

  for (let i = 0; i < product.titles.length; i++) {
    const { market_place } = product.titles[ i ]
    if (source_market_place === market_place) {
      selected_market_place = source_market_place
    }
  }

  return <div className="row">
    <div className="col-auto BLUR_FOR_WEBINAR">
      {product.import_in_progress ? <Loader/> :
        <InternalLink to={`/product/edit/${product.product_id}`}>
          <ProductFirstVisual product={product}/>
        </InternalLink>}
    </div>
    <div className="col">
      <div className="row BLUR_FOR_WEBINAR">
        <div className="col-12 font-weight-bold mb-2 blur_on_discreet">
          <InternalLink to={`/product/edit/${product.product_id}/${selected_market_place}`}>
            {product.import_in_progress ? <Loader size={20}/> : <ProductTitle product={product} snippets={snippets}/>}
          </InternalLink>
        </div>
        <div className="col-12">
          <div className="row">

            {product.titles.map(({ market_place }) => <div key={market_place} className={'col-auto'}>
              <InternalLink to={`/product/edit/${product.product_id}/${market_place}`}>
                <Flag market_place={market_place}/>
                <ShowGlobalProgress product_id={product.product_id}
                                    market_place={market_place}/>

              </InternalLink>

            </div>)}
          </div>
        </div>
        {product.variations && product.variations.length > 0 ?
          <div className="col-12">
            <Toggle open_label={<>{product.variations.length} {french('variation(s)')}</>}>
              {product.variations.map((variation) => <div className="col-12" key={variation.product_id}>
                <div className="field_labelish pl-2">
                  {clean_for_label(variation.product_info.variation, 50, [])} <ProductAsinAndSku product={variation}/>

                </div>
              </div>)}
            </Toggle>
          </div> : null}

        <div className="col-12 mb-2">
          <ExternalLink to={amazon_urls[ source_market_place ].public(product.asin)}><Stars rating={product.rating}
                                                                                            ratings_total={product.ratings_total}/></ExternalLink>
        </div>

        <div className="col-12">
          {is_recent ? <div className="badge badge-success">{translate({
            fr: `Nouveau`,
            en: `New`,
          })}</div> : null}
        </div>

        <div className="col-12 blur_on_discreet">
          <ProductAsinAndSku product={product}/>
        </div>
      </div>
    </div>
  </div>
}


export default function ProductIndex({ location }) {

  const translate = useTranslate()


  return <>
    <div className="container">
      <BackToHome/>
      <Title color={section_color('product')} style={{ color: 'white' }} details={<ProductsLimit/>}><WelcomeIcon
        section={'product'}/> {translate({
        fr: `Optimisation des fiches produits`,
        en: `Product listing optimization`,
      })}</Title>
      {/*<FollowLatestImport hide_if_finished={true} reload_on_finish={true}/>*/}
      <OnlyPremium>
        <ListAndSearchProducts/>
      </OnlyPremium>
    </div>
  </>
}

function ListAndSearchProducts() {
  const market_place = useSourceMarketPlace()
  const source_market_place = useSourceMarketPlace()

  const [current_page, set_current_page] = useHashParam('page', 0)
  const [product_state, set_product_state] = useHashParam('state', 'active')
  const [search_query, set_search_query] = React.useState('')
  const [real_search_query, set_real_search_query] = React.useState('')

  const translate = useTranslate()
  const french = useFrench()

  const set_search_query_timeout_ref = React.useRef(0)

  React.useEffect(() => {
    clearTimeout(set_search_query_timeout_ref.current)
    set_search_query_timeout_ref.current = setTimeout(() => {
      set_real_search_query(search_query)
    }, 750)
  }, [search_query])

  const products_per_page = 20
  const products = useCachedContext('minimal_products', market_place, ~~( current_page ), products_per_page, product_state)
  // const next_products = useDetailedProducts(market_place, ~~( current_page ) + 1, products_per_page)
  const product_count = useCachedContext('product_count')
  const deleted_product_count = useCachedContext('product_count', 'deleted')
  const search_products = useCachedContext('search_products', market_place, real_search_query, ~~( current_page ), products_per_page)
  const snippets = useCachedContext('snippets')
  const limits = useCachedContext('limits')

  const refresh_all = async () => {
    await Promise.all([
      products.refresh(),
      product_count.refresh(),
      deleted_product_count.refresh(),
      search_products.refresh(),
      snippets.refresh(),
      limits.refresh(),
    ])
  }

  const [allow_selection, set_allow_selection] = React.useState(false)

  const [selected_products, set_selected_products] = React.useState({})

  React.useEffect(() => {
    refresh_all()
  }, [])

  React.useEffect(() => {
    refresh_all()
  }, [product_state])

  if (product_count.loading || snippets.loading) {
    return <div><h2><DefaultLoading/></h2></div>
  }

  if (product_count.error || snippets.error) {
    return <DefaultError/>
  }

  const last_page = product_state === 'deleted' ? Math.ceil(deleted_product_count.count / products_per_page) : Math.ceil(product_count.count / products_per_page)


  const columns = [
    {
      label: '',
      content: (p) => <ProductRow product={p} snippets={snippets}/>,
      width: 'auto',
    },
    {
      label: '',
      content: (product) => {
        let selected_market_place = product.titles && product.titles[ 0 ] ? product.titles[ 0 ].market_place : source_market_place

        for (let i = 0; i < product.titles.length; i++) {
          const { market_place } = product.titles[ i ]
          if (source_market_place === market_place) {
            selected_market_place = source_market_place
          }
        }
        return <>
          <NavigationButton className="mb-1 w-100" to={`/product/edit/${product.product_id}/${selected_market_place}`}>
            <FiZap/> {translate({
            fr: `Optimiser`,
            en: `Optimize`,
          })} <FiArrowRight/>
          </NavigationButton>
          {product.state === 'active' ?
            <ProductDeleteButton onDelete={() => {
              refresh_all()
            }} product_id={product.product_id}
                                 className="mb-1 w-100 btn-sm" classNameGroup="w-100 btn-group-sm"/> :
            <>
              <ProductRestoreButton onDelete={() => {
                refresh_all()
              }} product_id={product.product_id}
                                    className="mb-1 w-100 btn-sm" classNameGroup="w-100 btn-group-sm"/>
              <ProductDefinitiveDeleteButton onDelete={() => {
                refresh_all()
              }} product_id={product.product_id}
                                             className="mb-1 w-100 btn-sm" classNameGroup="w-100 btn-group-sm"/>
            </>}

        </>
      },
      width: 200,
    },
  ]


  const can_go_to_next_page = !( ~~current_page === last_page - 1 )
  const can_go_to_previous_page = !( ~~current_page === 0 )

  const pagination = ( can_go_to_next_page || can_go_to_previous_page ) ? <>
    <div className="row mt-2 mb-2 align-items-center">
      <div className="col">

      </div>
      <div className="col-auto">
        <TextButton onClick={() => {
          set_current_page(Math.max(0, ~~current_page - 1))
        }}
                    disabled={!can_go_to_previous_page}>{translate({
          fr: `Produits précédents`,
          en: `Previous products`,
        })}</TextButton>
      </div>
      <div className="col-auto font-weight-bold ">
        {french(`Page`)}
        <input className="m-2" style={{ width: 80 }} type="number" value={~~current_page + 1}
               onChange={(e) => set_current_page(Math.max(Math.min(last_page - 1, ~~( e.target.value ) - 1), 0))}
               step={1}/>
        / {last_page}
      </div>
      <div className="col-auto">
        <TextButton onClick={() => {
          set_current_page(Math.min(~~current_page + 1, last_page - 1))
        }}
                    disabled={!can_go_to_next_page}>{translate({
          fr: `Produits suivants`,
          en: `Next products`,
        })}</TextButton>
      </div>
    </div>
  </> : null

  const show_trash = product_state === 'deleted'

  const selected_product_ids = Object.keys(selected_products).filter((product_id) => selected_products[ product_id ])
  const has_selected_products = selected_product_ids.length > 0

  const search_results_filtered = _.uniqBy(search_products.products || [], 'product_id')

  const products_to_display = real_search_query !== '' ? ( search_results_filtered ) : products

  if (products.error) {
    console.log('Failed to get products', products)
    return <div className="row">
      <div className="col-12">
        <DefaultError/>
      </div>
    </div>
  }

  return (
    <div className="row pt-2" style={{ overflowX: 'hidden' }}>
      <div className="col-12">
        <OnboardingVideo video_key={'product_optimization'}/>
      </div>
      <div className="col-12">
        {!show_trash ? <BigBox title={translate({
          fr: 'Liste des produits',
          en: 'Product list',
        })} actions={<><ImportProductOrNot className={'mr-2'} on_success={refresh_all}/>
          <AddProductButton className={'mr-2'} label={translate({
            fr: 'Créer un brouillon',
            en: 'Create a draft',
          })}/><Button button={'danger'}
                       onClick={() => {
                         set_real_search_query('')
                         set_selected_products({})
                         set_allow_selection(false)
                         set_product_state('deleted')
                       }}>{translate({
            fr: `Voir la corbeille`,
            en: `Go to the trash`,
          })} ({deleted_product_count.count})</Button></>}>
          <div className="row align-items-center">
            <div className="col-auto">
              <div className="row">
                <Checkbox value={allow_selection} setter={set_allow_selection}/>
              </div>
            </div>
            <div className="col">
              <div className="row">
                <Field type="text" value={search_query} setter={set_search_query} margin_bottom={0}
                       placeholder={translate({
                         fr: 'Rechercher un produit avec son ASIN ou SKU',
                         en: 'Find a product with its ASIN or SKU',
                       })}/>
              </div>
            </div>
            <div className="col-auto text-right">
              {real_search_query !== '' ? <Button className="btn-block" outline={true} onClick={() => {
                set_search_query('')
                set_real_search_query('')
              }}>{translate({
                fr: `Remettre à zéro la recherche`,
                en: `Reset the search`,
              })}</Button> : <div className="col font-weight-bold">
                {product_count.count} {translate({
                fr: 'produit(s)',
                en: 'product(s)',
              })}
              </div>}
            </div>
          </div>
        </BigBox> : null}
        {products_to_display.length > 0 ? pagination : null}

        {allow_selection ? <div className="row">
          <div className="col-12">
            <Button onClick={() => {
              const all_products_selected = {}
              for (let i = 0; i < products_to_display.length; i++) {
                all_products_selected[ products_to_display[ i ].product_id ] = true
              }
              set_selected_products(all_products_selected)
            }}>{french(`Tout sélectionner`)} </Button>
          </div>
        </div> : null}

        {has_selected_products && allow_selection ? <div className="row text-right mb-2">
          <div className="col-12">
            {translate({
              fr: `Actions sur les produits sélectionnés :`,
              en: `Actions on selected products:`,
            })} <MergeProducts selected_product_ids={selected_product_ids}
                               refresh_product={async () => {
                                 try {
                                   await refresh_all()
                                 } catch (e) {
                                   // osef
                                 }
                               }}/> <DeleteProducts selected_product_ids={selected_product_ids}
                                                    refresh_product={async () => {
                                                      try {
                                                        await refresh_all()
                                                      } catch (e) {
                                                        // osef
                                                      }
                                                    }}/>
          </div>
        </div> : null}

        {search_products.loading || search_query !== real_search_query ? <div className="row">
          <div className="col-12">
            <DefaultLoading/>
          </div>
        </div> : ( !products.loading ? <div className="row pb-5">
          {show_trash ? <>
            <div className="col-12">
              <BigBox title={<>{products.length} {translate({
                fr: 'produit(s)',
                en: 'product(s)',
              })} {translate({
                fr: `dans la corbeille`,
                en: `in the trash bin`,
              })}</>} actions={<div className="row text-right">
                <div className="col-12">
                  <Button button={'success'}
                          onClick={() => set_product_state('active')}>{translate({
                    fr: `Sortir de la corbeille`,
                    en: `Leave the trash bin`,
                  })}</Button>
                </div>
                <div className="col-12">
                  <EmptyTrashBin count={deleted_product_count.count} refresh_all={refresh_all}/>
                </div>
              </div>}>
              </BigBox>
            </div>
          </> : null}

          {products_to_display.length === 0 && product_state === 'active' && !search_products.loading ?
            <div className="col-12">
              <NoProductsYet refresh_all={refresh_all} search_query={real_search_query}/>
            </div> : null}

          <div className="col-12">
            {( products_to_display ).map((product, line) => {
              return <div className="row no-gutters align-items-center" key={product.product_id}>
                {allow_selection ? <div className="col-auto">
                  <div className="row">
                    <Checkbox value={!!selected_products[ product.product_id ]}
                              setter={(v) => set_selected_products({
                                ...selected_products,
                                [ product.product_id ]: v,
                              })}/>
                  </div>
                </div> : null}
                <div className="col mb-3">
                  <Item className={product.state === 'deleted' ? 'deleted' : null}
                        active={product.progress.average > 90}>
                    <div className="row no-gutters ">
                      {columns.map(({ hash, content, width, label, className }, i) => {

                        return <div key={i}
                                    className={`col_content ${className} ${width === 'auto' ? 'col' : 'col-auto'} m-1 ${hash ? 'clickable' : ''}`}
                                    style={{ width }}
                                    onClick={() => hash ? navigate(`/product/edit/${product.product_id}#${hash}`) : null}>
                          {content(product)}
                          {label && line !== 0 ?
                            <div className="hidden d-none font-weight-bold">{label}</div> : null}
                        </div>
                      })}
                    </div>
                  </Item>
                </div>
              </div>
            })
            }
          </div>
        </div> : <div className="row pb-5">
          <div className="col-12">
            <div><h2><DefaultLoading/></h2></div>
          </div>
        </div> )}
        {products_to_display.length > 0 ? pagination : null}

      </div>
    </div>
  )
}

const NoProductsYet = ({ refresh_all, search_query }) => {
  const french = useFrench()
  const translate = useTranslate()

  return <Item>
    <div className="row align-items-center">
      <div className="col-12 col-md-4">
        <img src={strategy} alt=""/>
      </div>
      <div className="col-12 col-md-8">
        <div className="row">
          {
            search_query ?
              <>
                <div className="col-12">
                  <h2>{french(`Aucun résultat pour :`)} "{search_query}"</h2>
                </div>
              </> :
              <>
                <div className="col-12">
                  <h2>{french(`Tu n'as encore aucun produit`)}</h2>
                </div>
                <div className="col-12">
                  <p>{french(`Tu peux commencer à optimiser tes produits de deux manières :`)}</p>
                </div>
              </>
          }

          <div className="col-12">
            <p>
              <ImportProductOrNot on_success={refresh_all}/><span
              className="mx-3">{french(`ou`)}</span>
              <AddProductButton label={translate({
                fr: 'Créer un brouillon',
                en: 'Create a draft',
              })}/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Item>
}
