import { is_publishable } from '../product_v2/publish_product_v2'

export const single_product_update_selector = (product, selected_updates) => {
  if(selected_updates.length===0) {
    return []
  }

  const { product_id, sku, asin, product_info: { updates, asin_override, sku_override}, variations } = product
  const returned_products = []

  if (is_publishable(product)) {
    returned_products.push({
      product_id,
      sku: sku_override || sku,
      asin: asin_override || asin,
      updates: updates.filter(({ field }) => selected_updates.indexOf(field) !== -1),
    })
  }

  for (let i = 0; i < variations.length; i++) {
    const { product_id, sku, asin, product_info: { updates, asin_override, sku_override } } = variations[ i ]

    if (is_publishable(variations[i])) {
      returned_products.push({
        product_id,
        sku: sku_override || sku,
        asin: asin_override || asin,
        updates: updates.filter(({ field }) => selected_updates.indexOf(field) !== -1),
      })
    }
  }

  return returned_products
}
