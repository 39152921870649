import React from 'react'

export default function ProgressBar({ progress, max_value = 100, success_threshold= max_value}) {
  if (!progress) {
    return null
  }
  return (
    <div className="col-12">
      <div
        className={`progress-bar rounded ${progress < success_threshold ? 'progress-bar-striped progress-bar-animated' : 'bg-success'}`}
        role="progressbar"
        aria-valuenow={progress} aria-valuemin="0" aria-valuemax={max_value}
        style={{
          width: (100 * (progress / max_value)) + '%',
          fontSize: 16,
          minHeight: 30,
          minWidth: 50,
          fontWeight: 600,
        }}><b>{progress.toFixed(0)} {max_value === 100 ? '%':`/ ${max_value}`}</b></div>
    </div>
  )
}
