import React, { useState } from 'react'

import './button.css'
import Redirect from './redirect'
import { useTranslate } from '../../context/lang'

export default function DeleteButton({ children, className = '', classNameGroup = '', onClick, disabled=false, mutation = {}, redirect, success }) {
  const translate = useTranslate()

  const [delete_confirmation, set_delete_confirmation] = useState(false)

  if (!delete_confirmation) {
    return (
      <button className={`btn btn-outline-danger ${className}`}
              disabled={disabled}
              onClick={() => set_delete_confirmation(true)}>{children}</button>
    )
  }

  disabled = disabled || mutation.loading

  if (mutation.error) {
    return <div>{translate({
        fr: `Impossible de supprimer l'élément`,
        en: `Error while deleting the element`
    })} </div>
  }

  if (mutation.data && redirect) {
    return <Redirect to={redirect}/>
  }

  if (mutation.data && success) {
    return <>{success}</>
  }

  return (
    <div className={`btn-group ${classNameGroup}`}>
      <button className={`btn btn-danger ${className}`}
              disabled={disabled}
              onClick={() => onClick && onClick()}>{translate({
                  fr: `Confirmer`,
                  en: `Confirm`
              })}
      </button>
      <button className={`btn btn-outline-danger ${className}`}
              disabled={disabled}
              onClick={() => set_delete_confirmation(false)}>{translate({
                  fr: `Annuler`,
                  en: `Cancel`
              })}
      </button>
    </div>
  )

}
