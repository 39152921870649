import React from 'react'
import { parse_nice_float } from './pricing_formulas'


const compute_target_price_for_margin_percentage = (margin, details) => {
  const {
    is_auto_entrepreneur,
    auto_entrepreneur_rate,
    fixed_cost,
    expedition,
    commission,
    vat,
  } = details

  if (is_auto_entrepreneur) {
    return ( fixed_cost + expedition ) / ( 1 / (1+vat) - commission - margin / ( 1 + vat ) - auto_entrepreneur_rate )
  }

  return ( fixed_cost + expedition ) / ( ( 1 / ( 1 + vat ) ) - commission - ( margin / ( 1 + vat ) ) )
}

export const useMarginSimulations = (details) => {

  const margins = [0.000001, 0.05, 0.1, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50]

  const prices = []

  for (let i = 0; i < margins.length; i++) {
    const margin = margins[ i ]

    prices.push({
      percentage: margin,
      price: compute_target_price_for_margin_percentage(margin, details),
    })
  }

  return prices
}
