import React from 'react'
import { useDesignerContext } from '../designer_context'
import { clean_for_label } from '../../../routes/product/text-substitution'

export default function VariationLabel({ product_id }) {
  const { product } = useDesignerContext()

  const variation = product.variations.find((v) => v.product_id === product_id)

  if (!variation) {
    return null
  }

  return (
    <span>{clean_for_label(variation.product_info.variation, 50, [])}</span>
  )
}

export function ProductLabel() {
  const { product } = useDesignerContext()

  if (product.variations && product.variations.length > 0) {
    return <span>du modèle</span>
  }

  return (
    <span>de {clean_for_label(product.product_info.title, 50, [])}</span>
  )
}
