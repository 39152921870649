import React from 'react'
import PixabaySearchBar from './pixabay_search_bar'
import { Browse_Content_Section, Browse_Content_SubTitle } from '../browse__content'
import Button from '../../../generic/button'
import PixabayResults, { categories_label_fr, displayed_categories } from './pixabay_results'
import ExternalLink from '../../../generic/external-link'
import { useFrench } from '../../../../context/lang'


export const LibrarySelector = ({ on_click }) => {

  const [pixabay_query, set_pixabay_query] = React.useState('')

  const french = useFrench()

  return (
    <>

      <Browse_Content_Section>
        <PixabaySearchBar update_query={(query) => set_pixabay_query(query)}
                          current_query={pixabay_query}/>
      </Browse_Content_Section>

      {pixabay_query ? <Browse_Content_Section>
        <PixabayResults query={pixabay_query} lang={'fr'} page={'1'} per_page={'50'} on_click={on_click} square={true}
                        can_load_more={true}/>
      </Browse_Content_Section> : displayed_categories.map((category) => {
        return <Browse_Content_Section>
          <Browse_Content_SubTitle>{french(categories_label_fr[ category ])} <Button button="secondary" className="btn-sm"
                                                                             onClick={() => set_pixabay_query(category)}>
            {french(`Voir plus`)}
          </Button>
          </Browse_Content_SubTitle>
          <PixabayResults category={category} per_page={2} on_click={on_click}/>
        </Browse_Content_Section>
      })}

    </>
  )
}
