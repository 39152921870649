import React from 'react'
import { useDesignerContext } from '../designer_context'
import Option_Content, { Option_Content_Title } from './select__content'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { FilterSetting } from './select_filters'
import { Browse_Content_Title } from '../designer/browse__content'
import { useTranslate } from '../../../context/lang'


export default function SelectBookProperties({ get_value, update_object, show_transparent }) {
  const { get_selected_object, update_object_props, current_objects, current_product_id } = useDesignerContext()
  const translate = useTranslate()

  const selected_object = apply_overrides_to_object(get_selected_object(), current_objects.overrides[ current_product_id ])

  const filters = get_value(selected_object) || {}

  const do_update_object = (update) => update_object_props(update_object(selected_object, { ...filters, ...update }))

  return (
    <Option_Content>
      <Browse_Content_Title>{translate({
          fr: `Propriétés du livre`,
          en: `Book properties`
      })}</Browse_Content_Title>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
          fr: 'Angle',
          en: 'Angle',
      })} field={'angle'}
                     default_value={30} min={0} max={90} step={1}/>
      <FilterSetting filters={filters} do_update_object={do_update_object} label={translate({
          fr: 'Nombre de page',
          en: 'Number of pages',
      })} field={'pages'}
                     default_value={100} min={50} max={500} step={10}/>

    </Option_Content>
  )
}
