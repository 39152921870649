import React, { useContext } from 'react'
import UserContext from '../../context/user'
import Field from '../../components/generic/field'
import { useMutation } from '@apollo/client'
import UpdateButton from '../../components/generic/update-button'
import ConnectIntellifoxToAmazonButton from '../../components/user/add-developper-mws'
import IntellifoxModal from '../../components/generic/modal'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import Button from '../../components/generic/button'
import { remove_amazon_account } from '../../graphql/queries/company'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import {
  FaAmazon,
  FaArrowRight,
  FaBackspace,
  FaCheck,
  FaSave, FiCheck, FiEdit,
  FiPlus, FiSave, FiTrash, FiX,
  MdAdd,
  MdCancel,
  MdErrorOutline,
} from 'react-icons/all'
import HelpButton from '../../components/generic/help_button'
import { useCachedContext } from '../../hooks/useCachedContext'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import Item, { CompactItem } from '../../components/generic/item'
import Flag from '../../components/flag/flag'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import { await_mutate } from '../../graphql/mutate_promise'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { FaEdit } from 'react-icons/fa'
import DeleteButton from '../../components/generic/delete-button'
import { MdDelete, MdWarning } from 'react-icons/md'
import ExternalLinkButton from '../../components/generic/external-link-button'
import NavigationButton from '../../components/generic/navigation-button'
import Redirect from '../../components/generic/redirect'
import useHashParam from 'use-hash-param'
import ProgressBar from '../../components/generic/progress_bar'
import { amazon_regions, find_market_place, MarketPlacesTable, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import CrispButton from '../../components/generic/crisp_button'
import Upgrade, { subscription_tiers } from '../subscription/upgrade'
import { navigate } from '../../absolute_link'
import { Checkbox } from '../../components/generic/checkbox'
import TextButton from '../../components/generic/text_button'
import { format_percentage } from '../order/format_numbers'


export const AmazonAccounts = ({ current_path = 'settings', hide_details = false }) => {

  const amazon_accounts = useCachedContext('amazon_accounts')
  const company = useCompany()

  const { subscription_tier } = company

  const [added_account, set_added_account] = useHashParam('added_account')
  const [spapi_oauth_code, set_spapi_oauth_code] = useHashParam('spapi_oauth_code', null)
  const [merchant_id, set_merchant_id] = useHashParam('merchant_id', null)
  const [market_place_from_hash, set_market_place_from_hash] = useHashParam('market_place', null)


  const translate = useTranslate()
  const french = useFrench()

  if (amazon_accounts.loading) {
    return <DefaultLoading/>
  }

  if (amazon_accounts.error) {
    return <DefaultError/>
  }

  const needs_to_upgrade = subscription_tier === subscription_tiers.single_amazon_account && amazon_accounts.length > 0

  return <div className="row">
    {amazon_accounts.length > 0 ? <div className="col-12 mb-3">
      {amazon_accounts.map((amazon_account) => {
        return <div className="row" key={amazon_account.merchant_id}>
          <div className="col-12 mb-3">
            <AmazonAccount amazon_account={amazon_account}
                           amazon_accounts={amazon_accounts}
                           added_account={added_account}
                           hide_details={hide_details}
            />
          </div>
        </div>
      })}
    </div> : null}

    <div className="col-12 mb-2">
      <div className="row">

        <div className="col-12">
          <AddAmazonAccount current_path={current_path}
                            amazon_accounts={amazon_accounts}
                            added_account={added_account}
                            set_added_account={set_added_account}
                            spapi_oauth_code={spapi_oauth_code}
                            set_spapi_oauth_code={set_spapi_oauth_code}
                            merchant_id={merchant_id}
                            set_merchant_id={set_merchant_id}
                            market_place_from_hash={market_place_from_hash}
                            set_market_place_from_hash={set_market_place_from_hash}
                            needs_to_upgrade={needs_to_upgrade}
          >
            {amazon_accounts.length === 0 ? translate({
                fr: `Ajouter un compte Amazon`,
                en: `Add Amazon account`,
              }) :
              <> {translate({
                fr: `Ajouter un autre compte Amazon`,
                en: `Add another Amazon account`,
              })}
              </>
            }
          </AddAmazonAccount>
        </div>
      </div>

    </div>

  </div>
}

const Step = ({ step, title, children, disabled = false }) => {

  return <div className="row align-items-center mb-4" style={{ opacity: disabled ? 0.3 : 1 }}>
    <div className="col-12 mb-1">
      <div className="row">
        <div className="col-12">
          <span className="step-number mr-1">{step}</span>
          <span className="font-weight-bold">{title}</span>
        </div>
      </div>

    </div>
    <div className="col-12">
      <Item>
        <div className="row">

          <div className="col-12">
            {children}
          </div>
        </div>
      </Item>
    </div>
  </div>
}

export const intellifox_sp_api_url = (market_place_details, state) => {
  if (!market_place_details) {
    return ''
  }
  return `${market_place_details.seller_central_url}/apps/authorize/consent?application_id=amzn1.sellerapps.app.0c27b067-aad0-45cd-be7e-09e380589bb8&state=${state}&redirect_uri=https%3A%2F%2Fapp.intellifox.com%2Famazon%2Fconfirmation`
}

const AddAmazonAccount = ({
                            current_path = 'settings',
                            children,

                            amazon_accounts,
                            spapi_oauth_code,
                            set_spapi_oauth_code,
                            merchant_id,
                            set_merchant_id,
                            market_place_from_hash,
                            set_market_place_from_hash,
                            added_account,
                            set_added_account,

                            disabled,
                            needs_to_upgrade,

                          }) => {

  const translate = useTranslate()
  const french = useFrench()

  const [selected_market_place, set_selected_market_place] = React.useState(market_place_from_hash || '')

  const set_retry = () => {
    set_spapi_oauth_code('')
    set_merchant_id('')
    set_market_place_from_hash('')
  }

  const set_success = (merchant_id) => {
    set_spapi_oauth_code('')
    set_merchant_id('')
    set_market_place_from_hash('')
    set_added_account(merchant_id)
    amazon_accounts.refresh()
  }

  let selected_market_place_details
  let selected_region = {}
  for (let i = 0; i < amazon_regions.length; i++) {
    const amazon_region = amazon_regions[ i ]
    for (let j = 0; j < amazon_region.market_places.length; j++) {
      const market_place = amazon_region.market_places[ j ]
      if (market_place.market_place === selected_market_place) {
        selected_market_place_details = market_place
        selected_region = amazon_region
      }
    }
  }

  if (needs_to_upgrade && !market_place_from_hash) {
    return <IntellifoxModal title={french(`Passer au niveau supérieur`)}
                            body={<>
                              <div className="alert alert-warning">

                                <MdWarning/> {french(`Pour pouvoir utiliser Intellifox avec plusieurs comptes, tu dois passer à un forfait supérieur.`)}

                              </div>
                            </>}
                            on_click={() => navigate('/subscription/upgrade')}
                            action_label={french(`Voir les options`)}
    >
      <FiPlus/> <FaAmazon/> {children}

    </IntellifoxModal>
  }


  return <IntellifoxModal title={french('Ajouter un compte Amazon')}
                          show_modal_default={market_place_from_hash}
                          body={<div className="row">
                            <div className="col-12">
                              <Step step={1} title={translate({
                                fr: 'Sélectionner une marketplace Amazon',
                                en: 'Select an Amazon marketplace',
                              })}>
                                {amazon_regions.map((r) => {
                                  if (market_place_from_hash && selected_region.region_id !== r.region_id) {
                                    return null
                                  }
                                  return <div className="row mb-2">
                                    <div className="col-12 small">
                                      {translate(r.label)}
                                    </div>
                                    <div className="col-12">
                                      {r.market_places.map(({ market_place, label }) => {
                                        if (market_place === market_place_from_hash) {
                                          return <Button><Flag
                                            market_place={market_place}/> {translate(label)} </Button>
                                        }

                                        if (market_place_from_hash) {
                                          return null
                                        }

                                        return <Button className="btn-sm mr-1"
                                                       button={market_place === selected_market_place ? 'primary' : 'secondary'}
                                                       onClick={() => set_selected_market_place(market_place)}
                                        ><Flag
                                          market_place={market_place}/> {translate(label)} </Button>
                                      })}
                                    </div>
                                  </div>
                                })}
                              </Step>
                              <Step step={2} title={translate({
                                fr: `Autoriser Intellifox sur Amazon`,
                                en: `Authorize Intellifox on Amazon`,
                              })} disabled={!selected_market_place_details}>
                                {market_place_from_hash ?
                                  <AddAmazonAccountForReal
                                    origin={current_path}
                                    label={`Amazon ${translate(selected_region.label)}`}
                                    region_id={selected_region.region_id}
                                    merchant_id={merchant_id}
                                    spapi_oauth_code={spapi_oauth_code}
                                    set_retry={set_retry}
                                    set_success={set_success}
                                  /> : <div className="row">
                                    <div className="col-12">
                                      <ExternalLinkButton
                                        target={''}
                                        to={intellifox_sp_api_url(selected_market_place_details, `${current_path},${selected_market_place}`)}>
                                        <FaAmazon/> {translate({
                                        fr: `Autoriser Intellifox sur le Seller Central`,
                                        en: `Authorize Intellifox on Amazon Seller Central`,
                                      })} {selected_market_place.toUpperCase()} <Flag
                                        market_place={selected_market_place}/></ExternalLinkButton>
                                    </div>
                                    <div className="col-12">
                                      <div className="col">
                                        <MdWarning/> {french(`Pour pouvoir utiliser la procédure de connexion à Amazon, il faut avoir un
              compte Amazon Professionnel`)}
                                      </div>
                                    </div>
                                  </div>}
                              </Step>
                            </div>
                          </div>}
                          button_props={{ disabled }}
  >
    <FiPlus/> <FaAmazon/> {children}
  </IntellifoxModal>
}

const AddAmazonAccountForReal = ({
                                   origin,
                                   region_id,
                                   label,
                                   merchant_id,
                                   spapi_oauth_code,
                                   set_retry,
                                   set_success,
                                 }) => {
  const company_id = useCompanyId()

  const translate = useTranslate()
  const french = useFrench()

  const [result, set_result] = React.useState('in_progress')

  const [add_amazon_account] = useMutation(create_mutation({
    mutation: 'add_amazon_account',
    params: {
      company_id: 'ID!',
      region_id: 'String',
      label: 'String',
      merchant_id: 'String',
      spapi_oauth_code: 'String',
    },
  }))


  React.useEffect(() => {
    const go = async () => {
      try {
        const output = await await_mutate(add_amazon_account, {
          variables: {
            company_id,
            region_id,
            label,
            merchant_id,
            spapi_oauth_code,
          },
        })
        if (output.add_amazon_account) {
          set_success(merchant_id)
        } else {
          set_result('failure')
        }
      } catch (e) {
        if (e && /account_already_connected/.test(e.message)) {
          set_result('failure_account_already_connected')
        } else {
          console.log('error', e)
          set_result('failure')
        }
      }
    }
    go().catch(e => console.error(e))
  }, [])

  return <div className="row">
    <div className="col-12">
      <div className={`alert ${result === 'failure' ? 'alert-warning' : 'alert-primary'}`}>
        {result === 'in_progress' ? <>
          {
            translate({
              fr: `Ajout du compte Amazon en cours...`,
              en: `Adding the Amazon account...`,
            })
          }
        </> : null}
        {result === 'success' ? <>
          <Redirect to={`/${origin}#?added_account=${merchant_id}`}/>
        </> : null}
        {result === 'failure' ? <>
          <div className="row">
            <div className="col-12 mb-2 font-weight-bold">
              {french(`Il y a eu un problème lors de l'ajout du compte.`)}
            </div>
            <div className="col-12">
              {french(`Vérifie tes emails ou contacte le support si cela persiste.`)}
            </div>
            <div className="col-12">
              <Button onClick={() => set_retry(true)}>{translate({
                fr: `Essayer à nouveau`,
                en: `Try again`,
              })}</Button>
            </div>
          </div>
        </> : null}
        {result === 'failure_account_already_connected' ? <>
          <div className="row">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-12 mb-2 font-weight-bold">
                  <MdWarning/> {french(`Ce compte Amazon a déjà été ajouté sur un autre compte Intellifox`)}
                </div>
                <div className="col-12 mb-2">
                  {french(`Pour bénéficier d'Intellifox, il faut réactiver le compte original où a été ajouté le compte Amazon pour la première fois.`)}
                </div>
                <div className="col-12">
                  <CrispButton
                    text={french(`Bonjour, je n'arrive pas à ajouter mon compte Amazon. \nerreur = failure_account_already_connected \nmerchant_id =`) + merchant_id}>{french(`Demander de l'aide au support`)}</CrispButton>
                </div>
                <div className="col-12">
                  <Button onClick={() => set_retry(true)}>{translate({
                    fr: `Essayer à nouveau`,
                    en: `Try again`,
                  })}</Button>
                </div>
              </div>
            </div>
          </div>
        </> : null}
      </div>
    </div>
  </div>
}

const AmazonAccount = ({ amazon_account, amazon_accounts, added_account, hide_details }) => {
  let {
    label,
    merchant_id,
    market_places,
    invalid_grant,
    is_not_vat_registered,
    auto_entrepreneur_config,
  } = amazon_account
  market_places = market_places || []
  const lang = useLang()
  const translate = useTranslate()
  const french = useFrench()
  const company_id = useCompanyId()


  const [remove_amazon_account] = useMutation(create_mutation({
    mutation: 'remove_amazon_account',
    params: {
      company_id: 'ID!',
      merchant_id: 'String',
    },
    output: '',
  }))


  const do_delete = async () => {
    await await_mutate(remove_amazon_account, {
      variables: {
        company_id,
        merchant_id,
      },
    })

    await amazon_accounts.refresh()
  }

  const is_newly_added_account = added_account === merchant_id
  const has_problem = invalid_grant || !market_places || market_places.length === 0

  const market_place = market_places && market_places.length > 0 ? market_places[ 0 ] : 'fr'
  const market_place_details = useMarketPlaceDetails(market_place)

  return <Item active={is_newly_added_account}>
    {is_newly_added_account ? <div className="row mb-2">
      <div className="col-12">
        <div className="alert alert-success">
          <FaCheck/> {translate({
          fr: `Le compte Amazon est correctement connecté à Intellifox`,
          en: `Your Amazon account is properly connected to Intellifox`,
        })}
        </div>
      </div>
    </div> : null}
    {has_problem ? <div className="row mb-2">
      <div className="col-12">
        <div className="alert alert-danger">
          <div className="row">
            <div className="col-12 font-weight-bold">
              <MdWarning/> {translate({
              fr: `Intellifox ne peut pas importer ou publier des produits : une action est nécessaire`,
            })}
            </div>
            <div className="col-12">
              {french(`Clique sur ce bouton pour résoudre le problème :`)} <ExternalLinkButton
              target={''}
              to={intellifox_sp_api_url(market_place_details, `settings,${market_place}`)}>
              <FaAmazon/> {translate({
              fr: `Autoriser Intellifox à nouveau sur le Seller Central`,
              en: `Refresh Intellifox authorization on Amazon Seller Central`,
            })}</ExternalLinkButton>
            </div>
          </div>
        </div>
      </div>
    </div> : null}
    <div className={`row ${is_newly_added_account ? 'mb-5' : ''}`}>
      <div className="col-12 font-weight-bold">
        <h4>{label} {hide_details ? market_places.map((m) => <Flag market_place={m}/>) : null}</h4>
      </div>
      <div className="col-12">
        <span className="badge badge-secondary BLUR_FOR_WEBINAR">{translate({
          fr: `Identifiant Amazon`,
          en: `Amazon ID`,
        })} {merchant_id}</span>
      </div>
      {hide_details ? null :
        <>
          <div className="col-12">
            <MarketPlacesTable market_places={market_places}
                               show_vats={is_not_vat_registered}/>
          </div>
          <div className="col-12 col-lg-6">
            {is_not_vat_registered ? <div className="row">
              <div className="col-12">
                <span className="badge badge-secondary">
              <FiX/> {french(`Ne récupère pas la TVA sur les frais de ventes`)}
            </span>
              </div>
            </div> : null}
            {auto_entrepreneur_config.is_auto_entrepreneur ? <div className="row">
              <div className="col-12">
                <span className="badge badge-success">
              <FiCheck/> {french(`Mode auto-entrepreneur activé`)} (<AutoEntrepreneurRate
                  auto_entrepreneur_config={auto_entrepreneur_config}/>)
            </span>
              </div>
            </div> : null}
          </div>
          <div className="col-12 col-lg-6 text-right">
            <DeleteButton onClick={do_delete} className={'mb-1'}><FiTrash/> {translate({
              fr: 'Supprimer',
              en: 'Delete',
            })}</DeleteButton>
            <UpdateAmazonAccount amazon_account={amazon_account} on_success={() => amazon_accounts.refresh()}/>
          </div>
        </>
      }
    </div>
  </Item>
}


export const UpdateAmazonAccount = ({ amazon_account, on_success, className = 'ml-2', button_label }) => {
  const lang = useLang()
  const french = useFrench()
  const company_id = useCompanyId()
  const translate = useTranslate()

  const [label, set_label] = React.useState(amazon_account.label)

  const [auto_entrepreneur_config, set_auto_entrepreneur_config] = React.useState(amazon_account.auto_entrepreneur_config || {})
  const [is_not_vat_registered, set_is_not_vat_registered] = React.useState(amazon_account.is_not_vat_registered)

  const [save] = useMutation(create_mutation({
    mutation: 'update_amazon_account',
    params: {
      company_id: 'ID!',
      merchant_id: 'String',
      label: 'String',
      is_not_vat_registered: 'Boolean',
      auto_entrepreneur_config: 'String',
    },
    output: '',
  }))

  const do_save = async () => {
    await await_mutate(save, {
      variables: {
        company_id,
        merchant_id: amazon_account.merchant_id,
        label,
        is_not_vat_registered,
        auto_entrepreneur_config: JSON.stringify(auto_entrepreneur_config),
      },
    })

    await on_success()
  }

  if (!amazon_account || !amazon_account.merchant_id) {
    return <IntellifoxModal title={<><FiEdit/> {french(`Modifier le compte Amazon`)}</>}
                            body={<div className="row">
                              <div className="col-12">
                                {translate({
                                  fr: `Il faut d'abord ajouter un compte Amazon afin de pouvoir configurer les spécificités lié au compte.`,
                                })}
                              </div>
                              <div className="col-12">
                                <NavigationButton to={'/settings'}>{french(`Accéder aux paramètres`)}</NavigationButton>
                              </div>
                            </div>}
                            button_props={{ className }}

    >
      {button_label ? button_label : <><FiEdit/> {translate({
        fr: `Modifier les paramètres de TVA`,
        en: `Edit VAT settings`,
      })}</>}
    </IntellifoxModal>
  }


  return <IntellifoxModal title={<><FiEdit/> {french(`Modifier le compte Amazon`)}</>}
                          body={<div className="row">
                            <Field type={'text'} value={label} setter={set_label} label={translate({
                              fr: 'Nom du compte Amazon',
                              en: 'Label of the Amazon Account',
                            })}/>
                            <div className="col-12 mt-4">
                              <CompactItem label={french(`Options supplémentaires`)}>
                                <div className="row">
                                  <Checkbox value={is_not_vat_registered} setter={set_is_not_vat_registered}>
                                    {french(`Ne récupère pas la TVA sur les frais de ventes`)}
                                  </Checkbox>
                                  <div className="col-12">
                                    {lang === 'fr' ?
                                      <>
                                        <hr/>
                                        <AutoEntrepreneurSettings
                                          amazon_account={amazon_account}
                                          auto_entrepreneur_config={auto_entrepreneur_config}
                                          set_auto_entrepreneur_config={set_auto_entrepreneur_config}/>
                                      </> : null}
                                  </div>
                                </div>
                              </CompactItem>
                            </div>

                          </div>}
                          action_label={<><FiSave/> {french(`Sauvegarder`)}</>}
                          on_click={do_save}
                          button_props={{ className }}

  >
    {button_label ? button_label : <><FiEdit/> {translate({
      fr: `Modifier les paramètres de TVA`,
      en: `Edit VAT settings`,
    })}</>}
  </IntellifoxModal>
}


export const compute_auto_entrepreneur_rate = (auto_entrepreneur_config) => {
  const {
    is_auto_entrepreneur, taux_cotisation, cfp, tfc, is_versement_liberatoire,
  } = auto_entrepreneur_config

  if (!is_auto_entrepreneur) {
    return 0
  }

  return ( taux_cotisation + cfp + tfc + ( is_versement_liberatoire ? 1 : 0 ) ) / 100
}

export const AutoEntrepreneurRate = ({ auto_entrepreneur_config }) => {


  return <>{format_percentage(compute_auto_entrepreneur_rate(auto_entrepreneur_config), 1)}</>
}
const AutoEntrepreneurSettings = ({ auto_entrepreneur_config, set_auto_entrepreneur_config }) => {

  const {
    is_auto_entrepreneur, taux_cotisation, cfp, tfc, is_versement_liberatoire,
  } = auto_entrepreneur_config

  const setter = (key) => (v) => set_auto_entrepreneur_config({
    ...auto_entrepreneur_config,
    [ key ]: v,
  })


  return <div className="row">
    <Checkbox value={!!is_auto_entrepreneur} setter={setter('is_auto_entrepreneur')}>
      Activer le mode auto-entrepreneur
    </Checkbox>

    {is_auto_entrepreneur ? <div className="col-12 mt-3">
      <CompactItem label={<>Configuration des taux URSSAF</>}>

        <Field type={'number'} value={taux_cotisation}
               setter={setter('taux_cotisation')}
               label={'Taux de cotisation (%)'}
               presets={(setter) => ( [3.2, 6.4, 9.6, 11.6, 12.8] )
                 .map((v) => <TextButton className={'mr-2 btn-sm'} onClick={() => setter(v)}>{v}%</TextButton>)
               }/>
        <Field type={'number'} value={cfp}
               setter={setter('cfp')}
               label={'Contribution à la formation professionnelle (%)'}
               presets={(setter) => ( [0.1] )
                 .map((v) => <TextButton className={'mr-2 btn-sm'} onClick={() => setter(v)}>{v}%</TextButton>)
               }/>
        <Field type={'number'} value={tfc}
               setter={setter('tfc')}
               label={'Taxe pour Frais de Chambres (%)'}
               presets={(setter) => ( [0.015, 0.02] )
                 .map((v) => <TextButton className={'mr-2 btn-sm'} onClick={() => setter(v)}>{v}%</TextButton>)
               }/>
        <div className="col-12">

          <hr/>
        </div>
        <Checkbox value={!!is_versement_liberatoire}
                  setter={setter('is_versement_liberatoire')}>Versement
          libératoire 1%</Checkbox>

      </CompactItem>
    </div> : null}
  </div>
}


