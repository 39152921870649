export const GET_SHAPES = `
    query GetShape($company_id: ID!, $limit: Int, $offset: Int) {
        shapes(company_id: $company_id, limit:$limit, offset:$offset) {
            shape_id
            url
            width
            height
        }
    }
`

export const GET_SHAPE = `
    query GetShape($company_id: ID!, $shape_id:ID!) {
        shape(company_id: $company_id, shape_id:$shape_id) {
            shape_id
            url
            width
            height
            svg
        }
    }
`
