import React from 'react'
import { useDesignerContext } from '../designer_context'
import Browse_Content, {
  Browse_Content_Picker_Item,
  Browse_Content_Section,
  Browse_Content_SubTitle,
  Browse_Content_Title,
} from './browse__content'
import { ProductImages } from './pictures/product_images'
import { DomRenderer } from '../renderer/dom_renderer'
import { useTranslate } from '../../../context/lang'

export default function BrowseTexts() {
  const { add_object } = useDesignerContext()
  const translate = useTranslate()

  const base_text = {
    object_id: 'bazzinga',
    type: 'text',
    x: 100, y: 100,
    width: 300,
    height: 50,
    angle: 0,
    props: {
      fill: 'A',
      textAlign: 'center',
      verticalAlign: 'center',
      content: `Ceci est un texte`,
      fontSize: 20,
      fontFamily: 'Lato',
      fontWeight: 400,
    },
  }

  const texts = [
    {
      width: 500, x: 0, y: 50, height: 50, props: {
        fontSize: 48, fontWeight: 600, content: translate({
          fr: 'Titre principal',
          en: 'Main title',
        }),
      },
    },
    {
      width: 500, x: 0, y: 100, height: 40, props: {
        fontSize: 36, fontWeight: 600, content: translate({
          fr: 'Sous-titre',
          en: 'Subtitle',
        }),
      },
    },
    {
      width: 500, x: 0, y: 50, height: 40, props: {
        fontSize: 26, fontWeight: 400, content: translate({
          fr: 'Phrase explicative',
          en: 'Explication sentence',
        }),
      },
    },
    {
      width: 460, x: 20, y: 150, height: 330, props: {
        fontSize: 30, fontWeight: 500, content: translate({
          fr: `<p><ul>
 <li>Liste</li>
 <li>Deuxième élément</li>
 <li>Troisième</li>
 <li>Quatrième</li>
 </ul>
 </p>`,
          en: `<p><ul>
        <li>List</li>
        <li>Second element</li>
        <li>Third</li>
        <li>Fourth</li>
        </ul>
        </p>`,
        }), textAlign: 'left', verticalAlign: 'top',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 50, props: {
        fontSize: 56, fontWeight: 400, content: translate({
          fr: 'Style robotique',
          en: 'Robotic style',
        }), fontFamily: 'Dot Matrix',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 50, props: {
        fontSize: 56, fontWeight: 400, content: translate({
          fr: 'Largeur fixe',
          en: 'Fixed width',
        }), fontFamily: 'Source Code Pro',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 60, props: {
        fontSize: 56, fontWeight: 400, content: translate({
          fr: 'Style différent',
          en: 'Different style',
        }), fontFamily: 'Grenze',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 70, props: {
        fontSize: 56, fontWeight: 400, content: translate({
          fr: 'Un extrait agréable',
          en: 'Nice to read',
        }), fontFamily: 'Mali',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 70, props: {
        fontSize: 56, fontWeight: 400, content: translate({
          fr: 'Texte classique',
          en: 'Classical',
        }), fontFamily: 'KoHo',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 70, props: {
        fontSize: 48, fontWeight: 400, content: translate({
          fr: 'Texte avec empattement',
          en: 'Text with serif',
        }), fontFamily: 'Trirong',
      },
    },
    {
      width: 500, x: 0, y: 250, height: 70, props: {
        fontSize: 48, fontWeight: 400, content: translate({
          fr: 'Autre avec empattement',
          en: 'Other with serif',
        }), fontFamily: 'Taviraj',
      },
    },
  ]

  return (
    <Browse_Content>
      <Browse_Content_Title>{translate({
          fr: `Textes`,
          en: `Texts`
      })}</Browse_Content_Title>
      <Browse_Content_Section>
        <Browse_Content_SubTitle>{translate({
            fr: `Modèles de texte`,
            en: `Text templates`
        })}</Browse_Content_SubTitle>
        {texts.map((text_object, i) => {
          const the_real_stuff = {
            ...base_text,
            ...text_object,
            props: {
              ...base_text.props,
              ...text_object.props,
            },
          }
          const sample = {
            ...base_text,
            ...text_object,
            x: 10,
            y: 0,
            width: 280,
            height: text_object.height / 2,
            props: {
              ...base_text.props,
              ...text_object.props,
              fontSize: text_object.props.fontSize / 2,
            },
          }

          return <div className={'mb-3 clickable'}>
            <DomRenderer width={300} height={text_object.height / 2} background="white"
                         objects={[sample]} onClick={() => {
              add_object(the_real_stuff)
            }
            }/>
          </div>
        })}

      </Browse_Content_Section>
    </Browse_Content>
  )
}
