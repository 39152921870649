import React from 'react'
import moment from 'moment'
import Button from '../../../components/generic/button'
import { FiArrowRight, FiCopy } from 'react-icons/all'
import { useFrench } from '../../../context/lang'
import Item from '../../../components/generic/item'
import { to_upper_first } from '../../order'

export const TimePicker = ({ daily_schedules, set_daily_schedules }) => {
  const on_click = (impacted_day, impacted_intervals, state) => {
    set_daily_schedules((daily_schedules) => daily_schedules.map((day) => {
      if (day.day !== impacted_day) {
        return day
      }
      return {
        ...day, intervals: day.intervals.map((interval) => {
          const is_impacted_interval = impacted_intervals.find((i) => i.start_hour === interval.start_hour)
          if (!is_impacted_interval) {
            return interval
          }
          return {
            ...interval, state,
          }
        }),
      }
    }))
  }

  const apply_intervals_to_every_days = (intervals) => {
    set_daily_schedules((daily_schedules) => daily_schedules.map((day) => {
      return {
        ...day, intervals,
      }
    }))
  }

  return <div className="row">
    <div className="col-12">
      {daily_schedules.map(({ day, intervals }, i) => {
        return <div className="row" key={i}>
          <div className="col-12">
            <TimePickerForDay day={day} intervals={intervals} on_click={on_click}
                              apply_intervals_to_every_days={apply_intervals_to_every_days}/>
          </div>
        </div>
      })}
    </div>
  </div>
}

const display_hour = (hour) => {
  return moment().startOf('day').add(hour, 'hour').format('LT').replace(/:[0-9][0-9]/g, '').replace(/^0/, '')
}

const TimeSlot = ({ day, start_hour, end_hour, state, on_click }) => {
  return <Button className={`btn-sm mr-1`} button={state === 'enabled' ? 'success' : 'danger'}
                 onClick={() => on_click(day, [{ start_hour }], state === 'enabled' ? 'paused' : 'enabled')}>
    {display_hour(start_hour)}<span className="mx-1"><FiArrowRight/></span>{display_hour(start_hour + 1)}
  </Button>
}
const TimePickerForDay = ({ day, intervals, on_click, apply_intervals_to_every_days }) => {
  const french = useFrench()

  const morning_intervals = intervals.filter(({ start_hour }) => start_hour < 12)
  const afternoon_intervals = intervals.filter(({ start_hour }) => start_hour >= 12)

  const shortcuts = [
    {
      label: french('Activer ce jour'),
      on_click: () => on_click(day, intervals, 'enabled'),
    },
    {
      label: french(`Désactiver ce jour`),
      on_click: () => on_click(day, intervals, 'paused'),
    },
    {
      label: french('Matin uniquement'),
      on_click: () => {
        on_click(day, morning_intervals, 'enabled')
        on_click(day, afternoon_intervals, 'paused')
      },
    },
    {
      label: french(`Après-midi uniquement`),
      on_click: () => {
        on_click(day, morning_intervals, 'paused')
        on_click(day, afternoon_intervals, 'enabled')
      },
    },
    {
      label: french(`Uniquement après 18h`),
      on_click: () => {
        on_click(day, intervals, 'paused')
        on_click(day, intervals.filter(({ start_hour }) => start_hour >= 18), 'enabled')
      },
    },
  ]

  return <Item className={'mb-2'}>
    <div className="row">
      <div className="col-12 col-lg-8">
        <div className="row">
          <div className="col-12">
            <span
              className="font-weight-bold mr-3">{to_upper_first(moment().startOf('week').add(day - 1, 'days').format('dddd'))}</span>
          </div>
          <div className="col-12">
            {intervals
              .map(({ start_hour, end_hour, state }, i) => <TimeSlot key={i} on_click={on_click} start_hour={start_hour}
                                                                     end_hour={end_hour} state={state} day={day}/>)}
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4">

        <div className="row">
          <div className="col-12">
            {shortcuts.map(({
                              label,
                              on_click,
                            }) => {
              return <Button className={'btn-sm mr-2'} outline={true}
                             onClick={() => on_click()}>
                {label}
              </Button>
            })}

          </div>
          <div className="col-12">
            <Button className={'btn-sm'} onClick={() => apply_intervals_to_every_days(intervals)}><FiCopy/> {french(
              `Appliquer à tous les autres jours`,
            )}</Button>
          </div>
        </div>
      </div>
    </div>
  </Item>
}

