import React from 'react'

export default function Grid({ width, height, scale, cols, lines, show_more_lines}) {

  const vertical_lines = []
  for (let i = 1; i < cols ; i++) {
    vertical_lines.push(i * width / cols)
  }
  const horizontal_lines = []
  for (let i = 1; i < lines ; i++) {
    horizontal_lines.push(i * width / lines)
  }

  const smaller_vertical_lines = []
  for (let i = 1; i < cols*3 ; i++) {
    smaller_vertical_lines.push(i * width / (cols * 3))
  }
  const smaller_horizontal_lines = []
  for (let i = 1; i < lines*3 ; i++) {
    smaller_horizontal_lines.push(i * width / (lines*3))
  }


  return (
    <div className="position-absolute"
         style={{ top: 0, left: 0, width, height, pointerEvents: 'none' }}>

      {vertical_lines.map((left) => {
        return <div className="position-absolute"
                    style={{ height: '100%', width: '1px', background: 'rgba(255,0,0,0.8)', left, top: 0 }}></div>
      })}
      {horizontal_lines.map((top) => {
        return <div className="position-absolute"
                    style={{ width: '100%', height: '1px', background: 'rgba(255,0,0,0.8)', top, left: 0 }}></div>
      })}
      {smaller_vertical_lines.map((left) => {
        return <div className="position-absolute"
                    style={{ height: '100%', width: '1px', background: 'rgba(255,0,0,0.1)', left, top: 0 }}></div>
      })}
      {smaller_horizontal_lines.map((top) => {
        return <div className="position-absolute"
                    style={{ width: '100%', height: '1px', background: 'rgba(255,0,0,0.1)', top, left: 0 }}></div>
      })}
    </div>
  )
}

export function RatioGrid({ width, height, ratio }) {


  const ratio_height = height / ratio
  const horizontal_lines = [
    (height - ratio_height)/2,
    (height - ratio_height)/2 + ratio_height
  ]


  return (
    <div className="position-absolute"
         style={{ top: 0, left: 0, width, height, pointerEvents: 'none' }}>

      {horizontal_lines.map((top) => {
        return <div className="position-absolute"
                    style={{ width: '100%', height: '1px', background: 'rgba(0,86,0,0.8)', top, left: 0 }}></div>
      })}
    </div>
  )
}
