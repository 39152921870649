import React from 'react'
import Item from '../../components/generic/item'
import CopyButton from '../../components/generic/copy-button'
import ExternalLinkButton from '../../components/generic/external-link-button'
import { useFrench, useTranslate } from '../../context/lang'
import useMarketPlace, { MarketPlaceDetails, useAmazonUrls, useMarketPlaceDetails } from '../../hooks/useMarketPlace'
import CrispButton from '../../components/generic/crisp_button'
import NavigationButton from '../../components/generic/navigation-button'
import ExternalLink from '../../components/generic/external-link'

export const ProblemSolver = ({ product }) => {
  const translate = useTranslate()
  const french = useFrench()

  const market_place = useMarketPlace()
  const market_place_details = useMarketPlaceDetails(market_place)
  const { asin, sku, variations } = product

  const has_variations = variations && variations.length > 0

  let asins = `ASIN: ${asin}`
  let skus = `ASIN: ${sku}`

  if (has_variations) {
    asins = `PARENT ASIN: ${asin}
VARIATIONS ASIN:
${variations.map(({ asin }) => `\t${asin}`).join('\n')}`

    skus = `PARENT SKU: ${sku}
VARIATIONS SKU:
${variations.map(({ sku }) => `\t${sku}`).join('\n')}`
  }


  return <>
    <div className="row">
      <div className="col-12 mb-3">
        {translate({
          fr: `Gagne du temps lorsque tu crées un cas sur le Support Amazon du Seller Central.`,
          en: `Save some time when you need to create a case in the Seller Central support.`,
        })}
      </div>
      <div className="col-12 mb-3">
        <ExternalLinkButton to={`${market_place_details.seller_central_url}/help/hub`} outline={true}>
          {french(`Centre d'aide du Amazon Seller Central`)}
        </ExternalLinkButton>
        <ExternalLinkButton
          to={`${market_place_details.seller_central_url}/cu/case-lobby?tab=all_cases&sortFilter=creationDate&sortByAscOrDesc=DESC&size=10&searchText=${product.asin}`}
          className="ml-3" outline={true}>
          {french(`Journal de cas pour l'ASIN`)} {product.asin}
        </ExternalLinkButton>
      </div>
    </div>
    <div className="row">
      <div className="col-6">
        <Item className={'mb-2'}>
          <CopyButton className="mb-2 float-right" text_to_copy={asins}>{translate({
            fr: `Copier les ASIN`,
            en: `Copy ASINs`,
          })}</CopyButton>
          <code
            dangerouslySetInnerHTML={{ __html: asins.replace(/\n/g, '<br/>').replace(/\t/g, '<span class="pr-3"></span>') }}>
          </code>
        </Item>
      </div>
      <div className="col-6">
        <Item className={'mb-2'}>
          <CopyButton className="mb-2 float-right" text_to_copy={skus}>{translate({
            fr: `Copier les SKU`,
            en: `Copy SKUs`,
          })}</CopyButton>
          <code
            dangerouslySetInnerHTML={{ __html: skus.replace(/\n/g, '<br/>').replace(/\t/g, '<span class="pr-3"></span>') }}>
          </code>
        </Item>
      </div>
    </div>
  </>
}


export const ProductHelp = ({ product }) => {
  const french = useFrench()
  const market_place = useMarketPlace()
  const amazon_urls = useAmazonUrls()
  const market_place_details = useMarketPlaceDetails(market_place)

  const help_items = [
    {
      title: french(`Mon produit ne se met pas à jour`),
      intro: <>
        <p>{french(`Quand tu publies le produit depuis Intellifox, les modifications sont transmises automatiquement à Amazon.`)}</p>

        <p>{french(`Comme quand tu modifies le produit depuis le Seller Central, il y a toujours un délai avant que les modifications soient prises en compte sur la fiche produit que les clients voient.`)}</p>

        <p>{french(`Cela peut prendre entre 15 minutes et 72h.`)}</p>
        <p>{french(`Il y a tout de même certains cas où un autre problème peut se cacher. Voici quelques explications possibles :`)}</p>
      </>,
      possible_explanations: [
        {
          title: french(`Est-ce que l’autorisation d’Intellifox est toujours valide sur le Seller Central ?`),
          details: <><ExternalLink
            to={`${market_place_details.seller_central_url}/apps/manage`}>{french(`Vérifie l'état du lien avec Intellifox sur le Seller Central`)}
            <MarketPlaceDetails market_place={market_place}/></ExternalLink></>,
        },
        {
          title: french(`Est-ce que la publication s'est déroulée sans soucis ?`),
          details: <p>
            {french(`Il existe parfois des problèmes dans les données transférées à Amazon. Si c'est le cas, un email d'avertissement est envoyé. Il y a alors un message qui explique la source du problème. Si après la lecture de l'avertissement, tu n'arrives pas à débloquer le problème, tu peux répondre à l'email ou me contacter au travers du chat. Une erreur courante est que le sku et/ou l'asin diffèrent sur Intellifox et sur Amazon.`)}
          </p>,
        },
        {
          title: french(`Est-ce que ça fait moins de 72 heures que tu as publié les changements ?`),
          details:
            <p>{french(`Amazon peut parfois mettre jusqu'à 72 heures pour mettre à jour une fiche produit. La meilleure solution est parfois... d'attendre ! Le délai du côté d'Amazon est variable. Cela dépend en général de l'état du produit sur Amazon (inactif, actif, supprimé, ...). Et tous les changements ne sont pas pris en compte au même rythme. Par exemple les prix sont mis à jour quasiment instantanément alors que les textes et les images prendront toujours plus de temps.`)}</p>,
        },
        {
          title: french(`Est-ce que les modifications sont visibles dans le Seller Central ?`),
          details: <>
            <p>{french(`Quand Intellifox publie tes modifications, c'est comme si elles étaient entrées pour toi dans le Seller Central, puis enregistrées. Si la publication s'est déroulée sans soucis, alors elles devraient être toutes visibles dans le Seller Central.`)}</p>
            <p>
              {french(`Pour vérifier cela, tu peux simplement cliquer ici :`)} <ExternalLink
              to={amazon_urls[ market_place ].edit(product.product_info.asin_override || product.asin, product.product_info.sku_override || product.sku)}>{french(`Voir le produit sur le Seller Central`)}
              <MarketPlaceDetails market_place={market_place}/></ExternalLink>
            </p>
          </>,

        },
        {
          title: french(`Est-ce que tu arrives à faire une modification depuis le Seller Central ?`),
          details: <>
            <p>{french(`Dans le cas où aucune des étapes précédentes n'a apporté une solution à ton problème, essaye de faire une modification depuis le Seller Central. Il y a parfois des éléments qui bloquent les mises à jour.`)}</p>
            <p>{french(`Pour vérifier si tout est en ordre, modifie le produit sur le Seller Central et vérifie que tout se passe bien. Si c'est le cas, alors tout devrait fonctionner correctement depuis Intellifox.`)}</p>

            <p><ExternalLink
              to={amazon_urls[ market_place ].edit(product.product_info.asin_override || product.asin, product.product_info.sku_override || product.sku)}>{french(`Modifier le produit sur le Seller Central`)}
              <MarketPlaceDetails market_place={market_place}/></ExternalLink></p>
          </>,
        },
        {
          title: french(`Est-ce que tu as du stock ?`),
          details: <p>
            {french(`Amazon est très pénible parfois au démarrage d'un produit, notamment lorsqu'il n'y a pas de stock encore : les modifications ne sont alors pas visibles... Mais ça se résout dès qu'on a du stock. Je te suggère de passer le produit temporairement en FBM afin de vérifier si les changements s'effectuent correctement.`)}
          </p>,
        },
      ],
    },
  ]

  return <div className="row">
    <div className="col-12">
      {help_items.map(({ title, intro, possible_explanations }, i) => {
        return <div className="row" key={i}>
          <div className="col-12">
            <Item>
              <div className="col-12">
                <h3>{french(`Problème :`)} {title}</h3>
              </div>
              <div className="col-12">
                <hr/>
              </div>
              <div className="col-12">
                {intro}
                <hr/>
              </div>
              <div className="col-12 font-weight-bold">
                <h4>{french(`Solutions possibles`)}</h4>
              </div>
              <div className="col-12">
                {possible_explanations.map(({ title, details }, i) => {
                  return <div className="row">
                    <div className="col-12">
                      <hr/>
                    </div>
                    <div className="col-12">
                      <h5>{i + 1}. {title}</h5>
                    </div>
                    <div className="col-12">
                      {details}
                    </div>
                  </div>
                })}
              </div>
            </Item>
          </div>
          <div className="col-12">
            <hr/>
          </div>
        </div>
      })}
    </div>
    <div className="col-12">
      <h3>{french(`Aucune solution trouvée ?`)}</h3>
      <CrispButton>{french(`Contacter le support d'Intellifox`)}</CrispButton>
    </div>
  </div>
}
