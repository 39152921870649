import React from 'react'
import IntellifoxModal from '../../components/generic/modal'
import { ConfirmLaunch, LaunchSteps, SelectProduct } from './magic_button'
import { await_mutate } from '../../graphql/mutate_promise'
import useCompanyId from '../../hooks/useCompanyId'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { useMutation } from '@apollo/client'
import { UPDATE_PRODUCT } from '../../graphql/queries/product'
import { useAddVariation } from './edit_product_global_properties'
import { fetch_product } from '../../graphql/fetcher/product'
import { useFrench, useTranslate } from '../../context/lang'

export default function MergeProducts({ selected_product_ids, refresh_product }) {
  const company_id = useCompanyId()
  const translate = useTranslate()
  const french = useFrench()

  const source_market_place = useSourceMarketPlace()

  const [selected_product, set_selected_product] = React.useState(null)

  const [launch_it, set_launch_it] = React.useState(false)

  const [update_product] = useMutation(UPDATE_PRODUCT)

  const add_variation = useAddVariation({ market_place: source_market_place })

  const steps = [
    {
      label: translate({
        fr: '1. Récupération des données du produit parent',
        en: '1. Fetch parent product data',
      }),
      action: async () => {
        return fetch_product({ company_id, product_id: selected_product, market_place: source_market_place })
      },
    },
    {
      label: translate({
        fr: '2. Récupération des données des produits sélectionnés',
        en: '2. Fetch selected products data',
      }),
      action: async () => {
        return Promise.all(
          selected_product_ids.map((product_id) => fetch_product({
              company_id,
              product_id,
              market_place: source_market_place,
            }),
          ),
        )
      },
    },
    {
      label: translate({
        fr: '3. Création des variations',
        en: '3. Create variations',
      }),
      action: async ([parent_product, variations]) => {
        for (let i = 0; i < variations.length; i++) {
          const variation = variations[ i ]
          await add_variation({
            sku: variation.sku,
            asin: variation.asin,
            variation: variation.product_info.variation,
            parent_product,
          })
        }
      },
    },
    {
      label: translate({
        fr: '4. Nettoyage des produits',
        en: '4. Cleanup',
      }),
      action: async ([selected_product]) => {
        for (let i = 0; i < selected_product_ids.length; i++) {
          const product_id = selected_product_ids[ i ]

          await await_mutate(update_product, {
            variables: {
              company_id,
              product_id,

              state: 'deleted',
            },
          })

        }
      },
    },
    {
      label: translate({
        fr: `5. Mise à jour de l'interface`,
        en: `5. Update`,
      }),
      action: async ([selected_product]) => {
        await refresh_product()
      },
    },
  ]


  return (
    <>
      <IntellifoxModal title={translate({
        fr: `Rattacher en tant que variation à un produit existant`,
        en: `Merge product as variation of an existing product`,
      })}
                       body={<>
                         <div className="row">

                           <div className="col-12">
                             <SelectProduct current_product_ids={selected_product_ids}
                                            selected_product={selected_product}
                                            set_selected_product={set_selected_product}/>
                           </div>
                         </div>
                       </>}
                       on_click={() => set_launch_it(true)}
                       action_label={translate({
                         fr: 'Lancer la fusion',
                         en: 'Merge',
                       })}
      >
        {translate({
          fr: `Rattacher en tant que variation`,
          en: `Merge as a variation`,
        })}
      </IntellifoxModal>
      {launch_it ? <ConfirmLaunch
        destroy={() => set_launch_it(false)}
        warning={<>
          <div className="row">
            <div className="col-12">
              {french(`Attention ! Les produits sélectionnés seront transferés en tant que variation du produit parent
              sélectionné.`)}
            </div>
            <div className="col-12">
              <ul>
                <li>{french(`Leurs titres, points forts, images, description, prix, ne seront PAS conservées`)}</li>
                <li>
                  {french(`Les produits seront ajoutés en tant que variation sur le produit parent sélectionné`)}
                </li>
                <li>{french(`Ils hériteront alors des valeurs du produit parent`)}</li>
              </ul>
            </div>
          </div>
        </>}
        confirmation_label={translate({
          fr: `J'ai bien compris que le contenu des produits sélectionnés sera perdu`,
          en: `I understand that the content of the product will be lost`,
        })}>
        <LaunchSteps steps={steps} destroy={() => set_launch_it(false)}/>
      </ConfirmLaunch> : null}
    </>
  )
}
