export const GET_ORDERS = `
    query Orders($company_id: ID!, $from:Float, $to:Float) {
        orders(company_id: $company_id, from:$from, to:$to) {
          merchant_id
          order_id
          order_type
          market_place
          status
          creation_date
          items {
            asin
            sku
            title
            quantity
            events {
              type
              date
              quantity
              earnings {
                type, amount, currency
              }
              fees {
                type, amount, currency
              }
            }
          }
        }
    }
`
export const GET_ORDERS_WITH_ASIN = `
    query Orders($company_id: ID!, $asin:String, $market_place: String, $from:Float, $to:Float) {
        orders_with_asin(company_id: $company_id, asin:$asin, market_place:$market_place, from:$from, to:$to) {
          merchant_id
          order_id
          order_type
          market_place
          status
          creation_date
          items {
            asin
            sku
            title
            quantity
            events {
              type
              date
              quantity
              earnings {
                type, amount, currency
              }
              fees {
                type, amount, currency
              }
            }
          }
        }
    }
`

export const GET_ORDERS_WITH_COMMENT_REQUEST = `
    query OrdersWithCommentRequest($company_id: ID!, $limit: Float) {
        orders_with_comment_request(company_id: $company_id, limit:$limit) {
          merchant_id
          order_id
          order_type
          market_place
          status
          creation_date
          comment_request_date
          items {
            asin
            sku
            title
            quantity
            events {
              type
              date
              quantity
              earnings {
                type, amount, currency
              }
              fees {
                type, amount, currency
              }
            }
          }
        }
    }
`
