import React from 'react'
import Spinner from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { useFrench } from '../../context/lang'
import IntellifoxIcon from '../../images/intellifox-icon'
import { FullScreenCentered } from './centered'
import { useIsMobile } from '../navigation/left-menu'

export const Loader = ({ size = 80, label }) => {
  const french = useFrench()
  return <div className="row">
    <div className="col-12">
      <Spinner type="Oval" color="currentColor" width={size} height={size}/>
    </div>
    {label ? <div className="col-12">
      {french(label)}...
    </div> : null}
  </div>
}


export const IntellifoxLoader = () => {
  const french = useFrench()

  const is_mobile = useIsMobile()

  const [percent, set_percent] = React.useState(0)
  React.useEffect(() => {
    let current_percent = 0
    const interval = setInterval(() => {
      current_percent = current_percent + 1
      set_percent(current_percent)
      if (current_percent >= 100) {
        clearInterval(interval)
      }
    }, 20)
    return () => clearInterval(interval)
  }, [])

  const left_menu_width = is_mobile ? 0 : 264

  return <FullScreenCentered overflowY={'hidden'}>
    <div className="text-center" style={{paddingLeft: left_menu_width}}>
      <div className="display-1">
        <IntellifoxIcon/>
      </div>
      <div className="font-weight-bold">
        {percent} %
      </div>
    </div>
  </FullScreenCentered>
}
