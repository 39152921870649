import React from 'react'
import { useMutation } from '@apollo/client'
import Redirect from '../../components/generic/redirect'
import SnippetFields from './snippet-fields'
import { ADD_SNIPPET, UPDATE_SNIPPET } from '../../graphql/queries/snippet'
import useCompanyId from '../../hooks/useCompanyId'
import PageHeader from '../../components/generic/page_header'
import { useCachedContext } from '../../hooks/useCachedContext'
import { await_mutate } from '../../graphql/mutate_promise'
import IntellifoxModal from '../../components/generic/modal'
import { displayFields } from '../../components/generic/field'
import { useTranslate } from '../../context/lang'
import { MdAdd } from 'react-icons/all'

export const snippet_fields = ({ translate, code, set_code, content_fr, set_content_fr }) => {

  return [
    {
      type: 'text',
      value: code,
      setter: set_code,
      trim: true,
      label: 'Code',
      help: translate({
          fr: `Ex: GARANTIE_30J`,
          en: <>E.g: WARRANTY</>,
      }),
      placeholder: translate({
          fr: 'Entrez le nom de la variable, sans espace et sans mettre le signe pourcentage (%). Ex : GARANTIE_30J',
          en: 'Enter the name of the variable, without space and without including the % symbol. E.g: WARRANTY',
      }),
    },
    {
      type: 'textarea',
      value: content_fr,
      setter: set_content_fr,
      label: translate({
          fr: 'Contenu',
          en: 'Content',
      }),
      placeholder: translate({
          fr: 'Texte',
          en: 'Text',
      }),
      show_html: true,
      count: true,
      count_warning: 180,
      count_limit: 200,
    },
  ]
}

export default function SnippetAdd() {
  const company_id = useCompanyId()
  const snippets = useCachedContext('snippets')
  const translate = useTranslate()

  const [addSnippet] = useMutation(ADD_SNIPPET)
  const [updateSnippet] = useMutation(UPDATE_SNIPPET)

  const [code, set_code] = React.useState('')
  const [content_fr, set_content_fr] = React.useState('')

  const fields = snippet_fields({translate, code, set_code, content_fr, set_content_fr})

  const onSubmit = async () => {
    const data = await await_mutate(addSnippet, { variables: { company_id, code } })

    await await_mutate(updateSnippet, {
      variables: {
        company_id,
        snippet_id: data.addSnippet.snippet_id,
        code,
        lang: 'fr',
        content: content_fr,
      },
    })

    await snippets.refresh()
    set_code('')
    set_content_fr('')
  }

  return (
    <IntellifoxModal body={<>
      {displayFields(fields, onSubmit)}
    </>} title={translate({
        fr: 'Ajouter une variable',
        en: 'Add a variable',
    })} action_in_progress_label={translate({
        fr: 'Ajout en cours...',
        en: 'Add in progress...',
    })}
                     action_label={translate({
                         fr: 'Ajouter la variable',
                         en: 'Add the variable',
                     })} on_click={onSubmit}
    button_props={{className: 'btn-sm'}}
    >
      <MdAdd/> {translate({
        fr: 'Créer une variable',
        en: 'Create a variable',
      })}
    </IntellifoxModal>
  )
}
