import React, { useRef } from 'react'
import { useField, useHandleUpdates } from '../../hooks/useField'
import { displayFields } from '../../components/generic/field'
import UpdateButton from '../../components/generic/update-button'
import { ADD_PRODUCT_VARIATION, UPDATE_PRODUCT } from '../../graphql/queries/product'
import useCompanyId from '../../hooks/useCompanyId'
import { useMutation } from '@apollo/client'
import { clean_for_label } from '../product/text-substitution'
import useMarketPlace, { amazon_regions, useMarketPlaceDetails, useSourceMarketPlace } from '../../hooks/useMarketPlace'
import safe from '../product/safe'
import { SingleValueAutoSave } from '../../components/generic/auto-save'
import { logger } from '../../debug/log'
import { ADD_SHARED_VISUAL } from '../../graphql/queries/visual'
import { await_mutate } from '../../graphql/mutate_promise'
import HelpButton from '../../components/generic/help_button'
import { Pricing } from '../../components/pricing/Pricing'
import IntellifoxModal, { IntellifoxModalComponent } from '../../components/generic/modal'
import { TranslateModal } from './i18n/translate_product'
import TextButton from '../../components/generic/text_button'
import moment from 'moment'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import ValidatePrices from './validate_prices'
import Item from '../../components/generic/item'
import Button from '../../components/generic/button'
import { FiPlus, MdSave } from 'react-icons/all'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { DisplayVariations, ProductPresentation, VariationSeparator } from './product_header'

const log = logger('edit_properties')

const EditProperties = ({
                          useFields,
                          product,
                          is_variation,
                          on_update,
                          inherit,
                          parent_product_fields,
                          show_auto_save = true,
                          parent_product,
                        }) => {
  const company_id = useCompanyId()
  const market_place = useMarketPlace()
  const translate = useTranslate()
  const french = useFrench()

  const product_fields = useFields({ product, is_variation, parent_product_fields, lock: true })

  const has_variations = product.variations && product.variations.length > 0

  const [saved_value, set_saved_value] = React.useState(null)
  const [current_value, set_current_value] = React.useState(null)

  useHandleUpdates((field_values) => {
    log('??', field_values)
    if (saved_value === null) { // init
      set_saved_value(field_values)
    }
    set_current_value(field_values)
  }, product_fields, [])

  const [update_product, update_product_mutation] = useMutation(UPDATE_PRODUCT, {
    variables: {
      company_id,
    },
  })


  const latest_update_id = useRef(0)
  const submit = React.useCallback((on_save) => {
    log('Submit !! ', current_value)
    latest_update_id.current = latest_update_id.current + 1
    const save_id = latest_update_id.current
    const value_to_be_saved = current_value
    update_product({
      variables: {
        product_id: product.product_id,
        market_place,
        ...current_value,
      },
      update: () => {
        if (latest_update_id.current === save_id) {
          // refresh product for the rest of the world
          set_saved_value(value_to_be_saved)
          product.refresh && product.refresh()
          on_save && on_save()
          on_update && on_update()
        }
      },
    }).catch(e => log(e))

  }, [current_value])

  return <>
    <Item>
      <div className="row">
        <div className="col-12">
          <ProductPresentation product={product} parent_product={parent_product}/>
        </div>
        <div className="col-12 text-right">
          {show_auto_save ? <SingleValueAutoSave saved_value={saved_value} current_value={current_value} save={submit}
                                                 button={({
                                                            save_in_progress,
                                                            has_unsaved_updates,
                                                            auto_save,
                                                            do_save,
                                                            label,
                                                          }) => {
                                                   return <div className={'ml-5'}><><i>{label}</i></>
                                                   </div>
                                                 }
                                                 }/> : null}
        </div>
      </div>
      {displayFields(product_fields, submit)}
    </Item>

    {!show_auto_save ? <div className="row">
      <div className="col-12 text-right mt-2">
        <UpdateButtonV2 trigger={async () => {
          await await_mutate(update_product, {
            variables: {
              product_id: product.product_id,
              market_place,
              ...current_value,
            },
          })
          await product.refresh()

        }}>
          <MdSave/> {french(`Sauvegarder`)}
        </UpdateButtonV2>
      </div>
    </div> : null}

    {!is_variation ? <DisplayVariations product={product} inherit={inherit}
                                        variation_mapper={(variation, parent_product) => {
                                          return <EditProperties useFields={useFields} product={variation}
                                                                 is_variation={true}
                                                                 on_update={() => parent_product.refresh()}
                                                                 parent_product_fields={product_fields}
                                                                 parent_product={parent_product}
                                                                 show_auto_save={show_auto_save}/>
                                        }}/> : null}
  </>
}
export const useSkuAsinFnsku = ({ product, is_variation, lock = false }) => {

  const market_place = useMarketPlace()
  const translate = useTranslate()
  const french = useFrench()
  const lang = useLang()


  if (!product) {
    product = {}
  }


  const sku = useField({
    key: 'sku',
    type: 'text',
    value: product.sku,
    refresh_if_this_changes: product.product_id,
    label: is_variation ? translate({
      fr: 'SKU DE LA VARIATION',
      en: 'VARIATION SKU',
    }) : translate({
      fr: 'SKU du produit',
      en: 'Product SKU',
    }),
    placeholder: translate({
      fr: 'SKU (ex: FV_PRO_CHOCOLAT)',
      en: 'SKU (e.g.: FV_PRO_CHOCOLATE)',
    }),
    col: '3',
    need_to_be_filled: true,
    // lock,
    copy_button: true,
  })

  const asin = useField({
    key: 'asin',
    type: 'text',
    value: product.asin,
    refresh_if_this_changes: product.product_id,
    label: is_variation ? translate({
      fr: 'ASIN DE LA VARIATION',
      en: 'VARIATION ASIN',
    }) : translate({
      fr: 'ASIN du produit',
      en: 'Product ASIN',
    }),
    placeholder: translate({
      fr: 'ASIN (ex: B081FXSTG3)',
      en: 'ASIN (e.g.: B081FXSTG3)',
    }),
    col: '3',
    need_to_be_filled: true,
    // lock,
    copy_button: true,
  })

  // const fnsku = useField({
  //   key: 'fnsku',
  //   type: 'text',
  //   value: product.fnsku,
  //   refresh_if_this_changes: product.product_id,
  //   label: is_variation ? 'FNSKU DE LA VARIATION' : 'FNSKU du produit',
  //   placeholder: 'FNSKU (ex: X0013X6ACN)',
  //   col: '3',
  //   need_to_be_filled: true,
  // })

  const sku_override = useField({
    key: 'sku_override',
    type: 'text',
    value: safe(product, 'product_info', 'sku_override'),
    refresh_if_this_changes: product.product_id,
    label: <>{french(`(optionnel)`)} SKU Amazon {market_place.toUpperCase()}</>,
    placeholder: translate({
      fr: 'SKU (ex: FV_PRO_CHOCOLAT)',
      en: 'SKU (e.g.: FV_PRO_CHOCOLATE)',
    }),
    col: '3',
    need_to_be_filled: false,
    // lock,
    copy_button: true,
  })
  const asin_override = useField({
    key: 'asin_override',
    type: 'text',
    value: safe(product, 'product_info', 'asin_override'),
    refresh_if_this_changes: product.product_id,
    label: <>{french(`(optionnel)`)} ASIN Amazon {market_place.toUpperCase()}</>,
    placeholder: translate({
      fr: 'ASIN (ex: B081FXSTG3)',
      en: 'ASIN (e.g.: B081FXSTG3)',
    }),
    col: '3',
    need_to_be_filled: false,
    // lock,
    copy_button: true,
  })

  const variation = useField({
    key: 'variation',
    type: 'textarea',
    value: safe(product, 'product_info', 'variation'),
    refresh_if_this_changes: product.product_id,
    label: <>
      {french(`Nom de la variation`)}
      {lang === 'fr' ? <HelpButton slug="variables-dans-les-textes-a-quoi-ca-sert-q2g12n"/> :
        <HelpButton slug="how-to-use-variables-inside-your-texts-jt95t7" lang_override={'en'}/>
      }
    </>
    ,
    placeholder: translate({
      fr: 'Entrez le nom de la variation',
      en: 'Enter the name of the variation',
    }),
    col: '6',
    count: true,
    count_max: 50,
    need_to_be_filled: is_variation,
  })
  variation.help =
    <TranslateModal product={product} access_value={(product) => safe(product, 'product_info', 'variation')}
                    setter={variation.setter}/>

  return [sku, asin, variation, sku_override, asin_override]
}
const useDimensions = ({ product, is_variation, parent_product_fields }) => {
  const translate = useTranslate()

  const packaging_length = useField({
    key: 'packaging_length',
    type: 'text',
    trim: true,
    value: product.packaging_length,
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Longueur en cm',
      en: 'Length in CM',
    }),
    placeholder: translate({
      fr: `Entrez la longueur du packaging (ex: 15.1)`,
      en: `Enter the length of the packaging (ex: 15.1)`,
    }),
    col: '3',
    need_to_be_filled: !is_variation,
  })
  const packaging_width = useField({
    key: 'packaging_width',
    type: 'text',
    trim: true,
    value: product.packaging_width,
    parent_value: parent_product_fields ? parent_product_fields[ 1 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Largeur en cm',
      en: 'Width in CM',
    }),
    placeholder: translate({
      fr: 'Entrez la largeur du packaging (ex: 20.4)',
      en: 'Enter the width of the packaging (ex: 20.4)',
    }),
    col: '3',
    need_to_be_filled: !is_variation,
  })
  const packaging_height = useField({
    key: 'packaging_height',
    type: 'text',
    trim: true,
    value: product.packaging_height,
    parent_value: parent_product_fields ? parent_product_fields[ 2 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Hauteur en cm',
      en: 'Height in CM',
    }),
    placeholder: translate({
      fr: 'Entrez la hauteur du packaging (ex: 10.4)',
      en: 'Enter the height of the packaging (ex: 10.4)',
    }),
    col: '3',
    need_to_be_filled: !is_variation,
  })

  const packaging_weight = useField({
    key: 'packaging_weight',
    type: 'text',
    trim: true,
    value: product.packaging_weight,
    parent_value: parent_product_fields ? parent_product_fields[ 3 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Poids en grammes',
      en: 'Weight in grams',
    }),
    placeholder: translate({
      fr: 'Entrez le poids du packaging (ex: 105.20)',
      en: 'Enter the weight of the packaging (ex: 105.20)',
    }),
    col: '3',
    need_to_be_filled: !is_variation,
  })

  return [packaging_length, packaging_width, packaging_height, packaging_weight]
}

const useGiftProperties = ({ product, is_variation, parent_product_fields }) => {
  const translate = useTranslate()

  const is_gift_wrap_available = useField({
    key: 'is_gift_wrap_available',
    type: 'checkbox',
    value: product.is_gift_wrap_available,
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Emballage cadeau disponible',
      en: 'Gift wrap available',
    }),
    col: '12',
  })
  const is_gift_message_available = useField({
    key: 'is_gift_message_available',
    type: 'checkbox',
    value: product.is_gift_message_available,
    parent_value: parent_product_fields ? parent_product_fields[ 1 ].value : null,
    refresh_if_this_changes: product.product_id,
    label: translate({
      fr: 'Message cadeau disponible',
      en: 'Gift message available',
    }),
    col: '12',
  })

  return [
    is_gift_wrap_available,
    is_gift_message_available,
  ]
}

export const usePrices = ({ product, is_variation, parent_product_fields }) => {
  const market_place = useMarketPlace()
  const market_place_currency = useMarketPlaceDetails(market_place).currency_symbol

  const translate = useTranslate()

  if (!product) {
    product = {}
  }


  const standard_price = useField({
    key: 'standard_price',
    value: safe(product, 'product_info', 'standard_price'),
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'number',
    label: translate({
      fr: `Prix normal en ${market_place_currency}`,
      en: `Standard price in ${market_place_currency}`,
    }),
    placeholder: translate({
      fr: `Prix normal en ${market_place_currency} (ex: 29.99)`,
      en: `Standard price in ${market_place_currency} (e.g.: 29.99)`,
    }),
    presets: (setter) => <>
      {safe(product, 'product_info', 'msrp') ? <TextButton className={'btn-sm mr-1'} onClick={() => {
        setter(safe(product, 'product_info', 'msrp'))
        sale_from_date.setter(moment().add(-2, 'day').toJSON())
        sale_end_date.setter(moment().add(-1, 'day').toJSON())
      }}>
        {translate({
          fr: `Vendre au prix suggéré`,
          en: `Sell at MSRP`,
        })}
      </TextButton> : null}
    </>,
    validate: (value) => {
      const parent_value = parent_product_fields ? parent_product_fields[ 0 ].value : null
      return parent_value || ( !!value && value > safe(product, 'product_info', 'sale_price') )
    },
    col: '3',
    need_to_be_filled: !is_variation,
  })
  const sale_price = useField({
    key: 'sale_price',
    value: safe(product, 'product_info', 'sale_price'),
    parent_value: parent_product_fields ? parent_product_fields[ 1 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'number',
    label: translate({
      fr: `Prix réduit en ${market_place_currency}`,
      en: `Sale price in ${market_place_currency}`,
    }),
    placeholder: translate({
      fr: `Prix réduit en ${market_place_currency} (ex: 19.99)`,
      en: `Sale price in ${market_place_currency} (e.g.: 19.99)`,
    }),
    validate: (value) => {
      const parent_value = parent_product_fields ? parent_product_fields[ 1 ].value : null
      return parent_value || value < safe(product, 'product_info', 'standard_price')
    },
    col: '3',
    presets: (setter) => {
      const standard_price = safe(product, 'product_info', 'standard_price') || ( parent_product_fields ? parent_product_fields[ 0 ].value : 0 )
      const reduce = (percentage) => {
        return <TextButton className={`btn-sm mr-1`}
                           onClick={() => {
                             return setter(parseFloat(
                               ( standard_price * ( 1 - percentage ) ).toFixed(2),
                             ))
                           }}>-{percentage * 100}%</TextButton>
      }
      return <>
        {reduce(0.1)}
        {reduce(0.15)}
        {reduce(0.20)}
        {reduce(0.25)}
        {reduce(0.30)}
        {reduce(0.35)}
        {reduce(0.40)}
        {reduce(0.50)}
      </>
    },
  })
  const sale_from_date = useField({
    key: 'sale_from_date',
    value: safe(product, 'product_info', 'sale_from_date'),
    parent_value: parent_product_fields ? parent_product_fields[ 2 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'date',
    label: translate({
      fr: 'Date début prix réduit',
      en: 'Sale from date',
    }),
    placeholder: translate({
      fr: 'Choisir une date de début de prix réduit',
      en: 'Pick a date for the start of the sale',
    }),
    validate: (value) => {
      const end_date = safe(product, 'product_info', 'sale_end_date')
      return !end_date || moment(value).isBefore(end_date)
    },
    col: '3',
    presets: (setter) => <>
      <TextButton className={`btn-sm`}
                  onClick={() => setter(moment().add(-1, 'week').toJSON())}>{translate({
        fr: `Activer le prix réduit dès maintenant`,
        en: `Enable the sale right now`,
      })}</TextButton>
    </>,
  })

  const sale_end_date = useField({
    key: 'sale_end_date',
    value: safe(product, 'product_info', 'sale_end_date'),
    parent_value: parent_product_fields ? parent_product_fields[ 3 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'date',
    label: translate({
      fr: 'Date fin prix réduit',
      en: 'Sale end date',
    }),
    placeholder: translate({
      fr: 'Choisir une date de fin de prix réuit',
      en: 'Pick a date for the end of the sale',
    }),
    validate: (value) => {
      const parent_value = parent_product_fields ? parent_product_fields[ 3 ].value : null
      const start_date = safe(product, 'product_info', 'sale_from_date')
      return parent_value || moment(value).isAfter(moment(start_date))
    },
    col: '3',
    presets: (setter) => <>
      <TextButton className={'btn-sm mr-1'} onClick={() => {
        sale_from_date.setter(moment().add(-2, 'day').toJSON())
        setter(moment().add(-1, 'day').toJSON())
      }}>
        {translate({
          fr: `Désactiver`,
          en: `Deactivate`,
        })}
      </TextButton>
      <TextButton className={'btn-sm mr-1'} onClick={() => setter(moment().add(1, 'week').toJSON())}>
        {translate({
          fr: `Une semaine`,
          en: `One week`,
        })}
      </TextButton>
      <TextButton className={'btn-sm mr-1'} onClick={() => setter(moment().add(1, 'month').toJSON())}>
        {translate({
          fr: `Un mois`,
          en: `One month`,
        })}
      </TextButton>
      <TextButton className={'btn-sm mr-1'} onClick={() => setter(moment().add(3, 'month').toJSON())}>
        {translate({
          fr: `Trois mois`,
          en: `Three months`,
        })}
      </TextButton>
      <TextButton className={'btn-sm mr-1'} onClick={() => setter(moment().add(1, 'year').toJSON())}>
        {translate({
          fr: `Un an`,
          en: `One year`,
        })}
      </TextButton>
      <TextButton className={'btn-sm mr-1'} onClick={() => setter(moment().add(5, 'year').toJSON())}>
        {translate({
          fr: `Cinq ans`,
          en: `Five years`,
        })}
      </TextButton>
    </>,
  })


  const msrp = useField({
    key: 'msrp',
    value: safe(product, 'product_info', 'msrp'),
    parent_value: parent_product_fields ? parent_product_fields[ 4 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'number',
    label: translate({
      fr: `Prix de vente suggéré par le fabricant en ${market_place_currency}`,
      en: `Recommended retail price in ${market_place_currency}`,
    }),
    placeholder: translate({
      fr: `Prix de vente suggéré par le fabricant en ${market_place_currency} (ex: 29.99)`,
      en: `Recommended retail price in ${market_place_currency} (e.g.: 29.99)`,
    }),
    col: '3',
    need_to_be_filled: !is_variation,
  })

  const min_price = useField({
    key: 'min_price',
    value: safe(product, 'product_info', 'min_price'),
    parent_value: parent_product_fields ? parent_product_fields[ 5 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'number',
    label: translate({
      fr: `Prix minimum en ${market_place_currency}`,
      en: `Minimum price in ${market_place_currency}`,
    }),
    placeholder: translate({
      fr: `Prix minimum en ${market_place_currency} (ex: 29.99)`,
      en: `Minimum price in ${market_place_currency} (e.g.: 29.99)`,
    }),
    col: '3',
  })
  const max_price = useField({
    key: 'max_price',
    value: safe(product, 'product_info', 'max_price'),
    parent_value: parent_product_fields ? parent_product_fields[ 6 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'number',
    label: translate({
      fr: `Prix maximum en ${market_place_currency}`,
      en: `Maximum price in ${market_place_currency}`,
    }),
    placeholder: translate({
      fr: `Prix maximum en ${market_place_currency} (ex: 29.99)`,
      en: `Maximum price in ${market_place_currency} (e.g.: 29.99)`,
    }),
    col: '3',
  })

  if (!product.product_id) {
    return null
  }

  return [standard_price, sale_price, sale_from_date, sale_end_date, msrp, min_price, max_price]
}

export const useProductVat = ({ product, is_variation, parent_product_fields }) => {
  const translate = useTranslate()
  if (!product) {
    product = {}
  }

  const vat = useField({
    key: 'tva',
    value: safe(product, 'product_info', 'price', 'tva'),
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    unit: '%',
    label: safe(product, 'product_info', 'price', 'tva_label') || translate({
      fr: 'TVA appliquée sur le prix de vente',
    }),
    placeholder: translate({
      fr: `TVA appliquée sur le prix de vente (ex: 20)`,
      en: `VAT applied to the price (e.g.: 20)`,
    }),

    presets: (setter) => {
      const vat_standard_rate = (percentage) => {
        return <Button className={`btn-sm mr-1 mt-1`}
                           onClick={() => {
                             return setter(percentage)
                           }}>{percentage}%</Button>
      }

      return <>
        {vat_standard_rate("0.0")}
        {vat_standard_rate(5.5)}
        {vat_standard_rate(10)}
        {vat_standard_rate(14)}
        {vat_standard_rate(15)}
        {vat_standard_rate(19)}
        {vat_standard_rate(20)}
        {vat_standard_rate(21)}
        {vat_standard_rate(22)}
        {vat_standard_rate(23)}
        {vat_standard_rate(25)}
      </>
    },
    col: '6',
    need_to_be_filled: true,
  })


  if (!product.product_id) {
    return null
  }

  return [vat]
}

export const useProductPriceConfig = ({ product, is_variation, parent_product_fields }) => {
  const translate = useTranslate()

  if (!product) {
    product = {}
  }

  const achat = useField({
    key: 'achat',
    value: safe(product, 'product_info', 'price', 'achat'),
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'achat_label') ||  translate({
      fr: `Coûts pour une unité`,
      en: 'Cost for one unit',
    }),
    placeholder: translate({
      fr: `Coût pour la production d'une unité (ex: 8.82)` ,
      en: `Cost for the production of one unit (e.g.: 8.82)`,
    }),

    col: '3',
    need_to_be_filled: true,
  })

  const cost_1 = useField({
    key: 'cost_1',
    value: safe(product, 'product_info', 'price', 'cost_1'),
    parent_value: parent_product_fields ? parent_product_fields[ 1 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_1_label') || translate({
      fr: 'Frais A',
      en: 'Fee A',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const cost_2 = useField({
    key: 'cost_2',
    value: safe(product, 'product_info', 'price', 'cost_2'),
    parent_value: parent_product_fields ? parent_product_fields[ 2 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_2_label') || translate({
      fr: 'Frais B',
      en: 'Fee B',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const cost_3 = useField({
    key: 'cost_3',
    value: safe(product, 'product_info', 'price', 'cost_3'),
    parent_value: parent_product_fields ? parent_product_fields[ 3 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_3_label') || translate({
      fr: 'Frais C',
      en: 'Fee C',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const cost_4 = useField({
    key: 'cost_4',
    value: safe(product, 'product_info', 'price', 'cost_4'),
    parent_value: parent_product_fields ? parent_product_fields[ 4 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_4_label') || translate({
      fr: 'Frais D',
      en: 'Fee D',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const cost_5 = useField({
    key: 'cost_5',
    value: safe(product, 'product_info', 'price', 'cost_5'),
    parent_value: parent_product_fields ? parent_product_fields[ 5 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_5_label') || translate({
      fr: 'Frais E',
      en: 'Fee E',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const cost_6 = useField({
    key: 'cost_6',
    value: safe(product, 'product_info', 'price', 'cost_6'),
    parent_value: parent_product_fields ? parent_product_fields[ 6 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'cost_6_label') || translate({
      fr: 'Frais F',
      en: 'Fee F',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const import_field = useField({
    key: 'import',
    value: safe(product, 'product_info', 'price', 'import'),
    parent_value: parent_product_fields ? parent_product_fields[ 7 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'import_label') || translate({
      fr: 'Frais envoi vers Amazon',
      en: 'Shipping fees to Amazon warehouse',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const stockage_field = useField({
    key: 'stockage',
    value: safe(product, 'product_info', 'price', 'stockage'),
    parent_value: parent_product_fields ? parent_product_fields[ 8 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'stockage_label') || translate({
      fr: 'Frais stockage',
      en: 'Storage costs',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })

  const fourniture_field = useField({
    key: 'fourniture',
    value: safe(product, 'product_info', 'price', 'fourniture'),
    parent_value: parent_product_fields ? parent_product_fields[ 9 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'fourniture_label') || translate({
      fr: 'Frais fourniture',
      en: 'Supply costs',
    }),
    placeholder: translate({
      fr: `Frais annexe`,
      en: `Additional fee`,
    }),

    col: '3',
  })


  if (!product.product_id) {
    return null
  }

  return [achat, cost_1, cost_2, cost_3, cost_4, cost_5, cost_6, import_field, stockage_field, fourniture_field]
}

export const useProductAmazonFeeConfig = ({ product, is_variation, parent_product_fields }) => {
  const translate = useTranslate()

  if (!product) {
    product = {}
  }

  const commission = useField({
    key: 'commission',
    value: safe(product, 'product_info', 'price', 'commission'),
    parent_value: parent_product_fields ? parent_product_fields[ 0 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'commission_label') ||  translate({
      fr: 'Pourcentage de commission Amazon',
      en: 'Amazon fee percentage',
    }),
    placeholder: translate({
      fr: 'Pourcentage de frais de commission Amazon HT',
      en: 'Amazon commission fee percentage Excl. VAT',
    }),

    unit: '%',
    col: '6',
    need_to_be_filled: true,
  })

  const expedition = useField({
    key: 'expedition',
    value: safe(product, 'product_info', 'price', 'expedition'),
    parent_value: parent_product_fields ? parent_product_fields[ 1 ].value : null,
    refresh_if_this_changes: product.product_id,
    type: 'text',
    label: safe(product, 'product_info', 'price', 'commission_label') ||  translate({
      fr: 'Expédition par Amazon HT (Frais FBA)',
      en: 'Fulfilment by Amazon Excl. VAT (FBA fee)',
    }),
    placeholder: translate({
      fr: 'Expédition par Amazon (Frais FBA)',
      en: 'Fulfilment by Amazon (FBA fee)',
    }),

    col: '6',
    need_to_be_filled: true,
  })


  if (!product.product_id) {
    return null
  }

  return [commission, expedition]
}



export const EditProductGlobalProperties = ({ product, show_auto_save = true }) => {
  return <>
    <EditProperties product={product} useFields={useSkuAsinFnsku} show_auto_save={show_auto_save}/>
  </>
}

export const EditDimensions = ({ product }) => {
  return <EditProperties product={product} useFields={useDimensions} inherit={true}/>
}

export const EditGiftProperties = ({ product }) => {
  return <EditProperties product={product} useFields={useGiftProperties} inherit={true}/>
}

export const EditPrices = ({ product }) => {
  return <>
    <ValidatePrices product={product}/>
    <EditProperties product={product} useFields={usePrices} inherit={true}/>
  </>
}


export const useAddVariation = ({ market_place }) => {
  const company_id = useCompanyId()
  const source_market_place = useSourceMarketPlace()

  const [update_product] = useMutation(UPDATE_PRODUCT)
  const [add_variation] = useMutation(ADD_PRODUCT_VARIATION)
  const [add_shared_visual] = useMutation(ADD_SHARED_VISUAL)

  return React.useCallback(async ({ sku, asin, variation, parent_product }) => {
      const data = await await_mutate(add_variation, {
        variables: {
          company_id,
          parent_product_id: parent_product.product_id,
          sku,
        },
      })

      const { product_id } = data.addProductVariation
      await await_mutate(update_product, {
        variables: {
          company_id,
          product_id,
          market_place,
          variation,
          asin,
        },
      })
    }, [add_shared_visual, add_variation, company_id, market_place, source_market_place, update_product],
  )
}

export const AddVariation = ({ product }) => {
  const market_place = useMarketPlace()
  const translate = useTranslate()

  const [sku, asin, variation] = useSkuAsinFnsku({
    product: { sku: '', asin: '', product_info: { variation: '' } },
    is_variation: true,
    lock: false,
  })

  const add_variation = useAddVariation({ market_place })

  const on_submit = React.useCallback(async () => {
    await add_variation({
      sku: sku.value,
      asin: asin.value,
      variation: variation.value,
      parent_product: product, // because source_product contains the information about id
    })
    await product.refresh()
  }, [add_variation, asin.value, product, sku.value, variation.value])

  return <IntellifoxModal title={<><FiPlus/> {translate({
    fr: 'Ajouter une variation',
    en: 'Add a variation',
  })}</>} action_label={<><FiPlus/> {translate({
    fr: 'Ajouter la variation',
    en: 'Add this variation',
  })}</>}
                          action_in_progress_label={translate({
                            fr: 'Ajout en cours...',
                            en: 'Add in progress...',
                          })} on_click={on_submit} body={<>
    <div className="row align-items-end">
      <div className="col-12">
        {displayFields([sku, asin, variation], on_submit)}
      </div>
      <div className="col-12 mb-3 text-right">
        {translate({
          fr: <HelpButton
            slug={'intellifoxr-permet-il-de-creer-automatiquement-des-produits-et-des-variations-sur-amazon-fsdhas'}
            className={'mr-5'}>Aide sur la création des variations</HelpButton>,
          en: null,
        })}
      </div>
    </div>
  </>}

                          button_props={{ className: 'btn-sm' }}>
    <FiPlus/> {translate({
    fr: `Ajouter une variation`,
    en: `Add a variation`,
  })}
  </IntellifoxModal>

}
