import { gql } from '@apollo/client'

export const ADD_COMPETITOR = gql`
    mutation AddCompetitor(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $asin: String!,
        $position: Float!,
    ){
        add_competitor(
            company_id: $company_id,
            product_id:$product_id,
            market_place:$market_place,
            asin:$asin,
            position:$position,
        )
    }
`

export const REMOVE_COMPETITOR = gql`
    mutation RemoveCompetitor(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $asin: String!,
    ){
        remove_competitor(
            company_id: $company_id,
            product_id:$product_id,
            market_place:$market_place,
            asin:$asin,
        )
    }
`


export const GET_COMPETITORS_ASIN = `
    query GetCompetitorsASIN($company_id: ID!, $product_id: ID!, $market_place: String) {
        product(company_id: $company_id, product_id: $product_id) {
            product_info (market_place:$market_place) {
                competitors {
                    asin
                }
            }
        }
    }
`

export const GET_COMPETITORS_DETAILS = `
    query GetCompetitorsASIN($company_id: ID!, $product_id: ID!, $market_place: String) {
        product(company_id: $company_id, product_id: $product_id) {
            product_info (market_place:$market_place) {
                competitors {
                    asin
                    title
                    url
                    image
                    price
                    rating
                    ratings_total
                    
                    description
                    feature_bullets_flat
                    key_points
                    
                    images
                }
            }
        }
    }
`

