import React from 'react'
import { OpacityDrowndown } from './opacity_dropdown'
import { ChangeOrder } from './order_dropdown'
import TextButton from '../../generic/text_button'
import { useDesignerContext } from '../designer_context'
import { apply_overrides_to_object } from '../override/apply_overrides'
import { AlignDropdown } from './align_dropdown'
import { DistributeDropdown } from './distribute_dropdown'
import { OptionSeparator } from './options'
import { useTranslate } from '../../../context/lang'
import * as _ from 'lodash'

export default function MultipleSelectionOptions({ selected_objects }) {
  const { delete_selected_objects, reinitialize_selected_objects, duplicate_object, edit_overrides, group_selected_objects, ungroup_selected_objects, are_all_selected_objects_grouped } = useDesignerContext()
  const translate = useTranslate()

  return (
    <div className="container-fluid h-100">
      <div className="row no-gutters h-100 align-items-center">
        <div className="col">
          {are_all_selected_objects_grouped() ?
            <TextButton onClick={() => ungroup_selected_objects()} disabled={edit_overrides}>{translate({
              fr: `Dégrouper`,
              en: `Ungroup`,
            })}</TextButton> :
            <TextButton onClick={() => group_selected_objects()} disabled={edit_overrides}>{translate({
              fr: `Grouper`,
              en: `Group`,
            })}</TextButton>}
        </div>
        <div className="col-auto">
          <DistributeDropdown selected_objects={selected_objects}/>
        </div>
        <OptionSeparator/>
        <div className="col-auto">
          <AlignDropdown selected_objects={selected_objects}/>
        </div>
        <OptionSeparator/>
        <div className="col-auto">
          <TextButton onClick={() => duplicate_object()} disabled={edit_overrides}>{translate({
            fr: `Dupliquer`,
            en: `Duplicate`,
          })}</TextButton>
        </div>
        <OptionSeparator/>
        <div className="col-auto">
          <TextButton onClick={() => delete_selected_objects()} disabled={edit_overrides}>{translate({
            fr: `Supprimer`,
            en: `Delete`,
          })}</TextButton>
        </div>
        {edit_overrides ? <>
          <OptionSeparator/>
          <div className="col-auto">
            <TextButton onClick={() => reinitialize_selected_objects()}>{translate({
              fr: `Réinitialiser`,
              en: `Reset`,
            })}</TextButton>
          </div>
        </> : null}
      </div>
    </div>
  )
}
