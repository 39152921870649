import React from 'react'

export default function IntellifoxIcon({ className = '', style, width = '1em', height = '1em', id}) {
  const styles = {
    fillOpacity: 1,
    strokeWidth: '0.39560777',
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
    strokeMiterlimit: 4,
    strokeDasharray: 'none',
    strokeOpacity: 1,
  }


  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 135.46666 135.46667"
      className={`${className}`}
      width={width}
      height={height}
      fill="currentColor"
      stroke="currentColor"
      style={style}
      id={id}
    >

      <path
        id="path1401"
        d="M 67.733523,21.227197 C 53.542538,25.393225 42.48908,43.349429 40.30398,51.431807 34.552378,55.298866 29.66042,61.003773 26.524468,66.36247 30.123916,66.3509 33.078412,66.494368 35.744532,68.280496 24.752748,68.808587 8.8243182,74.596876 2.3585634,86.727961 8.1709133,85.58863 11.827398,84.943567 16.929607,86.468023 14.768994,88.511698 12.12535,88.046471 8.1813186,98.317457 25.041753,89.199995 46.310123,101.08916 68.142918,109.54729 c 18.777474,7.27448 41.174362,6.661 57.870072,-5.005 10.88598,-7.916899 8.85718,-8.080382 -1.52436,-11.517619 C 95.924214,83.465507 99.85667,61.521876 88.752165,53.393953 82.57134,48.869912 76.49079,47.162805 69.885345,46.636566 78.06947,44.844999 82.019573,46.289468 86.372319,48.62274 82.777881,41.205134 79.367279,34.063239 78.905163,27.593596 c -3.995151,4.075513 -7.68769,12.850736 -9.36369,19.020949 -1.721213,-0.126113 -3.482456,-0.175092 -5.287031,-0.173936 0.547147,-7.594085 2.181941,-18.894911 3.479081,-25.213219 z m -9.471676,6.620052 C 52.716802,34.77965 48.661691,41.816103 46.46484,48.50839 l -2.489924,0.262252 C 46.649806,38.767114 53.990005,31.553911 58.261847,27.847403 Z M 78.16343,66.606712 c 5.002412,4.472978 5.003106,4.460636 10.674179,7.197738 C 78.126996,73.962187 78.071321,71.832623 78.16343,66.606712 Z"
        style={styles} />
    </svg>
  )
}


export const GiveMoneyIcon = ({ className = '', style, width = '1em', height = '1em', id}) => {
  return <svg
    viewBox="0 0 512 512"
    className={`${className}`}
    width={width}
    height={height}
    fill="currentColor"
    stroke="currentColor"
    style={style}
    id={id}>
    <defs
      id="defs8"/>
    <path
      d="m 298.9,24.31 c -14.9,0.3 -25.6,3.2 -32.7,8.4 l -97.3,52.1 -54.1,73.59 c -11.4,17.6 -3.3,51.6 32.3,29.8 l 39,-51.4 c 49.5,-42.69 150.5,-23.1 102.6,62.6 -23.5,49.6 -12.5,73.8 17.8,84 L 320.3,237 c 23.9,-53.8 68.5,-63.5 66.7,-106.9 l 107.2,7.7 -1,-112.09 z M 244.8,127.7 c -17.4,-0.3 -34.5,6.9 -46.9,17.3 l -39.1,51.4 c 10.7,8.5 21.5,3.9 32.2,-6.4 12.6,6.4 22.4,-3.5 30.4,-23.3 3.3,-13.5 8.2,-23 23.4,-39 z"
      />
  </svg>


}


export const ReceiveMoneyIcon = ({ className = '', style, width = '1em', height = '1em', id}) => {
  return <svg
    viewBox="0 0 512 512"
    className={`${className}`}
    width={width}
    height={height}
    fill="currentColor"
    stroke="currentColor"
    style={style}
    id={id}>
    <defs
      id="defs8"/>
    <path
      d="M 397.8,302.4 336,331.7 c 4.2,0.2 14.7,0.5 14.7,0.5 l 6.6,8.7 54.7,-28.5 z m -54.5,0.1 -57.4,27.2 c 5.5,0.3 18.5,0.5 23.7,0.8 l 49.8,-23.6 z m 92.6,10.8 -70.5,37.4 14.5,18.7 74.5,-44.6 z m -278.8,9.1 c -3.02803,-0.006 -6.04705,0.32986 -9,1 C 76.6,339.9 34.4,341.3 21.9,341.3 H 18 v 107.5 c 0,0 11.6,-1.7 30.9,-1.8 37.3,0 103,6.4 167,43.8 3.4,2.1 10.7,2.9 19.8,2.9 24.3,0 61.2,-5.8 69.7,-9 C 391,452.6 494,364.5 494,364.5 l -32.5,-28.4 c 0,0 -79.8,50.9 -89.9,55.8 -91.1,44.7 -164.9,16.8 -164.9,16.8 0,0 119.9,3 158.4,-27.3 l -22.6,-34 c 0,0 -82.8,-2.3 -112.3,-6.2 -15.4,-2 -48.7,-18.8 -73.1,-18.8 z"
       />
  </svg>


}
