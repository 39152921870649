import React from 'react'
import { useCompany } from '../../hooks/useCompanyId'
import { useFrench } from '../../context/lang'
import moment from 'moment'
import { FiX, IoMdWarning } from 'react-icons/all'
import NavigationButton from '../generic/navigation-button'

export const DataDeletionWarning = () => {
  const french = useFrench()

  const company = useCompany()

  if (!company || !company.data_deletion_date || window.location.pathname === '/subscription/upgrade') {
    return null
  }

  return <div className="container mt-5 pt-5 pb-5 mb-5">
    <div className="row">
      <div className="col-12">
        <div className="alert alert-danger">
          <div className="row">
            <div className="col-12 font-weight-bold">
              <h3><IoMdWarning/> {french(`Tes données Intellifox vont bientôt être supprimées`)}</h3>
            </div>
            <div className="col-12">
              <p>{french(`Sauf erreur de notre part, tu n'as plus d'abonnement actif chez Intellifox actuellement.`)}</p>
              <p>{french(`Afin de libérer de l'espace dans nos systèmes, nous allons bientôt supprimer les données suivantes dans ton compte Intellifox :`)}</p>
              <p>
                <ul>
                  <li>{french(`Tes produits et leurs visuels`)}</li>
                  <li>{french(`Tes images`)}</li>
                  <li>{french(`Ton historique de commandes`)}</li>
                  <li>{french(`Ton historique de coûts publicitaires`)}</li>
                </ul>
              </p>

            </div>

            <div className="col-12 font-weight-bold my-2">
              <div className="alert alert-success">
                {french(`Cela n'aura aucun impact sur ton compte Amazon et tes produits sur Amazon.`)}
              </div>
            </div>


            <div className="col-12">
              <div className="alert alert-warning">
                {french(`Suppression des données : `)} {moment(company.data_deletion_date).format('LL')} ({moment(company.data_deletion_date).fromNow()})
              </div>
            </div>

            <div className="col-12 mt-3">
              <NavigationButton to={'/subscription/upgrade'}><FiX/> {french(`Annuler la suppression des données`)}</NavigationButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
