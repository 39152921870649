import { gql } from '@apollo/client'

export const SELECT_KEYWORD = gql`
    mutation SelectKeyword(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $keyword: String!,
        $intensity: Float!,
        $selected: Boolean!,
        $category: String!,
    ){
        selectKeyword(
            company_id: $company_id,
            product_id:$product_id,
            market_place:$market_place,
            keyword:$keyword,
            intensity:$intensity,
            selected: $selected,
            category: $category,
        )
    }
`

export const SELECT_KEYWORDS = gql`
    mutation SelectKeywords(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $keywords: [KeywordInput],
    ){
        selectKeywords(
            company_id: $company_id,
            product_id:$product_id,
            market_place:$market_place,
            keywords:$keywords,
        )
    }
`
