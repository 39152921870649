export const set_3_cooking_spatula = [[{
  'type': 'rect',
  'object_id': '1',
  'x': 125,
  'y': 125,
  'width': 250,
  'height': 250,
  'angle': 0,
  'props': {
    'fill': 'transparent',
    'opacity': 1,
    'filters': { 'border_width': 10, 'border_color': 'C', 'border': true },
    'group_id': 'group_8bae9ys8xlr',
  },
}, {
  'type': 'rect',
  'object_id': '2',
  'x': 90,
  'y': 310,
  'width': 100,
  'height': 100,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1, 'group_id': 'group_8bae9ys8xlr' },
}, {
  'type': 'rect',
  'object_id': '3',
  'x': 310,
  'y': 90,
  'width': 100,
  'height': 100,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1, 'group_id': 'group_8bae9ys8xlr' },
}, {
  'type': 'image',
  'object_id': '4',
  'x': 0.4329004329004391,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': null,
  'props': {
    'image_id': 'SGhCphKF4',
    'filters': {
      'drop_shadow_x': -7,
      'drop_shadow_y': 10,
      'drop_shadow_spread': 10,
      'drop_shadow_color': 'rgba(0,0,0,0.5)',
      'drop_shadow': true,
    },
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'grey', 'gradient': true, 'gradient_color': 'white', 'gradient_angle': 286 },
}, {
  'type': 'rect',
  'object_id': '1',
  'x': 146.97120158887793,
  'y': 25.906157280506136,
  'width': 363.9026812313803,
  'height': 448.1876854389877,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1, 'gradient': true, 'gradient_color': 'E', 'gradient_angle': 118 },
}, {
  'type': 'image',
  'object_id': '2',
  'x': -42.005958291956375,
  'y': 46.9636860621066,
  'width': 382.2089256801078,
  'height': 411.8544032635342,
  'angle': -0.20425562390614624,
  'props': {
    'image_id': 'SGhCphKF4',
    'image_object_reverse': true,
    'filters': {
      'drop_shadow_x': 18,
      'drop_shadow_y': 36,
      'drop_shadow_spread': 7,
      'drop_shadow_color': 'rgba(0,0,0,0.56)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '3',
  'x': 286.49453823237343,
  'y': 27.060575968222395,
  'width': 215.9880834160873,
  'height': 128.63290250715698,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>SPATULE EN ACIER</p>',
    'fontSize': 39,
    'fontFamily': 'Taviraj',
    'fontWeight': 700,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 281.6683217477657,
  'y': 327.1002979145978,
  'width': 215.9880834160873,
  'height': 32.66468006425731,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>ÉCONOMISEZ</p>',
    'fontSize': 28,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 300,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 302.87984111221454,
  'y': 356.28930767101,
  'width': 174.77656405163856,
  'height': 38.23237338629593,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>20% !</p>',
    'fontSize': 37,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 500,
    'lineHeight': 32,
    'background': '#ffffff',
  },
}, {
  'type': 'text',
  'object_id': '6',
  'x': 317.27904667328704,
  'y': 398.7388282025819,
  'width': 150.44687189672294,
  'height': 32.66468006425731,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>OFFRE LIMITÉE DANS LE TEMPS</p>',
    'fontSize': 17,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 700,
    'lineHeight': 16,
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'grey', 'gradient': true, 'gradient_color': 'white', 'gradient_angle': 9 },
}, {
  'type': 'rect',
  'object_id': '1',
  'x': -193.2885906040268,
  'y': 25.906157280506136,
  'width': 522.9310930866485,
  'height': 448.1876854389877,
  'angle': 0,
  'props': {
    'fill': 'C',
    'opacity': 1,
    'group_id': 'group_2c10turdsns',
    'gradient': true,
    'gradient_color': 'E',
    'gradient_angle': 307,
  },
}, {
  'type': 'image',
  'object_id': '2',
  'x': 133.26713008937432,
  'y': 41.5019184354929,
  'width': 382.2089256801078,
  'height': 411.8544032635342,
  'angle': -0.20425562390614624,
  'props': {
    'image_id': 'SGhCphKF4',
    'image_object_reverse': true,
    'filters': {
      'drop_shadow_x': 18,
      'drop_shadow_y': 36,
      'drop_shadow_spread': 7,
      'drop_shadow_color': 'rgba(0,0,0,0.56)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '3',
  'x': 12.413108242303906,
  'y': 28.55014895729886,
  'width': 215.9880834160873,
  'height': 128.63290250715698,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>SPATULE EN ACIER</p>',
    'fontSize': 39,
    'fontFamily': 'Taviraj',
    'fontWeight': 700,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 20.000000000000014,
  'y': 148.24561352999706,
  'width': 190.0297914597815,
  'height': 270.1092353525323,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>Haute qualité</p>\n<p>Ne rouille pas</p>\n<p>Poignée durable</p>\n<p>Résiste à la chaleur</p>',
    'fontSize': 28,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'lineHeight': 22,
  },
}, {
  'type': 'rect',
  'object_id': '7',
  'x': 237.33862959285005,
  'y': 57.38166119633273,
  'width': 70,
  'height': 70,
  'angle': 0,
  'props': { 'fill': '#ffffff', 'opacity': 1 },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'grey', 'gradient': true, 'gradient_color': 'white', 'gradient_angle': 302 },
}, {
  'type': 'rect',
  'object_id': '1',
  'x': -246.64429530201346,
  'y': 19.50347567030785,
  'width': 646.6442953020135,
  'height': 222.63737084730622,
  'angle': 0,
  'props': {
    'fill': 'C',
    'opacity': 1,
    'group_id': 'group_2c10turdsns',
    'gradient': true,
    'gradient_color': 'E',
    'gradient_angle': 239,
  },
}, {
  'type': 'image',
  'object_id': '2',
  'x': 265.6771879378472,
  'y': 22.259025161906422,
  'width': 201.8173419800419,
  'height': 217.4710096095191,
  'angle': -0.20425562390614624,
  'props': {
    'image_id': 'SGhCphKF4',
    'image_object_reverse': true,
    'filters': {
      'drop_shadow_x': 18,
      'drop_shadow_y': 36,
      'drop_shadow_spread': 7,
      'drop_shadow_color': 'rgba(0,0,0,0.56)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '3',
  'x': 12.413108242303906,
  'y': 28.55014895729886,
  'width': 130.5858987090367,
  'height': 70.53955593317487,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>SPATULE EN ACIER</p>',
    'fontSize': 23,
    'fontFamily': 'Taviraj',
    'fontWeight': 700,
    'lineHeight': 20,
    'group_id': 'group_nk4lcglw9qc',
  },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 19.364448857994006,
  'y': 94.62098592324435,
  'width': 276.5640516385304,
  'height': 135.05461767626613,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>+ matériaux durables</p>\n<p>+ poignée ergonomique</p>\n<p>+ facile à nettoyer</p>\n<p>+ garantie 10 ans</p>',
    'fontSize': 20,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'lineHeight': 10,
    'group_id': 'group_nk4lcglw9qc',
  },
}, {
  'type': 'rect',
  'object_id': '5',
  'x': 100,
  'y': 257.3626291526938,
  'width': 428.0039721946375,
  'height': 222.63737084730622,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1, 'group_id': 'group_mfy66sk850q' },
}, {
  'type': 'image',
  'object_id': '6',
  'x': 16.66063207039842,
  'y': 287.03857398054384,
  'width': 159.67630182964092,
  'height': 172.06136117400814,
  'angle': 0.33987072083854075,
  'props': {
    'image_id': 'SGhCphKF4',
    'image_object_reverse': false,
    'filters': {
      'drop_shadow_x': 5,
      'drop_shadow_y': 8,
      'drop_shadow_spread': 9,
      'drop_shadow_color': 'rgba(0,0,0,0.56)',
      'drop_shadow': true,
      'contrast': true,
      'contrast_value': 0,
    },
  },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 174.41906653426025,
  'y': 276.45481628599805,
  'width': 202.4428997020853,
  'height': 70.53955593317487,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>CONCURRENTS</p>',
    'fontSize': 23,
    'fontFamily': 'Taviraj',
    'fontWeight': 500,
    'lineHeight': 20,
    'group_id': 'group_sfxms6f8c3m',
  },
}, {
  'type': 'text',
  'object_id': '8',
  'x': 181.37040714995035,
  'y': 342.5256532519435,
  'width': 276.5640516385304,
  'height': 135.05461767626613,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>⚠ poignée de mauvaise qualité</p>\n<p>⚠ forme inadaptée</p>\n<p>⚠ matériaux dangereux</p>\n<p>⚠ marque chinoise</p>',
    'fontSize': 20,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 600,
    'lineHeight': 10,
    'group_id': 'group_sfxms6f8c3m',
  },
}], [{
  'type': 'rect',
  'object_id': 'background',
  'x': 0,
  'y': 0,
  'width': 500,
  'height': 500,
  'angle': 0,
  'props': { 'fill': 'grey', 'gradient': true, 'gradient_color': 'white', 'gradient_angle': 309 },
}, {
  'type': 'rect',
  'object_id': '1',
  'x': 146.97120158887793,
  'y': 25.906157280506136,
  'width': 501.3509460621288,
  'height': 448.1876854389877,
  'angle': 0,
  'props': { 'fill': 'C', 'opacity': 1, 'gradient': true, 'gradient_color': 'E', 'gradient_angle': 135 },
}, {
  'type': 'image',
  'object_id': '2',
  'x': 8.717908594141022,
  'y': 19.37388992432068,
  'width': 280.0310424490565,
  'height': 301.7512416223619,
  'angle': 0,
  'props': {
    'image_id': 'SGhCphKF4',
    'image_object_reverse': true,
    'filters': {
      'drop_shadow_x': 18,
      'drop_shadow_y': 36,
      'drop_shadow_spread': 7,
      'drop_shadow_color': 'rgba(0,0,0,0.56)',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'text',
  'object_id': '3',
  'x': 286.49453823237343,
  'y': 27.060575968222395,
  'width': 215.9880834160873,
  'height': 128.63290250715698,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>SPATULE EN ACIER</p>',
    'fontSize': 39,
    'fontFamily': 'Taviraj',
    'fontWeight': 700,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '4',
  'x': 281.6683217477657,
  'y': 327.1002979145978,
  'width': 215.9880834160873,
  'height': 32.66468006425731,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>ÉCONOMISEZ</p>',
    'fontSize': 28,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 300,
    'lineHeight': 32,
  },
}, {
  'type': 'text',
  'object_id': '5',
  'x': 302.87984111221454,
  'y': 356.28930767101,
  'width': 174.77656405163856,
  'height': 38.23237338629593,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>20% !</p>',
    'fontSize': 37,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 500,
    'lineHeight': 32,
    'background': '#ffffff',
  },
}, {
  'type': 'text',
  'object_id': '6',
  'x': 317.27904667328704,
  'y': 398.7388282025819,
  'width': 150.44687189672294,
  'height': 32.66468006425731,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'center',
    'verticalAlign': 'baseline',
    'content': '<p>OFFRE LIMITÉE DANS LE TEMPS</p>',
    'fontSize': 17,
    'fontFamily': 'Alegreya Sans SC',
    'fontWeight': 700,
    'lineHeight': 16,
  },
}, {
  'type': 'text',
  'object_id': '7',
  'x': 294.5779543197617,
  'y': 137.92452830188674,
  'width': 148.81827209533265,
  'height': 128.63290250715698,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>Le choix du <b>CHEF</b></p>',
    'fontSize': 29,
    'fontFamily': 'Taviraj',
    'fontWeight': 500,
    'lineHeight': 32,
  },
}, {
  'type': 'rect',
  'object_id': '8',
  'x': 129.29493545183715,
  'y': 321.5326045925591,
  'width': 385.1042701092354,
  'height': 160.3442573736773,
  'angle': 0,
  'props': {
    'fill': '#ffffff',
    'opacity': 1,
    'filters': {
      'drop_shadow_x': 0,
      'drop_shadow_y': 0,
      'drop_shadow_spread': 1,
      'drop_shadow_color': 'black',
      'drop_shadow': true,
    },
  },
}, {
  'type': 'image',
  'object_id': '9',
  'x': 370.48627987010855,
  'y': 243.19117858952052,
  'width': 121.07280652512486,
  'height': 123.02861567533262,
  'angle': null,
  'props': {
    'image_id': 'pixabay:3975835',
    'filters': {
      'border_radius_option': '%',
      'border_radius': true,
      'border_radius_value': 100,
      'border_width': 9,
      'border_color': 'rgba(255,255,255,1)',
      'border': true,
    },
    'crop': { 'x': 180, 'y': 0, 'width': 1242, 'height': 1276 },
    'image_object_fit': 'contain',
    'image_object_reverse': true,
  },
}, {
  'type': 'text',
  'object_id': '10',
  'x': 145.4816285998014,
  'y': 351.82058870378063,
  'width': 357.0009930486594,
  'height': 129.78815012442197,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'left',
    'verticalAlign': 'center',
    'content': '<p>"<i>Je n\'utilise que cette marque depuis 10 ans. Pour rien au monde je ne changerais. Je la recommande à toutes mes connaissances."</i></p>\n<p><i><b>Chef C. Ducros</b></i></p>',
    'fontSize': 23,
    'fontFamily': 'Lato',
    'fontWeight': 300,
    'lineHeight': 18,
  },
}, {
  'type': 'text',
  'object_id': '11',
  'x': 141.64846077457798,
  'y': 327.62992336117884,
  'width': 241.66832174776567,
  'height': 33.128103277060575,
  'angle': 0,
  'props': {
    'fill': 'C',
    'textAlign': 'center',
    'verticalAlign': 'center',
    'content': '<p>★★★★★</p>',
    'fontSize': 33,
    'fontFamily': 'Lato',
    'fontWeight': 300,
    'lineHeight': 18,
  },
}]]
