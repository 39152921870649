import React, { useCallback, useContext, useEffect, useState } from 'react'
import { logger } from '../debug/log'

const log = logger('use_cached_context')

export const create_cached_context = () => React.createContext({
  // product cache : product_id -> product_with_full_details
  cache: {},
  update_value: (id, value) => {
    // products_cache[ product_id ] = product
  },
  refresh_value: () => {
  },
})

const cached_contexts = {}
export const CachedValues = ({ name, fetch_value, children, cache_validity }) => {
  const value = useCachedContextInit(fetch_value, cache_validity, name)

  if (!cached_contexts[ name ]) {
    cached_contexts[ name ] = create_cached_context()
  }
  const Context = cached_contexts[ name ]

  return <Context.Provider value={value}>
    {children}
  </Context.Provider>
}

const useCachedContextInit = (fetch_value, cache_validity, name) => {
  const [cache, set_cache] = useState({})

  const validity_ends_timeout_ref = React.useRef({})

  const update_value = useCallback((id, value) => {
    set_cache((cache) => ( {
      ...cache,
      [ id ]: value,
    } ))

    if (cache_validity) {
      clearTimeout(validity_ends_timeout_ref.current[ id ])
      validity_ends_timeout_ref.current[ id ] = setTimeout(() => {
        value.refresh()
      }, cache_validity * 1000)
    }

  }, [])

  const reset_cache = useCallback(() => {
    set_cache({})
  }, [set_cache, cache])

  return [cache, update_value, fetch_value, reset_cache]
}

const refresh_value = (current_value, resource_id, ids, fetch_value, update_value, name) => {

  log(`\t\t${name} cached_context: refresh called`)

  // hot lava !
  if (current_value.loading) {
    update_value(resource_id, current_value)
  }


  const fetch_product = async () => {
    try {
      const value = await fetch_value(...ids)
      log(`\t\t${name} cached_context: get "${resource_id}" ->`, value)
      value.refresh = () => refresh_value(value, resource_id, ids, fetch_value, update_value, name)
      update_value(resource_id, value)
      return value
    } catch (e) {
      log(`\t\t${name} cached_context: get "${resource_id}" -> failure`, e)
      update_value(resource_id, {
        error: e,
        refresh: () => refresh_value(current_value, resource_id, ids, fetch_value, update_value, name),
      })
    }
  }
  return fetch_product().catch(e => log(e))
}


export const get_value = async (context, name, ...ids) => {
  const [cache, update_value, fetch_value] = context
  const resource_id = ids.join('-')

  let value = cache[ resource_id ]
  if (!cache[ resource_id ]) {
    value = await refresh_value({
      loading: true, refresh: () => {
        log(`\t\t${name}  cached_context: refresh called on empty value`)
      },
    }, resource_id, ids, fetch_value, update_value, name).catch(e => log(e))
  }

  return value

}
export const useContextProgrammatically = (name) => {
  return useContext(cached_contexts[ name ])
}

export const useCachedContext = (name, ...ids) => {
  const resource_id = ids.join('-')

  const [cache, update_value, fetch_value] = useContext(cached_contexts[ name ])

  useEffect(() => {
    if (!cache[ resource_id ]) {
      refresh_value({
        loading: true, refresh: () => {
          log(`\t\t${name}  cached_context: refresh called on empty value`)
        },
      }, resource_id, ids, fetch_value, update_value, name).catch(e => log(e))
    }
  }, [resource_id, cache, ids])

  return cache[ resource_id ] || {
    loading: true, refresh: () => {
      log(`\t\tcached_context: refresh called on empty value`)
    },
  }
}


export const useResetCachedValues = (name) => {
  const [, , , reset_cache] = useContext(cached_contexts[ name ])

  return reset_cache
}

export const is_ready = (resource) => {
  return resource && !resource.loading && !resource.error
}


export const useMultiCachedContext = (name, items) => {
  const [cache, update_value, fetch_value] = useContext(cached_contexts[ name ])


  React.useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      const item = items[ i ]
      const ids = Object.keys(item).map((k) => item[ k ])
      const resource_id = ids.join('-')

      if (!cache[ resource_id ]) {
        refresh_value({
          loading: true, refresh: () => {
            log(`\t\t${name}  cached_context: refresh called on empty value`)
          },
        }, resource_id, ids, fetch_value, update_value, name).catch(e => log(e))
      }

    }
  }, [items])

  const values = []
  for (let i = 0; i < items.length; i++) {
    const item = items[ i ]
    const ids = Object.keys(item).map((k) => item[ k ])
    const resource_id = ids.join('-')

    if (cache[ resource_id ]) {
      if (cache[ resource_id ].loading) {
        values.loading = true
      } else if (cache[ resource_id ].error) {
        if (!values.error) {
          values.error = []
        }
        values.error.push(cache[ resource_id ].error)
      } else {
        values.push(cache[ resource_id ])
      }
    } else {
      values.loading = true
    }
  }

  return values
}
