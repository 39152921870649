import React, { useContext } from 'react'
import { BackTo } from '../../components/navigation/back_to_home'
import Title from '../../components/layout/title'
import { section_color } from '../colors'
import { useFrench, useLang, useTranslate } from '../../context/lang'
import BigBox from '../../components/layout/big_box'
import Item, { CompactItem } from '../../components/generic/item'
import Button, { IconLabel } from '../../components/generic/button'
import { CachedValues, useCachedContext } from '../../hooks/useCachedContext'
import { to_upper_first } from '../order'
import NavigationButton from '../../components/generic/navigation-button'
import { DefaultError, DefaultLoading } from '../../components/i18n/translations'
import { ProductFirstVisual } from '../product_v2'
import { useSourceMarketPlace } from '../../hooks/useMarketPlace'
import { ProgressBadge } from '../product/progress'
import { feature_usage_query } from '../../graphql/queries/company'
import useCompanyId, { useCompany } from '../../hooks/useCompanyId'
import { get_client } from '../../graphql/simple-client'
import { plan } from '../../graphql/queries/plan'
import UserContext from '../../context/user'
import ExternalLink from '../../components/generic/external-link'
import {
  AiFillFire, FaArrowLeft, FaArrowRight, FiArrowDown, FiArrowRight,
  FiCheck,
  FiDelete, FiInfo,
  FiPause,
  FiRefreshCcw,
  FiSave,
  FiX,
  MdCheck,
  MdError,
  MdPause,
} from 'react-icons/all'
import CrispButton from '../../components/generic/crisp_button'
import Select from '../../components/generic/select'
import * as _ from 'lodash'
import UpdateButtonV2 from '../../components/generic/update_button_v2'
import { useMutation } from '@apollo/client'
import { cancel_subscription_query } from '../../graphql/queries/subscription'
import { await_mutate } from '../../graphql/mutate_promise'
import Field from '../../components/generic/field'
import { navigate } from '../../absolute_link'
import { MdWarning } from 'react-icons/md'
import { Tooltip } from 'react-tippy'
import { HelpModal } from '../../components/generic/help_button'
import { useFetchV2 } from '../../context/fetch_v2'
import { create_mutation } from '../../graphql/queries/_query_and_mutations_templates'
import { format_money } from '../order/format_numbers'
import moment from 'moment'

const IntellifoxAdvantage = ({ number, title, visual_helper }) => {
  return <div className="row align-items-center mb-3">
    <div className="col-auto">
      <h1>{number}</h1>
    </div>
    <div className="col">
      <Item
        color={'linear-gradient(205deg, rgba(255,255,255,0.10826337370885852) 0%, rgba(9,9,121,0.07745104877888653) 35%, rgba(0,212,255,0.09425777146796221) 100%)'}
        no_padding={true}>
        <div className="container-fluid p-3">
          <h3>{title}</h3>
          <div className="row">
            <div className="col-12">
              {visual_helper}
            </div>
          </div>
        </div>
      </Item>
    </div>
  </div>
}

const ThreeFirstProducts = () => {
  const market_place = useSourceMarketPlace()
  const translate = useTranslate()


  const products = useCachedContext('minimal_products', market_place, 0, 3, 'active')

  if (products.loading) {
    return <DefaultLoading/>
  }

  if (products.error) {
    return <DefaultError/>
  }

  return <div className="row">
    {products.map((p) => <div className="col-4 text-center">
      <ProductFirstVisual product={p} auto_scale={true}/>
      <div className="row">
        <div className="col-12 font-weight-bold">
          {translate({
            fr: `Taux d'optimisation`,
            en: `Optimization rate`,
          })}
        </div>
        <div className="col-12">
          <ProgressBadge val={p.progress.average}/>
        </div>
        <div className="col-12">
          <NavigationButton to={`/product/edit/${p.product_id}`}>{translate({
            fr: `J'optimise ce produit`,
            en: `Let's optimize this product`,
          })}</NavigationButton>
        </div>
      </div>
    </div>)}
  </div>
}

const RequestedComments = () => {
  const feature_usage = useCachedContext('feature_usage')
  const user = useContext(UserContext)
  const translate = useTranslate()
  const french = useFrench()


  const has_automatic_comments_activated = user.company.activate_comment_automatic_request || false
  if (!has_automatic_comments_activated) {
    return <div className="alert alert-danger">
      <h5>{translate({
        fr: `Tu n'as pas activé cette fonctionnalité d'Intellifox ! C'est le moment ! 🦊`,
        en: `You haven't activated this feature! Now is the perfect time for this! 🦊`,
      })}</h5>
      <NavigationButton to={'/comments'}>{translate({
        fr: `J'active cette fonctionnalité`,
        en: `I want to activate this feature`,
      })}</NavigationButton>
    </div>
  }

  if (feature_usage.loading) {
    return <DefaultLoading/>
  }

  if (feature_usage.error) {
    return <DefaultError/>
  }

  if (feature_usage.comment_requested < 2) {
    return <div className="alert alert-success">
      <h5>
        {translate({
          fr: `Cette fonction est activée pour toi ! Tu peux retrouver l'historique des demandes ici :`,
          en: `This feature is enabled for you! You can see comment request history there:`,
        })}
      </h5>
      <NavigationButton to={'/comments'}>{translate({
        fr: `Accéder à l'historique des demandes de commentaires`,
        en: `Go to the comment request history`,
      })}</NavigationButton>
    </div>
  }

  return <div className="alert alert-warning">
    <h5>
      {feature_usage.comment_requested} :
      {french(`C'est le nombre de commentaires qu'Intellifox a automatiquement demandé pour toi depuis le début! 🦊`)}
    </h5>
    <NavigationButton to={'/comments'}>{translate({
      fr: `Accéder à l'historique des demandes de commentaires`,
      en: `Go to the comment request history`,
    })}</NavigationButton>
  </div>
}

const TransparentImages = () => {
  const feature_usage = useCachedContext('feature_usage')
  const translate = useTranslate()
  const french = useFrench()

  if (feature_usage.loading) {
    return <DefaultLoading/>
  }

  if (feature_usage.error) {
    return <DefaultError/>
  }

  return <div className="row">
    <div className="col-12">
      <div className="alert alert-success">
        <h5>
          {feature_usage.transparent_images} :
          {french(`C'est le nombre de photos qu'Intellifox a détouré pour toi automatiquement depuis le début ! 🦊`)}
        </h5>
      </div>
    </div>
    <div className="col-12 small mt-2 font-weight-bold">
      <>{french(`Astuces :`)}
        <ol>
          <li>{french(`Tu peux utiliser l'éditeur d'image pour d'autres plateformes de ventes grâce à la fonction "Télécharger"`)}
          </li>
          <li>{translate({
            fr: `Tu peux aussi faire des montages pour les anniversaires ou tes annonces sur leboncoin par exemple !`,
            en: ``,
          })}</li>
          <li>{french(`Tiens, par exemple, nous on utilise l'éditeur d'image pour faire nos miniatures de`)}
            <ExternalLink
              to={'https://youtube.com/intellifox'} open_in_new_window={false}>{french(`la chaîne YouTube
            d'Intellifox`)}</ExternalLink> !
          </li>
        </ol>
      </>
    </div>
  </div>
}
const Publishes = () => {
  const feature_usage = useCachedContext('feature_usage')
  const french = useFrench()

  if (feature_usage.loading) {
    return <DefaultLoading/>
  }

  if (feature_usage.error) {
    return <DefaultError/>
  }

  return <div className="row">
    <div className="col-12">
      <div className="alert alert-success">
        <h5>
          {feature_usage.publish} :
          {french(`C'est le nombre de fois où tu as publié des modifications de tes produits au travers d'Intellifox 🦊`)}
        </h5>
      </div>
    </div>
    <div className="col-12 small mt-2 font-weight-bold">
      <>{french(`Sans Intellifox, pour faire la même chose, c'est le parcours du combattant ! Il faut :`)}
        <ol>
          <li>{french(`Soit faire des copier/coller dans le Seller Central`)}</li>
          <li>{french(`Soit utiliser les fichiers Excel de template aux 150 colonnes d'Amazon (et à ce moment il faut héberger
            tes
            images quelque part...)`)}
          </li>
        </ol>
      </>
    </div>
  </div>
}

const features = [
  {
    label: `Optimisation du référencement de tes fiches produits`,
    explanation: `Tu obtiens des conseils précis sur tes fiches produits avec les suggestions de mots-clés et la facilité de mise en oeuvre.`,
    active_for: ['continue'],
    type: 'feature',
  },
  {
    label: `Création d'images de ventes attrayantes`,
    explanation: `Tu crées des images de vente facilement grâce aux outils dédiés au e-commerce.`,
    active_for: ['continue'],
    type: 'feature',
  },
  {
    label: `Détourage automatique avec l'intelligence artificielle`,
    explanation: `Tu crées des montages faciles avec tes produits.`,
    active_for: ['continue'],
    type: 'feature',
  },
  {
    label: `Suivi de tes ventes multi-plateformes`,
    explanation: `Tu suis tes ventes sur toutes les plateformes où ton entreprise vend des produits sur Amazon.`,
    active_for: ['continue'],
    type: 'feature',
  },

  {
    label: `Sauvegardes des textes de ventes des produits`,
    explanation: `Tes données sont sauvegardées toutes les 24h et dupliquées dans notre cloud privé.`,
    active_for: ['continue', 'pause'],
    type: 'automation',
  },
  {
    label: `Sauvegardes des photos des produits`,
    explanation: `Tes données sont sauvegardées toutes les 24h et dupliquées dans notre cloud privé.`,
    active_for: ['continue', 'pause'],
    type: 'automation',
  },
  {
    label: `Sauvegardes de l'historique des ventes`,
    explanation: `Tes données sont sauvegardées toutes les 24h et dupliquées dans notre cloud privé.`,
    active_for: ['continue', 'pause'],
    type: 'automation',
  },
  {
    label: `Demandes de commentaires automatiques`,
    explanation: `Tu bénéficies de nouvelles preuves sociales grâce aux demandes qui sont envoyées automatiquement à tes clients.`,
    active_for: ['continue'],
    type: 'automation',
  },
  {
    label: `Alertes en cas de nouveau commentaire et/ou nouvelle vente`,
    explanation: `Tu reçois des alertes chaque fois qu'un nouveau commentaire est laissé ou que tu fais une nouvelle vente.`,
    active_for: ['continue'],
    type: 'automation',
  },
  {
    label: `Rapports de ventes hebdomadaires et mensuels`,
    explanation: `Tu reçois chaque semaine et chaque mois un rapport qui te permet de suivre ta profitabilité sur Amazon.`,
    active_for: ['continue'],
    type: 'automation',
  },
  {
    label: `Aide en cas de souci avec Intellifox`,
    explanation: `Tu es aidé par le support pour t'aider à résoudre tes problèmes sur Intellifox et Amazon.`,
    active_for: ['continue'],
    type: 'support',
  },
  {
    label: `Aide en cas de souci avec Amazon`,
    explanation: `Tu es aidé par le support pour t'aider à résoudre tes problèmes sur Intellifox et Amazon.`,
    active_for: ['continue'],
    type: 'support',
  },

]


const ShowFeatures = ({ label, option, features }) => {
  const french = useFrench()

  return <div className="row h-100">
    <div className="col-12 h-100 pb-3">
      <CompactItem label={label} className={'h-100'}>

        {features.map(({ label, explanation, active_for }) => {
          const is_active = active_for.indexOf(option) !== -1
          return <div className="row">
            <div className={`col-12 small  ${is_active ? 'font-weight-bold green' : 'red'}`}>
              <div className="row">
                <div className="col-auto">
                  {is_active ? <FiCheck/> : <FiX/>}
                </div>
                <div className="col">
                  {french(label)}
                </div>
                {is_active ? <div className="col-auto">
                  <Tooltip arrow={true} html={french(explanation)}><FiInfo/></Tooltip>
                </div> : null}
              </div>
            </div>
          </div>
        })}
      </CompactItem>
    </div>
  </div>
}

const FeaturesList = ({ number, option, label, action, explanation }) => {


  const french = useFrench()

  const features_groups = _.groupBy(features, 'type')

  return <div className="row mb-3">
    <div className="col-12">
      <Item>
        <div className="row">
          <div className="col-12">
            <h3 className={'font-weight-bold'}>{label} {number}</h3>
          </div>
          <div className="col-12">
            <hr/>
          </div>
          <div className="col-12 small mb-2">
            {explanation}
          </div>
          <div className="col-12 col-md-4">
            <ShowFeatures label={french(`Fonctionnalités`)} option={option} features={features_groups[ 'feature' ]}/>
          </div>
          <div className="col-12 col-md-4">
            <ShowFeatures label={french(`Automatisations`)} option={option} features={features_groups[ 'automation' ]}/>
          </div>
          <div className="col-12 col-md-4">
            <ShowFeatures label={french(`Support`)} option={option} features={features_groups[ 'support' ]}/>
          </div>
          <div className="col-12">
            <hr/>
          </div>
          <div className="col-12">
            {action}
          </div>
          {option === 'pause' ? <div className="col-12">
            <HelpModal label={french(`Pourquoi ce n'est pas gratuit ?`)} small={true}>
              <div className="row">
                <div className="col-12 font-weight-bold mb-2">
                  {french(`Conserver tes données est une responsabilité importante.`)}
                </div>
                <div className="col-12">
                  {french(`Cela nous prend des ressources matérielles, logistiques et humaines.`)}
                </div>
                <div className="col-12">
                  {french(`Ce service te permet de garantir que tu auras accès à tes données dans le futur et te permettra de les récupérer en cas de :`)}
                </div>
                <div className="col-12">
                  <ul>
                    <li>{french(`Mauvaise manipulation`)}</li>
                    <li>{french(`Panne de ton environnement`)}</li>
                    <li>{french(`Piratage`)}</li>
                    <li>{french(`Suppression inopinée de tes fiches produits sur Amazon (ça arrive !)`)}</li>
                    <li>{french(`etc.`)}</li>
                  </ul>
                </div>
                <div className="col-12">
                  {french(`Cela te permet d'être plus serein face à ces risques et d'éviter d'avoir à tout refaire.`)}
                </div>
              </div>
            </HelpModal>
          </div> : null}
        </div>

      </Item>
    </div>
  </div>
}

const discount = 25

const RequestDiscountButton = () => {
  const company_id = useCompanyId()
  const french = useFrench()
  const lang = useLang()

  const next_payment = useCachedContext('next_payment', company_id)

  const [request_discount] = useMutation(create_mutation({
    mutation: 'request_discount',
    params: {
      company_id: 'ID!',
    },
  }))

  const do_request_discount = async () => {
    await await_mutate(request_discount, {
      variables: {
        company_id,
      },
    })

    await next_payment.refresh()
  }

  if (next_payment.loading) {
    return <DefaultLoading/>
  }

  if (next_payment.error) {
    return <DefaultError/>
  }

  const has_discount = next_payment && next_payment.modifiers && next_payment.modifiers.find((m) => m.description === 'DISCOUNT')

  return <>
    {has_discount ? <div className="row mt-2 mb-2">
      <div className="col-12">
        <div className="alert alert-success font-weight-bold">
          <FiCheck/> {french(`Réduction de ${discount}% appliquée !!!`)} {french(`Prochain paiement : `)} {format_money(lang, next_payment.amount, next_payment.currency, 2)} ({french(`au lieu de :`)}
          <s>{format_money(lang, next_payment.amount / ( 1 - discount/100 ), next_payment.currency, 2)}</s>) 😁
        </div>
      </div>
    </div> : <div className="row text-center">
      <div className="col-12 ">
        <div className="alert alert-success mt-2 mb-2">
          <span className="badge badge-success">{french(`Recommandé`)}</span>
          <h4 className={'font-weight-bold'}>{french(`Offre spéciale !`)}<br/>
            <s className={'small'}>{format_money(lang, next_payment.amount, next_payment.currency, 2)}</s>
            <FiArrowRight/> <span
              className="green">{format_money(lang, next_payment.amount * (1 - discount/100), next_payment.currency, 2)}</span>
          </h4>
          <UpdateButtonV2 button={'success'} trigger={do_request_discount}>
            <IconLabel icon={'❤️'}>
              {french(`Clique ici pour continuer à bénéficier d'Intellifox avec ${discount}% de réduction sur la prochaine facture`)}
            </IconLabel>
          </UpdateButtonV2>
        </div>
      </div>
    </div>}

  <div className="row text-center">
    <div className="col-12">
      <div className="alert alert-info mt-2 mb-2">
        <h4 className={"font-weight-bold"}>{french(`Envie d'économiser encore plus ?`)}</h4>
        <p>{french(`Économise jusqu'à 50% par rapport à un abonnement mensuel.`)}</p>
        <NavigationButton to={"/subscription/upgrade"}><FiArrowRight/> {french(`Passer à la formule annuelle`)}</NavigationButton>
      </div>
    </div>
  </div>
  </>
}


export const CancelPause = () => {
  const me = useCachedContext('me')
  const company = useCompany()
  const french = useFrench()
  const lang = useLang()

  const { company_id, pause_subscription, pause_subscription_date } = company

  const [pause_subscription_mut] = useMutation(create_mutation({
    mutation: 'pause_subscription',
    params: {
      company_id: 'ID!',
      pause_subscription: 'Boolean',
    },
  }))

  const do_pause_subscription = async (pause_subscription) => {
    await await_mutate(pause_subscription_mut, {
      variables: {
        company_id,
        pause_subscription,
      },
    })

    await me.refresh()
  }


  return <>
    {pause_subscription ?
      <UpdateButtonV2 button={'link'} className={'btn-sm'} trigger={async () => await do_pause_subscription(false)}>
        <IconLabel icon={<FiX/>}>
          {french(`Annuler la pause`)}
        </IconLabel>
      </UpdateButtonV2> : null
    }
  </>

}

export const PauseSubscriptionButton = () => {
  const me = useCachedContext('me')
  const company = useCompany()
  const french = useFrench()
  const lang = useLang()

  const { company_id, pause_subscription, pause_subscription_date } = company

  const [pause_subscription_mut] = useMutation(create_mutation({
    mutation: 'pause_subscription',
    params: {
      company_id: 'ID!',
      pause_subscription: 'Boolean',
    },
  }))

  const do_pause_subscription = async (pause_subscription) => {
    await await_mutate(pause_subscription_mut, {
      variables: {
        company_id,
        pause_subscription,
      },
    })

    await me.refresh()
  }


  return <>
    {pause_subscription ? <div className="row">
        <div className="col-12">
          <div className="alert alert-warning font-weight-bold">
            <FiCheck/> {french(`La pause est bien prise en compte.`)} {french(`Celle-ci sera effective à partir du :`)} {moment(pause_subscription_date).format('ll')}
            <CancelPause/>
          </div>
        </div>
      </div> :
      <UpdateButtonV2 button={'primary'} trigger={async () => await do_pause_subscription(true)}>
        <IconLabel icon={'⏸️'}>
          {french(`Clique ici pour bénéficier de la sauvegarde de tous tes produits et photos pour 7.99 € par mois`)}
        </IconLabel>
      </UpdateButtonV2>
    }
  </>
}

const ThingsYouHaveToLose = ({ set_wish_to_lose }) => {
  const me = useCachedContext('me')
  const translate = useTranslate()
  const french = useFrench()
  const company = useCompany()


  return <>
    <BigBox title={<>💔 {french(`Nous n'avons pas envie de te voir partir`)} 😢</>} wrap_in_item={false}>
      <FeaturesList option={'continue'} number={'❤️'}
                    label={french(`Et si tu restais en l'échange d'une réduction de 25% ?`)}
                    explanation={<div className="row">
                      <div className="col-12">
                        {french(`Pour te garder à nos côtés, nous sommes prêts à te faire une offre !`)}
                      </div>
                      <div className="col-12">
                        {french(`Si tu l'acceptes, tu vas continuer à bénéficier d'Intellifox dans son intégralité :`)}
                      </div>
                    </div>}
                    action={company.pause_subscription ? <PauseSubscriptionButton/> : <RequestDiscountButton/>}
      />
      {/*<FeaturesList option={'pause'} number={'⏸️'} label={french(`Faire une pause`)}*/}
      {/*              explanation={<div className="row">*/}
      {/*                <div className="col-12">*/}
      {/*                  {french(`Tu ne souhaites plus bénéficier des fonctionnalités et automatisations d'Intellifox ?`)}*/}
      {/*                </div>*/}
      {/*                <div className="col-12">*/}
      {/*                  {french(`On peut simplement faire une pause et nous conservons 100% de tes données pour te permettre de revenir quand tu le désires dans le futur.`)}*/}
      {/*                </div>*/}
      {/*              </div>}*/}
      {/*              action={*/}
      {/*                <PauseSubscriptionButton/>*/}
      {/*              }*/}
      {/*/>*/}
      <div className="row">
        <div className="col-12">
          <hr/>
        </div>
      </div>
      <FeaturesList option={'cancel'} number={'💔'} label={french(`Arrêter mon abonnement et supprimer mes données`)}
                    explanation={<div className="row">
                      <div className="col-12">
                        {french(`Tu conserveras l'accès à ton compte jusqu'à la fin de ta période de facturation actuelle.`)}
                      </div>
                      <div className="col-12">
                        {french(`Ensuite, au bout de 15 jours, toutes tes photos, mots-clés et historique de ventes seront supprimées de notre cloud privé.`)}
                      </div>
                    </div>}
                    action={company.pause_subscription ? <PauseSubscriptionButton/> : <><Button button={'danger'}
                                                                                                onClick={() => set_wish_to_lose(true)}>
                      <IconLabel icon={'😢'}>
                        {french(`Je veux arrêter mon abonnement et supprimer toutes mes données`)}
                      </IconLabel>
                    </Button>
                      <div className="row">
                        <div className="col-12 small">
                          {french(`Note : il faut confirmer l'arrêt de l'abonnement dans la prochaine étape`)}
                        </div>
                        <div className="col-12 small">
                          {french(`Note 2 : il n'est possible de bénéficier de la période d'essai qu'une seule fois par compte Amazon`)}
                        </div>
                      </div>
                    </>
                    }
      />
    </BigBox>
  </>
}
const default_reasons_fr = _.shuffle([
  `J'arrête la vente sur Amazon`,
  `Je voulais juste voir brièvement`,
  `J'ai des problèmes financiers`,
  `Le logiciel n'est pas assez ergonomique`,
  `Je suis un concurrent en espionnage industriel`,
  `Je suis déçu par les fonctionnalités`,
  `Je n'arrive pas à utiliser le logiciel`,
  `Je trouve le logiciel trop cher`,
  `Je trouve la documentation insuffisante`,
  `Je suis déçu par le support`,
  `J'utilise un autre logiciel mieux qu'Intellifox`,
  `C'est juste temporaire, je reviendrai !`,
])

const default_reasons_en = _.shuffle([
  `I stop selling on Amazon`, //`J'arrête la vente sur Amazon`,
  `I just wanted to see briefly`, //`Je voulais juste voir brièvement`,
  `I have financial problems`, //`J'ai des problèmes financiers`,
  `The software is not ergonomic enough`, //`Le logiciel n'est pas assez ergonomique`,
  `I am a competitor in industrial espionage`, //`Je suis un concurrent en espionnage industriel`,
  `I am disappointed with the features`, //`Je suis déçu par les fonctionnalités`,
  `I can't use the software`, //`Je n'arrive pas à utiliser le logiciel`,
  `I find the software too expensive`, //`Je trouve le logiciel trop cher`,
  `I find the documentation insufficient`, //`Je trouve la documentation insuffisante`,
  `I am disappointed with the support`, //`Je suis déçu par le support`,
  `I am using other software better than Intellifox`, //`J'utilise un autre logiciel mieux qu'Intellifox`,
  `It's just temporary, I'll be back!`, //`C'est juste temporaire, je reviendrai !`,
])

const ConfirmCancel = () => {
  const me = useCachedContext('me')
  const company_id = useCompanyId()

  const translate = useTranslate()
  const french = useFrench()

  const other_reason = translate({
    fr: 'Autre',
    en: 'Other',
  })

  const [confirmed, set_confirmed] = React.useState(true)

  const [current_reason, set_current_reason] = React.useState('n/a')
  const [other, set_other] = React.useState('')

  const [cancel_subscription] = useMutation(cancel_subscription_query)

  const do_cancel_subscription = async () => {
    await await_mutate(cancel_subscription, {
      variables: {
        company_id,
        reason: current_reason === other_reason ? other : current_reason,
      },
    })

    await me.refresh()

    await navigate('/subscription#?cancel=y')
  }


  const reasons = [
    french(`J'arrête la vente sur Amazon`),
    french(`Je voulais juste voir brièvement`),
    french(`J'ai des problèmes financiers`),
    french(`Le logiciel n'est pas assez ergonomique`),
    french(`Je suis un concurrent en espionnage industriel`),
    french(`Je suis déçu par les fonctionnalités`),
    french(`Je n'arrive pas à utiliser le logiciel`),
    french(`Je trouve le logiciel trop cher`),
    french(`Je trouve la documentation insuffisante`),
    french(`Je suis déçu par le support`),
    french(`J'utilise un autre logiciel mieux qu'Intellifox`),
    french(`C'est juste temporaire, je reviendrai !`),
    other_reason,
  ]

  if (confirmed) {

    return <BigBox title={french(`Pour confirmer ta résiliation, clique sur le bouton en bas`)}>
      <div className="row">
        <div className="col-12">
          <div className="col-12 font-weight-bold">
            {translate({
              fr: `Afin d'améliorer notre application, peux-tu nous expliquer la raison de ton départ ?`,
              en: `In order to improve our application, can you explain to us the reason for your departure?`,
            })}
          </div>

          <div className="col-12">
            {reasons.map((reason, i) => {
              return <div className="row clickable" onClick={() => set_current_reason(reason)} key={`${i}`}>
                <div className="col-auto">
                  <input type="radio" checked={reason === current_reason} id={reason}/>
                </div>
                <div className="col">
                  <label htmlFor={reason}>{reason}</label>
                </div>
              </div>
            })}
          </div>

          {current_reason === other_reason ? <Field type="text" value={other} setter={set_other}/> : null}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <UpdateButtonV2 trigger={do_cancel_subscription} button={'danger'}><IconLabel icon={'😢'}>{translate({
            fr: `Je confirme ma résiliation et j'ai compris que mes photos, mots-clés et historique de ventes allaient être supprimés 15 jours après la fin de ma période de facturation actuelle`,
            en: `I confirm my cancellation and understand my pictures, keywords and sales history will be deleted 15 days after the end of my current billing cycle`,
          })}</IconLabel></UpdateButtonV2>
        </div>
      </div>
    </BigBox>
  }

  return <>
    <BigBox title={<>{to_upper_first(me.user.first_name)}, une des offres ci-dessous peut te persuader de rester !</>}>
      <IntellifoxAdvantage number={1}
                           title={`Mettre en pause ton abonnement`}
                           visual_helper={<div className="row">
                             <div className="col-12 col-lg-4">
                               <Button button="secondary"><MdPause/> Faire une pause de 30 jours</Button>
                             </div>
                             <div className="col-12 col-lg-4">
                               <Button button="secondary"><MdPause/> Faire une pause de 60 jours</Button>
                             </div>
                             <div className="col-12 col-lg-4">
                               <Button button="secondary"><MdPause/> Faire une pause de 90 jours</Button>
                             </div>
                           </div>}
      />
      <hr/>
      <IntellifoxAdvantage number={2}
                           title={`50% de réduction pendant les 3 prochains mois`}
                           visual_helper={<div className="row">
                             <div className="col-12 font-weight-bold small">
                               Offre exceptionnelle
                             </div>
                             <div className="col-12">
                               <Button><AiFillFire/> Bénéficier de -50% pendant les 3 prochains mois</Button>
                             </div>
                           </div>}/>
      <hr/>
      <IntellifoxAdvantage number={3}
                           title={`Contacter le support`}
                           visual_helper={<div className="row">
                             <div className="col-12">
                               <CrispButton
                                 text={'Je souhaite résilier Intellifox, avez-vous une offre à me proposer ?'}>
                                 Contacter le service client
                               </CrispButton>
                             </div>
                           </div>}
      />
    </BigBox>

    <div className="row">
      <div className="col-6">
        <Button className="btn-block" button="secondary"
                onClick={() => set_confirmed(true)}>Aucune de ces offres ne m'intéresse</Button>
      </div>
    </div>
  </>
}

export default function Cancel() {
  const translate = useTranslate()
  const fetch_v2 = useFetchV2()

  const company_id = useCompanyId()

  const [wish_to_lose, set_wish_to_lose] = React.useState(false)


  const fetch_feature_usage = async () => {
    const client = get_client()
    const response = await client.request(feature_usage_query, {
      company_id,
    })
    return response.feature_usage
  }

  const fetch_next_payment = fetch_v2({
    query: 'next_payment',
    params: {
      company_id: 'ID!',
    },
    output: `
    {
      amount
      currency
      date
      modifiers {
        modifier_id
        subscription_id
        amount
        currency
        is_recurring
        description
      }
    }`,
  })


  return (
    <CachedValues fetch_value={fetch_feature_usage} name={'feature_usage'}>
      <CachedValues fetch_value={fetch_next_payment} name={'next_payment'}>
        <div className="container pb-5">
          <BackTo to={'/subscription'} label={translate({
            fr: `Retour à la gestion de l'abonnement`,
            en: `Back to subscription management`,
          })}/>
          <Title color={section_color('subscription')} style={{ color: 'black' }}>{translate({
            fr: `Modifier mon abonnement`,
            en: `Edit my subscription`,
          })}</Title>

          {!wish_to_lose ? <ThingsYouHaveToLose set_wish_to_lose={set_wish_to_lose}/> : <ConfirmCancel/>}
        </div>
      </CachedValues>
    </CachedValues>
  )
}
