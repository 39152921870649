import React, { useContext } from 'react'
import { useCachedContext } from '../hooks/useCachedContext'
import { get_client } from '../graphql/simple-client'
import { GET_TRANSLATION, translate_interface_query } from '../graphql/queries/translation'
import useCompanyId from '../hooks/useCompanyId'
import * as _ from 'lodash'

export const FetchV2Context = React.createContext({
  fetch_v2: () => {
  },
})

export const useFetchV2 = () => {
  const { fetch_v2 } = useContext(FetchV2Context)
  return fetch_v2
}

export const useQuery = ({query, validate_user,params,output}) => {
  const fetch_v2 = useFetchV2()

  return fetch_v2({query, validate_user,params,output})
}

