import { gql } from '@apollo/client'

export const GET_IMAGES_STR = `
    query GetImages($company_id: ID!, $tags: [String]) {
        images(company_id: $company_id, tags:$tags) {
            image_id
            url
            width
            height
            tags
        }
    }
`
export const GET_IMAGES = gql(GET_IMAGES_STR)

export const GET_IMAGE = `
    query GetImage($company_id: ID!, $image_id: ID!) {
        image(company_id: $company_id, image_id:$image_id) {
            image_id
            url
            width
            height
        }
    }
`

export const ADD_IMAGE = gql`
    mutation AddImage($company_id: ID!, $image: Upload!, $tags:[String]!) {
        addImage(company_id:$company_id, image: $image, tags: $tags) {
            image_id
        }
    }
`

export const REPLACE_IMAGE = gql`
    mutation ReplaceImage(
        $company_id: ID!,
        $image_id: ID!
        $image: Upload!,
    ){
        replaceImage(
            company_id:$company_id,
            image_id: $image_id,
            image: $image,
        )
    }
`


export const UPDATE_IMAGE = gql`
    mutation UpdateImage($company_id: ID!, $image_id: ID!, $label: String, $tags: [String]!) {
        updateImage(company_id: $company_id, image_id: $image_id, label:$label, tags:$tags) {
            image_id
        }
    }
`

export const DELETE_IMAGE = gql`
    mutation DeleteImage($company_id: ID!, $image_id: ID!) {
        deleteImage(company_id: $company_id, image_id: $image_id)
    }
`

export const MAKE_IMAGE_PUBLIC = gql`
    mutation MakeImagePublic($company_id: ID!, $image_id: ID!, $is_public: Boolean!) {
        make_image_public(company_id: $company_id, image_id: $image_id, is_public:$is_public)
    }
`

export const CREATE_TRANSPARENT_IMAGE = gql`
    mutation CreateTransparentImage($company_id: ID!, $image_id: ID!) {
        createTransparentImage(company_id: $company_id, image_id: $image_id)
    }
`


export const GET_PIXABAY_IMAGES = `
    query GetPixabayImages(
        $company_id: ID!,
        $query: String,
        $lang: String,
        $category: String,
        $page: String,
        $per_page: String,
        $colors: String,
    ) {
        pixabay_images(
            company_id: $company_id,
            query: $query,
            lang: $lang,
            category: $category,
            page: $page,
            per_page: $per_page,
            colors: $colors,
        ) {
            pixabay_id
            previewURL
            previewWidth
            previewHeight
            imageWidth
            imageHeight
        }
    }
`
