import { gql } from '@apollo/client'

export const SET_SEARCH_TERMS = gql`
    mutation SetSearchTerms(
        $company_id: ID!,
        $product_id: ID!,
        $market_place: String!,
        $search_terms: [String]!,
    ){
        set_search_terms(
            company_id: $company_id,
            product_id:$product_id,
            market_place:$market_place,
            search_terms:$search_terms,
        )
    }
`


export const GET_SEARCH_TERM_DETAILS = `
    query GetSearchTermDetails(
        $company_id: ID!
        $market_place: String!
        $search_term: String!
    ){
        get_search_term_details(
            company_id: $company_id
            market_place:$market_place
            search_term:$search_term
        ) {
            last_update
            search_results {
                asin
                title
                url
                image
                price
                rating
                ratings_total
                position
            }
            keywords {
                keyword
                intensity
                title
                bullet_point
                description
                found_in_brand
            }
        }
    }
`

