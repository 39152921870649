import React from 'react'
import { useDesignerContext } from '../designer_context'
import Button from '../../generic/button'
import { useTranslate } from '../../../context/lang'

export default function CopyPasteMenu() {
  const { copy, paste, clipboard, get_selected_objects } = useDesignerContext()
  const translate = useTranslate()

  const has_select_objects = get_selected_objects().length >= 1
  return (
    <>
      <Button className={'btn-sm mr-1'} onClick={copy} disabled={!has_select_objects}>{translate({
          fr: `Copier`,
          en: `Copy`
      })}</Button>
      <Button className={'btn-sm'} onClick={paste} disabled={!clipboard}>{translate({
          fr: `Coller`,
          en: `Paste`
      })}</Button>
    </>
  )
}
